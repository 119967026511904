import { makeApiRequestSlice } from '../../../helpers/apiRequestSlice';
import { ShippingPolicyEntity } from '../../../schemas';
import { NormalizedResponse } from '../../../types';

export type CreateShippingPolicyRequestPayload = {
  name: string;
};

export type CreateShippingPolicyResponsePayload = {
  request: CreateShippingPolicyRequestPayload;
  response: NormalizedResponse & {
    result: {
      shippingPolicy: ShippingPolicyEntity;
    };
  };
};

const createShippingPolicySlice = makeApiRequestSlice<
  CreateShippingPolicyRequestPayload,
  CreateShippingPolicyResponsePayload
>({
  name: 'shippingPolicy/create',
});

export default createShippingPolicySlice;
