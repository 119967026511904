import cx from 'classnames';
import React, { ReactElement } from 'react';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { decodeImageSrc, formatDateShort, formatPrice } from '../../../../helpers/utils';
import {
  ProductImageEntity,
  ShopEntity,
  WarehouseProductEntity,
  WarehouseProductStatus,
} from '../../../../schemas';
import WarehouseSingleActionsDropdown from '../WarehouseSingleActionsDropdown/WarehouseSingleActionsDropdown';
import WarehouseProductStatusBadge from '../WarehouseStatusBadge/WarehouseProductStatusBadge';
import styles from './formatters.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const imageFormatter = (image: ProductImageEntity | null): ReactElement => (
  <CenterCropImg
    alt="product"
    className="rounded"
    height={32}
    src={decodeImageSrc(image)}
    width={32}
  />
);

export const nameFormatter = (name: string, row: WarehouseProductEntity): ReactElement => {
  const { numVariants, sku } = row;
  return (
    <div className={styles.name}>
      {name ? (
        <span className={styles.name__link}>
          <div className={cx(tableStyles.textDark, styles.name__link__name)}>{name}</div>
          <div className={cx(tableStyles.textSecondary)}>
            {numVariants > 1 ? `${numVariants} variants` : sku}
          </div>
        </span>
      ) : null}
    </div>
  );
};

export const inventoryFormatter = (amount: number): ReactElement => {
  if (amount === undefined) return <></>;
  return <span>{amount} in stock</span>;
};

export const priceFormatter = (
  unused: unknown,
  row: WarehouseProductEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { maxPrice, minPrice } = row;
  if (minPrice === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark)}>
      {minPrice !== maxPrice
        ? `${formatPrice(minPrice, currency)} - ${formatPrice(maxPrice, currency)}`
        : formatPrice(minPrice, currency)}
    </span>
  );
};

export const statusFormatter = (status: WarehouseProductStatus): ReactElement => {
  return <WarehouseProductStatusBadge status={status} />;
};

export const categoriesFormatter = (categories: string[]): ReactElement => {
  if (categories === undefined) return <></>;
  return <span>{categories.join(', ')}</span>;
};

export const dateFormatter = (
  datetimeStr: string,
  row: WarehouseProductEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const currency = formatExtraData.shop?.currency;
  return <span className="text-nowrap">{formatDateShort(datetimeStr, currency)}</span>;
};

export const actionsFormatter = (
  unused: unknown,
  product: WarehouseProductEntity
): ReactElement => <WarehouseSingleActionsDropdown product={product} />;
