import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { INVENTORY_URL } from '../../../global/urls';
import { makeQueryKey } from '../../../helpers/utils';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SelectShopApiSuccessAction } from '../../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import {
  InventoryFetchApiFailureAction,
  InventoryFetchApiRequestAction,
  InventoryFetchApiSuccessAction,
  InventoryFetchTriggerAction,
} from '../actions/fetch.actions';
import {
  INVENTORY_FETCH_API_FAILURE,
  INVENTORY_FETCH_API_REQUEST,
  INVENTORY_FETCH_API_SUCCESS,
} from '../constants';
import { InventoryPageState } from '../types';
import { inventoryQueryReducer } from './inventoryQueryReducer';
import { InventorySearchAction, inventorySearchReducer } from './inventorySearchReducer';

const initialState: InventoryPageState = {
  queries: {},
  searches: {},
  selected: [],
};

export type InventoryAction =
  | LocationChangeAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | InventoryFetchApiFailureAction
  | InventoryFetchApiRequestAction
  | InventoryFetchApiSuccessAction
  | InventoryFetchTriggerAction
  | InventorySearchAction
  | ResetSessionAction
  | SelectShopApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const inventoryPageReducer: Reducer<InventoryPageState, InventoryAction> = (
  state = initialState,
  action: InventoryAction
): InventoryPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case INVENTORY_FETCH_API_FAILURE:
      case INVENTORY_FETCH_API_REQUEST:
      case INVENTORY_FETCH_API_SUCCESS: {
        const { search, sort, start } = action.payload;
        const key = makeQueryKey(sort, start, search);
        draft.queries[key] = inventoryQueryReducer(state.queries[key], action);
        draft.searches[search] = inventorySearchReducer(state.searches[search], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: INVENTORY_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
