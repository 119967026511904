import cx from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { Col, Input } from 'reactstrap';
import styles from './CustomersRightControls.module.scss';

type Props = {
  searchInput: string;
  setSearchInput: (newValue: string) => void;
};

const CustomersRightControls: FC<Props> = ({ searchInput, setSearchInput }: Props) => {
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />
        {/* <ButtonIcon */}
        {/*   icon={faExternalLinkAlt} */}
        {/*   transform="shrink-3 down-2" */}
        {/*   color="falcon-default" */}
        {/*   size="sm" */}
        {/*   className={cx('mx-2', styles.headerBtn, styles.headerBtnGray)} */}
        {/* > */}
        {/*   Export */}
        {/* </ButtonIcon> */}
      </Col>
    </>
  );
};

export default CustomersRightControls;
