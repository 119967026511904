import produce from 'immer';
import { Reducer } from 'redux';
import { CollectionProduct, MicroProductEntity } from '../../../schemas';
import { CollectionBumpProductsAction, CollectionShowMoreProductsAction } from '../actions/actions';
import { COLLECTION_BUMP_PRODUCTS, COLLECTION_SHOW_MORE_PRODUCTS } from '../constants';
import {
  CollectionBrowseSuccessPayload,
  CollectionProductsSuccessPayload,
  CollectionSuccessPayload,
  fetchCollectionBrowseSlice,
  FetchCollectionBrowseSuccessAction,
  fetchCollectionProductsSlice,
  FetchCollectionProductsSuccessAction,
  fetchCollectionSlice,
  FetchCollectionSuccessAction,
} from '../slices';

const PRODUCTS_PAGE_SIZE = 100;

export type CollectionState = {
  browsableProductIds: MicroProductEntity[] | null;
  bumper: number;
  numShown: number;
  numTotal: number;
  products: CollectionProduct[] | null;
  selectedProductIds: number[];
};

export const initialCollectionState: CollectionState = {
  browsableProductIds: null,
  bumper: 0,
  numShown: 0,
  numTotal: 0,
  products: null,
  selectedProductIds: [],
};

export type CollectionReducerAction =
  | CollectionBumpProductsAction
  | CollectionShowMoreProductsAction
  | FetchCollectionBrowseSuccessAction
  | FetchCollectionSuccessAction
  | FetchCollectionProductsSuccessAction;

export const collectionReducer: Reducer<CollectionState, CollectionReducerAction> = (
  state: CollectionState = initialCollectionState,
  action: CollectionReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case fetchCollectionBrowseSlice.actions.success.type: {
        const payload = action.payload as CollectionBrowseSuccessPayload;
        const { products } = payload.response.result;
        draft.browsableProductIds = products;
        break;
      }

      case fetchCollectionSlice.actions.success.type: {
        const payload = action.payload as CollectionSuccessPayload;
        const products = payload.response.result.products as CollectionProduct[];
        draft.products = products;
        draft.numTotal = products.length;
        draft.numShown = Math.min(products.length, PRODUCTS_PAGE_SIZE);
        draft.selectedProductIds = products.map((product) => product.productId);
        break;
      }

      case fetchCollectionProductsSlice.actions.success.type: {
        if (draft.products === null) {
          draft.products = [];
        }
        const payload: CollectionProductsSuccessPayload = action.payload as CollectionProductsSuccessPayload;
        draft.products = [...(draft.products || []), payload.response.result.products];
        break;
      }

      case COLLECTION_BUMP_PRODUCTS: {
        const { addedIds, removedIds } = (action as CollectionBumpProductsAction).payload;
        const numAdded = addedIds.length;
        const numRemoved = removedIds.length;
        const diff = numAdded - numRemoved;
        draft.numTotal += diff;
        draft.numShown = draft.numTotal;
        draft.bumper += 1;
        draft.selectedProductIds = draft.selectedProductIds.filter(
          (productId) => !removedIds.includes(productId)
        );
        addedIds.forEach((productId) => draft.selectedProductIds.push(productId));
        break;
      }

      case COLLECTION_SHOW_MORE_PRODUCTS: {
        draft.numShown = Math.min(draft.numTotal, draft.numShown + PRODUCTS_PAGE_SIZE);
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
