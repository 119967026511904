import { useDispatch, useSelector } from 'react-redux';
import {
  setContractEmail,
  setContractName,
  setContractPhone,
  setContractPosition,
  toggleContractAuthorized,
  toggleContractTermsAccepted,
} from '../actions';
import { getVendorRegistrationContractState } from '../selectors';
import { VendorRegistrationContract } from '../types';

type VendorRegistrationContractData = {
  contract: VendorRegistrationContract;
  setName: (name: string) => void;
  setPosition: (position: string) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
  toggleTermsAccepted: () => void;
  toggleAuthorized: () => void;
};

const useVendorRegistrationContractData = (): VendorRegistrationContractData => {
  const dispatch = useDispatch();
  const contract = useSelector(getVendorRegistrationContractState);

  return {
    contract,
    setName: (name: string) => dispatch(setContractName(name)),
    setPosition: (position: string) => dispatch(setContractPosition(position)),
    setEmail: (email: string) => dispatch(setContractEmail(email)),
    setPhone: (phone: string) => dispatch(setContractPhone(phone)),
    toggleTermsAccepted: () => dispatch(toggleContractTermsAccepted()),
    toggleAuthorized: () => dispatch(toggleContractAuthorized()),
  };
};

export default useVendorRegistrationContractData;
