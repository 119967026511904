import cx from 'classnames';
import React, { FC } from 'react';
import { FulfillmentStatus } from '../../schemas';
import styles from './FulfillmentStatusBadge.module.scss';

interface BadgeConf {
  styleColor: string;
  text: string;
}

const FULFILLMENT_STATUSES: { [key in FulfillmentStatus]?: BadgeConf } = {
  [FulfillmentStatus.Fulfilled]: {
    styleColor: 'Fulfilled',
    text: 'Fulfilled',
  },
  [FulfillmentStatus.Null]: {
    styleColor: 'Unfulfilled',
    text: 'Unfulfilled',
  },
  [FulfillmentStatus.Partial]: {
    styleColor: 'PartiallyFulfilled',
    text: 'Partially Fulfilled',
  },
};

const NO_INFO_STATUS: BadgeConf = {
  styleColor: 'NoInfo',
  text: 'No Info',
};

type Props = {
  className?: string;
  status: FulfillmentStatus | null;
};

const FulfillmentStatusBadge: FC<Props> = ({ className, status }: Props) => {
  if (!status) {
    return <span />;
  }

  const conf = FULFILLMENT_STATUSES[status] || NO_INFO_STATUS;
  const styleColor = `status${conf.styleColor}`;
  const { text } = conf;

  return (
    <span
      className={cx(
        'rounded-pill text-center text-capitalize py-1 px-3',
        styles.statusText,
        styles[styleColor],
        className
      )}
    >
      {text}
    </span>
  );
};

export default FulfillmentStatusBadge;
