import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../init/rootReducer';
import {
  productAddOption,
  productAddOptionValue,
  productRemoveOption,
  productRemoveOptionValue,
  productSetOption,
} from '../actions/options.actions';
import { getProductState } from '../selectors';
import { ProductOption } from '../types';
import useProductParams from './useProductParams';

type ProductOptionsData = {
  availableOptions: ProductOption[];
  optionActions: {
    addOption(name: string, values: string[]): void;
    addOptionValue(id: number | string, value: string): void;
    removeOption(id: number | string): void;
    removeOptionValue(id: number | string, value: string): void;
    setOption(id: number | string, name: string): void;
  };
};

const useProductOptionsData = (): ProductOptionsData => {
  const dispatch = useDispatch();
  const { productId } = useProductParams();
  const { options } = useSelector((state: AppState) => getProductState(state, productId));

  return {
    availableOptions: options,
    optionActions: {
      addOption: (name: string, values: string[]) =>
        dispatch(productAddOption(productId, name, values)),
      addOptionValue: (id: number | string, value: string) =>
        dispatch(productAddOptionValue(productId, id, value)),
      removeOption: (id: number | string) => dispatch(productRemoveOption(productId, id)),
      removeOptionValue: (id: number | string, value: string) =>
        dispatch(productRemoveOptionValue(productId, id, value)),
      setOption: (id: number | string, name: string) =>
        dispatch(productSetOption(productId, id, name)),
    },
  };
};

export default useProductOptionsData;
