import produce from 'immer';
import { Reducer } from 'redux';
import { GeneralActionTypes } from './actionTypes';
import { TOGGLE_SIDE_MENU_COLLAPSED } from './constants';
import { GeneralState } from './types';

const initialState = {
  sideMenuCollapsed: false,
};

// eslint-disable-next-line import/prefer-default-export
export const generalReducer: Reducer<GeneralState, GeneralActionTypes> = (
  state: GeneralState = initialState,
  action: GeneralActionTypes
): GeneralState =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_SIDE_MENU_COLLAPSED: {
        draft.sideMenuCollapsed = !draft.sideMenuCollapsed;
        break;
      }
      default:
        return draft;
    }
    return draft;
  });
