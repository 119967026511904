import cx from 'classnames';
import React, { CSSProperties, FC } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Card } from 'reactstrap';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { RefundedItemEntity, ShopEntity } from '../../../../schemas';
import styles from './OrderRefunds.module.scss';
import columns from './columns';

interface Props {
  items: RefundedItemEntity[];
  shop: ShopEntity | null;
}

const OrderRefunds: FC<Props> = ({ items, shop }: Props) => {
  if (items.length === 0) {
    return null;
  }

  const rowStyleFormat = (row: RefundedItemEntity, rowIdx: number): CSSProperties => {
    return { backgroundColor: rowIdx % 2 === 0 ? '' : '#f9fafd' };
  };

  return (
    <Card className={cx('h-lg-100 mb-3', styles.OrderRefunds)}>
      <FalconCardHeader border={false} className="bg-white" title="Refunds" />
      <BootstrapTable
        keyField="id"
        data={items}
        columns={columns(shop)}
        bootstrap4
        bordered={false}
        classes={cx(tableStyles.table, 'align-middle mb-0')}
        headerClasses={cx(tableStyles.header, 'fs-1')}
        headerWrapperClasses={cx(tableStyles.headerWrapper)}
        rowClasses={cx(tableStyles.row, 'py-2 align-middle')}
        rowStyle={(row, idx) => rowStyleFormat(row, idx)}
      />
    </Card>
  );
};

export default OrderRefunds;
