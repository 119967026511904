import cx from 'classnames';
import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import useShopData from '../../../../global/hooks/useShopData';
import useProductStoresData from '../../hooks/useProductStoresData';
import CollectionsList from '../CollectionsList/CollectionsList';
import styles from './ProductCollections.module.scss';

const ProductCollections: FC = () => {
  const { activeShop } = useShopData();
  const { collections, storeActions } = useProductStoresData();
  const { toggleCollectionItem } = storeActions;
  const activeShopName = activeShop ? activeShop.name : '';
  const activeShopLogoUrl = activeShop
    ? process.env.REACT_APP_DJANGO_BASE_URL + activeShop.logoUrl
    : undefined;

  return (
    <Card className="mb-3">
      <FalconCardHeader className="bg-white" title="Collections" titleClass={styles.header}>
        <img src={activeShopLogoUrl} alt={activeShopName} className={cx(styles.shopLogo)} />
      </FalconCardHeader>
      <CardBody className="py-0 mt-2 pb-3">
        <CollectionsList collections={collections} toggleCollectionItem={toggleCollectionItem} />
      </CardBody>
    </Card>
  );
};

export default ProductCollections;
