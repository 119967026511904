import cx from 'classnames';
import React, { FC, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ProductOption } from '../../types';
import styles from './OptionSelector.module.scss';

type Props = {
  allOptions: ProductOption[];
  name: string;
  onSelect: (optionName: string) => void;
  selected: string;
  disabled?: boolean;
};

const OptionSelector: FC<Props> = ({ allOptions, name, onSelect, selected, disabled }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const inventoryOptionItem = allOptions.find((item) => item.name === name);

  const options: string[] = inventoryOptionItem ? inventoryOptionItem.values : [];

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={styles.dropdown}>
      <DropdownToggle className={cx(styles.toggle, { [styles.needsSelect]: !selected })}>
        {selected || `Select ${name}`}
      </DropdownToggle>
      <DropdownMenu className={cx(styles.dropdownMenu)}>
        {options.map((option) => (
          <DropdownItem
            onClick={() => {
              onSelect(option);
            }}
            key={option}
            className={cx(styles.dropdownItem, 'pl-3')}
            disabled={disabled}
          >
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default OptionSelector;
