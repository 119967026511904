import { SagaIterator } from '@redux-saga/core';
import { push } from 'connected-react-router';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import { getSessionActiveShop } from '../../global/session/selectors';
import {
  DASHBOARD_URL,
  DUMMY_URL,
  LOGIN_AS_API_URL,
  LOGIN_AS_FETCH_API_URL,
} from '../../global/urls';
import { apiGet, apiPut, normalizeError } from '../../helpers/api';
import { handleFailureSaga } from '../../helpers/sagas';
import { SessionSchema, UserSchema } from '../../schemas';
import {
  loginAsFetchApiFailure,
  loginAsFetchApiRequest,
  loginAsFetchApiSuccess,
  LoginAsFetchNormalizedResponse,
  LoginAsFetchTriggerAction,
} from './actions/fetchActions';
import {
  loginAsApiFailure,
  loginAsApiRequest,
  loginAsApiSuccess,
  LoginAsNormalizedResponse,
  LoginAsTriggerAction,
} from './actions/selectActions';
import { LOGIN_AS_FETCH_TRIGGER, LOGIN_AS_TRIGGER } from './constants';

function* fetchSaga({ payload }: LoginAsFetchTriggerAction) {
  const { search } = payload;
  yield put(loginAsFetchApiRequest(search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: LOGIN_AS_FETCH_API_URL,
      query: { search: payload.search },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: LoginAsFetchNormalizedResponse = normalize(response, {
      users: [UserSchema],
    });
    yield put(loginAsFetchApiSuccess(search, normalizedResponse));
  } catch (error) {
    yield put(loginAsFetchApiFailure(search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

function* selectSaga({ payload }: LoginAsTriggerAction) {
  const { userId } = payload;
  yield put(loginAsApiRequest(userId));
  try {
    yield* initIfNeededSaga();
    const response = yield call(apiPut, LOGIN_AS_API_URL, {
      userId,
    });
    const normalizedResponse: LoginAsNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });

    /*
      This is a workaround for the following issue:
      After "login as" api succeeded, user in session data changes.
      Then, resetSessionIfNeededSaga resets all pages data, which causes
      "Login as" page to reload the page. But for all users except admin,
      it causes 403 error, which leads to redirecting to the login page.
      We're workarounding this by first changing URL to dummy one, which
      does not render/fetch any data (which may cause 403 error).
    */
    yield put(push(DUMMY_URL));

    // session cleanups
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(loginAsApiSuccess(userId, normalizedResponse));

    // redirect
    const activeShop = yield select(getSessionActiveShop);
    if (activeShop) {
      yield put(push(DASHBOARD_URL));
    }
  } catch (error) {
    yield put(loginAsApiFailure(userId, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(LOGIN_AS_FETCH_TRIGGER, fetchSaga),
    takeLatest(LOGIN_AS_TRIGGER, selectSaga),
  ]);
}
