import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import LoadingSwitch from '../../components/LoadingSwitch/LoadingSwitch';
import TableLoadingIndicator from '../../components/TableLoadingIndicator/TableLoadingIndicator';
import { getSessionActiveShop } from '../../global/session/selectors';
import { currencyToSymbol } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { ShopEntity } from '../../schemas';
import DashOrdersChart from './components/DashOrdersChart/DashOrdersChart';
import DashOrdersRevenue from './components/DashOrdersRevenue/DashOrdersRevenue';
import { fetchDashboardChartsSlice } from './slices';

interface Props {
  numMonths: number;
}

const DashboardCharts: FC<Props> = ({ numMonths }: Props) => {
  const dispatch = useDispatch();
  const loadData = () => {
    dispatch(fetchDashboardChartsSlice.actions.trigger({}));
  };

  const mainState = useSelector((state: AppState) => state.containers.dashboardCharts);
  const { ordersData, revenueData } = mainState;
  const shop: ShopEntity | null = useSelector(getSessionActiveShop);

  const { error, isLoading, loaded } = useSelector(
    (state: AppState) => state.containers.dashboardChartsLoading
  );

  const currencySymbol = currencyToSymbol(shop?.currency);

  return (
    <LoadingSwitch
      error={!!error}
      loading={isLoading}
      onLoad={loadData}
      renderError={() => <span>{error}</span>}
      renderLoading={() => <TableLoadingIndicator />}
      require={ordersData.length > 0 || revenueData.length > 0 || loaded}
    >
      <Row style={{ marginBottom: '40px' }}>
        <Col lg={6}>
          <div style={{ marginBottom: '20px' }}>Orders</div>
          <div style={{ height: '200px' }}>
            <DashOrdersChart data={ordersData} numMonths={numMonths} />
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ marginBottom: '20px' }}>Revenue, {currencySymbol}</div>
          <div style={{ height: '200px' }}>
            <DashOrdersRevenue data={revenueData} numMonths={numMonths} />
          </div>
        </Col>
      </Row>
    </LoadingSwitch>
  );
};

export default DashboardCharts;
