import { NormalizedError } from '../../../types';
import {
  VendorRegistrationSubmitApiFailureAction,
  VendorRegistrationSubmitApiRequestAction,
  VendorRegistrationSubmitApiSuccessAction,
  VendorRegistrationSubmitFields,
  VendorRegistrationSubmitNormalizedResponse,
  VendorRegistrationSubmitTriggerAction,
} from '../actionTypes/submitActions';
import {
  VENDOR_REGISTRATION_SUBMIT_API_FAILURE,
  VENDOR_REGISTRATION_SUBMIT_API_REQUEST,
  VENDOR_REGISTRATION_SUBMIT_API_SUCCESS,
  VENDOR_REGISTRATION_SUBMIT_TRIGGER,
} from '../constants';

export const vendorRegistrationSubmitTrigger = (
  invitationCode: string,
  fields: VendorRegistrationSubmitFields,
  successCallback: () => void,
  failureCallback: (error: NormalizedError) => void
): VendorRegistrationSubmitTriggerAction => ({
  type: VENDOR_REGISTRATION_SUBMIT_TRIGGER,
  payload: { failureCallback, fields, invitationCode, successCallback },
});

export const vendorRegistrationSubmitApiRequest = (
  invitationCode: string,
  fields: VendorRegistrationSubmitFields
): VendorRegistrationSubmitApiRequestAction => ({
  type: VENDOR_REGISTRATION_SUBMIT_API_REQUEST,
  payload: { fields, invitationCode },
});

export const vendorRegistrationSubmitApiSuccess = (
  invitationCode: string,
  fields: VendorRegistrationSubmitFields,
  response: VendorRegistrationSubmitNormalizedResponse
): VendorRegistrationSubmitApiSuccessAction => ({
  type: VENDOR_REGISTRATION_SUBMIT_API_SUCCESS,
  payload: { fields, invitationCode, response },
});

export const vendorRegistrationSubmitApiFailure = (
  invitationCode: string,
  fields: VendorRegistrationSubmitFields,
  error: NormalizedError
): VendorRegistrationSubmitApiFailureAction => ({
  type: VENDOR_REGISTRATION_SUBMIT_API_FAILURE,
  payload: { error, fields, invitationCode },
});
