import cx from 'classnames';
import { push } from 'connected-react-router';
import React, { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import tableStyles from '../../global/styles/falconTable.module.scss';
import { PRODUCT_URL } from '../../global/urls';
import { InventoryItemEntity } from '../../schemas';
import styles from './formatters.module.scss';

export interface FormatterExtraData {
  dispatch: Dispatch;
}

export const nameFormatter = (name: string, row: InventoryItemEntity): ReactElement => {
  return (
    <div className={styles.name}>
      {name ? (
        <Link
          className={styles.name__link}
          to={generatePath(PRODUCT_URL, { productId: row.productId })}
        >
          <div className={cx(tableStyles.textDark, styles.name__link__name)}>
            {row.productName}
            {row.variantName && <span> | {row.variantName}</span>}
          </div>
          <div className={cx(tableStyles.textSecondary)}>
            Stock <span className={styles.name__red}>{row.inventory}</span> items left
          </div>
        </Link>
      ) : null}
    </div>
  );
};

export const actionFormatter = (
  _0: number,
  row: InventoryItemEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { dispatch } = formatExtraData;
  const handleClick = () => {
    const productUrl = generatePath(PRODUCT_URL, { productId: row.productId });
    dispatch(push(productUrl));
  };
  return (
    <SecondaryButton onClick={handleClick} size="small">
      Restock
    </SecondaryButton>
  );
};
