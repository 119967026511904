import { createReducer } from '@reduxjs/toolkit';
import { fetchModerationListSlice } from '../slices/moderationSlice';
import { ModerationListQueryState } from '../types';

export const initialQueryState: ModerationListQueryState = {
  count: 0,
  moderationRequestIds: null,
};

export const moderationListQueryReducer = createReducer(initialQueryState, (builder) => {
  builder.addCase(fetchModerationListSlice.actions.success, (draft, action) => {
    draft.count = action.payload.response.result.count;
    draft.moderationRequestIds = action.payload.response.result.moderationList;
  });
});
