import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import {
  reloadPageEffect,
  reloadPageSaga,
  resetSessionIfNeededSaga,
} from '../../global/session/sagas';
import { COLLECTION_API_URL, COLLECTIONS_API_URL } from '../../global/urls';
import { apiGet, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import { CollectionSchema, SessionSchema } from '../../schemas';
import { CollectionsFetchNormalizedResponse, CollectionsFetchTriggerAction } from './actionTypes';
import {
  collectionsFetchApiFailure,
  collectionsFetchApiRequest,
  collectionsFetchApiSuccess,
} from './actions';
import { COLLECTIONS_FETCH_TRIGGER } from './constants';
import submitCollectionsEditSlice, {
  collectionDeleteSlice,
  collectionsChangeTypeSlice,
} from './slices';

function* fetchSaga({ payload }: CollectionsFetchTriggerAction) {
  const { search, sort, start } = payload;
  yield put(collectionsFetchApiRequest(sort, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: COLLECTIONS_API_URL,
      query: { search: payload.search, sort: payload.sort, start: payload.start },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: CollectionsFetchNormalizedResponse = normalize(response, {
      collections: [CollectionSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(collectionsFetchApiSuccess(sort, start, search, normalizedResponse));
  } catch (error) {
    yield put(collectionsFetchApiFailure(sort, start, search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

const submitCollectionsEditSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: COLLECTION_API_URL,
  queryPayload: (payload) => ({
    handle: payload.handle,
    managedBy: payload.managedBy,
    title: payload.title,
  }),
  urlParams: (payload) => ({
    collectionId: payload.collectionId,
  }),
  responseSchema: {
    collection: CollectionSchema,
    session: SessionSchema,
  },
  actions: submitCollectionsEditSlice.actions,
});

function* submitCollectionsSuccessSaga(
  action: ReturnType<typeof submitCollectionsEditSlice.actions.success>
) {
  if (!action.payload.request.collectionId) yield reloadPageEffect();
}

const collectionsChangeTypeSaga = makeApiRequestSaga({
  method: 'PATCH',
  baseUrl: COLLECTIONS_API_URL,
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: collectionsChangeTypeSlice.actions,
});

const collectionDeleteSaga = makeApiRequestSaga({
  method: 'DELETE',
  baseUrl: COLLECTION_API_URL,
  urlParams: (payload) => ({
    collectionId: payload.id,
  }),
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: collectionDeleteSlice.actions,
});

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(COLLECTIONS_FETCH_TRIGGER, fetchSaga),
    takeLatest(submitCollectionsEditSlice.actions.trigger.type, submitCollectionsEditSaga),
    takeLatest(submitCollectionsEditSlice.actions.success.type, submitCollectionsSuccessSaga),
    takeLatest(collectionsChangeTypeSlice.actions.trigger.type, collectionsChangeTypeSaga),
    takeLatest(collectionsChangeTypeSlice.actions.success.type, reloadPageSaga),
    takeLatest(collectionDeleteSlice.actions.trigger.type, collectionDeleteSaga),
    takeLatest(collectionDeleteSlice.actions.success.type, reloadPageSaga),
  ]);
}
