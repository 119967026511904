import cx from 'classnames';
import React, { FC } from 'react';
import styles from './BurgerMenuIcon.module.scss';

type Props = {
  sideMenuCollapsed: boolean;
  toggleSideMenu: () => void;
};

const BurgerMenuIcon: FC<Props> = ({ sideMenuCollapsed, toggleSideMenu }: Props) => (
  <button type="button" onClick={toggleSideMenu} className={cx(styles.navBarIcon)}>
    <span className={cx(styles.navbarToggleIcon, 'd-flex flex-column  justify-content-center')}>
      <span
        className={cx(
          styles.navbarToggleLine,
          sideMenuCollapsed ? styles.navbarToggleLineCollapsed : styles.navbarToggleLineOpen
        )}
      />
      <span
        className={cx(
          styles.navbarToggleLine,
          sideMenuCollapsed ? styles.navbarToggleLineCollapsedCenter : styles.navbarToggleLineOpen
        )}
      />
      <span
        className={cx(
          styles.navbarToggleLine,
          sideMenuCollapsed ? styles.navbarToggleLineCollapsed : styles.navbarToggleLineOpen
        )}
      />
    </span>
  </button>
);

export default BurgerMenuIcon;
