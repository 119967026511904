import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTableSelectRows } from '../../../../components/FalconTable/selectRow';
import GridPageWrapper from '../../../../components/GridPageWrapper/GridPageWrapper';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { WarehouseProductEntity } from '../../../../schemas';
import { PAGE_SIZE, PAGING_DISPLAYED_RANGE } from '../../constants';
import useWarehousePageData from '../../hooks/useWarehousePageData';
import { WarehouseType } from '../../types';
import { pushProductsUrl, replaceProductsUrl } from '../../utils';
import WarehouseGrid from '../WarehouseGrid/WarehouseGrid';
import WarehouseRightControls from '../WarehouseRightControls/WarehouseRightControls';

const WarehouseMainContent: FC = () => {
  useScrollToTop();

  const {
    correctLocation,
    count,
    error,
    inStock,
    isLoading,
    loaded,
    products,
    productsFetchTrigger,
    search,
    sortOrder,
    start,
    user,
    allProductIds,
    productsType,
  } = useWarehousePageData();

  const [inStockOnly, setInStockOnly] = useState(!!inStock);
  const [shippingIncludedOnly, setShippingIncludedOnly] = useState(false);

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: replaceProductsUrl,
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushProductsUrl,
    searchInput,
    sortOrder,
    start,
  });

  const dispatch = useDispatch();

  const handleTypeChange = (newType: WarehouseType) => {
    if (newType !== productsType) {
      pushProductsUrl(dispatch, {
        search: searchInput,
        type: newType,
      });
    }
  };

  const handleInStockChange = (inStockChecked: boolean) => {
    setInStockOnly(inStockChecked);
    pushProductsUrl(dispatch, {
      search: searchInput,
      type: productsType,
      inStock: inStockChecked,
    });
  };

  const handleShippingIncludedChange = (shippingIncludedChecked: boolean) => {
    // TBD XXX
    setShippingIncludedOnly(shippingIncludedChecked);
  };

  const { selected, selectRow, selectAll } = useTableSelectRows<WarehouseProductEntity>(
    allProductIds
  );

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <GridPageWrapper
      title="ShopNET"
      // leftControls={
      //   <WarehouseLeftControls
      //     total={allProductIds.length}
      //     numItems={products?.length}
      //     selectedProducts={selected}
      //     selectAll={selectAll}
      //   />
      // }
      rightControls={
        <WarehouseRightControls
          inStockOnly={inStockOnly}
          onInStockChange={handleInStockChange}
          onShippingIncludedChange={handleShippingIncludedChange}
          onTypeChange={handleTypeChange}
          productsType={productsType}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          shippingIncludedOnly={shippingIncludedOnly}
          user={user}
        />
      }
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGING_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={productsFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(products && products.length > 0) || loaded || !correctLocation}
      >
        {products && products.length > 0 ? (
          <>
            <WarehouseGrid
              products={products}
              pageSize={PAGE_SIZE}
              sortOrder={sortOrder}
              selectRow={selectRow}
            />
            {/* <WarehouseTable */}
            {/*   products={products} */}
            {/*   pageSize={PAGE_SIZE} */}
            {/*   sortOrder={sortOrder} */}
            {/*   selectRow={selectRow} */}
            {/* /> */}
          </>
        ) : (
          <TableNoResults search={search} text="There are no products in the warehouse." />
        )}
      </LoadingSwitch>
    </GridPageWrapper>
  );
};

export default WarehouseMainContent;
