import cx from 'classnames';
import React, { FC } from 'react';
import { BeatLoader } from 'react-spinners';
import styles from './TableLoadingIndicator.module.scss';

const TableLoadingIndicator: FC = () => (
  <div
    className={cx(
      'align-items-center d-flex flex-column justify-content-center',
      styles.TableLoadingIndicator
    )}
  >
    <div className={cx('my-2')}>
      <BeatLoader color="#ff8848" />
    </div>
  </div>
);

export default TableLoadingIndicator;
