import { Field } from 'formik';
import React, { FC } from 'react';
import { Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';

const VendorProfileContracts: FC = () => {
  return (
    <Card className="h-lg-100 mb-3 p-3">
      <h4 className="sss-form-title">
        Contract <CustomTooltip text="?" />
      </h4>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Signer Full Name</Label>
            <Field as={Input} disabled name="contractSignerFullName" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Signer Position</Label>
            <Field as={Input} disabled name="contractSignerPosition" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">E-mail</Label>
            <Field as={Input} disabled name="contractSignerEmail" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Phone</Label>
            <Field as={Input} disabled name="contractSignerPhone" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Signed at</Label>
            <Field as={Input} disabled name="contractSignedAt" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6} />
      </Row>
    </Card>
  );
};

export default VendorProfileContracts;
