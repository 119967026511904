import toast from '../../global/utils/toast';
import { NormalizedError } from '../../types';

const ERROR_CODES: { [K: string]: string } = {
  contract_hash_mismatch: 'Contract hash mismatch.',
  existing_display_name: 'Vendor with this display name already exists.',
  existing_email: 'User with this e-mail already exists.',
  invalid_invitation_code: 'Invalid invitation code.',
  logged_in_user_changed: 'Logged in user changed during the registration process.',
  no_user_vendor_relationship: 'No user-vendor relationship.',
  required_value_missing: 'Required value missing.',
  user_must_be_vendor: 'User must be a vendor.',
  validation_error: 'Validation error.',
  vendor_already_registered: 'Vendor already registered.',
};

// eslint-disable-next-line import/prefer-default-export
export const handleSubmitFailure = (error: NormalizedError): void => {
  const errorDescription = ERROR_CODES[error.json?.code];
  if (errorDescription) {
    toast.error(errorDescription);
  } else {
    toast.error('An error occured.');
  }
};
