import { useParams } from 'react-router-dom';

interface Params {
  collectionId: string;
}

interface CollectionParams {
  collectionId: number;
}

const useCollectionParams = (): CollectionParams => {
  const params = useParams<Params>();
  const collectionId = parseInt(params.collectionId, 10);
  return {
    collectionId,
  };
};

export default useCollectionParams;
