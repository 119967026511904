import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTableSelectRows } from '../../../../components/FalconTable/selectRow';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { BriefProductEntity } from '../../../../schemas';
import { PAGE_SIZE, PAGING_DISPLAYED_RANGE } from '../../constants';
import useProductsPageData from '../../hooks/useProductsPageData';
import { ProductsType } from '../../types';
import { pushProductsUrl, replaceProductsUrl } from '../../utils';
import ProductsLeftControls from '../ProductsLeftControls/ProductsLeftControls';
import ProductsRightControls from '../ProductsRightControls/ProductsRightControls';
import ProductsTable from '../ProductsTable/ProductsTable';

const ProductsMainContent: FC = () => {
  useScrollToTop();

  const {
    correctLocation,
    count,
    error,
    isLoading,
    loaded,
    products,
    productsFetchTrigger,
    search,
    sortOrder,
    start,
    user,
    allProductIds,
    productsType,
  } = useProductsPageData();

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: replaceProductsUrl,
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushProductsUrl,
    searchInput,
    sortOrder,
    start,
  });

  const dispatch = useDispatch();

  const handleTypeChange = (newType: ProductsType) => {
    if (newType !== productsType) {
      pushProductsUrl(dispatch, {
        search: searchInput,
        type: newType,
      });
    }
  };

  const { selected, selectRow, selectAll } = useTableSelectRows<BriefProductEntity>(allProductIds);

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Products"
      leftControls={
        <ProductsLeftControls
          total={allProductIds.length}
          numItems={products?.length}
          selectedProducts={selected}
          selectAll={selectAll}
        />
      }
      rightControls={
        <ProductsRightControls
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          user={user}
          productsType={productsType}
          onTypeChange={handleTypeChange}
        />
      }
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGING_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={productsFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(products && products.length > 0) || loaded || !correctLocation}
      >
        {products && products.length > 0 ? (
          <ProductsTable
            products={products}
            pageSize={PAGE_SIZE}
            sortOrder={sortOrder}
            selectRow={selectRow}
          />
        ) : (
          <TableNoResults search={search} text="You don't have any products." />
        )}
      </LoadingSwitch>
    </TablePageWrapper>
  );
};

export default ProductsMainContent;
