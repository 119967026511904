import { faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Input } from 'reactstrap';
import ButtonIcon from '../../../../components/ButtonIcon/ButtonIcon';
import CustomDumbCheckbox from '../../../../components/CustomDumbCheckbox/CustomDumbCheckbox';
import { getSessionActiveShop } from '../../../../global/session/selectors';
import { PRODUCT_NEW_URL } from '../../../../global/urls';
import { isShopNetShop } from '../../../../global/utils/shopnet';
import { UserEntity, UserRole } from '../../../../schemas';
import { WarehouseType } from '../../types';
import WarehouseTypeDropdown from '../WarehouseTypeDropdown/WarehouseTypeDropdown';
import styles from './WarehouseRightControls.module.scss';

type Props = {
  inStockOnly: boolean;
  shippingIncludedOnly: boolean;
  searchInput: string;
  setSearchInput: (newValue: string) => void;
  user: UserEntity | null;
  productsType: WarehouseType;
  onTypeChange: (type: WarehouseType) => void;
  onInStockChange: (inStockOnly: boolean) => void;
  onShippingIncludedChange: (shippingIncludedOnly: boolean) => void;
};

const WarehouseRightControls: FC<Props> = ({
  inStockOnly,
  searchInput,
  setSearchInput,
  user,
  productsType,
  onTypeChange,
  onInStockChange,
  onShippingIncludedChange,
  shippingIncludedOnly,
}: Props) => {
  const activeShop = useSelector(getSessionActiveShop);
  const isShopNet = isShopNetShop(activeShop);

  const handleInStockChange = () => {
    onInStockChange(!inStockOnly);
  };

  const handleShippingIncludedChange = () => {
    onShippingIncludedChange(!shippingIncludedOnly);
  };

  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <div className="mr-2">
          <CustomDumbCheckbox checked={inStockOnly} onClick={handleInStockChange} />
        </div>
        <span className="mr-3 user-select-none">In stock</span>

        {/* <div className="mr-2"> */}
        {/*   <CustomDumbCheckbox */}
        {/*     checked={shippingIncludedOnly} */}
        {/*     onClick={handleShippingIncludedChange} */}
        {/*   /> */}
        {/* </div> */}
        {/* <span className="mr-2 user-select-none">Shipping included</span> */}

        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />

        <WarehouseTypeDropdown
          className="mr-2"
          isShopNet={isShopNet}
          onChange={onTypeChange}
          warehouseType={productsType}
        />
        {user?.role === UserRole.Vendor && (
          <Link to={PRODUCT_NEW_URL}>
            <ButtonIcon
              icon={faPlus}
              className={cx(styles.headerBtn, styles.btnShadow, 'sss-primary mx-2')}
            >
              Add Product
            </ButtonIcon>
          </Link>
        )}
        {/* <ButtonIcon */}
        {/*   icon={faExternalLinkAlt} */}
        {/*   transform="shrink-3 down-2 rotate-180" */}
        {/*   color="falcon-default" */}
        {/*   size="sm" */}
        {/*   className={cx('mx-2', styles.headerBtn, styles.headerBtnGray)} */}
        {/* > */}
        {/*   Import */}
        {/* </ButtonIcon> */}
        {/* <ButtonIcon */}
        {/*   icon={faExternalLinkAlt} */}
        {/*   transform="shrink-3 down-2" */}
        {/*   color="falcon-default" */}
        {/*   size="sm" */}
        {/*   className={cx('mx-2', styles.headerBtn, styles.headerBtnGray)} */}
        {/* > */}
        {/*   Export */}
        {/* </ButtonIcon> */}
      </Col>
    </>
  );
};

export default WarehouseRightControls;
