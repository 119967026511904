import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { ChangeEvent, FC, FocusEvent, MouseEvent } from 'react';
import styles from './CustomRadio.module.scss';

type Props = {
  checked: boolean;
  disabled?: boolean;
  id?: string;
  name: string;
  onBlur?: (e: FocusEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent) => void;
  value?: string;
};

const CustomRadio: FC<Props> = ({
  checked,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  onClick,
  value,
}: Props) => {
  return (
    <div
      className={cx(
        'position-relative d-flex justify-content-center align-items-center',
        styles.wrapper,
        {
          [styles.wrapperChecked]: checked,
          [styles.wrapperDisabled]: disabled,
        }
      )}
    >
      <input
        checked={checked}
        className="invisible"
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        type="radio"
        value={value}
      />
      {checked && (
        <div className={cx(styles.checkIcon)}>
          <FontAwesomeIcon icon={faCircle} />
        </div>
      )}
    </div>
  );
};

export default CustomRadio;
