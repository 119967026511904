/* eslint-disable  @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomInput, Input } from 'reactstrap';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import MultipleInput from '../../../../components/MultipleInput/MultipleInput';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import VendorStatusBadge from '../../../../components/VendorStatusBadge/VendorStatusBadge';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { cleanNullableInt, formatPrice } from '../../../../helpers/utils';
import { ShopEntity, VendorEntity, VendorStatus } from '../../../../schemas';
import { vendorsSubmitTrigger } from '../../actions/submit.actions';
import { isSubmittingVendors } from '../../selectors';
import { FormItem, FormValues, VendorsSubmitValues, VendorsType } from '../../types';
import VendorsActionsDropdown from '../VendorsActionsDropdown/VendorsActionsDropdown';
import styles from './formatters.module.scss';

const actionFieldName = (id: number) => `vendors.${id}.action`;
const moderationFieldName = (id: number) => `vendors.${id}.moderation`;
const enquiriesFieldName = (id: number) => `vendors.${id}.enquiriesAllowed`;
const commissionFieldName = (id: number) => `vendors.${id}.commission`;
const maxProductsFieldName = (id: number) => `vendors.${id}.maxProducts`;
const tagsFieldName = (id: number) => `vendors.${id}.tags`;

export interface FormatterExtraData {
  shop: ShopEntity | null;
  type: VendorsType;
}

export const nameFormatter = ({ name, code }: { name: string; code: string }): ReactElement => (
  <span className={cx(tableStyles.textDark, styles.name)}>
    {name} ({code})
  </span>
);

export const imageFormatter = (logoUrl: string): ReactElement => (
  <CenterCropImg alt="logo" className="rounded" height={32} src={logoUrl} width={32} />
);

// eslint-disable-next-line react/no-unused-prop-types
type RowFieldProps = FieldAttributes<any> & { row: VendorEntity; vendorsType?: VendorsType };

export const SwitchField = (props: RowFieldProps): ReactElement | null => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row, vendorsType } = props;
  const rowValues = values.vendors[row.id];
  if (!rowValues) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    const newValue = event.target.checked;
    const vendorId = row.id;
    const [fieldName] = field.name.split('.').slice(-1);
    dispatch(vendorsSubmitTrigger(vendorId, { [fieldName]: newValue }));
  };

  return (
    <CustomInput
      {...field}
      className={cx('custom-sss-switch')}
      checked={field.value}
      disabled={vendorsType === VendorsType.Inactive || vendorsType === VendorsType.Invited}
      id={field.name}
      onChange={handleChange}
      type="switch"
    />
  );
};

const CommissionField = (props: RowFieldProps) => {
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row, vendorsType } = props;
  const rowValues = values.vendors[row.id];
  if (!rowValues) {
    return null;
  }
  const value = field.value || '';
  return (
    <Input
      {...field}
      bsSize="sm"
      disabled={vendorsType === VendorsType.Inactive || vendorsType === VendorsType.Invited}
      max={100}
      min={0}
      placeholder="Enter value"
      step={0.1}
      style={{ width: '5em' }}
      type="number"
      value={value}
    />
  );
};

const MaxProductsField = (props: RowFieldProps) => {
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row, vendorsType } = props;
  const rowValues = values.vendors[row.id];
  if (!rowValues) {
    return null;
  }
  const value = field.value || '';
  return (
    <Input
      {...field}
      bsSize="sm"
      className={cx(tableStyles.customInput)}
      disabled={vendorsType === VendorsType.Inactive || vendorsType === VendorsType.Invited}
      min={0}
      placeholder={vendorsType === VendorsType.Active ? 'Enter value' : ''}
      step={1}
      type="number"
      value={value}
    />
  );
};

const TagsField = (props: RowFieldProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row, vendorsType } = props;
  const rowValues = values.vendors[row.id];
  if (!rowValues) {
    return null;
  }
  const tags = (field.value as string[]) || [];
  const addTag = (tag: string) => {
    setFieldValue(field.name, [...tags, tag]);
  };
  const removeTag = (tag: string) => {
    setFieldValue(
      field.name,
      tags.filter((value) => value !== tag)
    );
  };

  return (
    <MultipleInput
      {...field}
      addTag={addTag}
      disabled={vendorsType === VendorsType.Inactive || vendorsType === VendorsType.Invited}
      placeholder={vendorsType === VendorsType.Active ? 'Type to add' : ''}
      removeTag={removeTag}
      submittedValues={tags}
    />
  );
};

const shouldShowSave = (row: VendorEntity, rowValues: FormItem): boolean => {
  const oldCommission = row.commission;
  const oldMaxProducts = row.maxProducts;
  const oldTags = row.tags;
  const newCommission = rowValues.commission;
  const newMaxProducts = rowValues.maxProducts;
  const newTags = rowValues.tags;

  const areSame =
    oldCommission === newCommission &&
    oldMaxProducts === newMaxProducts &&
    JSON.stringify(oldTags) === JSON.stringify(newTags);

  return !areSame;
};

const cleanRowValues = (rowValues: FormItem): VendorsSubmitValues => {
  return {
    ...rowValues,
    maxProducts: cleanNullableInt(rowValues.maxProducts),
  };
};

const ActionField = (props: RowFieldProps) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(isSubmittingVendors);
  const { values } = useFormikContext<FormValues>();
  const { row, vendorsType } = props;

  const vendorId = row.id;
  const rowValues = values.vendors[vendorId];
  if (!rowValues) {
    return null;
  }

  if (!shouldShowSave(row, rowValues)) {
    return (
      <VendorsActionsDropdown
        component="item"
        selected={[row.id]}
        vendor={props.row}
        vendorsType={vendorsType}
      />
    );
  }

  return (
    <SecondaryButton
      disabled={isSubmitting}
      onClick={() => dispatch(vendorsSubmitTrigger(vendorId, cleanRowValues(rowValues)))}
      size="small"
    >
      Save
    </SecondaryButton>
  );
};

export const moderationFormatter = (
  moderated: boolean,
  row: VendorEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement =>
  row.isShopNet ? (
    <></>
  ) : (
    <SwitchField name={moderationFieldName(row.id)} row={row} vendorsType={formatExtraData.type} />
  );

export const enquiriesFormatter = (enquiries: boolean, row: VendorEntity): ReactElement => (
  <SwitchField name={enquiriesFieldName(row.id)} row={row} />
);

export const commissionFormatter = (
  commission: number,
  row: VendorEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement =>
  row.isShopNet ? (
    <></>
  ) : (
    <CommissionField
      name={commissionFieldName(row.id)}
      row={row}
      vendorsType={formatExtraData.type}
    />
  );

export const maxProductsFormatter = (
  maxProducts: number,
  row: VendorEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement =>
  row.isShopNet ? (
    <></>
  ) : (
    <MaxProductsField
      name={maxProductsFieldName(row.id)}
      row={row}
      vendorsType={formatExtraData.type}
    />
  );

export const tagsFormatter = (
  tags: string[],
  row: VendorEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement =>
  row.isShopNet ? (
    <></>
  ) : (
    <TagsField name={tagsFieldName(row.id)} row={row} vendorsType={formatExtraData.type} />
  );

export const numProductsFormatter = (
  numPublishedProducts: number,
  row: VendorEntity
): ReactElement => {
  return (
    <span className={cx(tableStyles.textDark)}>
      {numPublishedProducts}{' '}
      {row.numPendingProducts ? (
        <span className={cx(tableStyles.textSecondary, 'font-weight-400')}>
          ({row.numPendingProducts})
        </span>
      ) : null}
    </span>
  );
};

export const paymentFeeFormatter = (
  _0: number,
  row: VendorEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { shop } = formatExtraData;
  if (!shop || row.isShopNet) {
    return <></>;
  }
  const currency = shop?.currency;
  return (
    <span className={cx(tableStyles.textSecondary)}>
      {`${shop.transactionPercentFee || shop.shopifyPercentFee || 0}% + ${formatPrice(
        shop.transactionFixedFee || shop.shopifyFixedFee || 0,
        currency
      )}`}
    </span>
  );
};

export const revenueFormatter = (
  revenue: number,
  row: VendorEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (revenue === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return <span className={cx(tableStyles.textDark)}>{formatPrice(revenue, currency)}</span>;
};

export const statusFormatter = (status: VendorStatus): ReactElement => {
  return <VendorStatusBadge status={status} />;
};

export const actionsFormatter = (
  _1: number,
  row: VendorEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => (
  <ActionField name={actionFieldName(row.id)} row={row} vendorsType={formatExtraData.type} />
);
