import { Action } from 'redux';
import {
  PRODUCT_ADD_UPLOADED_IMAGE,
  PRODUCT_REMOVE_UPLOADED_IMAGE,
  PRODUCT_SET_UPLOADED_IMAGES,
} from '../constants';
import { ImageItem } from '../types';

// action types

export interface ProductAddUploadedImageAction extends Action {
  type: typeof PRODUCT_ADD_UPLOADED_IMAGE;
  payload: {
    productId: number;
    image: ImageItem;
  };
}

export interface ProductRemoveUploadedImageAction extends Action {
  type: typeof PRODUCT_REMOVE_UPLOADED_IMAGE;
  payload: {
    productId: number;
    id: string;
  };
}

export interface ProductSetUploadedImagesAction extends Action {
  type: typeof PRODUCT_SET_UPLOADED_IMAGES;
  payload: {
    productId: number;
    images: ImageItem[];
  };
}

// action creators

export const productAddUploadedImage = (
  productId: number,
  image: ImageItem
): ProductAddUploadedImageAction => ({
  type: PRODUCT_ADD_UPLOADED_IMAGE,
  payload: { productId, image },
});

export const productRemoveUploadedImage = (
  productId: number,
  id: string
): ProductRemoveUploadedImageAction => ({
  type: PRODUCT_REMOVE_UPLOADED_IMAGE,
  payload: { productId, id },
});

export const productSetUploadedImages = (
  productId: number,
  images: ImageItem[]
): ProductSetUploadedImagesAction => ({
  type: PRODUCT_SET_UPLOADED_IMAGES,
  payload: { productId, images },
});
