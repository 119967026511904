import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/ordersQueryReducer';
import { initialSearchState } from './reducers/ordersSearchReducer';
import { OrdersPageState, OrdersQueryState, OrdersSearchState, OrdersSortOrder } from './types';

export const getOrdersPageState = (state: AppState): OrdersPageState => state.containers.orders;

export const getOrdersQueryState = (
  state: AppState,
  sort: OrdersSortOrder,
  start: number,
  search: string
): OrdersQueryState => {
  return getOrdersPageState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState;
};

export const getOrdersSearchState = (state: AppState, search: string): OrdersSearchState =>
  getOrdersPageState(state).searches[search] || initialSearchState;

export const getOrdersStateSelected = (state: AppState): number[] =>
  state.containers.orders.selected;
