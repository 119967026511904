import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { TFunction } from 'react-i18next';
import { OrderItemEntity, ShopEntity } from '../../../../schemas';
import { OrderItemGroup } from '../../types';
import {
  FormatterExtraData,
  imageFormatter,
  nameFormatter,
  optionsFormatter,
  priceFormatter,
  quantityFormatter,
} from './formatters';

interface Props {
  group: OrderItemGroup;
  shop: ShopEntity | null;
  t: TFunction;
}

const columns = ({
  group,
  shop,
  t,
}: Props): ColumnDescription<OrderItemEntity, FormatterExtraData>[] => [
  {
    dataField: 'image',
    formatter: imageFormatter,
    headerStyle: () => ({ paddingLeft: 0, width: '100px' } as CSSProperties),
    style: () => ({ paddingLeft: 0, userSelect: 'none' } as CSSProperties),
    text: '',
  },
  {
    dataField: 'name',
    formatExtraData: { group, shop, t },
    formatter: nameFormatter,
    text: 'Item',
  },
  {
    dataField: 'options',
    formatter: optionsFormatter,
    headerStyle: { width: '10%' },
    text: 'Variant',
  },
  {
    align: 'right',
    dataField: 'unitPrice',
    formatExtraData: { group, shop, t },
    formatter: priceFormatter,
    headerAlign: 'right',
    headerStyle: {
      width: '10%',
      whiteSpace: 'nowrap',
    },
    text: 'Unit Price',
  },
  {
    align: 'right',
    dataField: 'quantity',
    formatExtraData: { group, shop, t },
    formatter: quantityFormatter,
    headerAlign: 'right',
    headerStyle: {
      width: '15%',
    },
    text: 'QTY',
  },
  {
    align: 'right',
    dataField: 'price',
    formatExtraData: { group, shop, t },
    formatter: priceFormatter,
    headerAlign: 'right',
    headerStyle: {
      paddingRight: '40px',
      width: '15%',
    },
    style: { paddingRight: '40px' },
    text: 'Price',
  },
];

export default columns;
