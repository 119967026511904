import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { push } from 'connected-react-router';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import useToggle from '../../../../components/ModalForm/useToggle';
import { logoutTrigger } from '../../../../global/logout/actions';
import { getSessionUser } from '../../../../global/session/selectors';
import { LOGIN_URL, PROFILE_URL } from '../../../../global/urls';
import { UserRole } from '../../../../schemas';
import styles from './CogDropdown.module.scss';

interface Props {
  className?: string;
}

const CogDropdownItem: FC<DropdownItemProps> = ({ children, ...rest }: DropdownItemProps) => {
  return (
    <DropdownItem {...rest} className={cx(styles.CogDropdown__item, 'my-2')}>
      {children}
    </DropdownItem>
  );
};

const CogDropdownMenu: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getSessionUser);

  const logout = () => {
    dispatch(push(LOGIN_URL));
    dispatch(logoutTrigger());
  };

  return (
    <DropdownMenu right>
      {(user?.role === UserRole.Brand || user?.role === UserRole.Vendor) && (
        <CogDropdownItem tag={Link} to={PROFILE_URL}>
          Profile
        </CogDropdownItem>
      )}
      <CogDropdownItem onClick={logout}>Logout</CogDropdownItem>
    </DropdownMenu>
  );
};

const CogDropdown: FC<Props> = ({ className }: Props) => {
  const [dropdownOpen, toggle] = useToggle();

  return (
    <Dropdown className={className} inNavbar isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className={cx(styles.CogDropdown__toggle)} nav>
        <FontAwesomeIcon icon={faCog} size="lg" />
      </DropdownToggle>
      <CogDropdownMenu />
    </Dropdown>
  );
};

export default CogDropdown;
