export const skuWithoutVendorPrefix = (
  fullSku: string | null,
  vendorCode: string | null
): string => {
  if (!fullSku) return '';
  if (vendorCode && fullSku.startsWith(vendorCode)) {
    return fullSku.slice(vendorCode.length);
  }
  return fullSku;
};

export const skuWithVendorPrefix = (shortSku: string, vendorCode: string | null): string => {
  if (!shortSku) return '';
  if (!vendorCode) return shortSku;
  return vendorCode + shortSku;
};

export const isShopNetProduct1 = (productId: number) => {
  return productId === 29818; // XXX TBD
};

export const isShopNetProduct2 = (productVersionId: number) => {
  return productVersionId === 54097; // XXX TBD
};
