import produce from 'immer';
import { Reducer } from 'redux';
import { NormalizedError } from '../../types';
import { LoginApiFailureAction, LoginApiRequestAction, LoginApiSuccessAction } from './actions';
import { LOGIN_API_FAILURE, LOGIN_API_REQUEST, LOGIN_API_SUCCESS } from './constants';

export type LoginPageState = {
  error: NormalizedError | null;
};

const initialState: LoginPageState = {
  error: null,
};

type LoginPageActions = LoginApiFailureAction | LoginApiRequestAction | LoginApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const loginPageReducer: Reducer<LoginPageState, LoginPageActions> = (
  state = initialState,
  action: LoginPageActions
): LoginPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN_API_REQUEST: {
        draft.error = null;
        break;
      }

      case LOGIN_API_SUCCESS: {
        break;
      }

      case LOGIN_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
