import { createReducer } from '@reduxjs/toolkit';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { RESET_SESSION } from '../../../global/session/constants';
import { makeQueryKey } from '../../../helpers/utils';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import { fetchModerationListSlice } from '../slices/moderationSlice';
import { ModerationListPageState } from '../types';
import { moderationListQueryReducer } from './moderationListQueryReducer';

const initialState: ModerationListPageState = {
  queries: {},
  selected: [],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetState = (draft: ModerationListPageState, action: unknown) => {
  return initialState;
};

const moderationListPageReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchModerationListSlice.actions.success, (draft, action) => {
    const key = makeQueryKey(
      action.payload.request.sort,
      action.payload.request.start,
      action.payload.request.search
    );
    draft.queries[key] = moderationListQueryReducer(draft.queries[key], action);
  });

  builder.addCase(LOGIN_API_SUCCESS, resetState);
  builder.addCase(LOGOUT_API_SUCCESS, resetState);
  builder.addCase(RESET_SESSION, resetState);
  builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
});

export default moderationListPageReducer;
