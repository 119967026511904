/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState } from 'react';

interface Props {
  error: boolean;
  loading: boolean;
  renderError: (() => React.ReactElement) | null;
  renderLoading: () => React.ReactElement | null;
  require: boolean;
  onLoad?: () => void | null;
}

const LoadingSwitch: FC<Props> = ({
  children,
  error,
  loading,
  onLoad,
  renderError,
  renderLoading,
  require,
}) => {
  const [fresh, setFresh] = useState(true);

  useEffect(() => {
    if (!require && !loading && !error && !fresh && onLoad) {
      onLoad();
    }
  }, [error, fresh, loading, onLoad, require]);

  useEffect(() => {
    if (fresh && onLoad) {
      onLoad();
      setFresh(false);
    }
  }, [fresh, onLoad]);

  if (error && renderError) {
    return renderError();
  }

  if (!require && loading) {
    return renderLoading();
  }

  return <>{children}</>;
};

export default LoadingSwitch;
