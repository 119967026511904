import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router-dom';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { reloadPageSaga, resetSessionIfNeededSaga } from '../../global/session/sagas';
import {
  VENDOR_DEACTIVATE_API_URL,
  VENDOR_INVITE_API_URL,
  VENDOR_INVITES_API_URL,
  VENDOR_REACTIVATE_API_URL,
  VENDORS_API_URL,
  VENDORS_SUBMIT_API_URL,
} from '../../global/urls';
import toast from '../../global/utils/toast';
import { apiGet, apiPost, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import { SessionSchema, VendorSchema } from '../../schemas';
import {
  vendorsFetchApiFailure,
  vendorsFetchApiRequest,
  vendorsFetchApiSuccess,
  VendorsFetchNormalizedResponse,
  VendorsFetchTriggerAction,
} from './actions/fetch.actions';
import {
  vendorsSubmitApiFailure,
  vendorsSubmitApiRequest,
  vendorsSubmitApiSuccess,
  VendorsSubmitNormalizedResponse,
  VendorsSubmitTriggerAction,
} from './actions/submit.actions';
import { VENDORS_FETCH_TRIGGER, VENDORS_SUBMIT_TRIGGER } from './constants';
import {
  DeactivateVendorRequestPayload,
  deactivateVendorSlice,
  DeleteVendorInviteRequestPayload,
  deleteVendorInviteSlice,
  inviteVendorSlice,
  ReactivateVendorRequestPayload,
  reactivateVendorSlice,
} from './slices';

function* fetchSaga({ payload }: VendorsFetchTriggerAction) {
  const { search, sort, start, type } = payload;
  yield put(vendorsFetchApiRequest(type, sort, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: VENDORS_API_URL,
      query: {
        search: payload.search,
        sort: payload.sort,
        start: payload.start,
        type: payload.type,
      },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: VendorsFetchNormalizedResponse = normalize(response, {
      vendors: [VendorSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(vendorsFetchApiSuccess(type, sort, start, search, normalizedResponse));
  } catch (error) {
    yield put(vendorsFetchApiFailure(type, sort, start, search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

function* submitSaga({ payload }: VendorsSubmitTriggerAction) {
  const { vendorId, values } = payload;
  yield put(vendorsSubmitApiRequest(vendorId, values));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(VENDORS_SUBMIT_API_URL, { vendorId }),
      query: {},
    });
    const response = yield call(apiPost, url, values, 'PATCH');
    const normalizedResponse: VendorsSubmitNormalizedResponse = normalize(response, {
      vendor: VendorSchema,
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(vendorsSubmitApiSuccess(vendorId, values, normalizedResponse));
  } catch (error) {
    const normalizedError = normalizeError(error);
    yield put(vendorsSubmitApiFailure(vendorId, values, normalizedError));
    if (error.json?.code === 'vendor_renaming_is_blocked') {
      yield call(
        toast.warning,
        'Vendor cannot be renamed while products are being republished or moderated.'
      );
    } else {
      yield* handleFailureSaga(error);
    }
  }
}

const inviteVendorSaga = makeApiRequestSaga({
  baseUrl: VENDOR_INVITES_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: { session: SessionSchema },
  actions: inviteVendorSlice.actions,
});

type InviteVendorSuccessAction = ReturnType<typeof inviteVendorSlice.actions.success>;

function* successInviteVendorSaga(action: InviteVendorSuccessAction) {
  const numInvites = action.payload.request.invites.length;
  yield call(toast.success, `${numInvites} vendor${numInvites > 1 ? 's' : ''} invited.`);
}

const deleteVendorInviteSaga = makeApiRequestSaga({
  baseUrl: VENDOR_INVITE_API_URL,
  urlParams: ({ inviteId }: DeleteVendorInviteRequestPayload) => ({ inviteId }),
  method: 'DELETE',
  queryPayload: (payload) => payload,
  responseSchema: { session: SessionSchema },
  actions: deleteVendorInviteSlice.actions,
});

function* successDeleteVendorInviteSaga() {
  yield call(toast.success, 'Vendor invite removed.');
}

const deactivateVendorSaga = makeApiRequestSaga({
  actions: deactivateVendorSlice.actions,
  baseUrl: VENDOR_DEACTIVATE_API_URL,
  method: 'POST',
  responseSchema: { session: SessionSchema },
  urlParams: (payload: DeactivateVendorRequestPayload) => payload,
});

function* deactivateVendorConfirmationSaga() {
  yield call(toast.success, 'Vendor has been deactivated.');
  yield* reloadPageSaga();
}

const reactivateVendorSaga = makeApiRequestSaga({
  actions: reactivateVendorSlice.actions,
  baseUrl: VENDOR_REACTIVATE_API_URL,
  method: 'POST',
  responseSchema: { session: SessionSchema },
  urlParams: (payload: ReactivateVendorRequestPayload) => payload,
});

function* reactivateVendorConfirmationSaga() {
  yield call(toast.success, 'Vendor has been reactivated.');
  yield* reloadPageSaga();
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(VENDORS_FETCH_TRIGGER, fetchSaga),
    takeLatest(VENDORS_SUBMIT_TRIGGER, submitSaga),
    takeLatest(inviteVendorSlice.actions.trigger.type, inviteVendorSaga),
    takeLatest(inviteVendorSlice.actions.success.type, successInviteVendorSaga),
    takeLatest(deleteVendorInviteSlice.actions.trigger.type, deleteVendorInviteSaga),
    takeLatest(deleteVendorInviteSlice.actions.success.type, successDeleteVendorInviteSaga),
    takeLatest(deactivateVendorSlice.actions.trigger.type, deactivateVendorSaga),
    takeLatest(deactivateVendorSlice.actions.success.type, deactivateVendorConfirmationSaga),
    takeLatest(reactivateVendorSlice.actions.trigger.type, reactivateVendorSaga),
    takeLatest(reactivateVendorSlice.actions.success.type, reactivateVendorConfirmationSaga),
  ]);
}
