import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gotoLogin } from '../../containers/Login/actions';
import { isAuthenticated } from '../../helpers/utils';
import { UserEntity } from '../../schemas';
import { initTrigger } from '../init/actions';
import { getRouterState } from '../selectors';
import { getSessionUser, isSessionInitialized } from '../session/selectors';

interface RequireAuth {
  isInitialized: boolean;
  user: UserEntity | null;
}

const useRequireAuth = (): RequireAuth => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const isInitialized = useSelector(isSessionInitialized);
  const user = useSelector(getSessionUser);

  useEffect(() => {
    if (!isInitialized) dispatch(initTrigger());
  }, [dispatch, isInitialized]);

  useEffect(() => {
    if (isInitialized && !isAuthenticated(user)) {
      dispatch(gotoLogin(location.pathname));
    }
  }, [dispatch, isInitialized, location.pathname, user]);

  return {
    isInitialized,
    user,
  };
};

export default useRequireAuth;
