import cx from 'classnames';
import React, { FC, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ShopItem, { ShopItemCaret } from '../../../../components/ShopItem/ShopItem';
import { ShopEntity } from '../../../../schemas';
import styles from './ShopSelectorDropdown.module.scss';

interface Props {
  activeShop: ShopEntity | null;
  className?: string;
  onSelectShop: (shopId: number) => void;
  shops: ShopEntity[];
}

const ShopSelectorDropdown: FC<Props> = ({ activeShop, className, onSelectShop, shops }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openable = shops && shops.length > 1;

  const handleToggle = () => {
    if (openable) {
      setIsOpen(!isOpen);
    }
  };

  let caret: ShopItemCaret = ShopItemCaret.None;
  if (openable) {
    caret = isOpen ? ShopItemCaret.Up : ShopItemCaret.Down;
  }

  return (
    <Dropdown
      className={cx(styles.ShopSelectorDropdown, className)}
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
    >
      <DropdownToggle
        className={cx(
          styles.ShopSelectorDropdown__toggle,
          openable && styles['ShopSelectorDropdown__toggle--openable'],
          !openable && styles['ShopSelectorDropdown__toggle--unopenable'],
          isOpen && styles['ShopSelectorDropdown__toggle--open']
        )}
        nav
      >
        <ShopItem shop={activeShop} caret={caret} />
      </DropdownToggle>
      {openable && (
        <DropdownMenu className={styles.ShopSelectorDropdown__menu}>
          {shops &&
            shops
              .filter((shop) => shop.id !== activeShop?.id)
              .map((shop) => {
                return (
                  <DropdownItem
                    key={shop.id}
                    className={styles.ShopSelectorDropdown__item}
                    onClick={() => onSelectShop(shop.id)}
                  >
                    <ShopItem shop={shop} />
                  </DropdownItem>
                );
              })}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default ShopSelectorDropdown;
