import { useDispatch, useSelector } from 'react-redux';
import { toggleSideMenuCollapsed } from '../general/actions';
import { getGeneralSideMenuCollapsed } from '../general/selectors';

type GeneralStateData = { sideMenuCollapsed: boolean; toggleSideMenu: () => void };

const useGeneralStateData = (): GeneralStateData => {
  const dispatch = useDispatch();
  const sideMenuCollapsed = useSelector(getGeneralSideMenuCollapsed);

  return {
    sideMenuCollapsed,
    toggleSideMenu: () => dispatch(toggleSideMenuCollapsed()),
  };
};

export default useGeneralStateData;
