import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import ProductsMainContent from './components/ProductsMainContent/ProductsMainContent';

const Products: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <ProductsMainContent key={key} />;
};

export default Products;
