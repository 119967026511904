import cx from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getSessionActiveShop,
  getSessionUser,
  getSessionVendor,
} from '../../global/session/selectors';
import { PROFILE_URL } from '../../global/urls';
import { UserRole } from '../../schemas';
import styles from './UserName.module.scss';

interface UserNameProps {
  className?: string;
}

const UserName: FC<UserNameProps> = ({ className }: UserNameProps) => {
  const shop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);
  const vendor = useSelector(getSessionVendor);

  let username;
  if (user?.role === UserRole.Brand) {
    username = shop?.name;
  } else if (user?.role === UserRole.Vendor) {
    username = vendor?.name;
  } else {
    username = user?.username;
  }

  return user?.role === UserRole.Brand || user?.role === UserRole.Vendor ? (
    <Link title={username} className={cx(styles.UserName, className)} to={PROFILE_URL}>
      {username}
    </Link>
  ) : (
    <div title={username} className={cx(styles.UserName, className)}>
      {username}
    </div>
  );
};

export default UserName;
