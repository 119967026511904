import cx from 'classnames';
import React, { CSSProperties, FC, useMemo } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import styles from '../../../../global/styles/falconTable.module.scss';
import { SortOrderDirection } from '../../../../global/types';
import { GiftCardCodeEntity, ShopEntity } from '../../../../schemas';
import { mapSortCodesOrder, mapTableSortCodes } from '../../helpers';
import useOnCodesSortChange from '../../hooks/useOnCodesSortChange';
import { GiftCardCodesColumn, GiftCardCodesSortOrder } from '../../types';
import columns from './columns';

type Props = {
  giftCardCodes: GiftCardCodeEntity[] | null;
  pageSize: number;
  shop: ShopEntity | null;
  sortOrder: GiftCardCodesSortOrder;
};

const GiftCardCodesTable: FC<Props> = ({ giftCardCodes, pageSize, shop, sortOrder }: Props) => {
  const onSortChange = useOnCodesSortChange();

  const rowStyleFormat = (row: GiftCardCodeEntity, rowIdx: number): CSSProperties => {
    return { backgroundColor: rowIdx % 2 === 0 ? '' : '#f9fafd' };
  };

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<GiftCardCodeEntity>
  ): void => {
    onSortChange(
      mapSortCodesOrder(
        newState.sortField as GiftCardCodesColumn,
        newState.sortOrder as SortOrderDirection
      )
    );
  };

  const bootstrapTableSort = useMemo(() => mapTableSortCodes(sortOrder), [sortOrder]);

  const data = useMemo(
    () =>
      giftCardCodes !== null
        ? giftCardCodes
        : Array.from({ length: pageSize }, (_, id) => ({ id })),
    [giftCardCodes, pageSize]
  );

  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      classes={cx(styles.table, 'table align-middle')}
      columns={columns(shop)}
      data={data}
      headerClasses={cx(styles.header, 'fs-1')}
      headerWrapperClasses={cx(styles.headerWrapper)}
      keyField="id"
      rowClasses={cx(styles.row, 'py-2 align-middle')}
      rowStyle={(row, idx) => rowStyleFormat(row, idx)}
      sort={bootstrapTableSort}
      onTableChange={handleTableChange}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
    />
  );
};

export default GiftCardCodesTable;
