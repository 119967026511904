import cx from 'classnames';
import React, { FC } from 'react';
import { WarehouseProductStatus } from '../../../../schemas';
import styles from './WarehouseProductStatusBadge.module.scss';

interface BadgeConf {
  styleColor: string;
  text: string;
}

const PRODUCT_STATUSES: { [key in WarehouseProductStatus]?: BadgeConf } = {
  [WarehouseProductStatus.Approved]: {
    styleColor: 'Moderated',
    text: 'Approved',
  },
  [WarehouseProductStatus.Rejected]: {
    styleColor: 'Rejected',
    text: 'Rejected',
  },
  [WarehouseProductStatus.Available]: {
    styleColor: 'Draft',
    text: 'Available',
  },
};

const NO_INFO_STATUS: BadgeConf = {
  styleColor: 'NoInfo',
  text: 'No Info',
};

type Props = {
  className?: string;
  status: WarehouseProductStatus | null;
};

const WarehouseProductStatusBadge: FC<Props> = ({ className, status }: Props) => {
  if (!status) {
    return <span />;
  }

  const conf = PRODUCT_STATUSES[status] || NO_INFO_STATUS;
  const styleColor = `status${conf.styleColor}`;
  const { text } = conf;

  return (
    <span
      className={cx(
        'rounded-pill text-center text-capitalize py-1 px-3 fw-bold',
        styles.statusText,
        styles[styleColor],
        className
      )}
    >
      {text}
    </span>
  );
};

export default WarehouseProductStatusBadge;
