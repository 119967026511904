import { Action } from 'redux';
import { COLLECTION_BUMP_PRODUCTS, COLLECTION_SHOW_MORE_PRODUCTS } from '../constants';

// action types

interface CollectionPayload {
  collectionId: number;
}

export interface CollectionBumpProductsAction extends Action {
  type: typeof COLLECTION_BUMP_PRODUCTS;
  payload: CollectionPayload & {
    addedIds: number[];
    removedIds: number[];
  };
}

export interface CollectionShowMoreProductsAction extends Action {
  type: typeof COLLECTION_SHOW_MORE_PRODUCTS;
  payload: CollectionPayload;
}

// action creators

export const collectionBumpProducts = (
  collectionId: number,
  addedIds: number[],
  removedIds: number[]
): CollectionBumpProductsAction => ({
  type: COLLECTION_BUMP_PRODUCTS,
  payload: {
    collectionId,
    addedIds,
    removedIds,
  },
});

export const collectionShowMoreProducts = (
  collectionId: number
): CollectionShowMoreProductsAction => ({
  type: COLLECTION_SHOW_MORE_PRODUCTS,
  payload: {
    collectionId,
  },
});
