import cx from 'classnames';
import React, { ReactElement } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import useToggle from '../ModalForm/useToggle';
import styles from './DropdownSelect.module.scss';

export type DropdownSelectOption<K> = [K, string];

type Props<K> = {
  options: DropdownSelectOption<K>[];
  selected: K;
  onChange: (key: K) => void;
  disabled?: boolean;
  className?: string;
};

function DropdownSelect<K extends string>({
  options,
  selected,
  onChange,
  disabled = false,
  className,
}: Props<K>): ReactElement {
  const [dropdownOpen, toggle] = useToggle();

  const currentOption = options.find((option) => option[0] === selected) || options[0];

  return (
    <ButtonDropdown
      className={cx(styles.wrapper, 'position-relative', className)}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle caret className={cx(styles.headerToggleBtn)} disabled={disabled}>
        {currentOption[1]}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option) => (
          <DropdownItem
            className={cx(styles.dropdownMenuItem, 'my-2')}
            key={option[0]}
            onClick={() => onChange(option[0])}
          >
            {option[1]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default DropdownSelect;
