import React, { FC, useCallback } from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import SortableGallery from '../../../../components/SortableGallery/SortableGallery';
import useProductImagesData from '../../hooks/useProductImagesData';

const ProductImages: FC = () => {
  const { images, addImage, removeImage, setImages } = useProductImagesData();
  const onAddImage = useCallback(
    (image) => {
      addImage(image);
    },
    [addImage]
  );
  return (
    <Card className="h-lg-100 mb-3">
      <FalconCardHeader title="Product Images" />
      <CardBody className="py-3">
        <SortableGallery
          images={images}
          onAddImage={onAddImage}
          setImages={setImages}
          removeImage={removeImage}
        />
      </CardBody>
    </Card>
  );
};

export default ProductImages;
