import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { WarehouseSortOrder } from '../types';
import { replaceProductsUrl } from '../utils';
import useWarehouseQueryParams from './useWarehouseQueryParams';

const useOnSortChange = (): ((newSort: WarehouseSortOrder) => void) => {
  const dispatch = useDispatch();
  const queryParams = useWarehouseQueryParams();

  return useCallback(
    (newSort: WarehouseSortOrder) => {
      if (newSort !== queryParams.sortOrder) {
        replaceProductsUrl(dispatch, { ...queryParams, sort: newSort });
      }
    },
    [dispatch, queryParams]
  );
};

export default useOnSortChange;
