import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { CustomersSortOrder } from './types';

export const CUSTOMERS_FETCH_TRIGGER = 'CUSTOMERS_FETCH_TRIGGER';
export const CUSTOMERS_FETCH_API_PREFIX = 'CUSTOMERS_FETCH_API';
export const CUSTOMERS_FETCH_API_REQUEST = 'CUSTOMERS_FETCH_API_REQUEST';
export const CUSTOMERS_FETCH_API_SUCCESS = 'CUSTOMERS_FETCH_API_SUCCESS';
export const CUSTOMERS_FETCH_API_FAILURE = 'CUSTOMERS_FETCH_API_FAILURE';

export const defaultSortOrder: CustomersSortOrder = CustomersSortOrder.name;

export const PAGE_SIZE = 15;
export const PAGINATION_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
