import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CustomInput } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import ButtonIcon from '../../../../components/ButtonIcon/ButtonIcon';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import { getSessionActiveShop } from '../../../../global/session/selectors';
import { ShopEntity } from '../../../../schemas';
import { NormalizedError } from '../../../../types';
import useProductOptionsData from '../../hooks/useProductOptionsData';
import useProductVariantsData from '../../hooks/useProductVariantsData';
import ProductOptions from '../ProductOptions/ProductOptions';
import ProductVariant from '../ProductVariant/ProductVariant';

interface Props {
  error: NormalizedError | null;
  disabled?: boolean;
  isGiftCardProduct: boolean;
  isShopNetProduct: boolean;
}

const ProductVariants: FC<Props> = ({
  error,
  disabled,
  isGiftCardProduct,
  isShopNetProduct,
}: Props) => {
  const {
    isMultipleVariants,
    toggleMultipleVariants,
    variants,
    variantActions,
    vendorCode,
  } = useProductVariantsData();
  const { availableOptions, optionActions } = useProductOptionsData();
  const shop: ShopEntity | null = useSelector(getSessionActiveShop);

  const addNewOption = (): void => {
    switch (availableOptions.length) {
      case 0:
        optionActions.addOption('Colour', ['White']);
        break;
      case 1:
        optionActions.addOption('Size', ['M']);
        break;
      case 2:
        optionActions.addOption('Material', ['Wood']);
        break;
      default:
    }
  };

  const activeOptions = isMultipleVariants ? availableOptions : [];
  const activeVariants = isMultipleVariants ? variants : variants.slice(0, 1);

  return (
    <Card className="h-lg-100 mb-3">
      <FalconCardHeader className="bg-white" title="Variants and Pricing">
        <CustomInput
          type="switch"
          label="Multiple Variants"
          id="product-multiple-variants"
          onClick={toggleMultipleVariants}
          onChange={() => {}}
          checked={isMultipleVariants}
          className="custom-sss-switch sss-gray-1100"
          disabled={disabled || isGiftCardProduct}
        />
      </FalconCardHeader>
      <CardBody className="pt-2 pb-2">
        {activeOptions.length > 0 && (
          <ProductOptions options={availableOptions} {...optionActions} disabled={disabled} />
        )}
        {isMultipleVariants && activeOptions.length < 3 && (
          <ButtonIcon
            color="sss-primary-link"
            size="sm"
            icon={faPlus}
            transform="shrink-3"
            onClick={addNewOption}
            disabled={disabled}
          >
            Add Option
          </ButtonIcon>
        )}
        <div className="mt-4" />
        {activeVariants.map((variant, idx) => (
          <ProductVariant
            {...variant}
            {...variantActions}
            availableOptions={activeOptions}
            disabled={disabled}
            error={error}
            index={idx}
            isGiftCardProduct={isGiftCardProduct}
            isShopNetProduct={isShopNetProduct}
            isSingleVariant={!isMultipleVariants}
            key={variant.id}
            shop={shop}
            vendorCode={vendorCode}
          />
        ))}
        {isMultipleVariants && (
          <ButtonIcon
            color="sss-primary-link"
            size="sm"
            icon={faPlus}
            transform="shrink-3"
            onClick={() => variantActions.addVariant(uuidv4())}
            disabled={disabled}
          >
            Add Variant
          </ButtonIcon>
        )}
      </CardBody>
    </Card>
  );
};

export default ProductVariants;
