import cx from 'classnames';
import React, { FC, SetStateAction, useState } from 'react';
import CenteredWindow from '../../../../components/CenteredWindow/CenteredWindow';
import { handleSubmitFailure } from '../../errors';
import useVendorRegistrationData from '../../hooks/useVendorRegistrationData';
import { VendorRegistrationScreen } from '../../types';
import BusinessDetails from '../BusinessDetails/BusinessDetails';
import Contract from '../Contract/Contract';
import LoginDetails from '../LoginDetails/LoginDetails';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import styles from './VendorSignUp.module.scss';

interface Props {
  screen: VendorRegistrationScreen;
  setScreen: React.Dispatch<SetStateAction<VendorRegistrationScreen>>;
}

const VendorSignUp: FC<Props> = ({ screen, setScreen }: Props) => {
  const { shopLogoUrl, onRegistrationSubmit } = useVendorRegistrationData();
  const [inputHasError, setInputHasError] = useState(false);

  const successCallback = () => setScreen(VendorRegistrationScreen.SUCCESS);
  const handleRegistrationSubmit = () => onRegistrationSubmit(successCallback, handleSubmitFailure);

  return (
    <CenteredWindow
      vertically={
        screen === VendorRegistrationScreen.SUCCESS ||
        screen === VendorRegistrationScreen.LOGIN_DETAILS
      }
    >
      <div className="mb-5">
        <div className="d-flex justify-content-center my-3">
          <img src={shopLogoUrl} alt="shop-logo" className={styles.logo} />
        </div>
        <div className={cx('bg-white rounded', styles.wrapper)}>
          {screen === VendorRegistrationScreen.BUSINESS_DETAILS && (
            <BusinessDetails
              inputHasError={inputHasError}
              setInputHasError={setInputHasError}
              setScreen={setScreen}
            />
          )}
          {screen === VendorRegistrationScreen.CONTRACT && (
            <Contract
              inputHasError={inputHasError}
              onSubmit={handleRegistrationSubmit}
              setInputHasError={setInputHasError}
              setScreen={setScreen}
            />
          )}
          {screen === VendorRegistrationScreen.LOGIN_DETAILS && (
            <LoginDetails
              inputHasError={inputHasError}
              onSubmit={handleRegistrationSubmit}
              setInputHasError={setInputHasError}
              setScreen={setScreen}
            />
          )}
          {screen === VendorRegistrationScreen.SUCCESS && <SuccessMessage />}
        </div>
      </div>
    </CenteredWindow>
  );
};

export default VendorSignUp;
