// Description
export const PRODUCT_SET_PRODUCT_DESCRIPTION = 'PRODUCT_SET_PRODUCT_DESCRIPTION';
export const PRODUCT_SET_PRODUCT_TITLE = 'PRODUCT_SET_PRODUCT_TITLE';

// Tags
export const PRODUCT_ADD_TAG = 'PRODUCT_ADD_TAG';
export const PRODUCT_REMOVE_TAG = 'PRODUCT_REMOVE_TAG';

// Seo
export const PRODUCT_SET_SEO_DESCRIPTION = 'PRODUCT_SET_SEO_DESCRIPTION';
export const PRODUCT_SET_SEO_TITLE = 'PRODUCT_SET_SEO_TITLE';
export const PRODUCT_SET_SEO_URL = 'PRODUCT_SET_SEO_URL';

// Properties
export const PRODUCT_SET_PROPERTIES_AGE_RESTRICTED = 'PRODUCT_SET_PROPERTIES_AGE_RESTRICTED';
export const PRODUCT_SET_PROPERTIES_CUSTOMIZABLE = 'PRODUCT_SET_PROPERTIES_CUSTOMIZABLE';
export const PRODUCT_SET_PROPERTIES_ENQUIRY = 'PRODUCT_SET_PROPERTIES_ENQUIRY';

// Images
export const PRODUCT_ADD_UPLOADED_IMAGE = 'PRODUCT_ADD_UPLOADED_IMAGE';
export const PRODUCT_REMOVE_UPLOADED_IMAGE = 'PRODUCT_REMOVE_UPLOADED_IMAGE';
export const PRODUCT_SET_UPLOADED_IMAGES = 'PRODUCT_SET_UPLOADED_IMAGES';

// Variants
export const PRODUCT_ADD_NEW_VARIANT = 'PRODUCT_ADD_NEW_VARIANT';
export const PRODUCT_CLONE_VARIANT = 'PRODUCT_CLONE_VARIANT';
export const PRODUCT_REMOVE_VARIANT = 'PRODUCT_REMOVE_VARIANT';
export const PRODUCT_SET_PURCHASE_NOT_IN_STOCK = 'PRODUCT_SET_PURCHASE_NOT_IN_STOCK';
export const PRODUCT_SET_TRACK_INVENTORY = 'PRODUCT_SET_TRACK_INVENTORY';
export const PRODUCT_SET_VARIANT_BARCODE = 'PRODUCT_SET_VARIANT_BARCODE';
export const PRODUCT_SET_VARIANT_COMPARE_TO_PRICE = 'PRODUCT_SET_VARIANT_COMPARE_TO_PRICE';
export const PRODUCT_SET_VARIANT_IMAGE = 'PRODUCT_SET_VARIANT_IMAGE';
export const PRODUCT_SET_VARIANT_OPTION = 'PRODUCT_SET_VARIANT_OPTION';
export const PRODUCT_SET_VARIANT_PRICE = 'PRODUCT_SET_VARIANT_PRICE';
export const PRODUCT_SET_VARIANT_QUANTITY = 'PRODUCT_SET_VARIANT_QUANTITY';
export const PRODUCT_SET_VARIANT_SKU = 'PRODUCT_SET_VARIANT_SKU';
export const PRODUCT_TOGGLE_MULTIPLE_VARIANTS = 'PRODUCT_TOGGLE_MULTIPLE_VARIANTS';
export const PRODUCT_UNDO_REMOVE_VARIANT = 'PRODUCT_UNDO_REMOVE_VARIANT';

export const PRODUCT_SET_VARIANT_PROFIT = 'PRODUCT_SET_VARIANT_PROFIT';
export const PRODUCT_SET_VARIANT_PROFIT_PCT = 'PRODUCT_SET_VARIANT_PROFIT_PCT';

// Options
export const PRODUCT_ADD_OPTION = 'PRODUCT_ADD_OPTION';
export const PRODUCT_ADD_OPTION_VALUE = 'PRODUCT_ADD_OPTION_VALUE';
export const PRODUCT_REMOVE_OPTION = 'PRODUCT_REMOVE_OPTION';
export const PRODUCT_REMOVE_OPTION_VALUE = 'PRODUCT_REMOVE_OPTION_VALUE';
export const PRODUCT_SET_OPTION = 'PRODUCT_SET_OPTION';

// Stores
export const PRODUCT_ADD_INIT_STORE_DATA = 'PRODUCT_ADD_INIT_STORE_DATA';
export const PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM = 'PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM';
export const PRODUCT_SET_SHIPPING_BASE_FEE = 'PRODUCT_SET_SHIPPING_BASE_FEE';
export const PRODUCT_TOGGLE_COLLECTION_ITEM = 'PRODUCT_TOGGLE_COLLECTION_ITEM';
export const PRODUCT_TOGGLE_PHYSICAL_PRODUCT = 'PRODUCT_TOGGLE_PHYSICAL_PRODUCT';
export const PRODUCT_TOGGLE_SHIPPING_AVAILABLE = 'PRODUCT_TOGGLE_SHIPPING_AVAILABLE';
export const PRODUCT_TOGGLE_SHIPPING_FREE = 'PRODUCT_TOGGLE_SHIPPING_FREE';

// Fetch
export const PRODUCT_FETCH_API = 'PRODUCT_FETCH_API'; // prefix
export const PRODUCT_FETCH_API_FAILURE = 'PRODUCT_FETCH_API_FAILURE';
export const PRODUCT_FETCH_API_REQUEST = 'PRODUCT_FETCH_API_REQUEST';
export const PRODUCT_FETCH_API_SUCCESS = 'PRODUCT_FETCH_API_SUCCESS';
export const PRODUCT_FETCH_TRIGGER = 'PRODUCT_FETCH_TRIGGER';

// Submit
export const PRODUCT_SUBMIT_API = 'PRODUCT_SUBMIT_API'; // prefix
export const PRODUCT_SUBMIT_API_FAILURE = 'PRODUCT_SUBMIT_API_FAILURE';
export const PRODUCT_SUBMIT_API_REQUEST = 'PRODUCT_SUBMIT_API_REQUEST';
export const PRODUCT_SUBMIT_API_SUCCESS = 'PRODUCT_SUBMIT_API_SUCCESS';
export const PRODUCT_SUBMIT_TRIGGER = 'PRODUCT_SUBMIT_TRIGGER';
