import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGING_DISPLAYED_RANGE } from '../../constants';
import useShippingPoliciesData from '../../hooks/useShippingPoliciesData';
import { pushShippingPoliciesUrl } from '../../utils';
import ShippingPoliciesRightControls from '../ShippingPoliciesRightControls/ShippingPoliciesRightControls';
import ShippingPoliciesTable from '../ShippingPoliciesTable/ShippingPoliciesTable';

const ShippingPoliciesMainContent: FC = () => {
  useScrollToTop();

  const {
    correctLocation,
    count,
    error,
    formItems,
    isLoading,
    loaded,
    shippingPolicies,
    shippingPoliciesFetchTrigger,
    sortOrder,
    start,
    user,
  } = useShippingPoliciesData();

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushShippingPoliciesUrl,
    sortOrder,
    start,
  });

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Shipping Policies"
      rightControls={<ShippingPoliciesRightControls user={user} />}
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGING_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={shippingPoliciesFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(shippingPolicies && shippingPolicies.length > 0) || loaded || !correctLocation}
      >
        {shippingPolicies && shippingPolicies.length > 0 ? (
          <ShippingPoliciesTable
            formItems={formItems}
            shippingPolicies={shippingPolicies}
            pageSize={PAGE_SIZE}
            sortOrder={sortOrder}
          />
        ) : (
          <TableNoResults />
        )}
      </LoadingSwitch>
    </TablePageWrapper>
  );
};

export default ShippingPoliciesMainContent;
