import { ColumnDescription } from 'react-bootstrap-table-next';
import { loaderFormatter } from '../../../../helpers/formatters';
import {
  actionsFormatter,
  allowOversellingFormatter,
  imageFormatter,
  inventoryFormatter,
  productNameFormatter,
  trackInventoryFormatter,
  variantNameFormatter,
} from './formatters';

export const columns = (): ColumnDescription[] => {
  return [
    {
      dataField: 'image',
      formatter: imageFormatter,
      headerStyle: () => ({ width: '50px' }),
      text: '',
    },
    {
      dataField: 'productName',
      formatter: productNameFormatter,
      text: 'Product',
    },
    {
      dataField: 'variantName',
      formatter: variantNameFormatter,
      text: 'Variant',
    },
    {
      dataField: 'trackInventory',
      formatter: trackInventoryFormatter,
      headerStyle: () => ({ width: '8%' }),
      text: 'Track inventory',
    },
    {
      dataField: 'allowOverselling',
      formatter: allowOversellingFormatter,
      headerStyle: () => ({ width: '8%' }),
      text: 'Allow overselling',
    },
    {
      dataField: 'inventory',
      formatter: inventoryFormatter,
      headerStyle: () => ({ width: '10%' }),
      text: 'Available',
    },
    {
      dataField: '',
      text: '',
      formatter: actionsFormatter,
      headerStyle: () => ({ width: '12%' }),
    },
  ];
};

export const loadingColumns = (): ColumnDescription[] =>
  columns().map((column) => ({
    ...column,
    formatter: loaderFormatter,
  }));
