const orgNameRegex = /(?!^\d+$)(?!^.*_.*$)^[\p{L}\d,.\-\s]*[\p{L}\d][\p{L}\d,.\-\s]*[\p{L}\d][\p{L}\d,.&\-\s]*$/u;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const uppercaseRegex = /[A-Z]/g;
const lowercaseRegex = /[a-z]/g;
const numberRegex = /[0-9]/g;
const numberOrSymbolRegex = /[*.!@#$%^&(){}[\]:";'<>,.?/~`_+-=|\\ ]/g;
const lettersAndNumbersRegex = /^[0-9a-zA-Z]+$/;
const lettersAndNumbersRegexOrEmpty = /^[0-9a-zA-Z]*$/;

export const validateOrgName = (value: string): boolean => Boolean(value.match(orgNameRegex));

export const validateValueRequired = (value: string): boolean => value.length > 0;

export const validateEmail = (value: string): boolean =>
  emailRegex.test(String(value).toLowerCase());

export const validateHasUppercase = (value: string): boolean =>
  Boolean(value.match(uppercaseRegex));

export const validateHasLowercase = (value: string): boolean =>
  Boolean(value.match(lowercaseRegex));

export const validateHasNumbers = (value: string): boolean => Boolean(value.match(numberRegex));

export const validateHasNumberOrSymbol = (value: string): boolean =>
  Boolean(value.match(numberOrSymbolRegex));

export const validateHasLength = (value: string, requiredLength: number): boolean =>
  value.length >= requiredLength;

export const validateHasOnlyLettersAndNumbers = (value: string): boolean =>
  Boolean(value.match(lettersAndNumbersRegex));

export const validateHasOnlyLettersAndNumbersOrEmpty = (value: string): boolean =>
  Boolean(value.match(lettersAndNumbersRegexOrEmpty));
