import { Action } from 'redux';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  EntitiesState,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  PRODUCT_FETCH_API_FAILURE,
  PRODUCT_FETCH_API_REQUEST,
  PRODUCT_FETCH_API_SUCCESS,
  PRODUCT_FETCH_TRIGGER,
} from '../constants';

// action types

interface ProductFetchPayload {
  productId: number;
  isGiftCardProduct: boolean;
}

export interface ProductFetchTriggerAction extends Action {
  type: typeof PRODUCT_FETCH_TRIGGER;
  payload: ProductFetchPayload;
}

export interface ProductFetchApiRequestAction extends APIRequestAction {
  type: typeof PRODUCT_FETCH_API_REQUEST;
  payload: ProductFetchPayload;
}

export interface ProductFetchNormalizedResponse extends NormalizedResponse {
  readonly entities: EntitiesState;
  readonly result: {
    allCollections: number[];
    collectionIds: number[];
    isDisabledVendor: boolean;
    productVersion: number;
    shippingPolicies: number[];
    shippingTypes: number[];
    showUnpublish: boolean;
    status: string;
    vendorCode: string | null;
    enquiriesAllowed: boolean;
  };
}

export interface ProductFetchApiSuccessAction extends APISuccessAction {
  type: typeof PRODUCT_FETCH_API_SUCCESS;
  payload: ProductFetchPayload & {
    response: ProductFetchNormalizedResponse;
  };
}

export interface ProductFetchApiFailureAction extends APIFailureAction {
  type: typeof PRODUCT_FETCH_API_FAILURE;
  payload: ProductFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const productFetchTrigger = (
  productId: number,
  isGiftCardProduct: boolean
): ProductFetchTriggerAction => ({
  type: PRODUCT_FETCH_TRIGGER,
  payload: {
    isGiftCardProduct,
    productId,
  },
});

export const productFetchApiRequest = (
  productId: number,
  isGiftCardProduct: boolean
): ProductFetchApiRequestAction => ({
  type: PRODUCT_FETCH_API_REQUEST,
  payload: { isGiftCardProduct, productId },
});

export const productFetchApiSuccess = (
  productId: number,
  isGiftCardProduct: boolean,
  response: ProductFetchNormalizedResponse
): ProductFetchApiSuccessAction => ({
  type: PRODUCT_FETCH_API_SUCCESS,
  payload: { isGiftCardProduct, productId, response },
});

export const productFetchApiFailure = (
  productId: number,
  isGiftCardProduct: boolean,
  error: NormalizedError
): ProductFetchApiFailureAction => ({
  type: PRODUCT_FETCH_API_FAILURE,
  payload: { error, isGiftCardProduct, productId },
});
