import cx from 'classnames';
import React, { FC, ReactNode } from 'react';
import { CardHeader, Col, Row } from 'reactstrap';
import styles from './FalconCardHeader.module.scss';

type TitleProps = {
  breakPoint?: string;
  titleTag?: string;
  className?: string;
  children: ReactNode;
};

const Title: FC<TitleProps> = ({
  titleTag = 'h5',
  className,
  breakPoint,
  children,
}: TitleProps) => {
  const TitleTag = titleTag as keyof JSX.IntrinsicElements;
  return (
    <TitleTag
      className={cx(
        {
          'mb-0': !breakPoint,
          [`mb-${breakPoint}-0`]: !!breakPoint,
        },
        className
      )}
    >
      {children}
    </TitleTag>
  );
};

type Props = {
  children?: ReactNode;
  title: ReactNode;
  light?: boolean;
  white?: boolean;
  breakPoint?: string;
  titleTag?: string;
  titleClass?: string;
  className?: string;
  border?: boolean;
};

const FalconCardHeader: FC<Props> = ({
  title,
  light = false,
  white = true,
  titleTag,
  titleClass,
  className,
  breakPoint,
  children,
  border = true,
}: Props) => (
  <CardHeader className={cx({ 'bg-light': light, 'bg-white': white }, styles.header, className)}>
    {children ? (
      <Row className="align-items-center">
        <Col>
          <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
            {title}
          </Title>
        </Col>
        <Col
          {...{ [breakPoint || 'xs']: 'auto' }}
          className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
        >
          {children}
        </Col>
      </Row>
    ) : (
      <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
        {title}
      </Title>
    )}
    {border && <div className={cx(styles.bottomBorder, 'pt-3')} />}
  </CardHeader>
);

export default FalconCardHeader;
