import React, { FC, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import { formatDateFancy, formatPrice } from '../../../../helpers/utils';
import { OrderEntity, ShopEntity } from '../../../../schemas';
import { GroupItemsAndQuantities, OrderItemGroup } from '../../types';
import OrderAddress from '../OrderAddress/OrderAddress';
import OrderRefundModal from '../OrderRefundModal/OrderRefundModal';
import styles from './OrderHeader.module.scss';

interface Props {
  order: OrderEntity;
  orderName: string;
  orderRefundTrigger: (
    groupItems: GroupItemsAndQuantities,
    reason: string,
    shippingOnly: boolean
  ) => void;
  shop: ShopEntity | null;
}

const OrderHeader: FC<Props> = ({ order, orderName, orderRefundTrigger, shop }: Props) => {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const toggleRefundModal = () => setIsRefundModalOpen(!isRefundModalOpen);
  const openRefundModal = () => setIsRefundModalOpen(true);

  const handleRefundShipping = (reason: string) => {
    orderRefundTrigger(
      { [OrderItemGroup.Fulfilled]: [], [OrderItemGroup.Unfulfilled]: [] },
      reason,
      true
    );
  };

  const orderValue = order && order.totalPrice - order.totalTax - order.shippingPrice;
  return (
    <Card className="h-lg-100 mb-3">
      <FalconCardHeader title={`Order № ${orderName}`} className="bg-white">
        {/* <HeaderButton className="mx-2" icon={faPrint}> */}
        {/*   Print invoice */}
        {/* </HeaderButton> */}
        {/* <HeaderButton className="mx-2" icon={faPrint}> */}
        {/*   Print shipping label */}
        {/* </HeaderButton> */}
      </FalconCardHeader>
      <CardBody className="py-0">
        <Row className="pb-3">
          <Col className={styles.OrderHeader__infoCol} lg={6}>
            <Row>
              <Col>
                <div className="font-weight-500 gray-700">Order Value</div>
                <div className={styles.OrderHeader__orderValue}>
                  {order && formatPrice(orderValue, shop?.currency)}
                </div>
                <div className="">Excl.tax</div>
              </Col>
              <Col>
                <div className="font-weight-500 gray-700">Created</div>
                <div>{order && formatDateFancy(order.createdAt, shop?.currency)}</div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <div className="font-weight-500 gray-700">Shipping</div>
                <div className={styles.OrderHeader__shippingPrice}>
                  {order && formatPrice(order.shippingPrice, shop?.currency)}
                </div>
                <div>{order && order.shippingTypes}</div>
                <SecondaryButton
                  className="mt-2"
                  onClick={openRefundModal}
                  size="small"
                  disabled={order?.isShippingRefunded || !order?.shippingPrice}
                >
                  Refund shipping
                </SecondaryButton>
              </Col>
              <Col>
                <div className="font-weight-500 fs-13 gray-700">Last Updated</div>
                <div>{order && formatDateFancy(order.updatedAt, shop?.currency)}</div>
              </Col>
            </Row>
          </Col>
          <Col className={styles.OrderHeader__addressCol} lg={3}>
            {order && order.shippingAddress && (
              <OrderAddress
                address1={order.shippingAddress.address1}
                address2={order.shippingAddress.address2}
                addressType="Shipping Address"
                city={order.shippingAddress.city}
                companyName={order.shippingAddress.company}
                country={order.shippingAddress.country}
                email={order.customerEmail}
                name={order.shippingAddress.name}
                phoneNumber={order.shippingAddress.phone}
                provinceCode={order.shippingAddress.provinceCode}
                zipCode={order.shippingAddress.zipCode}
              />
            )}
          </Col>
          <Col className={styles.OrderHeader__addressCol} lg={3}>
            {order && order.billingAddress && (
              <OrderAddress
                address1={order.billingAddress.address1}
                address2={order.billingAddress.address2}
                addressType="Billing Address"
                city={order.billingAddress.city}
                companyName={order.billingAddress.company}
                country={order.billingAddress.country}
                name={order.billingAddress.name}
                phoneNumber={order.billingAddress.phone}
                provinceCode={order.shippingAddress.provinceCode}
                zipCode={order.billingAddress.zipCode}
              />
            )}
          </Col>
        </Row>
      </CardBody>
      <OrderRefundModal
        isOpen={isRefundModalOpen}
        toggle={toggleRefundModal}
        onSubmit={(reason: string) => handleRefundShipping(reason)}
        type="shipping"
      />
    </Card>
  );
};

export default OrderHeader;
