import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { ProductsSortOrder } from './types';

// fetch
export const PRODUCTS_FETCH_TRIGGER = 'PRODUCTS_FETCH_TRIGGER';
export const PRODUCTS_FETCH_API_PREFIX = 'PRODUCTS_FETCH_API';
export const PRODUCTS_FETCH_API_REQUEST = 'PRODUCTS_FETCH_API_REQUEST';
export const PRODUCTS_FETCH_API_SUCCESS = 'PRODUCTS_FETCH_API_SUCCESS';
export const PRODUCTS_FETCH_API_FAILURE = 'PRODUCTS_FETCH_API_FAILURE';

export const defaultSortOrder: ProductsSortOrder = ProductsSortOrder.name;

export const PAGE_SIZE = 15;
export const PAGING_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
