import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { getSessionActiveShop, getSessionUser } from '../../../global/session/selectors';
import { SortOrderDirection } from '../../../global/types';
import sortCaretFunc from '../../../global/utils/sortCaretFunc';
import { loaderFormatter } from '../../../helpers/formatters';
import { UserRole } from '../../../schemas';
import {
  customerFormatter,
  dateFormatter,
  financialsFormatter,
  fulfillmentFormatter,
  orderNameFormatter,
  totalPriceFormatter,
  vendorFormatter,
} from '../components/OrdersTable/formatters';
import { mapSortOrder } from '../helpers';
import { OrdersOrderItem } from '../types';
import useOnSortChange from './useOnSortChange';

const useOrdersTableColumns = (): {
  columns: ColumnDescription[];
  loadingColumns: ColumnDescription[];
} => {
  const onSortChange = useOnSortChange();
  const shop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);

  const sortFunc = (columnName: OrdersOrderItem, sortDirection: SortOrderDirection) => {
    onSortChange(mapSortOrder(columnName, sortDirection));
  };

  const columns = [
    {
      dataField: OrdersOrderItem.name,
      formatter: orderNameFormatter,
      headerStyle: () => ({ paddingLeft: 0, width: '15%', whiteSpace: 'nowrap' } as CSSProperties),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      style: () => ({ paddingLeft: 0 } as CSSProperties),
      text: 'Order#',
    },
    {
      dataField: OrdersOrderItem.createdAt,
      formatExtraData: { shop },
      formatter: dateFormatter,
      headerStyle: () => ({ width: '15%', whiteSpace: 'nowrap' } as CSSProperties),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Date',
    },
    {
      dataField: OrdersOrderItem.vendorName,
      hidden: !(user && (user.role === UserRole.Admin || user.role === UserRole.Brand)),
      formatter: vendorFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Vendor',
    },
    {
      dataField: OrdersOrderItem.customerName,
      formatter: customerFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Customer',
    },
    {
      dataField: OrdersOrderItem.fulfillmentStatus,
      formatter: fulfillmentFormatter,
      headerStyle: () => ({ width: '15%', whiteSpace: 'nowrap' } as CSSProperties),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Fulfilment',
    },
    {
      dataField: OrdersOrderItem.financialStatus,
      formatter: financialsFormatter,
      headerStyle: () => ({ width: '15%', whiteSpace: 'nowrap' } as CSSProperties),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Financials',
    },
    {
      align: 'right',
      dataField: OrdersOrderItem.totalPrice,
      formatExtraData: { shop },
      formatter: totalPriceFormatter,
      headerAlign: 'right',
      headerStyle: () => ({ whiteSpace: 'nowrap', width: '5%' } as CSSProperties),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Total',
    },
  ];

  const loadingColumns = columns.map((column) => ({
    ...column,
    formatter: loaderFormatter,
  }));

  return { columns, loadingColumns };
};

export default useOrdersTableColumns;
