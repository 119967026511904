import { PayloadAction } from '@reduxjs/toolkit';
import { makeApiRequestSlice } from '../../../helpers/apiRequestSlice';
import { NormalizedResponse } from '../../../types';
import { ProductSubmitValues } from '../../Product/types';
import { ModerationListSortOrder } from '../types';

type ModerationListFetchPayload = {
  sort: ModerationListSortOrder;
  start: number;
  search: string;
};

interface ModerationListSuccessPayload {
  request: ModerationListFetchPayload;
  response: NormalizedResponse & {
    result: {
      count: number;
      moderationList: number[];
    };
  };
}

export const fetchModerationListSlice = makeApiRequestSlice<
  ModerationListFetchPayload,
  ModerationListSuccessPayload
>({
  name: 'moderation/list',
});

export interface ReviewPublishPayload {
  moderationId?: number;
}

export type ReviewPublishSuccessResponse = NormalizedResponse & {
  result: {
    moderationRequest: number;
  };
};

export type ReviewPublishApiSuccessActionPayload = {
  request: ReviewPublishPayload;
  response: ReviewPublishSuccessResponse;
};
export type ReviewPublishApiSuccessAction = PayloadAction<ReviewPublishApiSuccessActionPayload>;

export const reviewPublishSlice = makeApiRequestSlice<ReviewPublishPayload>({
  name: 'moderation/reviewPublish',
});

export type ApprovePublishPayload = {
  moderationId: number;
  productId: number;
  values: ProductSubmitValues;
};

export const approvePublishSlice = makeApiRequestSlice<ApprovePublishPayload>({
  name: 'moderation/approvePublish',
});

export interface RejectPublishPayload {
  moderationId?: number;
  message: string;
}

export const rejectPublishSlice = makeApiRequestSlice<RejectPublishPayload>({
  name: 'moderation/rejectPublish',
});
