import cx from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import styles from './TablePageWrapper.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  itemsCount: number;
  leftControls?: ReactNode;
  pagination?: ReactNode;
  rightControls?: ReactNode;
  showCount?: boolean;
  title: string;
};

const TablePageWrapper: FC<Props> = ({
  className,
  children,
  itemsCount,
  leftControls,
  pagination,
  rightControls,
  showCount = true,
  title,
}: Props) => (
  <div className={cx('bg-white shadow', styles.tableWrapper, className)}>
    <Row className="align-items-center d-flex flex-row">
      <Col className="align-items-center d-flex flex-row">
        <h5 className={cx(styles.title)}>
          {title} {showCount && itemsCount ? `(${itemsCount})` : ''}
        </h5>
        {leftControls}
      </Col>
      {rightControls}
    </Row>
    {children}
    <Col className="d-flex justify-content-center align-items-center py-3">
      {Boolean(itemsCount) && pagination}
    </Col>
  </div>
);

export default TablePageWrapper;
