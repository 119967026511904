/* eslint-disable react/button-has-type */
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { Button, ButtonProps } from 'reactstrap';

type PrimaryButtonSize = 'large' | 'small';

interface Props extends ButtonProps {
  className?: string;
  icon?: IconProp;
  iconClassName?: string;
  disabled?: boolean;
  gray?: boolean;
  negative?: boolean;
  size: PrimaryButtonSize;
}
const PrimaryButton: FC<Props> = ({
  children,
  className,
  disabled = false,
  gray = false,
  icon,
  iconClassName,
  negative = false,
  size,
  type = 'button',
  ...rest
}: Props) => {
  const btnColorClass = negative ? 'sss-primary-negative' : 'sss-primary';
  const btnClass = disabled || gray ? 'sss-primary-disabled' : btnColorClass;
  const btnSizeClass = size === 'large' ? 'sss-btn-large' : 'sss-btn-small';
  return (
    <Button
      {...rest}
      className={cx('sss-btn', btnClass, btnSizeClass, className)}
      disabled={disabled}
      type={type}
    >
      {icon && (
        <FontAwesomeIcon
          className={cx(
            {
              'mr-1': children,
            },
            iconClassName
          )}
          icon={icon}
        />
      )}
      {children}
    </Button>
  );
};

export default PrimaryButton;
