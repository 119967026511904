import { Location } from 'history';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { LocationState } from '../../../global/types';
import { defaultCodesSortOrder, defaultProductsSortOrder } from '../constants';
import { GiftCardCodesSortOrder, GiftCardProductsSortOrder } from '../types';

type GiftCardCodesQueryParams = {
  codesSortOrder: GiftCardCodesSortOrder;
  location: Location<LocationState>;
  locationState: LocationState;
  productsSortOrder: GiftCardProductsSortOrder;
  search: string;
  start: number;
};

const parseCodesSortOrder = (sort: string): GiftCardCodesSortOrder => {
  const sortOrders = Object.values(GiftCardCodesSortOrder);
  return sortOrders.includes(sort as GiftCardCodesSortOrder)
    ? (sort as GiftCardCodesSortOrder)
    : defaultCodesSortOrder;
};

const parseProductsSortOrder = (sort: string): GiftCardProductsSortOrder => {
  const sortOrders = Object.values(GiftCardProductsSortOrder);
  return sortOrders.includes(sort as GiftCardProductsSortOrder)
    ? (sort as GiftCardProductsSortOrder)
    : defaultProductsSortOrder;
};

const useGiftCardsQueryParams = (): GiftCardCodesQueryParams => {
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const start: number = parsed.start ? parseInt(parsed.start.toString(), 10) : 0;
  const search: string = parsed.search ? parsed.search.toString() : '';
  const codesSortOrder = parseCodesSortOrder(parsed.sortCodes ? parsed.sortCodes.toString() : '');
  const productsSortOrder = parseProductsSortOrder(
    parsed.sortProducts ? parsed.sortProducts.toString() : ''
  );
  const { state } = location;

  return {
    codesSortOrder,
    location,
    locationState: state,
    productsSortOrder,
    search,
    start,
  };
};

export default useGiftCardsQueryParams;
