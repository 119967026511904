import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionUser } from '../../../global/session/selectors';
import { LocationState } from '../../../global/types';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { WAREHOUSE_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { UserEntity, WarehouseProductEntity, WarehouseProductSchema } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { productsFetchTrigger } from '../actions/fetch.actions';
import { WAREHOUSE_FETCH_API_PREFIX } from '../constants';
import { getWarehouseQueryState, getWarehouseSearchState } from '../selectors';
import { WarehouseSortOrder, WarehouseType } from '../types';
import useWarehouseQueryParams from './useWarehouseQueryParams';

type WarehousePageData = {
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  inStock: boolean;
  isLoading: boolean;
  loaded: boolean;
  locationState: LocationState;
  products: WarehouseProductEntity[] | null;
  productsFetchTrigger(): void;
  search: string;
  sortOrder: WarehouseSortOrder;
  start: number;
  user: UserEntity | null;
  allProductIds: number[];
  productsType: WarehouseType;
};

const useWarehousePageData = (): WarehousePageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { inStock, locationState, search, sortOrder, start, type } = useWarehouseQueryParams();
  const correctLocation = location.pathname === WAREHOUSE_URL;
  const entities = useSelector(getEntitiesState);
  const user = useSelector(getSessionUser);
  const { count, error, loaded, productVersionIds } = useSelector((state: AppState) =>
    getWarehouseQueryState(state, sortOrder, start, search)
  );
  const { allProductIds } = useSelector((state: AppState) =>
    getWarehouseSearchState(state, search)
  );

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, WAREHOUSE_FETCH_API_PREFIX)
  );

  const products = useMemo(
    () =>
      productVersionIds !== null
        ? productVersionIds
            .map(
              (productId) =>
                denormalize(productId, WarehouseProductSchema, entities) as WarehouseProductEntity
            )
            // Remapping product version IDs to product ids
            .map((e) => ({
              ...e,
              id: e.productId,
            }))
        : null,
    [entities, productVersionIds]
  );

  return {
    correctLocation,
    count,
    dispatch,
    error,
    inStock,
    isLoading,
    loaded,
    locationState,
    products,
    productsFetchTrigger: () =>
      dispatch(productsFetchTrigger(sortOrder, start, search, type, inStock)),
    search,
    allProductIds,
    sortOrder,
    start,
    user,
    productsType: type,
  };
};

export default useWarehousePageData;
