import { denormalize } from 'normalizr';
import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import FalconCardHeader from '../../components/FalconCardHeader/FalconCardHeader';
import FalconTable from '../../components/FalconTable/FalconTable';
import LoadingSwitch from '../../components/LoadingSwitch/LoadingSwitch';
import TableLoadingIndicator from '../../components/TableLoadingIndicator/TableLoadingIndicator';
import { getEntitiesState } from '../../global/entities/selectors';
import { InventoryItemSchema } from '../../schemas';
import styles from './InventoryAlerts.module.scss';
import columns from './columns';
import { getInventoryAlertsLoadingState, getInventoryAlertsState } from './selectors';
import { fetchInventoryAlertsSlice } from './slices';

const InventoryAlerts: FC = () => {
  const dispatch = useDispatch();
  const entities = useSelector(getEntitiesState);
  const inventoryItemIds = useSelector(getInventoryAlertsState).inventoryItems;
  const { error, isLoading, loaded } = useSelector(getInventoryAlertsLoadingState);

  const data = useMemo(
    () => inventoryItemIds.map((itemId) => denormalize(itemId, InventoryItemSchema, entities)),
    [inventoryItemIds, entities]
  );

  const loadData = () => {
    dispatch(fetchInventoryAlertsSlice.actions.trigger({}));
  };

  return (
    <LoadingSwitch
      error={!!error}
      loading={isLoading}
      onLoad={loadData}
      renderError={() => <span>{error}</span>}
      renderLoading={() => <TableLoadingIndicator />}
      require={data?.length > 0 || loaded}
    >
      <Card className="pb-3">
        <FalconCardHeader border={false} className="bg-white" title="Inventory Alerts" />
        <FalconTable
          columns={columns(dispatch)}
          data={data}
          keyField="id"
          wrapperClasses={styles.InventoryAlerts__table}
        />
      </Card>
    </LoadingSwitch>
  );
};

export default InventoryAlerts;
