import { ApiSuccessResultActionPayload, makeApiRequestSlice } from '../../helpers/apiRequestSlice';

// import stock levels

export type ImportStockRequestPayload = {
  csvFileName: string;
  csvFileData: string;
};

export type ImportStockResult = {
  numImported: number;
  numSkipped: number;
};

export type ImportStockSuccessPayload = ApiSuccessResultActionPayload<
  ImportStockRequestPayload,
  ImportStockResult
>;

export const importStockSlice = makeApiRequestSlice<
  ImportStockRequestPayload,
  ImportStockSuccessPayload
>({ name: 'inventory/importStock' });
