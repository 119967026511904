import { push, replace } from 'connected-react-router';
import { stringifyUrl } from 'query-string';
import { SortOrder } from 'react-bootstrap-table-next';
import { Dispatch } from 'redux';
import { SortOrderDirection, StandardQueryParams } from '../../global/types';
import { DASHBOARD_URL } from '../../global/urls';
import { defaultSortOrder } from './constants';
import { ModerationListItem, ModerationListSortOrder } from './types';

type QueryParams = StandardQueryParams;

export const getModerationListUrl = (params: QueryParams): string => {
  const query: QueryParams = {};
  if (params.search) {
    query.search = params.search;
  }
  if (params.sort && params.sort !== defaultSortOrder) {
    query.sort = params.sort;
  }
  if (params.start) {
    query.start = params.start;
  }
  return stringifyUrl({ url: DASHBOARD_URL, query });
};

export const replaceModerationListUrl = (dispatch: Dispatch, params: QueryParams): void => {
  const url = getModerationListUrl(params);
  dispatch(replace(url, { keepState: true }));
};

export const pushModerationListUrl = (
  dispatch: Dispatch,
  params: QueryParams,
  keepState = false
): void => {
  const url = getModerationListUrl(params);
  dispatch(push(url, { keepState }));
};

export const mapTableSort = (
  sortOrder: ModerationListSortOrder
): { dataField: ModerationListSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as ModerationListSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};

export const mapSortOrder = (
  columnName: ModerationListItem,
  sortDirection: SortOrderDirection
): ModerationListSortOrder => {
  if (columnName === ModerationListItem.name) {
    return sortDirection === SortOrderDirection.asc
      ? ModerationListSortOrder.name
      : ModerationListSortOrder.nameDesc;
  }
  const columnNameDesc = `-${columnName}` as ModerationListSortOrder;
  if (columnName === ModerationListItem.image) {
    return ModerationListSortOrder.name;
  }
  return sortDirection === SortOrderDirection.asc
    ? ModerationListSortOrder[columnName]
    : columnNameDesc;
};
