import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './global/styles/index.scss';
import './global/styles/styles.scss';
import store, { history } from './init/store';
import './services/i18n';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],

    // Disable tracing, we need only errors for now
    // tracesSampleRate: 1.0,

    normalizeDepth: 10, // For Redux integration
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
