import FalconCardHeader from 'components/FalconCardHeader/FalconCardHeader';
import React, { FC } from 'react';
import { Card } from 'reactstrap';
import ProductSubmitButton from '../../../Product/components/ProductSubmitButton/ProductSubmitButton';

interface Props {
  canSave: boolean;
  displayName: string;
  isSubmitting: boolean;
  onSave: () => void;
}

const VendorProfileHeader: FC<Props> = ({ canSave, displayName, isSubmitting, onSave }: Props) => {
  return (
    <Card className="h-lg-100 mb-3">
      <FalconCardHeader border={false} className="bg-white" title={displayName}>
        <ProductSubmitButton
          canSubmit={canSave}
          isSubmitting={isSubmitting}
          label="Save"
          submit={onSave}
        />
      </FalconCardHeader>
    </Card>
  );
};

export default VendorProfileHeader;
