import React, { FC } from 'react';
import CenteredWindow from '../../components/CenteredWindow/CenteredWindow';
import MainLogo from '../../components/MainLogo/MainLogo';
import EmailSent from './components/EmailSent/EmailSent';
import EnterEmail from './components/EnterEmail/EnterEmail';
import useForgottenPasswordData from './hooks/useForgottenPasswordData';
import { ForgottenPasswordScreen } from './types';

const ForgottenPassword: FC = () => {
  const { enteredEmail, error, forgottenPasswordTrigger, screen } = useForgottenPasswordData();
  return (
    <CenteredWindow>
      <div>
        <div className="mb-4">
          <MainLogo />
        </div>
        {screen === ForgottenPasswordScreen.ENTER_EMAIL ? (
          <EnterEmail error={error} forgottenPasswordTrigger={forgottenPasswordTrigger} />
        ) : (
          <EmailSent email={enteredEmail} />
        )}
      </div>
    </CenteredWindow>
  );
};

export default ForgottenPassword;
