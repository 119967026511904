import { ApiSuccessResultActionPayload, makeApiRequestSlice } from '../../helpers/apiRequestSlice';
import { VendorBareRecord } from './types';

// add transaction

type TransactionType = 'adjustment' | 'payout';

export type AddTransactionRequestPayload = {
  type: TransactionType;
  amount: number;
  date?: string; // not required for adjustment
  memo: string;
  vendorId: number;
};

export type AddTransactionResult = {
  transaction_id: number;
};

export type AddTransactionSuccessPayload = ApiSuccessResultActionPayload<
  AddTransactionRequestPayload,
  AddTransactionResult
>;

export const addTransactionSlice = makeApiRequestSlice<
  AddTransactionRequestPayload,
  AddTransactionSuccessPayload
>({ name: 'payouts/addTransaction' });

// fetch vendors bare

type FetchVendorsBareRequestPayload = Record<string, never>;

type FetchVendorsBareResult = { vendors: VendorBareRecord[] };

export type FetchVendorsBareSuccessPayload = ApiSuccessResultActionPayload<
  FetchVendorsBareRequestPayload,
  FetchVendorsBareResult
>;

export const fetchVendorsBareSlice = makeApiRequestSlice<
  FetchVendorsBareRequestPayload,
  FetchVendorsBareSuccessPayload
>({ name: 'payouts/fetchVendorsBare' });
