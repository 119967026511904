import React, { FC } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CenteredWindow from '../../../../components/CenteredWindow/CenteredWindow';
import { isSuperStoreSite } from '../../../../helpers/utils';

const VendorSignIn: FC = () => {
  return (
    <CenteredWindow>
      <div className="bg-white rounded p-5">
        <h3>
          {isSuperStoreSite()
            ? 'Welcome to the SuperStore Systems Portal'
            : 'Welcome to the ShopCentre Portal'}
        </h3>
        <Form>
          <FormGroup>
            <Label>Email address</Label>
            <Input type="email" placeholder="Enter email" autoComplete="username" />
          </FormGroup>
          <FormGroup>
            <Row noGutters className="d-flex align-items-center">
              <Col xl={6}>
                <Label>Password</Label>
              </Col>
              <Col xl={{ size: 5, offset: 1 }} className="d-flex justify-content-end">
                <Button color="link">Forgot Password?</Button>
              </Col>
            </Row>
            <Input type="password" placeholder="Password" autoComplete="current-password" />
          </FormGroup>
          <Row>
            <Col lg={12} className="my-2">
              <Button className="sss-primary">Create Vendor Account</Button>
            </Col>
            <Col lg={12}>
              <Button className="sss-primary--outline" type="submit">
                Invite Another Vendor
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </CenteredWindow>
  );
};

export default VendorSignIn;
