import { AppState } from '../../init/rootReducer';
import { CollectionState, initialCollectionState } from './reducers/collectionReducer';
import { CollectionPageState } from './reducers/pageReducer';

export const getCollectionPageState = (state: AppState): CollectionPageState =>
  state.containers.collection;

export const getCollectionState = (state: AppState, collectionId: number): CollectionState =>
  getCollectionPageState(state).collections[collectionId] || initialCollectionState;

export const isFetchingBrowse = (state: AppState): boolean =>
  state.containers.collectionBrowseLoading.isLoading;

export const isSubmittingCollection = (state: AppState): boolean =>
  state.containers.collectionSubmitLoading.isLoading;
