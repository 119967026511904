import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { initTrigger } from '../../../global/init/actions';
import {
  getSessionActiveShop,
  getSessionUser,
  isSessionInitialized,
} from '../../../global/session/selectors';
import { DASHBOARD_URL, NO_SHOPS_URL, PRODUCTS_URL } from '../../../global/urls';
import { ShopEntity, UserEntity } from '../../../schemas';
import { gotoLogin } from '../../Login/actions';

interface DashboardData {
  gotoLogin(): void;
  gotoNoShops(): void;
  gotoProducts(): void;
  initTrigger(): void;
  isInitialized: boolean;
  shop: ShopEntity | null;
  user: UserEntity | null;
}

const useGoToShopData = (): DashboardData => {
  const dispatch = useDispatch();
  const isInitialized = useSelector(isSessionInitialized);
  const shop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);
  return {
    gotoLogin: () => dispatch(gotoLogin(DASHBOARD_URL)),
    gotoNoShops: () => dispatch(push(NO_SHOPS_URL)),
    gotoProducts: () => dispatch(push(PRODUCTS_URL)),
    initTrigger: () => dispatch(initTrigger()),
    isInitialized,
    shop,
    user,
  };
};

export default useGoToShopData;
