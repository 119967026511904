import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { PayoutsSortOrder } from './types';

// fetch
export const PAYOUTS_FETCH_TRIGGER = 'PAYOUTS_FETCH_TRIGGER';
export const PAYOUTS_FETCH_API_PREFIX = 'PAYOUTS_FETCH_API';
export const PAYOUTS_FETCH_API_REQUEST = 'PAYOUTS_FETCH_API_REQUEST';
export const PAYOUTS_FETCH_API_SUCCESS = 'PAYOUTS_FETCH_API_SUCCESS';
export const PAYOUTS_FETCH_API_FAILURE = 'PAYOUTS_FETCH_API_FAILURE';

export const defaultSortOrder: PayoutsSortOrder = PayoutsSortOrder.name;

export const PAGE_SIZE = 15;
export const PAGINATION_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
