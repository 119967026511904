import cx from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { PROFILE_URL } from '../../../../global/urls';
import { ShopEntity, UserEntity, UserRole, VendorEntity } from '../../../../schemas';

interface Props {
  className?: string;
  shop: ShopEntity | null;
  user: UserEntity | null;
  vendor: VendorEntity | null;
}

const UserLogo: FC<Props> = ({ className, shop, user, vendor }: Props) => {
  let src = '';
  if (user?.role === UserRole.Vendor) {
    src = vendor && vendor.logoUrl ? process.env.REACT_APP_DJANGO_BASE_URL + vendor.logoUrl : '';
  } else if (user?.role === UserRole.Brand) {
    src = shop && shop.logoUrl ? process.env.REACT_APP_DJANGO_BASE_URL + shop.logoUrl : '';
  }

  return (
    <Link className={cx('d-flex', className)} to={PROFILE_URL}>
      {src && <img className="mr-2" src={src} alt="Logo" style={{ height: '58px' }} />}
    </Link>
  );
};

export default UserLogo;
