import cx from 'classnames';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { v4 } from 'uuid';
import styles from './CustomTooltip.module.scss';

type Props = {
  innerClassName?: string;
  text?: string;
};

const CustomTooltip: FC<Props> = ({ children, innerClassName, text }: PropsWithChildren<Props>) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipId = useMemo(() => v4(), []);

  return (
    <span>
      <span id={`custom-tooltip-${tooltipId}`} className={cx(styles.target)}>
        ?
      </span>
      <Tooltip
        isOpen={tooltipOpen}
        placement="right"
        innerClassName={innerClassName}
        target={`custom-tooltip-${tooltipId}`}
        toggle={toggle}
      >
        {text || children}
      </Tooltip>
    </span>
  );
};
export default CustomTooltip;
