import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import useToggle from '../../../../components/ModalForm/useToggle';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGING_DISPLAYED_RANGE } from '../../constants';
import useInventoryData from '../../hooks/useInventoryData';
import { pushInventoryUrl, replaceInventoryUrl } from '../../utils';
import ImportStockModal from '../ImportStockModal/ImportStockModal';
import InventoryRightControls from '../InventoryRightControls/InventoryRightControls';
import InventoryTable from '../InventoryTable/InventoryTable';

const InventoryMainContent: FC = () => {
  useScrollToTop();

  const {
    correctLocation,
    count,
    error,
    formItems,
    importStockTrigger,
    inventoryFetchTrigger,
    inventoryItems,
    isImportStockLoading,
    isLoading,
    loaded,
    search,
    sortOrder,
    start,
    user,
  } = useInventoryData();

  const [importStockOpen, importStockToggle] = useToggle();

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: replaceInventoryUrl,
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushInventoryUrl,
    searchInput,
    sortOrder,
    start,
  });

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Inventory"
      rightControls={
        <InventoryRightControls
          isImportStockLoading={isImportStockLoading}
          searchInput={searchInput}
          setModalOpen={importStockToggle}
          setSearchInput={setSearchInput}
          user={user}
        />
      }
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGING_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={inventoryFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(inventoryItems && inventoryItems.length > 0) || loaded || !correctLocation}
      >
        {inventoryItems && inventoryItems.length > 0 ? (
          <InventoryTable
            formItems={formItems}
            inventoryItems={inventoryItems}
            pageSize={PAGE_SIZE}
            sortOrder={sortOrder}
          />
        ) : (
          <TableNoResults search={search} />
        )}
      </LoadingSwitch>
      <ImportStockModal
        isOpen={importStockOpen}
        onSubmit={importStockTrigger}
        toggle={importStockToggle}
      />
    </TablePageWrapper>
  );
};

export default InventoryMainContent;
