import { toast as toastifyToast } from 'react-toastify';

const TYPES = {
  success: {
    prefix: 'Success',
    type: toastifyToast.TYPE.SUCCESS,
  },
  warning: {
    prefix: 'Warning',
    type: toastifyToast.TYPE.WARNING,
  },
  error: {
    prefix: 'Error',
    type: toastifyToast.TYPE.ERROR,
  },
};

function callToast(toastType: keyof typeof TYPES, message: string) {
  const { prefix, type } = TYPES[toastType];
  toastifyToast(`${prefix}: ${message}`, {
    autoClose: 10000,
    position: 'top-center',
    type,
  });
}

const toast = {
  success: (message: string): void => callToast('success', message),
  warning: (message: string): void => callToast('warning', message),
  error: (message: string): void => callToast('error', message),
};

export default toast;
