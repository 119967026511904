import cx from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import envelope from '../../../../assets/img/envelope.png';
import Errors from '../../../../components/Errors/Errors';
import { FORGOTTEN_PASSWORD_URL } from '../../../../global/urls';
import { NormalizedError } from '../../../../types';
import styles from './InvalidToken.module.scss';

interface Props {
  error: NormalizedError | null;
}

const InvalidToken: FC<Props> = ({ error }: Props) => {
  return (
    <div className={cx('bg-white rounded shadow p-5 text-center', styles.wrapper)}>
      <img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={70} />
      <h4 className={cx(styles.title)}>Password reset token is invalid.</h4>
      <Link to={FORGOTTEN_PASSWORD_URL}>
        <Button
          type="button"
          color="falcon-primary"
          size="sm"
          className={cx(styles.backBtn, 'mt-3')}
        >
          Resend the verification token
        </Button>
      </Link>
      <Errors error={error} />
    </div>
  );
};

export default InvalidToken;
