import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, useState } from 'react';
import Gallery, { PhotoProps, RenderImageProps } from 'react-photo-gallery';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PhotoItem, {
  PhotoItemType,
} from '../../../../components/SortableGallery/components/PhotoItem/PhotoItem';
import TapToUpload, {
  TapToUploadType,
} from '../../../../components/SortableGallery/components/TapToUpload/TapToUpload';
import useProductImagesData from '../../hooks/useProductImagesData';
import { ImageItemType } from '../../types';
import styles from './UpdateVariantImage.module.scss';

type Props = {
  variantId: string;
  imageSrc?: string;
  setImage: (variantId: string, imageId: string) => void;
  disabled?: boolean;
};

interface CustomPhotoProps {
  itemType?: ImageItemType;
}

const UpdateVariantImage: FC<Props> = ({ imageSrc = '', variantId, setImage, disabled }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { images, addImage } = useProductImagesData();

  const galleryItems: PhotoProps<CustomPhotoProps>[] = images.map((image) => ({
    src: image.imagePreviewUrl,
    width: 1,
    height: 1,
    key: image.id,
    id: image.id,
    itemType: ImageItemType.Image,
  }));

  galleryItems.push({
    key: 'tapToUpload',
    src: '',
    width: 1,
    height: 1,
    itemType: ImageItemType.TapToUpload,
  });

  const modalWindow = (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen
      toggle={() => setIsModalOpen(false)}
    >
      <ModalHeader closeButton>
        <h2 id="contained-modal-title-vcenter">Update variant image</h2>
      </ModalHeader>
      <ModalBody>
        <Gallery
          photos={galleryItems}
          renderImage={(props: RenderImageProps<CustomPhotoProps>) =>
            props.photo.itemType === ImageItemType.Image ? (
              <PhotoItem {...props} type={PhotoItemType.Small} className="cursor-pointer" />
            ) : (
              <TapToUpload onAddImage={addImage} type={TapToUploadType.Small} />
            )
          }
          onClick={(event) => {
            const target = (event.target as unknown) as { id: string };
            setImage(variantId, target.id);
            setIsModalOpen(false);
          }}
        />
      </ModalBody>
    </Modal>
  );

  return (
    <>
      <button type="button" onClick={() => setIsModalOpen(true)} disabled={disabled}>
        {imageSrc ? (
          <img src={imageSrc} alt="var" className={cx(styles.currentImage)} />
        ) : (
          <div
            className={cx(
              styles.noImage,
              'd-flex flex-wrap align-content-center justify-content-center'
            )}
          >
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
      </button>
      {isModalOpen && modalWindow}
    </>
  );
};

export default UpdateVariantImage;
