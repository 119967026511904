import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PayoutsSortOrder } from '../types';
import { replacePayoutsUrl } from '../utils';
import usePayoutsQueryParams from './usePayoutsQueryParams';

const useOnSortChange = (): ((newSort: PayoutsSortOrder) => void) => {
  const dispatch = useDispatch();
  const { search, sortOrder, start } = usePayoutsQueryParams();

  return useCallback(
    (newSort: PayoutsSortOrder) => {
      if (newSort !== sortOrder) {
        replacePayoutsUrl(dispatch, { search, sort: newSort, start });
      }
    },
    [dispatch, search, sortOrder, start]
  );
};

export default useOnSortChange;
