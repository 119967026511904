import { NormalizedError } from '../../../types';
import {
  VendorRegistrationContractApiFailureAction,
  VendorRegistrationContractApiRequestAction,
  VendorRegistrationContractApiSuccessAction,
  VendorRegistrationContractFields,
  VendorRegistrationContractNormalizedResponse,
  VendorRegistrationContractTriggerAction,
} from '../actionTypes/contractActions';
import {
  VENDOR_REGISTRATION_CONTRACT_API_FAILURE,
  VENDOR_REGISTRATION_CONTRACT_API_REQUEST,
  VENDOR_REGISTRATION_CONTRACT_API_SUCCESS,
  VENDOR_REGISTRATION_CONTRACT_TRIGGER,
} from '../constants';

export const vendorRegistrationContractTrigger = (
  invitationCode: string,
  fields: VendorRegistrationContractFields
): VendorRegistrationContractTriggerAction => ({
  type: VENDOR_REGISTRATION_CONTRACT_TRIGGER,
  payload: { fields, invitationCode },
});

export const vendorRegistrationContractApiRequest = (
  invitationCode: string,
  fields: VendorRegistrationContractFields
): VendorRegistrationContractApiRequestAction => ({
  type: VENDOR_REGISTRATION_CONTRACT_API_REQUEST,
  payload: { fields, invitationCode },
});

export const vendorRegistrationContractApiSuccess = (
  invitationCode: string,
  fields: VendorRegistrationContractFields,
  response: VendorRegistrationContractNormalizedResponse
): VendorRegistrationContractApiSuccessAction => ({
  type: VENDOR_REGISTRATION_CONTRACT_API_SUCCESS,
  payload: { fields, invitationCode, response },
});

export const vendorRegistrationContractApiFailure = (
  invitationCode: string,
  fields: VendorRegistrationContractFields,
  error: NormalizedError
): VendorRegistrationContractApiFailureAction => ({
  type: VENDOR_REGISTRATION_CONTRACT_API_FAILURE,
  payload: { error, fields, invitationCode },
});
