import { NormalizedError } from '../../types';

export interface FormItem {
  customAmount: number | null;
  memo: string;
}

export type FormItems = { [payoutId: number]: FormItem };

export interface FormValues {
  payouts: FormItems;
}

export interface VendorBareRecord {
  id: number;
  name: string;
}

export enum SortablePayoutsColumn {
  lastPayout = 'lastPayout',
  liveBalance = 'liveBalance',
  name = 'name',
  payout = 'payout',
}

export enum NonSortablePayoutsColumn {
  customAmount = 'customAmount',
  memo = 'memo',
}

export enum PayoutsSortOrder {
  lastPayout = 'lastPayout',
  lastPayoutDesc = '-lastPayout',
  liveBalance = 'liveBalance',
  liveBalanceDesc = '-liveBalance',
  name = 'name',
  nameDesc = '-name',
  payout = 'payout',
  payoutDesc = '-payout',
}

export type PayoutsQueryState = {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  payoutIds: number[] | null;
};

export interface PayoutsSearchState {
  allIds: number[] | null;
  formItems: FormItems;
}

export interface PayoutsPageState {
  allVendors: VendorBareRecord[] | null;
  queries: { [queryKey: string]: PayoutsQueryState };
  searches: { [search: string]: PayoutsSearchState };
}

export interface PayoutsSubmitValues {
  customAmount?: number | null;
  memo?: string;
}
