import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import sortCaretFunc from '../../../../global/utils/sortCaretFunc';
import { GiftCardCodeEntity, ShopEntity } from '../../../../schemas';
import { GiftCardCodesColumn } from '../../types';
import {
  dateFormatter,
  FormatterExtraData,
  purchaserFormatter,
  valueFormatter,
} from './formatters';

const columns = (
  shop: ShopEntity | null
): ColumnDescription<GiftCardCodeEntity, FormatterExtraData>[] => {
  return [
    {
      dataField: GiftCardCodesColumn.code,
      headerStyle: () => ({ paddingLeft: '22px', whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Code',
    },
    {
      dataField: GiftCardCodesColumn.createdAt,
      formatExtraData: { shop },
      formatter: dateFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Date',
    },
    {
      dataField: GiftCardCodesColumn.value,
      formatExtraData: { shop },
      formatter: valueFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Value',
    },
    {
      dataField: GiftCardCodesColumn.remaining,
      formatExtraData: { shop },
      formatter: valueFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Remaining',
    },
    {
      dataField: GiftCardCodesColumn.purchaserName,
      formatter: purchaserFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Purchaser',
    },
  ];
};

export default columns;
