import { Action } from 'redux';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  ORDER_REFUND_API_FAILURE,
  ORDER_REFUND_API_REQUEST,
  ORDER_REFUND_API_SUCCESS,
  ORDER_REFUND_TRIGGER,
} from '../constants';
import { GroupItemsAndQuantities } from '../types';

// action types

interface OrderRefundPayload {
  groupItems: GroupItemsAndQuantities;
  orderId: number;
  orderName: string;
  reason: string;
  shippingOnly: boolean;
}

export interface OrderRefundTriggerAction extends Action {
  type: typeof ORDER_REFUND_TRIGGER;
  payload: OrderRefundPayload;
}

export interface OrderRefundApiRequestAction extends APIRequestAction {
  type: typeof ORDER_REFUND_API_REQUEST;
  payload: OrderRefundPayload;
}

export interface OrderRefundNormalizedResponse extends NormalizedResponse {
  readonly result: {
    order: number;
    orderItems: number[];
    refundedItems: number[];
    shipments: number[];
  };
}

export interface OrderRefundApiSuccessAction extends APISuccessAction {
  type: typeof ORDER_REFUND_API_SUCCESS;
  payload: OrderRefundPayload & {
    response: OrderRefundNormalizedResponse;
  };
}

export interface OrderRefundApiFailureAction extends APIFailureAction {
  type: typeof ORDER_REFUND_API_FAILURE;
  payload: OrderRefundPayload & {
    error: NormalizedError;
  };
}

// action creators

export const orderRefundTrigger = (
  orderId: number,
  orderName: string,
  groupItems: GroupItemsAndQuantities,
  reason: string,
  shippingOnly: boolean
): OrderRefundTriggerAction => ({
  type: ORDER_REFUND_TRIGGER,
  payload: {
    groupItems,
    orderId,
    orderName,
    reason,
    shippingOnly,
  },
});

export const orderRefundApiRequest = (
  orderId: number,
  orderName: string,
  groupItems: GroupItemsAndQuantities,
  reason: string,
  shippingOnly: boolean
): OrderRefundApiRequestAction => ({
  type: ORDER_REFUND_API_REQUEST,
  payload: {
    groupItems,
    orderId,
    orderName,
    reason,
    shippingOnly,
  },
});

export const orderRefundApiSuccess = (
  orderId: number,
  orderName: string,
  groupItems: GroupItemsAndQuantities,
  reason: string,
  shippingOnly: boolean,
  response: OrderRefundNormalizedResponse
): OrderRefundApiSuccessAction => ({
  type: ORDER_REFUND_API_SUCCESS,
  payload: {
    groupItems,
    orderId,
    orderName,
    reason,
    response,
    shippingOnly,
  },
});

export const orderRefundApiFailure = (
  orderId: number,
  orderName: string,
  groupItems: GroupItemsAndQuantities,
  reason: string,
  shippingOnly: boolean,
  error: NormalizedError
): OrderRefundApiFailureAction => ({
  type: ORDER_REFUND_API_FAILURE,
  payload: {
    error,
    groupItems,
    orderId,
    orderName,
    reason,
    shippingOnly,
  },
});
