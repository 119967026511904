/* eslint-disable  @typescript-eslint/no-explicit-any */
import { SessionSectionNormalized } from '../../global/init/actions';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../types';
import {
  FORGOTTEN_PASSWORD_API_FAILURE,
  FORGOTTEN_PASSWORD_API_REQUEST,
  FORGOTTEN_PASSWORD_API_SUCCESS,
  FORGOTTEN_PASSWORD_TRIGGER,
} from './constants';

// action types

export interface ForgottenPasswordTriggerAction {
  type: typeof FORGOTTEN_PASSWORD_TRIGGER;
  payload: {
    email: string;
    emailSentCallback(): void;
  };
}

export interface ForgottenPasswordApiRequestAction extends APIRequestAction {
  type: typeof FORGOTTEN_PASSWORD_API_REQUEST;
}

export interface ForgottenPasswordNormalizedResponse extends NormalizedResponse {
  readonly result: {
    init: SessionSectionNormalized;
  };
}

export interface ForgottenPasswordApiSuccessAction extends APISuccessAction {
  type: typeof FORGOTTEN_PASSWORD_API_SUCCESS;
  payload: {
    response: ForgottenPasswordNormalizedResponse;
  };
}

export interface ForgottenPasswordApiFailureAction extends APIFailureAction {
  type: typeof FORGOTTEN_PASSWORD_API_FAILURE;
}

// action creators

export const forgottenPasswordTrigger = (
  email: string,
  emailSentCallback: () => void
): ForgottenPasswordTriggerAction => ({
  type: FORGOTTEN_PASSWORD_TRIGGER,
  payload: { email, emailSentCallback },
});

export const forgottenPasswordApiRequest = (): ForgottenPasswordApiRequestAction => ({
  type: FORGOTTEN_PASSWORD_API_REQUEST,
});

export const forgottenPasswordApiSuccess = (
  response: ForgottenPasswordNormalizedResponse
): ForgottenPasswordApiSuccessAction => ({
  type: FORGOTTEN_PASSWORD_API_SUCCESS,
  payload: { response },
});

export const forgottenPasswordApiFailure = (
  error: NormalizedError
): ForgottenPasswordApiFailureAction => ({
  type: FORGOTTEN_PASSWORD_API_FAILURE,
  payload: { error },
});
