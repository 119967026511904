import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { AppState } from '../../../init/rootReducer';
import { NormalizedError } from '../../../types';
import { forgottenPasswordTrigger } from '../actions';
import { FORGOTTEN_PASSWORD_API_PREFIX } from '../constants';
import getForgottenPasswordPageState from '../selectors';
import { ForgottenPasswordScreen } from '../types';

interface ForgottenPasswordData {
  enteredEmail: string;
  error: NormalizedError | null;
  forgottenPasswordTrigger(email: string): void;
  isProcessing: boolean;
  screen: ForgottenPasswordScreen;
}

const useForgottenPasswordData = (): ForgottenPasswordData => {
  const [screen, setScreen] = useState(ForgottenPasswordScreen.ENTER_EMAIL);
  const [enteredEmail, setEnteredEmail] = useState('');
  const dispatch = useDispatch();
  const { error } = useSelector(getForgottenPasswordPageState);
  const isProcessing = useSelector((state: AppState) =>
    checkIfProcessing(state, FORGOTTEN_PASSWORD_API_PREFIX)
  );
  const emailSentCallback = () => {
    setScreen(ForgottenPasswordScreen.EMAIL_SENT);
  };
  return {
    enteredEmail,
    error,
    forgottenPasswordTrigger: (email) => {
      setEnteredEmail(email);
      dispatch(forgottenPasswordTrigger(email, emailSentCallback));
    },
    isProcessing,
    screen,
  };
};

export default useForgottenPasswordData;
