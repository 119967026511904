import cx from 'classnames';
import { CollectionsEditFormValues } from 'containers/Collection/types';
import { Field, FormikProps } from 'formik';
import React, { FC } from 'react';
import { FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { appendSlash, handleFilteredChange, onlySlugChars } from '../../../../helpers/utils';
import styles from '../../../Collections/components/CollectionsEditModal/CollectionsEditModal.module.scss';

type Props = {
  fprops: FormikProps<CollectionsEditFormValues>;
  shopUrl: string;
};

const CollectionHandle: FC<Props> = ({ fprops, shopUrl }: Props) => (
  <FormGroup>
    <Label className={styles.CollectionsEditModal__label} for="collections-edit-handle">
      URL handle
    </Label>
    <InputGroup>
      <div className="input-group-prepend">
        <span className={cx('input-group-text')} style={{ fontSize: '13px' }}>
          {appendSlash(shopUrl)}collections/
        </span>
      </div>
      <Field
        as={Input}
        id="collections-edit-handle"
        name="handle"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleFilteredChange(event, onlySlugChars, fprops.setFieldValue, 'handle')
        }
        placeholder="URL handle"
        type="text"
      />
    </InputGroup>
    {fprops.errors.handle && fprops.touched.handle && <div>{fprops.errors.handle}</div>}
  </FormGroup>
);

export default CollectionHandle;
