import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router-dom';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { reloadPageSaga, resetSessionIfNeededSaga } from '../../global/session/sagas';
import {
  INVENTORY_API_URL,
  INVENTORY_IMPORT_STOCK_API_URL,
  INVENTORY_SUBMIT_API_URL,
} from '../../global/urls';
import toast from '../../global/utils/toast';
import { apiGet, apiPut, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import { InventoryItemSchema, SessionSchema } from '../../schemas';
import {
  inventoryFetchApiFailure,
  inventoryFetchApiRequest,
  inventoryFetchApiSuccess,
  InventoryFetchNormalizedResponse,
  InventoryFetchTriggerAction,
} from './actions/fetch.actions';
import {
  inventorySubmitApiFailure,
  inventorySubmitApiRequest,
  inventorySubmitApiSuccess,
  InventorySubmitNormalizedResponse,
  InventorySubmitTriggerAction,
} from './actions/submit.actions';
import { INVENTORY_FETCH_TRIGGER, INVENTORY_SUBMIT_TRIGGER } from './constants';
import { importStockSlice } from './slices';

function* fetchSaga({ payload }: InventoryFetchTriggerAction) {
  const { search, sort, start } = payload;
  yield put(inventoryFetchApiRequest(sort, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: INVENTORY_API_URL,
      query: { search: payload.search, sort: payload.sort, start: payload.start },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: InventoryFetchNormalizedResponse = normalize(response, {
      inventoryItems: [InventoryItemSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(inventoryFetchApiSuccess(sort, start, search, normalizedResponse));
  } catch (error) {
    yield put(inventoryFetchApiFailure(sort, start, search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

function* submitSaga({ payload }: InventorySubmitTriggerAction) {
  const { values, variantId } = payload;
  yield put(inventorySubmitApiRequest(variantId, values));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(INVENTORY_SUBMIT_API_URL, { inventoryId: variantId }),
      query: {},
    });
    const response = yield call(apiPut, url, values);
    const normalizedResponse: InventorySubmitNormalizedResponse = normalize(response, {
      inventoryItem: InventoryItemSchema,
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(inventorySubmitApiSuccess(variantId, values, normalizedResponse));
  } catch (error) {
    yield put(inventorySubmitApiFailure(variantId, values, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

const importStockSaga = makeApiRequestSaga({
  baseUrl: INVENTORY_IMPORT_STOCK_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: { session: SessionSchema },
  actions: importStockSlice.actions,
  onFailure: () => {},
});

type ImportStockSuccessAction = ReturnType<typeof importStockSlice.actions.success>;

function* successImportStockSaga(action: ImportStockSuccessAction) {
  const { numImported, numSkipped } = action.payload.response.result;
  const text = numSkipped
    ? `${numImported} variants updated, ${numSkipped} skipped.`
    : `${numImported} variants updated.`;
  const toastType = numSkipped ? toast.warning : toast.success;
  yield call(toastType, text);
  yield* reloadPageSaga();
}

type ImportStockFailureAction = ReturnType<typeof importStockSlice.actions.failure>;

function* failureImportStockSaga(action: ImportStockFailureAction) {
  const { error } = action.payload;
  if (error.json?.detail) {
    yield call(toast.error, error.json.detail);
  } else {
    yield call(toast.error, 'An error occured.');
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(INVENTORY_FETCH_TRIGGER, fetchSaga),
    takeLatest(INVENTORY_SUBMIT_TRIGGER, submitSaga),
    takeLatest(importStockSlice.actions.failure.type, failureImportStockSaga),
    takeLatest(importStockSlice.actions.success.type, successImportStockSaga),
    takeLatest(importStockSlice.actions.trigger.type, importStockSaga),
  ]);
}
