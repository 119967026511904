import { Action } from 'redux';
import {
  PRODUCT_ADD_INIT_STORE_DATA,
  PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM,
  PRODUCT_SET_SHIPPING_BASE_FEE,
  PRODUCT_TOGGLE_COLLECTION_ITEM,
  PRODUCT_TOGGLE_PHYSICAL_PRODUCT,
  PRODUCT_TOGGLE_SHIPPING_AVAILABLE,
  PRODUCT_TOGGLE_SHIPPING_FREE,
} from '../constants';

// action types

export interface ProductTogglePhysicalProductAction extends Action {
  type: typeof PRODUCT_TOGGLE_PHYSICAL_PRODUCT;
  payload: { productId: number };
}

export interface ProductAddInitStoreDataAction extends Action {
  type: typeof PRODUCT_ADD_INIT_STORE_DATA;
  payload: {
    productId: number;
    id: string;
    title: string;
    collection: string[];
  };
}

export interface ProductToggleShippingAvailableAction extends Action {
  type: typeof PRODUCT_TOGGLE_SHIPPING_AVAILABLE;
  payload: {
    productId: number;
    shippingType: string;
  };
}

export interface ProductToggleShippingFreeAction extends Action {
  type: typeof PRODUCT_TOGGLE_SHIPPING_FREE;
  payload: {
    productId: number;
    shippingType: string;
  };
}

export interface ProductSetShippingBaseFeeAction extends Action {
  type: typeof PRODUCT_SET_SHIPPING_BASE_FEE;
  payload: {
    productId: number;
    shippingType: string;
    value: string;
  };
}

export interface ProductSetShippingAdditionalItemAction extends Action {
  type: typeof PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM;
  payload: {
    productId: number;
    shippingType: string;
    value: string;
  };
}

export interface ProductToggleCollectionItemAction extends Action {
  type: typeof PRODUCT_TOGGLE_COLLECTION_ITEM;
  payload: {
    productId: number;
    id: number;
  };
}

// action creators

export const productTogglePhysicalProduct = (
  productId: number
): ProductTogglePhysicalProductAction => ({
  type: PRODUCT_TOGGLE_PHYSICAL_PRODUCT,
  payload: { productId },
});

export const productAddInitStoreData = (
  productId: number,
  id: string,
  title: string,
  collection: string[]
): ProductAddInitStoreDataAction => ({
  type: PRODUCT_ADD_INIT_STORE_DATA,
  payload: { productId, id, title, collection },
});

export const productToggleShippingAvailable = (
  productId: number,
  shippingType: string
): ProductToggleShippingAvailableAction => ({
  type: PRODUCT_TOGGLE_SHIPPING_AVAILABLE,
  payload: { productId, shippingType },
});

export const productToggleShippingFree = (
  productId: number,
  shippingType: string
): ProductToggleShippingFreeAction => ({
  type: PRODUCT_TOGGLE_SHIPPING_FREE,
  payload: { productId, shippingType },
});

export const productSetShippingBaseFee = (
  productId: number,
  shippingType: string,
  value: string
): ProductSetShippingBaseFeeAction => ({
  type: PRODUCT_SET_SHIPPING_BASE_FEE,
  payload: { productId, shippingType, value },
});

export const productSetShippingAdditionalItem = (
  productId: number,
  shippingType: string,
  value: string
): ProductSetShippingAdditionalItemAction => ({
  type: PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM,
  payload: { productId, shippingType, value },
});

export const productToggleCollectionItem = (
  productId: number,
  id: number
): ProductToggleCollectionItemAction => ({
  type: PRODUCT_TOGGLE_COLLECTION_ITEM,
  payload: { productId, id },
});
