/* eslint-disable  import/prefer-default-export */
import { replace, RouterState } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';
import { getCurrentUrl, getRouterState } from '../selectors';
import { LocationState } from '../types';
import { LOGIN_URL } from '../urls';
import { isForbiddenError } from './utils';

//
// if API call was forbidden (status code 403), redirect to login page
//
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* checkIfForbiddenSaga(error: Error) {
  if (isForbiddenError(error)) {
    const routerState: RouterState<LocationState> = yield select(getRouterState);
    const { location } = routerState;
    if (location.pathname === LOGIN_URL) {
      return;
    }
    const currentUrl: string = yield select(getCurrentUrl);
    const gotoUrl = `${LOGIN_URL}?next=${encodeURIComponent(currentUrl)}`;
    yield put(replace(gotoUrl));
  }
}
