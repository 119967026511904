import { faExternalLinkAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import React, { ChangeEvent, FC, useCallback } from 'react';
import { Col, Input } from 'reactstrap';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { VENDORS_EXPORT_API_URL } from '../../../../global/urls';
import { UserEntity, UserRole } from '../../../../schemas';
import { VendorsType } from '../../types';
import VendorsTypeDropdown from '../VendorsTypeDropdown/VendorsTypeDropdown';
import styles from './VendorsRightControls.module.scss';

type Props = {
  onTypeChange: (type: VendorsType) => void;
  searchInput: string;
  setModalOpen: () => void;
  setSearchInput: (newValue: string) => void;
  user: UserEntity | null;
  vendorsType: VendorsType;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VendorsRightControls: FC<Props> = ({
  onTypeChange,
  searchInput,
  setSearchInput,
  setModalOpen,
  user,
  vendorsType,
}: Props) => {
  const exportUrl = process.env.REACT_APP_BACKEND_API_URL + VENDORS_EXPORT_API_URL;
  const handleExportClick = useCallback(() => {
    window.location.href = exportUrl;
  }, [exportUrl]);
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />
        <VendorsTypeDropdown vendorsType={vendorsType} className="mr-2" onChange={onTypeChange} />
        <PrimaryButton className="text-nowrap" icon={faPlus} onClick={setModalOpen} size="small">
          Invite Vendor
        </PrimaryButton>
        {user?.role === UserRole.Admin && (
          <HeaderButton
            className="mx-2 text-nowrap"
            icon={faExternalLinkAlt}
            onClick={handleExportClick}
          >
            Export
          </HeaderButton>
        )}
      </Col>
    </>
  );
};

export default VendorsRightControls;
