import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { ORDER_URL } from '../../../global/urls';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SelectShopApiSuccessAction } from '../../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import {
  ORDER_FETCH_API_FAILURE,
  ORDER_FETCH_API_REQUEST,
  ORDER_FETCH_API_SUCCESS,
  ORDER_FULFILL_API_FAILURE,
  ORDER_FULFILL_API_REQUEST,
  ORDER_FULFILL_API_SUCCESS,
  ORDER_REFUND_API_FAILURE,
  ORDER_REFUND_API_REQUEST,
  ORDER_REFUND_API_SUCCESS,
  ORDER_SELECT_ALL,
  ORDER_TOGGLE_ITEM,
} from '../constants';
import { orderReducer, OrderReducerAction, OrderState } from './orderReducer';

export const makeQueryKey = (start: number, search: string): string => `${start}:${search}`;

export type OrderPageState = {
  orders: { [queryKey: string]: OrderState };
};

const initialState: OrderPageState = {
  orders: {},
};

type PageReducerAction =
  | LocationChangeAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | OrderReducerAction
  | ResetSessionAction
  | SelectShopApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const pageReducer: Reducer<OrderPageState, PageReducerAction> = (
  state = initialState,
  action: PageReducerAction
): OrderPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ORDER_FETCH_API_FAILURE:
      case ORDER_FETCH_API_REQUEST:
      case ORDER_FETCH_API_SUCCESS:
      case ORDER_FULFILL_API_FAILURE:
      case ORDER_FULFILL_API_REQUEST:
      case ORDER_FULFILL_API_SUCCESS:
      case ORDER_REFUND_API_FAILURE:
      case ORDER_REFUND_API_REQUEST:
      case ORDER_REFUND_API_SUCCESS:
      case ORDER_SELECT_ALL:
      case ORDER_TOGGLE_ITEM: {
        const key = action.payload.orderName;
        draft.orders[key] = orderReducer(state.orders[key], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: ORDER_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
