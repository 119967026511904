import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  PAYOUTS_FETCH_API_FAILURE,
  PAYOUTS_FETCH_API_REQUEST,
  PAYOUTS_FETCH_API_SUCCESS,
  PAYOUTS_FETCH_TRIGGER,
} from '../constants';
import { PayoutsSortOrder } from '../types';

// action types

interface PayoutsFetchPayload {
  sort: PayoutsSortOrder;
  start: number;
  search: string;
}

export interface PayoutsFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    count: number;
    ids: number[];
    payouts: number[];
  };
}

export interface PayoutsFetchTriggerAction {
  type: typeof PAYOUTS_FETCH_TRIGGER;
  payload: PayoutsFetchPayload;
}

export interface PayoutsFetchApiRequestAction extends APIRequestAction {
  type: typeof PAYOUTS_FETCH_API_REQUEST;
  payload: PayoutsFetchPayload;
}

export interface PayoutsFetchApiSuccessAction extends APISuccessAction {
  type: typeof PAYOUTS_FETCH_API_SUCCESS;
  payload: PayoutsFetchPayload & {
    response: PayoutsFetchNormalizedResponse;
  };
}

export interface PayoutsFetchApiFailureAction extends APIFailureAction {
  type: typeof PAYOUTS_FETCH_API_FAILURE;
  payload: PayoutsFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const payoutsFetchTrigger = (
  sort: PayoutsSortOrder,
  start: number,
  search: string
): PayoutsFetchTriggerAction => ({
  type: PAYOUTS_FETCH_TRIGGER,
  payload: {
    search,
    sort,
    start,
  },
});

export const payoutsFetchApiRequest = (
  sort: PayoutsSortOrder,
  start: number,
  search: string
): PayoutsFetchApiRequestAction => ({
  type: PAYOUTS_FETCH_API_REQUEST,
  payload: { search, sort, start },
});

export const payoutsFetchApiSuccess = (
  sort: PayoutsSortOrder,
  start: number,
  search: string,
  response: PayoutsFetchNormalizedResponse
): PayoutsFetchApiSuccessAction => ({
  type: PAYOUTS_FETCH_API_SUCCESS,
  payload: { response, search, sort, start },
});

export const payoutsFetchApiFailure = (
  sort: PayoutsSortOrder,
  start: number,
  search: string,
  error: NormalizedError
): PayoutsFetchApiFailureAction => ({
  type: PAYOUTS_FETCH_API_FAILURE,
  payload: { error, search, sort, start },
});
