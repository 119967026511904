import { ApiSuccessResultActionPayload, makeApiRequestSlice } from '../../helpers/apiRequestSlice';

export type CollectionsEditPayload = {
  collectionId: number;
  handle: string;
  managedBy: string;
  title: string;
};

export type CollectionsEditResult = {
  collection: number;
};

export type CollectionsEditSuccessPayload = ApiSuccessResultActionPayload<
  CollectionsEditPayload,
  CollectionsEditResult
>;

const submitCollectionsEditSlice = makeApiRequestSlice<
  CollectionsEditPayload,
  CollectionsEditSuccessPayload
>({
  name: 'collections/edit',
});

export type CollectionDeletePayload = {
  id: number;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type CollectionDeleteResult = {};

export type CollectionDeleteSuccessPayload = ApiSuccessResultActionPayload<
  CollectionDeletePayload,
  CollectionDeleteResult
>;

export const collectionDeleteSlice = makeApiRequestSlice<
  CollectionDeletePayload,
  CollectionDeleteSuccessPayload
>({
  name: 'collections/delete',
});

export default submitCollectionsEditSlice;

export type CollectionsChangeTypePayload = {
  collectionIds: number[];
  managedBy: string;
};

export type CollectionsChangeTypeResult = Record<string, never>;

export type CollectionsChangeTypeSuccessPayload = ApiSuccessResultActionPayload<
  CollectionsChangeTypePayload,
  CollectionsChangeTypeResult
>;

export const collectionsChangeTypeSlice = makeApiRequestSlice<
  CollectionsChangeTypePayload,
  CollectionsChangeTypeSuccessPayload
>({
  name: 'collections/changeType',
});
