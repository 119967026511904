import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import useToggle from '../../../../components/ModalForm/useToggle';
import { CollectionEntity } from '../../../../schemas';
import { collectionDeleteSlice } from '../../slices';

export type Props = {
  onEdit: () => void;
  collection: CollectionEntity;
};

const CollectionActionsDropdown: FC<Props> = ({ onEdit, collection }: Props) => {
  const [deleteModalOpen, deleteModalToggle] = useToggle();

  const items: DropDownItemDefinition[] = [
    { text: 'Edit', icon: faEdit, onClick: onEdit },
    { text: 'Delete', icon: faTrashAlt, onClick: deleteModalToggle },
  ];

  const dispatch = useDispatch();
  const onDelete = () => dispatch(collectionDeleteSlice.actions.trigger({ id: collection.id }));

  return (
    <>
      <DropdownMenu component="item" items={items} />

      <ModalForm
        isOpen={deleteModalOpen}
        toggle={deleteModalToggle}
        initialValues={{}}
        onSubmit={onDelete}
        title="Delete Collection"
        canSubmit={() => true}
        okButtonLabel="Delete"
        okButtonNegative
      >
        Do you want to delete the following collection?
        <br />
        <b>{collection.title}</b>
      </ModalForm>
    </>
  );
};

export default CollectionActionsDropdown;
