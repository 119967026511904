import { faArrowUp, faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import ProductStatusBadge from '../../../../components/ProductStatusBadge/ProductStatusBadge';
import { decodeImageSrc } from '../../../../helpers/utils';
import { ProductStatus } from '../../../../schemas';
import { EnhancedCollectionProduct } from '../../types';
import styles from './CollectionSortableProducts.module.scss';

type Props = {
  numShown: number;
  onMoveToTop: (productId: number) => void;
  productsTitles: EnhancedCollectionProduct[];
  sortable: boolean;
  toppable: boolean;
};

type RowProps = {
  onMoveToTop: () => void;
  product: EnhancedCollectionProduct;
  sortable: boolean;
  toppable: boolean;
};

const DragHandle = SortableHandle(() => (
  <FontAwesomeIcon
    icon={faGripHorizontal}
    className={cx(styles.CollectionSortableProducts__grip)}
  />
));

const CollectionSortableProduct = SortableElement<RowProps>(
  ({ onMoveToTop, product, sortable, toppable }: RowProps) => {
    const { isFirst, status, name } = product;
    return (
      <ListGroupItem
        className={cx(
          styles.CollectionSortableProducts,
          sortable && styles['CollectionSortableProducts--sortable']
        )}
      >
        <Row>
          {sortable && (
            <Col lg="auto" className="px-0">
              <DragHandle />
            </Col>
          )}
          {toppable && (
            <Col
              lg="auto"
              className={cx(
                'px-0',
                styles.CollectionSortableProducts__moveToTop,
                isFirst && styles['CollectionSortableProducts__moveToTop--disabled']
              )}
              onClick={onMoveToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </Col>
          )}
          <Col lg="auto">
            <CenterCropImg
              alt="product"
              className="rounded"
              height={32}
              src={decodeImageSrc(product.image)}
              width={32}
            />
          </Col>
          <Col
            className={cx(
              'align-self-center pl-0',
              styles.CollectionSortableProducts__title,
              sortable && styles['CollectionSortableProducts__title--sortable']
            )}
          >
            {name}
            {status !== ProductStatus.Published && (
              <ProductStatusBadge className="ml-2" status={status} />
            )}
          </Col>
        </Row>
      </ListGroupItem>
    );
  }
);

const CollectionSortableProducts = SortableContainer<Props>(
  ({ numShown, onMoveToTop, productsTitles, sortable, toppable }: Props) => {
    const productsToShow = productsTitles.slice(0, numShown);
    return (
      <ListGroup flush>
        {productsToShow.map((product, index) => (
          <CollectionSortableProduct
            disabled={!sortable}
            index={index}
            key={product.productId}
            onMoveToTop={() => onMoveToTop(product.productId)}
            product={product}
            sortable={sortable}
            toppable={toppable}
          />
        ))}
      </ListGroup>
    );
  }
);

export default CollectionSortableProducts;
