import { schema } from 'normalizr';

export interface Entity {
  readonly id: number;
}

export enum FinancialStatus {
  Pending = 'pending',
  Authorized = 'authorized',
  PartiallyPaid = 'partially_paid',
  Paid = 'paid',
  PartiallyRefunded = 'partially_refunded',
  Refunded = 'refunded',
  Voided = 'voided',
}

export enum FulfillmentStatus {
  Fulfilled = 'fulfilled',
  Null = 'null',
  Partial = 'partial',
}

export enum ProductEnquiry {
  No = 'no',
  Both = 'both',
  Only = 'only',
}

export enum ProductStatus {
  Unpublished = 'unpublished',
  Unpublishing = 'unpublishing',
  Draft = 'draft',
  Pending = 'moderation',
  InReview = 'in_review',
  Rejected = 'rejected',
  Approved = 'approved',
  Published = 'published',
  Archived = 'archived',
  ShopNET = 'shopnet',
}

export enum ProductVersionType {
  Unpublished = 'unpublished',
  Draft = 'draft',
  Pending = 'moderation',
  Published = 'published',
  Archived = 'archived',
}

export enum ModerationRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Canceled = 'canceled',
}

export enum UserRole {
  Admin = 'admin',
  Brand = 'brand',
  Unknown = 'unknown',
  Vendor = 'vendor',
}

export enum VendorStatus {
  Active = 'active',
  Invited = 'invited',
  Pending = 'pending',
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  company: string;
  country: string;
  countryCode: string;
  email: string;
  name: string;
  phone: string;
  province: string;
  provinceCode: string;
  zipCode: string;
}

export enum CollectionProductsOrder {
  AlphaAsc = 'alpha-asc',
  AlphaDesc = 'alpha-desc',
  BestSelling = 'best-selling',
  Created = 'created',
  CreatedDesc = 'created-desc',
  Manual = 'manual',
  PriceAsc = 'price-asc',
  PriceDesc = 'price-desc',
}

export interface CollectionProduct {
  image: ProductImageEntity | null; // normalized
  name: string;
  productId: number;
  status: ProductStatus;
}

export interface CollectionEntity extends Entity {
  handle: string;
  isVendorManaged: boolean;
  productsOrder: CollectionProductsOrder;
  title: string;
}

export const CollectionSchema = new schema.Entity('collections');

export interface ProductImageEntity extends Entity {
  imageCdnUrl: string;
  imageLocalUrl: string;
}

export const ProductImageSchema = new schema.Entity('productImages');

export interface CustomerEntity extends Entity {
  email: string;
  firstName: string;
  lastName: string;
  lastOrderAt: string;
  location: string;
  name: string;
  ordersCount: number;
  phone: string;
  totalSpent: number;
}

export const CustomerSchema = new schema.Entity('customers');

export interface GiftCardCodeEntity extends Entity {
  code: string;
  createdAt: string;
  purchaserName: string;
  remaining: number;
  value: number;
}

export const GiftCardCodeSchema = new schema.Entity('giftCardCodes');

export interface GiftCardProductEntity extends Entity {
  sku: string;
  sold: number;
  status: ProductStatus;
  value: number;
}

export const GiftCardProductSchema = new schema.Entity('giftCardProducts');

export interface OrderEntity extends Entity {
  billingAddress: Address;
  createdAt: string;
  customerEmail: string;
  customerName: string;
  financialStatus: FinancialStatus;
  fraudRisk: number;
  fulfillmentStatus: FulfillmentStatus;
  isShippingRefunded: boolean;
  name: string;
  shippingAddress: Address;
  shippingPrice: number;
  shippingRefundReason?: string;
  shippingTax: number;
  shippingTypes: string[];
  totalPrice: number;
  totalTax: number;
  updatedAt: string;
  vendorName: string;
}

export const OrderSchema = new schema.Entity('orders');

export interface OrderItemEntity extends Entity {
  customization?: string;
  dateOfBirth?: string;
  discountAmount?: number;
  fulfillableQuantity: number;
  fulfilledQuantity: number;
  image?: ProductImageEntity | null;
  name: string;
  options: string[] | null;
  price: number;
  totalTax: number;
  productId: number;
  quantity: number;
  refundedQuantity: number;
  removedQuantity: number;
  sku: string;
  totalQuantity: number;
  unitPrice: number;
}

export const OrderItemSchema = new schema.Entity('orderItems', {
  image: ProductImageSchema,
});

export interface ProductOption {
  id: number | string;
  name: string;
  values: string[];
}

export interface ProductVariantEntity extends Entity {
  barcode: string;
  compareAtPrice: number;
  option1: string;
  option2: string;
  option3: string;
  quantity: number;
  price: number;
  sku: string;
  title: string;
  image: ProductImageEntity;
  trackInventoryEnabled: boolean;
  allowOverselling: boolean;
}

export const ProductVariantSchema = new schema.Entity('productVariants', {
  image: ProductImageSchema,
});

export interface ProductVersionEntity extends Entity {
  title: string;
  descriptionHtml: string;
  seoTitle: string;
  seoDescription: string;
  handle: string;
  tags: string[];
  requiresShipping: boolean;
  age18plusRequired: boolean;
  customizationEnabled: boolean;
  enquiry: ProductEnquiry;
  images: ProductImageEntity[];
  options: ProductOption[];
  variants: ProductVariantEntity[];
  versionType: ProductVersionType;
}

export const ProductVersionSchema = new schema.Entity('productVersions', {
  images: [ProductImageSchema],
  variants: [ProductVariantSchema],
});

export interface ShippingPolicyEntity extends Entity {
  additionalFee: number;
  baseFee: number;
  default: boolean;
  disabled: boolean;
  free: boolean;
  name: string;
  order: number;
  productId: number;
  shippingFreeAboveOrderValue: number;
  shippingMaxCap: number;
  shopId: number;
  vendorId: number;
}

export const ShippingPolicySchema = new schema.Entity('shippingPolicies');

export interface ShippingType extends Entity {
  id: number;
  name: string;
  default: boolean;
  order: number;
}

export const ShippingTypeSchema = new schema.Entity('shippingTypes');

export interface ShopEntity extends Entity {
  currency: string;
  defaultVendorCommission?: number;
  enquiriesAllowed: boolean;
  isLive: boolean;
  logoUrl: string;
  name: string;
  shopCode: string;
  shopifyFixedFee: number;
  shopifyPercentFee: number;
  siteName: string;
  transactionFixedFee: number;
  transactionPercentFee: number;
  url: string;
}

export const ShopSchema = new schema.Entity('shops');

export interface UserEntity extends Entity {
  username: string;
  role: UserRole;
}

export const UserSchema = new schema.Entity('users');

export interface RefundedItemEntity extends Entity {
  image?: ProductImageEntity | null;
  name: string;
  options: string[];
  price: number;
  quantity: number;
  reason: string;
  sku: string;
  unitPrice: number;
}

export const RefundedItemSchema = new schema.Entity('refundedItems', {
  image: ProductImageSchema,
});

export interface ShipmentItem {
  id: number;
  name: string;
  options: string[];
  price: number;
  quantity: number;
  sku: string;
  totalQuantity: number;
  unitPrice: number;
}

export interface ShipmentEntity extends Entity {
  comment?: string;
  createdAt: string;
  items: ShipmentItem[];
  name: string;
  shippingTypes?: string[];
  status?: string;
  trackingCode: string;
  updatedAt?: string;
}

export const ShipmentSchema = new schema.Entity('shipments');

export interface VendorEntity extends Entity {
  bankAccountNumber: string;
  bankAddress: string;
  bankIban: string;
  bankName: string;
  bankSortCode: string;
  bankSwiftBic: string;
  billingContactEmail: string;
  billingContactName: string;
  billingContactPhone: string;
  commission: number;
  companyNumber: string;
  contractSignedAt: string;
  contractSignerEmail: string;
  contractSignerFullName: string;
  contractSignerPhone: string;
  contractSignerPosition: string;
  customerInquiryContactEmail: string;
  customerInquiryContactName: string;
  customerInquiryContactPhone: string;
  description: string;
  enquiriesAllowed: boolean;
  img: string;
  inventoryContactEmail: string;
  inventoryContactName: string;
  inventoryContactPhone: string;
  inviteCode: string;
  isShopNet: boolean;
  legalName: string;
  logoUrl: string;
  maxProducts: number;
  moderation: boolean;
  name: string;
  numPendingProducts: number;
  numPublishedProducts: number;
  regAddress1: string;
  regAddress2: string;
  regCity: string;
  regCountry: string;
  regPostCode: string;
  regState: string;
  revenue: number;
  status: string;
  tags: string[];
  tradingAddress1: string;
  tradingAddress2: string;
  tradingCity: string;
  tradingCountry: string;
  tradingPostCode: string;
  tradingState: string;
  vatNumber: string;
  vendorCode: string;
}

export const VendorSchema = new schema.Entity('vendors');

export interface PayoutEntity extends Entity {
  lastPayoutAmount: number;
  lastPayoutDate: string;
  liveBalance: number;
  payout: number;
  vendorCode: string;
  vendorDisplayName: string;
  vendorLegalName: string;
}

export const PayoutSchema = new schema.Entity('payouts');

export const SessionSchema = {
  activeShop: ShopSchema,
  shops: [ShopSchema],
  user: UserSchema,
  vendorshop: VendorSchema,
};

export interface MicroProductEntity extends Entity {
  image: ProductImageEntity | null;
  name: string;
  status: ProductStatus;
}

export const MicroProductSchema = new schema.Entity('microProducts', {
  image: ProductImageSchema,
});

export interface BriefProductEntity extends Entity {
  image: ProductImageEntity | null;
  inventory: number;
  isWarehouseProduct: boolean;
  lastPublishedAt: string;
  marginPct: number;
  maxPrice: number;
  maxVendorPrice: number;
  minPrice: number;
  minVendorPrice: number;
  name: string;
  numVariants: number;
  productId: number;
  sku: string;
  status: ProductStatus;
}

export const BriefProductSchema = new schema.Entity('briefProducts', {
  image: ProductImageSchema,
});

export interface InventoryItemEntity extends Entity {
  allowOverselling: boolean;
  image: ProductImageEntity | null;
  inventory: number;
  productId: number;
  productName: string;
  sku: string;
  trackInventory: boolean;
  variantName: string;
}

export const InventoryItemSchema = new schema.Entity('inventoryItems', {
  image: ProductImageSchema,
});

interface ReviewerEntity {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ModerationRequestEntity extends Entity {
  productId: number;
  createdAt: string;
  image: ProductImageEntity;
  name: string;
  vendor: VendorEntity;
  reviewer?: ReviewerEntity;
  status: ModerationRequestStatus;
}

export const ModerationRequestSchema = new schema.Entity('moderationList', {
  image: ProductImageSchema,
  vendor: VendorSchema,
});

export enum WarehouseProductStatus {
  Available = 'available',
  Unpublished = 'unpublished',
  Unpublishing = 'unpublishing',
  Draft = 'draft',
  Pending = 'moderation',
  InReview = 'in_review',
  Rejected = 'rejected',
  Approved = 'approved',
  Published = 'published',
  Archived = 'archived',
}

export interface WarehouseProductEntity extends Entity {
  addedAt: string;
  categories: string[];
  description: string;
  image: ProductImageEntity | null;
  inventory: number;
  maxPrice: number;
  minPrice: number;
  name: string;
  numVariants: number;
  productId: number;
  productUrl: string;
  sku: string;
  status: WarehouseProductStatus;
  vendor: string;
}

export const WarehouseProductSchema = new schema.Entity('warehouseProducts', {
  image: ProductImageSchema,
});
