import { NormalizedError } from '../../types';

export interface FormItem {
  additionalFee: number;
  applyToProducts: boolean;
  availability: boolean;
  baseFee: number;
  free: boolean;
  shippingFreeAbove: number | null | '';
  shippingMaxCap: number | null | '';
  order: number;
}

export type FormItems = { [order: number]: FormItem };

export interface FormValues {
  items: FormItems;
}

export type ShippingPoliciesQueryState = {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  shippingPolicyIds: number[] | null;
};

export interface ShippingPoliciesPageState {
  queries: { [queryKey: string]: ShippingPoliciesQueryState };
  selected: number[];
}

export enum ShippingPoliciesSortOrder {
  pk = 'pk',
  pkDesc = '-pk',
}

export interface ShippingPoliciesSubmitValues {
  additionalFee?: number;
  applyToProducts?: boolean;
  availability?: boolean;
  baseFee?: number;
  free?: boolean;
  shippingFreeAbove?: number | null;
  shippingMaxCap?: number | null;
}
