import { Formik } from 'formik';
import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import SimpleLoadingIndicator from '../../../../components/SimpleLoadingIndicator/SimpleLoadingIndicator';
import useRequireAuth from '../../../../global/hooks/useRequireAuth';
import { isAuthenticated } from '../../../../helpers/utils';
import { UserRole } from '../../../../schemas';
import useBrandProfileData from '../../hooks/useBrandProfileData';
import { fetchBrandProfileSlice } from '../../slices';
import { FormValues } from '../../types';
import BrandProfileHeader from '../BrandProfileHeader/BrandProfileHeader';
import BrandProfileMainInformation from '../BrandProfileMainInformation/BrandProfileMainInformation';

const BrandProfileMainContent: FC = () => {
  useRequireAuth();
  const dispatch = useDispatch();
  const {
    error,
    isLoading,
    loaded,
    logoUrl,
    saveBrandProfileTrigger,
    shop,
    shopId,
    user,
  } = useBrandProfileData();

  const loadData = () => {
    if (isAuthenticated(user) && user?.role !== UserRole.Brand) return;
    dispatch(fetchBrandProfileSlice.actions.trigger({}));
  };

  const initialValues: FormValues = useMemo(
    () => ({
      logoFileData: '',
      logoFileName: '',
    }),
    []
  );

  const handleSave = (values: FormValues) => {
    saveBrandProfileTrigger({
      ...values,
      shopId,
    });
  };

  return (
    <Formik<FormValues> enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
      {(fprops) => (
        <LoadingSwitch
          error={!!error}
          loading={isLoading}
          onLoad={loadData}
          renderError={null}
          renderLoading={() => <SimpleLoadingIndicator />}
          require={!!shop || loaded}
        >
          <BrandProfileHeader
            canSave={fprops.dirty}
            displayName={shop?.name || ''}
            isSubmitting={false}
            onSave={() => handleSave(fprops.values)}
          />
          <BrandProfileMainInformation fprops={fprops} logoUrl={logoUrl} />
        </LoadingSwitch>
      )}
    </Formik>
  );
};

export default BrandProfileMainContent;
