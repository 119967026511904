import SimpleLoadingIndicator from 'components/SimpleLoadingIndicator/SimpleLoadingIndicator';
import React, { FC, useState } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import useCollectionPageData from '../../hooks/useCollectionPageData';
import CollectionForm from '../CollectionForm/CollectionForm';

const renderError = () => {
  return (
    <div
      style={{
        marginTop: '20px',
        border: '2px solid red',
        padding: '20px',
      }}
    >
      ERROR!
    </div>
  );
};

const CollectionMainContent: FC = () => {
  const {
    browsableProducts,
    collection,
    collectionFetchTrigger,
    collectionSubmitTrigger,
    correctLocation,
    error,
    isLoading,
    isLoadingBrowse,
    isSubmitting,
    loaded,
    numShown,
    numTotal,
    products,
    selectedProductIds,
    shopUrl,
    showMore,
  } = useCollectionPageData();

  const [browseModalCounter, setBrowseModalCounter] = useState(0);
  const [isBrowseModalOpen, setIsBrowseModalOpen] = useState(false);
  const toggleBrowseModal = () => {
    if (isBrowseModalOpen) {
      setBrowseModalCounter(browseModalCounter + 1);
    }
    setIsBrowseModalOpen(!isBrowseModalOpen);
  };
  const openBrowseModal = () => {
    setIsBrowseModalOpen(true);
  };

  const loadData = () => {
    collectionFetchTrigger();
    // dispatch(
    //   fetchCollectionProductsSlice.actions.trigger({
    //     collectionId,
    //     start: 0,
    //   })
    // );
  };

  return (
    <LoadingSwitch
      error={!!error}
      loading={isLoading}
      onLoad={loadData}
      renderError={renderError}
      renderLoading={() => <SimpleLoadingIndicator />}
      require={!!(collection && products) || loaded || !correctLocation}
    >
      {collection && products ? (
        <CollectionForm
          browsableProducts={browsableProducts}
          browseModalCounter={browseModalCounter}
          collection={collection}
          isBrowseModalOpen={isBrowseModalOpen}
          isLoadingBrowse={isLoadingBrowse}
          isSubmitting={isSubmitting}
          numShown={numShown}
          numTotal={numTotal}
          onBrowse={openBrowseModal}
          onShowMore={showMore}
          onSubmit={collectionSubmitTrigger}
          products={products}
          selectedProductIds={selectedProductIds}
          shopUrl={shopUrl}
          toggleBrowseModal={toggleBrowseModal}
        />
      ) : null}
    </LoadingSwitch>
  );
};

export default CollectionMainContent;
