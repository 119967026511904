import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import VendorsMainContent from './components/VendorsMainContent/VendorsMainContent';

const Vendors: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <VendorsMainContent key={key} />;
};

export default Vendors;
