import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/customersQueryReducer';
import { CustomersPageState, CustomersQueryState, CustomersSortOrder } from './types';

export const getCustomersPageState = (state: AppState): CustomersPageState =>
  state.containers.customers;

export const getCustomersQueryState = (
  state: AppState,
  sort: CustomersSortOrder,
  start: number,
  search: string
): CustomersQueryState => {
  return (
    getCustomersPageState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState
  );
};
