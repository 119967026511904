import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import styles from './TableNoResults.module.scss';

interface Props {
  search?: string;
  text?: string;
}

const TableNoResults: FC<Props> = ({ search, text = 'There is no data for this page.' }: Props) => (
  <div
    className={cx(
      'align-items-center d-flex flex-column justify-content-center',
      styles.TableNoResults
    )}
  >
    {search ? (
      <>
        <div className={styles.TableNoResults__glass}>
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <div className={cx('my-2', styles.TableNoResults__bigText)}>No results found</div>
      </>
    ) : (
      <div className={cx('my-2', styles.TableNoResults__bigText)}>{text}</div>
    )}
  </div>
);

export default TableNoResults;
