import { SortOrder } from 'react-bootstrap-table-next';
import { SortOrderDirection } from '../../global/types';
import { SortableVendorsColumn, VendorsSortOrder, VendorsType } from './types';

export const makeQueryKey = (
  type: VendorsType,
  sortOrder: VendorsSortOrder,
  start: number,
  search: string
): string => `${type}:${sortOrder}:${start}:${search}`;

export const mapSortOrder = (
  columnName: SortableVendorsColumn,
  sortDirection: SortOrderDirection
): VendorsSortOrder => {
  return sortDirection === SortOrderDirection.asc
    ? VendorsSortOrder[columnName]
    : (`-${columnName}` as VendorsSortOrder);
};

export const mapTableSort = (
  sortOrder: VendorsSortOrder
): { dataField: VendorsSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as VendorsSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};
