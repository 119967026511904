import { SagaIterator } from '@redux-saga/core';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SessionSectionNormalized } from '../../global/init/actions';
import { resetSession } from '../../global/session/actions';
import { VENDOR_PROFILE_API_URL } from '../../global/urls';
import toast from '../../global/utils/toast';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { SessionSchema, VendorSchema } from '../../schemas';
import { APISuccessAction } from '../../types';
import { fetchVendorProfileSlice, saveVendorProfileSlice } from './slices';

const fetchVendorProfileSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: VENDOR_PROFILE_API_URL,
  responseSchema: {
    vendorshop: VendorSchema,
    session: SessionSchema,
  },
  actions: fetchVendorProfileSlice.actions,
});

const saveVendorProfileSaga = makeApiRequestSaga({
  baseUrl: VENDOR_PROFILE_API_URL,
  method: 'PUT',
  queryPayload: (payload) => payload,
  responseSchema: { vendorshop: VendorSchema, session: SessionSchema },
  actions: saveVendorProfileSlice.actions,
});

function* successSaveVendorProfileSaga(action: APISuccessAction) {
  yield call(toast.success, `Profile updated.`);

  // reset session to update to recent vendorshop data if needed
  const normalizedResponse = action.payload.response;
  const session: SessionSectionNormalized = normalizedResponse?.result?.session;
  yield put(resetSession(session, normalizedResponse.entities));
}

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest(fetchVendorProfileSlice.actions.trigger, fetchVendorProfileSaga),
    takeLatest(saveVendorProfileSlice.actions.trigger, saveVendorProfileSaga),
    takeLatest(saveVendorProfileSlice.actions.success, successSaveVendorProfileSaga),
  ]);
}
