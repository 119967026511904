import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faEllipsisH, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, ReactElement } from 'react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu as BaseDropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import useToggle from '../ModalForm/useToggle';
import styles from './DropdownMenu.module.scss';

type Props = {
  component: 'header' | 'item';
  items: DropDownItemDefinition[];
  headerLabel?: string;
  headerIcon?: IconDefinition;
  disabled?: boolean;
};

export type DropDownItemDefinition =
  | {
      text: string;
      icon: IconDefinition;
      onClick?: () => void;
      disabled?: boolean;
    }
  | ReactElement
  | null;

const DropdownMenu: FC<Props> = ({
  component,
  items,
  headerLabel,
  headerIcon,
  disabled = false,
}: Props) => {
  const [dropdownOpen, toggle] = useToggle();

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={cx(styles.wrapper, 'position-relative')}
    >
      {component === 'header' ? (
        <DropdownToggle caret className={cx(styles.headerToggleBtn)} disabled={disabled}>
          <FontAwesomeIcon icon={headerIcon || faList} className="mr-2" />
          {headerLabel}
        </DropdownToggle>
      ) : (
        <DropdownToggle
          className={cx(styles.itemToggleBtn, 'd-flex justify-content-center align-items-center')}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={headerIcon || faEllipsisH} />
        </DropdownToggle>
      )}
      <BaseDropdownMenu className={cx({ [styles.dropdownItemMenu]: component === 'item' })}>
        {items.map((item, position) => {
          // eslint-disable-next-line react/no-array-index-key
          if (!item) return <DropdownItem divider key={`divider-${position}`} />;

          // Custom ReactElement as dropdown item content.
          if ('props' in item)
            return (
              <DropdownItem
                className={cx(styles.dropdownMenuItem, 'my-2')}
                // eslint-disable-next-line react/no-array-index-key
                key={`customItem-${position}`}
              >
                {item}
              </DropdownItem>
            );

          return (
            <DropdownItem
              className={cx(styles.dropdownMenuItem, 'my-2', { [styles.disabled]: item.disabled })}
              key={item.text}
              onClick={item.onClick || (() => {})}
              disabled={item.disabled}
            >
              <FontAwesomeIcon icon={item.icon} className="mr-2 fa-fw" />
              {item.text}
            </DropdownItem>
          );
        })}
      </BaseDropdownMenu>
    </ButtonDropdown>
  );
};

export default DropdownMenu;
