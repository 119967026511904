import { ColumnDescription } from 'react-bootstrap-table-next';
import { RefundedItemEntity, ShopEntity } from '../../../../schemas';
import {
  FormatterExtraData,
  imageFormatter,
  itemFormatter,
  optionsFormatter,
  priceFormatter,
  quantityFormatter,
} from './formatters';

const columns = (
  shop: ShopEntity | null
): ColumnDescription<RefundedItemEntity, FormatterExtraData>[] => [
  {
    dataField: 'image',
    formatter: imageFormatter,
    headerStyle: () => ({ width: '100px' }),
    text: '',
  },
  {
    dataField: 'name',
    formatter: itemFormatter,
    text: 'Item',
  },
  {
    dataField: 'options',
    formatter: optionsFormatter,
    headerStyle: {
      whiteSpace: 'nowrap',
      width: '7%',
    },
    text: 'Variant',
  },
  {
    align: 'right',
    dataField: 'unitPrice',
    formatExtraData: { shop },
    formatter: priceFormatter,
    headerAlign: 'right',
    headerStyle: {
      whiteSpace: 'nowrap',
      width: '7%',
    },
    text: 'Unit Price',
  },
  {
    align: 'right',
    dataField: 'quantity',
    formatter: quantityFormatter,
    headerAlign: 'right',
    headerStyle: {
      whiteSpace: 'nowrap',
      width: '9%',
    },
    text: 'QTY',
  },
  {
    align: 'right',
    dataField: 'price',
    formatExtraData: { shop },
    formatter: priceFormatter,
    headerAlign: 'right',
    headerStyle: {
      paddingRight: '40px',
      whiteSpace: 'nowrap',
      width: '11%',
    },
    style: { paddingRight: '40px' },
    text: 'Price',
  },
];

export default columns;
