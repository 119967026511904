import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { WAREHOUSE_URL } from '../../../global/urls';
import { makeQueryKey } from '../../../helpers/utils';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SelectShopApiSuccessAction } from '../../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import {
  ProductsFetchApiFailureAction,
  ProductsFetchApiRequestAction,
  ProductsFetchApiSuccessAction,
  ProductsFetchTriggerAction,
} from '../actions/fetch.actions';
import {
  WAREHOUSE_FETCH_API_FAILURE,
  WAREHOUSE_FETCH_API_REQUEST,
  WAREHOUSE_FETCH_API_SUCCESS,
} from '../constants';
import { WarehousePageState } from '../types';
import { warehouseQueryReducer } from './warehouseQueryReducer';
import { ProductsSearchAction, warehouseSearchReducer } from './warehouseSearchReducer';

const initialState: WarehousePageState = {
  queries: {},
  searches: {},
};

export type ProductsAction =
  | LocationChangeAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | ProductsFetchApiFailureAction
  | ProductsFetchApiRequestAction
  | ProductsFetchApiSuccessAction
  | ProductsFetchTriggerAction
  | ProductsSearchAction
  | ResetSessionAction
  | SelectShopApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const warehousePageReducer: Reducer<WarehousePageState, ProductsAction> = (
  state = initialState,
  action: ProductsAction
): WarehousePageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case WAREHOUSE_FETCH_API_FAILURE:
      case WAREHOUSE_FETCH_API_REQUEST:
      case WAREHOUSE_FETCH_API_SUCCESS: {
        const { search, sort, start } = action.payload;
        const key = makeQueryKey(sort, start, search);
        draft.queries[key] = warehouseQueryReducer(state.queries[key], action);
        draft.searches[search] = warehouseSearchReducer(state.searches[search], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: WAREHOUSE_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
