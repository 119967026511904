/* eslint-disable  @typescript-eslint/no-explicit-any */
import arrayMove from 'array-move';
import React, { FC } from 'react';
import Gallery, { RenderImageProps } from 'react-photo-gallery';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ImageItem, ImageItemType } from '../../containers/Product/types';
import PhotoItem, { PhotoItemProps, PhotoItemType } from './components/PhotoItem/PhotoItem';
import TapToUpload, { TapToUploadType } from './components/TapToUpload/TapToUpload';

type Props = {
  images: ImageItem[];
  removeImage: (id: string) => void;
  setImages: (newImages: ImageItem[]) => void;
  onAddImage: (image: ImageItem) => void;
};

interface CustomPhotoProps {
  itemType?: ImageItemType;
}

const SortablePhoto = SortableElement((props: PhotoItemProps) => <PhotoItem {...props} />);

const SortableGalleryContainer = SortableContainer(
  ({ items, onAddImage, removeImage }: { items: any; onAddImage: any; removeImage: any }) => {
    return (
      <Gallery
        photos={items}
        renderImage={(props: RenderImageProps<CustomPhotoProps>) =>
          props.photo.itemType === ImageItemType.Image ? (
            <SortablePhoto {...props} removeImage={removeImage} type={PhotoItemType.Small} />
          ) : (
            <TapToUpload
              key="tapToUpload"
              onAddImage={onAddImage}
              type={props.index === 0 ? TapToUploadType.Big : TapToUploadType.Small}
            />
          )
        }
      />
    );
  }
);

const SortableGallery: FC<Props> = ({ images, onAddImage, removeImage, setImages }: Props) => {
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setImages(arrayMove(images, oldIndex, newIndex));
  };

  const galleryItems = images.map((photoItem: ImageItem) => ({
    src: photoItem.imagePreviewUrl,
    width: 1,
    height: 1,
    key: photoItem.id,
    itemType: photoItem.itemType,
  }));

  galleryItems.push({
    key: 'tapToUpload',
    src: '',
    width: 1,
    height: 1,
    itemType: ImageItemType.TapToUpload,
  });

  return (
    <div>
      <SortableGalleryContainer
        items={galleryItems}
        onAddImage={onAddImage}
        removeImage={removeImage}
        onSortEnd={onSortEnd}
        distance={3}
        axis="xy"
      />
    </div>
  );
};

export default SortableGallery;
