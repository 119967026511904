import { SortOrder } from 'react-bootstrap-table-next';
import { SortOrderDirection } from '../../global/types';
import { OrdersOrderItem, OrdersSortOrder } from './types';

export const mapSortOrder = (
  columnName: OrdersOrderItem,
  sortDirection: SortOrderDirection
): OrdersSortOrder => {
  if (columnName === OrdersOrderItem.name) {
    return sortDirection === SortOrderDirection.asc
      ? OrdersSortOrder.name
      : OrdersSortOrder.nameDesc;
  }
  const columnNameDesc = `-${columnName}` as OrdersSortOrder;
  return sortDirection === SortOrderDirection.asc ? OrdersSortOrder[columnName] : columnNameDesc;
};

export const mapTableSort = (
  sortOrder: OrdersSortOrder
): { dataField: OrdersSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as OrdersSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};
