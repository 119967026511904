import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { RESET_SESSION } from '../../../global/session/constants';
import { GIFT_CARDS_URL } from '../../../global/urls';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import { GiftCardsActionTypes } from '../actionTypes';
import {
  GIFT_CARDS_FETCH_API_FAILURE,
  GIFT_CARDS_FETCH_API_REQUEST,
  GIFT_CARDS_FETCH_API_SUCCESS,
} from '../constants';
import { GiftCardsPageState } from '../types';
import { makeQueryKey } from '../utils';
import { giftCardsQueryReducer } from './giftCardsQueryReducer';

const initialState: GiftCardsPageState = {
  queries: {},
};

// eslint-disable-next-line import/prefer-default-export
export const giftCardsPageReducer: Reducer<GiftCardsPageState, GiftCardsActionTypes> = (
  state = initialState,
  action: GiftCardsActionTypes
): GiftCardsPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case GIFT_CARDS_FETCH_API_FAILURE:
      case GIFT_CARDS_FETCH_API_REQUEST:
      case GIFT_CARDS_FETCH_API_SUCCESS: {
        const key = makeQueryKey(
          action.payload.sortProducts,
          action.payload.sortCodes,
          action.payload.start,
          action.payload.search
        );
        draft.queries[key] = giftCardsQueryReducer(state.queries[key], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: GIFT_CARDS_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
