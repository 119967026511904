import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router-dom';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import { ORDER_API_URL, ORDER_FULFILL_API_URL, ORDER_REFUND_API_URL } from '../../global/urls';
import toast from '../../global/utils/toast';
import { apiGet, apiPost, normalizeError } from '../../helpers/api';
import { handleFailureSaga } from '../../helpers/sagas';
import {
  OrderItemSchema,
  OrderSchema,
  RefundedItemSchema,
  SessionSchema,
  ShipmentSchema,
} from '../../schemas';
import {
  orderFetchApiFailure,
  orderFetchApiRequest,
  orderFetchApiSuccess,
  OrderFetchNormalizedResponse,
  OrderFetchTriggerAction,
} from './actions/fetch.actions';
import {
  orderFulfillApiFailure,
  orderFulfillApiRequest,
  orderFulfillApiSuccess,
  OrderFulfillNormalizedResponse,
  OrderFulfillTriggerAction,
} from './actions/fulfill.actions';
import {
  orderRefundApiFailure,
  orderRefundApiRequest,
  orderRefundApiSuccess,
  OrderRefundNormalizedResponse,
  OrderRefundTriggerAction,
} from './actions/refund.actions';
import { ORDER_FETCH_TRIGGER, ORDER_FULFILL_TRIGGER, ORDER_REFUND_TRIGGER } from './constants';

function* fetchSaga({ payload }: OrderFetchTriggerAction) {
  const { orderName } = payload;
  yield put(orderFetchApiRequest(orderName));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(ORDER_API_URL, { orderName: payload.orderName }),
      query: {},
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: OrderFetchNormalizedResponse = normalize(response, {
      order: OrderSchema,
      orderItems: [OrderItemSchema],
      refundedItems: [RefundedItemSchema],
      session: SessionSchema,
      shipments: [ShipmentSchema],
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(orderFetchApiSuccess(orderName, normalizedResponse));
  } catch (error) {
    yield put(orderFetchApiFailure(orderName, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

function* fulfillSaga({ payload }: OrderFulfillTriggerAction) {
  const { items, orderId, orderName, trackingCode } = payload;
  yield put(orderFulfillApiRequest(orderId, orderName, items, trackingCode));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(ORDER_FULFILL_API_URL, { orderId: payload.orderId }),
      query: {},
    });
    const response = yield call(apiPost, url, {
      items,
      trackingCode,
    });
    const normalizedResponse: OrderFulfillNormalizedResponse = normalize(response, {
      order: OrderSchema,
      orderItems: [OrderItemSchema],
      refundedItems: [RefundedItemSchema],
      session: SessionSchema,
      shipments: [ShipmentSchema],
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(orderFulfillApiSuccess(orderId, orderName, items, trackingCode, normalizedResponse));
    yield delay(100);
  } catch (error) {
    yield put(
      orderFulfillApiFailure(orderId, orderName, items, trackingCode, normalizeError(error))
    );

    if (error.json?.code === 'bad_quantity') {
      yield call(toast.error, 'Bad quantity to fulfil.');
    } else {
      yield* handleFailureSaga(error);
    }
  }
}

function* refundSaga({ payload }: OrderRefundTriggerAction) {
  const { groupItems, orderId, orderName, reason, shippingOnly } = payload;
  yield put(orderRefundApiRequest(orderId, orderName, groupItems, reason, shippingOnly));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(ORDER_REFUND_API_URL, { orderId: payload.orderId }),
      query: {},
    });
    const response = yield call(apiPost, url, {
      fulfilledItems: groupItems.fulfilled,
      reason,
      shippingOnly,
      unfulfilledItems: groupItems.unfulfilled,
    });
    const normalizedResponse: OrderRefundNormalizedResponse = normalize(response, {
      order: OrderSchema,
      orderItems: [OrderItemSchema],
      refundedItems: [RefundedItemSchema],
      session: SessionSchema,
      shipments: [ShipmentSchema],
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(
      orderRefundApiSuccess(
        orderId,
        orderName,
        groupItems,
        reason,
        shippingOnly,
        normalizedResponse
      )
    );
    yield delay(100);
  } catch (error) {
    yield put(
      orderRefundApiFailure(
        orderId,
        orderName,
        groupItems,
        reason,
        shippingOnly,
        normalizeError(error)
      )
    );

    if (error.json?.code === 'already_refunded') {
      yield call(toast.error, 'Already refunded.');
    } else if (error.json?.code === 'bad_quantity') {
      yield call(toast.error, 'Bad quantity to refund.');
    } else if (error.json?.code === 'no_items') {
      yield call(toast.error, 'No items to refund.');
    } else {
      yield* handleFailureSaga(error);
    }
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(ORDER_FETCH_TRIGGER, fetchSaga),
    takeLatest(ORDER_FULFILL_TRIGGER, fulfillSaga),
    takeLatest(ORDER_REFUND_TRIGGER, refundSaga),
  ]);
}
