import { NormalizedError } from '../../types';

export enum CollectionsColumn {
  title = 'title',
  managedBy = 'isVendorManaged',
  productsOrder = 'productsOrder',
  productsCount = 'productsCount',
}

export enum CollectionsSortOrder {
  title = 'title',
  titleDesc = '-title',
  isVendorManaged = 'isVendorManaged',
  isVendorManagedDesc = '-isVendorManaged',
  productsCount = 'productsCount',
  productsCountDesc = '-productsCount',
  productsOrder = 'productsOrder',
  productsOrderDesc = '-productsOrder',
}

export type CollectionsQueryState = {
  allIds: number[];
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  collectionIds: number[] | null;
};

export interface CollectionsPageState {
  queries: { [queryKey: string]: CollectionsQueryState };
}
