import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import sortCaretFunc from '../../../../global/utils/sortCaretFunc';
import { CustomerEntity, ShopEntity } from '../../../../schemas';
import { CustomersColumn } from '../../types';
import {
  emailFormatter,
  FormatterExtraData,
  nameFormatter,
  orderDateFormatter,
  ordersCountFormatter,
  totalPriceFormatter,
} from './formatters';

const columns = (
  shop: ShopEntity | null
): ColumnDescription<CustomerEntity, FormatterExtraData>[] => {
  return [
    {
      dataField: CustomersColumn.name,
      formatter: nameFormatter,
      headerStyle: () => ({ paddingLeft: '22px', whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Name',
    },
    {
      dataField: CustomersColumn.email,
      formatter: emailFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Email',
    },
    {
      align: 'right',
      dataField: CustomersColumn.ordersCount,
      formatter: ordersCountFormatter,
      headerAlign: 'right',
      headerStyle: () =>
        ({ paddingRight: '52px', whiteSpace: 'nowrap', width: '8%' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      style: () => ({ paddingRight: '52px' } as CSSProperties),
      text: 'Orders',
    },
    {
      dataField: CustomersColumn.lastOrderAt,
      formatExtraData: { shop },
      formatter: orderDateFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap', width: '8%' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Last order',
    },
    {
      align: 'right',
      dataField: CustomersColumn.totalSpent,
      formatExtraData: { shop },
      formatter: totalPriceFormatter,
      headerAlign: 'right',
      headerStyle: () =>
        ({ paddingRight: '52px', whiteSpace: 'nowrap', width: '20%' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      style: () => ({ paddingRight: '40px' } as CSSProperties),
      text: 'Amount spent',
    },
  ];
};

export default columns;
