import { NormalizedError } from '../../types';

export enum OrdersOrderItem {
  createdAt = 'createdAt',
  customerName = 'customerName',
  financialStatus = 'financialStatus',
  fulfillmentStatus = 'fulfillmentStatus',
  name = 'name',
  totalPrice = 'totalPrice',
  vendorName = 'vendorName',
}

export enum OrdersSortOrder {
  customerName = 'customerName',
  customerNameDesc = '-customerName',
  createdAt = 'createdAt',
  createdAtDesc = '-createdAt',
  financialStatus = 'financialStatus',
  financialStatusDesc = '-financialStatus',
  fulfillmentStatus = 'fulfillmentStatus',
  fulfillmentStatusDesc = '-fulfillmentStatus',
  name = 'name',
  nameDesc = '-name',
  totalPrice = 'totalPrice',
  totalPriceDesc = '-totalPrice',
  vendorName = 'vendorName',
  vendorNameDesc = '-vendorName',
}

export interface OrdersQueryState {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  orderIds: number[] | null;
}

export interface OrdersSearchState {
  allIds: number[] | null;
}

export interface OrdersPageState {
  queries: { [queryKey: string]: OrdersQueryState };
  searches: { [search: string]: OrdersSearchState };
  selected: number[];
}
