import { ErrorMessage, Field } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import * as Yup from 'yup';
import FormikSelect from '../../../../components/FormikSelect/FormikSelect';
import ModalForm, { ModalFormLabel, ModalRow } from '../../../../components/ModalForm/ModalForm';
import { checkMaxDecimalPlaces } from '../../../../global/utils/validation';
import { currencyToSymbol, formatPrice } from '../../../../helpers/utils';
import { AppState } from '../../../../init/rootReducer';
import { getPayoutsPageState, getPayoutsVendorsBareLoadingState } from '../../selectors';
import { AddTransactionRequestPayload, fetchVendorsBareSlice } from '../../slices';

type FormValues = {
  amount?: number;
  date: string;
  memo: string;
  vendorId: number;
};

type Props = {
  isOpen: boolean;
  onSubmit: (payload: AddTransactionRequestPayload) => void;
  toggle: () => void;
};

const ValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Invalid number')
    .required(' ')
    .test('notZero', 'Non-zero value required', (number) => number !== 0)
    .test('maxDigitsAfterDecimal', 'Max 2 decimal places allowed', (number) =>
      checkMaxDecimalPlaces(number, 2)
    ),
  date: Yup.date()
    .required(' ')
    .test(
      'dateNotInFuture',
      'Date should not be in the future',
      (date) => !!date && date <= new Date()
    ),
  vendorId: Yup.number()
    .typeError('Invalid number')
    .required(' ')
    .test('notZero', 'Non-zero value required', (number) => number !== 0),
  memo: Yup.string().required(' ').trim(),
});

const AddTransactionModal: FC<Props> = ({ isOpen, onSubmit, toggle }: Props) => {
  const dispatch = useDispatch();
  const loadingState = useSelector(getPayoutsVendorsBareLoadingState);
  const { allVendors } = useSelector(getPayoutsPageState);

  const options = useMemo(
    () =>
      allVendors ? allVendors.map((vendor) => ({ value: vendor.id, label: vendor.name })) : [],
    [allVendors]
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchVendorsBareSlice.actions.trigger({}));
    }
  }, [dispatch, isOpen]);

  const initialValues: FormValues = {
    amount: undefined,
    date: '',
    memo: '',
    vendorId: 0,
  };

  const handleSubmit = (values: FormValues) => {
    onSubmit({
      type: 'adjustment',
      amount: typeof values.amount === 'number' ? values.amount : 0,
      date: values.date,
      memo: values.memo,
      vendorId: values.vendorId,
    });
  };

  const shopCurrency = useSelector((state: AppState) => state.session.activeShop?.currency);
  const currencySymbol = currencyToSymbol(shopCurrency);

  return (
    <ModalForm
      initialValues={initialValues}
      isOpen={isOpen}
      okButtonLabel="Submit"
      onSubmit={handleSubmit}
      title="Add Transaction"
      toggle={toggle}
      validationSchema={ValidationSchema}
    >
      {(formikProps) => (
        <FormGroup>
          <ModalRow>
            <ModalFormLabel for="add-transaction-vendor">Vendor</ModalFormLabel>
            <Field
              component={FormikSelect}
              id="add-transaction-vendor"
              isLoading={!allVendors && loadingState.isLoading}
              name="vendorId"
              options={options}
            />
            <ErrorMessage name="vendorId" />
          </ModalRow>
          <ModalRow>
            <ModalFormLabel for="add-transaction-date">Date of transaction</ModalFormLabel>
            <Field
              as={Input}
              id="add-transaction-date"
              name="date"
              placeholder="Date"
              type="date"
            />
            <ErrorMessage name="date" />
          </ModalRow>
          <ModalRow>
            <ModalFormLabel for="add-transaction-amount">Amount</ModalFormLabel>
            <InputGroup size="sm" style={{ flexWrap: 'nowrap' }}>
              <InputGroupAddon addonType="prepend">{currencySymbol}</InputGroupAddon>
              <Field
                as={Input}
                id="add-transaction-amount"
                name="amount"
                placeholder="Amount"
                type="number"
              />
            </InputGroup>
            {!formikProps.errors.amount && formikProps.values.amount && (
              <div>
                Vendor&apos;s balance will{' '}
                <strong style={{ color: 'red' }}>
                  {formikProps.values.amount > 0 ? 'INCREASE' : 'DECREASE'}
                </strong>{' '}
                by {formatPrice(formikProps.values.amount, shopCurrency)}
              </div>
            )}
            <ErrorMessage name="amount" />
          </ModalRow>
          <ModalRow>
            <ModalFormLabel for="add-transaction-memo">Memo</ModalFormLabel>
            <Field
              as={Input}
              id="add-transaction-memo"
              name="memo"
              placeholder="Memo"
              type="text"
            />
            <ErrorMessage name="memo" />
          </ModalRow>
        </FormGroup>
      )}
    </ModalForm>
  );
};

export default AddTransactionModal;
