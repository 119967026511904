// verify token
export const PASSWORD_RESET_VERIFY_TOKEN_TRIGGER = 'PASSWORD_RESET_VERIFY_TOKEN_TRIGGER';
export const PASSWORD_RESET_VERIFY_TOKEN_API_PREFIX = 'PASSWORD_RESET_VERIFY_TOKEN_API';
export const PASSWORD_RESET_VERIFY_TOKEN_API_REQUEST = 'PASSWORD_RESET_VERIFY_TOKEN_API_REQUEST';
export const PASSWORD_RESET_VERIFY_TOKEN_API_SUCCESS = 'PASSWORD_RESET_VERIFY_TOKEN_API_SUCCESS';
export const PASSWORD_RESET_VERIFY_TOKEN_API_FAILURE = 'PASSWORD_RESET_VERIFY_TOKEN_API_FAILURE';

// reset password
export const PASSWORD_RESET_TRIGGER = 'PASSWORD_RESET_TRIGGER';
export const PASSWORD_RESET_API_PREFIX = 'PASSWORD_RESET_API';
export const PASSWORD_RESET_API_REQUEST = 'PASSWORD_RESET_API_REQUEST';
export const PASSWORD_RESET_API_SUCCESS = 'PASSWORD_RESET_API_SUCCESS';
export const PASSWORD_RESET_API_FAILURE = 'PASSWORD_RESET_API_FAILURE';
