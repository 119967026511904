import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { InventorySortOrder } from './types';

// fetch
export const INVENTORY_FETCH_TRIGGER = 'INVENTORY_FETCH_TRIGGER';
export const INVENTORY_FETCH_API = 'INVENTORY_FETCH_API';
export const INVENTORY_FETCH_API_REQUEST = 'INVENTORY_FETCH_API_REQUEST';
export const INVENTORY_FETCH_API_SUCCESS = 'INVENTORY_FETCH_API_SUCCESS';
export const INVENTORY_FETCH_API_FAILURE = 'INVENTORY_FETCH_API_FAILURE';

// submit
export const INVENTORY_SUBMIT_TRIGGER = 'INVENTORY_SUBMIT_TRIGGER';
export const INVENTORY_SUBMIT_API = 'INVENTORY_SUBMIT_API';
export const INVENTORY_SUBMIT_API_REQUEST = 'INVENTORY_SUBMIT_API_REQUEST';
export const INVENTORY_SUBMIT_API_SUCCESS = 'INVENTORY_SUBMIT_API_SUCCESS';
export const INVENTORY_SUBMIT_API_FAILURE = 'INVENTORY_SUBMIT_API_FAILURE';

export const defaultSortOrder: InventorySortOrder = InventorySortOrder.position;

export const PAGE_SIZE = 15;
export const PAGING_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
