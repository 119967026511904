import cx from 'classnames';
import { FormValues } from 'containers/Order/types';
import { Field, FormikProps } from 'formik';
import React, { FC, useState } from 'react';
import { Col, Input, Row } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import OrderRefundModal from '../OrderRefundModal/OrderRefundModal';
import styles from './OrderItemsFooter.module.scss';

interface Props {
  className?: string;
  fprops: FormikProps<FormValues>;
  isFulfilling: boolean;
  isRefunding: boolean;
  onFulfill: () => void;
  onRefund: (reason: string) => void;
  selectedFulfilled: number[];
  selectedUnfulfilled: number[];
}

const OrderItemsFooter: FC<Props> = ({
  className,
  fprops,
  isFulfilling,
  isRefunding,
  onFulfill,
  onRefund,
  selectedFulfilled,
  selectedUnfulfilled,
}: Props) => {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const toggleRefundModal = () => setIsRefundModalOpen(!isRefundModalOpen);
  const openRefundModal = () => setIsRefundModalOpen(true);

  const fulfillEnabled = selectedUnfulfilled.length > 0;
  const refundEnabled = selectedFulfilled.length > 0 || selectedUnfulfilled.length > 0;
  return (
    <Row className={cx('align-items-end px-4', styles.OrderItemsFooter, className)}>
      <Col lg="auto">
        <PrimaryButton
          className={styles.OrderItemsFooter__button}
          disabled={!fulfillEnabled || isFulfilling || !fprops.isValid}
          onClick={onFulfill}
          size="small"
        >
          {fulfillEnabled ? 'Fulfil Items' : 'Select items to Fulfil'}
        </PrimaryButton>
      </Col>
      <Col>
        <div className="fs-13 gray-700 pb-2">Tracking number (optional)</div>
        <div>
          <Field as={Input} bsSize="sm" name="trackingCode" style={{ width: '15em' }} />
        </div>
      </Col>
      <Col lg="auto">
        <PrimaryButton
          className={styles.OrderItemsFooter__button}
          disabled={!refundEnabled || isRefunding || !fprops.isValid}
          negative
          onClick={openRefundModal}
          size="small"
        >
          {refundEnabled ? 'Refund' : 'Select items to Refund'}
        </PrimaryButton>
        <OrderRefundModal
          isOpen={isRefundModalOpen}
          toggle={toggleRefundModal}
          onSubmit={onRefund}
          type="items"
        />
      </Col>
    </Row>
  );
};

export default OrderItemsFooter;
