import { CollectionsEditFormValues } from 'containers/Collection/types';
import { Field, FormikProps } from 'formik';
import React, { FC } from 'react';
import { Label } from 'reactstrap';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import styles from './CollectionType.module.scss';

type Props = {
  fprops: FormikProps<CollectionsEditFormValues>;
};

const CollectionType: FC<Props> = ({ fprops }: Props) => (
  <div>
    <div className="small">Type</div>
    <div className={styles.CollectionType__box}>
      <Label className={styles.CollectionType__radio}>
        <Field as={CustomRadio} name="managedBy" value="brand" type="radio" />
        <span className="ml-2 mr-2">Brand Managed</span>
        <CustomTooltip text="Only you will be able to see and populate this collection, it will not be visible to vendors when uploading products." />
      </Label>
      {fprops.errors.managedBy && fprops.touched.managedBy && <div>{fprops.errors.managedBy}</div>}
      <Label className={styles.CollectionType__radio}>
        <Field as={CustomRadio} name="managedBy" value="vendor" type="radio" />
        <span className="ml-2 mr-2">Vendor Managed</span>
        <CustomTooltip text="Vendors will be able to select this collection when uploading products. You can edit the collections a product is a part of at any time." />
      </Label>
    </div>
  </div>
);

export default CollectionType;
