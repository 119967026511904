import { Location } from 'history';
import { parseInt } from 'lodash';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop } from '../../../global/session/selectors';
import { LocationState } from '../../../global/types';
import { isShopNetShop } from '../../../global/utils/shopnet';
import { defaultSortOrder } from '../constants';
import { WarehouseSortOrder, WarehouseType } from '../types';

type WarehouseQueryParams = {
  inStock: boolean;
  location: Location<LocationState>;
  locationState: LocationState;
  search: string;
  sortOrder: WarehouseSortOrder;
  start: number;
  type: WarehouseType;
};

const parseWarehouseSortOrder = (sort: string): WarehouseSortOrder => {
  const sortProducts = Object.values(WarehouseSortOrder);
  return sortProducts.includes(sort as WarehouseSortOrder)
    ? (sort as WarehouseSortOrder)
    : defaultSortOrder;
};

const parseWarehouseType = (type: string): WarehouseType => {
  if (type === WarehouseType.Available) {
    return WarehouseType.Available;
  }
  if (type === WarehouseType.Approved) {
    return WarehouseType.Approved;
  }
  return WarehouseType.All;
};

const useWarehouseQueryParams = (): WarehouseQueryParams => {
  const { location } = useSelector(getRouterState);
  const activeShop = useSelector(getSessionActiveShop);
  const isShopNet = isShopNetShop(activeShop);
  const parsed = queryString.parse(location.search);
  const start: number = parsed.start ? parseInt(parsed.start.toString(), 10) : 0;
  const search: string = parsed.search ? parsed.search.toString() : '';
  const sortOrder = parseWarehouseSortOrder(parsed.sort ? parsed.sort.toString() : '');
  const defaultWarehouseType = isShopNet ? 'all' : 'available';
  const type: WarehouseType = parseWarehouseType(
    parsed.type ? parsed.type.toString() : defaultWarehouseType
  );
  const inStock = parsed.inStock === 'true';
  const { state } = location;

  return {
    inStock,
    location,
    locationState: state,
    search,
    sortOrder,
    start,
    type,
  };
};

export default useWarehouseQueryParams;
