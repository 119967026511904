import { AppState } from '../../init/rootReducer';
import {
  VendorRegistrationBusiness,
  VendorRegistrationContract,
  VendorRegistrationLogin,
  VendorRegistrationState,
} from './types';

export const getVendorRegistrationState = (state: AppState): VendorRegistrationState =>
  state.containers.vendorRegistration;
export const getVendorRegistrationBusinessState = (state: AppState): VendorRegistrationBusiness =>
  state.containers.vendorRegistration.business;
export const getVendorRegistrationContractState = (state: AppState): VendorRegistrationContract =>
  state.containers.vendorRegistration.contract;
export const getVendorRegistrationLoginState = (state: AppState): VendorRegistrationLogin =>
  state.containers.vendorRegistration.login;
