import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGINATION_DISPLAYED_RANGE } from '../../constants';
import useGiftCardsPageData from '../../hooks/useGiftCardsPageData';
import { pushGiftCardsUrl } from '../../utils';
import GiftCardCodesPagination from '../GiftCardCodesPagination/GiftCardCodesPagination';
import GiftCardCodesRightControls from '../GiftCardCodesRightControls/GiftCardCodesRightControls';
import GiftCardCodesTable from '../GiftCardCodesTable/GiftCardCodesTable';
import GiftCardProductsRightControls from '../GiftCardProductsRightControls/GiftCardProductsRightControls';
import GiftCardProductsTable from '../GiftCardProductsTable/GiftCardProductsTable';

const GiftCardsMainContent: FC = () => {
  useScrollToTop();

  const {
    codesCount,
    codesSortOrder,
    correctLocation,
    error,
    giftCardCodes,
    giftCardCodesFetchTrigger,
    giftCardProducts,
    isLoading,
    loaded,
    productsSortOrder,
    search,
    shop,
    start,
  } = useGiftCardsPageData();

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushGiftCardsUrl,
    searchInput: '',
    sortOrder: codesSortOrder,
    start,
  });

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <>
      <TablePageWrapper
        className="mb-4"
        title="Gift Card Products"
        rightControls={<GiftCardProductsRightControls />}
        itemsCount={0}
        showCount={false}
      >
        {giftCardProducts && giftCardProducts.length > 0 ? (
          <GiftCardProductsTable
            giftCardProducts={giftCardProducts}
            pageSize={PAGE_SIZE}
            shop={shop}
            sortOrder={productsSortOrder}
          />
        ) : (
          <TableNoResults search={search} text="There are no gift cards yet." />
        )}
      </TablePageWrapper>
      <TablePageWrapper
        title="Gift Card Codes"
        rightControls={<GiftCardCodesRightControls />}
        itemsCount={codesCount}
        pagination={
          <GiftCardCodesPagination
            activePage={activePage}
            displayedRange={PAGINATION_DISPLAYED_RANGE}
            itemsPerPage={PAGE_SIZE}
            onPageChange={onPageChange}
            totalPages={codesCount}
          />
        }
        showCount={false}
      >
        <LoadingSwitch
          error={!!error}
          loading={isLoading}
          onLoad={giftCardCodesFetchTrigger}
          renderError={null}
          renderLoading={() => <TableLoadingIndicator />}
          require={(giftCardCodes && giftCardCodes.length > 0) || loaded || !correctLocation}
        >
          {giftCardCodes && giftCardCodes.length > 0 ? (
            <GiftCardCodesTable
              giftCardCodes={giftCardCodes}
              pageSize={PAGE_SIZE}
              shop={shop}
              sortOrder={codesSortOrder}
            />
          ) : (
            <TableNoResults search={search} text="There are no active codes." />
          )}
        </LoadingSwitch>
      </TablePageWrapper>
    </>
  );
};

export default GiftCardsMainContent;
