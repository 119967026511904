import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { GIFT_CARD_NEW_URL } from '../../../../global/urls';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

// eslint-disable-next-line no-empty-pattern
const GiftCardProductsRightControls: FC<Props> = ({}: Props) => {
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Link to={GIFT_CARD_NEW_URL}>
          <PrimaryButton className="mx-2" icon={faPlus} size="small">
            Add Gift Card
          </PrimaryButton>
        </Link>
      </Col>
    </>
  );
};

export default GiftCardProductsRightControls;
