import cx from 'classnames';
import React, { FC, ReactNode } from 'react';
import styles from './FormInputError.module.scss';

type Props = {
  isBlock?: boolean;
  className?: string;
  children?: string | ReactNode;
};

const FormInputError: FC<Props> = ({ children, className = '', isBlock = false }: Props) => {
  return (
    <span
      className={cx('text-danger', isBlock ? 'd-block' : 'd-inline-block', styles.text, className)}
    >
      {children}
    </span>
  );
};

export default FormInputError;
