import { ErrorMessage, Field } from 'formik';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import * as Yup from 'yup';
import CustomInputField from '../../../../components/CustomInputField/CustomInputField';
import ModalForm, { ModalFormLabel, ModalRow } from '../../../../components/ModalForm/ModalForm';
import { getSessionActiveShop } from '../../../../global/session/selectors';
import { checkMaxDecimalPlaces } from '../../../../global/utils/validation';
import { InviteVendorRequestPayload } from '../../slices';

type FormValues = {
  commission: number;
  email: string;
  maxProducts: number | null;
  moderation: boolean;
  name: string;
};

type Props = {
  isOpen: boolean;
  onSubmit: (payload: InviteVendorRequestPayload) => void;
  toggle: () => void;
};

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid e-mail').required('Required'),
  commission: Yup.number()
    .typeError('Invalid number')
    .required('Required')
    .min(0, 'Invalid number')
    .max(100, 'Invalid number')
    .test('maxDigitsAfterDecimal', 'Max 2 decimal places allowed', (number) =>
      checkMaxDecimalPlaces(number, 2)
    ),
  moderation: Yup.boolean(),
});

const InviteVendorModal: FC<Props> = ({ isOpen, onSubmit, toggle }: Props) => {
  const shop = useSelector(getSessionActiveShop);

  const initialValues: FormValues = {
    commission: shop ? shop.defaultVendorCommission || 0 : 0,
    email: '',
    maxProducts: null,
    moderation: true,
    name: '',
  };

  const handleSubmit = (values: FormValues) => {
    onSubmit({
      invites: [
        {
          commission: values.commission,
          email: values.email,
          maxProducts: values.maxProducts,
          moderation: values.moderation,
          name: values.name,
        },
      ],
    });
  };

  return (
    <ModalForm
      initialValues={initialValues}
      isOpen={isOpen}
      okButtonLabel="Invite"
      onSubmit={handleSubmit}
      title="Invite Vendor"
      toggle={toggle}
      validationSchema={ValidationSchema}
    >
      <FormGroup>
        <ModalRow>
          <ModalFormLabel for="invite-vendor-name">Contact Name</ModalFormLabel>
          <Field as={Input} id="invite-vendor-name" name="name" placeholder="Name" type="text" />
          <ErrorMessage name="name" />
        </ModalRow>
        <ModalRow>
          <ModalFormLabel for="invite-vendor-email">E-mail</ModalFormLabel>
          <Field
            as={Input}
            id="invite-vendor-email"
            name="email"
            placeholder="E-mail"
            type="text"
          />
          <ErrorMessage name="email" />
        </ModalRow>
        <ModalRow>
          <ModalFormLabel for="invite-vendor-commission">Commission %</ModalFormLabel>
          <Field
            as={Input}
            id="invite-vendor-commission"
            name="commission"
            placeholder="Commission"
            type="text"
          />
          <ErrorMessage name="commission" />
        </ModalRow>
        <ModalRow>
          <ModalFormLabel for="invite-vendor-max-products">Max Products</ModalFormLabel>
          <Field
            as={Input}
            id="invite-vendor-max-products"
            name="maxProducts"
            placeholder="Max Products"
            type="number"
          />
          <ErrorMessage name="maxProducts" />
        </ModalRow>
        <ModalRow>
          <ModalFormLabel className="align-items-center d-flex">
            <CustomInputField id="invite-vendor-moderation" name="moderation" type="switch" />
            Moderation required
          </ModalFormLabel>
        </ModalRow>
      </FormGroup>
    </ModalForm>
  );
};

export default InviteVendorModal;
