import { Action } from 'redux';
import { ORDER_SELECT_ALL, ORDER_TOGGLE_ITEM } from '../constants';
import { OrderItemGroup } from '../types';

// action types

export interface OrderToggleItemAction extends Action {
  type: typeof ORDER_TOGGLE_ITEM;
  payload: {
    orderName: string;
    group: OrderItemGroup;
    itemId: number;
  };
}

export interface OrderSelectAllAction extends Action {
  type: typeof ORDER_SELECT_ALL;
  payload: {
    orderName: string;
    group: OrderItemGroup;
    isSelect: boolean;
    itemIds: number[];
  };
}

// action creators

export const orderToggleItem = (
  orderName: string,
  group: OrderItemGroup,
  itemId: number
): OrderToggleItemAction => ({
  type: ORDER_TOGGLE_ITEM,
  payload: {
    group,
    itemId,
    orderName,
  },
});

export const orderSelectAll = (
  orderName: string,
  group: OrderItemGroup,
  isSelect: boolean,
  itemIds: number[]
): OrderSelectAllAction => ({
  type: ORDER_SELECT_ALL,
  payload: {
    group,
    isSelect,
    itemIds,
    orderName,
  },
});
