import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { PRODUCTS_URL } from '../../../global/urls';
import { makeQueryKey } from '../../../helpers/utils';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SelectShopApiSuccessAction } from '../../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import {
  ProductsFetchApiFailureAction,
  ProductsFetchApiRequestAction,
  ProductsFetchApiSuccessAction,
  ProductsFetchTriggerAction,
} from '../actions/fetch.actions';
import {
  PRODUCTS_FETCH_API_FAILURE,
  PRODUCTS_FETCH_API_REQUEST,
  PRODUCTS_FETCH_API_SUCCESS,
} from '../constants';
import { ProductsPageState } from '../types';
import { productsQueryReducer } from './productsQueryReducer';
import { ProductsSearchAction, productsSearchReducer } from './productsSearchReducer';

const initialState: ProductsPageState = {
  queries: {},
  searches: {},
};

export type ProductsAction =
  | LocationChangeAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | ProductsFetchApiFailureAction
  | ProductsFetchApiRequestAction
  | ProductsFetchApiSuccessAction
  | ProductsFetchTriggerAction
  | ProductsSearchAction
  | ResetSessionAction
  | SelectShopApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const productsPageReducer: Reducer<ProductsPageState, ProductsAction> = (
  state = initialState,
  action: ProductsAction
): ProductsPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCTS_FETCH_API_FAILURE:
      case PRODUCTS_FETCH_API_REQUEST:
      case PRODUCTS_FETCH_API_SUCCESS: {
        const { search, sort, start } = action.payload;
        const key = makeQueryKey(sort, start, search);
        draft.queries[key] = productsQueryReducer(state.queries[key], action);
        draft.searches[search] = productsSearchReducer(state.searches[search], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: PRODUCTS_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
