import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop } from '../../../global/session/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { PAYOUTS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { PayoutEntity, PayoutSchema, ShopEntity } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { payoutsFetchTrigger } from '../actions/fetch.actions';
import { PAYOUTS_FETCH_API_PREFIX } from '../constants';
import { getPayoutsQueryState, getPayoutsSearchState } from '../selectors';
import { AddTransactionRequestPayload, addTransactionSlice } from '../slices';
import { FormItems, PayoutsSortOrder } from '../types';
import usePayoutsQueryParams from './usePayoutsQueryParams';

type PayoutsData = {
  addTransactionTrigger: (payload: AddTransactionRequestPayload) => void;
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  formItems: FormItems;
  isLoading: boolean;
  loaded: boolean;
  payouts: PayoutEntity[] | null;
  payoutsFetchTrigger(): void;
  search: string;
  shop: ShopEntity | null;
  sortOrder: PayoutsSortOrder;
  start: number;
};

const usePayoutsData = (): PayoutsData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { search, sortOrder, start } = usePayoutsQueryParams();
  const correctLocation = location.pathname === PAYOUTS_URL;
  const entities = useSelector(getEntitiesState);
  const shop = useSelector(getSessionActiveShop);
  const { count, error, loaded, payoutIds } = useSelector((state: AppState) =>
    getPayoutsQueryState(state, sortOrder, start, search)
  );
  const { formItems } = useSelector((state: AppState) => getPayoutsSearchState(state, search));

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, PAYOUTS_FETCH_API_PREFIX)
  );

  const payouts = useMemo(
    () =>
      payoutIds === null
        ? payoutIds
        : payoutIds.map((payoutId) => denormalize(payoutId, PayoutSchema, entities)),
    [entities, payoutIds]
  );

  return {
    addTransactionTrigger: (payload: AddTransactionRequestPayload) =>
      dispatch(addTransactionSlice.actions.trigger(payload)),
    correctLocation,
    count,
    dispatch,
    error,
    formItems,
    isLoading,
    loaded,
    payouts,
    payoutsFetchTrigger: () => dispatch(payoutsFetchTrigger(sortOrder, start, search)),
    search,
    shop,
    sortOrder,
    start,
  };
};

export default usePayoutsData;
