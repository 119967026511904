import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { initTrigger } from '../../../global/init/actions';
import {
  getSessionActiveShop,
  getSessionUser,
  isSessionInitialized,
} from '../../../global/session/selectors';
import { DASHBOARD_URL, NO_SHOPS_URL, PRODUCTS_URL } from '../../../global/urls';
import { ShopEntity, UserEntity } from '../../../schemas';
import { gotoLogin } from '../../Login/actions';

const siteNameToUrl = (siteName: string): string => {
  if (siteName === 'shopnet.superstoresystems.com') {
    return process.env.REACT_APP_SITE_SHOPNET || '';
  }
  if (siteName === 'superstoresystems.com') {
    return process.env.REACT_APP_SITE_SUPERSTORE || '';
  }
  return process.env.REACT_APP_SITE_SHOWSHOPPA || '';
};

interface DashboardData {
  gotoLogin(): void;
  gotoNoShops(): void;
  gotoOtherShop(siteName: string, shopId: number): void;
  gotoProducts(): void;
  initTrigger(): void;
  isInitialized: boolean;
  shop: ShopEntity | null;
  user: UserEntity | null;
}

const useDashboardData = (): DashboardData => {
  const dispatch = useDispatch();
  const isInitialized = useSelector(isSessionInitialized);
  const shop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);
  return {
    gotoLogin: () => dispatch(gotoLogin(DASHBOARD_URL)),
    gotoNoShops: () => dispatch(push(NO_SHOPS_URL)),
    gotoOtherShop: (siteName, shopId) => {
      const siteUrl = siteNameToUrl(siteName);
      const url = `${siteUrl}/goToShop/${shopId}`;
      window.location.href = url;
    },
    gotoProducts: () => dispatch(push(PRODUCTS_URL)),
    initTrigger: () => dispatch(initTrigger()),
    isInitialized,
    shop,
    user,
  };
};

export default useDashboardData;
