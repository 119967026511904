import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGING_DISPLAYED_RANGE } from '../../constants';
import useModerationListPageData from '../../hooks/useModerationListPageData';
import { pushModerationListUrl, replaceModerationListUrl } from '../../utils';
import ModerationList from '../ModerationList/ModerationList';

const ModerationListMainContent: FC = () => {
  useScrollToTop();

  const {
    moderationListFetchTrigger,
    count,
    correctLocation,
    error,
    isLoading,
    loaded,
    moderationList,
    search,
    sortOrder,
    start,
  } = useModerationListPageData();

  const { searchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: replaceModerationListUrl,
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushModerationListUrl,
    searchInput,
    sortOrder,
    start,
  });

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Moderation Queue"
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGING_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={moderationListFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(moderationList && moderationList.length > 0) || loaded || !correctLocation}
      >
        {moderationList && moderationList.length > 0 ? (
          <ModerationList
            moderationList={moderationList}
            pageSize={PAGE_SIZE}
            sortOrder={sortOrder}
          />
        ) : (
          <TableNoResults search={search} text="All done! The moderation queue is empty." />
        )}
      </LoadingSwitch>
    </TablePageWrapper>
  );
};

export default ModerationListMainContent;
