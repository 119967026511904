import { faArchive, faUnlink } from '@fortawesome/free-solid-svg-icons';
import ArchiveProductsModal from 'containers/Products/components/ArchiveProductsModal/ArchiveProductsModal';
import UnpublishProductsModal from 'containers/Products/components/UnpublishProductsModal/UnpublishProductsModal';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import useToggle from '../../../../components/ModalForm/useToggle';
import { GiftCardProductEntity, ProductStatus } from '../../../../schemas';
import {
  ArchiveProductsRequestPayload,
  archiveProductsSlice,
  UnpublishProductsRequestPayload,
  unpublishProductsSlice,
} from '../../../Products/slices';

export type Props = {
  product: GiftCardProductEntity;
};

const GiftCardProductActionsDropdown: FC<Props> = ({ product }: Props) => {
  const [unpublishOpen, unpublishToggle] = useToggle();
  const [archiveOpen, archiveToggle] = useToggle();

  const dropDownItems: DropDownItemDefinition[] = [];

  if (product.status === ProductStatus.Unpublished) {
    dropDownItems.push({ text: 'Archive', icon: faArchive, onClick: archiveToggle });
  } else if (product.status !== ProductStatus.Draft) {
    dropDownItems.push({ text: 'Unpublish', icon: faUnlink, onClick: unpublishToggle });
  }

  const dispatch = useDispatch();
  const unpublishProductsTrigger = (payload: UnpublishProductsRequestPayload) =>
    dispatch(unpublishProductsSlice.actions.trigger(payload));
  const archiveProductsTrigger = (payload: ArchiveProductsRequestPayload) =>
    dispatch(archiveProductsSlice.actions.trigger(payload));

  return (
    <>
      <DropdownMenu
        component="item"
        items={dropDownItems}
        // disabled={disabled}
      />
      <UnpublishProductsModal
        isOpen={unpublishOpen}
        toggle={unpublishToggle}
        selectedProducts={new Set([product.id])}
        submit={unpublishProductsTrigger}
      />
      <ArchiveProductsModal
        isOpen={archiveOpen}
        toggle={archiveToggle}
        selectedProducts={new Set([product.id])}
        submit={archiveProductsTrigger}
      />
    </>
  );
};

export default GiftCardProductActionsDropdown;
