import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import { ORDERS_API_URL } from '../../global/urls';
import { apiGet, normalizeError } from '../../helpers/api';
import { handleFailureSaga } from '../../helpers/sagas';
import { OrderSchema, SessionSchema } from '../../schemas';
import {
  ordersFetchApiFailure,
  ordersFetchApiRequest,
  ordersFetchApiSuccess,
  OrdersFetchNormalizedResponse,
  OrdersFetchTriggerAction,
} from './actions/fetch.actions';
import { ORDERS_FETCH_TRIGGER } from './constants';

function* fetchSaga({ payload }: OrdersFetchTriggerAction) {
  const { search, sort, start } = payload;
  yield put(ordersFetchApiRequest(sort, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: ORDERS_API_URL,
      query: { search: payload.search, sort: payload.sort, start: payload.start },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: OrdersFetchNormalizedResponse = normalize(response, {
      orders: [OrderSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(ordersFetchApiSuccess(sort, start, search, normalizedResponse));
  } catch (error) {
    yield put(ordersFetchApiFailure(sort, start, search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(ORDERS_FETCH_TRIGGER, fetchSaga)]);
}
