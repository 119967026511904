import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Col, Label, LabelProps, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './ModalForm.module.scss';

export interface CommonModalProps {
  isOpen: boolean;
  toggle: () => void;
}

type Props<FormValues> = CommonModalProps & {
  canSubmit?: (props: FormikProps<FormValues>) => boolean;
  cancelButtonLabel?: string;
  children: ((props: FormikProps<FormValues>) => ReactNode) | ReactNode;
  initialValues: FormValues;
  okButtonLabel?: string;
  okButtonNegative?: boolean;
  onAltSubmit?: (values: FormValues) => void;
  onSubmit: (values: FormValues) => void;
  secondButtonLabel?: string;
  size?: string;
  title: string;
  validate?: (values: FormValues) => void | FormikErrors<FormValues>;
  validationSchema?: any /* eslint-disable-line  @typescript-eslint/no-explicit-any */;
};

export const ModalFormLabel: FC<LabelProps> = ({ children, className }: LabelProps) => (
  <Label className={cx(styles.ModalForm__label, className)}>{children}</Label>
);

type ModalRowProps = PropsWithChildren<unknown>;

export const ModalRow: FC = ({ children }: ModalRowProps) => (
  <Row className={styles.ModalForm__fieldRow}>
    <Col>{children}</Col>
  </Row>
);

const ModalForm = <FormValues,>({
  canSubmit: canSubmit_,
  cancelButtonLabel = 'Cancel',
  children,
  initialValues,
  isOpen,
  okButtonLabel = 'Ok',
  okButtonNegative = false,
  onAltSubmit,
  onSubmit,
  secondButtonLabel,
  size,
  title,
  toggle,
  validate,
  validationSchema,
}: Props<FormValues>): ReactElement => {
  const submit = (values: FormValues) => {
    onSubmit(values);
    toggle();
  };

  const handleAltSubmit = (values: FormValues) => {
    if (onAltSubmit) {
      onAltSubmit(values);
    }
    toggle();
  };

  const canSubmit =
    canSubmit_ ||
    ((formikProps: FormikProps<FormValues>) => formikProps.dirty && formikProps.isValid);

  return (
    <Modal
      centered
      contentClassName={styles.ModalForm__content}
      isOpen={isOpen}
      size={size}
      toggle={toggle}
    >
      <ModalHeader className={styles.ModalForm__header}>
        <div className={cx(styles.ModalForm__heading)}>{title}</div>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={validate}
          onSubmit={submit}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Row className={styles.ModalForm__fieldRow}>
                <Col>{typeof children === 'function' ? children(formikProps) : children}</Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <PrimaryButton
                    size="large"
                    onClick={toggle}
                    gray
                    icon={faTimes}
                    className={styles.ModalForm__cancelBtn}
                  >
                    {cancelButtonLabel}
                  </PrimaryButton>
                  {secondButtonLabel && (
                    <PrimaryButton
                      size="large"
                      type="button"
                      icon={faCheck}
                      className={styles.ModalForm__secondBtn}
                      disabled={!canSubmit(formikProps)}
                      onClick={() => handleAltSubmit(formikProps.values)}
                    >
                      {secondButtonLabel}
                    </PrimaryButton>
                  )}
                  <PrimaryButton
                    size="large"
                    type="submit"
                    icon={faCheck}
                    className={styles.ModalForm__saveBtn}
                    negative={okButtonNegative}
                    disabled={!canSubmit(formikProps)}
                  >
                    {okButtonLabel}
                  </PrimaryButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalForm;
