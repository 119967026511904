import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { reloadPageEffect, resetSessionIfNeededSaga } from '../../global/session/sagas';
import { PAYOUTS_API_URL, VENDORS_BARE_API_URL } from '../../global/urls';
import toast from '../../global/utils/toast';
import { apiGet, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import { PayoutSchema, SessionSchema } from '../../schemas';
import {
  payoutsFetchApiFailure,
  payoutsFetchApiRequest,
  payoutsFetchApiSuccess,
  PayoutsFetchNormalizedResponse,
  PayoutsFetchTriggerAction,
} from './actions/fetch.actions';
import { PAYOUTS_FETCH_TRIGGER } from './constants';
import { addTransactionSlice, fetchVendorsBareSlice } from './slices';

function* fetchSaga({ payload }: PayoutsFetchTriggerAction) {
  const { search, sort, start } = payload;
  yield put(payoutsFetchApiRequest(sort, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: PAYOUTS_API_URL,
      query: {
        search: payload.search,
        sort: payload.sort,
        start: payload.start,
      },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: PayoutsFetchNormalizedResponse = normalize(response, {
      payouts: [PayoutSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(payoutsFetchApiSuccess(sort, start, search, normalizedResponse));
  } catch (error) {
    yield put(payoutsFetchApiFailure(sort, start, search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

const fetchVendorsBareSaga = makeApiRequestSaga({
  actions: fetchVendorsBareSlice.actions,
  baseUrl: VENDORS_BARE_API_URL,
  method: 'GET',
  responseSchema: { session: SessionSchema },
});

const addTransactionSaga = makeApiRequestSaga({
  actions: addTransactionSlice.actions,
  baseUrl: PAYOUTS_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: { payout: PayoutSchema, session: SessionSchema },
});

function* successAddTransactionSaga(/* action: AddTransactionSuccessAction */) {
  yield reloadPageEffect();
  yield call(toast.success, `A transaction has been added.`);
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(PAYOUTS_FETCH_TRIGGER, fetchSaga),
    takeLatest(addTransactionSlice.actions.trigger.type, addTransactionSaga),
    takeLatest(addTransactionSlice.actions.success.type, successAddTransactionSaga),
    takeLatest(fetchVendorsBareSlice.actions.trigger.type, fetchVendorsBareSaga),
  ]);
}
