import cx from 'classnames';
import React, { CSSProperties, FC, useMemo } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import styles from '../../../../global/styles/falconTable.module.scss';
import { SortOrderDirection } from '../../../../global/types';
import { CustomerEntity, ShopEntity } from '../../../../schemas';
import { mapSortOrder, mapTableSort } from '../../helpers';
import useOnSortChange from '../../hooks/useOnSortChange';
import { CustomersColumn, CustomersSortOrder } from '../../types';
import columns from './columns';

type Props = {
  customers: CustomerEntity[] | null;
  pageSize: number;
  shop: ShopEntity | null;
  sortOrder: CustomersSortOrder;
};

const CustomersTable: FC<Props> = ({ customers, pageSize, shop, sortOrder }: Props) => {
  const onSortChange = useOnSortChange();

  const rowStyleFormat = (row: CustomerEntity, rowIdx: number): CSSProperties => {
    return { backgroundColor: rowIdx % 2 === 0 ? '' : '#f9fafd' };
  };

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<CustomerEntity>
  ): void => {
    onSortChange(
      mapSortOrder(newState.sortField as CustomersColumn, newState.sortOrder as SortOrderDirection)
    );
  };

  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);

  const data = useMemo(
    () => (customers !== null ? customers : Array.from({ length: pageSize }, (_, id) => ({ id }))),
    [customers, pageSize]
  );

  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      classes={cx(styles.table, 'table align-middle')}
      columns={columns(shop)}
      data={data}
      headerClasses={cx(styles.header, 'fs-1')}
      headerWrapperClasses={cx(styles.headerWrapper)}
      keyField="id"
      rowClasses={cx(styles.row, 'py-2 align-middle')}
      rowStyle={(row, idx) => rowStyleFormat(row, idx)}
      sort={bootstrapTableSort}
      onTableChange={handleTableChange}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
    />
  );
};

export default CustomersTable;
