import cx from 'classnames';
import React, { FC, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ImageItem, ImageItemType } from '../../../../containers/Product/types';
import styles from './TapToUpload.module.scss';

export enum TapToUploadType {
  Big = 'big',
  Small = 'small',
}

export type Props = {
  onAddImage: (image: ImageItem) => void;
  type: TapToUploadType;
};

const TapToUpload: FC<Props> = ({ onAddImage, type }: Props) => {
  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      let inputFiles: File[] = [];
      if ('files' in e.target && e.target.files !== null) {
        inputFiles = Array.from(e.target.files);
      }
      inputFiles.forEach((inputFile) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          onAddImage({
            id: uuidv4(),
            inputFile,
            imagePreviewUrl: typeof reader.result === 'string' ? reader.result : '',
            itemType: ImageItemType.Image,
          });
        };
        reader.readAsDataURL(inputFile);
      });
    },
    [onAddImage]
  );

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={cx(styles.tapToUpload, {
        [styles.tapToUploadBig]: type === TapToUploadType.Big,
        [styles.tapToUploadSmall]: type === TapToUploadType.Small,
      })}
    >
      <input
        type="file"
        onChange={handleImageChange}
        multiple
        accept="image/*"
        className="d-none"
        onClick={(e) => {
          const target = e.target as HTMLInputElement;
          // eslint-disable-next-line no-param-reassign
          target.value = '';
        }}
      />
      <div className={styles.content}>
        {type === TapToUploadType.Big
          ? 'Click here to upload product images (max. 2MB per image)'
          : 'Tap to add new image'}
      </div>
    </label>
  );
};

export default TapToUpload;
