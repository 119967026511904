import cx from 'classnames';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomCheckbox from '../../../../../../components/CustomCheckbox/CustomCheckbox';
import FormInputError from '../../../../../../components/FormInputError/FormInputError';
import { INPUT_ERROR_MESSAGE } from '../../../../../../global/general/constants';
import { validateEmail, validateValueRequired } from '../../../../../../helpers/validators';
import useVendorRegistrationBusinessData from '../../../../hooks/useVendorRegistrationBusinessData';
import useVendorRegistrationContractData from '../../../../hooks/useVendorRegistrationContractData';
import styles from './ContractForm.module.scss';

type Props = {
  setInputHasError: (hasError: boolean) => void;
  submitTouched: boolean;
};

const onlyPhoneChars = (value: string): string => {
  return value.replace(/[^0-9+() -]/gi, '');
};

const ContractForm: FC<Props> = ({ setInputHasError, submitTouched }: Props) => {
  const {
    contract: { authorized, email, name, phone, position, termsAccepted },
    setEmail,
    setName,
    setPhone,
    setPosition,
    toggleAuthorized,
    toggleTermsAccepted,
  } = useVendorRegistrationContractData();
  const {
    general: { legalName },
  } = useVendorRegistrationBusinessData();
  const inputErrors = useMemo(
    () => ({
      authorized: !authorized,
      email: !validateEmail(email),
      name: !validateValueRequired(name),
      phone: !validateValueRequired(phone),
      position: !validateValueRequired(position),
      termsAccepted: !termsAccepted,
    }),
    [authorized, email, name, phone, position, termsAccepted]
  );
  const inputTouched = useMemo(
    () => ({
      authorized: submitTouched,
      email: submitTouched,
      name: submitTouched,
      phone: submitTouched,
      position: submitTouched,
      termsAccepted: submitTouched,
    }),
    [submitTouched]
  );

  const onInputChange = useCallback(
    ({ target }) => {
      const { name: inputName, value, checked } = target;
      switch (inputName) {
        case 'termsAccepted': {
          toggleTermsAccepted();
          inputErrors.termsAccepted = !checked;
          inputTouched.termsAccepted = true;
          break;
        }
        case 'authorized': {
          toggleAuthorized();
          inputErrors.authorized = !checked;
          inputTouched.authorized = true;
          break;
        }
        case 'name': {
          setName(value);
          inputErrors.name = !validateValueRequired(value);
          inputTouched.name = true;
          break;
        }
        case 'position': {
          setPosition(value);
          inputErrors.position = !validateValueRequired(value);
          inputTouched.position = true;
          break;
        }
        case 'email': {
          setEmail(value);
          inputErrors.email = !validateEmail(value);
          inputTouched.email = true;
          break;
        }
        case 'phone': {
          const newValue = onlyPhoneChars(value);
          setPhone(newValue);
          inputErrors.phone = !validateValueRequired(newValue);
          inputTouched.phone = true;
          break;
        }
        default:
          break;
      }
    },
    [
      inputErrors,
      inputTouched,
      setEmail,
      setName,
      setPhone,
      setPosition,
      toggleAuthorized,
      toggleTermsAccepted,
    ]
  );

  useEffect(() => {
    if (Object.values(inputErrors).every((error) => !error)) {
      setInputHasError(false);
    } else {
      setInputHasError(true);
    }
  }, [
    inputErrors,
    inputErrors.authorized,
    inputErrors.termsAccepted,
    inputErrors.email,
    inputErrors.name,
    inputErrors.phone,
    inputErrors.position,
    setInputHasError,
  ]);

  return (
    <Form>
      <Row>
        <Col lg={12}>
          <FormGroup check inline>
            <Label
              check
              className={cx(styles.checkbox, 'd-flex align-items-center user-select-none', {
                'text-danger': inputTouched.termsAccepted && inputErrors.termsAccepted,
              })}
            >
              <div className="mr-3">
                <CustomCheckbox
                  onChange={onInputChange}
                  checked={termsAccepted}
                  name="termsAccepted"
                />
              </div>
              I accept the Terms and Privacy Policy
            </Label>
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup check inline className={cx(styles.checkbox)}>
            <Label
              check
              className={cx(styles.checkbox, 'd-flex align-items-center user-select-none', {
                'text-danger': inputTouched.authorized && inputErrors.authorized,
              })}
            >
              <div className="mr-3">
                <CustomCheckbox onChange={onInputChange} checked={authorized} name="authorized" />
              </div>
              I am authorised to sign on behalf of&nbsp;
              <b className={styles.orgName}>{legalName}</b>
            </Label>
          </FormGroup>
        </Col>
        <Col lg={6} className="mt-4">
          <FormGroup>
            <Label className="sss-form-subtitle">
              Full Name <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={name} onChange={onInputChange} name="name" />
            {inputErrors.name && inputTouched.name && (
              <FormInputError>{INPUT_ERROR_MESSAGE.REQUIRED}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={6} className="mt-4">
          <FormGroup>
            <Label className="sss-form-subtitle">
              Position <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={position} onChange={onInputChange} name="position" />
            {inputErrors.position && inputTouched.position && (
              <FormInputError>{INPUT_ERROR_MESSAGE.REQUIRED}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Email <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={email} onChange={onInputChange} name="email" />
            {inputErrors.email && inputTouched.email && (
              <FormInputError>{INPUT_ERROR_MESSAGE.EMAIL}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Phone <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={phone} onChange={onInputChange} name="phone" />
            {inputErrors.phone && inputTouched.phone && (
              <FormInputError>{INPUT_ERROR_MESSAGE.REQUIRED}</FormInputError>
            )}
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractForm;
