import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import ShippingPoliciesMainContent from './components/ShippingPoliciesMainContent/ShippingPoliciesMainContent';

const ShippingPolicies: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <ShippingPoliciesMainContent key={key} />;
};

export default ShippingPolicies;
