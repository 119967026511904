import cx from 'classnames';
import React, { FC, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import AgreementModal from '../AgreementModal/AgreementModal';
import styles from './AgreementWindow.module.scss';

type Props = {
  title: string;
  textHtml: string;
};

const AgreementWindow: FC<Props> = ({ textHtml, title }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <h5>{title}</h5>
        <button type="button" className={cx(styles.link)} onClick={() => setIsOpen(true)}>
          Open in new window
        </button>
      </div>
      <div className={cx(styles.textWrapper)}>
        {textHtml ? (
          // eslint-disable-next-line react/no-danger
          <div className="fs-13 pl-3 pt-3 pb-3" dangerouslySetInnerHTML={{ __html: textHtml }} />
        ) : (
          <div className={cx('align-items-center d-flex h-100 justify-content-center')}>
            <BeatLoader color="#ff8848" />
          </div>
        )}
      </div>
      {isOpen && textHtml && (
        <AgreementModal textHtml={textHtml} onClose={() => setIsOpen(false)} title={title} />
      )}
    </div>
  );
};

export default AgreementWindow;
