import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { RESET_SESSION } from '../../../global/session/constants';
import { SHIPPING_TYPES_URL } from '../../../global/urls';
import { makeApiRequestSlice } from '../../../helpers/apiRequestSlice';
import { ShippingType } from '../../../schemas';
import { NormalizedResponse } from '../../../types';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';

export type ShippingTypesResponse = NormalizedResponse & {
  result: {
    shippingTypes: ShippingType[];
  };
};

export type ShippingTypesFetchPayload = void;
export type ShippingTypesSuccessPayload = {
  request: ShippingTypesFetchPayload;
  response: ShippingTypesResponse;
};

export const fetchShippingTypesSlice = makeApiRequestSlice<
  ShippingTypesFetchPayload,
  ShippingTypesSuccessPayload
>({
  name: 'shippingTypes/fetch',
});

export type ShippingTypesPageState = {
  shippingTypeIds: number[] | null;
};

const shippingTypesPageInitialState: ShippingTypesPageState = {
  shippingTypeIds: null,
};

export type CreateShippingTypeFetchPayload = {
  name: string;
};
export type CreateShippingTypeSuccessPayload = {
  request: CreateShippingTypeFetchPayload;
  response: ShippingTypesResponse;
};

export const createShippingTypeSlice = makeApiRequestSlice<
  CreateShippingTypeFetchPayload,
  CreateShippingTypeSuccessPayload
>({
  name: 'shippingTypes/create',
});

export type SaveShippingTypesOrderFetchPayload = {
  shippingTypeIds: number[];
};
export type SaveShippingTypesOrderSuccessPayload = {
  request: SaveShippingTypesOrderFetchPayload;
  response: ShippingTypesResponse;
};

export const saveShippingTypesOrderSlice = makeApiRequestSlice<
  SaveShippingTypesOrderFetchPayload,
  SaveShippingTypesOrderSuccessPayload
>({ name: 'shippingTypes/saveOrder' });

export type ReorderArgs = { oldIndex: number; newIndex: number };

export type UpdateShippingTypeTriggerFetchPayload = {
  id: number;
  name: string;
};
export type UpdateShippingTypeTriggerSuccessPayload = {
  request: UpdateShippingTypeTriggerFetchPayload;
  response: ShippingTypesResponse;
};

export const updateShippingTypeSlice = makeApiRequestSlice<
  UpdateShippingTypeTriggerFetchPayload,
  UpdateShippingTypeTriggerSuccessPayload
>({ name: 'shippingTypes/update' });

export type DeleteShippingTypeTriggerFetchPayload = {
  id: number;
};
export type DeleteShippingTypeTriggerSuccessPayload = {
  request: DeleteShippingTypeTriggerFetchPayload;
  response: ShippingTypesResponse;
};

export const deleteShippingTypeSlice = makeApiRequestSlice<
  DeleteShippingTypeTriggerFetchPayload,
  DeleteShippingTypeTriggerSuccessPayload
>({ name: 'shippingTypes/delete' });

const resetState = () => shippingTypesPageInitialState;

export const shippingTypesPageSlice = createSlice({
  name: 'shippingTypesPage',
  initialState: shippingTypesPageInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShippingTypesSlice.actions.success, (draft, action) => {
      draft.shippingTypeIds = action.payload.response.result.shippingTypes;
    });

    builder.addCase(createShippingTypeSlice.actions.success, (draft, action) => {
      draft.shippingTypeIds = action.payload.response.result.shippingTypes;
    });

    builder.addCase(saveShippingTypesOrderSlice.actions.trigger, (draft, action) => {
      draft.shippingTypeIds = action.payload.shippingTypeIds;
    });

    builder.addCase(saveShippingTypesOrderSlice.actions.success, (draft, action) => {
      draft.shippingTypeIds = action.payload.response.result.shippingTypes;
    });

    builder.addCase(deleteShippingTypeSlice.actions.success, (draft, action) => {
      draft.shippingTypeIds = action.payload.response.result.shippingTypes;
    });

    builder.addCase(LOCATION_CHANGE, (draft, action) => {
      const { location } = (action as LocationChangeAction).payload;
      const locationMatch = matchPath(location.pathname, {
        path: SHIPPING_TYPES_URL,
        exact: true,
      });
      return locationMatch ? shippingTypesPageInitialState : draft;
    });

    builder.addCase(LOGIN_API_SUCCESS, resetState);
    builder.addCase(LOGOUT_API_SUCCESS, resetState);
    builder.addCase(RESET_SESSION, resetState);
    builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
  },
});
