import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      CUSTOMIZABLE: 'Customizable',
      CUSTOMIZABLE_TOOLTIP: 'Some text to explain Customizable checkbox',
      CUSTOMIZATION: 'Customization',
      LEGAL_NAME_OF_YOUR_ORG: 'Legal Name of Your Organization',
    },
  },
  'en-GB': {
    translation: {
      CUSTOMIZABLE: 'Customisable',
      CUSTOMIZABLE_TOOLTIP: 'Some text to explain Customisable checkbox',
      CUSTOMIZATION: 'Customisation',
      LEGAL_NAME_OF_YOUR_ORG: 'Legal Name of Your Organisation',
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'en-GB',
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
