import { NormalizedError } from '../../types';

export type ProductsQueryState = {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  productVersionIds: number[] | null;
};

export interface ProductsSearchState {
  allIds: number[];
  allProductIds: number[];
}

export interface ProductsPageState {
  queries: { [queryKey: string]: ProductsQueryState };
  searches: { [search: string]: ProductsSearchState };
}

export enum SortableProductsColumn {
  inventory = 'inventory',
  lastPublishedAt = 'lastPublishedAt',
  name = 'name',
  buyPrice = 'buyPrice',
  price = 'price',
  status = 'status',
  vendor = 'vendor',
}

export enum NonSortableProductsColumn {
  image = 'image',
}

export enum ProductsSortOrder {
  inventory = 'inventory',
  inventoryDesc = '-inventory',
  lastPublishedAt = 'lastPublishedAt',
  lastPublishedAtDesc = '-lastPublishedAt',
  name = 'name',
  nameDesc = '-name',
  price = 'price',
  priceDesc = '-price',
  status = 'status',
  statusDesc = '-status',
  vendor = 'vendor',
  vendorDesc = '-vendor',
}

export enum ProductsType {
  Active = 'active',
  Archived = 'archived',
}
