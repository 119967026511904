import { SagaIterator } from '@redux-saga/core';
import { all, takeLatest } from 'redux-saga/effects';
import { INVENTORY_ALERTS_API_URL } from '../../global/urls';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { InventoryItemSchema, SessionSchema } from '../../schemas';
import { fetchInventoryAlertsSlice } from './slices';

const fetchInventoryAlertsSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: INVENTORY_ALERTS_API_URL,
  responseSchema: {
    inventoryItems: [InventoryItemSchema],
    session: SessionSchema,
  },
  actions: fetchInventoryAlertsSlice.actions,
});

export default function* sagas(): SagaIterator {
  yield all([takeLatest(fetchInventoryAlertsSlice.actions.trigger, fetchInventoryAlertsSaga)]);
}
