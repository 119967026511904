import cx from 'classnames';
import React, { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';
import BrandBadge from '../../../../components/BrandBadge/BrandBadge';
import ProductsOrderBadge from '../../../../components/ProductsOrderBadge/ProductsOrderBadge';
import VendorBadge from '../../../../components/VendorBadge/VendorBadge';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { COLLECTION_URL } from '../../../../global/urls';
import { CollectionEntity, CollectionProductsOrder } from '../../../../schemas';
import CollectionActionsDropdown from '../CollectionActionsDropdown/CollectionActionsDropdown';
import styles from './formatters.module.scss';

export interface CollectionsFormatterExtraData {
  onEdit: (collection: CollectionEntity) => void;
}

export const nameFormatter = (name: string, row: CollectionEntity): ReactElement => {
  if (row.title === undefined) return <></>;
  return (
    <div className={styles.name}>
      <Link
        className={styles.name__link}
        to={generatePath(COLLECTION_URL, { collectionId: row.id })}
      >
        <div className={cx(tableStyles.textDark, styles.name__link__name)}>{name}</div>
      </Link>
    </div>
  );
};

export const managedByFormatter = (email: string, row: CollectionEntity): ReactElement => {
  if (row.isVendorManaged === undefined) return <></>;
  return row.isVendorManaged ? <VendorBadge /> : <BrandBadge />;
};

export const productsOrderFormatter = (productsOrder: CollectionProductsOrder): ReactElement => {
  if (productsOrder === undefined) return <></>;
  return <ProductsOrderBadge status={productsOrder} />;
};

export const productsCountFormatter = (productsCount: number): ReactElement => (
  <span className={tableStyles.textDark}>{productsCount}</span>
);

export const actionsFormatter = (
  _1: unknown,
  row: CollectionEntity,
  _2: number,
  extraData: CollectionsFormatterExtraData
): ReactElement => {
  const { onEdit } = extraData;
  return <CollectionActionsDropdown onEdit={() => onEdit(row)} collection={row} />;
};
