import { Field } from 'formik';
import React, { FC, useMemo } from 'react';
import { Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import { getAvailableCountries } from '../../../../global/countries/countries';
import { statesByCountries } from '../../../../global/countries/states';

interface Props {
  selectedCountry: string;
}

const VendorProfileRegisteredAddress: FC<Props> = ({ selectedCountry }: Props) => {
  const countries = useMemo(() => getAvailableCountries(), []);
  const states = statesByCountries[selectedCountry];
  return (
    <Card className="h-lg-100 mb-3 p-3">
      <h4 className="sss-form-title">
        Registered Address <CustomTooltip text="The registered address of your company" />
      </h4>
      <Row className="d-flex justify-content-between">
        <Col lg={12}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Address Line 1 <span className="sss-color-orange">*</span>
            </Label>
            <Field as={Input} name="regAddress1" type="text" />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label className="sss-form-subtitle">Address Line 2</Label>
            <Field as={Input} name="regAddress2" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              City <span className="sss-color-orange">*</span>
            </Label>
            <Field as={Input} name="regCity" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Zip Code or Postcode <span className="sss-color-orange">*</span>
            </Label>
            <Field as={Input} name="regPostCode" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Country <span className="sss-color-orange">*</span>
            </Label>
            <Field as={Input} name="regCountry" type="select">
              {countries.map((country) => (
                <option key={country.abbr} value={country.abbr}>
                  {country.title}
                </option>
              ))}
            </Field>
          </FormGroup>
        </Col>
        <Col lg={6}>
          {states && (
            <FormGroup>
              <Label className="sss-form-subtitle">
                State <span className="sss-color-orange">*</span>
              </Label>
              <Field as={Input} disabled={!states} name="regState" type="select">
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Field>
            </FormGroup>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default VendorProfileRegisteredAddress;
