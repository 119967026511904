import { faThumbsDown, faThumbsUp, faUndo } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import useToggle from '../../../../components/ModalForm/useToggle';
import { WarehouseProductEntity, WarehouseProductStatus } from '../../../../schemas';
import {
  ApproveProductsRequestPayload,
  approveProductsSlice,
  RejectProductsRequestPayload,
  rejectProductsSlice,
} from '../../slices';
import ApproveProductsModal from '../ApproveProductsModal/ApproveProductsModal';
import RejectProductsModal from '../RejectProductsModal/RejectProductsModal';

export type Props = {
  product: WarehouseProductEntity;
};

const WarehouseSingleActionsDropdown: FC<Props> = ({ product }: Props) => {
  const [approveProductsOpen, approveProductsToggle] = useToggle();
  const [rejectProductsOpen, rejectProductsToggle] = useToggle();

  const dropDownItems: DropDownItemDefinition[] = [];

  if (product.status !== WarehouseProductStatus.Approved) {
    dropDownItems.push(...[{ text: 'Approve', icon: faThumbsUp, onClick: approveProductsToggle }]);
  }

  if (product.status !== WarehouseProductStatus.Rejected) {
    dropDownItems.push(...[{ text: 'Reject', icon: faThumbsDown, onClick: rejectProductsToggle }]);
  }

  if (product.status !== WarehouseProductStatus.Available) {
    dropDownItems.push(...[null, { text: 'Return to Available', icon: faUndo, onClick: () => {} }]);
  }

  const dispatch = useDispatch();
  const approveProductsTrigger = (payload: ApproveProductsRequestPayload) =>
    dispatch(approveProductsSlice.actions.trigger(payload));
  const rejectProductsTrigger = (payload: RejectProductsRequestPayload) =>
    dispatch(rejectProductsSlice.actions.trigger(payload));

  return (
    <>
      <DropdownMenu
        component="item"
        items={dropDownItems}
        // disabled={disabled}
      />
      <ApproveProductsModal
        isOpen={approveProductsOpen}
        toggle={approveProductsToggle}
        selectedProducts={new Set([product.productId])}
        submit={approveProductsTrigger}
      />
      <RejectProductsModal
        isOpen={rejectProductsOpen}
        toggle={rejectProductsToggle}
        selectedProducts={new Set([product.productId])}
        submit={rejectProductsTrigger}
      />
    </>
  );
};

export default WarehouseSingleActionsDropdown;
