import { Field } from 'formik';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import ModalForm, {
  CommonModalProps,
  ModalFormLabel,
} from '../../../../components/ModalForm/ModalForm';
import { ShippingType } from '../../../../schemas';
import { createShippingTypeSlice, updateShippingTypeSlice } from '../../slices/shippingTypesSlice';

type CreateProps = CommonModalProps;

type FormValues = {
  name: string;
};

export const CreateShippingTypeModal: FC<CreateProps> = (props: CreateProps) => {
  const dispatch = useDispatch();
  const onSubmit = (values: { name: string }) =>
    dispatch(createShippingTypeSlice.actions.trigger(values));

  const initialValues: FormValues = {
    name: '',
  };

  return (
    <ModalForm
      title="Add Shipping Type"
      okButtonLabel="Create"
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...props}
    >
      <FormGroup>
        <ModalFormLabel for="shipping-type-name">Name</ModalFormLabel>
        <Field as={Input} id="shipping-type-name" name="name" placeholder="Name" type="text" />
      </FormGroup>
    </ModalForm>
  );
};

type EditProps = CommonModalProps & {
  shippingType: ShippingType;
};

export const EditShippingTypeModal: FC<EditProps> = ({
  isOpen,
  toggle,
  shippingType,
}: EditProps) => {
  const { id, name: initialName } = shippingType;

  const initialValues: FormValues = {
    name: initialName,
  };

  const dispatch = useDispatch();
  const onSubmit = ({ name }: FormValues) =>
    dispatch(updateShippingTypeSlice.actions.trigger({ id, name }));

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      title="Edit Shipping Type"
      okButtonLabel="Update"
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <FormGroup>
        <ModalFormLabel for="shipping-type-name">Name</ModalFormLabel>
        <Field as={Input} id="shipping-type-name" name="name" placeholder="Name" type="text" />
      </FormGroup>
    </ModalForm>
  );
};
