import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  WAREHOUSE_FETCH_API_FAILURE,
  WAREHOUSE_FETCH_API_REQUEST,
  WAREHOUSE_FETCH_API_SUCCESS,
  WAREHOUSE_FETCH_TRIGGER,
} from '../constants';
import { WarehouseSortOrder, WarehouseType } from '../types';

// action types

type ProductsFetchPayload = {
  sort: WarehouseSortOrder;
  start: number;
  search: string;
  type: WarehouseType;
  inStock: boolean;
};

export interface ProductsFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    count: number;
    ids: number[];
    allProductIds: number[];
    briefProductVersions: number[];
  };
}

export interface ProductsFetchApiRequestAction extends APIRequestAction {
  type: typeof WAREHOUSE_FETCH_API_REQUEST;
  payload: ProductsFetchPayload;
}

export type ProductsFetchTriggerAction = {
  type: typeof WAREHOUSE_FETCH_TRIGGER;
  payload: ProductsFetchPayload;
};

export interface ProductsFetchApiSuccessAction extends APISuccessAction {
  type: typeof WAREHOUSE_FETCH_API_SUCCESS;
  payload: ProductsFetchPayload & {
    response: ProductsFetchNormalizedResponse;
  };
}

export interface ProductsFetchApiFailureAction extends APIFailureAction {
  type: typeof WAREHOUSE_FETCH_API_FAILURE;
  payload: ProductsFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const productsFetchTrigger = (
  sort: WarehouseSortOrder,
  start: number,
  search: string,
  type: WarehouseType,
  inStock: boolean
): ProductsFetchTriggerAction => ({
  type: WAREHOUSE_FETCH_TRIGGER,
  payload: {
    inStock,
    search,
    sort,
    start,
    type,
  },
});

export const productsFetchApiRequest = (
  sort: WarehouseSortOrder,
  start: number,
  search: string,
  type: WarehouseType,
  inStock: boolean
): ProductsFetchApiRequestAction => ({
  type: WAREHOUSE_FETCH_API_REQUEST,
  payload: { inStock, search, sort, start, type },
});

export const productsFetchApiSuccess = (
  sort: WarehouseSortOrder,
  start: number,
  search: string,
  type: WarehouseType,
  inStock: boolean,
  response: ProductsFetchNormalizedResponse
): ProductsFetchApiSuccessAction => ({
  type: WAREHOUSE_FETCH_API_SUCCESS,
  payload: { inStock, response, search, sort, start, type },
});

export const productsFetchApiFailure = (
  sort: WarehouseSortOrder,
  start: number,
  search: string,
  type: WarehouseType,
  inStock: boolean,
  error: NormalizedError
): ProductsFetchApiFailureAction => ({
  type: WAREHOUSE_FETCH_API_FAILURE,
  payload: { error, inStock, search, sort, start, type },
});
