import { FieldProps } from 'formik';
import React from 'react';
import Select, { OptionsType, ValueType } from 'react-select';

interface Option {
  label: string;
  value: string;
}

interface FormikSelectProps extends FieldProps {
  isLoading?: boolean;
  isMulti?: boolean;
  options: OptionsType<Option>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FormikSelect = ({
  field,
  form,
  isLoading = false,
  isMulti = false,
  options,
}: FormikSelectProps) => {
  const onChange = (option: ValueType<Option | Option[], boolean>) => {
    form.setFieldValue(
      field.name,
      isMulti ? (option as Option[]).map((item: Option) => item.value) : (option as Option).value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    }
    return isMulti ? [] : ('' as never);
  };

  return (
    <Select
      isLoading={isLoading}
      isMulti={isMulti}
      name={field.name}
      onChange={onChange}
      options={options}
      value={getValue()}
    />
  );
};

export default FormikSelect;

// taken from https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
