import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import OrderMainContent from './components/OrderMainContent/OrderMainContent';

const Order: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <OrderMainContent key={key} />;
};

export default Order;
