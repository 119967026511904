import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GiftCardCodesSortOrder } from '../types';
import { replaceGiftCardsUrl } from '../utils';
import useGiftCardsQueryParams from './useGiftCardsQueryParams';

const useOnCodesSortChange = (): ((newSort: GiftCardCodesSortOrder) => void) => {
  const dispatch = useDispatch();
  const { codesSortOrder, productsSortOrder, search, start } = useGiftCardsQueryParams();

  return useCallback(
    (newSort: GiftCardCodesSortOrder) => {
      if (newSort !== codesSortOrder) {
        replaceGiftCardsUrl(dispatch, {
          search,
          sortCodes: newSort,
          sortProducts: productsSortOrder,
          start,
        });
      }
    },
    [codesSortOrder, dispatch, productsSortOrder, search, start]
  );
};

export default useOnCodesSortChange;
