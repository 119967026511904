import cx from 'classnames';
import { push } from 'connected-react-router';
import React, { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import tableStyles from '../../global/styles/falconTable.module.scss';
import { PRODUCT_URL } from '../../global/urls';
import { BriefProductEntity } from '../../schemas';
import styles from './columns.module.scss';

export interface FormatterExtraData {
  dispatch: Dispatch;
}

export const nameFormatter = (name: string, row: BriefProductEntity): ReactElement => {
  const productUrl = generatePath(PRODUCT_URL, { productId: row.productId });
  return (
    <div className={styles.name}>
      <Link className={styles.name__link} to={productUrl}>
        <div className={cx(tableStyles.textDark, styles.name__link__name)}>{name}</div>
      </Link>
    </div>
  );
};

export const actionFormatter = (
  _0: number,
  row: BriefProductEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { dispatch } = formatExtraData;
  const handleClick = () => {
    const productUrl = generatePath(PRODUCT_URL, { productId: row.productId });
    dispatch(push(productUrl));
  };
  return (
    <SecondaryButton onClick={handleClick} size="small">
      View
    </SecondaryButton>
  );
};
