import { NormalizedError } from '../../types';

export type WarehouseQueryState = {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  productVersionIds: number[] | null;
};

export interface WarehouseSearchState {
  allIds: number[];
  allProductIds: number[];
}

export interface WarehousePageState {
  queries: { [queryKey: string]: WarehouseQueryState };
  searches: { [search: string]: WarehouseSearchState };
}

export enum SortableWarehouseColumn {
  inventory = 'inventory',
  lastPublishedAt = 'lastPublishedAt',
  name = 'name',
  price = 'price',
  status = 'status',
  vendor = 'vendor',
}

export enum NonSortableWarehouseColumn {
  image = 'image',
  categories = 'categories',
}

export enum WarehouseSortOrder {
  inventory = 'inventory',
  inventoryDesc = '-inventory',
  lastPublishedAt = 'lastPublishedAt',
  lastPublishedAtDesc = '-lastPublishedAt',
  name = 'name',
  nameDesc = '-name',
  price = 'price',
  priceDesc = '-price',
  status = 'status',
  statusDesc = '-status',
  vendor = 'vendor',
  vendorDesc = '-vendor',
}

export enum WarehouseType {
  All = 'all',
  Available = 'available',
  Approved = 'approved',
  Rejected = 'rejected',
}
