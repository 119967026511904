import { ErrorMessage, Field } from 'formik';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import { AppState } from '../../../../init/rootReducer';
import { VendorEntity } from '../../../../schemas';
import { deleteVendorInviteSlice } from '../../slices';
import { VendorsType } from '../../types';

export type Props = {
  isOpen: boolean;
  toggle: () => void;
  vendorOrInviteId: number;
  vendorsType: VendorsType;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VendorRemoveModal: FC<Props> = ({ isOpen, toggle, vendorOrInviteId, vendorsType }: Props) => {
  const currentName = useSelector(
    (state: AppState) =>
      (state.entities.vendors as Record<number, VendorEntity>)[vendorOrInviteId].name
  );

  const dispatch = useDispatch();
  const onSubmit = () =>
    dispatch(deleteVendorInviteSlice.actions.trigger({ inviteId: vendorOrInviteId }));

  return (
    <ModalForm
      canSubmit={() => true}
      initialValues={{ name: currentName }}
      isOpen={isOpen}
      okButtonLabel="Remove"
      okButtonNegative
      onSubmit={onSubmit}
      title="Remove Invite"
      toggle={toggle}
    >
      <FormGroup>
        <Field as={Input} disabled id="name" name="name" type="text" />
        <ErrorMessage name="name" component={FormInputError} />
      </FormGroup>
    </ModalForm>
  );
};

export default VendorRemoveModal;
