import React, { FC } from 'react';
import { SelectedRowsInfo } from '../../../../components/FalconTable/selectRow';
import { VendorsType } from '../../types';

type Props = {
  count: number;
  numItems: number | undefined;
  onSelectAll: () => void;
  selected: number[];
  vendorsType: VendorsType;
};

const VendorsLeftControls: FC<Props> = ({
  count,
  numItems,
  onSelectAll,
  selected,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  vendorsType,
}: Props) => {
  if (!numItems) {
    return null;
  }
  return (
    <>
      {/* <VendorsActionsDropdown component="header" selected={selected} vendorsType={vendorsType} /> */}
      <SelectedRowsInfo numSelected={selected.length} total={count} selectAll={onSelectAll} />
    </>
  );
};

export default VendorsLeftControls;
