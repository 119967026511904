import produce from 'immer';
import { Reducer } from 'redux';
import { GiftCardsQueryActions } from '../actionTypes';
import {
  GIFT_CARDS_FETCH_API_FAILURE,
  GIFT_CARDS_FETCH_API_REQUEST,
  GIFT_CARDS_FETCH_API_SUCCESS,
} from '../constants';
import { GiftCardCodesQueryState } from '../types';

export const initialQueryState: GiftCardCodesQueryState = {
  codesCount: 0,
  error: null,
  giftCardCodeIds: null,
  giftCardProductIds: null,
  loaded: false,
};

export const giftCardsQueryReducer: Reducer<GiftCardCodesQueryState, GiftCardsQueryActions> = (
  state: GiftCardCodesQueryState = initialQueryState,
  action: GiftCardsQueryActions
): GiftCardCodesQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case GIFT_CARDS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case GIFT_CARDS_FETCH_API_SUCCESS: {
        draft.codesCount = action.payload.response.result.codesCount;
        draft.loaded = true;
        draft.giftCardCodeIds = action.payload.response.result.giftCardCodes;
        draft.giftCardProductIds = action.payload.response.result.giftCardProducts;
        break;
      }

      case GIFT_CARDS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
