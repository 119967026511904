import cx from 'classnames';
import { FormikProps } from 'formik';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, FormGroup, Label, Row } from 'reactstrap';
import tapStyles from '../../../../components/SortableGallery/components/TapToUpload/TapToUpload.module.scss';
import { setLogoUrl } from '../../slices';
import { FormValues } from '../../types';
import styles from './BrandProfileMainInformation.module.scss';

interface ImageInputProps {
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageInput: FC<ImageInputProps> = ({ onChange }: ImageInputProps) => {
  return (
    <input
      type="file"
      onChange={onChange}
      accept="image/*"
      className="d-none"
      onClick={(e) => {
        const target = e.target as HTMLInputElement;
        // eslint-disable-next-line no-param-reassign
        target.value = '';
      }}
    />
  );
};

interface Props {
  fprops: FormikProps<FormValues>;
  logoUrl: string | null;
}

const BrandProfileMainInformation: FC<Props> = ({ fprops, logoUrl }: Props) => {
  const dispatch = useDispatch();
  const { setFieldValue } = fprops;

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      let inputFiles: File[] = [];
      if ('files' in e.target && e.target.files !== null) {
        inputFiles = Array.from(e.target.files);
      }
      inputFiles.forEach((inputFile) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = typeof reader.result === 'string' ? reader.result : '';
          setFieldValue('logoFileName', inputFile.name);
          setFieldValue('logoFileData', data);
          dispatch(setLogoUrl(data));
        };
        reader.readAsDataURL(inputFile);
      });
    },
    [dispatch, setFieldValue]
  );

  return (
    <Card className="h-lg-100 mb-3 p-3">
      <h4 className="sss-form-title">General Info</h4>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Logo</Label>
            <div>
              {logoUrl ? (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className={cx(styles.BrandProfileMainInformation__logo)}>
                  <ImageInput onChange={handleImageChange} />
                  <img alt="Logo" style={{ maxHeight: '100px', maxWidth: '100px' }} src={logoUrl} />
                </label>
              ) : (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className={cx(tapStyles.tapToUpload, tapStyles.tapToUploadSmall)}>
                  <ImageInput onChange={handleImageChange} />
                  <div className={tapStyles.content}>Tap to upload logo</div>
                </label>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
};

export default BrandProfileMainInformation;
