import cx from 'classnames';
import React, { FC, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';
import { ProductCollection } from '../../types';
import styles from '../ProductCollections/ProductCollections.module.scss';

interface ItemProps {
  checked?: boolean;
  name: string;
  toggleItem: () => void;
}

const Item: FC<ItemProps> = ({ checked = false, name, toggleItem }: ItemProps) => {
  return (
    <FormGroup>
      <Label
        check
        className={cx(styles.checkbox, 'd-flex align-items-center gray-900 user-select-none')}
      >
        <div className="mr-2">
          <CustomCheckbox checked={checked} name={name} onChange={toggleItem} />
        </div>
        <span className="mr-2">{name}</span>
      </Label>
    </FormGroup>
  );
};

export type CollectionsListProps = {
  collections: ProductCollection[];
  toggleCollectionItem: (id: number) => void;
};

const CollectionsList: FC<CollectionsListProps> = ({
  collections,
  toggleCollectionItem,
}: CollectionsListProps) => {
  const [searchValue, setSearchValue] = useState('');
  const filteredCollections = searchValue
    ? collections.filter(
        (collectionItem) =>
          collectionItem.name.toLocaleLowerCase().search(searchValue.toLocaleLowerCase()) !== -1
      )
    : collections;

  return (
    <>
      <Input
        bsSize="sm"
        type="search"
        placeholder="Search..."
        className="mb-4"
        onChange={(event) => {
          setSearchValue(event.target.value);
        }}
      />
      <div className={styles.collections}>
        {filteredCollections.map((item) => {
          return (
            <Item
              key={item.id}
              name={item.name}
              checked={item.checked}
              toggleItem={() => toggleCollectionItem(item.id)}
            />
          );
        })}
      </div>
    </>
  );
};

export default CollectionsList;
