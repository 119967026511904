import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { OrdersSortOrder } from './types';

// fetch
export const ORDERS_FETCH_TRIGGER = 'ORDERS_FETCH_TRIGGER';
export const ORDERS_FETCH_API = 'ORDERS_FETCH_API';
export const ORDERS_FETCH_API_REQUEST = 'ORDERS_FETCH_API_REQUEST';
export const ORDERS_FETCH_API_SUCCESS = 'ORDERS_FETCH_API_SUCCESS';
export const ORDERS_FETCH_API_FAILURE = 'ORDERS_FETCH_API_FAILURE';

// select
export const ORDERS_ADD_SELECTED_ORDERS = 'ORDERS_ADD_SELECTED_ORDERS';
export const ORDERS_REMOVE_SELECTED_ORDERS = 'ORDERS_REMOVE_SELECTED_ORDERS';
export const ORDERS_SELECT_ALL = 'ORDERS_SELECT_ALL';
export const ORDERS_TOGGLE_SELECTED_ORDER = 'ORDERS_TOGGLE_SELECTED_ORDER';

export const defaultSortOrder: OrdersSortOrder = OrdersSortOrder.createdAtDesc;

export const PAGE_SIZE = 15;
export const PAGING_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
