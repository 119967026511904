import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../init/rootReducer';
import { ProductEnquiry, ProductStatus } from '../../../schemas';
import {
  productSetProductDescription,
  productSetProductTitle,
} from '../actions/description.actions';
import {
  productSetPropertiesAgeRestricted,
  productSetPropertiesCustomizable,
  productSetPropertiesEnquiry,
} from '../actions/properties.actions';
import {
  productSetSeoDescription,
  productSetSeoTitle,
  productSetSeoUrl,
} from '../actions/seo.actions';
import { productAddProductTag, productRemoveProductTag } from '../actions/tags.actions';
import { getProductState } from '../selectors';
import { ProductItemInformation, ProductItemProperties, ProductItemSeo } from '../types';
import useProductParams from './useProductParams';

type ProductDetailsData = {
  information: ProductItemInformation;
  seo: ProductItemSeo;
  properties: ProductItemProperties;
  setInfoTitle(title: string): void;
  setInfoDescription(description: string): void;
  addProductTag(tag: string): void;
  removeProductTag(tag: string): void;
  setSeoTitle(title: string): void;
  setSeoDescription(description: string): void;
  setSeoUrl(url: string): void;
  setPropertiesAgeRestricted(): void;
  setPropertiesCustomizable(): void;
  setPropertiesEnquiry(value: ProductEnquiry): void;
  status: ProductStatus | null;
};

const useProductDetailsData = (): ProductDetailsData => {
  const dispatch = useDispatch();
  const { productId } = useProductParams();
  const { information, seo, properties, status } = useSelector((state: AppState) =>
    getProductState(state, productId)
  );
  return {
    information,
    seo,
    properties,
    setInfoTitle: (title: string) => dispatch(productSetProductTitle(productId, title)),
    setInfoDescription: (description: string) =>
      dispatch(productSetProductDescription(productId, description)),
    addProductTag: (tag: string) => dispatch(productAddProductTag(productId, tag)),
    removeProductTag: (tag: string) => dispatch(productRemoveProductTag(productId, tag)),
    setSeoTitle: (title: string) => dispatch(productSetSeoTitle(productId, title)),
    setSeoDescription: (description: string) =>
      dispatch(productSetSeoDescription(productId, description)),
    setSeoUrl: (url: string) => dispatch(productSetSeoUrl(productId, url)),
    setPropertiesAgeRestricted: () => dispatch(productSetPropertiesAgeRestricted(productId)),
    setPropertiesCustomizable: () => dispatch(productSetPropertiesCustomizable(productId)),
    setPropertiesEnquiry: (value: ProductEnquiry) =>
      dispatch(productSetPropertiesEnquiry(productId, value)),
    status,
  };
};

export default useProductDetailsData;
