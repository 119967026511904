import { push } from 'connected-react-router';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { SelectedRowsInfo, useTableSelectRows } from '../../../../components/FalconTable/selectRow';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { COLLECTION_URL } from '../../../../global/urls';
import { CollectionEntity } from '../../../../schemas';
import { PAGE_SIZE, PAGINATION_DISPLAYED_RANGE } from '../../constants';
import useCollectionsPageData from '../../hooks/useCollectionsPageData';
import { pushCollectionsUrl, replaceCollectionsUrl } from '../../utils';
import CollectionsActionsDropdown from '../CollectionsActionsDropdown/CollectionsActionsDropdown';
import CollectionsEditModal, {
  CollectionsEditFormValues,
} from '../CollectionsEditModal/CollectionsEditModal';
import CollectionsPagination from '../CollectionsPagination/CollectionsPagination';
import CollectionsRightControls from '../CollectionsRightControls/CollectionsRightControls';
import CollectionsTable from '../CollectionsTable/CollectionsTable';

const CollectionsMainContent: FC = () => {
  useScrollToTop();

  const dispatch = useDispatch();
  const [modalCollection, setModalCollection] = useState<CollectionEntity | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);
  const openEditModal = (collection: CollectionEntity | null) => {
    setModalCollection(collection);
    setIsEditModalOpen(true);
  };

  const pushCollectionDetail = (collection: CollectionEntity | null) =>
    dispatch(push(generatePath(COLLECTION_URL, { collectionId: collection?.id || 0 })));

  const {
    collectionsEditTrigger,
    collectionsFetchTrigger,
    allIds,
    count,
    correctLocation,
    collections,
    error,
    isLoading,
    loaded,
    search,
    shopUrl,
    sortOrder,
    start,
  } = useCollectionsPageData();

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: replaceCollectionsUrl,
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushCollectionsUrl,
    searchInput,
    sortOrder,
    start,
  });

  const { selected, selectRow, selectAll } = useTableSelectRows<CollectionEntity>(allIds);
  const numSelected = selected.size;

  if (error) {
    return <TableError error={error} />;
  }

  const handleEditSubmit = (collectionId: number, values: CollectionsEditFormValues) => {
    collectionsEditTrigger(collectionId, values.title, values.handle, values.managedBy);
  };

  return (
    <TablePageWrapper
      title="Collections"
      leftControls={
        <>
          <CollectionsActionsDropdown disabled={numSelected === 0} selectedCollections={selected} />
          <SelectedRowsInfo numSelected={numSelected} total={count} selectAll={selectAll} />
        </>
      }
      rightControls={
        <CollectionsRightControls
          onAddCollection={() => openEditModal(null)}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      }
      itemsCount={count}
      pagination={
        <CollectionsPagination
          activePage={activePage}
          displayedRange={PAGINATION_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={collectionsFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(collections && collections.length > 0) || loaded || !correctLocation}
      >
        {collections && collections.length > 0 ? (
          <CollectionsTable
            collections={collections}
            onCollectionEdit={pushCollectionDetail}
            pageSize={PAGE_SIZE}
            sortOrder={sortOrder}
            selectRow={selectRow}
          />
        ) : (
          <TableNoResults search={search} />
        )}
        <CollectionsEditModal
          collection={modalCollection}
          isOpen={isEditModalOpen}
          onSubmit={handleEditSubmit}
          shopUrl={shopUrl}
          toggle={toggleEditModal}
        />
      </LoadingSwitch>
    </TablePageWrapper>
  );
};

export default CollectionsMainContent;
