import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CollectionsSortOrder } from '../types';
import { replaceCollectionsUrl } from '../utils';
import useCollectionsQueryParams from './useCollectionsQueryParams';

const useOnSortChange = (): ((newSort: CollectionsSortOrder) => void) => {
  const dispatch = useDispatch();
  const { search, sortOrder, start } = useCollectionsQueryParams();

  return useCallback(
    (newSort: CollectionsSortOrder) => {
      if (newSort !== sortOrder) {
        replaceCollectionsUrl(dispatch, { search, sort: newSort, start });
      }
    },
    [dispatch, search, sortOrder, start]
  );
};

export default useOnSortChange;
