import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import envelope from '../../../../assets/img/envelope.png';
import Errors from '../../../../components/Errors/Errors';
import { LOGIN_URL } from '../../../../global/urls';
import { NormalizedError } from '../../../../types';
import styles from './PasswordChanged.module.scss';

interface Props {
  error: NormalizedError | null;
}

const PasswordChanged: FC<Props> = ({ error }: Props) => {
  return (
    <div className={cx('bg-white rounded shadow p-5 text-center', styles.emailSentWrapper)}>
      <img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={70} />
      <h4 className={cx(styles.emailSentTitle)}>Your password has been changed.</h4>
      <Link to={LOGIN_URL}>
        <Button
          type="button"
          color="falcon-primary"
          size="sm"
          className={cx(styles.emailSentBackBtn, 'mt-3')}
        >
          <FontAwesomeIcon icon={faChevronLeft} transform="shrink-4 down-1" className="mr-1" />
          Return to login
        </Button>
      </Link>
      <Errors error={error} />
    </div>
  );
};

export default PasswordChanged;
