import { ErrorMessage, Field, FormikErrors } from 'formik';
import React, { FC } from 'react';
import { FormGroup, Input } from 'reactstrap';
import FormInputError from '../../../components/FormInputError/FormInputError';
import ModalForm, { ModalFormLabel } from '../../../components/ModalForm/ModalForm';

interface RejectPublishModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (message: string) => void;
}

interface RejectFormState {
  rejectReason: string;
}

function validate(values: RejectFormState) {
  const errors: FormikErrors<RejectFormState> = {};
  if (!values.rejectReason) {
    errors.rejectReason = 'Reject reason should pe specified';
  }
  return errors;
}

const RejectPublishModal: FC<RejectPublishModalProps> = ({
  onSubmit,
  ...props
}: RejectPublishModalProps) => {
  const initialValues: RejectFormState = {
    rejectReason: '',
  };

  const onMessageSubmit = ({ rejectReason }: RejectFormState) => onSubmit(rejectReason);

  return (
    <ModalForm
      title="Reject publish request"
      okButtonLabel="Reject"
      initialValues={initialValues}
      validate={validate}
      onSubmit={onMessageSubmit}
      okButtonNegative
      {...props}
    >
      <FormGroup>
        <ModalFormLabel for="reject-reason">Reject reason</ModalFormLabel>
        <Field as={Input} id="reject-reason" name="rejectReason" type="text" />
        <ErrorMessage name="rejectReason" component={FormInputError} />
      </FormGroup>
    </ModalForm>
  );
};

export default RejectPublishModal;
