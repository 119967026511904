import cx from 'classnames';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from 'reactstrap';
import { SHOPNET_SHOP_ID } from '../../global/constants';
import useRouterData from '../../global/hooks/useRouterData';
import { getSessionActiveShop, getSessionUser } from '../../global/session/selectors';
import { sideMenuItems } from '../../global/sidemenu';
import { UserRole } from '../../schemas';
import styles from './SideMenu.module.scss';
import SideMenuItem from './components/SideMenuItem/SideMenuItem';

interface Props {
  collapsed: boolean;
  setMenuHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideMenu: FC<Props> = ({ collapsed, setMenuHovered }: Props) => {
  const { pathname } = useRouterData();
  const activeShop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);
  const role = user ? user.role : UserRole.Unknown;
  const [subMenu, setSubMenu] = useState({ isOpen: false, name: '' });

  const onSubMenuClick = (name: string) => {
    if (!subMenu.isOpen) {
      setSubMenu({ isOpen: true, name });
    }
    if (subMenu.isOpen && name === subMenu.name) {
      setSubMenu({ isOpen: false, name: '' });
    }
    if (subMenu.isOpen && name !== subMenu.name) {
      setSubMenu({ isOpen: true, name });
    }
  };

  let items = sideMenuItems;
  if (activeShop && activeShop.id === SHOPNET_SHOP_ID) {
    items = items.filter((item) => item.inShopNet);
  }

  return (
    <div className={cx('d-flex flex-column')}>
      <Nav
        className={cx(
          styles.sideMenu,
          collapsed ? styles.sideMenuCollapsed : styles.sideMenuExpanded,
          'flex-column pt-1'
        )}
        onMouseEnter={() => setMenuHovered(true)}
        onMouseLeave={() => setMenuHovered(false)}
      >
        {items
          .filter((item) => item.roles.includes(role))
          .map((item) => (
            <SideMenuItem
              sideMenuCollapsed={collapsed}
              key={item.title}
              onSubMenuClick={onSubMenuClick}
              page={item}
              pathname={pathname}
              subMenuOpen={subMenu.isOpen && subMenu.name === item.title}
            />
          ))}
      </Nav>
    </div>
  );
};

export default SideMenu;
