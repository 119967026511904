import { LoadingState } from '../../helpers/apiRequestSlice';
import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/payoutsQueryReducer';
import { initialSearchState } from './reducers/payoutsSearchReducer';
import { PayoutsPageState, PayoutsQueryState, PayoutsSearchState, PayoutsSortOrder } from './types';

export const getPayoutsPageState = (state: AppState): PayoutsPageState => state.containers.payouts;

export const getPayoutsVendorsBareLoadingState = (state: AppState): LoadingState =>
  state.containers.payoutsVendorsBareLoading;

export const getPayoutsQueryState = (
  state: AppState,
  sort: PayoutsSortOrder,
  start: number,
  search: string
): PayoutsQueryState =>
  getPayoutsPageState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState;

export const getPayoutsSearchState = (state: AppState, search: string): PayoutsSearchState =>
  getPayoutsPageState(state).searches[search] || initialSearchState;

export const isSubmittingPayouts = (state: AppState): boolean =>
  state.containers.payoutTransactionAdd.isLoading;
