import cx from 'classnames';
import React, { FC } from 'react';
import styles from './VendorBadge.module.scss';

type Props = {
  className?: string;
};

const VendorBadge: FC<Props> = ({ className }: Props) => {
  return (
    <span
      className={cx(
        'rounded-pill text-center text-capitalize py-1 px-3',
        styles.VendorBadge,
        className
      )}
    >
      Vendor
    </span>
  );
};

export default VendorBadge;
