import { ErrorMessage, Field } from 'formik';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import ModalForm, { ModalFormLabel } from '../../../../components/ModalForm/ModalForm';
import { AppState } from '../../../../init/rootReducer';
import { VendorEntity } from '../../../../schemas';
import { vendorsSubmitTrigger } from '../../actions/submit.actions';

export type Props = {
  isOpen: boolean;
  toggle: () => void;
  vendorId: number;
};

const VendorRenameModal: FC<Props> = ({ isOpen, toggle, vendorId }: Props) => {
  const currentName = useSelector(
    (state: AppState) => (state.entities.vendors as Record<number, VendorEntity>)[vendorId].name
  );

  const dispatch = useDispatch();
  const onSubmit = ({ name }: { name: string }) =>
    dispatch(vendorsSubmitTrigger(vendorId, { name }));

  return (
    <ModalForm
      initialValues={{ name: currentName }}
      isOpen={isOpen}
      onSubmit={onSubmit}
      title="Rename Vendor"
      toggle={toggle}
    >
      <FormGroup>
        <ModalFormLabel for="name">New vendor name</ModalFormLabel>
        <Field as={Input} id="name" name="name" type="text" />
        <ErrorMessage name="name" component={FormInputError} />
      </FormGroup>
    </ModalForm>
  );
};

export default VendorRenameModal;
