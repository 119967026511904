import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { LOGIN_AS_URL } from '../../../global/urls';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import {
  LoginAsFetchApiFailureAction,
  LoginAsFetchApiRequestAction,
  LoginAsFetchApiSuccessAction,
} from '../actions/fetchActions';
import { LoginAsApiSuccessAction } from '../actions/selectActions';
import {
  LOGIN_AS_API_SUCCESS,
  LOGIN_AS_FETCH_API_FAILURE,
  LOGIN_AS_FETCH_API_REQUEST,
  LOGIN_AS_FETCH_API_SUCCESS,
} from '../constants';
import { makeQueryKey } from '../helpers';
import { LoginAsQueryState, queryReducer } from './queryReducer';

export type LoginAsState = {
  queries: { [queryKey: string]: LoginAsQueryState };
};

const initialState: LoginAsState = {
  queries: { '': { error: null, userIds: [] } },
};

type LoginAsAction =
  | LocationChangeAction
  | LoginAsFetchApiFailureAction
  | LoginAsFetchApiRequestAction
  | LoginAsFetchApiSuccessAction
  | LoginAsApiSuccessAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | ResetSessionAction;

// eslint-disable-next-line import/prefer-default-export
export const loginAsReducer: Reducer<LoginAsState, LoginAsAction> = (
  state = initialState,
  action: LoginAsAction
): LoginAsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN_AS_FETCH_API_REQUEST:
      case LOGIN_AS_FETCH_API_SUCCESS:
      case LOGIN_AS_FETCH_API_FAILURE: {
        const key = makeQueryKey(action.payload.search);
        draft.queries[key] = queryReducer(state.queries[key], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: LOGIN_AS_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case LOGIN_AS_API_SUCCESS:
      case RESET_SESSION: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
