import { NormalizedError } from '../../../types';
import {
  VendorRegistrationInitApiFailureAction,
  VendorRegistrationInitApiRequestAction,
  VendorRegistrationInitApiSuccessAction,
  VendorRegistrationInitNormalizedResponse,
  VendorRegistrationInitTriggerAction,
} from '../actionTypes/initActions';
import {
  VENDOR_REGISTRATION_INIT_API_FAILURE,
  VENDOR_REGISTRATION_INIT_API_REQUEST,
  VENDOR_REGISTRATION_INIT_API_SUCCESS,
  VENDOR_REGISTRATION_INIT_TRIGGER,
} from '../constants';

export const vendorRegistrationInitTrigger = (
  invitationCode: string,
  failureCallback: () => void
): VendorRegistrationInitTriggerAction => ({
  type: VENDOR_REGISTRATION_INIT_TRIGGER,
  payload: { failureCallback, invitationCode },
});

export const vendorRegistrationInitApiRequest = (
  invitationCode: string
): VendorRegistrationInitApiRequestAction => ({
  type: VENDOR_REGISTRATION_INIT_API_REQUEST,
  payload: { invitationCode },
});

export const vendorRegistrationInitApiSuccess = (
  invitationCode: string,
  response: VendorRegistrationInitNormalizedResponse
): VendorRegistrationInitApiSuccessAction => ({
  type: VENDOR_REGISTRATION_INIT_API_SUCCESS,
  payload: { invitationCode, response },
});

export const vendorRegistrationInitApiFailure = (
  invitationCode: string,
  error: NormalizedError
): VendorRegistrationInitApiFailureAction => ({
  type: VENDOR_REGISTRATION_INIT_API_FAILURE,
  payload: { error, invitationCode },
});
