import { Formik } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import FalconTable from '../../../../components/FalconTable/FalconTable';
import selectRow from '../../../../components/FalconTable/selectRow';
import { SortOrderDirection } from '../../../../global/types';
import { ShopEntity, VendorEntity } from '../../../../schemas';
import { mapSortOrder, mapTableSort } from '../../helpers';
import useOnSortChange from '../../hooks/useOnSortChange';
import useVendorsData from '../../hooks/useVendorsData';
import {
  FormItems,
  FormValues,
  SortableVendorsColumn,
  VendorsSortOrder,
  VendorsType,
} from '../../types';
import { columns, loadingColumns } from './columns';

type Props = {
  formItems: FormItems;
  pageSize: number;
  shop: ShopEntity | null;
  sortOrder: VendorsSortOrder;
  type: VendorsType;
  vendors: VendorEntity[] | null;
};

const VendorsTable: FC<Props> = ({
  formItems,
  pageSize,
  shop,
  sortOrder,
  type,
  vendors,
}: Props) => {
  const onSortChange = useOnSortChange();
  const {
    addSelectedVendors,
    removeSelectedVendors,
    selectedVendors,
    toggleSelectedVendor,
  } = useVendorsData();

  const handleTableChange = (
    tctype: TableChangeType,
    newState: TableChangeState<unknown>
  ): void => {
    onSortChange(
      mapSortOrder(
        newState.sortField as SortableVendorsColumn,
        newState.sortOrder as SortOrderDirection
      )
    );
  };

  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);

  const mappedVendorsName = useCallback(
    (vendorsList: VendorEntity[]) =>
      vendorsList.map((vendor) => ({
        ...vendor,
        name: { name: vendor.name, code: vendor.vendorCode },
      })),
    []
  );

  const data = useMemo(
    () =>
      vendors !== null
        ? mappedVendorsName(vendors)
        : Array.from({ length: pageSize }, (_, id) => ({ id })),
    [vendors, mappedVendorsName, pageSize]
  );

  const selectedVendorsArr = useMemo(() => [...selectedVendors], [selectedVendors]);

  const onSelect = (row: VendorEntity) => {
    toggleSelectedVendor(row.id);
  };

  const onSelectAll = (isSelect: boolean, rows: VendorEntity[]) => {
    if (isSelect) {
      // select all vendors from current page
      const selectedIds = rows.map((row) => row.id);
      addSelectedVendors(selectedIds);
    } else {
      // unselect all vendors (including other pages)
      removeSelectedVendors(selectedVendorsArr);
    }
  };

  const initialValues: FormValues = useMemo(() => ({ vendors: formItems }), [formItems]);

  return (
    <Formik<FormValues> enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
      <FalconTable
        columns={vendors !== null ? columns(type, shop) : loadingColumns(type, shop)}
        data={data}
        keyField="id"
        selectRow={selectRow(onSelect, onSelectAll, selectedVendorsArr)}
        sort={bootstrapTableSort}
        onTableChange={handleTableChange}
        remote={{
          filter: true,
          pagination: true,
          sort: true,
        }}
      />
    </Formik>
  );
};

export default VendorsTable;
