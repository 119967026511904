import React from 'react';
import slugify from 'slugify';
import { CollectionsSortOrder } from '../containers/Collections/types';
import { CustomersSortOrder } from '../containers/Customers/types';
import { InventorySortOrder } from '../containers/Inventory/types';
import { ModerationListSortOrder } from '../containers/Moderation/types';
import { OrdersSortOrder } from '../containers/Orders/types';
import { PayoutsSortOrder } from '../containers/Payouts/types';
import { ProductsSortOrder } from '../containers/Products/types';
import { ShippingPoliciesSortOrder } from '../containers/ShippingPolicies/types';
import { WarehouseSortOrder } from '../containers/Warehouse/types';
import { ProductImageEntity, UserEntity } from '../schemas';

export const isAuthenticated = (user: UserEntity | null): boolean => user !== null && user.id !== 0;

export const makeQueryKey = (
  sortOrder:
    | CollectionsSortOrder
    | CustomersSortOrder
    | InventorySortOrder
    | ModerationListSortOrder
    | OrdersSortOrder
    | PayoutsSortOrder
    | ProductsSortOrder
    | ShippingPoliciesSortOrder
    | WarehouseSortOrder,
  start: number,
  search: string
): string => `${sortOrder}:${start}:${search}`;

export const keyMatchesSearch = (key: string, search: string): boolean => {
  const match = key.match(/^.*?:.*?:(.*)$/);
  if (!match) return false;
  return match[1] === search;
};

export const decodeImageSrc = (image: ProductImageEntity | null): string => {
  if (!image) return '';
  // if (image.imageCdnUrl) return image.imageCdnUrl;
  if (image.imageLocalUrl) return process.env.REACT_APP_DJANGO_BASE_URL + image.imageLocalUrl;
  return '';
};

const CURRENCY_SYMBOLS: { [K: string]: string } = {
  GBP: '£',
  USD: '$',
};

const DEFAULT_CURRENCY_SYMBOL = CURRENCY_SYMBOLS.GBP;

export const currencyToSymbol = (currency: string | undefined): string => {
  if (!currency) {
    return DEFAULT_CURRENCY_SYMBOL;
  }
  return CURRENCY_SYMBOLS[currency] || DEFAULT_CURRENCY_SYMBOL;
};

export const getLanguageByShopCurrency = (currency?: string): string =>
  currency === 'USD' ? 'en' : 'en-GB';

const formatPriceGBP = (value: number): string => {
  if (!value) return '';
  const val = typeof value === 'string' ? parseFloat(value) : value;
  return `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

const formatPriceUSD = (value: number): string => {
  if (!value) return '';
  const val = typeof value === 'string' ? parseFloat(value) : value;
  return `$${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatPrice = (value: number, currency: string | undefined): string => {
  if (currency === 'GBP') return formatPriceGBP(value);
  if (currency === 'USD') return formatPriceUSD(value);
  return formatPriceGBP(value);
};

const SHORT_DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatDateShortUK = (datetimeStr: string): string => {
  // 17 Sep 2021
  if (!datetimeStr) {
    return '';
  }
  const date = new Date(datetimeStr);
  const dayStr = date.getDate().toString().padStart(2, '0');
  const monName = SHORT_MONTHS[date.getMonth()];
  return `${dayStr} ${monName} ${date.getFullYear()}`;
};

export const formatDateShortUS = (datetimeStr: string): string => {
  // Sep 17 2021
  if (!datetimeStr) {
    return '';
  }
  const date = new Date(datetimeStr);
  const dayStr = date.getDate().toString().padStart(2, '0');
  const monName = SHORT_MONTHS[date.getMonth()];
  return `${monName} ${dayStr} ${date.getFullYear()}`;
};

export const formatDateShort = (datetimeStr: string, currency: string | undefined): string => {
  if (currency === 'GBP') return formatDateShortUK(datetimeStr);
  if (currency === 'USD') return formatDateShortUS(datetimeStr);
  return formatDateShortUK(datetimeStr);
};

export const formatDateLongUK = (datetimeStr: string): string => {
  // 08 Apr 2021, 13:58
  if (!datetimeStr) {
    return '';
  }
  const date = new Date(datetimeStr);
  return `${formatDateShortUK(datetimeStr)}, ${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const formatDateLongUS = (datetimeStr: string): string => {
  // Apr 08 2021, 13:58
  if (!datetimeStr) {
    return '';
  }
  const date = new Date(datetimeStr);
  const hour24 = date.getHours();
  return `${formatDateShortUS(datetimeStr)}, ${hour24
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const formatDateLong = (datetimeStr: string, currency: string | undefined): string => {
  if (currency === 'GBP') return formatDateLongUK(datetimeStr);
  if (currency === 'USD') return formatDateLongUS(datetimeStr);
  return formatDateLongUK(datetimeStr);
};

export const formatDateFancyUK = (datetimeStr: string): string => {
  // Mon, 27 Sep 2021, 18:14
  if (!datetimeStr) {
    return '';
  }
  const date = new Date(datetimeStr);
  const dayName = SHORT_DAYS_OF_WEEK[date.getDay()];
  const dayStr = date.getDate().toString().padStart(2, '0');
  const monName = SHORT_MONTHS[date.getMonth()];
  const hour24 = date.getHours();
  return `${dayName}, ${dayStr} ${monName} ${date.getFullYear()}, ${hour24
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const formatDateFancyUS = (datetimeStr: string): string => {
  // Mon, Sep 27 2021, 18:14
  if (!datetimeStr) {
    return '';
  }
  const date = new Date(datetimeStr);
  const dayName = SHORT_DAYS_OF_WEEK[date.getDay()];
  const dayStr = date.getDate().toString().padStart(2, '0');
  const monName = SHORT_MONTHS[date.getMonth()];
  const hour24 = date.getHours();
  return `${dayName}, ${monName} ${dayStr} ${date.getFullYear()}, ${hour24
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const formatDateFancy = (datetimeStr: string, currency: string | undefined): string => {
  if (currency === 'GBP') return formatDateFancyUK(datetimeStr);
  if (currency === 'USD') return formatDateFancyUS(datetimeStr);
  return formatDateFancyUK(datetimeStr);
};

export const isNotDefaultTitle = (title: string): boolean => title !== 'Default Title';

export const appendSlash = (text: string): string => (text.endsWith('/') ? text : `${text}/`);

export const cleanNullableInt = (value: number | string | null): number | null => {
  if (value === null || value === '') return null;
  if (typeof value === 'number') return value;
  return parseInt(value, 10);
};

export const onlySlugChars = (value: string): string => {
  return slugify(value, { lower: true, strict: true, trim: false });
};

export const handleFilteredChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  filterFunc: (value: string) => string,
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  setFieldValue: (field: string, value: any) => void,
  name: string
): void => {
  const { value } = event.target;
  const newValue = filterFunc(value);
  setFieldValue(name, newValue);
};

export const isSuperStoreSite = () => {
  return process.env.REACT_APP_SITE === 'sss' || process.env.REACT_APP_SITE === 'shopnet';
};
