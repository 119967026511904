import cx from 'classnames';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { CustomInput, Input } from 'reactstrap';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { PRODUCT_URL } from '../../../../global/urls';
import { decodeImageSrc, isNotDefaultTitle } from '../../../../helpers/utils';
import { InventoryItemEntity, ProductImageEntity } from '../../../../schemas';
import { inventorySubmitTrigger } from '../../actions/submit.actions';
import { isSubmittingInventory } from '../../selectors';
import { FormValues } from '../../types';
import styles from './formatters.module.scss';

export const imageFormatter = (image: ProductImageEntity | null): ReactElement => (
  <CenterCropImg
    alt="product"
    className="rounded"
    height={32}
    src={decodeImageSrc(image)}
    width={32}
  />
);

export const productNameFormatter = (name: string, row: InventoryItemEntity): ReactElement => {
  return (
    <div className={styles.name}>
      {name ? (
        <Link
          className={styles.name__link}
          to={generatePath(PRODUCT_URL, { productId: row.productId })}
        >
          <div className={cx(tableStyles.textDark, styles.name__link__name)}>{name}</div>
        </Link>
      ) : null}
    </div>
  );
};

export const variantNameFormatter = (name: string, row: InventoryItemEntity): ReactElement => {
  const { sku } = row;
  return (
    <div className={styles.name}>
      {name ? (
        <Link
          className={styles.name__link}
          to={generatePath(PRODUCT_URL, { productId: row.productId })}
        >
          <div className={cx(tableStyles.textDark, styles.name__link__name)}>
            {isNotDefaultTitle(name) ? name : '\u00A0'}
          </div>
          <div className={cx(tableStyles.textSecondary)}>{sku}</div>
        </Link>
      ) : null}
    </div>
  );
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
type RowFieldProps = FieldAttributes<any> & { row: InventoryItemEntity };

export const TrackInventoryField = (props: RowFieldProps): ReactElement | null => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row } = props;
  const rowValues = values.items[row.id];
  if (!rowValues) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    const newTrackInventory = event.target.checked;
    let newAllowOverselling = rowValues.allowOverselling;
    const name = `items.${row.id}.allowOverselling`;
    if (!newTrackInventory && !newAllowOverselling) {
      setFieldValue(name, true);
      newAllowOverselling = true;
    } else if (newTrackInventory && newAllowOverselling) {
      setFieldValue(name, false);
      newAllowOverselling = false;
    }
    const variantId = row.id;
    dispatch(
      inventorySubmitTrigger(variantId, {
        trackInventory: newTrackInventory,
        allowOverselling: newAllowOverselling,
      })
    );
  };

  return (
    <CustomInput
      {...field}
      className={cx('custom-sss-switch')}
      checked={field.value}
      id={field.name}
      onChange={handleChange}
      type="switch"
    />
  );
};

export const AllowOversellingField = (props: RowFieldProps): ReactElement | null => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row } = props;
  const rowValues = values.items[row.id];

  if (!rowValues) {
    return null;
  }

  const { trackInventory } = rowValues;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    const newAllowOverselling = event.target.checked;
    const variantId = row.id;
    dispatch(inventorySubmitTrigger(variantId, { allowOverselling: newAllowOverselling }));
  };

  return (
    <CustomInput
      {...field}
      className={cx('custom-sss-switch')}
      checked={field.value}
      disabled={!trackInventory}
      id={field.name}
      onChange={handleChange}
      type="switch"
    />
  );
};

const InventoryField = (props: RowFieldProps) => {
  const { values } = useFormikContext<FormValues>();
  const [field, , helpers] = useField(props);
  const { row } = props;
  const rowValues = values.items[row.id];
  if (!rowValues) {
    return null;
  }
  return (
    <Input
      {...field}
      className={styles.quantity}
      min={0}
      type="number"
      onChange={(e) => {
        e.preventDefault();
        const { value: oldValue } = e.target;
        helpers.setValue(Math.floor(Number(oldValue)));
      }}
    />
  );
};

const ActionField = (props: RowFieldProps) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(isSubmittingInventory);
  const { values } = useFormikContext<FormValues>();
  const { row } = props;

  const variantId = row.id;
  const oldQuantity = row.inventory;
  const rowValues = values.items[variantId];
  if (!rowValues) {
    return null;
  }
  const newQuantity = rowValues.quantity;
  if (typeof newQuantity !== 'number') return null;

  const diff = newQuantity - oldQuantity;
  if (diff !== Math.floor(diff)) return null;

  let actionText;
  if (newQuantity > oldQuantity) {
    actionText = `Add ${diff}`;
  } else if (newQuantity < oldQuantity) {
    actionText = `Remove ${diff}`;
  } else return null;

  return (
    <SecondaryButton
      disabled={isSubmitting}
      onClick={() => dispatch(inventorySubmitTrigger(variantId, rowValues))}
      size="small"
    >
      {actionText}
    </SecondaryButton>
  );
};

export const trackInventoryFormatter = (
  trackInventory: boolean,
  row: InventoryItemEntity
): ReactElement => <TrackInventoryField name={`items.${row.id}.trackInventory`} row={row} />;

export const allowOversellingFormatter = (
  allowOverselling: boolean,
  row: InventoryItemEntity
): ReactElement => <AllowOversellingField name={`items.${row.id}.allowOverselling`} row={row} />;

export const inventoryFormatter = (amount: number, row: InventoryItemEntity): ReactElement => {
  if (amount === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      <InventoryField name={`items.${row.id}.quantity`} row={row} />
    </div>
  );
};

export const actionsFormatter = (_amount: number, row: InventoryItemEntity): ReactElement => {
  return <ActionField name={`items.${row.id}.action`} row={row} />;
};
