import cx from 'classnames';
import styles from 'global/styles/falconTable.module.scss';
import React, { CSSProperties, FC } from 'react';
import BootstrapTable, { BootstrapTableProps } from 'react-bootstrap-table-next';
import { BriefProductEntity } from 'schemas';

const rowStyleFormat = (row: BriefProductEntity, rowIdx: number): CSSProperties => {
  return { backgroundColor: rowIdx % 2 === 0 ? '' : '#f9fafd' };
};

const FalconTable: FC<BootstrapTableProps> = (props) => {
  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      classes={cx(styles.table, 'align-middle')}
      headerClasses={cx(styles.header, 'fs-1')}
      headerWrapperClasses={cx(styles.headerWrapper)}
      rowClasses={cx(styles.row, 'py-2 align-middle')}
      rowStyle={(row, idx) => rowStyleFormat(row, idx)}
      {...props}
    />
  );
};

export default FalconTable;
