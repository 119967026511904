import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import VendorProfileMainContent from './components/ProfileMainContent/VendorProfileMainContent';

const VendorProfile: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <VendorProfileMainContent key={key} />;
};

export default VendorProfile;
