import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { WarehouseSortOrder } from './types';

// fetch
export const WAREHOUSE_FETCH_TRIGGER = 'WAREHOUSE_FETCH_TRIGGER';
export const WAREHOUSE_FETCH_API_PREFIX = 'WAREHOUSE_FETCH_API';
export const WAREHOUSE_FETCH_API_REQUEST = 'WAREHOUSE_FETCH_API_REQUEST';
export const WAREHOUSE_FETCH_API_SUCCESS = 'WAREHOUSE_FETCH_API_SUCCESS';
export const WAREHOUSE_FETCH_API_FAILURE = 'WAREHOUSE_FETCH_API_FAILURE';

export const defaultSortOrder: WarehouseSortOrder = WarehouseSortOrder.name;

export const PAGE_SIZE = 15;
export const PAGING_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
