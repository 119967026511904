import { useDispatch, useSelector } from 'react-redux';
import { NormalizedError } from '../../../types';
import { alphaPageInit, alphaPingTrigger } from '../actions';
import { Pong } from '../reducer';
import getAlphaPageState from '../selectors';

interface AlphaPageData {
  alphaPageInit(): void;
  alphaPingTrigger(): void;
  error: NormalizedError | null;
  pong: Pong | null;
}

const useAlphaPageData = (): AlphaPageData => {
  const dispatch = useDispatch();
  const { error, pong } = useSelector(getAlphaPageState);
  return {
    alphaPageInit: () => dispatch(alphaPageInit()),
    alphaPingTrigger: () => dispatch(alphaPingTrigger()),
    error,
    pong,
  };
};

export default useAlphaPageData;
