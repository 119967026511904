import cx from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import React, { FC } from 'react';
import { FormGroup } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { INPUT_ERROR_MESSAGE } from '../../../../global/general/constants';
import styles from '../../../Login/Login.module.scss';

interface FormValues {
  password: string;
  password2: string;
}

interface Props {
  onSubmit(password: string): void;
}

const PasswordResetForm: FC<Props> = ({ onSubmit }: Props) => {
  const initialValues: FormValues = { password: '', password2: '' };
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};
        if (values.password !== values.password2) {
          errors.password2 = 'Both passwords need to be the same';
        }
        if (!values.password) {
          errors.password = INPUT_ERROR_MESSAGE.REQUIRED;
        }
        if (!values.password2) {
          errors.password2 = INPUT_ERROR_MESSAGE.REQUIRED;
        }
        return errors;
      }}
      onSubmit={(values) => {
        onSubmit(values.password);
      }}
    >
      {() => (
        <Form className="mt-4">
          <FormGroup>
            <Field
              type="password"
              name="password"
              className={cx('form-control', styles.loginFormInput)}
              placeholder="Password"
            />
            <ErrorMessage name="password" component="div" />
          </FormGroup>
          <FormGroup>
            <Field
              type="password"
              name="password2"
              className={cx('form-control', styles.loginFormInput)}
              placeholder="Confirm password"
            />
            <ErrorMessage name="password2" component="div" />
          </FormGroup>
          <FormGroup>
            <PrimaryButton className="w-100" size="large" type="submit">
              Change password
            </PrimaryButton>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordResetForm;
