import { createReducer } from '@reduxjs/toolkit';
import { LOGOUT_API_SUCCESS } from '../../global/logout/constants';
import { RESET_SESSION } from '../../global/session/constants';
import { makeApiRequestSlice } from '../../helpers/apiRequestSlice';
import { NormalizedResponse } from '../../types';
import { LOGIN_API_SUCCESS } from '../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../ShopSelector/constants';

type RejectedModerationListFetchPayload = Record<string, never>;

interface RejectedModerationListSuccessPayload {
  request: RejectedModerationListFetchPayload;
  response: NormalizedResponse & {
    result: {
      count: number;
      rejectedModerationRequests: number[];
    };
  };
}

export const fetchRejectedProductsSlice = makeApiRequestSlice<
  RejectedModerationListFetchPayload,
  RejectedModerationListSuccessPayload
>({
  name: 'rejectedProducts',
});

export interface RejectedProductsState {
  rejectedModerationRequests: number[];
}

const initialState: RejectedProductsState = {
  rejectedModerationRequests: [],
};

const resetState = () => initialState;

export const rejectedProductsDataReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchRejectedProductsSlice.actions.success, (draft, action) => {
    draft.rejectedModerationRequests = action.payload.response.result.rejectedModerationRequests;
  });
  builder.addCase(LOGIN_API_SUCCESS, resetState);
  builder.addCase(LOGOUT_API_SUCCESS, resetState);
  builder.addCase(RESET_SESSION, resetState);
  builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
});
