import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Dispatch } from 'redux';
import { InventoryItemEntity } from '../../schemas';
import { imageFormatter } from '../Products/components/ProductsTable/formatters';
import { actionFormatter, FormatterExtraData, nameFormatter } from './formatters';

const columns = (
  dispatch: Dispatch
): ColumnDescription<InventoryItemEntity, FormatterExtraData>[] => [
  {
    dataField: 'image',
    formatter: imageFormatter,
    headerStyle: () => ({ width: '50px' }),
    text: '',
  },
  {
    dataField: 'name',
    formatter: nameFormatter,
    text: 'Name',
  },
  {
    dataField: '',
    formatter: actionFormatter,
    formatExtraData: { dispatch },
    headerStyle: () => ({ width: '100px' } as CSSProperties),
    style: () => ({ paddingRight: 20, textAlign: 'right' } as CSSProperties),
    text: '',
  },
];

export default columns;
