import React, { ReactElement } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';

const RE_BOLD = /\*\*(.*?)\*\*/;

export const boldize = (text: string): React.ReactChild[] => {
  const ret = [];
  const matcher = new RegExp(RE_BOLD, 'g');
  let from = 0;
  for (;;) {
    const match = matcher.exec(text);
    if (match) {
      const matchLen = match[0].length;
      const content = match[1];

      const to = matcher.lastIndex - matchLen;

      // copy text before this markup
      ret.push(text.substring(from, to));

      // insert bold content
      ret.push(<b key={from}>{content}</b>);

      from = matcher.lastIndex;
    } else {
      // copy text after last markup
      ret.push(text.substring(from));
      break;
    }
  }
  return ret;
};

export const loaderFormatter = (): ReactElement => <span />;

export const loadingColumns = (columns: ColumnDescription[]): ColumnDescription[] =>
  columns.map((column) => ({
    ...column,
    formatter: loaderFormatter,
  }));
