import cx from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import styles from './GridPageWrapper.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  itemsCount: number;
  leftControls?: ReactNode;
  pagination?: ReactNode;
  rightControls?: ReactNode;
  showCount?: boolean;
  title: string;
};

const GridPageWrapper: FC<Props> = ({
  className,
  children,
  itemsCount,
  leftControls,
  pagination,
  rightControls,
  showCount = true,
  title,
}: Props) => (
  <div>
    <div className={cx('bg-white shadow', styles.gridWrapper, className)}>
      <Row className="align-items-center d-flex flex-row">
        <Col className="align-items-center d-flex flex-row">
          <h5 className={cx(styles.title)}>
            {title} {showCount && itemsCount ? `(${itemsCount})` : ''}
          </h5>
          {leftControls}
        </Col>
        {rightControls}
      </Row>
    </div>
    <div className="mt-3">{children}</div>
    <div className={cx('bg-white shadow', styles.gridWrapper, className)}>
      <Col className="d-flex justify-content-center align-items-center pt-3">
        {Boolean(itemsCount) && pagination}
      </Col>
    </div>
  </div>
);

export default GridPageWrapper;
