import { createReducer } from '@reduxjs/toolkit';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { RESET_SESSION } from '../../../global/session/constants';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import { CollectionBumpProductsAction, CollectionShowMoreProductsAction } from '../actions/actions';
import { COLLECTION_BUMP_PRODUCTS, COLLECTION_SHOW_MORE_PRODUCTS } from '../constants';
import { fetchCollectionBrowseSlice, fetchCollectionSlice } from '../slices';
import { collectionReducer, CollectionReducerAction, CollectionState } from './collectionReducer';

export type CollectionPageState = {
  collections: { [collectionId: number]: CollectionState };
};

const initialState: CollectionPageState = {
  collections: {},
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetState = (draft: CollectionPageState, action: unknown) => {
  return initialState;
};

const reduceCollection = (draft: CollectionPageState, action: CollectionReducerAction) => {
  const key =
    'request' in action.payload ? action.payload.request.collectionId : action.payload.collectionId;
  draft.collections[key] = collectionReducer(draft.collections[key], action);
};

export const pageReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchCollectionSlice.actions.success, reduceCollection);
  builder.addCase(fetchCollectionBrowseSlice.actions.success, reduceCollection);

  builder.addCase(COLLECTION_BUMP_PRODUCTS, (draft, action: CollectionBumpProductsAction) => {
    const key = action.payload.collectionId;
    draft.collections[key] = collectionReducer(draft.collections[key], action);
  });

  builder.addCase(
    COLLECTION_SHOW_MORE_PRODUCTS,
    (draft, action: CollectionShowMoreProductsAction) => {
      const key = action.payload.collectionId;
      draft.collections[key] = collectionReducer(draft.collections[key], action);
    }
  );

  builder.addCase(LOGIN_API_SUCCESS, resetState);
  builder.addCase(LOGOUT_API_SUCCESS, resetState);
  builder.addCase(RESET_SESSION, resetState);
  builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
});
