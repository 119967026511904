import { denormalize } from 'normalizr';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import { getEntitiesState } from '../../../../global/entities/selectors';
import { checkIfProcessing } from '../../../../global/ui/selectors';
import { AppState } from '../../../../init/rootReducer';
import { BriefProductEntity, CollectionEntity, CollectionSchema } from '../../../../schemas';
import { productFetchTrigger } from '../../../Product/actions/fetch.actions';
import CollectionsList from '../../../Product/components/CollectionsList/CollectionsList';
import { PRODUCT_FETCH_API } from '../../../Product/constants';
import { ProductCollection } from '../../../Product/types';
import { getProductsCollectionsSlice, setCollectionsSlice } from '../../slices';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  selectedProducts: Set<number>;
  submit: ({
    productIds,
    collectionIds,
  }: {
    productIds: number[];
    collectionIds: number[];
  }) => void;
};

type CustomProps = Props & {
  title: string;
  message: JSX.Element;
  showAllCollections?: boolean;
};

function notEmpty<TValue>(value: TValue | undefined): value is TValue {
  return value !== undefined;
}

const useCollections = (initialCollections: ProductCollection[]) => {
  const [collections, setCollections] = useState([] as ProductCollection[]);

  useEffect(() => {
    setCollections(initialCollections);
  }, [setCollections, initialCollections]);

  const toggleCollectionItem = (id: number) =>
    setCollections(
      collections.map((collection) =>
        collection.id === id ? { ...collection, checked: !collection.checked } : collection
      )
    );
  return { collections, toggleCollectionItem };
};

const BulkEditCollectionsModal: FC<CustomProps> = ({
  title: modalTitle,
  message,
  showAllCollections,
  isOpen,
  toggle,
  selectedProducts,
  submit,
}: CustomProps) => {
  const productIds = useMemo(() => Array.from(selectedProducts), [selectedProducts]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isOpen) return;
    const collectionsRequestProductIds = showAllCollections ? [] : productIds;
    dispatch(
      getProductsCollectionsSlice.actions.trigger({ productIds: collectionsRequestProductIds })
    );
  }, [dispatch, isOpen, showAllCollections, productIds]);

  const collectionIds = useSelector(
    (state: AppState) => state.containers.productsCollections.collectionIds
  );
  const entities = useSelector(getEntitiesState);

  const productsCollections = useMemo(
    () =>
      collectionIds.map(
        (collectionId) =>
          denormalize(collectionId, CollectionSchema, entities) as CollectionEntity | undefined
      ),
    [collectionIds, entities]
  );

  const initialCollections: ProductCollection[] = useMemo(
    () =>
      productsCollections.filter(notEmpty).map(({ id, title }) => ({
        id,
        name: title,
        checked: false,
      })),
    [productsCollections]
  );

  const { collections, toggleCollectionItem } = useCollections(initialCollections);

  const itemsSelected = collections.filter((c) => c.checked).map((c) => c.id);

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={() =>
        submit({
          collectionIds: itemsSelected,
          productIds,
        })
      }
      canSubmit={() => !!itemsSelected.length}
      title={modalTitle}
      okButtonLabel="Save"
      initialValues={{}}
    >
      {message}
      <CollectionsList collections={collections} toggleCollectionItem={toggleCollectionItem} />
    </ModalForm>
  );
};

export const AddCollectionsModal: FC<Props> = (props: Props) => {
  const { selectedProducts } = props;
  return (
    <BulkEditCollectionsModal
      title="Add Collections"
      message={
        <>
          Add collections to <b>{selectedProducts.size}</b> products
        </>
      }
      showAllCollections
      {...props}
    />
  );
};

export const RemoveCollectionsModal: FC<Props> = (props: Props) => {
  const { selectedProducts } = props;
  return (
    <BulkEditCollectionsModal
      title="Remove Collections"
      message={
        <>
          Remove collections from <b>{selectedProducts.size}</b> products
        </>
      }
      {...props}
    />
  );
};

type EditCollectionsModalProps = {
  isOpen: boolean;
  toggle: () => void;
  product: BriefProductEntity;
};

export const EditCollectionsModal: FC<EditCollectionsModalProps> = ({
  isOpen,
  toggle,
  product: briefProduct,
}: EditCollectionsModalProps) => {
  const product = useSelector(
    (state: AppState) => state.containers.product.products[briefProduct.productId]
  );

  const isLoading = useSelector((state: AppState) => checkIfProcessing(state, PRODUCT_FETCH_API));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isOpen || isLoading || product) return;
    dispatch(productFetchTrigger(briefProduct.productId, false));
  }, [isOpen, product, briefProduct, isLoading, dispatch]);

  const initialCollections = useMemo(() => (product ? product.collections : []), [product]);
  const { collections, toggleCollectionItem } = useCollections(initialCollections);

  const changed = useMemo(
    () =>
      !collections.every(
        (collection, index) => collection.checked === initialCollections[index].checked
      ),
    [collections, initialCollections]
  );

  const setProductCollectionsTrigger = () =>
    dispatch(
      setCollectionsSlice.actions.trigger({
        productIds: [briefProduct.productId],
        collectionIds: collections.filter((c) => c.checked).map((c) => c.id),
      })
    );

  if (!product) {
    return null;
  }

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={setProductCollectionsTrigger}
      canSubmit={() => changed}
      title="Edit Collections"
      okButtonLabel="Update"
      initialValues={{}}
    >
      Edit collections for <b>{product.information.title}</b>
      <CollectionsList collections={collections} toggleCollectionItem={toggleCollectionItem} />
    </ModalForm>
  );
};
