import {
  SetBusinessGeneralCompanyNumberAction,
  SetBusinessGeneralDisplayNameAction,
  SetBusinessGeneralLegalNameAction,
  SetBusinessGeneralVatNumberAction,
  SetBusinessGeneralVatPrefixAction,
  SetBusinessRegisteredAddressCityAction,
  SetBusinessRegisteredAddressCountryAction,
  SetBusinessRegisteredAddressLine1Action,
  SetBusinessRegisteredAddressLine2Action,
  SetBusinessRegisteredAddressPostcodeAction,
  SetBusinessRegisteredAddressStateAction,
  SetBusinessTradingAddressCityAction,
  SetBusinessTradingAddressCountryAction,
  SetBusinessTradingAddressLine1Action,
  SetBusinessTradingAddressLine2Action,
  SetBusinessTradingAddressPostcodeAction,
  SetBusinessTradingAddressStateAction,
} from '../actionTypes/business';
import {
  SET_BUSINESS_GENERAL_COMPANY_NUMBER,
  SET_BUSINESS_GENERAL_DISPLAY_NAME,
  SET_BUSINESS_GENERAL_LEGAL_NAME,
  SET_BUSINESS_GENERAL_VAT_NUMBER,
  SET_BUSINESS_GENERAL_VAT_PREFIX,
  SET_BUSINESS_REGISTERED_ADDRESS_CITY,
  SET_BUSINESS_REGISTERED_ADDRESS_COUNTRY,
  SET_BUSINESS_REGISTERED_ADDRESS_LINE_1,
  SET_BUSINESS_REGISTERED_ADDRESS_LINE_2,
  SET_BUSINESS_REGISTERED_ADDRESS_POSTCODE,
  SET_BUSINESS_REGISTERED_ADDRESS_STATE,
  SET_BUSINESS_TRADING_ADDRESS_CITY,
  SET_BUSINESS_TRADING_ADDRESS_COUNTRY,
  SET_BUSINESS_TRADING_ADDRESS_LINE_1,
  SET_BUSINESS_TRADING_ADDRESS_LINE_2,
  SET_BUSINESS_TRADING_ADDRESS_POSTCODE,
  SET_BUSINESS_TRADING_ADDRESS_STATE,
} from '../constants';

// general

export const setBusinessGeneralLegalName = (name: string): SetBusinessGeneralLegalNameAction => ({
  type: SET_BUSINESS_GENERAL_LEGAL_NAME,
  payload: { name },
});

export const setBusinessGeneralDisplayName = (
  name: string
): SetBusinessGeneralDisplayNameAction => ({
  type: SET_BUSINESS_GENERAL_DISPLAY_NAME,
  payload: { name },
});

export const setBusinessGeneralCompanyNumber = (
  companyNumber: string
): SetBusinessGeneralCompanyNumberAction => ({
  type: SET_BUSINESS_GENERAL_COMPANY_NUMBER,
  payload: { companyNumber },
});

export const setBusinessGeneralVatNumber = (
  vatNumber: string
): SetBusinessGeneralVatNumberAction => ({
  type: SET_BUSINESS_GENERAL_VAT_NUMBER,
  payload: { vatNumber },
});

export const setBusinessGeneralVatPrefix = (
  vatPrefix: string
): SetBusinessGeneralVatPrefixAction => ({
  type: SET_BUSINESS_GENERAL_VAT_PREFIX,
  payload: { vatPrefix },
});

// registeredAddress

export const setBusinessRegisteredAddressLine1 = (
  address: string
): SetBusinessRegisteredAddressLine1Action => ({
  type: SET_BUSINESS_REGISTERED_ADDRESS_LINE_1,
  payload: { address },
});

export const setBusinessRegisteredAddressLine2 = (
  address: string
): SetBusinessRegisteredAddressLine2Action => ({
  type: SET_BUSINESS_REGISTERED_ADDRESS_LINE_2,
  payload: { address },
});

export const setBusinessRegisteredAddressCity = (
  city: string
): SetBusinessRegisteredAddressCityAction => ({
  type: SET_BUSINESS_REGISTERED_ADDRESS_CITY,
  payload: { city },
});

export const setBusinessRegisteredAddressCountry = (
  country: string
): SetBusinessRegisteredAddressCountryAction => ({
  type: SET_BUSINESS_REGISTERED_ADDRESS_COUNTRY,
  payload: { country },
});

export const setBusinessRegisteredAddressPostcode = (
  postcode: string
): SetBusinessRegisteredAddressPostcodeAction => ({
  type: SET_BUSINESS_REGISTERED_ADDRESS_POSTCODE,
  payload: { postcode },
});

export const setBusinessRegisteredAddressState = (
  state: string
): SetBusinessRegisteredAddressStateAction => ({
  type: SET_BUSINESS_REGISTERED_ADDRESS_STATE,
  payload: { state },
});

// tradingAddress

export const setBusinessTradingAddressLine1 = (
  address: string
): SetBusinessTradingAddressLine1Action => ({
  type: SET_BUSINESS_TRADING_ADDRESS_LINE_1,
  payload: { address },
});

export const setBusinessTradingAddressLine2 = (
  address: string
): SetBusinessTradingAddressLine2Action => ({
  type: SET_BUSINESS_TRADING_ADDRESS_LINE_2,
  payload: { address },
});

export const setBusinessTradingAddressCity = (
  city: string
): SetBusinessTradingAddressCityAction => ({
  type: SET_BUSINESS_TRADING_ADDRESS_CITY,
  payload: { city },
});

export const setBusinessTradingAddressCountry = (
  country: string
): SetBusinessTradingAddressCountryAction => ({
  type: SET_BUSINESS_TRADING_ADDRESS_COUNTRY,
  payload: { country },
});

export const setBusinessTradingAddressPostcode = (
  postcode: string
): SetBusinessTradingAddressPostcodeAction => ({
  type: SET_BUSINESS_TRADING_ADDRESS_POSTCODE,
  payload: { postcode },
});

export const setBusinessTradingAddressState = (
  state: string
): SetBusinessTradingAddressStateAction => ({
  type: SET_BUSINESS_TRADING_ADDRESS_STATE,
  payload: { state },
});
