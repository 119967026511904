/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../types';
import { INIT_API_FAILURE, INIT_API_REQUEST, INIT_API_SUCCESS, INIT_TRIGGER } from './constants';

export interface InitTriggerAction {
  type: typeof INIT_TRIGGER;
}

export interface InitApiRequestAction extends APIRequestAction {
  type: typeof INIT_API_REQUEST;
}

export interface SessionSectionNormalized {
  activeShop: number;
  shops?: number[];
  user: number;
  vendorshop: number;
}

export interface InitNormalizedResponse extends NormalizedResponse {
  readonly result: {
    session: SessionSectionNormalized;
  };
}

export interface InitApiSuccessAction extends APISuccessAction {
  type: typeof INIT_API_SUCCESS;
  payload: {
    response: InitNormalizedResponse;
  };
}

export interface InitApiFailureAction extends APIFailureAction {
  type: typeof INIT_API_FAILURE;
}

export const initTrigger = (): InitTriggerAction => ({
  type: INIT_TRIGGER,
});

export const initApiRequest = (): InitApiRequestAction => ({
  type: INIT_API_REQUEST,
});

export const initApiSuccess = (response: InitNormalizedResponse): InitApiSuccessAction => ({
  type: INIT_API_SUCCESS,
  payload: { response },
});

export const initApiFailure = (error: NormalizedError): InitApiFailureAction => ({
  type: INIT_API_FAILURE,
  payload: { error },
});
