import React, { FC, useMemo } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import FalconTable from '../../../../components/FalconTable/FalconTable';
import selectRow from '../../../../components/FalconTable/selectRow';
import { OrderEntity } from '../../../../schemas';
import { mapTableSort } from '../../helpers';
import useOrdersPageData from '../../hooks/useOrdersPageData';
import useOrdersTableColumns from '../../hooks/useOrdersTableColumns';
import { OrdersSortOrder } from '../../types';

type Props = {
  orders: OrderEntity[] | null;
  pageSize: number;
  sortOrder: OrdersSortOrder;
};

const OrdersTable: FC<Props> = ({ orders, pageSize, sortOrder }: Props) => {
  const { columns, loadingColumns } = useOrdersTableColumns();
  const {
    addSelectedOrders,
    removeSelectedOrders,
    selectedOrders,
    toggleSelectedOrder,
  } = useOrdersPageData();

  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);

  const data = useMemo(
    () => (orders !== null ? orders : Array.from({ length: pageSize }, (_, id) => ({ id }))),
    [orders, pageSize]
  );

  const selectedOrdersArr = useMemo(() => [...selectedOrders], [selectedOrders]);

  const onSelect = (row: OrderEntity) => {
    toggleSelectedOrder(row.id);
  };

  const onSelectAll = (isSelect: boolean, rows: OrderEntity[]) => {
    if (isSelect) {
      // select all orders from current page
      const selectedIds = rows.map((row) => row.id);
      addSelectedOrders(selectedIds);
    } else {
      // unselect all orders (including other pages)
      removeSelectedOrders(selectedOrdersArr);
    }
  };

  return (
    <FalconTable
      columns={orders !== null ? columns : loadingColumns}
      data={data}
      keyField="id"
      selectRow={selectRow(onSelect, onSelectAll, selectedOrdersArr)}
      sort={bootstrapTableSort}
      onTableChange={() => {}}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
    />
  );
};

export default OrdersTable;
