import cx from 'classnames';
import React, { FC, useEffect } from 'react';
import { Button, Jumbotron, Row } from 'reactstrap';
import useAlphaPageData from './hooks/useAlphaPageData';

const AlphaPage: FC = () => {
  const { alphaPageInit, alphaPingTrigger, error, pong } = useAlphaPageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => alphaPageInit(), []);

  return (
    <Jumbotron>
      <h1>Proof of concept area</h1>

      <Row className={cx('mt-4')}>
        <Button color="falcon-primary" onClick={alphaPingTrigger}>
          Ping
        </Button>
      </Row>

      {pong && (
        <div style={{ marginTop: '20px' }}>
          <div>Answer: {pong.answer}</div>
          <div>Local datetime: {pong.localDatetime}</div>
          <div>Timestamp: {pong.timestamp}</div>
        </div>
      )}

      {error && (
        <div
          style={{
            marginTop: '20px',
            border: '2px solid red',
            padding: '20px',
          }}
        >
          ERROR!
        </div>
      )}
    </Jumbotron>
  );
};

export default AlphaPage;
