import { SagaIterator } from '@redux-saga/core';
import { all, fork } from 'redux-saga/effects';
import alpha from '../containers/AlphaPage/sagas';
import brandProfile from '../containers/BrandProfile/sagas';
import collection from '../containers/Collection/sagas';
import collections from '../containers/Collections/sagas';
import customers from '../containers/Customers/sagas';
import dashboardCharts from '../containers/DashboardCharts/sagas';
import forgottenPassword from '../containers/ForgottenPassword/sagas';
import giftCards from '../containers/GiftCards/sagas';
import inventory from '../containers/Inventory/sagas';
import inventoryAlerts from '../containers/InventoryAlerts/sagas';
import login from '../containers/Login/sagas';
import loginAs from '../containers/LoginAs/sagas';
import moderationList from '../containers/Moderation/sagas';
import order from '../containers/Order/sagas';
import orders from '../containers/Orders/sagas';
import passwordReset from '../containers/PasswordReset/sagas';
import payouts from '../containers/Payouts/sagas';
import product from '../containers/Product/sagas';
import products from '../containers/Products/sagas';
import rejectedProducts from '../containers/RejectedProducts/sagas';
import shippingPolicies from '../containers/ShippingPolicies/sagas';
import shippingTypes from '../containers/ShippingTypes/sagas';
import shopSelector from '../containers/ShopSelector/sagas';
import vendorProfile from '../containers/VendorProfile/sagas';
import vendorRegistration from '../containers/VendorRegistration/sagas';
import vendors from '../containers/Vendors/sagas';
import warehouse from '../containers/Warehouse/sagas';
import init from '../global/init/sagas';
import logout from '../global/logout/sagas';

function* rootSaga(): SagaIterator {
  yield all([
    fork(alpha),
    fork(brandProfile),
    fork(collection),
    fork(collections),
    fork(customers),
    fork(dashboardCharts),
    fork(forgottenPassword),
    fork(giftCards),
    fork(init),
    fork(inventory),
    fork(inventoryAlerts),
    fork(login),
    fork(loginAs),
    fork(logout),
    fork(moderationList),
    fork(order),
    fork(orders),
    fork(passwordReset),
    fork(payouts),
    fork(product),
    fork(products),
    fork(rejectedProducts),
    fork(shippingPolicies),
    fork(shippingTypes),
    fork(shopSelector),
    fork(vendorProfile),
    fork(vendorRegistration),
    fork(vendors),
    fork(warehouse),
  ]);
}

export default rootSaga;
