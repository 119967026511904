import { getLanguageByShopCurrency } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { ShopEntity, UserEntity, VendorEntity } from '../../schemas';
import { SessionState } from './reducer';

export const getSession = (state: AppState): SessionState => state.session;
export const getSessionUser = (state: AppState): UserEntity | null => getSession(state).user;
export const isSessionInitialized = (state: AppState): boolean => getSession(state).isInitialized;
export const getSessionActiveShop = (state: AppState): ShopEntity | null =>
  getSession(state).activeShop;
export const getSessionShops = (state: AppState): ShopEntity[] => getSession(state).shops;
export const getSessionVendor = (state: AppState): VendorEntity | null =>
  getSession(state).vendorshop;

export const getSessionLanguage = (state: AppState): string =>
  getLanguageByShopCurrency(getSessionActiveShop(state)?.currency);
