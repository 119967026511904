/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../types';
import {
  LOGOUT_API_FAILURE,
  LOGOUT_API_REQUEST,
  LOGOUT_API_SUCCESS,
  LOGOUT_TRIGGER,
} from './constants';

export interface LogoutTriggerAction {
  type: typeof LOGOUT_TRIGGER;
}

export interface LogoutApiRequestAction extends APIRequestAction {
  type: typeof LOGOUT_API_REQUEST;
}

export interface LogoutNormalizedResponse extends NormalizedResponse {
  readonly result: Record<string, never>;
}

export interface LogoutApiSuccessAction extends APISuccessAction<LogoutNormalizedResponse> {
  type: typeof LOGOUT_API_SUCCESS;
}

export interface LogoutApiFailureAction extends APIFailureAction {
  type: typeof LOGOUT_API_FAILURE;
}

export const logoutTrigger = (): LogoutTriggerAction => ({
  type: LOGOUT_TRIGGER,
});

export const logoutApiRequest = (): LogoutApiRequestAction => ({
  type: LOGOUT_API_REQUEST,
});

export const logoutApiSuccess = (response: LogoutNormalizedResponse): LogoutApiSuccessAction => ({
  type: LOGOUT_API_SUCCESS,
  payload: { response },
});

export const logoutApiFailure = (error: NormalizedError): LogoutApiFailureAction => ({
  type: LOGOUT_API_FAILURE,
  payload: { error },
});
