import { FormValues } from 'containers/VendorProfile/types';
import { Formik } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import SimpleLoadingIndicator from '../../../../components/SimpleLoadingIndicator/SimpleLoadingIndicator';
import useRequireAuth from '../../../../global/hooks/useRequireAuth';
import { formatDateLong, isAuthenticated } from '../../../../helpers/utils';
import { UserRole } from '../../../../schemas';
import useVendorProfileData from '../../hooks/useVendorProfileData';
import { fetchVendorProfileSlice } from '../../slices';
import VendorProfileBankInformation from '../ProfileBankInformation/VendorProfileBankInformation';
import VendorProfileHeader from '../ProfileHeader/VendorProfileHeader';
import VendorProfileMainInformation from '../ProfileMainInformation/VendorProfileMainInformation';
import VendorProfileContacts from '../VendorProfileContacts/VendorProfileContacts';
import VendorProfileContracts from '../VendorProfileContracts/VendorProfileContracts';
import VendorProfileRegisteredAddress from '../VendorProfileRegisteredAddress/VendorProfileRegisteredAddress';
import VendorProfileTradingAddress from '../VendorProfileTradingAddress/VendorProfileTradingAddress';

const VendorProfileMainContent: FC = () => {
  useRequireAuth();
  const dispatch = useDispatch();
  const {
    error,
    isLoading,
    loaded,
    logoUrl,
    saveVendorProfileTrigger,
    shop,
    user,
    vendor,
    vendorShopId,
  } = useVendorProfileData();

  const loadData = () => {
    if (isAuthenticated(user) && user?.role !== UserRole.Vendor) return;
    dispatch(fetchVendorProfileSlice.actions.trigger({}));
  };

  const initialValues: FormValues = useMemo(
    () => ({
      addressLine1: '',
      addressLine2: '',
      bankAccountNumber: vendor?.bankAccountNumber || '',
      bankAddress: vendor?.bankAddress || '',
      bankIban: vendor?.bankIban || '',
      bankName: vendor?.bankName || '',
      bankSortCode: vendor?.bankSortCode || '',
      bankSwiftBic: vendor?.bankSwiftBic || '',
      billingContactEmail: vendor?.billingContactEmail || '',
      billingContactName: vendor?.billingContactName || '',
      billingContactPhone: vendor?.billingContactPhone || '',
      city: '',
      companyNumber: vendor?.companyNumber || '',
      contractSignedAt: formatDateLong(vendor?.contractSignedAt, shop?.currency) || '',
      contractSignerEmail: vendor?.contractSignerEmail || '',
      contractSignerFullName: vendor?.contractSignerFullName || '',
      contractSignerPhone: vendor?.contractSignerPhone || '',
      contractSignerPosition: vendor?.contractSignerPosition || '',
      customerInquiryContactEmail: vendor?.customerInquiryContactEmail || '',
      customerInquiryContactName: vendor?.customerInquiryContactName || '',
      customerInquiryContactPhone: vendor?.customerInquiryContactPhone || '',
      description: vendor?.description || '',
      displayName: vendor?.name || '',
      inventoryContactEmail: vendor?.inventoryContactEmail || '',
      inventoryContactName: vendor?.inventoryContactName || '',
      inventoryContactPhone: vendor?.inventoryContactPhone || '',
      legalName: vendor?.legalName || '',
      logoFileData: '',
      logoFileName: '',
      regAddress1: vendor?.regAddress1 || '',
      regAddress2: vendor?.regAddress2 || '',
      regCity: vendor?.regCity || '',
      regCountry: vendor?.regCountry || 'GB',
      regPostCode: vendor?.regPostCode || '',
      regState: vendor?.regState || '',
      tradingAddress1: vendor?.tradingAddress1 || '',
      tradingAddress2: vendor?.tradingAddress2 || '',
      tradingCity: vendor?.tradingCity || '',
      tradingCountry: vendor?.tradingCountry || 'GB',
      tradingPostCode: vendor?.tradingPostCode || '',
      tradingState: vendor?.tradingState || '',
      vatCountry: vendor?.vatNumber ? vendor.vatNumber.slice(0, 2) : '',
      vatNumberOnly: vendor?.vatNumber ? vendor.vatNumber.slice(2) : '',
      vendorCode: vendor?.vendorCode || '',
    }),
    [shop, vendor]
  );

  const handleSave = (values: FormValues) => {
    saveVendorProfileTrigger({
      ...values,
      vendorShopId,
    });
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (values.vatNumberOnly && !values.vatCountry) {
      errors.vatNumberOnly = 'VAT country prefix needs to be selected';
    }
    return errors;
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}}
      validate={validate}
    >
      {(fprops) => (
        <LoadingSwitch
          error={!!error}
          loading={isLoading}
          onLoad={loadData}
          renderError={null}
          renderLoading={() => <SimpleLoadingIndicator />}
          require={!!vendor || loaded}
        >
          <VendorProfileHeader
            canSave={fprops.isValid && fprops.dirty}
            displayName={fprops.values.displayName}
            isSubmitting={false}
            onSave={() => handleSave(fprops.values)}
          />
          <VendorProfileMainInformation fprops={fprops} logoUrl={logoUrl} />
          <VendorProfileRegisteredAddress selectedCountry={fprops.values.regCountry} />
          <VendorProfileTradingAddress selectedCountry={fprops.values.tradingCountry} />
          <VendorProfileBankInformation />
          <VendorProfileContacts />
          <VendorProfileContracts />
        </LoadingSwitch>
      )}
    </Formik>
  );
};

export default VendorProfileMainContent;
