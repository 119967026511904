import { useSelector } from 'react-redux';
import { ShopEntity } from '../../schemas';
import { getSessionActiveShop } from '../session/selectors';

type ShopData = {
  activeShop: ShopEntity | null;
};

const useShopData = (): ShopData => {
  const activeShop = useSelector(getSessionActiveShop);

  return {
    activeShop,
  };
};

export default useShopData;
