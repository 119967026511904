import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { StandardQueryParams } from '../types';

interface UsePagingProps {
  pageSize: number;
  pushUrl: (dispatch: Dispatch, params: StandardQueryParams) => void;
  searchInput?: string;
  sortOrder: string;
  start: number;
}

interface UsePagingResult {
  activePage: number;
  onPageChange: (newPage: number) => void;
}

function usePaging({
  pageSize,
  pushUrl,
  searchInput,
  sortOrder,
  start,
}: UsePagingProps): UsePagingResult {
  const dispatch = useDispatch();
  const activePage = useMemo(() => Math.ceil(start === 0 ? 1 : (start + 1) / pageSize), [
    pageSize,
    start,
  ]);
  const onPageChange = useCallback(
    (newPage: number) => {
      pushUrl(dispatch, {
        search: searchInput,
        sort: sortOrder,
        start: (newPage - 1) * pageSize,
      });
    },
    [dispatch, pageSize, pushUrl, searchInput, sortOrder]
  );
  return {
    activePage,
    onPageChange,
  };
}

export default usePaging;
