import cx from 'classnames';
import { Field, FieldProps } from 'formik';
import React, { ReactElement } from 'react';
import { TFunction } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { PRODUCT_URL } from '../../../../global/urls';
import { decodeImageSrc, formatPrice, isNotDefaultTitle } from '../../../../helpers/utils';
import { OrderItemEntity, ProductImageEntity, ShopEntity } from '../../../../schemas';
import { OrderItemGroup } from '../../types';
import { groupToItemsName } from '../../utils';
import styles from './OrderItemsList.module.scss';

export interface FormatterExtraData {
  group: OrderItemGroup;
  shop: ShopEntity | null;
  t: TFunction;
}

export const quantityFieldName = (group: OrderItemGroup, id: number): string =>
  `${groupToItemsName(group)}.${id}.quantity`;

export const imageFormatter = (image: ProductImageEntity | null): ReactElement => (
  <CenterCropImg
    alt="product"
    className="rounded"
    height={32}
    src={decodeImageSrc(image)}
    width={32}
  />
);

export const nameFormatter = (
  name: string,
  row: OrderItemEntity,
  rowIndex: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { t } = formatExtraData;
  return (
    <Row>
      <Col className={cx(tableStyles.textDark)}>
        <Link
          className={styles.OrderItemsList__link}
          to={generatePath(PRODUCT_URL, { productId: row.productId })}
        >
          <div>{name}</div>
          <div className={styles.OrderItemsList__sku}>{row.sku}</div>
        </Link>
      </Col>
      <Col className={cx(tableStyles.textDark)}>
        {row.dateOfBirth && (
          <div>
            DOB: <span className={styles.OrderItemsList__dateOfBirth}>{row.dateOfBirth}</span>
          </div>
        )}
        {row.customization && (
          <div>
            <span className={styles.OrderItemsList__customization}>{t('CUSTOMIZATION')}:</span>{' '}
            {row.customization}
          </div>
        )}
      </Col>
    </Row>
  );
};

export const optionsFormatter = (options: string[]): ReactElement => {
  if (options === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      {options.filter(isNotDefaultTitle).map((option) => (
        <div key={option}>{option}</div>
      ))}
    </div>
  );
};

export const priceFormatter = (
  price: number,
  row: OrderItemEntity,
  rowIndex: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (price === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return <div className={cx(tableStyles.textDark)}>{formatPrice(price, currency)}</div>;
};

const validateQuantity = (value: string, totalQuantity: number) => {
  const quantity = parseInt(value, 10);
  if (quantity < 0) return 'Invalid';
  if (quantity > totalQuantity) return 'Too many';
  if (quantity === 0) {
    return 'Please provide quantity';
  }
  return null;
};

export const quantityFormatter = (
  quantity: number,
  row: OrderItemEntity,
  rowIndex: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { group } = formatExtraData;
  return (
    <div className={cx(tableStyles.textDark)}>
      <Field
        name={quantityFieldName(group, row.id)}
        validate={(value: string) => validateQuantity(value, row.totalQuantity)}
      >
        {({ field, meta }: FieldProps) => (
          <>
            <Input
              {...field}
              className={styles.OrderItemsList__quantity}
              max={row.totalQuantity}
              min={0}
              type="number"
            />{' '}
            of {row.totalQuantity}
            {meta.error && <FormInputError isBlock>{meta.error}</FormInputError>}
          </>
        )}
      </Field>
    </div>
  );
};
