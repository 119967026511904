import { SagaIterator } from '@redux-saga/core';
import { push } from 'connected-react-router';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router-dom';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import {
  GIFT_CARDS_URL,
  PRODUCT_API_URL,
  PRODUCT_ARCHIVE_API_URL,
  PRODUCT_UNARCHIVE_API_URL,
  PRODUCT_UNPUBLISH_API_URL,
  PRODUCTS_URL,
} from '../../global/urls';
import toast from '../../global/utils/toast';
import { apiGet, apiPut, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import {
  CollectionSchema,
  ProductVersionSchema,
  SessionSchema,
  ShippingPolicySchema,
  ShippingTypeSchema,
} from '../../schemas';
import {
  productFetchApiFailure,
  productFetchApiRequest,
  productFetchApiSuccess,
  ProductFetchNormalizedResponse,
  ProductFetchTriggerAction,
} from './actions/fetch.actions';
import {
  productSubmitApiFailure,
  productSubmitApiRequest,
  productSubmitApiSuccess,
  ProductSubmitApiSuccessAction,
  ProductSubmitNormalizedResponse,
  ProductSubmitTriggerAction,
} from './actions/submit.actions';
import {
  PRODUCT_FETCH_TRIGGER,
  PRODUCT_SUBMIT_API_SUCCESS,
  PRODUCT_SUBMIT_TRIGGER,
} from './constants';
import {
  ProductArchiveRequestPayload,
  productArchiveSlice,
  ProductUnarchiveRequestPayload,
  productUnarchiveSlice,
  ProductUnpublishPayload,
  productUnpublishSlice,
} from './slices';

function* fetchSaga({ payload }: ProductFetchTriggerAction) {
  const { isGiftCardProduct, productId } = payload;
  yield put(productFetchApiRequest(productId, isGiftCardProduct));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(PRODUCT_API_URL, { productId }),
      query: { ...(isGiftCardProduct && { isGiftCardProduct: 1 }) },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: ProductFetchNormalizedResponse = normalize(response, {
      allCollections: [CollectionSchema],
      productVersion: ProductVersionSchema,
      session: SessionSchema,
      shippingPolicies: [ShippingPolicySchema],
      shippingTypes: [ShippingTypeSchema],
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(productFetchApiSuccess(productId, isGiftCardProduct, normalizedResponse));
  } catch (error) {
    yield put(productFetchApiFailure(productId, isGiftCardProduct, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

function* submitSaga({ payload }: ProductSubmitTriggerAction) {
  const { productId, values } = payload;
  yield put(productSubmitApiRequest(productId, values));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(PRODUCT_API_URL, { productId }),
      query: {},
    });
    const response = yield call(apiPut, url, values);
    const normalizedResponse: ProductSubmitNormalizedResponse = normalize(response, {
      allCollections: [CollectionSchema],
      productVersion: ProductVersionSchema,
      session: SessionSchema,
      shippingPolicies: [ShippingPolicySchema],
      shippingTypes: [ShippingTypeSchema],
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(productSubmitApiSuccess(productId, values, normalizedResponse));
  } catch (error) {
    const failureAction = productSubmitApiFailure(productId, values, normalizeError(error));
    yield put(failureAction);
    const errorCode = failureAction.payload?.error?.json?.code;
    if (errorCode !== 'max_products_reached' && errorCode !== 'moderation_request_cancelled') {
      yield* handleFailureSaga(error);
    }
  }
}

export const unpublishSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: PRODUCT_UNPUBLISH_API_URL,
  urlParams: (payload: ProductUnpublishPayload) => payload,
  responseSchema: {
    allCollections: [CollectionSchema],
    productVersion: ProductVersionSchema,
    session: SessionSchema,
    shippingPolicies: [ShippingPolicySchema],
    shippingTypes: [ShippingTypeSchema],
  },
  actions: productUnpublishSlice.actions,
});

function* publishConfirmationSaga({ payload }: ProductSubmitApiSuccessAction) {
  const { values } = payload;
  yield call(toast.success, 'Product is being published');
  const url = values.isGiftCardProduct ? GIFT_CARDS_URL : PRODUCTS_URL;
  yield put(push(url));
}

export const archiveSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: PRODUCT_ARCHIVE_API_URL,
  urlParams: (payload: ProductArchiveRequestPayload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: productArchiveSlice.actions,
});

function* archiveConfirmationSaga() {
  yield call(toast.success, 'Product has been archived');
  yield put(push(PRODUCTS_URL));
}

export const unarchiveSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: PRODUCT_UNARCHIVE_API_URL,
  urlParams: (payload: ProductUnarchiveRequestPayload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: productUnarchiveSlice.actions,
});

function* unarchiveConfirmationSaga() {
  yield call(toast.success, 'Product has been unarchived');
  yield put(push(PRODUCTS_URL));
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(PRODUCT_FETCH_TRIGGER, fetchSaga),
    takeLatest(PRODUCT_SUBMIT_TRIGGER, submitSaga),
    takeLatest(productUnpublishSlice.actions.trigger.type, unpublishSaga),
    takeLatest(PRODUCT_SUBMIT_API_SUCCESS, publishConfirmationSaga),
    takeLatest(productArchiveSlice.actions.trigger.type, archiveSaga),
    takeLatest(productArchiveSlice.actions.success.type, archiveConfirmationSaga),
    takeLatest(productUnarchiveSlice.actions.trigger.type, unarchiveSaga),
    takeLatest(productUnarchiveSlice.actions.success.type, unarchiveConfirmationSaga),
  ]);
}
