import { Action } from 'redux';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  ORDER_FETCH_API_FAILURE,
  ORDER_FETCH_API_REQUEST,
  ORDER_FETCH_API_SUCCESS,
  ORDER_FETCH_TRIGGER,
} from '../constants';

// action types

interface OrderFetchPayload {
  orderName: string;
}

export interface OrderFetchTriggerAction extends Action {
  type: typeof ORDER_FETCH_TRIGGER;
  payload: OrderFetchPayload;
}

export interface OrderFetchApiRequestAction extends APIRequestAction {
  type: typeof ORDER_FETCH_API_REQUEST;
  payload: OrderFetchPayload;
}

export interface OrderFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    order: number;
    orderItems: number[];
    refundedItems: number[];
    shipments: number[];
  };
}

export interface OrderFetchApiSuccessAction extends APISuccessAction {
  type: typeof ORDER_FETCH_API_SUCCESS;
  payload: OrderFetchPayload & {
    response: OrderFetchNormalizedResponse;
  };
}

export interface OrderFetchApiFailureAction extends APIFailureAction {
  type: typeof ORDER_FETCH_API_FAILURE;
  payload: OrderFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const orderFetchTrigger = (orderName: string): OrderFetchTriggerAction => ({
  type: ORDER_FETCH_TRIGGER,
  payload: {
    orderName,
  },
});

export const orderFetchApiRequest = (orderName: string): OrderFetchApiRequestAction => ({
  type: ORDER_FETCH_API_REQUEST,
  payload: {
    orderName,
  },
});

export const orderFetchApiSuccess = (
  orderName: string,
  response: OrderFetchNormalizedResponse
): OrderFetchApiSuccessAction => ({
  type: ORDER_FETCH_API_SUCCESS,
  payload: {
    orderName,
    response,
  },
});

export const orderFetchApiFailure = (
  orderName: string,
  error: NormalizedError
): OrderFetchApiFailureAction => ({
  type: ORDER_FETCH_API_FAILURE,
  payload: {
    error,
    orderName,
  },
});
