import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import useToggle from '../../../../components/ModalForm/useToggle';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGINATION_DISPLAYED_RANGE } from '../../constants';
import useVendorsData from '../../hooks/useVendorsData';
import { VendorsType } from '../../types';
import { pushVendorsUrl, replaceVendorsUrl } from '../../utils';
import InviteVendorModal from '../InviteVendorModal/InviteVendorModal';
import VendorsLeftControls from '../VendorsLeftControls/VendorsLeftControls';
import VendorsRightControls from '../VendorsRightControls/VendorsRightControls';
import VendorsTable from '../VendorsTable/VendorsTable';

const NO_RESULTS_TEXTS: { [K in VendorsType]: string } = {
  [VendorsType.Active]: 'There are no vendors in this store.',
  [VendorsType.Inactive]: 'There are no deactivated vendors in this store.',
  [VendorsType.Invited]: 'There are no invited vendors.',
  // [VendorsType.ShopNet]: 'There are no ShopNet vendors in this store.',
};

const VendorsMainContent: FC = () => {
  useScrollToTop();

  const {
    correctLocation,
    count,
    dispatch,
    error,
    formItems,
    inviteVendorTrigger,
    isLoading,
    loaded,
    search,
    selectedVendors,
    shop,
    sortOrder,
    start,
    type,
    user,
    vendors,
    vendorsFetchTrigger,
    vendorsSelectAll,
  } = useVendorsData();

  const [inviteVendorOpen, inviteVendorToggle] = useToggle();

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: (dsp, params) => replaceVendorsUrl(dsp, { ...params, type }),
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: (dsp, params) => pushVendorsUrl(dsp, { ...params, type }),
    searchInput,
    sortOrder,
    start,
  });

  const handleTypeChange = (newType: VendorsType) => {
    if (newType !== type) {
      pushVendorsUrl(dispatch, {
        search: searchInput,
        type: newType,
      });
    }
  };

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Vendors"
      leftControls={
        <VendorsLeftControls
          count={count}
          numItems={vendors?.length}
          onSelectAll={vendorsSelectAll}
          selected={[...selectedVendors]}
          vendorsType={type}
        />
      }
      rightControls={
        <VendorsRightControls
          onTypeChange={handleTypeChange}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setModalOpen={inviteVendorToggle}
          user={user}
          vendorsType={type}
        />
      }
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGINATION_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={vendorsFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(vendors && vendors.length > 0) || loaded || !correctLocation}
      >
        {vendors && vendors.length ? (
          <VendorsTable
            formItems={formItems}
            pageSize={PAGE_SIZE}
            shop={shop}
            sortOrder={sortOrder}
            type={type}
            vendors={vendors}
          />
        ) : (
          <TableNoResults search={search} text={NO_RESULTS_TEXTS[type]} />
        )}
      </LoadingSwitch>
      <InviteVendorModal
        isOpen={inviteVendorOpen}
        onSubmit={inviteVendorTrigger}
        toggle={inviteVendorToggle}
      />
    </TablePageWrapper>
  );
};

export default VendorsMainContent;
