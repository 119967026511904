import React, { FC } from 'react';
import DropdownSelect, {
  DropdownSelectOption,
} from '../../../../components/DropdownSelect/DropdownSelect';
import { WarehouseType } from '../../types';

const CHOICES: DropdownSelectOption<WarehouseType>[] = [
  [WarehouseType.All, 'All'],
  [WarehouseType.Available, 'Available'],
  [WarehouseType.Approved, 'In Store'],
];

const SHOPNET_CHOICES: DropdownSelectOption<WarehouseType>[] = [[WarehouseType.All, 'All']];

type Props = {
  className?: string;
  disabled?: boolean;
  isShopNet?: boolean;
  onChange: (choice: WarehouseType) => void;
  warehouseType: WarehouseType;
};

const WarehouseTypeDropdown: FC<Props> = ({
  className,
  disabled = false,
  isShopNet = false,
  onChange,
  warehouseType,
}: Props) => (
  <DropdownSelect
    options={isShopNet ? SHOPNET_CHOICES : CHOICES}
    selected={warehouseType}
    onChange={onChange}
    className={className}
    disabled={disabled || isShopNet}
  />
);

export default WarehouseTypeDropdown;
