import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import { AppState } from '../../../../init/rootReducer';
import { VendorEntity } from '../../../../schemas';
import { reactivateVendorSlice } from '../../slices';

export type Props = {
  isOpen: boolean;
  toggle: () => void;
  vendorId: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReactivateVendorModal: FC<Props> = ({ isOpen, toggle, vendorId }: Props) => {
  const currentName = useSelector(
    (state: AppState) => (state.entities.vendors as Record<number, VendorEntity>)[vendorId].name
  );

  const dispatch = useDispatch();
  const onSubmit = () => dispatch(reactivateVendorSlice.actions.trigger({ vendorId }));

  return (
    <ModalForm
      canSubmit={() => true}
      initialValues={{ name: currentName }}
      isOpen={isOpen}
      okButtonLabel="Reactivate"
      okButtonNegative
      onSubmit={onSubmit}
      title="Reactivate Vendor"
      toggle={toggle}
    >
      Do you want to reactivate <b>{currentName}</b>?
    </ModalForm>
  );
};

export default ReactivateVendorModal;
