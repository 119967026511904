import cx from 'classnames';
import { push } from 'connected-react-router';
import React, { ReactElement } from 'react';
import { generatePath } from 'react-router-dom';
import { Dispatch } from 'redux';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { MODERATION_URL } from '../../../../global/urls';
import { formatDateShort } from '../../../../helpers/utils';
import { ModerationRequestEntity, ShopEntity, UserEntity, VendorEntity } from '../../../../schemas';
import { imageFormatter } from '../../../Products/components/ProductsTable/formatters';
import styles from '../../../Vendors/components/VendorsTable/formatters.module.scss';
import { reviewPublishSlice } from '../../slices/moderationSlice';

const { trigger: reviewPublishTrigger } = reviewPublishSlice.actions;

export interface FormatterExtraData {
  dispatch: Dispatch;
  shop?: ShopEntity | null;
  user?: UserEntity | null;
}

export const nameFormatter = (name: string): ReactElement => {
  return (
    <div className={styles.name}>
      {name ? (
        <div className={cx(tableStyles.textDark, styles.name__link__name)}>{name}</div>
      ) : null}
    </div>
  );
};

export const vendorNameFormatter = (vendor: VendorEntity): ReactElement => {
  if (!vendor) return <span> </span>;
  const { name, vendorCode } = vendor;
  return (
    <span className={cx(tableStyles.textDark, styles.name)}>
      {name} ({vendorCode})
    </span>
  );
};

export const dateFormatter = (
  value: string,
  row: ModerationRequestEntity,
  _2: number,
  { shop }: FormatterExtraData
): ReactElement => {
  const currency = shop?.currency;
  return (
    <span className={cx('text-nowrap', tableStyles.textDark)}>
      {formatDateShort(value, currency)}
    </span>
  );
};

export const statusFormatter = (
  status: string,
  { reviewer }: ModerationRequestEntity
): ReactElement => {
  if (reviewer) {
    const reviewerName =
      reviewer.firstName || reviewer.lastName
        ? `${reviewer.firstName} ${reviewer.lastName}`
        : `${reviewer.email}`;
    return <span>{`In moderation by ${reviewerName}`}</span>;
  }
  return <span className="text-capitalize">{status}</span>;
};

export const reviewButtonFormatter = (
  id: number,
  row: ModerationRequestEntity,
  _2: number,
  { dispatch, user }: FormatterExtraData
): ReactElement => {
  const isInModerationByCurrentUser = row.reviewer !== null && row.reviewer?.id === user?.id;
  const enabled = row.reviewer === null || isInModerationByCurrentUser;
  const click = () => {
    if (isInModerationByCurrentUser) {
      const moderationUrl = generatePath(MODERATION_URL, { moderationId: row.id });
      dispatch(push(moderationUrl));
    } else {
      dispatch(reviewPublishTrigger({ moderationId: id }));
    }
  };
  return (
    <SecondaryButton size="small" disabled={!enabled} onClick={click}>
      Review
    </SecondaryButton>
  );
};

export { imageFormatter };
