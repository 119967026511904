import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import styles from './CustomDumbCheckbox.module.scss';

type Props = {
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onClick: (e: React.MouseEvent) => void;
};

const CustomDumbCheckbox: FC<Props> = ({ checked, disabled, indeterminate, onClick }: Props) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={cx(
        'position-relative rounded d-flex justify-content-center align-items-center',
        styles.wrapper,
        {
          [styles.wrapperChecked]: checked || indeterminate,
          [styles.wrapperDisabled]: disabled,
        }
      )}
      onClick={onClick}
    >
      {(checked || indeterminate) && (
        <div className={cx(styles.checkIcon, 'text-white position-absolute')}>
          <FontAwesomeIcon icon={checked ? faCheck : faMinus} />
        </div>
      )}
    </div>
  );
};

export default CustomDumbCheckbox;
