import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomTooltip from '../../../../../../components/CustomTooltip/CustomTooltip';
import { getAvailableCountries } from '../../../../../../global/countries/countries';
import { statesByCountries } from '../../../../../../global/countries/states';
import useVendorRegistrationBusinessData from '../../../../hooks/useVendorRegistrationBusinessData';
import { transformPostCode } from '../../../../utils';
import styles from './TradingAddress.module.scss';

const TradingAddress: FC = () => {
  const countries = useMemo(() => getAvailableCountries(), []);
  const [collapsed, setCollapsed] = useState(true);
  const {
    tradingAddress: { addressLine1, addressLine2, addressState, city, country, postcode },
    tradingAddressActions: {
      setAddressLine1,
      setAddressLine2,
      setAddressState,
      setCity,
      setCountry,
      setPostcode,
    },
  } = useVendorRegistrationBusinessData();

  const states = statesByCountries[country];

  return (
    <div className="pt-5">
      <button
        type="button"
        onClick={() => setCollapsed((prevState) => !prevState)}
        className="d-flex flex-row justify-content-between w-100"
      >
        <div>
          <h4 className="sss-form-title">
            Trading Address <span className="sss-color-orange small">(enter if different)</span>{' '}
            <CustomTooltip text="Only required if your trading address is different from your registered address" />
          </h4>
        </div>
        <div>
          <FontAwesomeIcon
            icon={collapsed ? faAngleDown : faAngleUp}
            className={cx(styles.collapseBtn)}
          />
        </div>
      </button>
      <Collapse isOpen={!collapsed}>
        <Row className="d-flex justify-content-between">
          <Col lg={12}>
            <FormGroup>
              <Label className="sss-form-subtitle">Address Line 1</Label>
              <Input
                type="text"
                value={addressLine1}
                onChange={(event) => setAddressLine1(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg={12}>
            <FormGroup>
              <Label className="sss-form-subtitle">Address Line 2</Label>
              <Input
                type="text"
                value={addressLine2}
                onChange={(event) => setAddressLine2(event.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col lg={6}>
            <FormGroup>
              <Label className="sss-form-subtitle">City</Label>
              <Input type="text" value={city} onChange={(event) => setCity(event.target.value)} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label className="sss-form-subtitle">Zip Code or Postcode</Label>
              <Input
                type="text"
                value={postcode}
                onChange={(event) => setPostcode(transformPostCode(event.target.value))}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label className="sss-form-subtitle">Country</Label>
              <Input
                type="select"
                name="select"
                onChange={(e) => setCountry(e.target.value)}
                defaultValue={country}
              >
                {countries.map((cntr) => (
                  <option key={cntr.abbr} value={cntr.abbr}>
                    {cntr.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg={6}>
            {states && (
              <FormGroup>
                <Label className="sss-form-subtitle">
                  State <span className="sss-color-orange">*</span>
                </Label>
                <Input
                  disabled={!states}
                  name="select"
                  onChange={(e) => setAddressState(e.target.value)}
                  type="select"
                  value={addressState}
                >
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default TradingAddress;
