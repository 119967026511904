import { ColumnDescription } from 'react-bootstrap-table-next';
import { PayoutEntity, ShopEntity } from 'schemas';
import sortCaretFunc from '../../../../global/utils/sortCaretFunc';
import { loaderFormatter } from '../../../../helpers/formatters';
import { NonSortablePayoutsColumn, SortablePayoutsColumn } from '../../types';
import {
  actionsFormatter,
  customAmountFormatter,
  FormatterExtraData,
  lastPayoutFormatter,
  memoFormatter,
  moneyFormatter,
  nameFormatter,
} from './formatters';

export const columns = (
  shop: ShopEntity | null
): ColumnDescription<PayoutEntity, FormatterExtraData>[] => {
  return [
    {
      dataField: SortablePayoutsColumn.name,
      formatter: nameFormatter,
      // headerStyle: () => ({ width: '24%' }),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Vendor Name',
    },
    {
      dataField: SortablePayoutsColumn.lastPayout,
      formatExtraData: { shop },
      formatter: lastPayoutFormatter,
      // headerStyle: () => ({ width: '24%' }),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Last Payout',
    },
    {
      dataField: SortablePayoutsColumn.liveBalance,
      formatExtraData: { shop },
      formatter: moneyFormatter,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Live Balance',
    },
    {
      dataField: SortablePayoutsColumn.payout,
      formatExtraData: { shop },
      formatter: moneyFormatter,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Payout',
    },
    {
      dataField: NonSortablePayoutsColumn.customAmount,
      formatExtraData: { shop },
      formatter: customAmountFormatter,
      text: 'Custom Amount',
    },
    {
      dataField: NonSortablePayoutsColumn.memo,
      formatter: memoFormatter,
      text: 'Memo',
    },
    {
      dataField: '',
      formatExtraData: { shop },
      formatter: actionsFormatter,
      headerStyle: () => ({ width: '100px' }),
      text: '',
    },
  ];
};

export const loadingColumns = (shop: ShopEntity | null): ColumnDescription[] =>
  columns(shop).map((column) => ({
    ...column,
    formatter: loaderFormatter,
  }));
