import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import { GIFT_CARDS_API_URL } from '../../global/urls';
import { apiGet, normalizeError } from '../../helpers/api';
import { handleFailureSaga } from '../../helpers/sagas';
import { GiftCardCodeSchema, SessionSchema } from '../../schemas';
import { GiftCardsFetchNormalizedResponse, GiftCardsFetchTriggerAction } from './actionTypes';
import {
  giftCardCodesFetchApiFailure,
  giftCardCodesFetchApiRequest,
  giftCardCodesFetchApiSuccess,
} from './actions';
import { GIFT_CARDS_FETCH_TRIGGER } from './constants';

function* fetchSaga({ payload }: GiftCardsFetchTriggerAction) {
  const { search, sortCodes, sortProducts, start } = payload;
  yield put(giftCardCodesFetchApiRequest(sortProducts, sortCodes, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: GIFT_CARDS_API_URL,
      query: { search, sortCodes, sortProducts, start },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: GiftCardsFetchNormalizedResponse = normalize(response, {
      giftCardCodes: [GiftCardCodeSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(
      giftCardCodesFetchApiSuccess(sortProducts, sortCodes, start, search, normalizedResponse)
    );
  } catch (error) {
    yield put(
      giftCardCodesFetchApiFailure(sortProducts, sortCodes, start, search, normalizeError(error))
    );
    yield* handleFailureSaga(error);
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(GIFT_CARDS_FETCH_TRIGGER, fetchSaga)]);
}
