export enum SortOrderDirection {
  asc = 'asc',
  desc = 'desc',
}

export interface LocationState {
  keepState?: boolean;
}

export type StandardQueryParams = {
  search?: string;
  sort?: string;
  start?: number;
};
