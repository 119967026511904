import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';
import { capitalize } from 'lodash';
import { denormalize } from 'normalizr';
import { matchPath } from 'react-router-dom';
import { Action, Reducer } from 'redux';
import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';
import { LogoutApiSuccessAction } from '../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../global/logout/constants';
import { ResetSessionAction } from '../../global/session/actions';
import { RESET_SESSION } from '../../global/session/constants';
import { PRODUCT_URL } from '../../global/urls';
import { decodeImageSrc, onlySlugChars } from '../../helpers/utils';
import {
  CollectionEntity,
  ProductEnquiry,
  ProductStatus,
  ProductVersionEntity,
  ProductVersionSchema,
  ShippingPolicyEntity,
  ShippingPolicySchema,
  ShippingType,
  ShippingTypeSchema,
} from '../../schemas';
import { LoginApiSuccessAction } from '../Login/actions';
import { LOGIN_API_SUCCESS } from '../Login/constants';
import { SelectShopApiSuccessAction } from '../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../ShopSelector/constants';
import {
  ProductSetProductDescriptionAction,
  ProductSetProductTitleAction,
} from './actions/description.actions';
import {
  ProductFetchApiFailureAction,
  ProductFetchApiSuccessAction,
} from './actions/fetch.actions';
import {
  ProductAddUploadedImageAction,
  ProductRemoveUploadedImageAction,
  ProductSetUploadedImagesAction,
} from './actions/images.actions';
import {
  ProductAddOptionAction,
  ProductAddOptionValueAction,
  ProductRemoveOptionAction,
  ProductRemoveOptionValueAction,
  ProductSetOptionAction,
} from './actions/options.actions';
import {
  ProductSetPropertiesAgeRestrictedAction,
  ProductSetPropertiesCustomizableAction,
  ProductSetPropertiesEnquiryAction,
} from './actions/properties.actions';
import {
  ProductSetSeoDescriptionAction,
  ProductSetSeoTitleAction,
  ProductSetSeoUrlAction,
} from './actions/seo.actions';
import {
  ProductAddInitStoreDataAction,
  ProductSetShippingAdditionalItemAction,
  ProductSetShippingBaseFeeAction,
  ProductToggleCollectionItemAction,
  ProductTogglePhysicalProductAction,
  ProductToggleShippingAvailableAction,
  ProductToggleShippingFreeAction,
} from './actions/stores.actions';
import {
  ProductSubmitApiFailureAction,
  ProductSubmitApiSuccessAction,
} from './actions/submit.actions';
import { ProductAddProductTagAction, ProductRemoveProductTagAction } from './actions/tags.actions';
import {
  ProductAddNewVariantAction,
  ProductCloneVariantAction,
  ProductRemoveVariantAction,
  ProductSetPurchaseNotInStockAction,
  ProductSetTrackInventoryAction,
  ProductSetVariantBarcodeAction,
  ProductSetVariantCompareToPriceAction,
  ProductSetVariantImageAction,
  ProductSetVariantOptionAction,
  ProductSetVariantPriceAction,
  ProductSetVariantProfitAction,
  ProductSetVariantProfitPctAction,
  ProductSetVariantQuantityAction,
  ProductSetVariantSkuAction,
  ProductToggleMultipleVariantsAction,
  ProductUndoRemoveVariantAction,
} from './actions/variants.actions';
import {
  PRODUCT_ADD_NEW_VARIANT,
  PRODUCT_ADD_OPTION,
  PRODUCT_ADD_OPTION_VALUE,
  PRODUCT_ADD_TAG,
  PRODUCT_ADD_UPLOADED_IMAGE,
  PRODUCT_CLONE_VARIANT,
  PRODUCT_FETCH_API_FAILURE,
  PRODUCT_FETCH_API_SUCCESS,
  PRODUCT_REMOVE_OPTION,
  PRODUCT_REMOVE_OPTION_VALUE,
  PRODUCT_REMOVE_TAG,
  PRODUCT_REMOVE_UPLOADED_IMAGE,
  PRODUCT_REMOVE_VARIANT,
  PRODUCT_SET_OPTION,
  PRODUCT_SET_PRODUCT_DESCRIPTION,
  PRODUCT_SET_PRODUCT_TITLE,
  PRODUCT_SET_PROPERTIES_AGE_RESTRICTED,
  PRODUCT_SET_PROPERTIES_CUSTOMIZABLE,
  PRODUCT_SET_PROPERTIES_ENQUIRY,
  PRODUCT_SET_PURCHASE_NOT_IN_STOCK,
  PRODUCT_SET_SEO_DESCRIPTION,
  PRODUCT_SET_SEO_TITLE,
  PRODUCT_SET_SEO_URL,
  PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM,
  PRODUCT_SET_SHIPPING_BASE_FEE,
  PRODUCT_SET_TRACK_INVENTORY,
  PRODUCT_SET_UPLOADED_IMAGES,
  PRODUCT_SET_VARIANT_BARCODE,
  PRODUCT_SET_VARIANT_COMPARE_TO_PRICE,
  PRODUCT_SET_VARIANT_IMAGE,
  PRODUCT_SET_VARIANT_OPTION,
  PRODUCT_SET_VARIANT_PRICE,
  PRODUCT_SET_VARIANT_PROFIT,
  PRODUCT_SET_VARIANT_QUANTITY,
  PRODUCT_SET_VARIANT_SKU,
  PRODUCT_SUBMIT_API_FAILURE,
  PRODUCT_SUBMIT_API_SUCCESS,
  PRODUCT_TOGGLE_COLLECTION_ITEM,
  PRODUCT_TOGGLE_MULTIPLE_VARIANTS,
  PRODUCT_TOGGLE_PHYSICAL_PRODUCT,
  PRODUCT_TOGGLE_SHIPPING_AVAILABLE,
  PRODUCT_TOGGLE_SHIPPING_FREE,
  PRODUCT_UNDO_REMOVE_VARIANT,
} from './constants';
import { cleanUpCapitalAlphanum, cleanUpNumber, setVariantParam } from './helper';
import { ProductUnpublishSuccessPayload } from './slices';
import {
  ImageItemType,
  Params,
  ProductShippingPolicy,
  ProductState,
  ProductVariantItem,
} from './types';
import { isShopNetProduct2, skuWithoutVendorPrefix } from './utils';

export const makeQueryKey = (productId: number): string => `${productId}`;

const initialSingleVariantState: ProductVariantItem = {
  barcode: '',
  compareToPrice: null,
  id: '',
  isDeleted: false,
  options: [],
  price: '0',
  purchaseNotInStock: false,
  quantity: 0,
  sku: '',
  trackInventory: true,
  netPrice: '',
  marginPct: '',
};

export const initialProductState: ProductState = {
  collections: [],
  error: null,
  images: [],
  information: {
    description: '',
    tags: [],
    title: '',
  },
  isDisabledVendor: false,
  isLoading: false,
  isMultipleVariants: false,
  isPhysicalProduct: true,
  isTouched: false,
  isTouchedUrlHandle: false,
  loaded: false,
  options: [],
  productId: null,
  productVersionId: null,
  properties: {
    ageRestricted: false,
    customizable: false,
    enquiry: ProductEnquiry.No,
  },
  seo: {
    description: '',
    title: '',
    url: '',
  },
  shipping: [],
  showUnpublish: false,
  status: null,
  variants: [initialSingleVariantState],
  vendorCode: null,
  versionType: null,
  enquiriesAllowed: false,
};

// FIXME doing this to cheat reducer's ProductAction type.
//    This is actually an anti-pattern: https://phryneas.de/redux-typescript-no-discriminating-union
const PRODUCT_UNPUBLISH_API_SUCCESS = 'product/unpublish/success';
interface ProductUnpublishSuccessAction extends Action {
  type: typeof PRODUCT_UNPUBLISH_API_SUCCESS;
  payload: ProductUnpublishSuccessPayload;
}

type ProductAction =
  | LocationChangeAction
  | ProductAddInitStoreDataAction
  | ProductAddNewVariantAction
  | ProductAddOptionAction
  | ProductAddOptionValueAction
  | ProductAddProductTagAction
  | ProductAddUploadedImageAction
  | ProductCloneVariantAction
  | ProductFetchApiFailureAction
  | ProductFetchApiSuccessAction
  | ProductRemoveOptionAction
  | ProductRemoveOptionValueAction
  | ProductRemoveProductTagAction
  | ProductRemoveUploadedImageAction
  | ProductRemoveVariantAction
  | ProductSetOptionAction
  | ProductSetProductDescriptionAction
  | ProductSetProductTitleAction
  | ProductSetPropertiesAgeRestrictedAction
  | ProductSetPropertiesCustomizableAction
  | ProductSetPropertiesEnquiryAction
  | ProductSetPurchaseNotInStockAction
  | ProductSetSeoDescriptionAction
  | ProductSetSeoTitleAction
  | ProductSetSeoUrlAction
  | ProductSetShippingAdditionalItemAction
  | ProductSetShippingBaseFeeAction
  | ProductSetTrackInventoryAction
  | ProductSetUploadedImagesAction
  | ProductSetVariantBarcodeAction
  | ProductSetVariantCompareToPriceAction
  | ProductSetVariantImageAction
  | ProductSetVariantOptionAction
  | ProductSetVariantPriceAction
  | ProductSetVariantProfitAction
  | ProductSetVariantProfitPctAction
  | ProductSetVariantQuantityAction
  | ProductSetVariantSkuAction
  | ProductSubmitApiFailureAction
  | ProductSubmitApiSuccessAction
  | ProductToggleCollectionItemAction
  | ProductToggleMultipleVariantsAction
  | ProductTogglePhysicalProductAction
  | ProductToggleShippingAvailableAction
  | ProductToggleShippingFreeAction
  | ProductUnpublishSuccessAction
  | ProductUndoRemoveVariantAction;

const productReducer: Reducer<ProductState, ProductAction> = (
  state = initialProductState,
  action: ProductAction
): ProductState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case PRODUCT_ADD_OPTION_VALUE: {
        const {
          payload: { id, value },
        } = action as ProductAddOptionValueAction;
        draft.options[draft.options.findIndex((option) => option.id === id)].values.push(
          capitalize(value)
        );
        draft.isTouched = true;
        break;
      }

      case PRODUCT_ADD_NEW_VARIANT: {
        const {
          payload: { variantId },
        } = action as ProductAddNewVariantAction;
        const options = state.options.map(() => '');
        const variant: ProductVariantItem = {
          barcode: '',
          compareToPrice: null,
          id: variantId,
          isDeleted: false,
          options,
          price: '0',
          purchaseNotInStock: false,
          quantity: 0,
          sku: '',
          trackInventory: true,
          netPrice: '',
          marginPct: '',
        };
        draft.variants.push(variant);
        draft.isTouched = true;
        break;
      }

      case PRODUCT_ADD_TAG: {
        const {
          payload: { tag },
        } = action as ProductAddProductTagAction;
        draft.information.tags.push(tag);
        draft.isTouched = true;
        break;
      }

      case PRODUCT_ADD_UPLOADED_IMAGE: {
        const {
          payload: { image },
        } = action as ProductAddUploadedImageAction;
        draft.images = [...draft.images, image];
        draft.isTouched = true;
        break;
      }

      case PRODUCT_CLONE_VARIANT: {
        const {
          payload: { variantId },
        } = action;
        const variantIdx = state.variants.findIndex((variant) => variant.id === variantId);
        const oldVariant = state.variants[variantIdx];
        const newVariant = { ...oldVariant, id: uuidv4() };
        draft.variants.splice(variantIdx + 1, 0, newVariant);
        draft.isTouched = true;
        break;
      }

      case PRODUCT_FETCH_API_FAILURE:
      case PRODUCT_SUBMIT_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      case PRODUCT_UNPUBLISH_API_SUCCESS:
      case PRODUCT_FETCH_API_SUCCESS:
      case PRODUCT_SUBMIT_API_SUCCESS: {
        const { entities } = action.payload.response;
        const { result } = action.payload.response;

        const {
          collectionIds,
          isDisabledVendor,
          showUnpublish,
          status,
          vendorCode,
          enquiriesAllowed,
        } = result;
        const productVersionId = result.productVersion;

        const newDraft: WritableDraft<ProductState> =
          productVersionId === 0 ? { ...state, ...initialProductState } : draft;

        newDraft.error = null;
        newDraft.isTouched = false;
        newDraft.loaded = true;
        newDraft.productVersionId = productVersionId;
        newDraft.vendorCode = vendorCode;
        newDraft.status = status as ProductStatus;
        newDraft.enquiriesAllowed = enquiriesAllowed;

        const allCollections: CollectionEntity[] = result.allCollections.map(
          (collectionId) => entities.collections[collectionId]
        );

        const productVersion: ProductVersionEntity = denormalize(
          productVersionId,
          ProductVersionSchema,
          entities
        );

        newDraft.versionType = productVersionId ? productVersion.versionType : null;

        const shippingPolicies: ShippingPolicyEntity[] = result.shippingPolicies
          ? result.shippingPolicies.map((shippingPolicyId) =>
              denormalize(shippingPolicyId, ShippingPolicySchema, entities)
            )
          : [];

        const shippingTypes: ShippingType[] = result.shippingTypes
          ? result.shippingTypes.map((shippingTypeId) =>
              denormalize(shippingTypeId, ShippingTypeSchema, entities)
            )
          : [];

        if (productVersionId) {
          // When there is only one variant with option "Title": "Default Title",
          // we display it as a "no multiple variants", same as Shopify does.
          // And to be correctly displayed when we turn "multiple variants" on,
          // update product options and variant options as well.
          if (
            productVersion.options.length === 1 &&
            productVersion.options[0].name === 'Title' &&
            productVersion.variants[0].option1 === 'Default Title'
          ) {
            productVersion.options = [];
            productVersion.variants[0].option1 = '';
          }

          newDraft.information.title = productVersion.title;
          newDraft.information.description = productVersion.descriptionHtml;
          newDraft.information.tags = productVersion.tags;
          newDraft.seo.title = productVersion.seoTitle;
          newDraft.seo.description = productVersion.seoDescription;
          newDraft.seo.url = productVersion.handle;
          newDraft.isDisabledVendor = isDisabledVendor;
          newDraft.isPhysicalProduct = productVersion.requiresShipping;
          newDraft.properties.ageRestricted = productVersion.age18plusRequired;
          newDraft.properties.customizable = productVersion.customizationEnabled;
          newDraft.properties.enquiry = productVersion.enquiry || ProductEnquiry.No;
          newDraft.options = productVersion.options;
          newDraft.isMultipleVariants = productVersion.options.length > 0;
          newDraft.showUnpublish = showUnpublish;

          const isShopNet = isShopNetProduct2(productVersionId);

          newDraft.variants = productVersion.variants.map((variant) => {
            const numOptions = newDraft.options.length;
            const options: string[] = [];
            if (numOptions >= 1) {
              options.push(variant.option1);
            }
            if (numOptions >= 2) {
              options.push(variant.option2);
            }
            if (numOptions >= 3) {
              options.push(variant.option3);
            }

            const retailPrice = isShopNet ? 15 : variant.price.toString();

            return {
              id: variant.id?.toString(),
              options,
              sku: skuWithoutVendorPrefix(variant.sku, vendorCode),
              barcode: variant.barcode,
              quantity: variant.quantity,
              price: retailPrice,
              compareToPrice:
                variant.compareAtPrice === null ? null : variant.compareAtPrice.toString(),
              imageId: variant.image?.id.toString(),
              imageSrc: decodeImageSrc(variant.image),
              isDeleted: false,
              trackInventory: variant.trackInventoryEnabled,
              purchaseNotInStock: variant.allowOverselling,
              netPrice: isShopNet ? 10 : retailPrice,
            } as ProductVariantItem;
          });

          newDraft.images = productVersion.images.map((image) => {
            return {
              id: image.id.toString(),
              imagePreviewUrl: decodeImageSrc(image),
              inputFile: null,
              itemType: ImageItemType.Image,
            };
          });
        }

        newDraft.collections = allCollections.map((collection) => {
          return {
            id: collection.id,
            name: collection.title,
            checked: productVersionId ? collectionIds.includes(collection.id) : false,
          };
        });

        const shippingPolicyToPageItem = (
          shippingPolicy: ShippingPolicyEntity
        ): ProductShippingPolicy => ({
          name: shippingPolicy.name,
          available: !shippingPolicy.disabled,
          freeShipping: shippingPolicy.free,
          baseShippingFee: shippingPolicy.baseFee,
          additionalItem: shippingPolicy.additionalFee,
        });

        const shippingTypeToPageItem = (shippingType: ShippingType): ProductShippingPolicy => ({
          name: shippingType.name,
          available: false,
          freeShipping: false,
          baseShippingFee: 0,
          additionalItem: 0,
        });

        newDraft.shipping = shippingTypes.map((shippingType) => {
          const shippingPolicy = shippingPolicies.find(
            (policy) => policy.name === shippingType.name
          );
          return shippingPolicy
            ? shippingPolicyToPageItem(shippingPolicy)
            : shippingTypeToPageItem(shippingType);
        });

        return newDraft;
      }

      case PRODUCT_REMOVE_OPTION: {
        const {
          payload: { id },
        } = action as ProductRemoveOptionAction;
        const optionIdx = draft.options.findIndex((option) => option.id === id);
        if (optionIdx < 0) {
          break;
        }
        draft.options.splice(
          draft.options.findIndex((prevOption) => prevOption.id === id),
          1
        );
        draft.variants.forEach((variant) => {
          variant.options.splice(optionIdx, 1);
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_REMOVE_OPTION_VALUE: {
        const {
          payload: { id, value },
        } = action as ProductRemoveOptionValueAction;
        const optionIndex = draft.options.findIndex((option) => option.id === id);

        // clear this option value in all the variants where it's used
        state.variants.forEach((variant, variantIndex) => {
          if (variant.options[optionIndex] === value) {
            draft.variants[variantIndex].options[optionIndex] = '';
          }
        });

        // remove option value
        draft.options[optionIndex].values = draft.options[optionIndex].values.filter(
          (optionValue) => optionValue !== value
        );
        draft.isTouched = true;
        break;
      }

      case PRODUCT_REMOVE_TAG: {
        const {
          payload: { tag },
        } = action as ProductRemoveProductTagAction;
        draft.information.tags.splice(
          draft.information.tags.findIndex((prevTag) => prevTag === tag),
          1
        );
        draft.isTouched = true;
        break;
      }

      case PRODUCT_REMOVE_UPLOADED_IMAGE: {
        const {
          payload: { id },
        } = action as ProductRemoveUploadedImageAction;
        const imageToRemove = draft.images.find((image) => image.id === id);
        const imageSrcToRemove = imageToRemove?.imagePreviewUrl;
        draft.images.splice(
          draft.images.findIndex((image) => image.id === id),
          1
        );
        draft.variants = state.variants.map((variant) => {
          return variant.imageSrc === imageSrcToRemove
            ? { ...variant, imageSrc: undefined, imageId: undefined }
            : variant;
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_REMOVE_VARIANT: {
        const {
          payload: { variantId },
        } = action;
        const variantToRemove = draft.variants.find((variant) => variant.id === variantId);
        if (variantToRemove) {
          variantToRemove.isDeleted = true;
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_ADD_OPTION: {
        const {
          payload: { name, values },
        } = action;
        const newOptionId = uuidv4();
        draft.options.push({ id: newOptionId, name, values });

        // add new option to all existing variants
        state.variants.forEach((variant, idx) => {
          draft.variants[idx].options.push('');
        });

        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_OPTION: {
        const {
          payload: { id, name },
        } = action as ProductSetOptionAction;
        const optionIndex = draft.options.findIndex((optionItem) => optionItem.id === id);
        if (optionIndex >= 0) {
          draft.options[optionIndex].name = name;
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_PRODUCT_DESCRIPTION: {
        const {
          payload: { description },
        } = action as ProductSetProductDescriptionAction;
        draft.information.description = description;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_PRODUCT_TITLE: {
        const {
          payload: { title },
        } = action as ProductSetProductTitleAction;
        draft.information.title = title;
        draft.isTouched = true;
        if (!draft.isTouchedUrlHandle || !draft.seo.url) {
          draft.seo.url = slugify(title, { lower: true, strict: true });
          draft.isTouchedUrlHandle = false;
        }
        break;
      }

      case PRODUCT_SET_PROPERTIES_AGE_RESTRICTED: {
        draft.properties.ageRestricted = !draft.properties.ageRestricted;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_PROPERTIES_CUSTOMIZABLE: {
        draft.properties.customizable = !draft.properties.customizable;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_PROPERTIES_ENQUIRY: {
        draft.properties.enquiry = action.payload.value;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_PURCHASE_NOT_IN_STOCK: {
        const {
          payload: { variantId },
        } = action as ProductSetPurchaseNotInStockAction;
        setVariantParam({
          draft,
          paramName: 'purchaseNotInStock',
          paramValue: !draft.variants.find((variantItem) => variantItem.id === variantId)
            ?.purchaseNotInStock,
          variantId,
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_SEO_DESCRIPTION: {
        const {
          payload: { description },
        } = action as ProductSetSeoDescriptionAction;
        draft.seo.description = description;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_SEO_TITLE: {
        const {
          payload: { title },
        } = action as ProductSetSeoTitleAction;
        draft.seo.title = title;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_SEO_URL: {
        const {
          payload: { url },
        } = action as ProductSetSeoUrlAction;
        draft.seo.url = onlySlugChars(url);
        draft.isTouched = true;
        draft.isTouchedUrlHandle = true;
        break;
      }

      case PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM: {
        const {
          payload: { shippingType, value },
        } = action as ProductSetShippingAdditionalItemAction;
        const policy = draft.shipping.find((item) => item.name === shippingType);
        if (policy) {
          policy.additionalItem = cleanUpNumber(value);
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_SHIPPING_BASE_FEE: {
        const {
          payload: { shippingType, value },
        } = action as ProductSetShippingBaseFeeAction;

        const policy = draft.shipping.find((item) => item.name === shippingType);
        if (policy) {
          policy.baseShippingFee = cleanUpNumber(value);
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_TRACK_INVENTORY: {
        const {
          payload: { variantId },
        } = action as ProductSetTrackInventoryAction;
        const variant = draft.variants.find((item) => item.id === variantId);
        if (variant) {
          variant.trackInventory = !variant.trackInventory;
          variant.purchaseNotInStock = !variant.trackInventory;
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_UPLOADED_IMAGES: {
        const {
          payload: { images },
        } = action as ProductSetUploadedImagesAction;
        draft.images = images;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_BARCODE: {
        const {
          payload: { variantId, barcode },
        } = action as ProductSetVariantBarcodeAction;
        setVariantParam({
          draft,
          paramName: 'barcode',
          paramValue: cleanUpCapitalAlphanum(barcode),
          variantId,
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_COMPARE_TO_PRICE: {
        const {
          payload: { variantId, compareToPrice },
        } = action as ProductSetVariantCompareToPriceAction;

        setVariantParam({
          draft,
          paramName: 'compareToPrice',
          paramValue: cleanUpNumber(compareToPrice),
          variantId,
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_IMAGE: {
        const {
          payload: { variantId, imageId },
        } = action as ProductSetVariantImageAction;

        const newImage = state.images.find((image) => image.id === imageId);

        const variantIndex = state.variants.findIndex(
          (variantItem) => variantItem.id === variantId
        );
        if (variantIndex >= 0) {
          draft.variants[variantIndex] = {
            ...draft.variants[variantIndex],
            imageId,
            imageSrc: newImage?.imagePreviewUrl,
          };
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_OPTION: {
        const {
          payload: { variantId, optionIndex, optionValue },
        } = action as ProductSetVariantOptionAction;
        const variantIndex = draft.variants.findIndex((variant) => variant.id === variantId);
        draft.variants[variantIndex].options[optionIndex] = optionValue;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_PROFIT: {
        const {
          payload: { variantId, profit },
        } = action as ProductSetVariantProfitAction;

        // TBD XXX

        console.log('profffffiiiiit');

        const variant = draft.variants.find((variantItem) => variantItem.id === variantId);
        console.log(variant?.price);
        console.log(variant?.netPrice);

        // const cost = wsPrice * 1.022 + 0.2 : parseFloat(price); // XXX TBD
        // const profit = retailPrice - cost;
        // const marginPct = cleanUpNumber((profit / retailPrice) * 100);
        // const cleanedProfit = cleanUpNumber(profit);

        setVariantParam({
          draft,
          paramName: 'profit',
          paramValue: cleanUpNumber(profit),
          variantId,
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_PRICE: {
        const {
          payload: { variantId, price },
        } = action as ProductSetVariantPriceAction;

        setVariantParam({
          draft,
          paramName: 'price',
          paramValue: cleanUpNumber(price),
          variantId,
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_QUANTITY: {
        const {
          payload: { variantId, quantity },
        } = action as ProductSetVariantQuantityAction;
        setVariantParam({
          draft,
          paramName: 'quantity',
          paramValue: quantity,
          variantId,
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_SET_VARIANT_SKU: {
        const {
          payload: { variantId, sku },
        } = action as ProductSetVariantSkuAction;
        setVariantParam({
          draft,
          paramName: 'sku',
          paramValue: cleanUpCapitalAlphanum(sku),
          variantId,
        });
        draft.isTouched = true;
        break;
      }

      case PRODUCT_TOGGLE_COLLECTION_ITEM: {
        const {
          payload: { id },
        } = action as ProductToggleCollectionItemAction;
        draft.collections = draft.collections.map((collectionItem) =>
          collectionItem.id === id
            ? { ...collectionItem, checked: !collectionItem.checked }
            : collectionItem
        );
        draft.isTouched = true;
        break;
      }

      case PRODUCT_TOGGLE_MULTIPLE_VARIANTS: {
        draft.isMultipleVariants = !draft.isMultipleVariants;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_TOGGLE_PHYSICAL_PRODUCT: {
        draft.isPhysicalProduct = !draft.isPhysicalProduct;
        draft.isTouched = true;
        break;
      }

      case PRODUCT_TOGGLE_SHIPPING_AVAILABLE: {
        const {
          payload: { shippingType },
        } = action as ProductToggleShippingAvailableAction;
        const policy = draft.shipping.find((item) => item.name === shippingType);
        if (policy) {
          policy.available = !policy.available;
          if (!policy.available) {
            policy.freeShipping = false;
          }
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_TOGGLE_SHIPPING_FREE: {
        const {
          payload: { shippingType },
        } = action as ProductToggleShippingFreeAction;
        const policy = draft.shipping.find((item) => item.name === shippingType);
        if (policy) {
          policy.freeShipping = !policy.freeShipping;
        }
        draft.isTouched = true;
        break;
      }

      case PRODUCT_UNDO_REMOVE_VARIANT: {
        const {
          payload: { variantId },
        } = action;
        const variantToUndo = draft.variants.find((variant) => variant.id === variantId);
        if (variantToUndo) {
          variantToUndo.isDeleted = false;
        }
        draft.isTouched = true;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
};

export type ProductPageState = {
  products: { [queryKey: string]: ProductState };
};

const initialState: ProductPageState = {
  products: {
    0: initialProductState,
  },
};

type ProductPageAction =
  | ProductAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | ResetSessionAction
  | SelectShopApiSuccessAction;

export const productPageReducer: Reducer<ProductPageState, ProductPageAction> = (
  state: ProductPageState = initialState,
  action: ProductPageAction
): ProductPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCT_ADD_NEW_VARIANT:
      case PRODUCT_ADD_OPTION:
      case PRODUCT_ADD_OPTION_VALUE:
      case PRODUCT_ADD_TAG:
      case PRODUCT_ADD_UPLOADED_IMAGE:
      case PRODUCT_CLONE_VARIANT:
      case PRODUCT_FETCH_API_FAILURE:
      case PRODUCT_FETCH_API_SUCCESS:
      case PRODUCT_REMOVE_OPTION:
      case PRODUCT_REMOVE_OPTION_VALUE:
      case PRODUCT_REMOVE_TAG:
      case PRODUCT_REMOVE_UPLOADED_IMAGE:
      case PRODUCT_REMOVE_VARIANT:
      case PRODUCT_SET_OPTION:
      case PRODUCT_SET_PRODUCT_DESCRIPTION:
      case PRODUCT_SET_PRODUCT_TITLE:
      case PRODUCT_SET_PROPERTIES_AGE_RESTRICTED:
      case PRODUCT_SET_PROPERTIES_CUSTOMIZABLE:
      case PRODUCT_SET_PROPERTIES_ENQUIRY:
      case PRODUCT_SET_PURCHASE_NOT_IN_STOCK:
      case PRODUCT_SET_SEO_DESCRIPTION:
      case PRODUCT_SET_SEO_TITLE:
      case PRODUCT_SET_SEO_URL:
      case PRODUCT_SET_SHIPPING_ADDITIONAL_ITEM:
      case PRODUCT_SET_SHIPPING_BASE_FEE:
      case PRODUCT_SET_TRACK_INVENTORY:
      case PRODUCT_SET_UPLOADED_IMAGES:
      case PRODUCT_SET_VARIANT_BARCODE:
      case PRODUCT_SET_VARIANT_COMPARE_TO_PRICE:
      case PRODUCT_SET_VARIANT_IMAGE:
      case PRODUCT_SET_VARIANT_OPTION:
      case PRODUCT_SET_VARIANT_PRICE:
      case PRODUCT_SET_VARIANT_QUANTITY:
      case PRODUCT_SET_VARIANT_SKU:
      case PRODUCT_SUBMIT_API_FAILURE:
      case PRODUCT_SUBMIT_API_SUCCESS:
      case PRODUCT_TOGGLE_COLLECTION_ITEM:
      case PRODUCT_TOGGLE_MULTIPLE_VARIANTS:
      case PRODUCT_TOGGLE_PHYSICAL_PRODUCT:
      case PRODUCT_TOGGLE_SHIPPING_AVAILABLE:
      case PRODUCT_TOGGLE_SHIPPING_FREE:
      case PRODUCT_UNDO_REMOVE_VARIANT: {
        const key = makeQueryKey(action.payload.productId);
        draft.products[key] = productReducer(state.products[key], action);
        break;
      }
      case PRODUCT_UNPUBLISH_API_SUCCESS: {
        const key = makeQueryKey(action.payload.request.productId);
        draft.products[key] = productReducer(state.products[key], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath<Params>(location.pathname, {
          path: PRODUCT_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
