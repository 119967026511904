/* eslint-disable  @typescript-eslint/no-explicit-any */
import { faBars, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap';
import FieldError from '../../../../components/FieldError/FieldError';
import { getSessionUser } from '../../../../global/session/selectors';
import { currencyToSymbol } from '../../../../helpers/utils';
import { ShopEntity, UserRole } from '../../../../schemas';
import { NormalizedError } from '../../../../types';
import { cleanUpNumber } from '../../helper';
import { ProductVariantActions } from '../../hooks/useProductVariantsData';
import { ProductOption, ProductVariantItem } from '../../types';
import OptionSelector from '../OptionSelector/OptionSelector';
import UpdateVariantImage from '../UpdateVariantImage/UpdateVariantImage';
import styles from './ProductVariant.module.scss';

type ProductVariantProps = ProductVariantActions &
  ProductVariantItem & {
    availableOptions: ProductOption[];
    disabled?: boolean;
    error: NormalizedError | null;
    imageSrc?: string;
    index: number;
    isGiftCardProduct: boolean;
    isShopNetProduct: boolean;
    isSingleVariant: boolean;
    shop: ShopEntity | null;
    vendorCode: string | null;
  };

const ProductVariant: FC<ProductVariantProps> = ({
  availableOptions,
  barcode = '',
  cloneVariant,
  compareToPrice,
  disabled,
  error,
  id,
  imageSrc,
  index,
  isDeleted,
  isGiftCardProduct,
  isShopNetProduct,
  isSingleVariant,
  options,
  price,
  netPrice,
  purchaseNotInStock = false,
  quantity = 0,
  removeVariant,
  setBarcode,
  setCompareToPrice,
  setImage,
  setOption,
  setPrice,
  setProfit,
  setProfitPct,
  setPurchaseNotInStock,
  setQuantity,
  setSku,
  setTrackInventory,
  shop,
  sku = '',
  trackInventory = false,
  undoRemoveVariant,
  vendorCode,
}: ProductVariantProps) => {
  const user = useSelector(getSessionUser);
  const currencySymbol = currencyToSymbol(shop?.currency);
  const isVendor = user?.role === UserRole.Vendor;
  // const wsPrice = isShopNet ? 10 : parseFloat(price); // XXX TBD
  // const retailPrice = isShopNet ? 15 : parseFloat(price); // XXX TBD
  const wsPrice = parseFloat(netPrice);
  const retailPrice = parseFloat(price);
  const cost = isShopNetProduct ? wsPrice * 1.022 + 0.2 : parseFloat(price); // XXX TBD
  const profit = retailPrice - cost;
  const marginPct = cleanUpNumber((profit / retailPrice) * 100);
  const cleanedProfit = cleanUpNumber(profit);
  return (
    <Row className={cx('mb-2')}>
      <Col lg={12} className="position-relative">
        <div className="border">
          <Row>
            <Col className="d-flex flex-row align-items-center pl-3">
              <FontAwesomeIcon className={cx('ml-3 my-2', styles.hamburger)} icon={faBars} />
              {Object.keys(availableOptions).map((option, optionIdx) => {
                const { name } = availableOptions[optionIdx];
                const value = options[optionIdx];
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${optionIdx}`} className={cx('small pl-3', styles.option)}>
                    <span className={styles.optionName}>{name}:</span>{' '}
                    <OptionSelector
                      allOptions={availableOptions}
                      name={name}
                      onSelect={(selectedValue) => setOption(id, optionIdx, selectedValue)}
                      selected={value}
                      disabled={disabled}
                    />
                  </div>
                );
              })}
              {error && (
                <>
                  &nbsp;
                  <FieldError
                    error={error}
                    name={(json: any) => {
                      const optionsErrors =
                        json.variants && json.variants[index] && json.variants[index].options;
                      if (!optionsErrors) return false;
                      if (typeof optionsErrors === 'string') return optionsErrors;
                      const isBlank = json.code.variants[index].options[0][0] === 'blank';
                      return isBlank
                        ? 'Assign option(s) to this variant.'
                        : (optionsErrors[0][0] as any);
                    }}
                    showWarningIcon
                  />
                </>
              )}
            </Col>
            {!isSingleVariant && (
              <Col xs="auto" className="text-right">
                <button
                  type="button"
                  className={disabled ? 'btn-outline-secondary.disabled' : 'gray-600'}
                  onClick={() => cloneVariant(id)}
                  disabled={disabled}
                >
                  <FontAwesomeIcon className="mr-4 my-2" icon={faCopy} />
                </button>
                <button
                  type="button"
                  className={disabled ? 'btn-outline-secondary.disabled' : 'gray-600'}
                  onClick={() => removeVariant(id)}
                  disabled={disabled}
                >
                  <FontAwesomeIcon className="mr-2 my-2" icon={faTrash} />
                </button>
              </Col>
            )}
          </Row>
        </div>
        <div className="border-left border-right border-bottom p-2">
          <Row>
            <Col>
              <Form className={cx('d-flex flex-row p-2')}>
                <UpdateVariantImage
                  imageSrc={imageSrc}
                  variantId={id}
                  setImage={setImage}
                  disabled={disabled}
                />

                <div className="ml-3 mr-3">
                  <Row>
                    <Col lg={5} className="text-nowrap">
                      <Label className="small">{isShopNetProduct ? 'Retail Price' : 'Price'}</Label>
                    </Col>
                    <Col lg={7} className="text-right">
                      {!isGiftCardProduct && <Label className="small">Compare to</Label>}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup size="sm">
                        <InputGroupAddon addonType="prepend">{currencySymbol}</InputGroupAddon>
                        <Input
                          disabled={disabled && !isGiftCardProduct}
                          value={price !== undefined ? cleanUpNumber(retailPrice) : ''}
                          onChange={(e) => setPrice(id, e.target.value)}
                          type="number"
                          placeholder="Price"
                          required
                          min={0}
                          style={{ maxWidth: '80px' }}
                        />
                        {!isGiftCardProduct && (
                          <Input
                            disabled={disabled}
                            value={compareToPrice !== null ? compareToPrice : ''}
                            onChange={(e) => setCompareToPrice(id, e.target.value)}
                            type="number"
                            placeholder="Price"
                            min={0}
                            style={{ maxWidth: '80px' }}
                          />
                        )}
                      </InputGroup>
                      <FieldError
                        error={error}
                        name={(json: any) =>
                          json.variants && json.variants[index] && json.variants[index].price
                        }
                      />
                    </Col>
                  </Row>
                  {isShopNetProduct && (
                    <Row>
                      <Col className="small">
                        Net price + fees: {currencySymbol}
                        {cost}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      {!isGiftCardProduct && (
                        <CustomInput
                          checked={trackInventory}
                          className="custom-sss-switch mt-2 sss-gray-1100 text-nowrap"
                          id={`variant-${id}-track-inventory`}
                          label="Track inventory"
                          onChange={() => setTrackInventory(id)}
                          type="switch"
                          disabled={disabled}
                        />
                      )}
                    </Col>
                  </Row>
                </div>

                {!isVendor && false && (
                  <div className="ml-3 mr-3">
                    <Row>
                      <Col lg={5} className="text-nowrap">
                        <Label className="small">Your Profit</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">{currencySymbol}</InputGroupAddon>
                          <Input
                            disabled={disabled && !isGiftCardProduct}
                            value={cleanedProfit}
                            onChange={(e) => setProfit(id, e.target.value)}
                            type="number"
                            placeholder="Profit"
                            required
                            min={0}
                            style={{ maxWidth: '80px' }}
                          />
                          <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                          <Input
                            disabled={disabled}
                            value={marginPct}
                            onChange={(e) => setProfitPct(id, e.target.value)}
                            type="number"
                            placeholder="Margin"
                            min={0}
                            style={{ maxWidth: '70px' }}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                )}

                {!isGiftCardProduct && (
                  <div className="mr-3">
                    <Row>
                      <Col>
                        <Label className="small">SKU</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">{vendorCode}</InputGroupAddon>
                          <Input
                            disabled={disabled}
                            value={sku}
                            onChange={(e) => setSku(id, e.target.value)}
                            type="text"
                            placeholder="SKU"
                            required
                            className="small"
                            style={{ maxWidth: '170px' }}
                          />
                        </InputGroup>
                        <FieldError
                          error={error}
                          name={(json: any) =>
                            json.variants && json.variants[index] && json.variants[index].sku
                          }
                        />
                      </Col>
                    </Row>
                    {isShopNetProduct && (
                      <Row>
                        <Col className="small">&nbsp;</Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <CustomInput
                          checked={purchaseNotInStock}
                          className="custom-sss-switch mt-2 sss-gray-1100 text-nowrap"
                          disabled={disabled || !trackInventory}
                          id={`variant-${id}-purchase-not-in-stock`}
                          label="Allow overselling"
                          onChange={() => setPurchaseNotInStock(id)}
                          type="switch"
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                {!isGiftCardProduct && (
                  <FormGroup className="mr-3">
                    <Label className="small">Quantity</Label>
                    <Input
                      bsSize="sm"
                      disabled={disabled || !trackInventory}
                      value={trackInventory ? quantity : ''}
                      onChange={(e) => setQuantity(id, Number(e.target.value))}
                      type="number"
                      min={0}
                      style={{ maxWidth: '60px' }}
                    />
                  </FormGroup>
                )}

                {!isGiftCardProduct && (
                  <FormGroup>
                    <Label className="small">Barcode</Label>
                    <Input
                      bsSize="sm"
                      disabled={disabled}
                      value={barcode}
                      onChange={(e) => setBarcode(id, e.target.value)}
                      type="text"
                      placeholder="Barcode"
                    />
                  </FormGroup>
                )}
              </Form>
            </Col>
          </Row>
        </div>
        {isDeleted && (
          <div className={styles.deleted}>
            <div className={styles.deleted__overlay} />
            <Button
              color="sss-primary-outline"
              className={styles.deleted__button}
              onClick={() => undoRemoveVariant(id)}
            >
              Undo deletion
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ProductVariant;
