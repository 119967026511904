import React, { FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import RejectPublishModal from '../../../Moderation/components/RejectPublishModal';
import ProductSubmitButton from '../ProductSubmitButton/ProductSubmitButton';

interface ModerationButtonsProps {
  approvePublishTrigger: () => void;
  rejectPublishTrigger: (message: string) => void;
  canSubmit: boolean;
  isSubmitting: boolean;
}

const ModerationButtons: FC<ModerationButtonsProps> = ({
  approvePublishTrigger,
  rejectPublishTrigger,
  canSubmit,
  isSubmitting,
}: ModerationButtonsProps) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const toggleRejectModal = () => setIsRejectModalOpen(!isRejectModalOpen);
  const openRejectModal = () => setIsRejectModalOpen(true);

  return (
    <Row>
      <Col>
        <ProductSubmitButton
          label="Reject"
          canSubmit={canSubmit}
          isSubmitting={isSubmitting}
          submit={openRejectModal}
          negative
        />
        <RejectPublishModal
          isOpen={isRejectModalOpen}
          toggle={toggleRejectModal}
          onSubmit={rejectPublishTrigger}
        />
      </Col>
      <Col>
        <ProductSubmitButton
          label="Approve"
          canSubmit={canSubmit}
          isSubmitting={isSubmitting}
          submit={approvePublishTrigger}
        />
      </Col>
    </Row>
  );
};

export default ModerationButtons;
