import { createReducer } from '@reduxjs/toolkit';
import { LOGOUT_API_SUCCESS } from '../../global/logout/constants';
import { RESET_SESSION } from '../../global/session/constants';
import { makeApiRequestSlice } from '../../helpers/apiRequestSlice';
import { NormalizedResponse } from '../../types';
import { LOGIN_API_SUCCESS } from '../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../ShopSelector/constants';

interface OrdersChartData {
  count: number;
  month: number;
  year: number;
}

interface RevenueChartData {
  revenue: number;
  month: number;
  year: number;
}

type DashboardChartsFetchPayload = Record<string, never>;

interface DashboardChartsSuccessPayload {
  request: DashboardChartsFetchPayload;
  response: NormalizedResponse & {
    result: {
      orders: OrdersChartData[];
      revenue: RevenueChartData[];
    };
  };
}

export const fetchDashboardChartsSlice = makeApiRequestSlice<
  DashboardChartsFetchPayload,
  DashboardChartsSuccessPayload
>({
  name: 'dashboardCharts',
});

export interface DashboardChartsState {
  ordersData: OrdersChartData[];
  revenueData: RevenueChartData[];
}

const initialState: DashboardChartsState = {
  ordersData: [],
  revenueData: [],
};

const resetState = () => initialState;

export const dashboardChartsDataReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchDashboardChartsSlice.actions.success, (draft, action) => {
    draft.ordersData = action.payload.response.result.orders;
    draft.revenueData = action.payload.response.result.revenue;
  });
  builder.addCase(LOGIN_API_SUCCESS, resetState);
  builder.addCase(LOGOUT_API_SUCCESS, resetState);
  builder.addCase(RESET_SESSION, resetState);
  builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
});
