import { ApiSuccessResultActionPayload, makeApiRequestSlice } from '../../helpers/apiRequestSlice';
import { CollectionProduct } from '../../schemas';
import { NormalizedResponse } from '../../types';
import { CollectionSubmitValues } from './types';

// fetch

export interface CollectionFetchPayload {
  collectionId: number;
}

export interface CollectionSuccessPayload {
  request: CollectionFetchPayload;
  response: NormalizedResponse & {
    result: {
      products: CollectionProduct[];
      shop: number;
    };
  };
}

export const fetchCollectionSlice = makeApiRequestSlice<
  CollectionFetchPayload,
  CollectionSuccessPayload
>({
  name: 'collection/fetch',
});

export type FetchCollectionSuccessAction = ReturnType<typeof fetchCollectionSlice.actions.success>;

// browse

export interface CollectionBrowseFetchPayload {
  collectionId: number;
  search?: number;
}

export interface CollectionBrowseSuccessPayload {
  request: CollectionBrowseFetchPayload;
  response: NormalizedResponse & {
    result: {
      count: number;
      products: number[];
    };
  };
}

export const fetchCollectionBrowseSlice = makeApiRequestSlice<
  CollectionBrowseFetchPayload,
  CollectionBrowseSuccessPayload
>({
  name: 'collection/browse',
});

export type FetchCollectionBrowseSuccessAction = ReturnType<
  typeof fetchCollectionBrowseSlice.actions.success
>;

// fetch products

export interface CollectionProductsFetchPayload {
  collectionId: number;
  start?: number;
}

export interface CollectionProductsSuccessPayload {
  request: CollectionProductsFetchPayload;
  response: NormalizedResponse & {
    result: {
      products: CollectionProduct[];
      shop: number;
    };
  };
}

export const fetchCollectionProductsSlice = makeApiRequestSlice<
  CollectionProductsFetchPayload,
  CollectionProductsSuccessPayload
>({
  name: 'collection/fetchProducts',
});

export type FetchCollectionProductsSuccessAction = ReturnType<
  typeof fetchCollectionProductsSlice.actions.success
>;

// submit

export type CollectionSubmitPayload = {
  collectionId: number;
  values: CollectionSubmitValues;
};

export type CollectionSubmitResult = {
  collection: number;
};

export type CollectionSubmitSuccessPayload = ApiSuccessResultActionPayload<
  CollectionSubmitPayload,
  CollectionSubmitResult
>;

const collectionSubmitSlice = makeApiRequestSlice<
  CollectionSubmitPayload,
  CollectionSubmitSuccessPayload
>({
  name: 'collection/submit',
});

export default collectionSubmitSlice;
