// https://hackernoon.com/handling-loading-actions-the-proper-way-in-redux-t3k36e8
import { Action, combineReducers, Reducer } from 'redux';

interface ProcessingState {
  [actionPrefix: string]: boolean;
}

export interface UiState {
  processing: ProcessingState;
}

const processingReducer: Reducer<ProcessingState, Action> = (
  state: ProcessingState = {},
  action: Action
) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) {
    return state;
  }

  const [, requestPrefix, requestState] = matches;
  return {
    ...state,
    [requestPrefix]: requestState === 'REQUEST',
  };
};

export const uiReducer = combineReducers<UiState>({
  processing: processingReducer,
});
