import React, { FC } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatTooltipLabel, formatTooltipValue, formatXAxis, formatYAxis } from '../../utils';

interface OrdersChartItem {
  count: number;
  month: number;
  year: number;
}

interface Props {
  data: OrdersChartItem[];
  numMonths: number;
}

const DashOrdersChart: FC<Props> = ({ data, numMonths }: Props) => {
  const chartData = data.slice(0, numMonths).map((item) => ({
    Orders: item.count,
    name: `${item.year}-${item.month}`,
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={chartData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          reversed
          tick={{ fontFamily: 'sans-serif', fontSize: '14px' }}
          tickFormatter={formatXAxis}
        />
        <YAxis tick={{ fontFamily: 'sans-serif', fontSize: '14px' }} tickFormatter={formatYAxis} />
        <Tooltip
          formatter={formatTooltipValue}
          labelFormatter={formatTooltipLabel}
          wrapperStyle={{ fontFamily: 'sans-serif', fontSize: '14px' }}
        />
        <Area
          dataKey="Orders"
          fill="#2070e740"
          isAnimationActive={false}
          stroke="#2371e7"
          strokeWidth={1}
          type="monotone"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DashOrdersChart;
