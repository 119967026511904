import { createReducer } from '@reduxjs/toolkit';
import { ApiSuccessResultActionPayload, makeApiRequestSlice } from '../../helpers/apiRequestSlice';

export type SetTagsRequestPayload = {
  productIds: number[];
  tags: string[];
};

type SetTagsSuccessResult = {
  productsUpdated: number;
};

export type SetTagsSuccessPayload = ApiSuccessResultActionPayload<
  SetTagsRequestPayload,
  SetTagsSuccessResult
>;

export const setTagsSlice = makeApiRequestSlice<SetTagsRequestPayload, SetTagsSuccessPayload>({
  name: 'products/setTags',
});

export type AddTagsRequestPayload = {
  productIds: number[];
  tags: string[];
};

type AddTagsSuccessResult = {
  productsUpdated: number;
};

export type AddTagsSuccessPayload = ApiSuccessResultActionPayload<
  AddTagsRequestPayload,
  AddTagsSuccessResult
>;

export const addTagsSlice = makeApiRequestSlice<AddTagsRequestPayload, AddTagsSuccessPayload>({
  name: 'products/addTags',
});

export type RemoveTagsRequestPayload = {
  productIds: number[];
  tags: string[];
};

type RemoveTagsSuccessResult = {
  productsUpdated: number;
};

export type RemoveTagsSuccessPayload = ApiSuccessResultActionPayload<
  RemoveTagsRequestPayload,
  RemoveTagsSuccessResult
>;

export const removeTagsSlice = makeApiRequestSlice<
  RemoveTagsRequestPayload,
  RemoveTagsSuccessPayload
>({
  name: 'products/removeTags',
});

export type SetCollectionsRequestPayload = {
  productIds: number[];
  collectionIds: number[];
};

type SetCollectionsSuccessResult = {
  productsUpdated: number;
};

export type SetCollectionsSuccessPayload = ApiSuccessResultActionPayload<
  SetCollectionsRequestPayload,
  SetCollectionsSuccessResult
>;

export const setCollectionsSlice = makeApiRequestSlice<
  SetCollectionsRequestPayload,
  SetCollectionsSuccessPayload
>({
  name: 'products/setCollections',
});

export type ApproveProductsRequestPayload = {
  productIds: number[];
  collectionIds: number[];
};

type ApproveProductsSuccessResult = {
  productsUpdated: number;
};

export type ApproveProductsSuccessPayload = ApiSuccessResultActionPayload<
  ApproveProductsRequestPayload,
  ApproveProductsSuccessResult
>;

export const approveProductsSlice = makeApiRequestSlice<
  ApproveProductsRequestPayload,
  ApproveProductsSuccessPayload
>({
  name: 'warehouse/approveProducts',
});

export type RemoveCollectionsRequestPayload = {
  productIds: number[];
  collectionIds: number[];
};

type RemoveCollectionsSuccessResult = {
  productsUpdated: number;
};

export type RemoveCollectionsSuccessPayload = ApiSuccessResultActionPayload<
  RemoveCollectionsRequestPayload,
  RemoveCollectionsSuccessResult
>;

export const removeCollectionsSlice = makeApiRequestSlice<
  RemoveCollectionsRequestPayload,
  RemoveCollectionsSuccessPayload
>({
  name: 'products/removeCollections',
});

export type RejectProductsRequestPayload = {
  productIds: number[];
};

export type RejectProductsResult = {
  unpublishedProducts: number;
};

export type RejectProductsSuccessPayload = ApiSuccessResultActionPayload<
  RejectProductsRequestPayload,
  RejectProductsResult
>;

export const rejectProductsSlice = makeApiRequestSlice<
  RejectProductsRequestPayload,
  RejectProductsSuccessPayload
>({ name: 'warehouse/rejectProducts' });

export type ArchiveProductsRequestPayload = {
  productIds: number[];
};

export type ArchiveProductsResult = {
  archivedProducts: number;
};

export type ArchiveProductsSuccessPayload = ApiSuccessResultActionPayload<
  ArchiveProductsRequestPayload,
  ArchiveProductsResult
>;

export const archiveProductsSlice = makeApiRequestSlice<
  ArchiveProductsRequestPayload,
  ArchiveProductsSuccessPayload
>({ name: 'products/archive' });

export type UnarchiveProductsRequestPayload = {
  productIds: number[];
};

export type UnarchiveProductsResult = {
  unarchivedProducts: number;
};

export type UnarchiveProductsSuccessPayload = ApiSuccessResultActionPayload<
  UnarchiveProductsRequestPayload,
  UnarchiveProductsResult
>;

export const unarchiveProductsSlice = makeApiRequestSlice<
  UnarchiveProductsRequestPayload,
  UnarchiveProductsSuccessPayload
>({ name: 'products/unarchive' });

export type GetProductsTagsRequestPayload = {
  productIds?: number[];
};

export type GetProductsTagsResult = {
  tags: string[];
};

export type GetProductsTagsSuccessPayload = ApiSuccessResultActionPayload<
  GetProductsTagsRequestPayload,
  GetProductsTagsResult
>;

export const getProductsTagsSlice = makeApiRequestSlice<
  GetProductsTagsRequestPayload,
  GetProductsTagsSuccessPayload
>({ name: 'products/tags' });

export type ProductsTagsState = {
  tags: string[];
};

export const productsTagsReducer = createReducer<ProductsTagsState>({ tags: [] }, (builder) => {
  builder.addCase(getProductsTagsSlice.actions.success, (draft, action) => {
    draft.tags = action.payload.response.result.tags;
  });
});

export type GetProductsCollectionsRequestPayload = {
  productIds?: number[];
};

export type GetProductsCollectionsResult = {
  collections: number[];
};

export type GetProductsCollectionsSuccessPayload = ApiSuccessResultActionPayload<
  GetProductsCollectionsRequestPayload,
  GetProductsCollectionsResult
>;

export const getProductsCollectionsSlice = makeApiRequestSlice<
  GetProductsCollectionsRequestPayload,
  GetProductsCollectionsSuccessPayload
>({ name: 'products/collections' });

export type ProductsCollectionsState = {
  collectionIds: number[];
};

export const productsCollectionsReducer = createReducer<ProductsCollectionsState>(
  { collectionIds: [] },
  (builder) => {
    builder.addCase(getProductsCollectionsSlice.actions.success, (draft, action) => {
      draft.collectionIds = action.payload.response.result.collections;
    });
  }
);

export type WarehouseAddToStoreRequestPayload = {
  whProductId: number;
  collectionIds: number[];
};

export type WarehouseAddToStoreResult = {
  productId: number;
};

export type WarehouseAddToStoreSuccessPayload = ApiSuccessResultActionPayload<
  WarehouseAddToStoreRequestPayload,
  WarehouseAddToStoreResult
>;

export const warehouseAddToStoreSlice = makeApiRequestSlice<
  WarehouseAddToStoreRequestPayload,
  WarehouseAddToStoreSuccessPayload
>({
  name: 'warehouse/addToStore',
});
