import { faArchive, faBoxes, faBoxOpen, faTags, faUnlink } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import useToggle from '../../../../components/ModalForm/useToggle';
import { BriefProductEntity, ProductStatus } from '../../../../schemas';
import {
  ArchiveProductsRequestPayload,
  archiveProductsSlice,
  UnarchiveProductsRequestPayload,
  unarchiveProductsSlice,
  UnpublishProductsRequestPayload,
  unpublishProductsSlice,
} from '../../slices';
import ArchiveProductsModal from '../ArchiveProductsModal/ArchiveProductsModal';
import { EditCollectionsModal } from '../EditCollectionsModal/EditCollectionsModal';
import { EditTagsModal } from '../EditTagsModal/EditTagsModal';
import UnarchiveProductsModal from '../UnarchiveProductsModal/UnarchiveProductsModal';
import UnpublishProductsModal from '../UnpublishProductsModal/UnpublishProductsModal';

export type Props = {
  product: BriefProductEntity;
};

const ProductActionsDropdown: FC<Props> = ({ product }: Props) => {
  const [editTagsOpen, editTagsToggle] = useToggle();
  const [editCollectionsOpen, editCollectionsToggle] = useToggle();
  const [unpublishOpen, unpublishToggle] = useToggle();
  const [archiveOpen, archiveToggle] = useToggle();
  const [unarchiveOpen, unarchiveToggle] = useToggle();

  const dropDownItems: DropDownItemDefinition[] = [];

  if (product.status !== ProductStatus.Archived) {
    dropDownItems.push(
      ...[
        { text: 'Edit Tags', icon: faTags, onClick: editTagsToggle },
        { text: 'Edit Collections', icon: faBoxes, onClick: editCollectionsToggle },
        null,
      ]
    );
  }

  if (product.status === ProductStatus.Unpublished) {
    dropDownItems.push({ text: 'Archive', icon: faArchive, onClick: archiveToggle });
  } else if (product.status === ProductStatus.Archived) {
    dropDownItems.push({ text: 'Unarchive', icon: faBoxOpen, onClick: unarchiveToggle });
  } else if (product.status !== ProductStatus.Draft) {
    dropDownItems.push({ text: 'Unpublish', icon: faUnlink, onClick: unpublishToggle });
  }

  const dispatch = useDispatch();
  const unpublishProductsTrigger = (payload: UnpublishProductsRequestPayload) =>
    dispatch(unpublishProductsSlice.actions.trigger(payload));
  const archiveProductsTrigger = (payload: ArchiveProductsRequestPayload) =>
    dispatch(archiveProductsSlice.actions.trigger(payload));
  const unarchiveProductsTrigger = (payload: UnarchiveProductsRequestPayload) =>
    dispatch(unarchiveProductsSlice.actions.trigger(payload));

  return (
    <>
      <DropdownMenu
        component="item"
        items={dropDownItems}
        // disabled={disabled}
      />
      <EditTagsModal isOpen={editTagsOpen} toggle={editTagsToggle} product={product} />
      <EditCollectionsModal
        isOpen={editCollectionsOpen}
        toggle={editCollectionsToggle}
        product={product}
      />
      <UnpublishProductsModal
        isOpen={unpublishOpen}
        toggle={unpublishToggle}
        selectedProducts={new Set([product.productId])}
        submit={unpublishProductsTrigger}
      />
      <ArchiveProductsModal
        isOpen={archiveOpen}
        toggle={archiveToggle}
        selectedProducts={new Set([product.productId])}
        submit={archiveProductsTrigger}
      />
      <UnarchiveProductsModal
        isOpen={unarchiveOpen}
        toggle={unarchiveToggle}
        selectedProducts={new Set([product.productId])}
        submit={unarchiveProductsTrigger}
      />
    </>
  );
};

export default ProductActionsDropdown;
