import { orderBy } from 'lodash';
import {
  MappedProductVariantItem,
  ProductState,
  SetShippingParam,
  SetVariantParam,
  SortType,
} from './types';

export const setVariantParam = ({
  draft,
  paramName,
  paramValue,
  variantId,
}: SetVariantParam): ProductState => {
  const variantIndex = draft.variants.findIndex((variantItem) => variantItem.id === variantId);
  if (variantIndex !== -1) {
    draft.variants[variantIndex] = {
      ...draft.variants[variantIndex],
      [paramName]: paramValue,
    };
  }
  return draft;
};

export const setShippingParam = ({
  draft,
  paramName,
  paramValue,
  shippingType,
}: SetShippingParam): ProductState => {
  const policyIdx = draft.shipping.findIndex((policy) => policy.name === shippingType);
  if (policyIdx < 0) return draft;
  draft.shipping[policyIdx] = { ...draft.shipping[policyIdx], [paramName]: paramValue };
  return draft;
};

export const getOptionsValues = (optionsObj?: { [name: string]: string }): string => {
  if (optionsObj) {
    return Object.values(optionsObj).join(' ');
  }
  return ' ';
};

export const sortVariants = (
  variantsArr: MappedProductVariantItem[],
  sortType: SortType
): MappedProductVariantItem[] => {
  if (sortType.key === '') {
    return variantsArr;
  }
  const sortDirection = sortType.ascending ? 'asc' : 'desc';
  return orderBy(variantsArr, [sortType.key], [sortDirection]);
};

export const cleanUpNumber = (value: number | string): string => {
  let strValue = typeof value === 'number' ? value.toString() : value;

  // strip leading zeroes
  strValue = strValue.length > 1 ? strValue.replace(/^0+/, '') : strValue;

  // keep max. 2 decimal places
  const parts = strValue.split(/\./);
  if (parts.length > 1 && parts[1].length > 2) {
    const placesToCut = parts[1].length - 2;
    strValue = strValue.slice(0, -placesToCut);
  }

  return strValue;
};

export const cleanUpCapitalAlphanum = (value: string): string => {
  return value.toUpperCase().replaceAll(/[^A-Z0-9]/g, '');
};
