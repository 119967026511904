import cx from 'classnames';
import React, { FC } from 'react';
import { Card } from 'reactstrap';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import { ShipmentEntity, ShopEntity } from '../../../../schemas';
import OrderFulfillment from '../OrderFulfillment/OrderFulfillment';
import styles from './OrderFulfillments.module.scss';

interface Props {
  fulfillments: ShipmentEntity[];
  shop: ShopEntity | null;
}

const OrderFulfillments: FC<Props> = ({ fulfillments, shop }: Props) => {
  if (fulfillments.length === 0) {
    return null;
  }

  return (
    <Card className={cx('h-lg-100 mb-3', styles.OrderFulfillments)}>
      <FalconCardHeader border={false} className="bg-white" title="Fulfillments" />
      {fulfillments.map((fulfillment) => {
        return <OrderFulfillment key={fulfillment.id} fulfillment={fulfillment} shop={shop} />;
      })}
    </Card>
  );
};

export default OrderFulfillments;
