import { useDispatch, useSelector } from 'react-redux';
import { setLoginEmail, setLoginPassword, setLoginPasswordConfirm } from '../actions';
import { getVendorRegistrationLoginState } from '../selectors';
import { VendorRegistrationLogin } from '../types';

type VendorRegistrationLoginData = {
  login: VendorRegistrationLogin;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setPasswordConfirm: (passwordConfirm: string) => void;
};

const useVendorRegistrationLoginData = (): VendorRegistrationLoginData => {
  const dispatch = useDispatch();
  const login = useSelector(getVendorRegistrationLoginState);

  return {
    login,
    setEmail: (email: string) => dispatch(setLoginEmail(email)),
    setPassword: (password: string) => dispatch(setLoginPassword(password)),
    setPasswordConfirm: (passwordConfirm: string) =>
      dispatch(setLoginPasswordConfirm(passwordConfirm)),
  };
};

export default useVendorRegistrationLoginData;
