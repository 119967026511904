import cx from 'classnames';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SideMenuSubItem.module.scss';

type Props = {
  subItem: { title: string; link: string };
  pathname: string;
};

const SideMenuSubItem: FC<Props> = ({ subItem, pathname }: Props) => {
  const createNavLinkClass = (isActive: boolean) =>
    cx(
      'text-capitalize py-1 px-2 text-decoration-none d-flex justify-content-between align-items-center',
      styles.navLink,
      {
        'text-secondary': !isActive,
        'text-primary': isActive,
      }
    );
  return (
    <NavLink to={subItem.link} className={createNavLinkClass(subItem.link === pathname)}>
      <span>{subItem.title}</span>
    </NavLink>
  );
};

export default SideMenuSubItem;
