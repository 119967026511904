import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { PING_API_URL } from '../../global/urls';
import { apiGet } from '../../helpers/api';
import {
  alphaPingApiFailure,
  alphaPingApiRequest,
  alphaPingApiSuccess,
  AlphaPingNormalizedResponse,
} from './actions';
import { ALPHA_PAGE_INIT, ALPHA_PING_TRIGGER } from './constants';

function* initPageSaga() {
  yield* initIfNeededSaga();
}

function* pingSaga() {
  yield put(alphaPingApiRequest());

  try {
    const response = yield call(apiGet, PING_API_URL);
    const normalizedResponse: AlphaPingNormalizedResponse = normalize(response, {});
    yield put(alphaPingApiSuccess(normalizedResponse));
  } catch (error) {
    yield put(alphaPingApiFailure(error));
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(ALPHA_PAGE_INIT, initPageSaga), takeLatest(ALPHA_PING_TRIGGER, pingSaga)]);
}
