import { NormalizedError } from '../../types';
import {
  CustomersFetchApiFailureAction,
  CustomersFetchApiRequestAction,
  CustomersFetchApiSuccessAction,
  CustomersFetchNormalizedResponse,
  CustomersFetchTriggerAction,
} from './actionTypes';
import {
  CUSTOMERS_FETCH_API_FAILURE,
  CUSTOMERS_FETCH_API_REQUEST,
  CUSTOMERS_FETCH_API_SUCCESS,
  CUSTOMERS_FETCH_TRIGGER,
} from './constants';
import { CustomersSortOrder } from './types';

export const customersFetchTrigger = (
  sort: CustomersSortOrder,
  start: number,
  search: string
): CustomersFetchTriggerAction => ({
  type: CUSTOMERS_FETCH_TRIGGER,
  payload: {
    search,
    sort,
    start,
  },
});

export const customersFetchApiRequest = (
  sort: CustomersSortOrder,
  start: number,
  search: string
): CustomersFetchApiRequestAction => ({
  type: CUSTOMERS_FETCH_API_REQUEST,
  payload: { search, sort, start },
});

export const customersFetchApiSuccess = (
  sort: CustomersSortOrder,
  start: number,
  search: string,
  response: CustomersFetchNormalizedResponse
): CustomersFetchApiSuccessAction => ({
  type: CUSTOMERS_FETCH_API_SUCCESS,
  payload: { response, search, sort, start },
});

export const customersFetchApiFailure = (
  sort: CustomersSortOrder,
  start: number,
  search: string,
  error: NormalizedError
): CustomersFetchApiFailureAction => ({
  type: CUSTOMERS_FETCH_API_FAILURE,
  payload: { error, search, sort, start },
});
