import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  VENDORS_FETCH_API_FAILURE,
  VENDORS_FETCH_API_REQUEST,
  VENDORS_FETCH_API_SUCCESS,
  VENDORS_FETCH_TRIGGER,
} from '../constants';
import { VendorsSortOrder, VendorsType } from '../types';

// action types

interface VendorsFetchPayload {
  type: VendorsType;
  sort: VendorsSortOrder;
  start: number;
  search: string;
}

export interface VendorsFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    count: number;
    ids: number[];
    vendors: number[];
  };
}

export interface VendorsFetchTriggerAction {
  type: typeof VENDORS_FETCH_TRIGGER;
  payload: VendorsFetchPayload;
}

export interface VendorsFetchApiRequestAction extends APIRequestAction {
  type: typeof VENDORS_FETCH_API_REQUEST;
  payload: VendorsFetchPayload;
}

export interface VendorsFetchApiSuccessAction extends APISuccessAction {
  type: typeof VENDORS_FETCH_API_SUCCESS;
  payload: VendorsFetchPayload & {
    response: VendorsFetchNormalizedResponse;
  };
}

export interface VendorsFetchApiFailureAction extends APIFailureAction {
  type: typeof VENDORS_FETCH_API_FAILURE;
  payload: VendorsFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const vendorsFetchTrigger = (
  type: VendorsType,
  sort: VendorsSortOrder,
  start: number,
  search: string
): VendorsFetchTriggerAction => ({
  type: VENDORS_FETCH_TRIGGER,
  payload: {
    search,
    sort,
    start,
    type,
  },
});

export const vendorsFetchApiRequest = (
  type: VendorsType,
  sort: VendorsSortOrder,
  start: number,
  search: string
): VendorsFetchApiRequestAction => ({
  type: VENDORS_FETCH_API_REQUEST,
  payload: { search, sort, start, type },
});

export const vendorsFetchApiSuccess = (
  type: VendorsType,
  sort: VendorsSortOrder,
  start: number,
  search: string,
  response: VendorsFetchNormalizedResponse
): VendorsFetchApiSuccessAction => ({
  type: VENDORS_FETCH_API_SUCCESS,
  payload: { response, search, sort, start, type },
});

export const vendorsFetchApiFailure = (
  type: VendorsType,
  sort: VendorsSortOrder,
  start: number,
  search: string,
  error: NormalizedError
): VendorsFetchApiFailureAction => ({
  type: VENDORS_FETCH_API_FAILURE,
  payload: { error, search, sort, start, type },
});
