import { faCopy, faEdit, faPause, faPlay, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import useToggle from '../../../../components/ModalForm/useToggle';
import { getSessionUser } from '../../../../global/session/selectors';
import { VENDOR_REGISTRATION_URL } from '../../../../global/urls';
import toast from '../../../../global/utils/toast';
import { UserRole, VendorEntity } from '../../../../schemas';
import { VendorsType } from '../../types';
import DeactivateVendorModal from '../DeactivateVendorModal/DeactivateVendorModal';
import ReactivateVendorModal from '../ReactivateVendorModal/ReactivateVendorModal';
import VendorRemoveModal from '../VendorRemoveModal/VendorRemoveModal';
import VendorRenameModal from '../VendorRenameModal/VendorRenameModal';

type Props = {
  component: 'header' | 'item';
  selected: number[];
  vendor?: VendorEntity;
  vendorsType: VendorsType;
};

const getVendorInviteUrl = (vendor: VendorEntity): string => {
  return (
    window.location.origin +
    generatePath(VENDOR_REGISTRATION_URL, { invitationCode: vendor.inviteCode })
  );
};

const copyInviteLink = (vendor: VendorEntity | undefined) => {
  if (!vendor) return;
  navigator.clipboard.writeText(getVendorInviteUrl(vendor));
  toast.success('Invitation link has been copied to clipboard.');
};

const VendorsActionsDropdown: FC<Props> = ({ component, selected, vendor, vendorsType }: Props) => {
  const [vendorRemoveOpen, vendorRemoveToggle] = useToggle();
  const [vendorRenameOpen, vendorRenameToggle] = useToggle();
  const [vendorDeactivateOpen, vendorDeactivateToggle] = useToggle();
  const [vendorReactivateOpen, vendorReactivateToggle] = useToggle();

  const user = useSelector(getSessionUser);

  const dropDownItems = useMemo(() => {
    const items: DropDownItemDefinition[] = [];
    if (vendorsType === VendorsType.Invited) {
      items.push({
        text: 'Copy Invite Link',
        icon: faCopy,
        onClick: () => copyInviteLink(vendor),
      });
    }
    if (
      vendorsType === VendorsType.Active &&
      user &&
      (user.role === UserRole.Admin || user.role === UserRole.Brand) &&
      selected.length === 1
    ) {
      items.push({
        text: 'Rename',
        icon: faEdit,
        onClick: vendorRenameToggle,
      });
    }
    if (
      user &&
      (user.role === UserRole.Admin || user.role === UserRole.Brand) &&
      selected.length === 1
    ) {
      if (vendorsType === VendorsType.Active) {
        items.push({
          text: 'Deactivate',
          icon: faPause,
          onClick: vendorDeactivateToggle,
        });
      }
      if (vendorsType === VendorsType.Inactive) {
        items.push({
          text: 'Reactivate',
          icon: faPlay,
          onClick: vendorReactivateToggle,
        });
      }
    }
    if (vendorsType === VendorsType.Invited) {
      items.push({
        text: 'Remove',
        icon: faTrashAlt,
        onClick: vendorRemoveToggle,
      });
    }
    return items;
  }, [
    selected.length,
    user,
    vendor,
    vendorDeactivateToggle,
    vendorReactivateToggle,
    vendorRemoveToggle,
    vendorRenameToggle,
    vendorsType,
  ]);

  if (dropDownItems.length === 0) return null;

  const disabled = selected.length === 0;

  const vendorId = selected.length === 1 ? selected[0] : undefined;

  return (
    <>
      <DropdownMenu
        component={component}
        headerLabel={component === 'header' ? 'Bulk Actions' : undefined}
        items={dropDownItems}
        disabled={disabled}
      />
      {vendorId !== undefined && (
        <>
          <VendorRenameModal
            isOpen={vendorRenameOpen}
            toggle={vendorRenameToggle}
            vendorId={vendorId}
          />
          <VendorRemoveModal
            isOpen={vendorRemoveOpen}
            toggle={vendorRemoveToggle}
            vendorOrInviteId={vendorId}
            vendorsType={vendorsType}
          />
          <DeactivateVendorModal
            isOpen={vendorDeactivateOpen}
            toggle={vendorDeactivateToggle}
            vendorId={vendorId}
          />
          <ReactivateVendorModal
            isOpen={vendorReactivateOpen}
            toggle={vendorReactivateToggle}
            vendorId={vendorId}
          />
        </>
      )}
    </>
  );
};

export default VendorsActionsDropdown;
