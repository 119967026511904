import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GiftCardProductsSortOrder } from '../types';
import { replaceGiftCardsUrl } from '../utils';
import useGiftCardsQueryParams from './useGiftCardsQueryParams';

const useOnProductsSortChange = (): ((newSort: GiftCardProductsSortOrder) => void) => {
  const dispatch = useDispatch();
  const { codesSortOrder, productsSortOrder, search, start } = useGiftCardsQueryParams();

  return useCallback(
    (newSort: GiftCardProductsSortOrder) => {
      if (newSort !== productsSortOrder) {
        replaceGiftCardsUrl(dispatch, {
          search,
          sortCodes: codesSortOrder,
          sortProducts: newSort,
          start,
        });
      }
    },
    [codesSortOrder, dispatch, productsSortOrder, search, start]
  );
};

export default useOnProductsSortChange;
