import produce from 'immer';
import { denormalize } from 'normalizr';
import { Reducer } from 'redux';
import { VendorEntity, VendorSchema } from '../../../schemas';
import {
  VendorsFetchApiFailureAction,
  VendorsFetchApiRequestAction,
  VendorsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import { VendorsSubmitApiSuccessAction } from '../actions/submit.actions';
import { VENDORS_FETCH_API_SUCCESS, VENDORS_SUBMIT_API_SUCCESS } from '../constants';
import { DeleteVendorInviteSuccessAction, FormItem, FormItems, VendorsSearchState } from '../types';

export const initialSearchState: VendorsSearchState = {
  allIds: null,
  formItems: {},
};

const vendorToFormItem = (vendor: VendorEntity): FormItem => {
  return {
    commission: vendor.commission,
    enquiriesAllowed: vendor.enquiriesAllowed,
    maxProducts: vendor.maxProducts,
    moderation: vendor.moderation,
    tags: vendor.tags,
  };
};

export type VendorsSearchAction =
  | DeleteVendorInviteSuccessAction
  | VendorsFetchApiFailureAction
  | VendorsFetchApiRequestAction
  | VendorsFetchApiSuccessAction
  | VendorsSubmitApiSuccessAction;

export const vendorsSearchReducer: Reducer<VendorsSearchState, VendorsSearchAction> = (
  state: VendorsSearchState = initialSearchState,
  action: VendorsSearchAction
): VendorsSearchState =>
  produce(state, (draft) => {
    switch (action.type) {
      case VENDORS_FETCH_API_SUCCESS: {
        const { entities, result } = action.payload.response;
        const { ids } = result;
        draft.allIds = ids;

        const vendors: VendorEntity[] = result.vendors.map((itemId) =>
          denormalize(itemId, VendorSchema, entities)
        );

        draft.formItems = vendors.reduce((acc, vendor) => {
          // eslint-disable-next-line no-param-reassign
          acc[vendor.id] = vendorToFormItem(vendor);
          return acc;
        }, {} as FormItems);

        break;
      }

      case VENDORS_SUBMIT_API_SUCCESS: {
        const { entities, result } = action.payload.response;
        const vendor = denormalize(result.vendor, VendorSchema, entities);
        draft.formItems[vendor.id] = vendorToFormItem(vendor);
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
