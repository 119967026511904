export const TOGGLE_SIDE_MENU_COLLAPSED = 'APP_TOGGLE_SIDE_MENU_COLLAPSED';

// error message
export const ERROR_PAGE_MESSAGE =
  ' The link you have visited has expired. If you think this is a mistake, contact us.';

export const INPUT_ERROR_MESSAGE = {
  ORG_NAME: 'Allowed characters: alphanumeric, , . & - ( )',
  LETTERS_AND_NUMBERS: 'Only alphanumeric characters allowed.',
  REQUIRED: 'This is a required field.',
  EMAIL: 'Invalid email format',
  PASSWORD_MATCH: 'Passwords do not match',
  FORM_FIELDS: 'Please review & correct errors above.',
};
