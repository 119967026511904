import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { SHIPPING_POLICIES_URL } from '../../../global/urls';
import { makeQueryKey } from '../../../helpers/utils';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SelectShopApiSuccessAction } from '../../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import {
  ShippingPoliciesFetchApiFailureAction,
  ShippingPoliciesFetchApiRequestAction,
  ShippingPoliciesFetchApiSuccessAction,
  ShippingPoliciesFetchTriggerAction,
} from '../actions/fetch.actions';
import {
  SHIPPING_POLICIES_FETCH_API_FAILURE,
  SHIPPING_POLICIES_FETCH_API_REQUEST,
  SHIPPING_POLICIES_FETCH_API_SUCCESS,
} from '../constants';
import { ShippingPoliciesPageState } from '../types';
import { shippingPoliciesQueryReducer } from './shippingPoliciesQueryReducer';

const initialState: ShippingPoliciesPageState = {
  queries: {},
  selected: [],
};

export type ShippingPoliciesAction =
  | LocationChangeAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | ShippingPoliciesFetchApiFailureAction
  | ShippingPoliciesFetchApiRequestAction
  | ShippingPoliciesFetchApiSuccessAction
  | ShippingPoliciesFetchTriggerAction
  | ResetSessionAction
  | SelectShopApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const shippingPoliciesPageReducer: Reducer<
  ShippingPoliciesPageState,
  ShippingPoliciesAction
> = (state = initialState, action: ShippingPoliciesAction): ShippingPoliciesPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SHIPPING_POLICIES_FETCH_API_FAILURE:
      case SHIPPING_POLICIES_FETCH_API_REQUEST:
      case SHIPPING_POLICIES_FETCH_API_SUCCESS: {
        const { search, sort, start } = action.payload;
        const key = makeQueryKey(sort, start, search);
        draft.queries[key] = shippingPoliciesQueryReducer(state.queries[key], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: SHIPPING_POLICIES_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
