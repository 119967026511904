import React, { FC } from 'react';
import DropdownSelect, {
  DropdownSelectOption,
} from '../../../../components/DropdownSelect/DropdownSelect';
import { VendorsType } from '../../types';

const CHOICES: DropdownSelectOption<VendorsType>[] = [
  [VendorsType.Active, 'Active Vendors'],
  // [VendorsType.ShopNet, 'ShopNet Vendors'],
  [VendorsType.Invited, 'Invited Vendors'],
  [VendorsType.Inactive, 'Inactive Vendors'],
];

type Props = {
  className?: string;
  disabled?: boolean;
  onChange: (choice: VendorsType) => void;
  vendorsType: VendorsType;
};

const VendorsTypeDropdown: FC<Props> = ({
  className,
  disabled = false,
  onChange,
  vendorsType,
}: Props) => {
  return (
    <DropdownSelect
      options={CHOICES}
      selected={vendorsType}
      onChange={(type) => onChange(type as VendorsType)}
      className={className}
      disabled={disabled}
    />
  );
};

export default VendorsTypeDropdown;
