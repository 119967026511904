import produce from 'immer';
import { Reducer } from 'redux';
import {
  OrdersFetchApiFailureAction,
  OrdersFetchApiRequestAction,
  OrdersFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  ORDERS_FETCH_API_FAILURE,
  ORDERS_FETCH_API_REQUEST,
  ORDERS_FETCH_API_SUCCESS,
} from '../constants';
import { OrdersQueryState } from '../types';

export const initialQueryState: OrdersQueryState = {
  count: 0,
  error: null,
  loaded: false,
  orderIds: null,
};

export type OrdersQueryAction =
  | OrdersFetchApiFailureAction
  | OrdersFetchApiRequestAction
  | OrdersFetchApiSuccessAction;

export const ordersQueryReducer: Reducer<OrdersQueryState, OrdersQueryAction> = (
  state: OrdersQueryState = initialQueryState,
  action: OrdersQueryAction
): OrdersQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case ORDERS_FETCH_API_SUCCESS: {
        const { result } = action.payload.response;
        draft.count = result.count;
        draft.loaded = true;
        draft.orderIds = result.orders;
        break;
      }

      case ORDERS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
