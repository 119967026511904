import produce from 'immer';
import { Reducer } from 'redux';
import { CollectionsQueryActions } from '../actionTypes';
import {
  COLLECTIONS_FETCH_API_FAILURE,
  COLLECTIONS_FETCH_API_REQUEST,
  COLLECTIONS_FETCH_API_SUCCESS,
} from '../constants';
import { CollectionsQueryState } from '../types';

export const initialQueryState: CollectionsQueryState = {
  allIds: [],
  count: 0,
  error: null,
  loaded: false,
  collectionIds: null,
};

export const collectionsQueryReducer: Reducer<CollectionsQueryState, CollectionsQueryActions> = (
  state: CollectionsQueryState = initialQueryState,
  action: CollectionsQueryActions
): CollectionsQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case COLLECTIONS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case COLLECTIONS_FETCH_API_SUCCESS: {
        draft.allIds = action.payload.response.result.ids;
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.collectionIds = action.payload.response.result.collections;
        break;
      }

      case COLLECTIONS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
