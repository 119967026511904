import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/moderationListQueryReducer';
import {
  ModerationListPageState,
  ModerationListQueryState,
  ModerationListSortOrder,
} from './types';

export const getModerationListState = (state: AppState): ModerationListPageState =>
  state.containers.moderationList;

export const getModerationListQueryState = (
  state: AppState,
  sort: ModerationListSortOrder,
  start: number,
  search: string
): ModerationListQueryState => {
  return (
    getModerationListState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState
  );
};
