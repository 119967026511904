import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop } from '../../../global/session/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { CUSTOMERS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { CustomerEntity, CustomerSchema, ShopEntity } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { customersFetchTrigger } from '../actions';
import { CUSTOMERS_FETCH_API_PREFIX } from '../constants';
import { getCustomersQueryState } from '../selectors';
import { CustomersSortOrder } from '../types';
import useCustomersQueryParams from './useCustomersQueryParams';

type CustomersPageData = {
  correctLocation: boolean;
  count: number;
  customers: CustomerEntity[] | null;
  customersFetchTrigger(): void;
  dispatch: Dispatch;
  error: NormalizedError | null;
  isLoading: boolean;
  loaded: boolean;
  search: string;
  shop: ShopEntity | null;
  sortOrder: CustomersSortOrder;
  start: number;
};

const useCustomersPageData = (): CustomersPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { search, sortOrder, start } = useCustomersQueryParams();
  const correctLocation = location.pathname === CUSTOMERS_URL;
  const entities = useSelector(getEntitiesState);
  const shop = useSelector(getSessionActiveShop);
  const { count, error, loaded, customerIds } = useSelector((state: AppState) =>
    getCustomersQueryState(state, sortOrder, start, search)
  );

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, CUSTOMERS_FETCH_API_PREFIX)
  );

  const customers = useMemo(
    () =>
      customerIds === null
        ? customerIds
        : customerIds.map((customerId) => denormalize(customerId, CustomerSchema, entities)),
    [entities, customerIds]
  );

  return {
    customersFetchTrigger: () => dispatch(customersFetchTrigger(sortOrder, start, search)),
    count,
    correctLocation,
    customers,
    dispatch,
    error,
    isLoading,
    loaded,
    search,
    shop,
    sortOrder,
    start,
  };
};

export default useCustomersPageData;
