import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import { getSessionActiveShop, getSessionShops } from '../../../../global/session/selectors';
import { ShopEntity } from '../../../../schemas';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  selectedProducts: Set<number>;
  submit: ({ productIds, shopId }: { productIds: number[]; shopId: number }) => void;
};

type ShopsListProps = {
  selectedShop: number;
  setSelectedShop: React.Dispatch<React.SetStateAction<number>>;
  shops: ShopEntity[];
};

const ShopsList: FC<ShopsListProps> = ({
  selectedShop,
  setSelectedShop,
  shops,
}: ShopsListProps) => {
  const handleChange = (shopId: number) => {
    setSelectedShop(shopId);
  };

  return (
    <div className="mt-3">
      {shops.map((shop) => {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            key={shop.id}
            className="d-flex align-items-center gray-900 user-select-none mt-2"
            onClick={() => handleChange(shop.id)}
          >
            <CustomRadio
              checked={shop.id === selectedShop}
              name="shop"
              value={shop.id.toString()}
              onChange={() => handleChange(shop.id)}
            />
            <div className="ml-2">{shop.name}</div>
          </div>
        );
      })}
    </div>
  );
};

const CloneProductsModal: FC<Props> = ({ isOpen, toggle, selectedProducts, submit }: Props) => {
  const [selectedShop, setSelectedShop] = useState(0);
  const activeShop = useSelector(getSessionActiveShop);
  const shops = useSelector(getSessionShops);
  const numSelected = selectedProducts.size;

  const selectableShops = shops.filter((shop) => shop.id !== activeShop?.id);

  useEffect(() => {
    if (selectableShops.length === 1) {
      setSelectedShop(selectableShops[0].id);
    }
  }, [selectableShops]);

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={() =>
        submit({
          productIds: Array.from(selectedProducts),
          shopId: selectedShop,
        })
      }
      canSubmit={() => !!selectedShop}
      title="Clone Products"
      okButtonLabel="Clone"
      initialValues={{}}
    >
      Clone <b>{numSelected}</b> products to the selected store:
      <ShopsList
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
        shops={selectableShops}
      />
    </ModalForm>
  );
};

export default CloneProductsModal;
