import { SagaIterator } from '@redux-saga/core';
import { all, call, takeLatest } from 'redux-saga/effects';
import { reloadPageEffect } from '../../global/session/sagas';
import {
  COLLECTION_API_URL,
  COLLECTION_BROWSE_API_URL,
  COLLECTION_PRODUCTS_API_URL,
} from '../../global/urls';
import toast from '../../global/utils/toast';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { CollectionSchema, MicroProductSchema, SessionSchema } from '../../schemas';
import collectionSubmitSlice, {
  CollectionBrowseFetchPayload,
  CollectionFetchPayload,
  CollectionProductsFetchPayload,
  fetchCollectionBrowseSlice,
  fetchCollectionProductsSlice,
  fetchCollectionSlice,
} from './slices';

const fetchCollectionSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: COLLECTION_API_URL,
  urlParams: ({ collectionId }: CollectionFetchPayload) => ({ collectionId }),
  responseSchema: {
    collection: CollectionSchema,
    session: SessionSchema,
  },
  actions: fetchCollectionSlice.actions,
});

const fetchCollectionBrowseSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: COLLECTION_BROWSE_API_URL,
  urlParams: ({ collectionId }: CollectionBrowseFetchPayload) => ({ collectionId }),
  responseSchema: {
    products: [MicroProductSchema],
    session: SessionSchema,
  },
  actions: fetchCollectionBrowseSlice.actions,
});

const fetchCollectionProductsSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: COLLECTION_PRODUCTS_API_URL,
  urlParams: ({ collectionId }: CollectionProductsFetchPayload) => ({ collectionId }),
  responseSchema: {
    session: SessionSchema,
  },
  actions: fetchCollectionProductsSlice.actions,
});

const submitCollectionSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: COLLECTION_API_URL,
  queryPayload: (payload) => ({
    handle: payload.values.handle,
    managedBy: payload.values.managedBy,
    productIds: payload.values.productIds,
    productsOrder: payload.values.productsOrder,
    title: payload.values.title,
  }),
  urlParams: (payload) => ({
    collectionId: payload.collectionId,
  }),
  responseSchema: {
    collection: CollectionSchema,
    session: SessionSchema,
  },
  actions: collectionSubmitSlice.actions,
});

function* submitCollectionSuccessSaga(
  action: ReturnType<typeof collectionSubmitSlice.actions.success>
) {
  yield call(toast.success, `Collection updated.`);
  if (!action.payload.request.collectionId) yield reloadPageEffect();
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(collectionSubmitSlice.actions.success.type, submitCollectionSuccessSaga),
    takeLatest(collectionSubmitSlice.actions.trigger.type, submitCollectionSaga),
    takeLatest(fetchCollectionBrowseSlice.actions.trigger, fetchCollectionBrowseSaga),
    takeLatest(fetchCollectionProductsSlice.actions.trigger, fetchCollectionProductsSaga),
    takeLatest(fetchCollectionSlice.actions.trigger, fetchCollectionSaga),
  ]);
}
