import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { GiftCardCodesSortOrder, GiftCardProductsSortOrder } from './types';

export const GIFT_CARDS_FETCH_TRIGGER = 'GIFT_CARDS_FETCH_TRIGGER';
export const GIFT_CARDS_FETCH_API_PREFIX = 'GIFT_CARDS_FETCH_API';
export const GIFT_CARDS_FETCH_API_REQUEST = 'GIFT_CARDS_FETCH_API_REQUEST';
export const GIFT_CARDS_FETCH_API_SUCCESS = 'GIFT_CARDS_FETCH_API_SUCCESS';
export const GIFT_CARDS_FETCH_API_FAILURE = 'GIFT_CARDS_FETCH_API_FAILURE';

export const defaultCodesSortOrder: GiftCardCodesSortOrder = GiftCardCodesSortOrder.createdAtDesc;
export const defaultProductsSortOrder: GiftCardProductsSortOrder = GiftCardProductsSortOrder.sku;

export const PAGE_SIZE = 15;
export const PAGINATION_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
