import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { Button, Col, Row } from 'reactstrap';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import { INPUT_ERROR_MESSAGE } from '../../../../global/general/constants';
import { VendorRegistrationScreen } from '../../types';
import styles from './VendorSignUpFooter.module.scss';

type Props = {
  goBack: (() => void) | null;
  goNext: (() => void) | null;
  screen: VendorRegistrationScreen;
  showError: boolean;
};

const VendorSignUpFooter: FC<Props> = ({ screen, goBack, goNext, showError }: Props) => (
  <Row className={cx(styles.wrapper, 'd-flex align-items-center')} noGutters>
    {goBack && (
      <Col lg={{ size: 2, offset: 1 }}>
        <button className={cx(styles.buttonBack)} onClick={() => goBack()} type="button">
          <FontAwesomeIcon
            icon={faAngleLeft}
            className={cx(styles.buttonIcon, styles.buttonIconBack)}
          />
          <span className={cx(styles.buttonText, styles.buttonTextBack)}>Back</span>
        </button>
      </Col>
    )}
    <Col lg={{ size: 5, offset: !goBack ? 4 : 1 }} className={cx({ invisible: !showError })}>
      <FormInputError>{INPUT_ERROR_MESSAGE.FORM_FIELDS}</FormInputError>
    </Col>
    <Col lg={{ size: 2 }}>
      <Button className="sss-primary w-100" onClick={() => goNext && goNext()}>
        <span className="d-flex justify-content-center align-items-center">
          <span className={cx(styles.buttonText)}>
            {screen === VendorRegistrationScreen.LOGIN_DETAILS ? 'Save' : 'Next'}
          </span>
          {screen !== VendorRegistrationScreen.LOGIN_DETAILS && (
            <FontAwesomeIcon
              icon={faAngleRight}
              className={cx(styles.buttonIcon, styles.buttonIconNext)}
            />
          )}
        </span>
      </Button>
    </Col>
  </Row>
);

export default VendorSignUpFooter;
