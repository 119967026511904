import { push, replace } from 'connected-react-router';
import { stringifyUrl } from 'query-string';
import { Dispatch } from 'redux';
import { StandardQueryParams } from '../../global/types';
import { COLLECTIONS_URL } from '../../global/urls';
import { defaultSortOrder } from './constants';

type QueryParams = StandardQueryParams;

export const getCollectionsUrl = (params: QueryParams): string => {
  const query: QueryParams = {};
  if (params.search) {
    query.search = params.search;
  }
  if (params.sort && params.sort !== defaultSortOrder) {
    query.sort = params.sort;
  }
  if (params.start) {
    query.start = params.start;
  }
  return stringifyUrl({ url: COLLECTIONS_URL, query });
};

export const replaceCollectionsUrl = (dispatch: Dispatch, params: QueryParams): void => {
  const url = getCollectionsUrl(params);
  dispatch(replace(url, { keepState: true }));
};

export const pushCollectionsUrl = (
  dispatch: Dispatch,
  params: QueryParams,
  keepState = false
): void => {
  const url = getCollectionsUrl(params);
  dispatch(push(url, { keepState }));
};
