// fetch
export const LOGIN_AS_FETCH_TRIGGER = 'LOGIN_AS_FETCH_TRIGGER';
export const LOGIN_AS_FETCH_API_PREFIX = 'LOGIN_AS_FETCH_API';
export const LOGIN_AS_FETCH_API_REQUEST = 'LOGIN_AS_FETCH_API_REQUEST';
export const LOGIN_AS_FETCH_API_SUCCESS = 'LOGIN_AS_FETCH_API_SUCCESS';
export const LOGIN_AS_FETCH_API_FAILURE = 'LOGIN_AS_FETCH_API_FAILURE';

// select
export const LOGIN_AS_TRIGGER = 'LOGIN_AS_TRIGGER';
export const LOGIN_AS_API_PREFIX = 'LOGIN_AS_API';
export const LOGIN_AS_API_REQUEST = 'LOGIN_AS_API_REQUEST';
export const LOGIN_AS_API_SUCCESS = 'LOGIN_AS_API_SUCCESS';
export const LOGIN_AS_API_FAILURE = 'LOGIN_AS_API_FAILURE';
