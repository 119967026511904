import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import styles from 'global/styles/falconTable.module.scss';
import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { ShippingType } from '../../../../schemas';
import ShippingTypeActionsDropdown from '../ShippingTypeActionsDropdown/ShippingTypeActionsDropdown';

type Props = {
  shippingTypes: ShippingType[] | null;
};

type RowProps = {
  shippingType: ShippingType;
};

const DragHandle = SortableHandle(() => <FontAwesomeIcon icon={faGripLines} className="mr-3" />);

const ShippingTypeRow = SortableElement<RowProps>(({ shippingType }: RowProps) => (
  <ListGroupItem>
    <Row>
      <Col className={cx(styles.table, styles.row, styles.textDark)}>
        <DragHandle />
        {shippingType.name}
      </Col>
      <Col xs={1}>
        <ShippingTypeActionsDropdown shippingType={shippingType} />
      </Col>
    </Row>
  </ListGroupItem>
));

const ShippingTypesTable = SortableContainer<Props>(({ shippingTypes }: Props) => {
  const data: ShippingType[] = shippingTypes || [];
  return (
    <ListGroup flush>
      {data.map((shippingType, index) => (
        <ShippingTypeRow index={index} key={shippingType.id} shippingType={shippingType} />
      ))}
    </ListGroup>
  );
});

export default ShippingTypesTable;
