import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { ShopEntity } from '../../schemas';
import styles from './ShopItem.module.scss';

export enum ShopItemCaret {
  None = 'none',
  Down = 'down',
  Up = 'up',
}

type Props = {
  caret?: ShopItemCaret;
  className?: string;
  shop: ShopEntity | null;
};

const ShopItem: FC<Props> = ({ caret = ShopItemCaret.None, className, shop }: Props) => {
  const src = shop && shop.logoUrl ? process.env.REACT_APP_DJANGO_BASE_URL + shop.logoUrl : '';
  return (
    <div className={cx(styles.ShopItem, className)}>
      <div className={styles.ShopItem__logo}>
        {src && (
          <img
            alt="Logo"
            className={styles.ShopItem__logo__img}
            src={src}
            style={{ height: '40px' }}
          />
        )}
      </div>
      <div className={styles.ShopItem__name}>{shop && shop.name}</div>
      {caret !== ShopItemCaret.None && (
        <FontAwesomeIcon
          className={styles.ShopItem__caret}
          icon={caret === ShopItemCaret.Down ? faAngleDown : faAngleUp}
        />
      )}
    </div>
  );
};

export default ShopItem;
