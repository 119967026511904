import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import styles from './PhotoItem.module.scss';

export enum PhotoItemType {
  Big = 'big',
  Small = 'small',
}

export type PhotoItemProps = {
  index: number;
  onClick:
    | null
    | ((
        e: React.MouseEvent<HTMLImageElement, MouseEvent>,
        item: {
          photo: {
            src: string;
            width?: number;
            height?: number;
            key?: string;
          };
          index: number;
        }
      ) => void);
  photo: {
    src: string;
    width?: number;
    height?: number;
    key?: string;
  };
  margin?: string;
  direction?: string;
  top?: number;
  left?: number;
  removeImage?: (id: string) => void;
  type: PhotoItemType;
  className?: string;
};

const PhotoItem: FC<PhotoItemProps> = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
  removeImage,
  type,
  className,
}: PhotoItemProps) => {
  const imgStyle: {
    margin?: string;
    position?: 'absolute';
    top?: number;
    left?: number;
  } = { margin };
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = (event: never) => {
    if (onClick) {
      onClick(event, { photo, index });
    }
  };

  return (
    <div className={cx(styles.photoItem, className)}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img
        {...photo}
        onClick={onClick ? handleClick : () => null}
        className={cx(styles.photoItemImg, {
          [styles.photoItemImgBig]: type === PhotoItemType.Big,
          [styles.photoItemImgSmall]: type === PhotoItemType.Small,
        })}
        alt="img"
      />
      {removeImage && (
        <button
          type="button"
          className={styles.xButton}
          onClick={() => {
            if (photo.key) {
              removeImage(photo.key);
            }
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      )}
    </div>
  );
};

export default PhotoItem;
