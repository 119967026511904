/* eslint-disable  @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomInput, Input, Label } from 'reactstrap';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { ShippingPolicyEntity } from '../../../../schemas';
import { shippingPoliciesSubmitTrigger } from '../../actions/submit.actions';
import { isSubmittingShippingPolicies } from '../../selectors';
import createShippingPolicySlice from '../../slices.tx/shippingPolicySlice';
import { FormItem, FormValues } from '../../types';
import styles from './formatters.module.scss';

const actionFieldName = (order: number) => `items.${order}.action`;
const applyToProductsFieldName = (order: number) => `items.${order}.applyToProducts`;
const additionalFeeFieldName = (order: number) => `items.${order}.additionalFee`;
const availabilityFieldName = (order: number) => `items.${order}.availability`;
const baseFeeFieldName = (order: number) => `items.${order}.baseFee`;
const freeFieldName = (order: number) => `items.${order}.free`;
const shippingFreeAboveFieldName = (order: number) => `items.${order}.shippingFreeAbove`;
const shippingMaxCapFieldName = (order: number) => `items.${order}.shippingMaxCap`;

export const shippingTypeFormatter = (name: string): ReactElement => {
  if (name && name.startsWith('__')) return <></>;
  return <div className={styles.name}>{name}</div>;
};

type RowFieldProps = FieldAttributes<any> & { row: ShippingPolicyEntity };

export const AvailabilityField = (props: RowFieldProps): ReactElement | null => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row } = props;
  const rowValues = values.items[row.order];
  if (!rowValues) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    const newAvailability = event.target.checked;
    let newFree = rowValues.free;
    const name = freeFieldName(row.order);
    if (!newAvailability) {
      setFieldValue(name, false);
      newFree = false;
    }
    const shippingPolicyId = row.id;
    if (row.id) {
      dispatch(
        shippingPoliciesSubmitTrigger(shippingPolicyId, {
          availability: newAvailability,
          free: newFree,
        })
      );
    } else {
      dispatch(createShippingPolicySlice.actions.trigger({ name: row.name }));
    }
  };

  return (
    <CustomInput
      {...field}
      className={cx('custom-sss-switch')}
      checked={field.value}
      id={field.name}
      onChange={handleChange}
      type="switch"
    />
  );
};

export const FreeField = (props: RowFieldProps): ReactElement | null => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row } = props;
  const rowValues = values.items[row.order];

  if (!rowValues) {
    return null;
  }

  const { availability } = rowValues;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    const newFree = event.target.checked;
    const shippingPolicyId = row.id;
    dispatch(shippingPoliciesSubmitTrigger(shippingPolicyId, { free: newFree }));
  };

  return (
    <CustomInput
      {...field}
      className={cx('custom-sss-switch')}
      checked={field.value}
      disabled={!availability}
      id={field.name}
      onChange={handleChange}
      type="switch"
    />
  );
};

type FeeFieldProps = RowFieldProps & { isBaseFee?: boolean };

const FeeField = (props: FeeFieldProps) => {
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { isBaseFee, row } = props;
  const rowValues = values.items[row.order];
  if (!rowValues) {
    return null;
  }
  const disabled = !rowValues.availability || rowValues.free;
  const value = field.value || '';
  let isRed = false;

  if (isBaseFee) {
    isRed = !disabled && !value;
  }

  return (
    <>
      <Input
        {...field}
        className={cx(styles.fee, { [styles.fee__red]: isRed })}
        disabled={disabled}
        min={0}
        step={0.5}
        type="number"
        value={!disabled ? value : ''}
      />
      {isRed && <div className={styles.fee__error}>Required</div>}
    </>
  );
};

const shouldShowSave = (row: ShippingPolicyEntity, rowValues: FormItem): boolean => {
  const oldBaseFee = row.baseFee;
  const oldAdditionalFee = row.additionalFee;
  const oldShippingFreeAbove = row.shippingFreeAboveOrderValue;
  const oldShippingMaxCap = row.shippingMaxCap;
  const newBaseFee = rowValues.baseFee;
  const newAdditionalFee = rowValues.additionalFee;
  const newShippingFreeAbove = rowValues.shippingFreeAbove;
  const newShippingMaxCap = rowValues.shippingMaxCap;

  const areSame =
    oldBaseFee === newBaseFee &&
    oldAdditionalFee === newAdditionalFee &&
    oldShippingFreeAbove === newShippingFreeAbove &&
    oldShippingMaxCap === newShippingMaxCap;

  return !areSame;
};

const ApplyToProductsField = (props: RowFieldProps) => {
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row } = props;

  const rowValues = values.items[row.order];
  if (!rowValues) {
    return null;
  }

  if (!shouldShowSave(row, rowValues)) {
    return null;
  }

  return (
    <Label check className="align-items-center d-flex user-select-none">
      <CustomCheckbox {...field} checked={!!field.value} />
      <div className="ml-2 mr-3">Apply to all products</div>
    </Label>
  );
};

const ActionField = (props: RowFieldProps) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(isSubmittingShippingPolicies);
  const { values } = useFormikContext<FormValues>();
  const { row } = props;

  const shippingPolicyId = row.id;
  const rowValues = values.items[row.order];
  if (!rowValues) {
    return null;
  }

  const rowValuesNormalized = {
    ...rowValues,
    shippingFreeAbove: rowValues.shippingFreeAbove === '' ? null : rowValues.shippingFreeAbove,
    shippingMaxCap: rowValues.shippingMaxCap === '' ? null : rowValues.shippingMaxCap,
  };

  if (!shouldShowSave(row, rowValuesNormalized)) {
    return null;
  }

  return (
    <SecondaryButton
      disabled={isSubmitting}
      onClick={() => dispatch(shippingPoliciesSubmitTrigger(shippingPolicyId, rowValuesNormalized))}
      size="small"
    >
      Save
    </SecondaryButton>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const availabilityFormatter = (_: any, row: ShippingPolicyEntity): ReactElement => (
  <AvailabilityField name={availabilityFieldName(row.order)} row={row} />
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const freeFormatter = (_: any, row: ShippingPolicyEntity): ReactElement => (
  <FreeField name={freeFieldName(row.order)} row={row} />
);

export const baseFeeFormatter = (amount: number, row: ShippingPolicyEntity): ReactElement => {
  if (amount === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      <FeeField isBaseFee name={baseFeeFieldName(row.order)} row={row} />
    </div>
  );
};

export const additionalFeeFormatter = (amount: number, row: ShippingPolicyEntity): ReactElement => {
  if (amount === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      <FeeField name={additionalFeeFieldName(row.order)} row={row} />
    </div>
  );
};

export const shippingFreeAboveFormatter = (
  amount: number,
  row: ShippingPolicyEntity
): ReactElement => {
  if (amount === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      <FeeField name={shippingFreeAboveFieldName(row.order)} row={row} />
    </div>
  );
};

export const shippingMaxCapFormatter = (
  amount: number,
  row: ShippingPolicyEntity
): ReactElement => {
  if (amount === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      <FeeField name={shippingMaxCapFieldName(row.order)} row={row} />
    </div>
  );
};

export const actionsFormatter = (_amount: number, row: ShippingPolicyEntity): ReactElement => {
  return (
    <div className="align-items-center d-flex flex-row">
      <ApplyToProductsField name={applyToProductsFieldName(row.order)} row={row} />
      <ActionField name={actionFieldName(row.order)} row={row} />
    </div>
  );
};
