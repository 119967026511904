import cx from 'classnames';
import { FormikProps } from 'formik';
import React, { FC, useCallback } from 'react';
import * as Yup from 'yup';
import stockLevelsImg from '../../../../assets/img/import_stock_levels.png';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import tapStyles from '../../../../components/SortableGallery/components/TapToUpload/TapToUpload.module.scss';
import { ImportStockRequestPayload } from '../../slices';

interface FileInputProps {
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileInput: FC<FileInputProps> = ({ onChange }: FileInputProps) => {
  return (
    <input
      type="file"
      onChange={onChange}
      accept=".csv"
      className="d-none"
      onClick={(e) => {
        const target = e.target as HTMLInputElement;
        // eslint-disable-next-line no-param-reassign
        target.value = '';
      }}
    />
  );
};

type FormValues = {
  csvFileData: string;
  csvFileName: string;
};

type Props = {
  isOpen: boolean;
  onSubmit: (payload: ImportStockRequestPayload) => void;
  toggle: () => void;
};

const ValidationSchema = Yup.object().shape({
  csvFileData: Yup.string().required(),
});

const ImportStockModal: FC<Props> = ({ isOpen, onSubmit, toggle }: Props) => {
  const initialValues: FormValues = {
    csvFileData: '',
    csvFileName: '',
  };

  const handleSubmit = (values: FormValues) => {
    onSubmit({
      csvFileName: values.csvFileName,
      csvFileData: values.csvFileData,
    });
  };

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, fprops: FormikProps<FormValues>) => {
      e.preventDefault();
      const { setFieldValue } = fprops;
      let inputFiles: File[] = [];
      if ('files' in e.target && e.target.files !== null) {
        inputFiles = Array.from(e.target.files);
      }
      inputFiles.forEach((inputFile) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = typeof reader.result === 'string' ? reader.result : '';
          setFieldValue('csvFileName', inputFile.name);
          setFieldValue('csvFileData', data);
        };
        reader.readAsDataURL(inputFile);
      });
    },
    []
  );

  return (
    <ModalForm
      canSubmit={(fprops) => !!fprops.values.csvFileData}
      initialValues={initialValues}
      isOpen={isOpen}
      okButtonLabel="Upload"
      onSubmit={handleSubmit}
      title="Import Stock Levels"
      toggle={toggle}
      validationSchema={ValidationSchema}
    >
      {(fprops) => (
        <>
          <img
            alt="example"
            className="d-block mx-auto my-2"
            src={stockLevelsImg}
            style={{ height: '80px' }}
          />
          <div className="my-4">
            The CSV has to contain 2 columns labelled{' '}
            <b>&quot;SKU&quot; and &quot;Quantity&quot;</b> in the first row
          </div>

          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={cx(tapStyles.tapToUpload, tapStyles.tapToUploadBig)}>
            <FileInput onChange={(e) => handleFileChange(e, fprops)} />
            <div className={tapStyles.content}>
              {fprops.values.csvFileData ? (
                <div>
                  <div>{fprops.values.csvFileName}</div>
                  <div>Ready for upload</div>
                </div>
              ) : (
                'Click to upload CSV file'
              )}
            </div>
          </label>
        </>
      )}
    </ModalForm>
  );
};

export default ImportStockModal;
