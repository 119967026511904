import cx from 'classnames';
import React, { CSSProperties, FC, useMemo } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import styles from '../../../../global/styles/falconTable.module.scss';
import { SortOrderDirection } from '../../../../global/types';
import { GiftCardProductEntity, ShopEntity } from '../../../../schemas';
import { mapSortProductsOrder, mapTableSortProducts } from '../../helpers';
import useOnProductsSortChange from '../../hooks/useOnProductsSortChange';
import { GiftCardProductsColumn, GiftCardProductsSortOrder } from '../../types';
import columns from './columns';

type Props = {
  giftCardProducts: GiftCardProductEntity[] | null;
  pageSize: number;
  shop: ShopEntity | null;
  sortOrder: GiftCardProductsSortOrder;
};

const GiftCardProductsTable: FC<Props> = ({
  giftCardProducts,
  pageSize,
  shop,
  sortOrder,
}: Props) => {
  const onSortChange = useOnProductsSortChange();

  const rowStyleFormat = (row: GiftCardProductEntity, rowIdx: number): CSSProperties => {
    return { backgroundColor: rowIdx % 2 === 0 ? '' : '#f9fafd' };
  };

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<GiftCardProductEntity>
  ): void => {
    onSortChange(
      mapSortProductsOrder(
        newState.sortField as GiftCardProductsColumn,
        newState.sortOrder as SortOrderDirection
      )
    );
  };

  const bootstrapTableSort = useMemo(() => mapTableSortProducts(sortOrder), [sortOrder]);

  const data = useMemo(
    () =>
      giftCardProducts !== null
        ? giftCardProducts
        : Array.from({ length: pageSize }, (_, id) => ({ id })),
    [giftCardProducts, pageSize]
  );

  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      classes={cx(styles.table, 'table align-middle')}
      columns={columns(shop)}
      data={data}
      headerClasses={cx(styles.header, 'fs-1')}
      headerWrapperClasses={cx(styles.headerWrapper)}
      keyField="id"
      rowClasses={cx(styles.row, 'py-2 align-middle')}
      rowStyle={(row, idx) => rowStyleFormat(row, idx)}
      sort={bootstrapTableSort}
      onTableChange={handleTableChange}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
    />
  );
};

export default GiftCardProductsTable;
