import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { VENDORS_URL } from '../../../global/urls';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SelectShopApiSuccessAction } from '../../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import {
  VENDORS_ADD_SELECTED_VENDORS,
  VENDORS_FETCH_API_FAILURE,
  VENDORS_FETCH_API_REQUEST,
  VENDORS_FETCH_API_SUCCESS,
  VENDORS_REMOVE_SELECTED_VENDORS,
  VENDORS_SELECT_ALL,
  VENDORS_SUBMIT_API_SUCCESS,
  VENDORS_TOGGLE_SELECTED_VENDOR,
} from '../constants';
import {
  VENDOR_INVITE_DELETE_SUCCESS,
  VendorsPageState,
  VendorsType,
  VendorsTypeState,
} from '../types';
import { VendorsSearchAction } from './vendorsSearchReducer';
import { VendorsTypeAction, vendorsTypeReducer } from './vendorsTypeReducer';

export const initialTypeState: VendorsTypeState = {
  queries: {},
  searches: {},
  selected: [],
};

const initialState: VendorsPageState = {
  types: {},
};

type VendorsAction =
  | LocationChangeAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | ResetSessionAction
  | SelectShopApiSuccessAction
  | VendorsSearchAction
  | VendorsTypeAction;

// eslint-disable-next-line import/prefer-default-export
export const vendorsPageReducer: Reducer<VendorsPageState, VendorsAction> = (
  state = initialState,
  action: VendorsAction
): VendorsPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case VENDORS_ADD_SELECTED_VENDORS:
      case VENDORS_FETCH_API_FAILURE:
      case VENDORS_FETCH_API_REQUEST:
      case VENDORS_FETCH_API_SUCCESS:
      case VENDORS_REMOVE_SELECTED_VENDORS:
      case VENDORS_SELECT_ALL:
      case VENDORS_TOGGLE_SELECTED_VENDOR: {
        const { type } = action.payload;
        draft.types[type] = vendorsTypeReducer(state.types[type], action);
        break;
      }

      case VENDORS_SUBMIT_API_SUCCESS: {
        Object.keys(draft.types).forEach((key) => {
          draft.types[key] = vendorsTypeReducer(state.types[key], action);
        });
        break;
      }

      case VENDOR_INVITE_DELETE_SUCCESS: {
        draft.types[VendorsType.Invited] = vendorsTypeReducer(
          state.types[VendorsType.Invited],
          action
        );
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: VENDORS_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
