import { replace } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRequireAuth from '../../global/hooks/useRequireAuth';
import { getSessionUser } from '../../global/session/selectors';
import { DASHBOARD_URL } from '../../global/urls';
import { isAuthenticated } from '../../helpers/utils';
import { UserRole } from '../../schemas';
import BrandProfile from '../BrandProfile/BrandProfile';
import VendorProfile from '../VendorProfile/VendorProfile';

const Profile: FC = () => {
  useRequireAuth();

  const dispatch = useDispatch();
  const user = useSelector(getSessionUser);

  // redirect to dashboard if not brand or vendor user
  useEffect(() => {
    if (
      isAuthenticated(user) &&
      !(user?.role === UserRole.Brand || user?.role === UserRole.Vendor)
    ) {
      dispatch(replace(DASHBOARD_URL));
    }
  }, [dispatch, user]);

  if (user?.role === UserRole.Brand) {
    return <BrandProfile />;
  }

  if (user?.role === UserRole.Vendor) {
    return <VendorProfile />;
  }

  return null;
};

export default Profile;
