import { Action } from 'redux';
import { ProductEnquiry } from '../../../schemas';
import {
  PRODUCT_SET_PROPERTIES_AGE_RESTRICTED,
  PRODUCT_SET_PROPERTIES_CUSTOMIZABLE,
  PRODUCT_SET_PROPERTIES_ENQUIRY,
} from '../constants';

// action types

export interface ProductSetPropertiesAgeRestrictedAction extends Action {
  type: typeof PRODUCT_SET_PROPERTIES_AGE_RESTRICTED;
  payload: {
    productId: number;
  };
}

export interface ProductSetPropertiesCustomizableAction extends Action {
  type: typeof PRODUCT_SET_PROPERTIES_CUSTOMIZABLE;
  payload: {
    productId: number;
  };
}

export interface ProductSetPropertiesEnquiryAction extends Action {
  type: typeof PRODUCT_SET_PROPERTIES_ENQUIRY;
  payload: {
    productId: number;
    value: ProductEnquiry;
  };
}

// action creators

export const productSetPropertiesAgeRestricted = (
  productId: number
): ProductSetPropertiesAgeRestrictedAction => ({
  type: PRODUCT_SET_PROPERTIES_AGE_RESTRICTED,
  payload: { productId },
});

export const productSetPropertiesCustomizable = (
  productId: number
): ProductSetPropertiesCustomizableAction => ({
  type: PRODUCT_SET_PROPERTIES_CUSTOMIZABLE,
  payload: { productId },
});

export const productSetPropertiesEnquiry = (
  productId: number,
  value: ProductEnquiry
): ProductSetPropertiesEnquiryAction => ({
  type: PRODUCT_SET_PROPERTIES_ENQUIRY,
  payload: { productId, value },
});
