import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Button, ButtonProps } from 'reactstrap';

export interface ButtonIconProps extends ButtonProps {
  icon: IconProp;
  iconAlign?: 'left' | 'right';
  iconClassName?: string;
  spin?: boolean;
  transform?: string;
}

const ButtonIcon: FC<ButtonIconProps> = ({
  icon,
  iconAlign = 'left',
  iconClassName,
  spin,
  transform,
  children,
  ...rest
}: ButtonIconProps) => (
  <Button {...rest}>
    {iconAlign === 'right' && children}
    <FontAwesomeIcon
      icon={icon}
      className={classNames(iconClassName, {
        'mr-1': children && iconAlign === 'left',
        'ml-1': children && iconAlign === 'right',
      })}
      spin={spin}
      transform={transform}
    />
    {iconAlign === 'left' && children}
  </Button>
);

export default ButtonIcon;
