import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  PASSWORD_RESET_VERIFY_TOKEN_API_FAILURE,
  PASSWORD_RESET_VERIFY_TOKEN_API_REQUEST,
  PASSWORD_RESET_VERIFY_TOKEN_API_SUCCESS,
  PASSWORD_RESET_VERIFY_TOKEN_TRIGGER,
} from '../constants';

// action types

export interface PasswordResetVerifyTokenTriggerAction {
  type: typeof PASSWORD_RESET_VERIFY_TOKEN_TRIGGER;
  payload: {
    uid: string;
    token: string;
  };
}

export interface PasswordResetVerifyTokenApiRequestAction extends APIRequestAction {
  type: typeof PASSWORD_RESET_VERIFY_TOKEN_API_REQUEST;
}

export interface PasswordResetVerifyTokenNormalizedResponse extends NormalizedResponse {
  readonly result: Record<string, never>;
}

export interface PasswordResetVerifyTokenApiSuccessAction extends APISuccessAction {
  type: typeof PASSWORD_RESET_VERIFY_TOKEN_API_SUCCESS;
  payload: {
    response: PasswordResetVerifyTokenNormalizedResponse;
  };
}

export interface PasswordResetVerifyTokenApiFailureAction extends APIFailureAction {
  type: typeof PASSWORD_RESET_VERIFY_TOKEN_API_FAILURE;
}

// action creators

export const passwordResetVerifyTokenTrigger = (
  uid: string,
  token: string
): PasswordResetVerifyTokenTriggerAction => ({
  type: PASSWORD_RESET_VERIFY_TOKEN_TRIGGER,
  payload: { uid, token },
});

export const passwordResetVerifyTokenApiRequest = (): PasswordResetVerifyTokenApiRequestAction => ({
  type: PASSWORD_RESET_VERIFY_TOKEN_API_REQUEST,
});

export const passwordResetVerifyTokenApiSuccess = (
  response: PasswordResetVerifyTokenNormalizedResponse
): PasswordResetVerifyTokenApiSuccessAction => ({
  type: PASSWORD_RESET_VERIFY_TOKEN_API_SUCCESS,
  payload: { response },
});

export const passwordResetVerifyTokenApiFailure = (
  error: NormalizedError
): PasswordResetVerifyTokenApiFailureAction => ({
  type: PASSWORD_RESET_VERIFY_TOKEN_API_FAILURE,
  payload: { error },
});
