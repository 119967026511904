export interface ModerationListPageState {
  queries: { [queryKey: string]: ModerationListQueryState };
  selected: number[];
}

export type ModerationListQueryState = {
  count: number;
  moderationRequestIds: number[] | null;
};

export enum ModerationListItem {
  createdAt = 'createdAt',
  image = 'image',
  name = 'name',
  vendor = 'vendor',
}

export enum ModerationListSortOrder {
  createdAt = 'createdAt',
  createdAtDesc = '-createdAt',
  image = 'image',
  imageDesc = '-image',
  name = 'name',
  nameDesc = '-name',
  vendor = 'vendor',
  vendorDesc = '-vendor',
}
