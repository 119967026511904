import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { VendorsSortOrder } from '../types';
import { replaceVendorsUrl } from '../utils';
import useVendorsQueryParams from './useVendorsQueryParams';

const useOnSortChange = (): ((newSort: VendorsSortOrder) => void) => {
  const dispatch = useDispatch();
  const { search, sortOrder, start, type } = useVendorsQueryParams();

  return useCallback(
    (newSort: VendorsSortOrder) => {
      if (newSort !== sortOrder) {
        replaceVendorsUrl(dispatch, { search, sort: newSort, start, type });
      }
    },
    [dispatch, search, sortOrder, start, type]
  );
};

export default useOnSortChange;
