import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { getSessionActiveShop, getSessionUser } from '../../../global/session/selectors';
import { SortOrderDirection } from '../../../global/types';
import sortCaretFunc from '../../../global/utils/sortCaretFunc';
import { loaderFormatter } from '../../../helpers/formatters';
import { ShopEntity, UserRole } from '../../../schemas';
import {
  actionsFormatter,
  buyPriceFormatter,
  dateFormatter,
  imageFormatter,
  nameFormatter,
  priceFormatter,
  statusFormatter,
} from '../components/ProductsTable/formatters';
import { NonSortableProductsColumn, SortableProductsColumn } from '../types';
import { mapSortOrder } from '../utils';
import useOnSortChange from './useOnSortChange';

const useProductsTableColumns = (): {
  columns: ColumnDescription[];
  loadingColumns: ColumnDescription[];
} => {
  const onSortChange = useOnSortChange();

  const shop: ShopEntity | null = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);

  const sortFunc = (columnName: SortableProductsColumn, sortDirection: SortOrderDirection) => {
    onSortChange(mapSortOrder(columnName, sortDirection));
  };

  const columns = [
    {
      dataField: NonSortableProductsColumn.image,
      formatter: imageFormatter,
      headerStyle: () => ({ paddingLeft: 0, width: '50px' } as CSSProperties),
      style: () => ({ paddingLeft: 0, userSelect: 'none' } as CSSProperties),
      text: '',
    },
    {
      dataField: SortableProductsColumn.name,
      formatter: nameFormatter,
      headerStyle: () => ({ width: '40%' }),
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Name',
    },
    {
      dataField: SortableProductsColumn.vendor,
      hidden: !(user && (user.role === UserRole.Admin || user.role === UserRole.Brand)),
      formatter: (value: string) => value,
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Vendor',
    },
    // {
    //   dataField: SortableProductsColumn.inventory,
    //   formatter: inventoryFormatter,
    //   onSort: sortFunc,
    //   sort: true,
    //   sortCaret: sortCaretFunc,
    //   text: 'Inventory',
    // },
    {
      dataField: SortableProductsColumn.buyPrice,
      formatExtraData: { shop },
      formatter: buyPriceFormatter,
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Buy Price',
    },
    {
      dataField: SortableProductsColumn.price,
      formatExtraData: { shop },
      formatter: priceFormatter,
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Sale Price',
    },
    {
      dataField: SortableProductsColumn.status,
      formatter: statusFormatter,
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Status',
    },
    {
      dataField: SortableProductsColumn.lastPublishedAt,
      formatExtraData: { shop },
      formatter: dateFormatter,
      onSort: sortFunc,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Last published',
    },
    {
      dataField: '',
      text: '',
      formatter: actionsFormatter,
      headerStyle: () => ({ width: '4%' }),
    },
  ];

  const loadingColumns = columns.map((column) => ({
    ...column,
    formatter: loaderFormatter,
  }));

  return { columns, loadingColumns };
};

export default useProductsTableColumns;
