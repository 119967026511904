import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit';
import { normalize, SchemaObject } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router-dom';
import { call, Effect, put } from 'redux-saga/effects';
import { initIfNeededSaga } from '../global/init/sagas';
import { resetSessionIfNeededSaga } from '../global/session/sagas';
import { SessionSchema } from '../schemas';
import { NormalizedError, NormalizedResponse } from '../types';
import { apiCall, normalizeError } from './api';
import { ApiSuccessActionPayload } from './apiRequestSlice';
import { handleFailureSaga } from './sagas';

interface ApiSessionResponseSchema {
  session: typeof SessionSchema;
}

type ErrorResponsePayload = {
  readonly error: NormalizedError;
};

type QueryPayload = Record<string, unknown>;

export type RequestSagaConfig<
  RequestPayload,
  S extends ApiSuccessActionPayload<RequestPayload> = ApiSuccessActionPayload<RequestPayload>
> = {
  method: string;
  baseUrl: string;
  urlParams?: (payload: RequestPayload) => Record<string, string | number | boolean | undefined>;
  urlBuilder?: (payload: RequestPayload) => string;
  queryPayload?: (payload: RequestPayload) => QueryPayload;
  // TODO: stricter schema definition
  onFailure?: (error: Error, payload?: RequestPayload) => void;
  responseSchema: SchemaObject<unknown> & ApiSessionResponseSchema;
  actions: {
    request: ActionCreatorWithPayload<RequestPayload>;
    success: ActionCreatorWithPayload<S>;
    failure: ActionCreatorWithPayload<ErrorResponsePayload>;
  };
};

export default function makeApiRequestSaga<RequestPayload>(
  config: RequestSagaConfig<RequestPayload>
): ({ payload }: PayloadAction<RequestPayload>) => Generator<Effect, void> {
  const {
    actions,
    onFailure,
    baseUrl,
    method,
    queryPayload,
    responseSchema,
    urlParams,
    urlBuilder,
  } = config;

  const urlParamsMaker = urlParams || (() => ({}));

  // eslint-disable-next-line func-names
  return function* ({ payload }: PayloadAction<RequestPayload>) {
    yield put(actions.request(payload));
    try {
      yield* initIfNeededSaga();
      const url = urlBuilder
        ? urlBuilder(payload)
        : stringifyUrl({
            url: generatePath(baseUrl, urlParamsMaker(payload)),
            query: {},
          });
      const makePayload = queryPayload ? queryPayload(payload) : {};
      const response = yield call(apiCall, url, method, makePayload);
      const normalizedResponse: NormalizedResponse = normalize(response, responseSchema);

      yield* resetSessionIfNeededSaga(normalizedResponse);
      const successPayload = { request: payload, response: normalizedResponse };
      yield put(actions.success(successPayload));
    } catch (error) {
      yield put(actions.failure({ error: normalizeError(error) }));
      if (onFailure) {
        yield call(onFailure, error, payload);
      } else {
        yield* handleFailureSaga(error);
      }
    }
  };
}
