import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { VendorsSortOrder } from './types';

// fetch
export const VENDORS_FETCH_TRIGGER = 'VENDORS_FETCH_TRIGGER';
export const VENDORS_FETCH_API_PREFIX = 'VENDORS_FETCH_API';
export const VENDORS_FETCH_API_REQUEST = 'VENDORS_FETCH_API_REQUEST';
export const VENDORS_FETCH_API_SUCCESS = 'VENDORS_FETCH_API_SUCCESS';
export const VENDORS_FETCH_API_FAILURE = 'VENDORS_FETCH_API_FAILURE';

// select
export const VENDORS_ADD_SELECTED_VENDORS = 'VENDORS_ADD_SELECTED_VENDORS';
export const VENDORS_REMOVE_SELECTED_VENDORS = 'VENDORS_REMOVE_SELECTED_VENDORS';
export const VENDORS_SELECT_ALL = 'VENDORS_SELECT_ALL';
export const VENDORS_TOGGLE_SELECTED_VENDOR = 'VENDORS_TOGGLE_SELECTED_VENDOR';

// submit
export const VENDORS_SUBMIT_TRIGGER = 'VENDORS_SUBMIT_TRIGGER';
export const VENDORS_SUBMIT_API = 'VENDORS_SUBMIT_API';
export const VENDORS_SUBMIT_API_REQUEST = 'VENDORS_SUBMIT_API_REQUEST';
export const VENDORS_SUBMIT_API_SUCCESS = 'VENDORS_SUBMIT_API_SUCCESS';
export const VENDORS_SUBMIT_API_FAILURE = 'VENDORS_SUBMIT_API_FAILURE';

export const defaultSortOrder: VendorsSortOrder = VendorsSortOrder.name;

export const PAGE_SIZE = 15;
export const PAGINATION_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
