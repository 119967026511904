import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faAddressCard,
  faBox,
  faDollarSign,
  faDollyFlatbed,
  faFolderOpen,
  faGift,
  faLayerGroup,
  faShippingFast,
  faShoppingBasket,
  faTachometerAlt,
  faUsersCog,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { UserRole } from '../schemas';
import {
  COLLECTIONS_URL,
  CUSTOMERS_URL,
  DASHBOARD_URL,
  GIFT_CARDS_URL,
  INVENTORY_URL,
  LOGIN_AS_URL,
  ORDERS_URL,
  PAYOUTS_URL,
  PRODUCTS_URL,
  SHIPPING_POLICIES_URL,
  SHIPPING_TYPES_URL,
  VENDORS_URL,
  WAREHOUSE_URL,
} from './urls';

interface SideMenuSubItem {
  title: string;
  link: string;
}

export interface SideMenuItemType {
  title: string;
  link: string;
  icon: IconDefinition;
  roles: UserRole[];
  subItems?: SideMenuSubItem[];
  inShopNet?: boolean;
}

export const sideMenuItems: SideMenuItemType[] = [
  {
    title: 'dashboard',
    icon: faTachometerAlt,
    link: DASHBOARD_URL,
    roles: [UserRole.Admin, UserRole.Brand, UserRole.Vendor],
    inShopNet: true,
  },
  {
    title: 'orders',
    icon: faShoppingBasket,
    link: ORDERS_URL,
    roles: [UserRole.Admin, UserRole.Brand, UserRole.Vendor],
    inShopNet: true,
  },
  {
    title: 'products',
    icon: faBox,
    link: PRODUCTS_URL,
    roles: [UserRole.Admin, UserRole.Brand, UserRole.Vendor],
    inShopNet: true,
  },
  {
    title: 'inventory',
    icon: faDollyFlatbed,
    link: INVENTORY_URL,
    roles: [UserRole.Admin, UserRole.Vendor],
    inShopNet: true,
  },
  {
    title: 'shipping policies',
    icon: faShippingFast,
    link: SHIPPING_POLICIES_URL,
    roles: [UserRole.Vendor],
    inShopNet: true,
  },
  {
    title: 'shipping policies',
    icon: faShippingFast,
    link: SHIPPING_TYPES_URL,
    roles: [UserRole.Admin, UserRole.Brand],
    inShopNet: true,
  },
  {
    title: 'collections',
    icon: faLayerGroup,
    link: COLLECTIONS_URL,
    roles: [UserRole.Admin, UserRole.Brand],
    inShopNet: true,
  },
  {
    title: 'vendors',
    icon: faUsersCog,
    link: VENDORS_URL,
    roles: [UserRole.Admin, UserRole.Brand],
    inShopNet: true,
  },
  {
    title: 'customers',
    icon: faAddressCard,
    link: CUSTOMERS_URL,
    roles: [UserRole.Admin, UserRole.Brand, UserRole.Vendor],
    inShopNet: true,
  },
  {
    title: 'gift cards',
    icon: faGift,
    link: GIFT_CARDS_URL,
    roles: [UserRole.Admin, UserRole.Brand],
  },
  {
    title: 'payouts',
    icon: faDollarSign,
    link: PAYOUTS_URL,
    roles: [UserRole.Admin],
  },
  {
    title: 'ShopNET',
    icon: faFolderOpen,
    link: WAREHOUSE_URL,
    // roles: [UserRole.Admin, UserRole.Brand],
    roles: [UserRole.Admin],
    inShopNet: true,
  },
  {
    title: 'login as',
    icon: faUserSecret,
    link: LOGIN_AS_URL,
    roles: [UserRole.Admin],
    inShopNet: true,
  },
];
