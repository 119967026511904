import produce from 'immer';
import { Reducer } from 'redux';
import {
  ProductsFetchApiFailureAction,
  ProductsFetchApiRequestAction,
  ProductsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  WAREHOUSE_FETCH_API_FAILURE,
  WAREHOUSE_FETCH_API_REQUEST,
  WAREHOUSE_FETCH_API_SUCCESS,
} from '../constants';
import { WarehouseQueryState } from '../types';

export const initialQueryState: WarehouseQueryState = {
  count: 0,
  error: null,
  loaded: false,
  productVersionIds: null,
};

export type ProductsQueryActions =
  | ProductsFetchApiFailureAction
  | ProductsFetchApiRequestAction
  | ProductsFetchApiSuccessAction;

export const warehouseQueryReducer: Reducer<WarehouseQueryState, ProductsQueryActions> = (
  state: WarehouseQueryState = initialQueryState,
  action: ProductsQueryActions
): WarehouseQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case WAREHOUSE_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case WAREHOUSE_FETCH_API_SUCCESS: {
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.productVersionIds = action.payload.response.result.briefProductVersions;
        break;
      }

      case WAREHOUSE_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
