import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { Field, Form, Formik, FormikErrors, FormikProps } from 'formik';
import React, { FC } from 'react';
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import slugify from 'slugify';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { appendSlash, handleFilteredChange, onlySlugChars } from '../../../../helpers/utils';
import { CollectionEntity } from '../../../../schemas';
import styles from './CollectionsEditModal.module.scss';

export interface CollectionsEditFormValues {
  handle: string;
  managedBy: string;
  title: string;
}

interface Props {
  collection: CollectionEntity | null;
  isOpen: boolean;
  onSubmit: (collectionId: number, values: CollectionsEditFormValues) => void;
  shopUrl: string;
  toggle: () => void;
}

const defaultInitialValues: CollectionsEditFormValues = {
  handle: '',
  managedBy: 'vendor',
  title: '',
};

function validate(values: CollectionsEditFormValues) {
  const errors: FormikErrors<CollectionsEditFormValues> = {};
  if (!values.title) {
    errors.title = 'Required';
  }
  if (!values.handle) {
    errors.handle = 'Required';
  }
  return errors;
}

const CollectionsEditModal: FC<Props> = ({
  collection,
  isOpen,
  onSubmit,
  shopUrl,
  toggle,
}: Props) => {
  const initialValues: CollectionsEditFormValues = collection
    ? {
        handle: collection.handle,
        managedBy: collection.isVendorManaged ? 'vendor' : 'brand',
        title: collection.title,
      }
    : defaultInitialValues;

  const submit = (values: CollectionsEditFormValues) => {
    onSubmit(collection ? collection.id : 0, values);
    toggle();
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fprops: FormikProps<CollectionsEditFormValues>
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  ) => {
    const { value } = event.target;
    fprops.setFieldValue('title', value);
    if (!fprops.touched.handle || !fprops.values.handle) {
      fprops.setFieldValue('handle', slugify(value, { lower: true, strict: true }));
      fprops.setFieldTouched('handle', false);
    }
  };

  return (
    <Modal
      centered
      contentClassName={styles.CollectionsEditModal__content}
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
    >
      <ModalHeader className={styles.CollectionsEditModal__header}>
        <div className={cx(styles.CollectionsEditModal__heading)}>
          {collection ? 'Edit Collection' : 'Add Collection'}
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik<CollectionsEditFormValues>
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          {(fprops) => (
            <Form onSubmit={fprops.handleSubmit}>
              <Row className={styles.CollectionsEditModal__fieldRow}>
                <Col>
                  <FormGroup>
                    <Label
                      className={styles.CollectionsEditModal__label}
                      for="collections-edit-title"
                    >
                      Title
                    </Label>
                    <Field
                      as={Input}
                      id="collections-edit-title"
                      name="title"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleTitleChange(event, fprops)
                      }
                      placeholder="Title"
                      type="text"
                    />
                    {fprops.errors.title && fprops.touched.title && (
                      <div>{fprops.errors.title}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className={styles.CollectionsEditModal__fieldRow}>
                <Col>
                  <FormGroup>
                    <Label
                      className={styles.CollectionsEditModal__label}
                      for="collections-edit-handle"
                    >
                      URL handle
                    </Label>
                    <InputGroup>
                      <div className="input-group-prepend">
                        <span className={cx('input-group-text')} style={{ fontSize: '13px' }}>
                          {appendSlash(shopUrl)}collections/
                        </span>
                      </div>
                      <Field
                        as={Input}
                        id="collections-edit-handle"
                        name="handle"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleFilteredChange(event, onlySlugChars, fprops.setFieldValue, 'handle')
                        }
                        placeholder="URL handle"
                        type="text"
                      />
                    </InputGroup>
                    {fprops.errors.handle && fprops.touched.handle && (
                      <div>{fprops.errors.handle}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className={styles.CollectionsEditModal__fieldRow}>
                <Col>
                  <Row>
                    <Col className={cx('mb-3', styles.CollectionsEditModal__label)}>
                      Managed by:
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className={styles.CollectionsEditModal__radio}>
                          <Field as={CustomRadio} name="managedBy" value="brand" type="radio" />
                          <span className="ml-2">Brand</span>
                        </Label>
                        {fprops.errors.managedBy && fprops.touched.managedBy && (
                          <div>{fprops.errors.managedBy}</div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className={styles.CollectionsEditModal__radio}>
                          <Field as={CustomRadio} name="managedBy" value="vendor" type="radio" />
                          <span className="ml-2">Vendor</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <PrimaryButton
                    className={styles.CollectionsEditModal__cancelBtn}
                    gray
                    icon={faTimes}
                    onClick={toggle}
                    size="large"
                  >
                    Cancel
                  </PrimaryButton>
                  <PrimaryButton
                    className={styles.CollectionsEditModal__saveBtn}
                    icon={faCheck}
                    size="large"
                    type="submit"
                  >
                    {collection ? 'Save' : 'Add'}
                  </PrimaryButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default CollectionsEditModal;
