import { Action } from 'redux';
import {
  PRODUCT_ADD_OPTION,
  PRODUCT_ADD_OPTION_VALUE,
  PRODUCT_REMOVE_OPTION,
  PRODUCT_REMOVE_OPTION_VALUE,
  PRODUCT_SET_OPTION,
} from '../constants';

// action types

export interface ProductAddOptionAction extends Action {
  type: typeof PRODUCT_ADD_OPTION;
  payload: {
    productId: number;
    name: string;
    values: string[];
  };
}

export interface ProductSetOptionAction extends Action {
  type: typeof PRODUCT_SET_OPTION;
  payload: {
    productId: number;
    id: number | string;
    name: string;
  };
}

export interface ProductRemoveOptionAction extends Action {
  type: typeof PRODUCT_REMOVE_OPTION;
  payload: {
    productId: number;
    id: number | string;
  };
}

export interface ProductAddOptionValueAction extends Action {
  type: typeof PRODUCT_ADD_OPTION_VALUE;
  payload: {
    productId: number;
    id: number | string;
    value: string;
  };
}

export interface ProductRemoveOptionValueAction extends Action {
  type: typeof PRODUCT_REMOVE_OPTION_VALUE;
  payload: {
    productId: number;
    id: number | string;
    value: string;
  };
}

// action creators

export const productAddOption = (
  productId: number,
  name: string,
  values: string[]
): ProductAddOptionAction => ({
  type: PRODUCT_ADD_OPTION,
  payload: { productId, name, values },
});

export const productSetOption = (
  productId: number,
  id: number | string,
  name: string
): ProductSetOptionAction => ({
  type: PRODUCT_SET_OPTION,
  payload: { productId, id, name },
});

export const productRemoveOption = (
  productId: number,
  id: number | string
): ProductRemoveOptionAction => ({
  type: PRODUCT_REMOVE_OPTION,
  payload: {
    productId,
    id,
  },
});

export const productAddOptionValue = (
  productId: number,
  id: number | string,
  value: string
): ProductAddOptionValueAction => ({
  type: PRODUCT_ADD_OPTION_VALUE,
  payload: {
    productId,
    id,
    value,
  },
});

export const productRemoveOptionValue = (
  productId: number,
  id: number | string,
  value: string
): ProductRemoveOptionValueAction => ({
  type: PRODUCT_REMOVE_OPTION_VALUE,
  payload: {
    productId,
    id,
    value,
  },
});
