import React, { FC } from 'react';
import ProductStatusBadge from '../../../../components/ProductStatusBadge/ProductStatusBadge';
import { ProductStatus } from '../../../../schemas';

type Props = {
  isGiftCardProduct: boolean;
  productId: number;
  status: ProductStatus | null;
  title: string;
};

const getTitle = (title: string, productId: number, isGiftCardProduct: boolean): string => {
  if (title) return title;
  if (productId) return 'Empty Title';
  if (isGiftCardProduct) return 'New Gift Card Product';
  return 'New Product';
};

const ProductTitle: FC<Props> = ({ isGiftCardProduct, productId, title, status }: Props) => (
  <>
    {getTitle(title, productId, isGiftCardProduct)}
    <ProductStatusBadge className="ml-3" status={status} />
  </>
);

export default ProductTitle;
