import cx from 'classnames';
import React, { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  vertically?: boolean;
  horizontally?: boolean;
};

const CenteredWindow: FC<Props> = ({ children, vertically = true, horizontally = true }: Props) => (
  <div
    className={cx('d-flex justify-content-center align-items-center', {
      'vw-100': horizontally,
      'vh-100': vertically,
    })}
  >
    {children}
  </div>
);

export default CenteredWindow;
