import { ColumnDescription } from 'react-bootstrap-table-next';
import { Dispatch } from 'redux';
import { ModerationRequestEntity, ShopEntity, UserEntity } from '../../../../schemas';
import { NonSortableProductsColumn, SortableProductsColumn } from '../../../Products/types';
import {
  dateFormatter,
  FormatterExtraData,
  imageFormatter,
  nameFormatter,
  reviewButtonFormatter,
  statusFormatter,
  vendorNameFormatter,
} from './formatters';

const columns = (
  dispatch: Dispatch,
  shop: ShopEntity | null,
  user: UserEntity | null
): ColumnDescription<ModerationRequestEntity, FormatterExtraData>[] => [
  {
    dataField: NonSortableProductsColumn.image,
    formatter: imageFormatter,
    headerStyle: () => ({ width: '50px' }),
    text: '',
  },
  {
    dataField: SortableProductsColumn.name,
    formatter: nameFormatter,
    headerStyle: () => ({ width: '40%' }),
    text: 'Name',
  },
  {
    dataField: 'vendor',
    formatter: vendorNameFormatter,
    text: 'Vendor',
  },
  {
    dataField: 'createdAt',
    formatExtraData: {
      dispatch,
      shop,
    },
    formatter: dateFormatter,
    headerStyle: () => ({ whiteSpace: 'nowrap' }),
    text: 'Date',
  },
  {
    dataField: 'status',
    formatter: statusFormatter,
    text: 'Status',
  },
  {
    dataField: 'id',
    formatter: reviewButtonFormatter,
    formatExtraData: {
      dispatch,
      user,
    },
    text: '',
  },
];

export default columns;
