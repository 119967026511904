import cx from 'classnames';
import React, { FC } from 'react';
import { Card, CardBody, Form, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import FieldError from '../../../../components/FieldError/FieldError';
import MultipleInput from '../../../../components/MultipleInput/MultipleInput';
import { appendSlash } from '../../../../helpers/utils';
import { NormalizedError } from '../../../../types';
import styles from './ProductInformation.module.scss';

type Props = {
  addTag: (tag: string) => void;
  description: string;
  error: NormalizedError | null;
  removeTag: (tag: string) => void;
  seoDescription: string;
  seoTitle: string;
  seoUrl: string;
  setDescription: (value: string) => void;
  setSeoDescription: (value: string) => void;
  setSeoTitle: (value: string) => void;
  setSeoUrl: (value: string) => void;
  setTitle: (value: string) => void;
  shopUrl: string;
  tags: string[];
  title: string;
};

const ProductInformation: FC<Props> = ({
  addTag,
  description,
  error,
  removeTag,
  seoDescription,
  seoTitle,
  seoUrl,
  setDescription,
  setSeoDescription,
  setSeoTitle,
  setSeoUrl,
  setTitle,
  shopUrl,
  tags,
  title,
}: Props) => (
  <Card className="h-lg-100 mb-3">
    <FalconCardHeader title="Product Information" className="bg-white" />
    <CardBody className="py-0">
      <Form>
        <FormGroup>
          <Label className="small" htmlFor="productInfoTitle">
            Product Title
          </Label>
          <Input
            placeholder="Add Product Title"
            id="productInfoTitle"
            value={title || ''}
            onChange={(e) => setTitle(e.target.value)}
          />
          <FieldError error={error} name="title" />
        </FormGroup>
        <FormGroup>
          <Label className="small" htmlFor="productInfoDescription">
            Description
          </Label>
          <Input
            type="textarea"
            placeholder=""
            id="productInfoDescription"
            value={description || ''}
            rows={5}
            onChange={(e) => setDescription(e.target.value)}
          />
          <FieldError error={error} name="description" />
        </FormGroup>
        <FormGroup>
          <MultipleInput
            name="tags"
            title="Tags"
            submittedValues={tags}
            addTag={addTag}
            removeTag={removeTag}
            placeholder="Type to add"
          />
        </FormGroup>
        <hr className={cx('mt-4', styles.dashedLine)} />
        <FormGroup>
          <Label className="small" htmlFor="productSEOTitle">
            SEO Title
          </Label>
          <Input
            placeholder="Add SEO Title"
            id="productSEOTitle"
            value={seoTitle || ''}
            onChange={(e) => setSeoTitle(e.target.value)}
          />
          <FieldError error={error} name="seoTitle" />
        </FormGroup>
        <FormGroup>
          <Label className="small" htmlFor="productSEODescription">
            SEO Description
          </Label>
          <Input
            type="textarea"
            placeholder=""
            id="productSEODescription"
            value={seoDescription || ''}
            rows={5}
            onChange={(e) => setSeoDescription(e.target.value)}
          />
          <FieldError error={error} name="seoDescription" />
        </FormGroup>
        <FormGroup>
          <Label className="small" htmlFor="productURLHandle">
            URL Handle
          </Label>
          <InputGroup>
            <div className="input-group-prepend">
              <span className={cx('input-group-text')} style={{ fontSize: '13px' }}>
                {appendSlash(shopUrl)}products/
              </span>
            </div>
            <Input
              id="productURLHandle"
              value={seoUrl}
              onChange={(e) => setSeoUrl(e.target.value)}
            />
          </InputGroup>
          <FieldError error={error} name="handle" />
        </FormGroup>
      </Form>
    </CardBody>
  </Card>
);

export default ProductInformation;
