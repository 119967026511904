import { debounce } from 'lodash';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { StandardQueryParams } from '../types';

interface DebouncedSearchProps {
  correctLocation: boolean;
  replaceUrl: (dispatch: Dispatch, params: StandardQueryParams) => void;
  search: string;
  sortOrder: string;
}

interface DebouncedSearchResult {
  searchInput: string;
  setSearchInput: React.Dispatch<SetStateAction<string>>;
}

function useDebouncedSearch({
  correctLocation,
  replaceUrl,
  search,
  sortOrder,
}: DebouncedSearchProps): DebouncedSearchResult {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState(search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((newSearch: string, oldSearch: string, sort: string) => {
      if (newSearch !== oldSearch) {
        replaceUrl(dispatch, { search: newSearch, sort, start: 0 });
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (correctLocation) {
      debounceSearch(searchInput, search, sortOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, search, searchInput]);

  return {
    searchInput,
    setSearchInput,
  };
}

export default useDebouncedSearch;
