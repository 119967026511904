import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { FC, useState } from 'react';
import { Col } from 'reactstrap';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import { CreateShippingTypeModal } from '../EditShippingTypeModal/EditShippingTypeModal';
import ShippingTypesTable from '../ShippingTypesTable/ShippingTypesTable';
import useShippingTypesData from '../hooks/useShippingTypesData';

const ShippingTypesMainContent: FC = () => {
  const {
    shippingTypes,
    fetchShippingTypesTrigger,
    error,
    actionError,
    isLoading,
    loaded,
    reorderShippingTypes,
  } = useShippingTypesData();

  const [isCreateShippingTypeModalOpen, isAddShippingTypeModalOpen] = useState(false);

  return (
    <>
      {actionError && (
        <div style={{ color: 'red' }}>
          {' '}
          {actionError?.json?.detail || `${actionError?.statusCode} ${actionError?.message}`}
        </div>
      )}
      <TablePageWrapper
        title="Shipping Types"
        rightControls={
          <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
            <PrimaryButton
              className="mx-2"
              icon={faPlus}
              onClick={() => isAddShippingTypeModalOpen(true)}
              size="small"
            >
              Add Shipping Type
            </PrimaryButton>
            <CreateShippingTypeModal
              isOpen={isCreateShippingTypeModalOpen}
              toggle={() => isAddShippingTypeModalOpen(!isCreateShippingTypeModalOpen)}
            />
          </Col>
        }
        itemsCount={0}
        pagination={null}
      >
        <LoadingSwitch
          error={!!error}
          loading={isLoading}
          onLoad={fetchShippingTypesTrigger}
          renderError={() => (
            <span style={{ color: 'red' }}>
              {error?.json?.detail || `${error?.statusCode} ${error?.message}`}
            </span>
          )}
          renderLoading={() => <TableLoadingIndicator />}
          require={loaded}
        >
          <ShippingTypesTable
            useDragHandle
            shippingTypes={shippingTypes}
            onSortEnd={(args) => reorderShippingTypes(args)}
          />
        </LoadingSwitch>
      </TablePageWrapper>
    </>
  );
};

export default ShippingTypesMainContent;
