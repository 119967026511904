import { createAction, createReducer } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { denormalize } from 'normalizr';
import { matchPath } from 'react-router-dom';
import { LOGOUT_API_SUCCESS } from '../../global/logout/constants';
import { RESET_SESSION } from '../../global/session/constants';
import { PROFILE_URL } from '../../global/urls';
import { ApiSuccessResultActionPayload, makeApiRequestSlice } from '../../helpers/apiRequestSlice';
import { VendorEntity, VendorSchema } from '../../schemas';
import { NormalizedResponse } from '../../types';
import { LOGIN_API_SUCCESS } from '../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../ShopSelector/constants';

// fetch

type VendorProfileFetchPayload = Record<string, never>;

interface VendorProfileSuccessPayload {
  request: VendorProfileFetchPayload;
  response: NormalizedResponse & {
    result: {
      vendorshop: number;
    };
  };
}

export const fetchVendorProfileSlice = makeApiRequestSlice<
  VendorProfileFetchPayload,
  VendorProfileSuccessPayload
>({
  name: 'vendorProfile',
});

// save

export type SaveVendorProfileRequestPayload = { vendorShopId: number | null; bankName: string };

export type SaveVendorProfileResult = {
  vendorshop: number;
};

export type SaveVendorProfileSuccessPayload = ApiSuccessResultActionPayload<
  SaveVendorProfileRequestPayload,
  SaveVendorProfileResult
>;

export const saveVendorProfileSlice = makeApiRequestSlice<
  SaveVendorProfileRequestPayload,
  SaveVendorProfileSuccessPayload
>({ name: 'vendorProfile/save' });

// state

export interface VendorProfileState {
  logoUrl: string | null;
  vendorShopId: number | null;
}

const initialState: VendorProfileState = {
  logoUrl: null,
  vendorShopId: null,
};

const resetState = () => initialState;

export const setLogoUrl = createAction<string>('vendorProfile/setLogoUrl');

export const vendorProfilePageReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchVendorProfileSlice.actions.success, (draft, action) => {
    const { response } = action.payload;
    draft.vendorShopId = response.result.vendorshop;
    const { entities } = response;
    const vendor: VendorEntity = denormalize(draft.vendorShopId, VendorSchema, entities);
    draft.logoUrl = vendor.logoUrl ? process.env.REACT_APP_DJANGO_BASE_URL + vendor.logoUrl : '';
  });
  builder.addCase(setLogoUrl, (draft, action) => {
    draft.logoUrl = action.payload;
  });
  builder.addCase(LOCATION_CHANGE, (draft, action) => {
    const { location } = (action as LocationChangeAction).payload;
    const locationMatch = matchPath(location.pathname, {
      path: PROFILE_URL,
      exact: true,
    });
    return locationMatch ? initialState : draft;
  });
  builder.addCase(LOGIN_API_SUCCESS, resetState);
  builder.addCase(LOGOUT_API_SUCCESS, resetState);
  builder.addCase(RESET_SESSION, resetState);
  builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
});
