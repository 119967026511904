import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router-dom';
import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import { SHIPPING_POLICIES_API_URL, SHIPPING_POLICIES_SUBMIT_API_URL } from '../../global/urls';
import { apiGet, apiPut, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import { SessionSchema, ShippingPolicySchema } from '../../schemas';
import { fetchShippingTypesSlice } from '../ShippingTypes/slices/shippingTypesSlice';
import {
  shippingPoliciesFetchApiFailure,
  shippingPoliciesFetchApiRequest,
  shippingPoliciesFetchApiSuccess,
  ShippingPoliciesFetchNormalizedResponse,
  shippingPoliciesFetchTrigger,
  ShippingPoliciesFetchTriggerAction,
} from './actions/fetch.actions';
import {
  shippingPoliciesSubmitApiFailure,
  shippingPoliciesSubmitApiRequest,
  shippingPoliciesSubmitApiSuccess,
  ShippingPoliciesSubmitNormalizedResponse,
  ShippingPoliciesSubmitTriggerAction,
} from './actions/submit.actions';
import { SHIPPING_POLICIES_FETCH_TRIGGER, SHIPPING_POLICIES_SUBMIT_TRIGGER } from './constants';
import createShippingPolicySlice from './slices.tx/shippingPolicySlice';
import { ShippingPoliciesSortOrder } from './types';

function* fetchShippingPoliciesSaga({ payload }: ShippingPoliciesFetchTriggerAction) {
  const { search, sort, start } = payload;
  yield put(shippingPoliciesFetchApiRequest(sort, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: SHIPPING_POLICIES_API_URL,
      query: { search: payload.search, sort: payload.sort, start: payload.start },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: ShippingPoliciesFetchNormalizedResponse = normalize(response, {
      shippingPolicies: [ShippingPolicySchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(shippingPoliciesFetchApiSuccess(sort, start, search, normalizedResponse));
  } catch (error) {
    yield put(shippingPoliciesFetchApiFailure(sort, start, search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

function* fetchSaga(action: ShippingPoliciesFetchTriggerAction) {
  yield put(fetchShippingTypesSlice.actions.trigger());
  yield* fetchShippingPoliciesSaga(action);
}

function* submitSaga({ payload }: ShippingPoliciesSubmitTriggerAction) {
  const { shippingPolicyId, values } = payload;
  yield put(shippingPoliciesSubmitApiRequest(shippingPolicyId, values));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: generatePath(SHIPPING_POLICIES_SUBMIT_API_URL, { shippingPolicyId }),
      query: {},
    });
    const response = yield call(apiPut, url, values);
    const normalizedResponse: ShippingPoliciesSubmitNormalizedResponse = normalize(response, {
      shippingPolicy: ShippingPolicySchema,
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(shippingPoliciesSubmitApiSuccess(shippingPolicyId, values, normalizedResponse));
  } catch (error) {
    yield put(shippingPoliciesSubmitApiFailure(shippingPolicyId, values, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

const createSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: SHIPPING_POLICIES_API_URL,
  queryPayload: (payload) => payload,
  responseSchema: {
    shippingPolicy: ShippingPolicySchema,
    session: SessionSchema,
  },
  actions: createShippingPolicySlice.actions,
});

function* reloadSaga() {
  const { success, failure } = createShippingPolicySlice.actions;
  const resultAction = yield take([success.type, failure.type]);
  if (resultAction.type === success.type) {
    yield put(shippingPoliciesFetchTrigger(ShippingPoliciesSortOrder.pk, 0, ''));
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(SHIPPING_POLICIES_FETCH_TRIGGER, fetchSaga),
    takeLatest(SHIPPING_POLICIES_SUBMIT_TRIGGER, submitSaga),
    takeLatest(createShippingPolicySlice.actions.trigger.type, createSaga),
    takeLatest(createShippingPolicySlice.actions.trigger.type, reloadSaga),
  ]);
}
