import produce from 'immer';
import { Reducer } from 'redux';
import {
  ProductsFetchApiFailureAction,
  ProductsFetchApiRequestAction,
  ProductsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import { WAREHOUSE_FETCH_API_SUCCESS } from '../constants';
import { WarehouseSearchState } from '../types';

export const initialSearchState: WarehouseSearchState = {
  allIds: [],
  allProductIds: [],
};

export type ProductsSearchAction =
  | ProductsFetchApiFailureAction
  | ProductsFetchApiRequestAction
  | ProductsFetchApiSuccessAction;

export const warehouseSearchReducer: Reducer<WarehouseSearchState, ProductsSearchAction> = (
  state: WarehouseSearchState = initialSearchState,
  action: ProductsSearchAction
): WarehouseSearchState =>
  produce(state, (draft) => {
    switch (action.type) {
      case WAREHOUSE_FETCH_API_SUCCESS: {
        const { ids, allProductIds } = action.payload.response.result;
        draft.allIds = ids;
        draft.allProductIds = allProductIds;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
