import { checkIfProcessing } from '../../global/ui/selectors';
import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { INVENTORY_SUBMIT_API } from './constants';
import { initialQueryState } from './reducers/inventoryQueryReducer';
import { initialSearchState } from './reducers/inventorySearchReducer';
import {
  InventoryPageState,
  InventoryQueryState,
  InventorySearchState,
  InventorySortOrder,
} from './types';

export const getInventoryPageState = (state: AppState): InventoryPageState =>
  state.containers.inventory;

export const getInventoryQueryState = (
  state: AppState,
  sort: InventorySortOrder,
  start: number,
  search: string
): InventoryQueryState => {
  return (
    getInventoryPageState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState
  );
};

export const getInventorySearchState = (state: AppState, search: string): InventorySearchState =>
  getInventoryPageState(state).searches[search] || initialSearchState;

export const isSubmittingInventory = (state: AppState): boolean =>
  checkIfProcessing(state, INVENTORY_SUBMIT_API);
