import { Location } from 'history';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { LocationState } from '../../../global/types';
import { defaultSortOrder } from '../constants';
import { ProductsSortOrder, ProductsType } from '../types';

type ProductsQueryParams = {
  location: Location<LocationState>;
  locationState: LocationState;
  search: string;
  sortOrder: ProductsSortOrder;
  start: number;
  type: ProductsType;
};

const parseProductsSortOrder = (sort: string): ProductsSortOrder => {
  const sortProducts = Object.values(ProductsSortOrder);
  return sortProducts.includes(sort as ProductsSortOrder)
    ? (sort as ProductsSortOrder)
    : defaultSortOrder;
};

const parseProductsType = (type: string): ProductsType => {
  return type === ProductsType.Archived ? ProductsType.Archived : ProductsType.Active;
};

const useProductsQueryParams = (): ProductsQueryParams => {
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const start: number = parsed.start ? parseInt(parsed.start.toString(), 10) : 0;
  const search: string = parsed.search ? parsed.search.toString() : '';
  const sortOrder = parseProductsSortOrder(parsed.sort ? parsed.sort.toString() : '');
  const type: ProductsType = parseProductsType(parsed.type ? parsed.type.toString() : '');
  const { state } = location;

  return {
    location,
    locationState: state,
    search,
    sortOrder,
    start,
    type,
  };
};

export default useProductsQueryParams;
