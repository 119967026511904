import { SagaIterator } from '@redux-saga/core';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SessionSectionNormalized } from '../../global/init/actions';
import { resetSession } from '../../global/session/actions';
import { BRAND_PROFILE_API_URL } from '../../global/urls';
import toast from '../../global/utils/toast';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { SessionSchema, ShopSchema } from '../../schemas';
import { APISuccessAction } from '../../types';
import { fetchBrandProfileSlice, saveBrandProfileSlice } from './slices';

const fetchBrandProfileSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: BRAND_PROFILE_API_URL,
  responseSchema: {
    session: SessionSchema,
    shop: ShopSchema,
  },
  actions: fetchBrandProfileSlice.actions,
});

const saveBrandProfileSaga = makeApiRequestSaga({
  baseUrl: BRAND_PROFILE_API_URL,
  method: 'PUT',
  queryPayload: (payload) => payload,
  responseSchema: { session: SessionSchema, shop: ShopSchema },
  actions: saveBrandProfileSlice.actions,
});

function* successSaveBrandProfileSaga(action: APISuccessAction) {
  yield call(toast.success, `Profile updated.`);

  // reset session to update to recent shop data if needed
  const normalizedResponse = action.payload.response;
  const session: SessionSectionNormalized = normalizedResponse?.result?.session;
  yield put(resetSession(session, normalizedResponse.entities));
}

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest(fetchBrandProfileSlice.actions.trigger, fetchBrandProfileSaga),
    takeLatest(saveBrandProfileSlice.actions.trigger, saveBrandProfileSaga),
    takeLatest(saveBrandProfileSlice.actions.success, successSaveBrandProfileSaga),
  ]);
}
