import { createReducer } from '@reduxjs/toolkit';
import { LOGOUT_API_SUCCESS } from '../../global/logout/constants';
import { RESET_SESSION } from '../../global/session/constants';
import { makeApiRequestSlice } from '../../helpers/apiRequestSlice';
import { NormalizedResponse } from '../../types';
import { LOGIN_API_SUCCESS } from '../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../ShopSelector/constants';

type InventoryAlertsFetchPayload = Record<string, never>;

interface InventoryAlertsSuccessPayload {
  request: InventoryAlertsFetchPayload;
  response: NormalizedResponse & {
    result: {
      count: number;
      inventoryItems: number[];
    };
  };
}

export const fetchInventoryAlertsSlice = makeApiRequestSlice<
  InventoryAlertsFetchPayload,
  InventoryAlertsSuccessPayload
>({
  name: 'inventoryAlerts',
});

export interface InventoryAlertsState {
  inventoryItems: number[];
}

const initialState: InventoryAlertsState = {
  inventoryItems: [],
};

const resetState = () => initialState;

export const inventoryAlertsDataReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchInventoryAlertsSlice.actions.success, (draft, action) => {
    draft.inventoryItems = action.payload.response.result.inventoryItems;
  });
  builder.addCase(LOGIN_API_SUCCESS, resetState);
  builder.addCase(LOGOUT_API_SUCCESS, resetState);
  builder.addCase(RESET_SESSION, resetState);
  builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
});
