import cx from 'classnames';
import { Field } from 'formik';
import React, { FC } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import styles from '../CollectionsEditModal/CollectionsEditModal.module.scss';

export type ManagedBy = 'vendor' | 'brand' | undefined;

export type Props = {
  isOpen: boolean;
  toggle: () => void;
  numSelected: number;
  onSubmit: (managedBy: string) => void;
};

type FormValues = {
  managedBy: ManagedBy;
};

const initialValues: FormValues = {
  managedBy: undefined,
};

const CollectionsChangeTypeModal: FC<Props> = ({
  isOpen,
  toggle,
  numSelected,
  onSubmit,
}: Props) => (
  <ModalForm
    isOpen={isOpen}
    toggle={toggle}
    title="Change Collections Type"
    initialValues={initialValues}
    onSubmit={({ managedBy }) => (managedBy ? onSubmit(managedBy) : () => {})}
  >
    {(fprops) => {
      return (
        <Row className={styles.CollectionsEditModal__fieldRow}>
          <Col>
            <Row>
              Change type of {numSelected} collections:
              <br />
              <br />
            </Row>
            <Row>
              <Col className={cx('mb-3', styles.CollectionsEditModal__label)}>Managed by:</Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className={styles.CollectionsEditModal__radio}>
                    <Field as={CustomRadio} name="managedBy" value="brand" type="radio" />
                    <span className="ml-2">Brand</span>
                  </Label>
                  {fprops.errors.managedBy && fprops.touched.managedBy && (
                    <div>{fprops.errors.managedBy}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className={styles.CollectionsEditModal__radio}>
                    <Field as={CustomRadio} name="managedBy" value="vendor" type="radio" />
                    <span className="ml-2">Vendor</span>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }}
  </ModalForm>
);

export default CollectionsChangeTypeModal;
