import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import InventoryMainContent from './components/InventoryMainContent/InventoryMainContent';

const Inventory: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <InventoryMainContent key={key} />;
};

export default Inventory;
