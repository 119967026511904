import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { ShippingPoliciesSortOrder } from './types';

// fetch
export const SHIPPING_POLICIES_FETCH_TRIGGER = 'SHIPPING_POLICIES_FETCH_TRIGGER';
export const SHIPPING_POLICIES_FETCH_API = 'SHIPPING_POLICIES_FETCH_API';
export const SHIPPING_POLICIES_FETCH_API_REQUEST = 'SHIPPING_POLICIES_FETCH_API_REQUEST';
export const SHIPPING_POLICIES_FETCH_API_SUCCESS = 'SHIPPING_POLICIES_FETCH_API_SUCCESS';
export const SHIPPING_POLICIES_FETCH_API_FAILURE = 'SHIPPING_POLICIES_FETCH_API_FAILURE';

// submit
export const SHIPPING_POLICIES_SUBMIT_TRIGGER = 'SHIPPING_POLICIES_SUBMIT_TRIGGER';
export const SHIPPING_POLICIES_SUBMIT_API = 'SHIPPING_POLICIES_SUBMIT_API';
export const SHIPPING_POLICIES_SUBMIT_API_REQUEST = 'SHIPPING_POLICIES_SUBMIT_API_REQUEST';
export const SHIPPING_POLICIES_SUBMIT_API_SUCCESS = 'SHIPPING_POLICIES_SUBMIT_API_SUCCESS';
export const SHIPPING_POLICIES_SUBMIT_API_FAILURE = 'SHIPPING_POLICIES_SUBMIT_API_FAILURE';

export const defaultSortOrder: ShippingPoliciesSortOrder = ShippingPoliciesSortOrder.pk;

export const PAGE_SIZE = 15;
export const PAGING_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
