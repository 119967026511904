import React, { FC } from 'react';
import showShoppaLogo from '../../assets/img/showshoppa-logo.png';
import sssLogo from '../../assets/img/sss-logo2.png';
import { isSuperStoreSite } from '../../helpers/utils';

type Props = {
  size?: 'sm' | 'lg';
};

const MainLogo: FC<Props> = ({ size }: Props) => {
  return (
    <div className="d-flex row justify-content-center align-content-center">
      <img
        className="mr-2"
        src={isSuperStoreSite() ? sssLogo : showShoppaLogo}
        alt="Logo"
        style={{ width: `${size === 'sm' ? '40px' : '180px'}` }}
      />
    </div>
  );
};

export default MainLogo;
