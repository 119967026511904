import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import PayoutsMainContent from './components/PayoutsMainContent/PayoutsMainContent';

const Payouts: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <PayoutsMainContent key={key} />;
};

export default Payouts;
