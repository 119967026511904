import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import React, { ChangeEvent, FC, useRef } from 'react';
import { Col, Input } from 'reactstrap';
import ButtonIcon from '../../../../components/ButtonIcon/ButtonIcon';
import { ORDERS_EXPORT_API_URL } from '../../../../global/urls';
import styles from './OrdersRightControls.module.scss';

type Props = {
  searchInput: string;
  setSearchInput: (newValue: string) => void;
};

const OrdersRightControls: FC<Props> = ({ searchInput, setSearchInput }: Props) => {
  const exportUrl = process.env.REACT_APP_BACKEND_API_URL + ORDERS_EXPORT_API_URL;
  const exportLinkRef = useRef<HTMLAnchorElement>(null);
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />
        {/* <ButtonIcon */}
        {/*   icon={faPlus} */}
        {/*   className={cx(styles.headerBtn, styles.btnShadow, 'sss-primary mx-2')} */}
        {/* > */}
        {/*   Add Vendor */}
        {/* </ButtonIcon> */}
        <ButtonIcon
          icon={faExternalLinkAlt}
          transform="shrink-3 down-2"
          color="falcon-default"
          size="sm"
          className={cx('mx-2', styles.headerBtn, styles.headerBtnGray)}
          onClick={() => {
            // This is needed for the case when user clicks on the edge of a button, outside of anchor click area.
            exportLinkRef.current?.click();
          }}
        >
          <a href={exportUrl} download className={styles.menuItemLink} ref={exportLinkRef}>
            Export
          </a>
        </ButtonIcon>
      </Col>
    </>
  );
};

export default OrdersRightControls;
