import produce from 'immer';
import { Reducer } from 'redux';
import {
  ProductsFetchApiFailureAction,
  ProductsFetchApiRequestAction,
  ProductsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  PRODUCTS_FETCH_API_FAILURE,
  PRODUCTS_FETCH_API_REQUEST,
  PRODUCTS_FETCH_API_SUCCESS,
} from '../constants';
import { ProductsQueryState } from '../types';

export const initialQueryState: ProductsQueryState = {
  count: 0,
  error: null,
  loaded: false,
  productVersionIds: null,
};

export type ProductsQueryActions =
  | ProductsFetchApiFailureAction
  | ProductsFetchApiRequestAction
  | ProductsFetchApiSuccessAction;

export const productsQueryReducer: Reducer<ProductsQueryState, ProductsQueryActions> = (
  state: ProductsQueryState = initialQueryState,
  action: ProductsQueryActions
): ProductsQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCTS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case PRODUCTS_FETCH_API_SUCCESS: {
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.productVersionIds = action.payload.response.result.briefProductVersions;
        break;
      }

      case PRODUCTS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
