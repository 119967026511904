import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  EntitiesState,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  INVENTORY_SUBMIT_API_FAILURE,
  INVENTORY_SUBMIT_API_REQUEST,
  INVENTORY_SUBMIT_API_SUCCESS,
  INVENTORY_SUBMIT_TRIGGER,
} from '../constants';
import { InventorySubmitValues } from '../types';

// action types

type InventorySubmitPayload = {
  variantId: number;
  values: InventorySubmitValues;
};

export interface InventorySubmitNormalizedResponse extends NormalizedResponse {
  readonly entities: EntitiesState;
  readonly result: {
    inventoryItem: number;
  };
}

export interface InventorySubmitApiRequestAction extends APIRequestAction {
  type: typeof INVENTORY_SUBMIT_API_REQUEST;
  payload: InventorySubmitPayload;
}

export type InventorySubmitTriggerAction = {
  type: typeof INVENTORY_SUBMIT_TRIGGER;
  payload: InventorySubmitPayload;
};

export interface InventorySubmitApiSuccessAction extends APISuccessAction {
  type: typeof INVENTORY_SUBMIT_API_SUCCESS;
  payload: InventorySubmitPayload & {
    response: InventorySubmitNormalizedResponse;
  };
}

export interface InventorySubmitApiFailureAction extends APIFailureAction {
  type: typeof INVENTORY_SUBMIT_API_FAILURE;
  payload: InventorySubmitPayload & {
    error: NormalizedError;
  };
}

// action creators

export const inventorySubmitTrigger = (
  variantId: number,
  values: InventorySubmitValues
): InventorySubmitTriggerAction => ({
  type: INVENTORY_SUBMIT_TRIGGER,
  payload: {
    values,
    variantId,
  },
});

export const inventorySubmitApiRequest = (
  variantId: number,
  values: InventorySubmitValues
): InventorySubmitApiRequestAction => ({
  type: INVENTORY_SUBMIT_API_REQUEST,
  payload: { values, variantId },
});

export const inventorySubmitApiSuccess = (
  variantId: number,
  values: InventorySubmitValues,
  response: InventorySubmitNormalizedResponse
): InventorySubmitApiSuccessAction => ({
  type: INVENTORY_SUBMIT_API_SUCCESS,
  payload: { response, values, variantId },
});

export const inventorySubmitApiFailure = (
  variantId: number,
  values: InventorySubmitValues,
  error: NormalizedError
): InventorySubmitApiFailureAction => ({
  type: INVENTORY_SUBMIT_API_FAILURE,
  payload: { error, values, variantId },
});
