import { SortOrder } from 'react-bootstrap-table-next';
import { SortOrderDirection } from '../../global/types';
import { CustomersColumn, CustomersSortOrder } from './types';

export const mapSortOrder = (
  columnName: CustomersColumn,
  sortDirection: SortOrderDirection
): CustomersSortOrder => {
  if (columnName === CustomersColumn.name) {
    return sortDirection === SortOrderDirection.asc
      ? CustomersSortOrder.name
      : CustomersSortOrder.nameDesc;
  }
  const columnNameDesc = `-${columnName}` as CustomersSortOrder;
  return sortDirection === SortOrderDirection.asc ? CustomersSortOrder[columnName] : columnNameDesc;
};

export const mapTableSort = (
  sortOrder: CustomersSortOrder
): { dataField: CustomersSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as CustomersSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};
