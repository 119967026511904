import { DEFAULT_PAGING_DISPLAYED_RANGE } from '../../global/constants';
import { CollectionsSortOrder } from './types';

export const COLLECTIONS_FETCH_TRIGGER = 'COLLECTIONS_FETCH_TRIGGER';
export const COLLECTIONS_FETCH_API = 'COLLECTIONS_FETCH_API';
export const COLLECTIONS_FETCH_API_REQUEST = 'COLLECTIONS_FETCH_API_REQUEST';
export const COLLECTIONS_FETCH_API_SUCCESS = 'COLLECTIONS_FETCH_API_SUCCESS';
export const COLLECTIONS_FETCH_API_FAILURE = 'COLLECTIONS_FETCH_API_FAILURE';

export const defaultSortOrder: CollectionsSortOrder = CollectionsSortOrder.title;

export const PAGE_SIZE = 15;
export const PAGINATION_DISPLAYED_RANGE = DEFAULT_PAGING_DISPLAYED_RANGE;
