import cx from 'classnames';
import React, { FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import { INPUT_ERROR_MESSAGE } from '../../../../global/general/constants';
import { isSuperStoreSite } from '../../../../helpers/utils';
import {
  validateEmail,
  validateHasLength,
  validateHasLowercase,
  validateHasNumberOrSymbol,
  validateHasUppercase,
} from '../../../../helpers/validators';
import useVendorRegistrationLoginData from '../../hooks/useVendorRegistrationLoginData';
import { VendorRegistrationScreen } from '../../types';
import VendorSignUpFooter from '../VendorSignUpFooter/VendorSignUpFooter';
import VendorSignUpHeader from '../VendorSignUpHeader/VendorSignUpHeader';
import styles from './LoginDetails.module.scss';
import RequirementsList from './components/RequirementsList/RequirementsList';

type Props = {
  inputHasError: boolean;
  onSubmit(): void;
  setInputHasError: (hasError: boolean) => void;
  setScreen: React.Dispatch<SetStateAction<VendorRegistrationScreen>>;
};

const LoginDetails: FC<Props> = ({
  inputHasError,
  onSubmit,
  setInputHasError,
  setScreen,
}: Props) => {
  const [submitTouched, setSubmitTouched] = useState(false);

  const {
    login: { email, password, passwordConfirm },
    setEmail,
    setPassword,
    setPasswordConfirm,
  } = useVendorRegistrationLoginData();
  const inputTouched = useMemo(
    () => ({
      email: submitTouched,
      password: submitTouched,
      passwordConfirm: submitTouched,
    }),
    [submitTouched]
  );
  const inputErrors = useMemo(
    () => ({
      email: !validateEmail(email),
      password:
        !validateHasLength(password, 8) ||
        !validateHasLowercase(password) ||
        !validateHasUppercase(password) ||
        !validateHasNumberOrSymbol(password),
      passwordConfirm: password !== passwordConfirm,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onInputChange = useCallback(
    ({ target }) => {
      const { name: inputName, value } = target;
      switch (inputName) {
        case 'email': {
          setEmail(value);
          inputErrors.email = !validateEmail(value);
          inputTouched.email = true;
          break;
        }
        case 'password': {
          setPassword(value);
          inputTouched.password = true;
          inputErrors.password =
            !validateHasLength(value, 8) ||
            !validateHasLowercase(value) ||
            !validateHasUppercase(value) ||
            !validateHasNumberOrSymbol(value);
          inputErrors.passwordConfirm = passwordConfirm !== value;
          break;
        }
        case 'passwordConfirm': {
          setPasswordConfirm(value);
          inputErrors.passwordConfirm = password !== value;
          inputTouched.passwordConfirm = true;
          break;
        }
        default:
          break;
      }
    },
    [
      inputErrors,
      inputTouched,
      password,
      passwordConfirm,
      setEmail,
      setPassword,
      setPasswordConfirm,
    ]
  );

  useEffect(() => {
    if (Object.values(inputErrors).every((error) => !error)) {
      setInputHasError(false);
    } else {
      setInputHasError(true);
    }
  }, [
    inputErrors,
    inputErrors.email,
    inputErrors.password,
    inputErrors.passwordConfirm,
    setInputHasError,
  ]);

  const portalName = isSuperStoreSite() ? 'portal' : 'ShopCentre';

  return (
    <>
      <VendorSignUpHeader
        screen={VendorRegistrationScreen.LOGIN_DETAILS}
        setInputHasError={setInputHasError}
        setScreen={setScreen}
      />
      <div className="px-5 pt-5">
        <div className={cx(styles.wrapper)}>
          <p className={cx(styles.text)}>
            Enter the email you wish to use to login and create a password to log into your
            {portalName} to start uploading your products.
          </p>
          <h5 className={cx(styles.title)}>Create Login</h5>
          <Form>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label className="sss-form-subtitle">
                    Email <CustomTooltip text="This email will be used for order notifications" />
                  </Label>
                  <Input type="text" value={email} name="email" onChange={onInputChange} />
                  {inputErrors.email && inputTouched.email && (
                    <FormInputError>{INPUT_ERROR_MESSAGE.EMAIL}</FormInputError>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label className="sss-form-subtitle">Create Password</Label>
                  <Input
                    type="password"
                    value={password}
                    name="password"
                    onChange={onInputChange}
                    autoComplete="new-password"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="sss-form-subtitle">Repeat Password</Label>
                  <Input
                    type="password"
                    value={passwordConfirm}
                    name="passwordConfirm"
                    onChange={onInputChange}
                  />
                  {inputErrors.passwordConfirm && inputTouched.passwordConfirm && (
                    <FormInputError>{INPUT_ERROR_MESSAGE.PASSWORD_MATCH}</FormInputError>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <RequirementsList password={password} passwordTouched={inputTouched.password} />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <VendorSignUpFooter
        screen={VendorRegistrationScreen.LOGIN_DETAILS}
        showError={inputHasError && submitTouched}
        goBack={() => setScreen(VendorRegistrationScreen.CONTRACT)}
        goNext={() => {
          setSubmitTouched(true);
          if (!inputHasError) {
            onSubmit();
          }
        }}
      />
    </>
  );
};

export default LoginDetails;
