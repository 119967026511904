import { SortOrder } from 'react-bootstrap-table-next';
import { SortOrderDirection } from '../../global/types';
import { PayoutsSortOrder, SortablePayoutsColumn } from './types';

export const mapSortOrder = (
  columnName: SortablePayoutsColumn,
  sortDirection: SortOrderDirection
): PayoutsSortOrder => {
  return sortDirection === SortOrderDirection.asc
    ? PayoutsSortOrder[columnName]
    : (`-${columnName}` as PayoutsSortOrder);
};

export const mapTableSort = (
  sortOrder: PayoutsSortOrder
): { dataField: PayoutsSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as PayoutsSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};
