import produce from 'immer';
import { Reducer } from 'redux';
import { NormalizedError } from '../../types';
import {
  ForgottenPasswordApiFailureAction,
  ForgottenPasswordApiRequestAction,
  ForgottenPasswordApiSuccessAction,
} from './actions';
import {
  FORGOTTEN_PASSWORD_API_FAILURE,
  FORGOTTEN_PASSWORD_API_REQUEST,
  FORGOTTEN_PASSWORD_API_SUCCESS,
} from './constants';

export type ForgottenPasswordPageState = {
  error: NormalizedError | null;
};

const initialState: ForgottenPasswordPageState = {
  error: null,
};

type ForgottenPasswordPageActions =
  | ForgottenPasswordApiFailureAction
  | ForgottenPasswordApiRequestAction
  | ForgottenPasswordApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const forgottenPasswordPageReducer: Reducer<
  ForgottenPasswordPageState,
  ForgottenPasswordPageActions
> = (state = initialState, action: ForgottenPasswordPageActions): ForgottenPasswordPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case FORGOTTEN_PASSWORD_API_REQUEST: {
        draft.error = null;
        break;
      }

      case FORGOTTEN_PASSWORD_API_SUCCESS: {
        break;
      }

      case FORGOTTEN_PASSWORD_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
