import produce from 'immer';
import { denormalize } from 'normalizr';
import { Reducer } from 'redux';
import { PayoutEntity, PayoutSchema } from '../../../schemas';
import {
  PayoutsFetchApiFailureAction,
  PayoutsFetchApiRequestAction,
  PayoutsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import { PAYOUTS_FETCH_API_SUCCESS } from '../constants';
import { FormItem, FormItems, PayoutsSearchState } from '../types';

export const initialSearchState: PayoutsSearchState = {
  allIds: null,
  formItems: {},
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const payoutToFormItem = (payout: PayoutEntity): FormItem => {
  return {
    customAmount: null,
    memo: '',
  };
};

export type PayoutsSearchAction =
  | PayoutsFetchApiFailureAction
  | PayoutsFetchApiRequestAction
  | PayoutsFetchApiSuccessAction;

export const payoutsSearchReducer: Reducer<PayoutsSearchState, PayoutsSearchAction> = (
  state: PayoutsSearchState = initialSearchState,
  action: PayoutsSearchAction
): PayoutsSearchState =>
  produce(state, (draft) => {
    switch (action.type) {
      case PAYOUTS_FETCH_API_SUCCESS: {
        const { entities, result } = action.payload.response;
        const { ids } = result;
        draft.allIds = ids;

        const payouts: PayoutEntity[] = result.payouts.map((itemId) =>
          denormalize(itemId, PayoutSchema, entities)
        );

        draft.formItems = payouts.reduce((acc, payout) => {
          // eslint-disable-next-line no-param-reassign
          acc[payout.id] = payoutToFormItem(payout);
          return acc;
        }, {} as FormItems);

        break;
      }

      default:
        return draft;
    }
    return draft;
  });
