import cx from 'classnames';
import React, { FC } from 'react';
import { CollectionProductsOrder } from '../../schemas';
import styles from './ProductsOrderBadge.module.scss';

interface BadgeConf {
  styleColor: string;
  text: string;
}

const PRODUCTS_ORDERS: { [key in CollectionProductsOrder]?: BadgeConf } = {
  [CollectionProductsOrder.AlphaAsc]: { styleColor: 'AlphaAsc', text: 'Product title A-Z' },
  [CollectionProductsOrder.AlphaDesc]: { styleColor: 'AlphaDesc', text: 'Product title Z-A' },
  [CollectionProductsOrder.BestSelling]: { styleColor: 'BestSelling', text: 'Best Selling' },
  [CollectionProductsOrder.CreatedDesc]: { styleColor: 'CreatedDesc', text: 'Newest' },
  [CollectionProductsOrder.Created]: { styleColor: 'Created', text: 'Oldest' },
  [CollectionProductsOrder.Manual]: { styleColor: 'Manual', text: 'Manual' },
  [CollectionProductsOrder.PriceAsc]: { styleColor: 'PriceAsc', text: 'Lowest Price' },
  [CollectionProductsOrder.PriceDesc]: { styleColor: 'PriceDesc', text: 'Highest Price' },
};

const NO_INFO_STATUS: BadgeConf = {
  styleColor: 'NoInfo',
  text: 'No Info',
};

type Props = {
  className?: string;
  status: CollectionProductsOrder;
};

const ProductsOrderBadge: FC<Props> = ({ className, status }: Props) => {
  const conf = PRODUCTS_ORDERS[status] || NO_INFO_STATUS;
  const styleColor = `status${conf.styleColor}`;
  const { text } = conf;

  return (
    <span
      className={cx(
        'rounded-pill text-center text-capitalize py-1 px-3',
        styles.ProductsOrderBadge,
        styles[styleColor],
        className
      )}
    >
      {text}
    </span>
  );
};

export default ProductsOrderBadge;
