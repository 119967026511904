import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  PASSWORD_RESET_API_FAILURE,
  PASSWORD_RESET_API_REQUEST,
  PASSWORD_RESET_API_SUCCESS,
  PASSWORD_RESET_TRIGGER,
} from '../constants';

// action types

export interface PasswordResetTriggerAction {
  type: typeof PASSWORD_RESET_TRIGGER;
  payload: {
    uid: string;
    token: string;
    password: string;
    passwordChangedCallback(): void;
  };
}

export interface PasswordResetApiRequestAction extends APIRequestAction {
  type: typeof PASSWORD_RESET_API_REQUEST;
}

export interface PasswordResetNormalizedResponse extends NormalizedResponse {
  readonly result: Record<string, never>;
}

export interface PasswordResetApiSuccessAction extends APISuccessAction {
  type: typeof PASSWORD_RESET_API_SUCCESS;
  payload: {
    response: PasswordResetNormalizedResponse;
  };
}

export interface PasswordResetApiFailureAction extends APIFailureAction {
  type: typeof PASSWORD_RESET_API_FAILURE;
}

// action creators

export const passwordResetTrigger = (
  uid: string,
  token: string,
  password: string,
  passwordChangedCallback: () => void
): PasswordResetTriggerAction => ({
  type: PASSWORD_RESET_TRIGGER,
  payload: { uid, token, password, passwordChangedCallback },
});

export const passwordResetApiRequest = (): PasswordResetApiRequestAction => ({
  type: PASSWORD_RESET_API_REQUEST,
});

export const passwordResetApiSuccess = (
  response: PasswordResetNormalizedResponse
): PasswordResetApiSuccessAction => ({
  type: PASSWORD_RESET_API_SUCCESS,
  payload: { response },
});

export const passwordResetApiFailure = (error: NormalizedError): PasswordResetApiFailureAction => ({
  type: PASSWORD_RESET_API_FAILURE,
  payload: { error },
});
