import cx from 'classnames';
import React, { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import ProductStatusBadge from '../../../../components/ProductStatusBadge/ProductStatusBadge';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { PRODUCT_URL } from '../../../../global/urls';
import { decodeImageSrc, formatDateShort, formatPrice } from '../../../../helpers/utils';
import {
  BriefProductEntity,
  ProductImageEntity,
  ProductStatus,
  ShopEntity,
} from '../../../../schemas';
import ProductActionsDropdown from '../ProductActionsDropdown/ProductActionsDropdown';
import styles from './formatters.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const imageFormatter = (image: ProductImageEntity | null): ReactElement => (
  <CenterCropImg
    alt="product"
    className="rounded"
    height={32}
    src={decodeImageSrc(image)}
    width={32}
  />
);

export const nameFormatter = (name: string, row: BriefProductEntity): ReactElement => {
  const { numVariants, sku } = row;
  return (
    <div className={styles.name}>
      {name ? (
        <Link
          className={styles.name__link}
          to={generatePath(PRODUCT_URL, { productId: row.productId })}
        >
          <div className={cx(tableStyles.textDark, styles.name__link__name)}>{name}</div>
          <div className={cx(tableStyles.textSecondary)}>
            {numVariants > 1 ? `${numVariants} variants` : sku}
          </div>
        </Link>
      ) : null}
    </div>
  );
};

export const inventoryFormatter = (amount: number): ReactElement => {
  if (amount === undefined) return <></>;
  return <span>{amount} in stock</span>;
};

export const buyPriceFormatter = (
  unused: unknown,
  row: BriefProductEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { maxVendorPrice, minVendorPrice } = row;
  if (minVendorPrice === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark)}>
      {minVendorPrice !== maxVendorPrice
        ? `${formatPrice(minVendorPrice, currency)} - ${formatPrice(maxVendorPrice, currency)}`
        : formatPrice(minVendorPrice, currency)}
    </span>
  );
};

export const priceFormatter = (
  unused: unknown,
  row: BriefProductEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const { maxPrice, minPrice } = row;
  if (minPrice === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark)}>
      {minPrice !== maxPrice
        ? `${formatPrice(minPrice, currency)} - ${formatPrice(maxPrice, currency)}`
        : formatPrice(minPrice, currency)}
    </span>
  );
};

export const statusFormatter = (status: ProductStatus): ReactElement => {
  return <ProductStatusBadge status={status} />;
};

export const dateFormatter = (
  datetimeStr: string,
  row: BriefProductEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const currency = formatExtraData.shop?.currency;
  return <span className="text-nowrap">{formatDateShort(datetimeStr, currency)}</span>;
};

export const actionsFormatter = (unused: unknown, product: BriefProductEntity): ReactElement => (
  <ProductActionsDropdown product={product} />
);
