import { faTrash } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import React, { FC } from 'react';
import { Col, Input, Row } from 'reactstrap';
import ButtonIcon from '../../../../components/ButtonIcon/ButtonIcon';
import MultipleInput from '../../../../components/MultipleInput/MultipleInput';
import { ProductOption } from '../../types';

type Props = {
  addOptionValue: (id: number | string, value: string) => void;
  options: ProductOption[];
  removeOption: (id: number | string) => void;
  removeOptionValue: (id: number | string, value: string) => void;
  setOption: (id: number | string, name: string) => void;
  disabled?: boolean;
};

const ProductOptions: FC<Props> = ({
  addOptionValue,
  options,
  removeOption,
  removeOptionValue,
  setOption,
  disabled,
}: Props) => {
  const makeOptions = () =>
    options.map((option) => (
      <Row key={option.id} className={cx('mb-3 text-capitalize')}>
        <Col lg={3}>
          <Input
            value={option.name}
            onChange={(e) => {
              setOption(option.id, e.target.value);
            }}
            disabled={disabled}
          />
        </Col>
        <Col lg={9}>
          <div className="d-flex">
            <MultipleInput
              className="flex-grow-1"
              name={`option-${option.id}`}
              submittedValues={option.values}
              addTag={(value) => {
                if (!option.values.includes(value)) addOptionValue(option.id, value);
              }}
              removeTag={(value) => removeOptionValue(option.id, value)}
              disabled={disabled}
            />
            <ButtonIcon
              outline
              icon={faTrash}
              color="secondary"
              className="border-0 d-block ml-2"
              onClick={() => removeOption(option.id)}
              disabled={disabled}
            />
          </div>
        </Col>
      </Row>
    ));
  return <>{makeOptions()}</>;
};

export default ProductOptions;
