import cx from 'classnames';
import React, { FC } from 'react';
import Pagination from 'react-js-pagination';
import styles from './CollectionsPagination.module.scss';

type Props = {
  activePage: number;
  displayedRange: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
  totalPages: number;
};

const CollectionsPagination: FC<Props> = ({
  activePage,
  displayedRange,
  itemsPerPage,
  onPageChange,
  totalPages,
}: Props) => (
  <Pagination
    activePage={activePage}
    itemsCountPerPage={itemsPerPage}
    onChange={onPageChange}
    pageRangeDisplayed={displayedRange}
    totalItemsCount={totalPages}
    itemClass={cx(styles.paginationItem, 'ml-2 user-select-none')}
    linkClass={cx(styles.paginationLink)}
    activeLinkClass={cx(styles.paginationActiveLink)}
    activeClass={cx(styles.paginationActiveItem)}
    disabledClass={cx(styles.paginationDisabledItem)}
  />
);

export default CollectionsPagination;
