import React, { FC, useMemo } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';
import { WarehouseProductEntity } from '../../../../schemas';
import useWarehouseTableColumns from '../../hooks/useWarehouseTableColumns';
import { WarehouseSortOrder } from '../../types';
import { mapTableSort } from '../../utils';
import WarehouseProduct from '../WarehouseProduct/WarehouseProduct';

type Props = {
  products: WarehouseProductEntity[] | null;
  pageSize: number;
  sortOrder: WarehouseSortOrder;
  selectRow: SelectRowProps<WarehouseProductEntity>;
};

const WarehouseGrid: FC<Props> = ({ products, pageSize, sortOrder, selectRow }: Props) => {
  const { columns, loadingColumns } = useWarehouseTableColumns();

  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);

  return (
    <div className="d-flex flex-wrap">
      {products &&
        products.map((product) => {
          return <WarehouseProduct key={product.id} product={product} />;
        })}
    </div>
  );

  // return (
  //   <FalconTable
  //     columns={products !== null ? columns : loadingColumns}
  //     data={data}
  //     keyField="id"
  //     selectRow={selectRow}
  //     sort={bootstrapTableSort}
  //     onTableChange={() => {}}
  //     remote={{
  //       filter: true,
  //       pagination: true,
  //       sort: true,
  //     }}
  //   />
  // );
};

export default WarehouseGrid;
