import produce from 'immer';
import { Reducer } from 'redux';
import {
  ShippingPoliciesFetchApiFailureAction,
  ShippingPoliciesFetchApiRequestAction,
  ShippingPoliciesFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  SHIPPING_POLICIES_FETCH_API_FAILURE,
  SHIPPING_POLICIES_FETCH_API_REQUEST,
  SHIPPING_POLICIES_FETCH_API_SUCCESS,
} from '../constants';
import { ShippingPoliciesQueryState } from '../types';

export const initialQueryState: ShippingPoliciesQueryState = {
  count: 0,
  error: null,
  loaded: false,
  shippingPolicyIds: null,
};

export type ShippingPoliciesQueryActions =
  | ShippingPoliciesFetchApiFailureAction
  | ShippingPoliciesFetchApiRequestAction
  | ShippingPoliciesFetchApiSuccessAction;

export const shippingPoliciesQueryReducer: Reducer<
  ShippingPoliciesQueryState,
  ShippingPoliciesQueryActions
> = (
  state: ShippingPoliciesQueryState = initialQueryState,
  action: ShippingPoliciesQueryActions
): ShippingPoliciesQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SHIPPING_POLICIES_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case SHIPPING_POLICIES_FETCH_API_SUCCESS: {
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.shippingPolicyIds = action.payload.response.result.shippingPolicies;
        break;
      }

      case SHIPPING_POLICIES_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
