import cx from 'classnames';
import React, { FC, SetStateAction } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { SCREEN_STEPS } from '../../constants';
import { VendorRegistrationScreen } from '../../types';
import styles from './VendorSignUpHeader.module.scss';

type NavigationHeaderProps = {
  screen: VendorRegistrationScreen;
  setInputHasError: (hasError: boolean) => void;
  setScreen: React.Dispatch<SetStateAction<VendorRegistrationScreen>>;
};

const VendorSignUpHeader: FC<NavigationHeaderProps> = ({
  screen,
  setInputHasError,
  setScreen,
}: NavigationHeaderProps) => {
  const currentStep = SCREEN_STEPS.findIndex((item) => item.screen === screen);
  return (
    <div className={cx('theme-wizard d-flex align-items-center w-100', styles.wrapper)}>
      <Nav className="justify-content-center w-100">
        {SCREEN_STEPS.map((screenStep, step) => (
          <NavItem key={screenStep.screen}>
            <NavLink
              className={cx('font-weight-semi-bold', {
                'done cursor-pointer': currentStep > step,
                active: currentStep === step,
              })}
              onClick={() => {
                if (step < currentStep) {
                  setScreen(screenStep.screen);
                  setInputHasError(false);
                }
              }}
            >
              <span className="nav-item-circle-parent">
                <span className="nav-item-circle">
                  <span>{step + 1}</span>
                </span>
              </span>
              <span className={cx('d-none d-md-block mt-1 fs--1', styles.text)}>
                {screenStep.title}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
  );
};

export default VendorSignUpHeader;
