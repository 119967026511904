import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../init/rootReducer';
import {
  productSetShippingAdditionalItem,
  productSetShippingBaseFee,
  productToggleCollectionItem,
  productTogglePhysicalProduct,
  productToggleShippingAvailable,
  productToggleShippingFree,
} from '../actions/stores.actions';
import { getProductState } from '../selectors';
import { ProductCollection, ProductShippingPolicy } from '../types';
import useProductParams from './useProductParams';

type ProductStoresData = {
  collections: ProductCollection[];
  shipping: ProductShippingPolicy[];
  isPhysicalProduct: boolean;
  storeActions: {
    toggleShippingAvailable(shippingType: string): void;
    toggleShippingFree(shippingType: string): void;
    setShippingBaseFee(shippingType: string, value: string): void;
    setShippingAdditionalItem(shippingType: string, value: string): void;
    toggleCollectionItem(id: number): void;
  };
  togglePhysicalProduct(): void;
};

const useProductStoresData = (): ProductStoresData => {
  const dispatch = useDispatch();
  const { productId } = useProductParams();
  const { collections, shipping, isPhysicalProduct } = useSelector((state: AppState) =>
    getProductState(state, productId)
  );
  const storeActions = {
    toggleShippingAvailable: (shippingType: string) =>
      dispatch(productToggleShippingAvailable(productId, shippingType)),
    toggleShippingFree: (shippingType: string) =>
      dispatch(productToggleShippingFree(productId, shippingType)),
    setShippingBaseFee: (shippingType: string, value: string) =>
      dispatch(productSetShippingBaseFee(productId, shippingType, value)),
    setShippingAdditionalItem: (shippingType: string, value: string) =>
      dispatch(productSetShippingAdditionalItem(productId, shippingType, value)),
    toggleCollectionItem: (id: number) => dispatch(productToggleCollectionItem(productId, id)),
  };
  return {
    collections,
    shipping,
    isPhysicalProduct,
    storeActions,
    togglePhysicalProduct: () => dispatch(productTogglePhysicalProduct(productId)),
  };
};

export default useProductStoresData;
