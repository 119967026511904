import { SagaIterator } from '@redux-saga/core';
import { all, takeLatest } from 'redux-saga/effects';
import { DASHBOARD_CHARTS_API_URL } from '../../global/urls';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { SessionSchema } from '../../schemas';
import { fetchDashboardChartsSlice } from './slices';

const fetchDashboardChartsSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: DASHBOARD_CHARTS_API_URL,
  responseSchema: {
    session: SessionSchema,
  },
  actions: fetchDashboardChartsSlice.actions,
});

export default function* sagas(): SagaIterator {
  yield all([takeLatest(fetchDashboardChartsSlice.actions.trigger, fetchDashboardChartsSaga)]);
}
