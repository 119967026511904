import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  EntitiesState,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  SHIPPING_POLICIES_SUBMIT_API_FAILURE,
  SHIPPING_POLICIES_SUBMIT_API_REQUEST,
  SHIPPING_POLICIES_SUBMIT_API_SUCCESS,
  SHIPPING_POLICIES_SUBMIT_TRIGGER,
} from '../constants';
import { ShippingPoliciesSubmitValues } from '../types';

// action types

type ShippingPoliciesSubmitPayload = {
  shippingPolicyId: number;
  values: ShippingPoliciesSubmitValues;
};

export interface ShippingPoliciesSubmitNormalizedResponse extends NormalizedResponse {
  readonly entities: EntitiesState;
  readonly result: {
    shippingPolicy: number;
  };
}

export interface ShippingPoliciesSubmitApiRequestAction extends APIRequestAction {
  type: typeof SHIPPING_POLICIES_SUBMIT_API_REQUEST;
  payload: ShippingPoliciesSubmitPayload;
}

export type ShippingPoliciesSubmitTriggerAction = {
  type: typeof SHIPPING_POLICIES_SUBMIT_TRIGGER;
  payload: ShippingPoliciesSubmitPayload;
};

export interface ShippingPoliciesSubmitApiSuccessAction extends APISuccessAction {
  type: typeof SHIPPING_POLICIES_SUBMIT_API_SUCCESS;
  payload: ShippingPoliciesSubmitPayload & {
    response: ShippingPoliciesSubmitNormalizedResponse;
  };
}

export interface ShippingPoliciesSubmitApiFailureAction extends APIFailureAction {
  type: typeof SHIPPING_POLICIES_SUBMIT_API_FAILURE;
  payload: ShippingPoliciesSubmitPayload & {
    error: NormalizedError;
  };
}

// action creators

export const shippingPoliciesSubmitTrigger = (
  shippingPolicyId: number,
  values: ShippingPoliciesSubmitValues
): ShippingPoliciesSubmitTriggerAction => ({
  type: SHIPPING_POLICIES_SUBMIT_TRIGGER,
  payload: {
    values,
    shippingPolicyId,
  },
});

export const shippingPoliciesSubmitApiRequest = (
  shippingPolicyId: number,
  values: ShippingPoliciesSubmitValues
): ShippingPoliciesSubmitApiRequestAction => ({
  type: SHIPPING_POLICIES_SUBMIT_API_REQUEST,
  payload: { values, shippingPolicyId },
});

export const shippingPoliciesSubmitApiSuccess = (
  shippingPolicyId: number,
  values: ShippingPoliciesSubmitValues,
  response: ShippingPoliciesSubmitNormalizedResponse
): ShippingPoliciesSubmitApiSuccessAction => ({
  type: SHIPPING_POLICIES_SUBMIT_API_SUCCESS,
  payload: { response, values, shippingPolicyId },
});

export const shippingPoliciesSubmitApiFailure = (
  shippingPolicyId: number,
  values: ShippingPoliciesSubmitValues,
  error: NormalizedError
): ShippingPoliciesSubmitApiFailureAction => ({
  type: SHIPPING_POLICIES_SUBMIT_API_FAILURE,
  payload: { error, values, shippingPolicyId },
});
