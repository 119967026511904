import { NormalizedError } from '../../types';
import {
  CollectionsFetchApiFailureAction,
  CollectionsFetchApiRequestAction,
  CollectionsFetchApiSuccessAction,
  CollectionsFetchNormalizedResponse,
  CollectionsFetchTriggerAction,
} from './actionTypes';
import {
  COLLECTIONS_FETCH_API_FAILURE,
  COLLECTIONS_FETCH_API_REQUEST,
  COLLECTIONS_FETCH_API_SUCCESS,
  COLLECTIONS_FETCH_TRIGGER,
} from './constants';
import { CollectionsSortOrder } from './types';

export const collectionsFetchTrigger = (
  sort: CollectionsSortOrder,
  start: number,
  search: string
): CollectionsFetchTriggerAction => ({
  type: COLLECTIONS_FETCH_TRIGGER,
  payload: {
    search,
    sort,
    start,
  },
});

export const collectionsFetchApiRequest = (
  sort: CollectionsSortOrder,
  start: number,
  search: string
): CollectionsFetchApiRequestAction => ({
  type: COLLECTIONS_FETCH_API_REQUEST,
  payload: { search, sort, start },
});

export const collectionsFetchApiSuccess = (
  sort: CollectionsSortOrder,
  start: number,
  search: string,
  response: CollectionsFetchNormalizedResponse
): CollectionsFetchApiSuccessAction => ({
  type: COLLECTIONS_FETCH_API_SUCCESS,
  payload: { response, search, sort, start },
});

export const collectionsFetchApiFailure = (
  sort: CollectionsSortOrder,
  start: number,
  search: string,
  error: NormalizedError
): CollectionsFetchApiFailureAction => ({
  type: COLLECTIONS_FETCH_API_FAILURE,
  payload: { error, search, sort, start },
});
