import produce from 'immer';
import { Reducer } from 'redux';
import { NormalizedError } from '../../../types';
import {
  LoginAsFetchApiFailureAction,
  LoginAsFetchApiRequestAction,
  LoginAsFetchApiSuccessAction,
} from '../actions/fetchActions';
import {
  LOGIN_AS_FETCH_API_FAILURE,
  LOGIN_AS_FETCH_API_REQUEST,
  LOGIN_AS_FETCH_API_SUCCESS,
} from '../constants';

export type LoginAsQueryState = {
  error: NormalizedError | null;
  userIds: number[] | null;
};

export const initialQueryState: LoginAsQueryState = {
  error: null,
  userIds: null,
};

type QueryAction =
  | LoginAsFetchApiFailureAction
  | LoginAsFetchApiRequestAction
  | LoginAsFetchApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const queryReducer: Reducer<LoginAsQueryState, QueryAction> = (
  state = initialQueryState,
  action: QueryAction
): LoginAsQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN_AS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case LOGIN_AS_FETCH_API_SUCCESS: {
        draft.userIds = action.payload.response.result.users;
        break;
      }

      case LOGIN_AS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
