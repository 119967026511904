import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import CollectionsMainContent from './components/CollectionsMainContent/CollectionsMainContent';

const Collections: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <CollectionsMainContent key={key} />;
};

export default Collections;
