import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import CenteredWindow from '../../components/CenteredWindow/CenteredWindow';
import MainLogo from '../../components/MainLogo/MainLogo';
import styles from './ErrorPage.module.scss';

type Props = {
  message?: ReactNode | string;
  linkTo?: string;
};

const DefaultErrorMessage = (
  <p>
    Make sure the address is correct and that the page hasn&apos;t moved. If you think this is a
    mistake,
    <a href="mailto:info@exmaple.com" className="ml-1">
      contact us
    </a>
    .
  </p>
);

const ErrorPage: FC<Props> = ({ linkTo = '/', message = DefaultErrorMessage }: Props) => (
  <CenteredWindow>
    <div>
      <div className="mb-4">
        <MainLogo />
      </div>
      <Card className={cx('text-center', styles.wrapper)}>
        <CardBody className="p-5">
          <div className={cx(styles.errorCode)}>404</div>
          <p className={cx(' mt-4 text-sans-serif font-weight-semi-bold', styles.title)}>
            The page you&apos;re looking for is not found.
          </p>
          <hr />
          {message}
          <div>
            <Link className="btn btn-primary btn-sm mt-3" to={linkTo}>
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              Take me home
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  </CenteredWindow>
);

export default ErrorPage;
