import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import ShopSelector from '../ShopSelector/ShopSelector';
import styles from './MainHeader.module.scss';
import CogDropdown from './components/CogDropdown/CogDropdown';

const MainHeader: FC = () => {
  return (
    <header className={cx(styles.MainHeader, 'main-container-header d-flex flex-row')}>
      <div className="d-flex justify-content-between align-items-center w-100 pl-5">
        <div>{/* <HeaderBreadcrumb /> */}</div>
        <div className={cx('d-flex flex-row align-items-center')}>
          {/* <NotificationsDropdown className="mr-4" /> */}
          <ShopSelector />
          <a
            className={cx(styles.MainHeader__navBarIcon, 'ml-3')}
            href="https://docs.google.com/document/d/1JHmpwNWn3TQ7y7xqxoKAMIgCYSnu_ZTW9jUA2QtfKkI"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
          </a>
          <CogDropdown className="ml-1" />
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
