import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomTooltip from '../../../../../../components/CustomTooltip/CustomTooltip';
import FormInputError from '../../../../../../components/FormInputError/FormInputError';
import { getAvailableCountries } from '../../../../../../global/countries/countries';
import { statesByCountries } from '../../../../../../global/countries/states';
import { INPUT_ERROR_MESSAGE } from '../../../../../../global/general/constants';
import { validateValueRequired } from '../../../../../../helpers/validators';
import useVendorRegistrationBusinessData from '../../../../hooks/useVendorRegistrationBusinessData';
import { transformPostCode } from '../../../../utils';

type Props = {
  setBusinessInputErrors: Dispatch<SetStateAction<{ general: boolean; registered: boolean }>>;
  submitTouched: boolean;
};

const RegisteredAddress: FC<Props> = ({ setBusinessInputErrors, submitTouched }: Props) => {
  const countries = useMemo(() => getAvailableCountries(), []);
  const {
    registeredAddress: { addressLine1, addressLine2, addressState, city, country, postcode },
    registeredAddressActions: {
      setAddressLine1,
      setAddressLine2,
      setAddressState,
      setCity,
      setCountry,
      setPostcode,
    },
  } = useVendorRegistrationBusinessData();

  const states = statesByCountries[country];

  const inputTouched = useMemo(
    () => ({
      addressLine1: submitTouched,
      city: submitTouched,
      postcode: submitTouched,
    }),
    [submitTouched]
  );
  const inputErrors = useMemo(
    () => ({
      addressLine1: !validateValueRequired(addressLine1),
      city: !validateValueRequired(city),
      country: !validateValueRequired(country),
      postcode: !validateValueRequired(postcode),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onInputChange = useCallback(
    ({ target }) => {
      const { name: inputName, value } = target;
      switch (inputName) {
        case 'addressLine1': {
          setAddressLine1(value);
          inputErrors.addressLine1 = !validateValueRequired(value);
          inputTouched.addressLine1 = true;
          break;
        }
        case 'city': {
          setCity(value);
          inputErrors.city = !validateValueRequired(value);
          inputTouched.city = true;
          break;
        }
        case 'postcode': {
          const newPostcode = transformPostCode(value);
          setPostcode(newPostcode);
          inputErrors.postcode = !validateValueRequired(newPostcode);
          inputTouched.postcode = true;
          break;
        }

        default:
          break;
      }
    },
    [inputErrors, inputTouched, setAddressLine1, setCity, setPostcode]
  );

  useEffect(() => {
    if (Object.values(inputErrors).every((error) => !error)) {
      setBusinessInputErrors((prevState) => ({ ...prevState, registered: false }));
    } else {
      setBusinessInputErrors((prevState) => ({ ...prevState, registered: true }));
    }
  }, [
    inputErrors,
    inputErrors.postcode,
    inputErrors.country,
    inputErrors.city,
    inputErrors.addressLine1,
    setBusinessInputErrors,
  ]);

  return (
    <div className="pt-5">
      <h4 className="sss-form-title">
        Registered Address <CustomTooltip text="The registered address of your company" />
      </h4>
      <Row className="d-flex justify-content-between">
        <Col lg={12}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Address Line 1 <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={addressLine1} name="addressLine1" onChange={onInputChange} />
            {inputErrors.addressLine1 && inputTouched.addressLine1 && (
              <FormInputError>{INPUT_ERROR_MESSAGE.REQUIRED}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label className="sss-form-subtitle">Address Line 2</Label>
            <Input
              type="text"
              value={addressLine2}
              onChange={(event) => setAddressLine2(event.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              City <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={city} name="city" onChange={onInputChange} />
            {inputErrors.city && inputTouched.city && (
              <FormInputError>{INPUT_ERROR_MESSAGE.REQUIRED}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Zip Code or Postcode <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={postcode} name="postcode" onChange={onInputChange} />
            {inputErrors.postcode && inputTouched.postcode && (
              <FormInputError>{INPUT_ERROR_MESSAGE.REQUIRED}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Country <span className="sss-color-orange">*</span>
            </Label>
            <Input
              type="select"
              name="select"
              onChange={(e) => setCountry(e.target.value)}
              defaultValue={country}
            >
              {countries.map((cntr) => (
                <option key={cntr.abbr} value={cntr.abbr}>
                  {cntr.title}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col lg={6}>
          {states && (
            <FormGroup>
              <Label className="sss-form-subtitle">
                State <span className="sss-color-orange">*</span>
              </Label>
              <Input
                disabled={!states}
                name="select"
                onChange={(e) => setAddressState(e.target.value)}
                type="select"
                value={addressState}
              >
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RegisteredAddress;
