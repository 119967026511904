import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { PASSWORD_RESET_API_URL, PASSWORD_RESET_VERIFY_TOKEN_API_URL } from '../../global/urls';
import { apiGet, apiPost, normalizeError } from '../../helpers/api';
import {
  passwordResetApiFailure,
  passwordResetApiRequest,
  passwordResetApiSuccess,
  PasswordResetNormalizedResponse,
  PasswordResetTriggerAction,
} from './actions/passwordReset';
import {
  passwordResetVerifyTokenApiFailure,
  passwordResetVerifyTokenApiRequest,
  passwordResetVerifyTokenApiSuccess,
  PasswordResetVerifyTokenNormalizedResponse,
  PasswordResetVerifyTokenTriggerAction,
} from './actions/passwordResetVerifyToken';
import { PASSWORD_RESET_TRIGGER, PASSWORD_RESET_VERIFY_TOKEN_TRIGGER } from './constants';

function* verifyTokenSaga(action: PasswordResetVerifyTokenTriggerAction) {
  const { uid, token } = action.payload;
  yield put(passwordResetVerifyTokenApiRequest());

  try {
    // we first need to get CSRF token from Django backend
    yield* initIfNeededSaga();

    // send verify token request
    const url = stringifyUrl({
      url: PASSWORD_RESET_VERIFY_TOKEN_API_URL,
      query: { token, uid },
    });
    const response = yield call(apiGet, url);

    const normalizedResponse: PasswordResetVerifyTokenNormalizedResponse = normalize(response, {});
    yield put(passwordResetVerifyTokenApiSuccess(normalizedResponse));
  } catch (error) {
    yield put(passwordResetVerifyTokenApiFailure(normalizeError(error)));
  }
}

function* changePasswordSaga(action: PasswordResetTriggerAction) {
  const { password, passwordChangedCallback, token, uid } = action.payload;
  yield put(passwordResetApiRequest());

  try {
    // we first need to get CSRF token from Django backend
    yield* initIfNeededSaga();

    // submit password reset request
    const response = yield call(apiPost, PASSWORD_RESET_API_URL, {
      password,
      token,
      uid,
    });

    const normalizedResponse: PasswordResetNormalizedResponse = normalize(response, {});
    yield put(passwordResetApiSuccess(normalizedResponse));

    // show email sent screen
    yield call(passwordChangedCallback);
  } catch (error) {
    yield put(passwordResetApiFailure(normalizeError(error)));
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(PASSWORD_RESET_TRIGGER, changePasswordSaga),
    takeLatest(PASSWORD_RESET_VERIFY_TOKEN_TRIGGER, verifyTokenSaga),
  ]);
}
