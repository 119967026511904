import cx from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import UserName from '../../components/UserName/UserName';
import useGeneralStateData from '../../global/hooks/useGeneralStateData';
import {
  getSessionActiveShop,
  getSessionUser,
  getSessionVendor,
} from '../../global/session/selectors';
import SideMenu from '../SideMenu/SideMenu';
import styles from './LeftColumn.module.scss';
import BurgerMenuIcon from './components/BurgerMenuIcon/BurgerMenuIcon';
import GettingStarted from './components/GettingStarted/GettingStarted';
import UserLogo from './components/UserLogo/UserLogo';

const LeftColumn: FC = () => {
  const activeShop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);
  const vendor = useSelector(getSessionVendor);
  const { sideMenuCollapsed, toggleSideMenu } = useGeneralStateData();

  const [menuHovered, setMenuHovered] = useState(false);
  const isMenuClosed = useMemo(() => sideMenuCollapsed && !menuHovered, [
    menuHovered,
    sideMenuCollapsed,
  ]);

  return (
    <div
      className={cx(
        'h-100',
        styles.LeftColumn,
        isMenuClosed ? styles.LeftColumn__collapsed : styles.LeftColumn__expanded
      )}
    >
      <div className={cx(styles.LeftColumn__topBlock, 'd-flex flex-row pb-3 pt-1')}>
        <BurgerMenuIcon sideMenuCollapsed={sideMenuCollapsed} toggleSideMenu={toggleSideMenu} />
        <div className={cx(styles.LeftColumn__logoBlock, 'align-items-center d-flex flex-column')}>
          <UserLogo className="ml-3" shop={activeShop} user={user} vendor={vendor} />
          <UserName className="pt-1" />
        </div>
      </div>
      <SideMenu collapsed={isMenuClosed} setMenuHovered={setMenuHovered} />
      <div className="position-fixed" style={{ bottom: 0, marginBottom: '20px' }}>
        <GettingStarted className="position-fixed" shop={activeShop} user={user} />
      </div>
    </div>
  );
};

export default LeftColumn;
