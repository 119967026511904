import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import CustomDumbCheckbox from 'components/CustomDumbCheckbox/CustomDumbCheckbox';
import { debounce } from 'lodash';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import SimpleLoadingIndicator from '../../../../components/SimpleLoadingIndicator/SimpleLoadingIndicator';
import { decodeImageSrc } from '../../../../helpers/utils';
import { CollectionEntity, MicroProductEntity } from '../../../../schemas';
import { fetchCollectionBrowseSlice } from '../../slices';
import styles from './CollectionBrowseModal.module.scss';

interface CheckableMicroProduct extends MicroProductEntity {
  checked: boolean;
}

export interface CollectionBrowseFormValues {
  added: MicroProductEntity[];
  removedIds: number[];
}

interface Props {
  browsableProducts: MicroProductEntity[] | null;
  collection: CollectionEntity | null;
  initialSelectedProductIds: number[];
  isLoading: boolean;
  isOpen: boolean;
  onDone: (collectionId: number, values: CollectionBrowseFormValues) => void;
  toggle: () => void;
}

type ProductProps = {
  onCheck: () => void;
  product: CheckableMicroProduct;
};

const Product = ({ onCheck, product }: ProductProps) => {
  return (
    <ListGroupItem className={styles.CollectionBrowseModal__products__product}>
      <Row className="align-items-center">
        <Col lg="auto">
          <CustomDumbCheckbox checked={product.checked} onClick={onCheck} />
        </Col>
        <Col lg="auto">
          <CenterCropImg
            alt="product"
            className="rounded"
            height={32}
            src={decodeImageSrc(product.image)}
            width={32}
          />
        </Col>
        <Col>{product.name}</Col>
      </Row>
    </ListGroupItem>
  );
};

const CollectionBrowseModal: FC<Props> = ({
  browsableProducts,
  collection,
  initialSelectedProductIds,
  isLoading,
  isOpen,
  onDone,
  toggle,
}: Props) => {
  const [searchInput, setSearchInput] = useState('');
  const [realSearchInput, setRealSearchInput] = useState('');
  const [selectedIds, setSelectedIds] = useState(initialSelectedProductIds);

  const checkableProducts = useMemo(() => {
    return browsableProducts
      ? browsableProducts.map(
          (product) =>
            ({
              ...product,
              checked: selectedIds.includes(product.id),
            } as CheckableMicroProduct)
        )
      : [];
  }, [browsableProducts, selectedIds]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debounce((newSearch: string, oldSearch: string) => {
      setRealSearchInput(newSearch);
    }, 300),
    []
  );

  useEffect(() => {
    debounceSearch(searchInput, realSearchInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, realSearchInput, searchInput]);

  const dispatch = useDispatch();
  const collectionId = collection?.id || 0;

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchCollectionBrowseSlice.actions.trigger({ collectionId }));
    }
  }, [collectionId, dispatch, isOpen]);

  const trimmedSearch = realSearchInput.trim().toLowerCase();
  const filteredProducts = realSearchInput
    ? checkableProducts.filter((product) => product.name.toLowerCase().includes(trimmedSearch))
    : checkableProducts;

  const toggleProduct = (productId: number) => {
    const checked = selectedIds.includes(productId);
    const newSelectedIds = checked
      ? selectedIds.filter((id) => id !== productId)
      : [...selectedIds, productId];
    setSelectedIds(newSelectedIds);
  };

  const handleBrowseDone = () => {
    if (!collection) return;

    const oldSet = new Set(initialSelectedProductIds);
    const newSet = new Set(selectedIds);

    const addedIds = [...newSet].filter((x) => !oldSet.has(x));
    const removedIds = [...oldSet].filter((x) => !newSet.has(x));

    const products = browsableProducts || [];
    const added = addedIds
      .map((productId) => products.find((product) => product.id === productId))
      .filter((product) => product !== undefined) as MicroProductEntity[];

    const values: CollectionBrowseFormValues = { added, removedIds };
    onDone(collection ? collection.id : 0, values);
    toggle();
  };

  return (
    <Modal
      centered
      contentClassName={styles.CollectionBrowseModal__content}
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
    >
      <ModalHeader className={styles.CollectionBrowseModal__header}>
        <div className={cx(styles.CollectionBrowseModal__heading)}>Browse products</div>
      </ModalHeader>
      <ModalBody>
        <Row className={styles.CollectionBrowseModal__fieldRow}>
          <Col>
            <Input
              bsSize="sm"
              className={cx('d-block mb-1 mt-3', styles.CollectionProducts__search)}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearchInput(event.target.value)
              }
              placeholder="Search..."
              type="search"
              value={searchInput}
            />
          </Col>
        </Row>
        <Row
          className={cx(
            styles.CollectionBrowseModal__fieldRow,
            styles.CollectionBrowseModal__products
          )}
        >
          {isLoading ? (
            <SimpleLoadingIndicator className={styles.CollectionBrowseModal__products__loading} />
          ) : (
            <ListGroup className="w-100" flush>
              {filteredProducts.map((product) => {
                return (
                  <Product
                    key={product.id}
                    onCheck={() => toggleProduct(product.id)}
                    product={product}
                  />
                );
              })}
            </ListGroup>
          )}
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <PrimaryButton
              className={styles.CollectionBrowseModal__cancelBtn}
              gray
              icon={faTimes}
              onClick={toggle}
              size="large"
            >
              Cancel
            </PrimaryButton>
            <PrimaryButton
              className={styles.CollectionBrowseModal__doneBtn}
              icon={faCheck}
              size="large"
              onClick={handleBrowseDone}
              type="submit"
            >
              Done
            </PrimaryButton>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CollectionBrowseModal;
