import { createReducer } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { Action } from 'redux';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { RESET_SESSION } from '../../../global/session/constants';
import { PAYOUTS_URL } from '../../../global/urls';
import { makeQueryKey } from '../../../helpers/utils';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import {
  PayoutsFetchApiFailureAction,
  PayoutsFetchApiRequestAction,
  PayoutsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  PAYOUTS_FETCH_API_FAILURE,
  PAYOUTS_FETCH_API_REQUEST,
  PAYOUTS_FETCH_API_SUCCESS,
} from '../constants';
import { fetchVendorsBareSlice } from '../slices';
import { PayoutsPageState } from '../types';
import { payoutsQueryReducer } from './payoutsQueryReducer';
import { payoutsSearchReducer } from './payoutsSearchReducer';

const initialState: PayoutsPageState = {
  allVendors: null,
  queries: {},
  searches: {},
};

const resetState = () => initialState;

type PayoutFetchApiAction =
  | PayoutsFetchApiFailureAction
  | PayoutsFetchApiRequestAction
  | PayoutsFetchApiSuccessAction;

const payoutsFetch = (draft: PayoutsPageState, broadAction: Action) => {
  const action = broadAction as PayoutFetchApiAction;
  const { search, sort, start } = action.payload;
  const key = makeQueryKey(sort, start, search);
  draft.queries[key] = payoutsQueryReducer(draft.queries[key], action);
  draft.searches[search] = payoutsSearchReducer(draft.searches[search], action);
  return draft;
};

// eslint-disable-next-line import/prefer-default-export
export const payoutsPageReducer = createReducer(initialState, (builder) => {
  builder.addCase(PAYOUTS_FETCH_API_FAILURE, payoutsFetch);
  builder.addCase(PAYOUTS_FETCH_API_REQUEST, payoutsFetch);
  builder.addCase(PAYOUTS_FETCH_API_SUCCESS, payoutsFetch);

  builder.addCase(fetchVendorsBareSlice.actions.success, (draft, action) => {
    const { vendors } = action.payload.response.result;
    draft.allVendors = vendors;
  });

  builder.addCase(LOCATION_CHANGE, (draft, action) => {
    const { location } = (action as LocationChangeAction).payload;
    const locationMatch = matchPath(location.pathname, {
      path: PAYOUTS_URL,
      exact: true,
    });
    return locationMatch ? initialState : draft;
  });

  builder.addCase(LOGIN_API_SUCCESS, resetState);
  builder.addCase(LOGOUT_API_SUCCESS, resetState);
  builder.addCase(RESET_SESSION, resetState);
  builder.addCase(SELECT_SHOP_API_SUCCESS, resetState);
});
