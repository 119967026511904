import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../../global/selectors';
import { DASHBOARD_URL } from '../../../../global/urls';
import { isShopNetShop } from '../../../../global/utils/shopnet';
import { ShopEntity, UserEntity } from '../../../../schemas';
import styles from './GettingStarted.module.scss';

interface Props {
  className?: string;
  shop: ShopEntity | null;
  user: UserEntity | null;
}

const GettingStarted: FC<Props> = ({ className, shop, user }: Props) => {
  const { location } = useSelector(getRouterState);

  if (isShopNetShop(shop) && location.pathname === DASHBOARD_URL) {
    return (
      <a
        className={styles.GettingStarted}
        href="https://superstoresystems.com/wp-content/uploads/2023/06/Welcome-to-ShopNET-1-1.pdf"
        rel="noreferrer"
        target="_blank"
      >
        <div className={styles.GettingStarted__text1}>Getting Started ?</div>
        <div className={styles.GettingStarted__text2}>CLICK HERE</div>
      </a>
    );
  }

  return null;
};

export default GettingStarted;
