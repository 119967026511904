import { SortOrder } from 'react-bootstrap-table-next';
import { SortOrderDirection } from '../../global/types';
import { CollectionsColumn, CollectionsSortOrder } from './types';

export const mapSortOrder = (
  columnName: CollectionsColumn,
  sortDirection: SortOrderDirection
): CollectionsSortOrder => {
  if (columnName === CollectionsColumn.title) {
    return sortDirection === SortOrderDirection.asc
      ? CollectionsSortOrder.title
      : CollectionsSortOrder.titleDesc;
  }
  const columnNameDesc = `-${columnName}` as CollectionsSortOrder;
  return sortDirection === SortOrderDirection.asc
    ? CollectionsSortOrder[columnName]
    : columnNameDesc;
};

export const mapTableSort = (
  sortOrder: CollectionsSortOrder
): { dataField: CollectionsSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as CollectionsSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};
