import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGING_DISPLAYED_RANGE } from '../../constants';
import useOrdersPageData from '../../hooks/useOrdersPageData';
import { pushOrdersUrl, replaceOrdersUrl } from '../../utils';
import OrdersLeftControls from '../OrdersLeftControls/OrdersLeftControls';
import OrdersRightControls from '../OrdersRightControls/OrdersRightControls';
import OrdersTable from '../OrdersTable/OrdersTable';

const OrdersMainContent: FC = () => {
  useScrollToTop();

  const {
    areAllSelected,
    correctLocation,
    count,
    error,
    isLoading,
    loaded,
    orders,
    ordersFetchTrigger,
    ordersSelectAll,
    search,
    selectedOrders,
    sortOrder,
    start,
  } = useOrdersPageData();

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: replaceOrdersUrl,
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushOrdersUrl,
    searchInput,
    sortOrder,
    start,
  });

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Orders"
      leftControls={
        <OrdersLeftControls
          areAllSelected={areAllSelected}
          count={count}
          numItems={orders?.length}
          numSelected={selectedOrders.size}
          onSelectAll={ordersSelectAll}
        />
      }
      rightControls={
        <OrdersRightControls searchInput={searchInput} setSearchInput={setSearchInput} />
      }
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGING_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={ordersFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(orders && orders.length > 0) || loaded || !correctLocation}
      >
        {orders && orders.length > 0 ? (
          <OrdersTable orders={orders} pageSize={PAGE_SIZE} sortOrder={sortOrder} />
        ) : (
          <TableNoResults search={search} text="You don't have any orders, yet." />
        )}
      </LoadingSwitch>
    </TablePageWrapper>
  );
};

export default OrdersMainContent;
