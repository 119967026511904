import cx from 'classnames';
import React, { FC } from 'react';
import ButtonIcon, { ButtonIconProps } from '../ButtonIcon/ButtonIcon';
import styles from './HeaderButton.module.scss';

type Props = ButtonIconProps;

const HeaderButton: FC<Props> = ({ children, className, disabled, ...rest }: Props) => (
  <ButtonIcon
    {...rest}
    className={cx(styles.headerBtn, className)}
    color="sss-btn-gray"
    disabled={disabled}
    size="sm"
    transform="shrink-3 down-2"
  >
    {children}
  </ButtonIcon>
);

export default HeaderButton;
