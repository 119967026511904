import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CustomersSortOrder } from '../types';
import { replaceCustomersUrl } from '../utils';
import useCustomersQueryParams from './useCustomersQueryParams';

const useOnSortChange = (): ((newSort: CustomersSortOrder) => void) => {
  const dispatch = useDispatch();
  const { search, sortOrder, start } = useCustomersQueryParams();

  return useCallback(
    (newSort: CustomersSortOrder) => {
      if (newSort !== sortOrder) {
        replaceCustomersUrl(dispatch, { search, sort: newSort, start });
      }
    },
    [dispatch, search, sortOrder, start]
  );
};

export default useOnSortChange;
