import cx from 'classnames';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Card, Col, Row } from 'reactstrap';
import FalconCardHeader from '../../components/FalconCardHeader/FalconCardHeader';
import LoadingSwitch from '../../components/LoadingSwitch/LoadingSwitch';
import SimpleLoadingIndicator from '../../components/SimpleLoadingIndicator/SimpleLoadingIndicator';
import useRequireAuth from '../../global/hooks/useRequireAuth';
import { ProductStatus, UserRole } from '../../schemas';
import { fetchModerationListSlice } from '../Moderation/slices/moderationSlice';
import { ModerationListSortOrder } from '../Moderation/types';
import styles from './Product.module.scss';
import ModerationButtons from './components/ModerationButtons/ModerationButtons';
import ProductCollections from './components/ProductCollections/ProductCollections';
import ProductImages from './components/ProductImages/ProductImages';
import ProductInformation from './components/ProductInformation/ProductInformation';
import ProductProperties from './components/ProductProperties/ProductProperties';
import ProductShipping from './components/ProductShipping/ProductShipping';
import ProductSubmitButton from './components/ProductSubmitButton/ProductSubmitButton';
import ProductTitle from './components/ProductTitle/ProductTitle';
import ProductVariants from './components/ProductVariants/ProductVariants';
import useProductData from './hooks/useProductData';
import useProductDetailsData from './hooks/useProductDetailsData';

const Product: FC = () => {
  const {
    correctLocation,
    error,
    isDisabledVendor,
    isGiftCardProduct,
    isLoading,
    isSubmitting,
    isTouched,
    loaded,
    product,
    productId,
    isModeration,
    isModerationAllowed,
    isShopNet,
    isShopNetProduct,
    user,
    productFetchTrigger,
    productUnpublishTrigger,
    productUnarchiveTrigger,
    productPublishTrigger,
    approvePublishTrigger,
    rejectPublishTrigger,
    shop,
    shopUrl,
    enquiriesAllowed,
  } = useProductData();

  const {
    information,
    seo,
    properties,
    setInfoTitle,
    setInfoDescription,
    addProductTag,
    removeProductTag,
    setSeoTitle,
    setSeoDescription,
    setSeoUrl,
    setPropertiesAgeRestricted,
    setPropertiesCustomizable,
    setPropertiesEnquiry,
    status,
  } = useProductDetailsData();

  useRequireAuth();

  const title = isModeration ? `[IN MODERATION] ${information.title}` : information.title;
  const canSubmit =
    !status ||
    ([
      ProductStatus.Unpublished,
      ProductStatus.Draft,
      ProductStatus.Rejected,
      ProductStatus.Published,
      ProductStatus.Pending,
      ProductStatus.InReview,
    ].includes(status) &&
      (isTouched || status === ProductStatus.Draft || status === ProductStatus.Unpublished));

  const canUnpublish =
    !!status &&
    ![
      ProductStatus.Draft,
      ProductStatus.Unpublishing,
      ProductStatus.Unpublished,
      ProductStatus.Archived,
    ].includes(status);

  const canUnarchive = !isDisabledVendor && status === ProductStatus.Archived;
  const canPublish = !isDisabledVendor && !canUnarchive;

  const submitButtons = isModeration ? (
    <ModerationButtons
      approvePublishTrigger={approvePublishTrigger}
      rejectPublishTrigger={rejectPublishTrigger}
      isSubmitting={isSubmitting}
      canSubmit
    />
  ) : (
    <Row>
      {canUnpublish && (
        <Col>
          <ProductSubmitButton
            label="Unpublish"
            isSubmitting={isSubmitting}
            canSubmit={canUnpublish}
            submit={productUnpublishTrigger}
            negative
          />
        </Col>
      )}
      {canUnarchive && (
        <Col>
          <ProductSubmitButton
            label="Unarchive"
            isSubmitting={isSubmitting}
            canSubmit={canUnarchive}
            submit={productUnarchiveTrigger}
          />
        </Col>
      )}
      {canPublish && (
        <Col>
          <ProductSubmitButton
            label="Publish"
            isSubmitting={isSubmitting}
            canSubmit={canSubmit}
            submit={productPublishTrigger}
          />
        </Col>
      )}
    </Row>
  );

  const moderationListFetchTrigger = fetchModerationListSlice.actions.trigger;
  const dispatch = useDispatch();
  //
  // Hacky. Needs refactoring, same as useProductParams.ts (see latter for some explanations).
  //
  const onLoad = useCallback(() => {
    if (isModeration && !productId) {
      dispatch(
        moderationListFetchTrigger({ sort: ModerationListSortOrder.name, start: 0, search: '' })
      );
    }
    productFetchTrigger();
  }, [isModeration, productId, dispatch, productFetchTrigger, moderationListFetchTrigger]);

  if (isModeration && !isModerationAllowed) return <span>Permission denied</span>;

  /**
   * There is a requirement:
   * When a product is edited OUTSIDE of moderation (ie find in the list and open)
   * by Brand User - the same field that are allowed to edit in moderation will be allowed to edit here.
   * When product is saved it's immediately published (not going to moderation)
   */
  const productEditingModeration = isModeration || user?.role === UserRole.Brand;

  const errorDetail =
    error &&
    (error.json?.detail ||
      (error.json?.isValidationError
        ? 'An error occured. Please check the page for details.'
        : 'An error occured.'));

  return (
    <div className={styles.Product}>
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={onLoad}
        renderError={null}
        renderLoading={() => <SimpleLoadingIndicator />}
        require={!!product || loaded || !correctLocation}
      >
        <Row className={cx('rounded-pill pb-3')}>
          <Col>
            <Card className="h-lg-100 py-1">
              <FalconCardHeader
                border={false}
                className="bg-white"
                title={
                  <ProductTitle
                    isGiftCardProduct={isGiftCardProduct}
                    productId={productId}
                    title={title}
                    status={status}
                  />
                }
              >
                {submitButtons}
              </FalconCardHeader>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={8} className="pb-3">
            {error && (
              <Alert color="danger" className="small">
                {errorDetail}
              </Alert>
            )}

            <ProductInformation
              addTag={addProductTag}
              description={information.description}
              error={error}
              removeTag={removeProductTag}
              seoDescription={seo.description}
              seoTitle={seo.title}
              seoUrl={seo.url}
              setDescription={setInfoDescription}
              setSeoDescription={setSeoDescription}
              setSeoTitle={setSeoTitle}
              setSeoUrl={setSeoUrl}
              setTitle={setInfoTitle}
              shopUrl={shopUrl}
              tags={information.tags}
              title={information.title}
            />
            <ProductImages />
            <ProductVariants
              error={error}
              disabled={productEditingModeration}
              isGiftCardProduct={isGiftCardProduct}
              isShopNetProduct={isShopNetProduct}
            />
          </Col>
          <Col lg={4}>
            {!isGiftCardProduct && (
              <ProductProperties
                ageRestricted={properties.ageRestricted}
                customizable={properties.customizable}
                enquiriesAllowed={enquiriesAllowed}
                enquiry={properties.enquiry}
                setAgeRestricted={setPropertiesAgeRestricted}
                setCustomizable={setPropertiesCustomizable}
                setEnquiry={setPropertiesEnquiry}
              />
            )}
            <ProductCollections />
            {!isGiftCardProduct && !isShopNet && (
              <ProductShipping disabled={productEditingModeration} shop={shop} />
            )}
          </Col>
        </Row>

        <Card className="mb-3">
          <FalconCardHeader
            border={false}
            className="bg-white"
            title="Nice Job! You’re almost done"
          >
            {submitButtons}
          </FalconCardHeader>
        </Card>
      </LoadingSwitch>
    </div>
  );
};

export default Product;
