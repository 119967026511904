import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import OrdersMainContent from './components/OrdersMainContent/OrdersMainContent';

const Orders: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <OrdersMainContent key={key} />;
};

export default Orders;
