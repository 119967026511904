import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { generatePath } from 'react-router-dom';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SessionSectionNormalized } from '../../global/init/actions';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSession } from '../../global/session/actions';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import {
  VENDOR_REGISTRATION_CONTRACT_API_URL,
  VENDOR_REGISTRATION_INIT_API_URL,
  VENDOR_REGISTRATION_SUBMIT_API_URL,
} from '../../global/urls';
import { apiGet, apiPost, normalizeError } from '../../helpers/api';
import { handleFailureSaga } from '../../helpers/sagas';
import { SessionSchema } from '../../schemas';
import {
  VendorRegistrationContractNormalizedResponse,
  VendorRegistrationContractTriggerAction,
} from './actionTypes/contractActions';
import {
  VendorRegistrationInitNormalizedResponse,
  VendorRegistrationInitTriggerAction,
} from './actionTypes/initActions';
import {
  VendorRegistrationSubmitNormalizedResponse,
  VendorRegistrationSubmitTriggerAction,
} from './actionTypes/submitActions';
import {
  vendorRegistrationContractApiFailure,
  vendorRegistrationContractApiRequest,
  vendorRegistrationContractApiSuccess,
} from './actions/contractActions';
import {
  vendorRegistrationInitApiFailure,
  vendorRegistrationInitApiRequest,
  vendorRegistrationInitApiSuccess,
} from './actions/initActions';
import {
  vendorRegistrationSubmitApiFailure,
  vendorRegistrationSubmitApiRequest,
  vendorRegistrationSubmitApiSuccess,
} from './actions/submitActions';
import {
  VENDOR_REGISTRATION_CONTRACT_TRIGGER,
  VENDOR_REGISTRATION_INIT_TRIGGER,
  VENDOR_REGISTRATION_SUBMIT_TRIGGER,
} from './constants';
import { handleSubmitFailure } from './errors';

function* initSaga({ payload }: VendorRegistrationInitTriggerAction) {
  const { failureCallback, invitationCode } = payload;
  yield put(vendorRegistrationInitApiRequest(invitationCode));
  try {
    yield* initIfNeededSaga();
    const url = generatePath(VENDOR_REGISTRATION_INIT_API_URL, { invitationCode });
    const response = yield call(apiGet, url);
    const normalizedResponse: VendorRegistrationInitNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse, false);
    yield put(vendorRegistrationInitApiSuccess(invitationCode, normalizedResponse));
  } catch (error) {
    yield put(vendorRegistrationInitApiFailure(invitationCode, normalizeError(error)));
    yield* handleFailureSaga(error);
    yield call(failureCallback);
  }
}

function* contractSaga({ payload }: VendorRegistrationContractTriggerAction) {
  const { fields, invitationCode } = payload;
  yield put(vendorRegistrationContractApiRequest(invitationCode, fields));
  try {
    yield* initIfNeededSaga();
    const url = generatePath(VENDOR_REGISTRATION_CONTRACT_API_URL, { invitationCode });
    const response = yield call(apiPost, url, fields);
    const normalizedResponse: VendorRegistrationContractNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse, false);
    yield put(vendorRegistrationContractApiSuccess(invitationCode, fields, normalizedResponse));
  } catch (error) {
    const normalizedError = normalizeError(error);
    yield put(vendorRegistrationContractApiFailure(invitationCode, fields, normalizeError(error)));
    yield call(handleSubmitFailure, normalizedError);
  }
}

function* submitSaga({ payload }: VendorRegistrationSubmitTriggerAction) {
  const { failureCallback, fields, invitationCode, successCallback } = payload;
  yield put(vendorRegistrationSubmitApiRequest(invitationCode, fields));
  try {
    yield* initIfNeededSaga();
    const url = generatePath(VENDOR_REGISTRATION_SUBMIT_API_URL, { invitationCode });
    const response = yield call(apiPost, url, fields);
    const normalizedResponse: VendorRegistrationSubmitNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse, false);
    yield put(vendorRegistrationSubmitApiSuccess(invitationCode, fields, normalizedResponse));
    // reset session (to refresh list of shops in the shop selector)
    const session: SessionSectionNormalized = normalizedResponse?.result?.session;
    yield put(resetSession(session, normalizedResponse.entities));
    yield call(successCallback);
  } catch (error) {
    const normalizedError = normalizeError(error);
    yield put(vendorRegistrationSubmitApiFailure(invitationCode, fields, normalizedError));
    yield call(failureCallback, normalizedError);
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(VENDOR_REGISTRATION_INIT_TRIGGER, initSaga),
    takeLatest(VENDOR_REGISTRATION_CONTRACT_TRIGGER, contractSaga),
    takeLatest(VENDOR_REGISTRATION_SUBMIT_TRIGGER, submitSaga),
  ]);
}
