import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, useMemo } from 'react';
import {
  validateHasLength,
  validateHasLowercase,
  validateHasNumberOrSymbol,
  validateHasUppercase,
} from '../../../../../../helpers/validators';
import styles from '../../LoginDetails.module.scss';

type Props = {
  passwordTouched: boolean;
  password: string;
};

const RequirementsList: FC<Props> = ({ password, passwordTouched }: Props) => {
  const validationStarted = useMemo(() => passwordTouched && password.length > 0, [
    password.length,
    passwordTouched,
  ]);

  const isLengthValid = useMemo(() => validateHasLength(password, 8), [password]);
  const isLowerCaseValid = useMemo(() => validateHasLowercase(password), [password]);
  const isUpperCaseValid = useMemo(() => validateHasUppercase(password), [password]);
  const isNumberOrSymbolValid = useMemo(() => validateHasNumberOrSymbol(password), [password]);

  return (
    <ul className={styles.requirements}>
      <li className={cx(validationStarted && (isLengthValid ? styles.correct : styles.incorrect))}>
        <span
          className={cx({
            invisible: !validationStarted || (validationStarted && !isLengthValid),
          })}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <span className="ml-1">At least 8 characters in length</span>
      </li>
      <li
        className={cx(validationStarted && (isLowerCaseValid ? styles.correct : styles.incorrect))}
      >
        <span
          className={cx({
            invisible: !validationStarted || (validationStarted && !isLowerCaseValid),
          })}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <span className="ml-1">Lower case letters (a-z)</span>
      </li>
      <li
        className={cx(validationStarted && (isUpperCaseValid ? styles.correct : styles.incorrect))}
      >
        <span
          className={cx({
            invisible: !validationStarted || (validationStarted && !isUpperCaseValid),
          })}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <span className="ml-1">Upper case letters (A-Z)</span>
      </li>
      <li
        className={cx(
          validationStarted && (isNumberOrSymbolValid ? styles.correct : styles.incorrect)
        )}
      >
        <span
          className={cx({
            invisible: !validationStarted || (validationStarted && !isNumberOrSymbolValid),
          })}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <span className="ml-1">Numbers (0-9) or symbol</span>
      </li>
    </ul>
  );
};

export default RequirementsList;

// inputTouched.password &&
//                         password.length > 0 &&
//                         (validateHasUppercase(password) ? styles.correct : styles.incorrect)
