import { SagaIterator } from '@redux-saga/core';
import { schema } from 'normalizr';
import { all, takeLatest } from 'redux-saga/effects';
import { REJECTED_PRODUCTS_LIST_API_URL } from '../../global/urls';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { BriefProductSchema, SessionSchema } from '../../schemas';
import { fetchRejectedProductsSlice } from './slices';

export const RejectedModerationRequestSchema = new schema.Entity('rejectedModerationRequest', {
  productVersion: BriefProductSchema,
});

const fetchRejectedProductsSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: REJECTED_PRODUCTS_LIST_API_URL,
  responseSchema: {
    rejectedModerationRequests: [RejectedModerationRequestSchema],
    session: SessionSchema,
  },
  actions: fetchRejectedProductsSlice.actions,
});

export default function* sagas(): SagaIterator {
  yield all([takeLatest(fetchRejectedProductsSlice.actions.trigger, fetchRejectedProductsSaga)]);
}
