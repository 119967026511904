import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop } from '../../../global/session/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { ORDER_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import {
  OrderEntity,
  OrderItemEntity,
  OrderItemSchema,
  OrderSchema,
  RefundedItemEntity,
  RefundedItemSchema,
  ShipmentEntity,
  ShipmentSchema,
  ShopEntity,
} from '../../../schemas';
import { NormalizedError } from '../../../types';
import { orderFetchTrigger } from '../actions/fetch.actions';
import { orderFulfillTrigger } from '../actions/fulfill.actions';
import { orderRefundTrigger } from '../actions/refund.actions';
import { ORDER_FETCH_API, ORDER_FULFILL_API, ORDER_REFUND_API } from '../constants';
import { getOrderState } from '../selectors';
import { GroupItemsAndQuantities, ItemAndQuantity, Params } from '../types';

interface OrderPageData {
  correctLocation: boolean;
  dispatch: Dispatch;
  error: NormalizedError | null;
  isFulfilling: boolean;
  isLoading: boolean;
  isRefunding: boolean;
  loaded: boolean;
  order: OrderEntity;
  orderFetchTrigger(): void;
  orderFulfillTrigger: (items: ItemAndQuantity[], trackingCode: string) => void;
  orderItems: OrderItemEntity[];
  orderName: string;
  orderRefundTrigger: (
    items: GroupItemsAndQuantities,
    reason: string,
    shippingOnly: boolean
  ) => void;
  refundedItems: RefundedItemEntity[];
  shipments: ShipmentEntity[];
  shop: ShopEntity | null;
}

const useOrderPageData = (): OrderPageData => {
  const dispatch = useDispatch();
  const { orderName } = useParams<Params>();
  const { location } = useSelector(getRouterState);
  const match = matchPath(location.pathname, { path: ORDER_URL, exact: true });
  const correctLocation = !!match;
  const entities = useSelector(getEntitiesState);
  const shop: ShopEntity | null = useSelector(getSessionActiveShop);
  const {
    error,
    loaded,
    orderId,
    orderItemIds,
    refundedItemIds,
    shipmentIds,
  } = useSelector((state: AppState) => getOrderState(state, orderName));

  const isLoading = useSelector((state: AppState) => checkIfProcessing(state, ORDER_FETCH_API));
  const isFulfilling = useSelector((state: AppState) =>
    checkIfProcessing(state, ORDER_FULFILL_API)
  );
  const isRefunding = useSelector((state: AppState) => checkIfProcessing(state, ORDER_REFUND_API));

  const order = useMemo(() => denormalize(orderId, OrderSchema, entities), [entities, orderId]);

  const orderItems = useMemo(
    () => orderItemIds.map((orderItemId) => denormalize(orderItemId, OrderItemSchema, entities)),
    [entities, orderItemIds]
  );

  const refundedItems = useMemo(
    () =>
      refundedItemIds.map((refundedItemId) =>
        denormalize(refundedItemId, RefundedItemSchema, entities)
      ),
    [entities, refundedItemIds]
  );

  const shipments = useMemo(
    () => shipmentIds.map((shipmentId) => denormalize(shipmentId, ShipmentSchema, entities)),
    [entities, shipmentIds]
  );

  return {
    correctLocation,
    dispatch,
    error,
    isFulfilling,
    isLoading,
    isRefunding,
    loaded,
    order,
    orderFetchTrigger: () => dispatch(orderFetchTrigger(orderName)),
    orderFulfillTrigger: (items, trackingCode) =>
      dispatch(orderFulfillTrigger(orderId || 0, orderName, items, trackingCode)),
    orderItems,
    orderName,
    orderRefundTrigger: (
      groupItems: GroupItemsAndQuantities,
      reason: string,
      shippingOnly: boolean
    ) => dispatch(orderRefundTrigger(orderId || 0, orderName, groupItems, reason, shippingOnly)),
    refundedItems,
    shipments,
    shop,
  };
};

export default useOrderPageData;
