import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ShopEntity, VendorEntity } from 'schemas';
import sortCaretFunc from '../../../../global/utils/sortCaretFunc';
import { loaderFormatter } from '../../../../helpers/formatters';
import { NonSortableVendorsColumn, SortableVendorsColumn, VendorsType } from '../../types';
import {
  actionsFormatter,
  commissionFormatter,
  enquiriesFormatter,
  FormatterExtraData,
  imageFormatter,
  maxProductsFormatter,
  moderationFormatter,
  nameFormatter,
  numProductsFormatter,
  paymentFeeFormatter,
  tagsFormatter,
} from './formatters';

export const columns = (
  type: VendorsType,
  shop: ShopEntity | null
): ColumnDescription<VendorEntity, FormatterExtraData>[] => {
  const enquiriesAllowed = !!shop?.enquiriesAllowed;
  return [
    {
      dataField: NonSortableVendorsColumn.logoUrl,
      formatter: imageFormatter,
      headerStyle: () => ({ paddingLeft: 0, width: '50px' } as CSSProperties),
      style: () => ({ paddingLeft: 0 } as CSSProperties),
      text: '',
    },
    {
      dataField: SortableVendorsColumn.name,
      sort: true,
      sortCaret: sortCaretFunc,
      formatter: nameFormatter,
      text: 'Vendor name',
      headerStyle: () => ({ width: '24%' }),
    },
    {
      // not in shopnet
      dataField: SortableVendorsColumn.moderation,
      formatExtraData: { shop, type },
      formatter: moderationFormatter,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Moderation',
    },
    ...(enquiriesAllowed && type === VendorsType.Active
      ? [
          {
            dataField: SortableVendorsColumn.enquiriesAllowed,
            formatter: enquiriesFormatter,
            sort: true,
            sortCaret: sortCaretFunc,
            text: 'Enquiries',
          },
        ]
      : []),
    {
      // not in shopnet
      dataField: SortableVendorsColumn.commission,
      formatExtraData: { shop, type },
      formatter: commissionFormatter,
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Commission %',
    },
    {
      // not in shopnet
      dataField: NonSortableVendorsColumn.paymentFee,
      formatExtraData: { shop, type },
      formatter: paymentFeeFormatter,
      text: 'Payment Fee',
    },
    {
      // not in shopnet
      dataField: SortableVendorsColumn.maxProducts,
      formatExtraData: { shop, type },
      sortCaret: sortCaretFunc,
      formatter: maxProductsFormatter,
      text: 'Max. Products',
    },
    {
      // not in shopnet
      dataField: SortableVendorsColumn.tags,
      formatExtraData: { shop, type },
      sortCaret: sortCaretFunc,
      formatter: tagsFormatter,
      text: 'Tags',
    },
    ...(type === VendorsType.Active
      ? [
          {
            dataField: SortableVendorsColumn.numPublishedProducts,
            formatter: numProductsFormatter,
            sort: true,
            sortCaret: sortCaretFunc,
            text: 'Products',
          },
        ]
      : []),
    {
      dataField: '',
      formatExtraData: { shop, type },
      formatter: actionsFormatter,
      headerStyle: () => ({ width: '4%' }),
      text: '',
    },
  ];
};

export const loadingColumns = (type: VendorsType, shop: ShopEntity | null): ColumnDescription[] =>
  columns(type, shop).map((column) => ({
    ...column,
    formatter: loaderFormatter,
  }));
