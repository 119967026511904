import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';
import { alphaPageReducer, AlphaPageState } from '../containers/AlphaPage/reducer';
import {
  brandProfilePageReducer,
  BrandProfileState,
  fetchBrandProfileSlice,
} from '../containers/BrandProfile/slices';
import {
  CollectionPageState,
  pageReducer as collectionPageReducer,
} from '../containers/Collection/reducers/pageReducer';
import collectionSubmitSlice, {
  fetchCollectionBrowseSlice,
  fetchCollectionSlice,
} from '../containers/Collection/slices';
import { collectionsPageReducer } from '../containers/Collections/reducers/collectionsPageReducer';
import submitCollectionsEditSlice from '../containers/Collections/slices';
import { CollectionsPageState } from '../containers/Collections/types';
import { customersPageReducer } from '../containers/Customers/reducers/customersPageReducer';
import { CustomersPageState } from '../containers/Customers/types';
import {
  dashboardChartsDataReducer,
  DashboardChartsState,
  fetchDashboardChartsSlice,
} from '../containers/DashboardCharts/slices';
import {
  forgottenPasswordPageReducer,
  ForgottenPasswordPageState,
} from '../containers/ForgottenPassword/reducer';
import { giftCardsPageReducer } from '../containers/GiftCards/reducers/giftCardsPageReducer';
import { GiftCardsPageState } from '../containers/GiftCards/types';
import { inventoryPageReducer } from '../containers/Inventory/reducers/inventoryPageReducer';
import { importStockSlice } from '../containers/Inventory/slices';
import { InventoryPageState } from '../containers/Inventory/types';
import {
  fetchInventoryAlertsSlice,
  inventoryAlertsDataReducer,
  InventoryAlertsState,
} from '../containers/InventoryAlerts/slices';
import { loginPageReducer, LoginPageState } from '../containers/Login/reducer';
import { loginAsReducer, LoginAsState } from '../containers/LoginAs/reducers/pageReducer';
import moderationListPageReducer from '../containers/Moderation/reducers/moderationListPageReducer';
import { fetchModerationListSlice } from '../containers/Moderation/slices/moderationSlice';
import { ModerationListPageState } from '../containers/Moderation/types';
import {
  OrderPageState,
  pageReducer as orderPageReducer,
} from '../containers/Order/reducers/pageReducer';
import { ordersPageReducer as ordersPage } from '../containers/Orders/reducers/ordersPageReducer';
import { OrdersPageState } from '../containers/Orders/types';
import {
  passwordResetPageReducer,
  PasswordResetPageState,
} from '../containers/PasswordReset/reducer';
import { payoutsPageReducer } from '../containers/Payouts/reducers/payoutsPageReducer';
import { addTransactionSlice, fetchVendorsBareSlice } from '../containers/Payouts/slices';
import { PayoutsPageState } from '../containers/Payouts/types';
import { productPageReducer, ProductPageState } from '../containers/Product/reducer';
import { productUnpublishSlice } from '../containers/Product/slices';
import { productsPageReducer } from '../containers/Products/reducers/productsPageReducer';
import {
  getProductsCollectionsSlice,
  getProductsTagsSlice,
  productsCollectionsReducer,
  ProductsCollectionsState,
  productsTagsReducer,
  ProductsTagsState,
} from '../containers/Products/slices';
import { ProductsPageState } from '../containers/Products/types';
import {
  fetchRejectedProductsSlice,
  rejectedProductsDataReducer,
  RejectedProductsState,
} from '../containers/RejectedProducts/slices';
import { shippingPoliciesPageReducer } from '../containers/ShippingPolicies/reducers/shippingPoliciesPageReducer';
import { ShippingPoliciesPageState } from '../containers/ShippingPolicies/types';
import {
  createShippingTypeSlice,
  fetchShippingTypesSlice,
  shippingTypesPageSlice,
  ShippingTypesPageState,
} from '../containers/ShippingTypes/slices/shippingTypesSlice';
import {
  fetchVendorProfileSlice,
  vendorProfilePageReducer,
  VendorProfileState,
} from '../containers/VendorProfile/slices';
import { vendorRegistrationReducer } from '../containers/VendorRegistration/reducer';
import { VendorRegistrationState } from '../containers/VendorRegistration/types';
import { vendorsPageReducer } from '../containers/Vendors/reducers/vendorsPageReducer';
import { VendorsPageState } from '../containers/Vendors/types';
import { warehousePageReducer } from '../containers/Warehouse/reducers/warehousePageReducer';
import { WarehousePageState } from '../containers/Warehouse/types';
import { entitiesReducer } from '../global/entities/reducers';
import { generalReducer } from '../global/general/reducer';
import { GeneralState } from '../global/general/types';
import { sessionReducer, SessionState } from '../global/session/reducer';
import { LocationState } from '../global/types';
import { uiReducer, UiState } from '../global/ui/reducers';
import { LoadingState } from '../helpers/apiRequestSlice';
import { EntitiesState } from '../types';

interface ContainersState {
  alphaPage: AlphaPageState;
  brandProfile: BrandProfileState;
  brandProfileLoading: LoadingState;
  collection: CollectionPageState;
  collectionLoading: LoadingState;
  collectionBrowseLoading: LoadingState;
  collectionSubmitLoading: LoadingState;
  collections: CollectionsPageState;
  collectionsEditLoading: LoadingState;
  createShippingTypeLoading: LoadingState;
  customers: CustomersPageState;
  dashboardCharts: DashboardChartsState;
  dashboardChartsLoading: LoadingState;
  forgottenPassword: ForgottenPasswordPageState;
  giftCards: GiftCardsPageState;
  inventory: InventoryPageState;
  inventoryAlerts: InventoryAlertsState;
  inventoryAlertsLoading: LoadingState;
  inventoryImportStockLoading: LoadingState;
  login: LoginPageState;
  loginAs: LoginAsState;
  moderationList: ModerationListPageState;
  moderationListLoading: LoadingState;
  order: OrderPageState;
  orders: OrdersPageState;
  passwordReset: PasswordResetPageState;
  payouts: PayoutsPageState;
  payoutsVendorsBareLoading: LoadingState;
  payoutTransactionAdd: LoadingState;
  product: ProductPageState;
  productUnpublishLoading: LoadingState;
  products: ProductsPageState;
  productsCollections: ProductsCollectionsState;
  productsCollectionsLoading: LoadingState;
  productsTags: ProductsTagsState;
  productsTagsLoading: LoadingState;
  rejectedProducts: RejectedProductsState;
  rejectedProductsLoading: LoadingState;
  shippingPolicies: ShippingPoliciesPageState;
  shippingTypes: ShippingTypesPageState;
  shippingTypesLoading: LoadingState;
  vendorProfile: VendorProfileState;
  vendorProfileLoading: LoadingState;
  vendorRegistration: VendorRegistrationState;
  vendors: VendorsPageState;
  warehouse: WarehousePageState;
}

export interface AppState {
  containers: ContainersState;
  entities: EntitiesState;
  general: GeneralState;
  router: RouterState<LocationState>;
  session: SessionState;
  ui: UiState;
}

const containersReducer = combineReducers<ContainersState>({
  alphaPage: alphaPageReducer,
  brandProfile: brandProfilePageReducer,
  brandProfileLoading: fetchBrandProfileSlice.reducer,
  collection: collectionPageReducer,
  collectionLoading: fetchCollectionSlice.reducer,
  collectionBrowseLoading: fetchCollectionBrowseSlice.reducer,
  collectionSubmitLoading: collectionSubmitSlice.reducer,
  collections: collectionsPageReducer,
  collectionsEditLoading: submitCollectionsEditSlice.reducer,
  createShippingTypeLoading: createShippingTypeSlice.reducer,
  customers: customersPageReducer,
  dashboardCharts: dashboardChartsDataReducer,
  dashboardChartsLoading: fetchDashboardChartsSlice.reducer,
  forgottenPassword: forgottenPasswordPageReducer,
  giftCards: giftCardsPageReducer,
  inventory: inventoryPageReducer,
  inventoryAlerts: inventoryAlertsDataReducer,
  inventoryAlertsLoading: fetchInventoryAlertsSlice.reducer,
  inventoryImportStockLoading: importStockSlice.reducer,
  login: loginPageReducer,
  loginAs: loginAsReducer,
  moderationList: moderationListPageReducer,
  moderationListLoading: fetchModerationListSlice.reducer,
  order: orderPageReducer,
  orders: ordersPage,
  passwordReset: passwordResetPageReducer,
  payouts: payoutsPageReducer,
  payoutsVendorsBareLoading: fetchVendorsBareSlice.reducer,
  payoutTransactionAdd: addTransactionSlice.reducer,
  product: productPageReducer,
  productUnpublishLoading: productUnpublishSlice.reducer,
  products: productsPageReducer,
  productsCollections: productsCollectionsReducer,
  productsCollectionsLoading: getProductsCollectionsSlice.reducer,
  productsTags: productsTagsReducer,
  productsTagsLoading: getProductsTagsSlice.reducer,
  rejectedProducts: rejectedProductsDataReducer,
  rejectedProductsLoading: fetchRejectedProductsSlice.reducer,
  shippingPolicies: shippingPoliciesPageReducer,
  shippingTypes: shippingTypesPageSlice.reducer,
  shippingTypesLoading: fetchShippingTypesSlice.reducer,
  vendorProfile: vendorProfilePageReducer,
  vendorProfileLoading: fetchVendorProfileSlice.reducer,
  vendorRegistration: vendorRegistrationReducer,
  vendors: vendorsPageReducer,
  warehouse: warehousePageReducer,
});

export const createRootReducer = (history: History<LocationState>): Reducer<AppState> =>
  combineReducers<AppState>({
    containers: containersReducer,
    entities: entitiesReducer,
    general: generalReducer,
    router: connectRouter<LocationState>(history),
    session: sessionReducer,
    ui: uiReducer,
  });
