import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import useSelectAll from '../../../global/hooks/useSelectAll';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop, getSessionUser } from '../../../global/session/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { VENDORS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { ShopEntity, UserEntity, VendorEntity, VendorSchema } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { vendorsFetchTrigger } from '../actions/fetch.actions';
import {
  vendorsAddSelectedVendors,
  vendorsRemoveSelectedVendors,
  vendorsSelectAll,
  vendorsToggleSelectedVendor,
} from '../actions/select.actions';
import { VENDORS_FETCH_API_PREFIX } from '../constants';
import { getVendorsQueryState, getVendorsSearchState, getVendorsStateSelected } from '../selectors';
import { InviteVendorRequestPayload, inviteVendorSlice } from '../slices';
import { FormItems, VendorsSortOrder, VendorsType } from '../types';
import useVendorsQueryParams from './useVendorsQueryParams';

type VendorsData = {
  addSelectedVendors: (selectedVendors: number[]) => void;
  areAllSelected: boolean;
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  formItems: FormItems;
  inviteVendorTrigger: (payload: InviteVendorRequestPayload) => void;
  isLoading: boolean;
  loaded: boolean;
  removeSelectedVendors: (selectedVendors: number[]) => void;
  search: string;
  selectedVendors: Set<number>;
  shop: ShopEntity | null;
  sortOrder: VendorsSortOrder;
  start: number;
  toggleSelectedVendor: (id: number) => void;
  type: VendorsType;
  user: UserEntity | null;
  vendors: VendorEntity[] | null;
  vendorsFetchTrigger(): void;
  vendorsSelectAll(): void;
};

const useVendorsData = (): VendorsData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { search, sortOrder, start, type } = useVendorsQueryParams();
  const correctLocation = location.pathname === VENDORS_URL;
  const entities = useSelector(getEntitiesState);
  const user = useSelector(getSessionUser);
  const shop = useSelector(getSessionActiveShop);
  const { count, error, loaded, vendorIds } = useSelector((state: AppState) =>
    getVendorsQueryState(state, type, sortOrder, start, search)
  );
  const { allIds, formItems } = useSelector((state: AppState) =>
    getVendorsSearchState(state, type, search)
  );

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, VENDORS_FETCH_API_PREFIX)
  );

  const selectedVendors = useSelector((state: AppState) => getVendorsStateSelected(state, type));

  const vendors = useMemo(
    () =>
      vendorIds === null
        ? vendorIds
        : vendorIds.map((vendorId) => denormalize(vendorId, VendorSchema, entities)),
    [entities, vendorIds]
  );

  const { areAllSelected, effSelectedSet } = useSelectAll(allIds, selectedVendors);

  const inviteVendorTrigger = (payload: InviteVendorRequestPayload) =>
    dispatch(inviteVendorSlice.actions.trigger(payload));

  return {
    addSelectedVendors: (selectedVendorIds: number[]) =>
      dispatch(vendorsAddSelectedVendors(type, selectedVendorIds)),
    areAllSelected,
    correctLocation,
    count,
    dispatch,
    error,
    formItems,
    inviteVendorTrigger,
    isLoading,
    loaded,
    removeSelectedVendors: (selectedVendorIds: number[]) =>
      dispatch(vendorsRemoveSelectedVendors(type, selectedVendorIds)),
    search,
    selectedVendors: effSelectedSet,
    shop,
    sortOrder,
    start,
    toggleSelectedVendor: (id: number) => dispatch(vendorsToggleSelectedVendor(type, id)),
    type,
    user,
    vendors,
    vendorsFetchTrigger: () => dispatch(vendorsFetchTrigger(type, sortOrder, start, search)),
    vendorsSelectAll: () => dispatch(vendorsSelectAll(type, search)),
  };
};

export default useVendorsData;
