import { faTags } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import useToggle from '../../../../components/ModalForm/useToggle';
import { collectionsChangeTypeSlice } from '../../slices';
import CollectionsChangeTypeModal from '../CollectionsChangeType/CollectionsChangeTypeModal';

type Props = {
  disabled?: boolean;
  selectedCollections: Set<number>;
};

const CollectionsActionsDropdown: FC<Props> = ({ disabled, selectedCollections }: Props) => {
  const [changeTypeModalIsOpen, changeTypeModalToggle] = useToggle();
  const items: DropDownItemDefinition[] = [
    { text: 'Change Type', icon: faTags, onClick: changeTypeModalToggle },
  ];

  const dispatch = useDispatch();

  const collectionsChangeTypeTrigger = (managedBy: string) =>
    dispatch(
      collectionsChangeTypeSlice.actions.trigger({
        collectionIds: [...selectedCollections],
        managedBy,
      })
    );

  return (
    <>
      <DropdownMenu
        component="header"
        headerLabel="Bulk Actions"
        items={items}
        disabled={disabled}
      />
      <CollectionsChangeTypeModal
        isOpen={changeTypeModalIsOpen}
        toggle={changeTypeModalToggle}
        numSelected={selectedCollections.size}
        onSubmit={collectionsChangeTypeTrigger}
      />
    </>
  );
};

export default CollectionsActionsDropdown;
