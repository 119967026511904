import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  EntitiesState,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  VENDORS_SUBMIT_API_FAILURE,
  VENDORS_SUBMIT_API_REQUEST,
  VENDORS_SUBMIT_API_SUCCESS,
  VENDORS_SUBMIT_TRIGGER,
} from '../constants';
import { VendorsSubmitValues } from '../types';

// action types

type VendorsSubmitPayload = {
  vendorId: number;
  values: VendorsSubmitValues;
};

export interface VendorsSubmitNormalizedResponse extends NormalizedResponse {
  readonly entities: EntitiesState;
  readonly result: {
    vendor: number;
  };
}

export interface VendorsSubmitApiRequestAction extends APIRequestAction {
  type: typeof VENDORS_SUBMIT_API_REQUEST;
  payload: VendorsSubmitPayload;
}

export type VendorsSubmitTriggerAction = {
  type: typeof VENDORS_SUBMIT_TRIGGER;
  payload: VendorsSubmitPayload;
};

export interface VendorsSubmitApiSuccessAction extends APISuccessAction {
  type: typeof VENDORS_SUBMIT_API_SUCCESS;
  payload: VendorsSubmitPayload & {
    response: VendorsSubmitNormalizedResponse;
  };
}

export interface VendorsSubmitApiFailureAction extends APIFailureAction {
  type: typeof VENDORS_SUBMIT_API_FAILURE;
  payload: VendorsSubmitPayload & {
    error: NormalizedError;
  };
}

// action creators

export const vendorsSubmitTrigger = (
  vendorId: number,
  values: VendorsSubmitValues
): VendorsSubmitTriggerAction => ({
  type: VENDORS_SUBMIT_TRIGGER,
  payload: {
    values,
    vendorId,
  },
});

export const vendorsSubmitApiRequest = (
  vendorId: number,
  values: VendorsSubmitValues
): VendorsSubmitApiRequestAction => ({
  type: VENDORS_SUBMIT_API_REQUEST,
  payload: { values, vendorId },
});

export const vendorsSubmitApiSuccess = (
  vendorId: number,
  values: VendorsSubmitValues,
  response: VendorsSubmitNormalizedResponse
): VendorsSubmitApiSuccessAction => ({
  type: VENDORS_SUBMIT_API_SUCCESS,
  payload: { response, values, vendorId },
});

export const vendorsSubmitApiFailure = (
  vendorId: number,
  values: VendorsSubmitValues,
  error: NormalizedError
): VendorsSubmitApiFailureAction => ({
  type: VENDORS_SUBMIT_API_FAILURE,
  payload: { error, values, vendorId },
});
