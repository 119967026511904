import cx from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Country } from '../../global/countries/countries';
import styles from './CountrySelector.module.scss';

export type CountrySelectorProps = {
  countries: Country[];
  countryNamesFormat: 'long' | 'short';
  dropdownMenuWidth: number;
  onCountrySelect: (selectedCountryCode: string) => void;
  selectedCountryCode: string;
};

const CountrySelector: FC<CountrySelectorProps> = ({
  countries,
  countryNamesFormat,
  dropdownMenuWidth = 80,
  onCountrySelect,
  selectedCountryCode,
}: CountrySelectorProps) => {
  const selectedCountry =
    countries.find((country) => country.abbr === selectedCountryCode) || countries[0];
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const countriesAbbrAlphabetically = useMemo(
    () => Object.values(countries).sort((a, b) => a.abbr.localeCompare(b.abbr)),
    [countries]
  );

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={cx(styles.wrapper)}>
      <DropdownToggle caret className={cx(styles.selected, 'w-100')}>
        <span className="mr-1">{selectedCountry.flag}</span>
        {countryNamesFormat === 'short' ? selectedCountry.abbr : selectedCountry.title}
      </DropdownToggle>
      <DropdownMenu className={cx(styles.dropdownMenu)} style={{ width: `${dropdownMenuWidth}px` }}>
        {countriesAbbrAlphabetically.map((country) => (
          <DropdownItem
            onClick={() => {
              onCountrySelect(country.abbr);
            }}
            key={country.name}
            className={cx(styles.dropdownItem, 'd-flex justify-content-center align-items-center')}
          >
            <span className="mr-1">{country.flag}</span>{' '}
            {countryNamesFormat === 'short' ? country.abbr : country.title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CountrySelector;
