import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FalconTable from '../../../../components/FalconTable/FalconTable';
import { getSessionActiveShop, getSessionUser } from '../../../../global/session/selectors';
import { ModerationRequestEntity } from '../../../../schemas';
import { ModerationListSortOrder } from '../../types';
import { mapTableSort } from '../../utils';
import columns from './columns';

type Props = {
  moderationList: ModerationRequestEntity[] | null;
  pageSize: number;
  sortOrder: ModerationListSortOrder;
};

const ModerationList: FC<Props> = ({ moderationList, pageSize, sortOrder }: Props) => {
  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);
  const dispatch = useDispatch();
  const shop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);

  const data = useMemo(
    () =>
      moderationList !== null
        ? moderationList
        : Array.from({ length: pageSize }, (_, id) => ({ id })),
    [moderationList, pageSize]
  );

  return (
    <FalconTable
      columns={columns(dispatch, shop, user)}
      data={data}
      keyField="id"
      sort={bootstrapTableSort}
      onTableChange={() => {}}
      // remote={{
      //   filter: true,
      //   pagination: true,
      //   sort: true,
      // }}
    />
  );
};

export default ModerationList;
