import { ColumnDescription } from 'react-bootstrap-table-next';
import { ShipmentItem, ShopEntity } from '../../../../schemas';
import {
  FormatterExtraData,
  itemFormatter,
  optionsFormatter,
  priceFormatter,
  quantityFormatter,
} from './formatters';

const columns = (
  shop: ShopEntity | null
): ColumnDescription<ShipmentItem, FormatterExtraData>[] => {
  return [
    {
      dataField: 'name',
      formatter: itemFormatter,
      headerStyle: { paddingLeft: '20px' },
      style: { paddingLeft: '20px' },
      text: 'Item',
    },
    {
      dataField: 'options',
      formatter: optionsFormatter,
      headerStyle: {
        whiteSpace: 'nowrap',
        width: '15%',
      },
      text: 'Variant',
    },
    {
      align: 'right',
      dataField: 'unitPrice',
      formatExtraData: { shop },
      formatter: priceFormatter,
      headerAlign: 'right',
      headerStyle: {
        whiteSpace: 'nowrap',
        width: '12%',
      },
      text: 'Unit Price',
    },
    {
      align: 'right',
      dataField: 'quantity',
      formatter: quantityFormatter,
      headerAlign: 'right',
      headerStyle: {
        whiteSpace: 'nowrap',
        width: '20%',
      },
      text: 'QTY',
    },
    {
      align: 'right',
      dataField: 'price',
      formatExtraData: { shop },
      formatter: priceFormatter,
      headerAlign: 'right',
      headerStyle: {
        paddingRight: '40px',
        whiteSpace: 'nowrap',
        width: '25%',
      },
      style: { paddingRight: '40px' },
      text: 'Price',
    },
  ];
};

export default columns;
