import cx from 'classnames';
import React, { FC, SetStateAction, useState } from 'react';
import { SUPPORT_EMAIL } from '../../../../global/constants';
import useVendorRegistrationData from '../../hooks/useVendorRegistrationData';
import { VendorRegistrationScreen } from '../../types';
import VendorSignUpFooter from '../VendorSignUpFooter/VendorSignUpFooter';
import VendorSignUpHeader from '../VendorSignUpHeader/VendorSignUpHeader';
import styles from './Contract.module.scss';
import AgreementWindow from './components/AgreementWindow/AgreementWindow';
import ContractForm from './components/ContractForm/ContractForm';

type Props = {
  inputHasError: boolean;
  onSubmit(): void;
  setInputHasError: (hasError: boolean) => void;
  setScreen: React.Dispatch<SetStateAction<VendorRegistrationScreen>>;
};

const Contract: FC<Props> = ({ inputHasError, onSubmit, setInputHasError, setScreen }: Props) => {
  const { loggedInAs, scheduleOfFeesText, shopAgreementText } = useVendorRegistrationData();
  const [submitTouched, setSubmitTouched] = useState(false);

  return (
    <>
      {!loggedInAs && (
        <VendorSignUpHeader
          screen={VendorRegistrationScreen.CONTRACT}
          setInputHasError={setInputHasError}
          setScreen={setScreen}
        />
      )}
      <div className="px-5 pt-5">
        <div className={cx(styles.wrapper)}>
          <p className="mb-2">
            Here is your Agreement contract and related legal documents. You will find the Schedule
            of Fees in the window below the contract. Please read through and sign to get started.
          </p>
          <p className="mb-2">You will be emailed a copy of the contract.</p>
          <p className="mb-4">
            Please email{' '}
            <a href={`mailto: ${SUPPORT_EMAIL}`} className={cx(styles.link)}>
              {SUPPORT_EMAIL}
            </a>{' '}
            if you have any queries.
          </p>
          <div className="mb-4">
            <AgreementWindow textHtml={shopAgreementText} title="Shop Agreement" />
          </div>
          <div className="mb-4">
            <AgreementWindow textHtml={scheduleOfFeesText} title="Schedule of Fees" />
          </div>
          <ContractForm setInputHasError={setInputHasError} submitTouched={submitTouched} />
        </div>
      </div>
      <VendorSignUpFooter
        screen={VendorRegistrationScreen.CONTRACT}
        showError={inputHasError && submitTouched}
        goBack={() => setScreen(VendorRegistrationScreen.BUSINESS_DETAILS)}
        goNext={() => {
          setSubmitTouched(true);
          if (!inputHasError && loggedInAs) {
            onSubmit();
          } else if (!inputHasError) {
            setScreen(VendorRegistrationScreen.LOGIN_DETAILS);
          }
        }}
      />
    </>
  );
};

export default Contract;
