import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionUser } from '../../../global/session/selectors';
import { LocationState } from '../../../global/types';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { INVENTORY_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { InventoryItemEntity, InventoryItemSchema, UserEntity } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { inventoryFetchTrigger } from '../actions/fetch.actions';
import { INVENTORY_FETCH_API } from '../constants';
import { getInventoryQueryState, getInventorySearchState } from '../selectors';
import { ImportStockRequestPayload, importStockSlice } from '../slices';
import { FormItems, InventorySortOrder } from '../types';
import useInventoryQueryParams from './useInventoryQueryParams';

type InventoryPageData = {
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  formItems: FormItems;
  importStockTrigger: (payload: ImportStockRequestPayload) => void;
  inventoryFetchTrigger(): void;
  inventoryItems: InventoryItemEntity[] | null;
  isImportStockLoading: boolean;
  isLoading: boolean;
  loaded: boolean;
  locationState: LocationState;
  search: string;
  sortOrder: InventorySortOrder;
  start: number;
  user: UserEntity | null;
};

const useInventoryData = (): InventoryPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { locationState, search, sortOrder, start } = useInventoryQueryParams();
  const correctLocation = location.pathname === INVENTORY_URL;
  const entities = useSelector(getEntitiesState);
  const user = useSelector(getSessionUser);
  const { count, error, loaded, inventoryItemIds } = useSelector((state: AppState) =>
    getInventoryQueryState(state, sortOrder, start, search)
  );
  const { formItems } = useSelector((state: AppState) => getInventorySearchState(state, search));

  const isLoading = useSelector((state: AppState) => checkIfProcessing(state, INVENTORY_FETCH_API));

  const importStockLoadingState = useSelector(
    (state: AppState) => state.containers.inventoryImportStockLoading
  );

  const inventoryItems = useMemo(
    () =>
      inventoryItemIds !== null
        ? inventoryItemIds.map((itemId) => denormalize(itemId, InventoryItemSchema, entities))
        : null,
    [entities, inventoryItemIds]
  );

  const importStockTrigger = (payload: ImportStockRequestPayload) =>
    dispatch(importStockSlice.actions.trigger(payload));

  return {
    correctLocation,
    count,
    dispatch,
    error,
    formItems,
    importStockTrigger,
    inventoryFetchTrigger: () => dispatch(inventoryFetchTrigger(sortOrder, start, search)),
    inventoryItems,
    isImportStockLoading: importStockLoadingState.isLoading,
    isLoading,
    loaded,
    locationState,
    search,
    sortOrder,
    start,
    user,
  };
};

export default useInventoryData;
