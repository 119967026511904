/* eslint-disable  @typescript-eslint/no-explicit-any */
import produce from 'immer';
import { isEqual } from 'lodash';
import { Reducer } from 'redux';
import { LoginApiSuccessAction } from '../../containers/Login/actions';
import { LOGIN_API_SUCCESS } from '../../containers/Login/constants';
import { SelectShopApiSuccessAction } from '../../containers/ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../containers/ShopSelector/constants';
import { APISuccessAction, EntitiesState } from '../../types';
import { LogoutApiSuccessAction } from '../logout/actions';
import { LOGOUT_API_SUCCESS } from '../logout/constants';

const addToEntities = (state: EntitiesState, entities: EntitiesState) =>
  produce(state, (draft) => {
    const keys = Object.keys(entities);
    if (keys.length) {
      keys.forEach((key) => {
        const ids = Object.keys(entities[key]);
        if (ids.length) {
          draft[key] = produce(state[key] || {}, (draftKey) => {
            ids.forEach((id) => {
              if (!draftKey[id] || !isEqual(draftKey[id], entities[key][id])) {
                draftKey[id] = entities[key][id];
              }
            });
            return draftKey;
          });
        } else if (!(key in draft)) {
          draft[key] = entities[key];
        }
      });
    }
  });

const initialState: EntitiesState = {};

type EntitiesAction =
  | APISuccessAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | SelectShopApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const entitiesReducer: Reducer<EntitiesState, EntitiesAction> = (
  state: EntitiesState = initialState,
  action: EntitiesAction
): EntitiesState => {
  switch (action.type) {
    case LOGIN_API_SUCCESS:
    case LOGOUT_API_SUCCESS:
    case SELECT_SHOP_API_SUCCESS:
      // throw away all existing entities
      return addToEntities(initialState, action.payload.response.entities);

    default:
      if (
        'payload' in action &&
        action.payload &&
        typeof action.payload === 'object' &&
        'response' in action.payload &&
        action.payload.response &&
        action.payload.response.entities
      ) {
        return addToEntities(state, action.payload.response.entities);
      }
      return state;
  }
};
