import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import useSelectAll from '../../../global/hooks/useSelectAll';
import { getRouterState } from '../../../global/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { ORDERS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { OrderEntity, OrderSchema } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { ordersFetchTrigger } from '../actions/fetch.actions';
import {
  ordersAddSelectedOrders,
  ordersRemoveSelectedOrders,
  ordersSelectAll,
  ordersToggleSelectedOrder,
} from '../actions/select.actions';
import { ORDERS_FETCH_API } from '../constants';
import { getOrdersQueryState, getOrdersSearchState, getOrdersStateSelected } from '../selectors';
import { OrdersSortOrder } from '../types';
import useOrdersQueryParams from './useOrdersQueryParams';

type OrdersPageData = {
  addSelectedOrders: (selectedOrders: number[]) => void;
  areAllSelected: boolean;
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  isLoading: boolean;
  loaded: boolean;
  orders: OrderEntity[] | null;
  ordersFetchTrigger(): void;
  ordersSelectAll(): void;
  removeSelectedOrders: (selectedOrders: number[]) => void;
  search: string;
  selectedOrders: Set<number>;
  sortOrder: OrdersSortOrder;
  start: number;
  toggleSelectedOrder: (id: number) => void;
};

const useOrdersPageData = (): OrdersPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { search, sortOrder, start } = useOrdersQueryParams();
  const correctLocation = location.pathname === ORDERS_URL;
  const entities = useSelector(getEntitiesState);
  const { count, error, loaded, orderIds } = useSelector((state: AppState) =>
    getOrdersQueryState(state, sortOrder, start, search)
  );
  const { allIds } = useSelector((state: AppState) => getOrdersSearchState(state, search));
  const isLoading = useSelector((state: AppState) => checkIfProcessing(state, ORDERS_FETCH_API));

  const selectedOrders = useSelector(getOrdersStateSelected);

  const orders = useMemo(
    () =>
      orderIds === null
        ? orderIds
        : orderIds.map((orderId) => denormalize(orderId, OrderSchema, entities)),
    [entities, orderIds]
  );

  const { areAllSelected, effSelectedSet } = useSelectAll(allIds, selectedOrders);

  return {
    addSelectedOrders: (selectedOrdersIds: number[]) =>
      dispatch(ordersAddSelectedOrders(selectedOrdersIds)),
    areAllSelected,
    correctLocation,
    count,
    dispatch,
    error,
    isLoading,
    loaded,
    orders,
    ordersFetchTrigger: () => dispatch(ordersFetchTrigger(sortOrder, start, search)),
    ordersSelectAll: () => dispatch(ordersSelectAll(search)),
    removeSelectedOrders: (selectedOrdersIds: number[]) =>
      dispatch(ordersRemoveSelectedOrders(selectedOrdersIds)),
    search,
    selectedOrders: effSelectedSet,
    sortOrder,
    start,
    toggleSelectedOrder: (id: number) => dispatch(ordersToggleSelectedOrder(id)),
  };
};

export default useOrdersPageData;
