/* eslint-disable react/button-has-type */
import cx from 'classnames';
import React, { FC } from 'react';
import { Button, ButtonProps } from 'reactstrap';

type SecondaryButtonSize = 'large' | 'small';

interface Props extends ButtonProps {
  disabled?: boolean;
  size: SecondaryButtonSize;
}

const SecondaryButton: FC<Props> = ({
  children,
  className,
  disabled = false,
  size,
  type = 'button',
  ...rest
}: Props) => {
  const btnSizeClass = size === 'large' ? 'sss-btn-large' : 'sss-btn-small';
  const btnClass = disabled ? 'sss-primary-disabled' : 'sss-primary--outline';
  return (
    <Button
      {...rest}
      className={cx('sss-btn', btnClass, btnSizeClass, className)}
      disabled={disabled}
      type={type}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
