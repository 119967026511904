export enum VendorRegistrationScreen {
  FIRST_SCREEN,
  SIGN_IN,
  BUSINESS_DETAILS,
  CONTRACT,
  LOGIN_DETAILS,
  SUCCESS,
  ERROR,
}

export interface VendorRegistrationScreenStep {
  screen: VendorRegistrationScreen;
  title: string;
}

export type VendorRegistrationBusinessGeneral = {
  legalName: string;
  displayName: string;
  companyNumber: string;
  vatNumber: string;
  vatPrefix: string;
};

export type VendorRegistrationBusinessRegisteredAddress = {
  addressLine1: string;
  addressLine2: string;
  addressState: string;
  city: string;
  country: string;
  postcode: string;
};

export type VendorRegistrationBusinessTradingAddress = {
  addressLine1: string;
  addressLine2: string;
  addressState: string;
  city: string;
  country: string;
  postcode: string;
};

export type VendorRegistrationBusiness = {
  general: VendorRegistrationBusinessGeneral;
  registeredAddress: VendorRegistrationBusinessRegisteredAddress;
  tradingAddress: VendorRegistrationBusinessTradingAddress;
};

export type VendorRegistrationContract = {
  name: string;
  position: string;
  email: string;
  phone: string;
  termsAccepted: boolean;
  authorized: boolean;
};

export type VendorRegistrationLogin = {
  email: string;
  password: string;
  passwordConfirm: string;
};

export type VendorRegistrationState = {
  business: VendorRegistrationBusiness;
  contract: VendorRegistrationContract;
  login: VendorRegistrationLogin;
  contractHash: string;
  loggedInAs: string | null;
  scheduleOfFeesText: string;
  shopAgreementText: string;
  shopCurrency: string;
  shopLogoUrl: string;
};
