import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import MultipleInput, { SimpleTag } from '../../../../components/MultipleInput/MultipleInput';
import { AppState } from '../../../../init/rootReducer';
import { BriefProductEntity } from '../../../../schemas';
import { getProductsTagsSlice, setTagsSlice } from '../../slices';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  selectedProducts: Set<number>;
  submit: ({ tags, productIds }: { productIds: number[]; tags: string[] }) => void;
};

const useTags = (initialTags: string[] = []) => {
  const [tags, setTags] = useState<string[]>(initialTags);
  const addTag = (tag: string) => setTags(tags.indexOf(tag) === -1 ? [...tags, tag] : tags);
  const removeTag = (tag: string) => setTags(tags.filter((t) => t !== tag));
  return { tags, setTags, addTag, removeTag };
};

const useHints = (initialHints: string[] = []) => {
  const { tags, setTags, addTag, removeTag } = useTags(initialHints);
  return {
    hints: tags,
    setHints: setTags,
    addHint: addTag,
    removeHint: removeTag,
  };
};

export const AddTagsModal: FC<Props> = ({ isOpen, toggle, selectedProducts, submit }: Props) => {
  const { tags, addTag, removeTag } = useTags();
  const { hints, setHints, addHint, removeHint } = useHints([]);
  const numSelected = selectedProducts.size;

  const { isLoading } = useSelector((state: AppState) => state.containers.productsTagsLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isOpen) return;
    dispatch(getProductsTagsSlice.actions.trigger({}));
  }, [isOpen, dispatch, setHints]);

  const { tags: allTags } = useSelector((state: AppState) => state.containers.productsTags);
  useEffect(() => {
    setHints(allTags);
  }, [allTags, setHints]);

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={() =>
        submit({
          tags,
          productIds: Array.from(selectedProducts),
        })
      }
      canSubmit={() => !!tags.length}
      title="Add Tags"
      okButtonLabel="Add"
      initialValues={{}}
    >
      Add tags to <b>{numSelected}</b> products
      <MultipleInput
        name="tags"
        title="Tags"
        submittedValues={tags}
        addTag={addTag}
        removeTag={(tag) => {
          removeTag(tag);
          addHint(tag);
        }}
        placeholder="Type to add"
      />
      <hr />
      {isLoading && 'Loading existing tags...'}
      {!isLoading &&
        hints.map((hint) => (
          <SimpleTag
            key={hint}
            tag={hint}
            onClick={(tag) => {
              addTag(tag);
              removeHint(tag);
            }}
          />
        ))}
    </ModalForm>
  );
};

export const RemoveTagsModal: FC<Props> = ({ isOpen, toggle, selectedProducts, submit }: Props) => {
  const { tags, addTag, removeTag } = useTags();
  const { hints, setHints, addHint, removeHint } = useHints([]);
  const numSelected = selectedProducts.size;

  const { isLoading } = useSelector((state: AppState) => state.containers.productsTagsLoading);
  const dispatch = useDispatch();
  const productIds = useMemo(() => Array.from(selectedProducts), [selectedProducts]);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getProductsTagsSlice.actions.trigger({ productIds }));
  }, [isOpen, dispatch, productIds, setHints]);

  const { tags: allTags } = useSelector((state: AppState) => state.containers.productsTags);
  useEffect(() => {
    setHints(allTags);
  }, [allTags, setHints]);

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={() => submit({ tags, productIds })}
      canSubmit={() => !!tags.length}
      title="Remove Tags"
      okButtonLabel="Remove"
      initialValues={{}}
    >
      Remove tags from <b>{numSelected}</b> products
      <MultipleInput
        name="tags"
        title="Tags"
        submittedValues={tags}
        addTag={addTag}
        removeTag={(tag) => {
          removeTag(tag);
          addHint(tag);
        }}
        placeholder="Type to add"
      />
      <hr />
      {isLoading && 'Loading existing tags...'}
      {!isLoading &&
        hints.map((hint) => (
          <SimpleTag
            key={hint}
            tag={hint}
            onClick={(tag) => {
              addTag(tag);
              removeHint(tag);
            }}
          />
        ))}
    </ModalForm>
  );
};

type EditTagsModalProps = {
  isOpen: boolean;
  toggle: () => void;
  product: BriefProductEntity;
};

export const EditTagsModal: FC<EditTagsModalProps> = ({
  isOpen,
  toggle,
  product,
}: EditTagsModalProps) => {
  const { tags, addTag, removeTag, setTags } = useTags();

  const { isLoading } = useSelector((state: AppState) => state.containers.productsTagsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getProductsTagsSlice.actions.trigger({ productIds: [product.productId] }));
  }, [isOpen, dispatch, product]);

  const { tags: allTags } = useSelector((state: AppState) => state.containers.productsTags);
  useEffect(() => {
    setTags(allTags);
  }, [allTags, setTags]);

  const setProductTagsTrigger = () =>
    dispatch(setTagsSlice.actions.trigger({ tags, productIds: [product.productId] }));

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={setProductTagsTrigger}
      canSubmit={() => !!tags.length}
      title="Edit Tags"
      okButtonLabel="Update"
      initialValues={{ productName: product.name }}
    >
      Edit tags for <b>{product.name}</b>
      <MultipleInput
        name="tags"
        title="Tags"
        submittedValues={tags}
        addTag={addTag}
        removeTag={removeTag}
        placeholder="Type to add"
      />
    </ModalForm>
  );
};
