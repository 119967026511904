import { isEqual } from 'lodash';
import { useMemo } from 'react';

interface UseSelectAllResults {
  areAllSelected: boolean;
  effSelectedSet: Set<number>;
}

function useSelectAll(allIds: number[] | null, selectedIds: number[]): UseSelectAllResults {
  // all IDs for this search (paging and sorting not relevant here)
  const allIdsSet = useMemo(() => new Set(allIds), [allIds]);

  // complete list of selected IDs (IDs can be even outside of this search)
  const selectedSet = useMemo(() => new Set(selectedIds), [selectedIds]);

  // effective set of selected IDs for this search (intersect of allIds and selectedIds)
  const effSelectedSet = useMemo(() => {
    return allIds !== null ? new Set(allIds.filter((id) => selectedSet.has(id))) : selectedSet;
  }, [allIds, selectedSet]);

  // true if all IDs in this search are selected (so we can hide Select all button)
  const areAllSelected = useMemo(() => isEqual(allIdsSet, effSelectedSet), [
    allIdsSet,
    effSelectedSet,
  ]);

  return {
    areAllSelected,
    effSelectedSet,
  };
}

export default useSelectAll;
