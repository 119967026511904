import cx from 'classnames';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { checkMaxDecimalPlaces } from 'global/utils/validation';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { currencyToSymbol, formatDateFancy, formatPrice } from '../../../../helpers/utils';
import { AppState } from '../../../../init/rootReducer';
import { PayoutEntity, ShopEntity } from '../../../../schemas';
import { isSubmittingPayouts } from '../../selectors';
import { addTransactionSlice } from '../../slices';
import { FormItem, FormValues } from '../../types';
import styles from './formatters.module.scss';

const actionFieldName = (id: number) => `payouts.${id}.action`;
const customAmountFieldName = (id: number) => `payouts.${id}.customAmount`;
const memoFieldName = (id: number) => `payouts.${id}.memo`;

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const nameFormatter = (name: string, row: PayoutEntity): ReactElement => (
  <span className={cx(tableStyles.textDark, styles.name)}>
    <div className={tableStyles.textDark}>
      {row.vendorDisplayName} ({row.vendorCode})
    </div>
    <div className={tableStyles.textSecondary}>{row.vendorLegalName}</div>
  </span>
);

export const lastPayoutFormatter = (
  name: string,
  row: PayoutEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (!row.lastPayoutDate) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark, styles.name)}>
      <div className={tableStyles.textDark}>{formatDateFancy(row.lastPayoutDate, currency)}</div>
      <div className={tableStyles.textSecondary}>{formatPrice(row.lastPayoutAmount, currency)}</div>
    </span>
  );
};

// eslint-disable-next-line react/no-unused-prop-types, @typescript-eslint/no-explicit-any
type RowFieldProps = FieldAttributes<any> & { row: PayoutEntity };

const CustomAmountField = (props: RowFieldProps) => {
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row } = props;
  const rowValues = values.payouts[row.id];
  const shopCurrency = useSelector((state: AppState) => state.session.activeShop?.currency);
  if (!rowValues) {
    return null;
  }
  const value = field.value || '';
  const currencySymbol = currencyToSymbol(shopCurrency);
  return (
    <InputGroup size="sm" style={{ flexWrap: 'nowrap' }}>
      <InputGroupAddon addonType="prepend">{currencySymbol}</InputGroupAddon>
      <Input
        {...field}
        bsSize="sm"
        placeholder=""
        step={1}
        style={{ width: '6em', flexGrow: 0 }}
        type="number"
        value={value}
      />
    </InputGroup>
  );
};

const MemoField = (props: RowFieldProps) => {
  const { values } = useFormikContext<FormValues>();
  const [field] = useField(props);
  const { row } = props;
  const rowValues = values.payouts[row.id];
  if (!rowValues) {
    return null;
  }
  const value = field.value || '';
  return <Input {...field} bsSize="sm" placeholder="" type="text" value={value} />;
};

const isSavePossible = (row: PayoutEntity, rowValues: FormItem): boolean => {
  const { customAmount } = rowValues;
  return !customAmount || (customAmount > 0 && checkMaxDecimalPlaces(customAmount, 2));
};

const ActionField = (props: RowFieldProps) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(isSubmittingPayouts);
  const { values } = useFormikContext<FormValues>();
  const { row } = props;

  const vendorId = row.id;
  const rowValues = values.payouts[vendorId];
  if (!rowValues) {
    return null;
  }

  const saveEnabled = isSavePossible(row, rowValues);

  return (
    <SecondaryButton
      disabled={isSubmitting || !saveEnabled}
      onClick={() =>
        dispatch(
          addTransactionSlice.actions.trigger({
            type: 'payout',
            amount: rowValues.customAmount || row.payout,
            memo: rowValues.memo,
            vendorId: row.id,
          })
        )
      }
      size="small"
    >
      Submit
    </SecondaryButton>
  );
};

export const moneyFormatter = (
  amount: number,
  row: PayoutEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (amount === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark, { [tableStyles.negativeAmount]: amount < 0 })}>
      {formatPrice(amount, currency)}
    </span>
  );
};

export const customAmountFormatter = (commission: number, row: PayoutEntity): ReactElement => (
  <CustomAmountField name={customAmountFieldName(row.id)} row={row} />
);

export const memoFormatter = (maxProducts: number, row: PayoutEntity): ReactElement => (
  <MemoField name={memoFieldName(row.id)} row={row} />
);

export const actionsFormatter = (_1: number, row: PayoutEntity): ReactElement => (
  <ActionField name={actionFieldName(row.id)} row={row} />
);
