import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Col } from 'reactstrap';
import { SideMenuItemType } from '../../../../global/sidemenu';
import SideMenuSubItem from '../SideMenuSubItem/SideMenuSubItem';
import styles from './SideMenuItem.module.scss';

type Props = {
  sideMenuCollapsed: boolean;
  onSubMenuClick: (name: string) => void;
  page: SideMenuItemType;
  pathname: string;
  subMenuOpen: boolean;
};

const SideMenuItem: FC<Props> = ({
  sideMenuCollapsed,
  onSubMenuClick,
  page,
  pathname,
  subMenuOpen,
}: Props) => {
  const createNavLinkClass = (isActive: boolean, isButton: boolean) =>
    cx(
      'text-capitalize text-decoration-none d-flex align-items-center text-left w-100',
      styles.navLink,
      {
        [styles.navLinkActive]: isActive,
        [styles.navLinkBtn]: isButton,
      }
    );
  const menuIconStyles = cx(
    styles.menuIconWrapper,
    'd-flex justify-content-center align-items-center'
  );
  const menuItemTextStyles = cx(styles.menuItemText, 'd-flex align-items-center');

  const NavLinkWithSubItem = () => (
    <>
      <button
        type="button"
        className={createNavLinkClass(page.link === pathname, true)}
        onClick={() => onSubMenuClick(page.title)}
      >
        <div className={menuIconStyles}>
          <FontAwesomeIcon size="sm" icon={page.icon} />
        </div>
        {!sideMenuCollapsed && (
          <div className={cx('d-flex justify-content-between w-100')}>
            <div className={menuItemTextStyles}>{page.title}</div>
            <Col xl={1}>
              <FontAwesomeIcon size="sm" icon={subMenuOpen ? faChevronUp : faChevronDown} />
            </Col>
          </div>
        )}
      </button>

      {!sideMenuCollapsed &&
        'subItems' in page &&
        subMenuOpen &&
        page.subItems &&
        page.subItems.map((subItem, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx} className={cx('pl-3')}>
            <SideMenuSubItem pathname={pathname} subItem={subItem} />
          </div>
        ))}
    </>
  );

  const NavLinkWithNoSubItem = () => (
    <>
      <NavLink to={page.link} className={createNavLinkClass(page.link === pathname, false)}>
        <div className={menuIconStyles}>
          <FontAwesomeIcon size="sm" icon={page.icon} />
        </div>
        {!sideMenuCollapsed && <div className={menuItemTextStyles}>{page.title}</div>}
      </NavLink>
    </>
  );

  return <>{'subItems' in page ? <NavLinkWithSubItem /> : <NavLinkWithNoSubItem />}</>;
};

export default SideMenuItem;
