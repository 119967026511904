import { Field } from 'formik';
import React, { FC } from 'react';
import { Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';

const VendorProfileContacts: FC = () => {
  return (
    <Card className="h-lg-100 mb-3 p-3">
      <h4 className="sss-form-title">
        Contacts <CustomTooltip text="?" />
      </h4>
      <Row className="d-flex justify-content-between">
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">Name (Inventory Contact)</Label>
            <Field as={Input} name="inventoryContactName" type="text" />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">E-mail (Inventory Contact)</Label>
            <Field as={Input} name="inventoryContactEmail" type="text" />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">Phone (Inventory Contact)</Label>
            <Field as={Input} name="inventoryContactPhone" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">Name (Billing Contact)</Label>
            <Field as={Input} name="billingContactName" type="text" />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">E-mail (Billing Contact)</Label>
            <Field as={Input} name="billingContactEmail" type="text" />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">Phone (Billing Contact)</Label>
            <Field as={Input} name="billingContactPhone" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">Name (Customer Enquiry Contact)</Label>
            <Field as={Input} name="customerInquiryContactName" type="text" />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">E-mail (Customer Enquiry Contact)</Label>
            <Field as={Input} name="customerInquiryContactEmail" type="text" />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label className="sss-form-subtitle">Phone (Customer Enquiry Contact)</Label>
            <Field as={Input} name="customerInquiryContactPhone" type="text" />
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
};

export default VendorProfileContacts;
