import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { LOGIN_AS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { UserEntity, UserSchema } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { loginAsFetchTrigger } from '../actions/fetchActions';
import { loginAsTrigger } from '../actions/selectActions';
import { LOGIN_AS_FETCH_API_PREFIX } from '../constants';
import { getLoginAsQueryState } from '../selectors';
import useLoginAsQueryParams from './useLoginAsQueryParams';

interface LoginAsData {
  correctLocation: boolean;
  dispatch: Dispatch;
  error: NormalizedError | null;
  isLoading: boolean;
  loginAsFetchTrigger(): void;
  loginAsTrigger(userId: number): void;
  search: string;
  users: UserEntity[] | null;
}

const useLoginAsData = (): LoginAsData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { search } = useLoginAsQueryParams();
  const correctLocation = location.pathname === LOGIN_AS_URL;
  const entities = useSelector(getEntitiesState);
  const { error, userIds } = useSelector((state: AppState) => getLoginAsQueryState(state, search));

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, LOGIN_AS_FETCH_API_PREFIX)
  );

  const users = useMemo(
    () =>
      userIds === null ? null : userIds.map((userId) => denormalize(userId, UserSchema, entities)),
    [entities, userIds]
  );

  return {
    correctLocation,
    dispatch,
    error,
    isLoading,
    loginAsFetchTrigger: () => dispatch(loginAsFetchTrigger(search)),
    loginAsTrigger: (userId) => dispatch(loginAsTrigger(userId)),
    search,
    users,
  };
};

export default useLoginAsData;
