import { Action } from 'redux';
import { NormalizedError } from '../../types';

export interface FormItem {
  commission: number;
  enquiriesAllowed: boolean;
  maxProducts: number;
  moderation: boolean;
  tags: string[];
}

export type FormItems = { [vendorId: number]: FormItem };

export interface FormValues {
  vendors: FormItems;
}

export enum SortableVendorsColumn {
  commission = 'commission',
  enquiriesAllowed = 'enquiriesAllowed',
  maxProducts = 'maxProducts',
  moderation = 'moderation',
  name = 'name',
  numPublishedProducts = 'numPublishedProducts',
  revenue = 'revenue',
  status = 'status',
  tags = 'tags',
}

export enum NonSortableVendorsColumn {
  logoUrl = 'logoUrl',
  paymentFee = 'paymentFee',
}

export enum VendorsSortOrder {
  commission = 'commission',
  commissionDesc = '-commission',
  enquiriesAllowed = 'enquiriesAllowed',
  enquiriesAllowedDesc = '-enquiriesAllowed',
  logoUrl = 'logoUrl',
  logoUrlDesc = '-logoUrl',
  maxProducts = 'maxProducts',
  maxProductsDesc = '-maxProducts',
  moderation = 'moderation',
  moderationDesc = '-moderation',
  name = 'name',
  nameDesc = '-name',
  numPublishedProducts = 'numPublishedProducts',
  numPublishedProductsDesc = '-numPublishedProducts',
  revenue = 'revenue',
  revenueDesc = '-revenue',
  status = 'status',
  statusDesc = '-status',
  tags = 'tags',
  tagsDesc = '-tags',
}

export type VendorsQueryState = {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  vendorIds: number[] | null;
};

export interface VendorsSearchState {
  allIds: number[] | null;
  formItems: FormItems;
}

export interface VendorsTypeState {
  queries: { [queryKey: string]: VendorsQueryState };
  searches: { [search: string]: VendorsSearchState };
  selected: number[];
}

export interface VendorsPageState {
  types: { [type: string]: VendorsTypeState };
}

export interface VendorsSubmitValues {
  commission?: number;
  enquiriesAllowed?: boolean;
  maxProducts?: number | null;
  moderation?: boolean;
  name?: string;
  tags?: string[];
}

export enum VendorsType {
  Active = 'active',
  Inactive = 'inactive',
  Invited = 'invited',
  // ShopNet = 'shopnet',
}

// BEGIN: temporary workaround
export const VENDOR_INVITE_DELETE_SUCCESS = 'vendor-invite/delete/success';

export interface DeleteVendorInviteSuccessAction extends Action {
  type: typeof VENDOR_INVITE_DELETE_SUCCESS;
  payload: { request: { inviteId: number }; type: VendorsType };
}
// END: temporary workaround
