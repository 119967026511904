import { EntitiesState } from '../../types';
import { SessionSectionNormalized } from '../init/actions';
import { RESET_SESSION } from './constants';

// interfaces

export interface ResetSessionAction {
  type: typeof RESET_SESSION;
  payload: {
    normalizedSession: SessionSectionNormalized;
    entities: EntitiesState;
    dummy?: boolean;
  };
}

// action creators

export const resetSession = (
  normalizedSession: SessionSectionNormalized,
  entities: EntitiesState,
  resetOnly = false
): ResetSessionAction => ({
  type: RESET_SESSION,
  payload: { entities, normalizedSession, dummy: resetOnly },
});

// This is a workaround for nicer reload of the pages via redux actions.
// It's slightly better than location.reload();
// but the correct way is to fetch added/updated entities.
export const reloadPage = (): ResetSessionAction => ({
  type: RESET_SESSION,
  payload: {
    normalizedSession: {
      activeShop: 0,
      user: 0,
      vendorshop: 0,
    },
    entities: {},
    dummy: true,
  },
});
