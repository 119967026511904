import cx from 'classnames';
import React, { FC } from 'react';
import { Spinner } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';

interface ProductSubmitButtonProps {
  label: string;
  isSubmitting: boolean;
  submit?: () => void;
  canSubmit?: boolean;
  negative?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

const ProductSubmitButton: FC<ProductSubmitButtonProps> = ({
  label,
  isSubmitting,
  submit,
  canSubmit,
  negative,
  type,
}: ProductSubmitButtonProps) => {
  return (
    <PrimaryButton
      size="small"
      disabled={!canSubmit || isSubmitting}
      onClick={submit}
      negative={negative}
      type={type}
    >
      <span className={cx(isSubmitting && 'invisible', 'text-nowrap')}>{label}</span>
      {isSubmitting && (
        <div className="position-absolute" style={{ inset: 0, marginTop: '3px' }}>
          <Spinner color="white" size="sm" />
        </div>
      )}
    </PrimaryButton>
  );
};

export default ProductSubmitButton;
