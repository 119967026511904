import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { FORGOTTEN_PASSWORD_API_URL } from '../../global/urls';
import { apiPost, normalizeError } from '../../helpers/api';
import {
  forgottenPasswordApiFailure,
  forgottenPasswordApiRequest,
  forgottenPasswordApiSuccess,
  ForgottenPasswordNormalizedResponse,
  ForgottenPasswordTriggerAction,
} from './actions';
import { FORGOTTEN_PASSWORD_TRIGGER } from './constants';

function* forgottenPasswordSaga(action: ForgottenPasswordTriggerAction) {
  const { email, emailSentCallback } = action.payload;
  yield put(forgottenPasswordApiRequest());

  try {
    // we first need to get CSRF token from Django backend
    yield* initIfNeededSaga();

    // submit password reset request
    const response = yield call(apiPost, FORGOTTEN_PASSWORD_API_URL, {
      email,
    });

    const normalizedResponse: ForgottenPasswordNormalizedResponse = normalize(response, {});
    yield put(forgottenPasswordApiSuccess(normalizedResponse));

    // show email sent screen
    yield call(emailSentCallback);
  } catch (error) {
    yield put(forgottenPasswordApiFailure(normalizeError(error)));
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(FORGOTTEN_PASSWORD_TRIGGER, forgottenPasswordSaga)]);
}
