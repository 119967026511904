import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/giftCardsQueryReducer';
import {
  GiftCardCodesQueryState,
  GiftCardCodesSortOrder,
  GiftCardProductsSortOrder,
  GiftCardsPageState,
} from './types';
import { makeQueryKey } from './utils';

export const getGiftCardsPageState = (state: AppState): GiftCardsPageState =>
  state.containers.giftCards;

export const getGiftCardsQueryState = (
  state: AppState,
  sortProducts: GiftCardProductsSortOrder,
  sortCodes: GiftCardCodesSortOrder,
  start: number,
  search: string
): GiftCardCodesQueryState => {
  return (
    getGiftCardsPageState(state).queries[makeQueryKey(sortProducts, sortCodes, start, search)] ||
    initialQueryState
  );
};
