import React, { FC } from 'react';
import ModalForm from '../../../../components/ModalForm/ModalForm';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  selectedProducts: Set<number>;
  submit: ({ productIds }: { productIds: number[] }) => void;
};

const UnpublishProductsModal: FC<Props> = ({ isOpen, toggle, selectedProducts, submit }: Props) => {
  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={() =>
        submit({
          productIds: Array.from(selectedProducts),
        })
      }
      canSubmit={() => true}
      title="Unpublish products"
      okButtonLabel="Confirm"
      initialValues={{}}
    >
      Do you want to unpublish <b>{selectedProducts.size}</b> products?
    </ModalForm>
  );
};

export default UnpublishProductsModal;
