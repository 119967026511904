import cx from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import React, { FC } from 'react';
import { FormGroup, Label } from 'reactstrap';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import { INPUT_ERROR_MESSAGE } from '../../../../global/general/constants';
import { validateEmail } from '../../../../helpers/validators';
import styles from './ForgottenPasswordForm.module.scss';

interface FormValues {
  email: string;
}

interface Props {
  onSubmit(email: string): void;
}

const ForgottenPasswordForm: FC<Props> = ({ onSubmit }: Props) => {
  const initialValues: FormValues = { email: '' };
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.email) {
          errors.email = INPUT_ERROR_MESSAGE.REQUIRED;
        } else if (!validateEmail(values.email)) {
          errors.email = INPUT_ERROR_MESSAGE.EMAIL;
        }
        return errors;
      }}
      onSubmit={(values) => {
        onSubmit(values.email);
      }}
    >
      {() => (
        <Form className="mt-4">
          <FormGroup className="text-left">
            <Label className={cx('w-100 mb-0')}>
              <span className={cx(styles.labelText)}>Email address</span>
              <Field
                type="email"
                name="email"
                className={cx('form-control', styles.loginFormInput)}
                autoComplete="username"
              />
            </Label>
            <ErrorMessage name="email" component={FormInputError} className="text-left" />
          </FormGroup>
          <FormGroup>
            <button
              type="submit"
              disabled={false}
              className="sss-primary w-100 rounded"
              style={{ height: '36px' }}
            >
              Send reset link
            </button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default ForgottenPasswordForm;
