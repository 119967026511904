import { faEnvelopeOpen, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import styles from './OrderAddress.module.scss';

interface Props {
  address1: string;
  address2: string;
  addressType: string;
  city: string;
  companyName: string;
  country: string;
  email?: string;
  name: string;
  phoneNumber: string;
  provinceCode: string;
  zipCode: string;
}

const OrderAddress: FC<Props> = ({
  address1,
  address2,
  addressType,
  city,
  companyName,
  country,
  email,
  name,
  phoneNumber,
  provinceCode,
  zipCode,
}: Props) => {
  return (
    <div className={cx('font-weight-600 fs-13 sss-gray-1100', styles.OrderAddress)}>
      <div className="font-weight-500 gray-700">{addressType}</div>
      <div className={cx('fs-20 sss-color-orange', styles.OrderAddress__name)}>{name}</div>
      <div>{companyName}</div>
      <div>{address1}</div>
      <div>{address2}</div>
      <div>
        {city} {country === 'United States' && provinceCode}
      </div>
      <div>{zipCode}</div>
      <div>{country}</div>
      {phoneNumber && (
        <div>
          <a className="font-weight-400 sss-blue" href={`tel:${phoneNumber}`}>
            <FontAwesomeIcon icon={faPhone} /> {phoneNumber}
          </a>
        </div>
      )}
      {email && (
        <div>
          <a className="font-weight-400 sss-blue" href={`mailto:${email}`}>
            <FontAwesomeIcon icon={faEnvelopeOpen} /> {email}
          </a>
        </div>
      )}
    </div>
  );
};

export default OrderAddress;
