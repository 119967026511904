import {
  SetContractEmailAction,
  SetContractNameAction,
  SetContractPhoneAction,
  SetContractPositionAction,
  ToggleContractAuthorizedAction,
  ToggleContractTermsAcceptedAction,
} from '../actionTypes/contract';
import {
  SET_CONTRACT_EMAIL,
  SET_CONTRACT_NAME,
  SET_CONTRACT_PHONE,
  SET_CONTRACT_POSITION,
  TOGGLE_CONTRACT_AUTHORIZED,
  TOGGLE_CONTRACT_TERMS_ACCEPTED,
} from '../constants';

export const setContractName = (name: string): SetContractNameAction => ({
  type: SET_CONTRACT_NAME,
  payload: { name },
});

export const setContractPosition = (position: string): SetContractPositionAction => ({
  type: SET_CONTRACT_POSITION,
  payload: { position },
});

export const setContractEmail = (email: string): SetContractEmailAction => ({
  type: SET_CONTRACT_EMAIL,
  payload: { email },
});

export const setContractPhone = (phone: string): SetContractPhoneAction => ({
  type: SET_CONTRACT_PHONE,
  payload: { phone },
});

export const toggleContractTermsAccepted = (): ToggleContractTermsAcceptedAction => ({
  type: TOGGLE_CONTRACT_TERMS_ACCEPTED,
});

export const toggleContractAuthorized = (): ToggleContractAuthorizedAction => ({
  type: TOGGLE_CONTRACT_AUTHORIZED,
});
