import { push } from 'connected-react-router';
import { Effect, put, select } from 'redux-saga/effects';
import { isAuthenticated } from '../../helpers/utils';
import { NormalizedResponse } from '../../types';
import { SessionSectionNormalized } from '../init/actions';
import { logoutTrigger } from '../logout/actions';
import { NO_SHOPS_URL } from '../urls';
import { reloadPage, resetSession } from './actions';
import { SessionState } from './reducer';
import { getSession } from './selectors';

/*
If a different 'activeShop' or 'user' to the ones set in current session are received in the API success response,
emit 'RESET_SESSION' action to reset all the redux state where it is relevant
 */

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/explicit-module-boundary-types
export function* resetSessionIfNeededSaga(response: NormalizedResponse, checkNoShops = true) {
  const oldSession: SessionState = yield select(getSession);
  const { activeShop, user } = oldSession;

  const session: SessionSectionNormalized = response?.result?.session;
  if (session && (session.activeShop !== activeShop?.id || session.user !== user?.id)) {
    yield put(resetSession(session, response.entities));
  }

  if (checkNoShops && session && !session.activeShop) {
    yield put(push(NO_SHOPS_URL));
    if (isAuthenticated(user)) {
      yield put(logoutTrigger());
    }
  }
}

export function reloadPageEffect(): Effect {
  return put(reloadPage());
}

export function* reloadPageSaga(): Generator<Effect> {
  yield reloadPageEffect();
}
