import { RouterState } from 'connected-react-router';
import { AppState } from '../init/rootReducer';
import { LocationState } from './types';

export const getRouterState = (state: AppState): RouterState<LocationState> => state.router;

export const getCurrentUrl = (state: AppState): string => {
  const { location } = getRouterState(state);
  return `${location.pathname}${location.search}${location.hash}`;
};
