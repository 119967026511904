import { Action } from 'redux';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  ORDER_FULFILL_API_FAILURE,
  ORDER_FULFILL_API_REQUEST,
  ORDER_FULFILL_API_SUCCESS,
  ORDER_FULFILL_TRIGGER,
} from '../constants';
import { ItemAndQuantity } from '../types';

// action types

interface OrderFulfillPayload {
  orderId: number;
  orderName: string;
  items: ItemAndQuantity[];
  trackingCode: string;
}

export interface OrderFulfillTriggerAction extends Action {
  type: typeof ORDER_FULFILL_TRIGGER;
  payload: OrderFulfillPayload;
}

export interface OrderFulfillApiRequestAction extends APIRequestAction {
  type: typeof ORDER_FULFILL_API_REQUEST;
  payload: OrderFulfillPayload;
}

export interface OrderFulfillNormalizedResponse extends NormalizedResponse {
  readonly result: {
    order: number;
    orderItems: number[];
    refundedItems: number[];
    shipments: number[];
  };
}

export interface OrderFulfillApiSuccessAction extends APISuccessAction {
  type: typeof ORDER_FULFILL_API_SUCCESS;
  payload: OrderFulfillPayload & {
    response: OrderFulfillNormalizedResponse;
  };
}

export interface OrderFulfillApiFailureAction extends APIFailureAction {
  type: typeof ORDER_FULFILL_API_FAILURE;
  payload: OrderFulfillPayload & {
    error: NormalizedError;
  };
}

// action creators

export const orderFulfillTrigger = (
  orderId: number,
  orderName: string,
  items: ItemAndQuantity[],
  trackingCode: string
): OrderFulfillTriggerAction => ({
  type: ORDER_FULFILL_TRIGGER,
  payload: {
    items,
    orderId,
    orderName,
    trackingCode,
  },
});

export const orderFulfillApiRequest = (
  orderId: number,
  orderName: string,
  items: ItemAndQuantity[],
  trackingCode: string
): OrderFulfillApiRequestAction => ({
  type: ORDER_FULFILL_API_REQUEST,
  payload: {
    items,
    orderId,
    orderName,
    trackingCode,
  },
});

export const orderFulfillApiSuccess = (
  orderId: number,
  orderName: string,
  items: ItemAndQuantity[],
  trackingCode: string,
  response: OrderFulfillNormalizedResponse
): OrderFulfillApiSuccessAction => ({
  type: ORDER_FULFILL_API_SUCCESS,
  payload: {
    items,
    orderId,
    orderName,
    response,
    trackingCode,
  },
});

export const orderFulfillApiFailure = (
  orderId: number,
  orderName: string,
  items: ItemAndQuantity[],
  trackingCode: string,
  error: NormalizedError
): OrderFulfillApiFailureAction => ({
  type: ORDER_FULFILL_API_FAILURE,
  payload: {
    error,
    items,
    orderId,
    orderName,
    trackingCode,
  },
});
