import { denormalize } from 'normalizr';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import FalconCardHeader from '../../components/FalconCardHeader/FalconCardHeader';
import FalconTable from '../../components/FalconTable/FalconTable';
import LoadingSwitch from '../../components/LoadingSwitch/LoadingSwitch';
import TableLoadingIndicator from '../../components/TableLoadingIndicator/TableLoadingIndicator';
import { getEntitiesState } from '../../global/entities/selectors';
import { AppState } from '../../init/rootReducer';
import styles from './RejectedProducts.module.scss';
import columns from './columns';
import { RejectedModerationRequestSchema } from './sagas';
import { fetchRejectedProductsSlice } from './slices';

const RejectedProducts: FC = () => {
  const dispatch = useDispatch();
  const loadData = () => {
    dispatch(fetchRejectedProductsSlice.actions.trigger({}));
  };

  const ids = useSelector(
    (state: AppState) => state.containers.rejectedProducts.rejectedModerationRequests
  );

  const entities = useSelector(getEntitiesState);
  const { error, isLoading, loaded } = useSelector(
    (state: AppState) => state.containers.rejectedProductsLoading
  );
  const data = ids.map((id) => denormalize(id, RejectedModerationRequestSchema, entities));

  return (
    <LoadingSwitch
      error={!!error}
      loading={isLoading}
      onLoad={loadData}
      renderError={() => <span>{error}</span>}
      renderLoading={() => <TableLoadingIndicator />}
      require={data?.length > 0 || loaded}
    >
      <Card className="pb-3">
        <FalconCardHeader border={false} className="bg-white" title="Rejected Products" />
        <FalconTable
          columns={columns(dispatch)}
          data={data}
          keyField="id"
          wrapperClasses={styles.RejectedProducts__table}
        />
      </Card>
    </LoadingSwitch>
  );
};

export default RejectedProducts;
