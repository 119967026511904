import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import useToggle from '../../../../components/ModalForm/useToggle';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGINATION_DISPLAYED_RANGE } from '../../constants';
import usePayoutsData from '../../hooks/usePayoutsData';
import { pushPayoutsUrl, replacePayoutsUrl } from '../../utils';
import AddTransactionModal from '../AddTransactionModal/AddTransactionModal';
import PayoutsRightControls from '../PayoutsRightControls/PayoutsRightControls';
import PayoutsTable from '../PayoutsTable/PayoutsTable';

const PayoutsMainContent: FC = () => {
  useScrollToTop();

  const {
    correctLocation,
    count,
    error,
    formItems,
    addTransactionTrigger,
    isLoading,
    loaded,
    search,
    shop,
    sortOrder,
    start,
    payouts,
    payoutsFetchTrigger,
  } = usePayoutsData();

  const [inviteVendorOpen, inviteVendorToggle] = useToggle();

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: (dsp, params) => replacePayoutsUrl(dsp, params),
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: (dsp, params) => pushPayoutsUrl(dsp, params),
    searchInput,
    sortOrder,
    start,
  });

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Payouts"
      rightControls={
        <PayoutsRightControls
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setModalOpen={inviteVendorToggle}
        />
      }
      itemsCount={count}
      pagination={
        <TablePagination
          activePage={activePage}
          displayedRange={PAGINATION_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={payoutsFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(payouts && payouts.length > 0) || loaded || !correctLocation}
      >
        {payouts && payouts.length ? (
          <PayoutsTable
            formItems={formItems}
            pageSize={PAGE_SIZE}
            shop={shop}
            sortOrder={sortOrder}
            payouts={payouts}
          />
        ) : (
          <TableNoResults search={search} text="There are no data for vendor payouts, yet." />
        )}
      </LoadingSwitch>
      <AddTransactionModal
        isOpen={inviteVendorOpen}
        onSubmit={addTransactionTrigger}
        toggle={inviteVendorToggle}
      />
    </TablePageWrapper>
  );
};

export default PayoutsMainContent;
