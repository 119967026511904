import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/collectionsQueryReducer';
import { CollectionsPageState, CollectionsQueryState, CollectionsSortOrder } from './types';

export const getCollectionsPageState = (state: AppState): CollectionsPageState =>
  state.containers.collections;

export const getCollectionsQueryState = (
  state: AppState,
  sort: CollectionsSortOrder,
  start: number,
  search: string
): CollectionsQueryState => {
  return (
    getCollectionsPageState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState
  );
};
