import { Action } from 'redux';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  EntitiesState,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  PRODUCT_SUBMIT_API_FAILURE,
  PRODUCT_SUBMIT_API_REQUEST,
  PRODUCT_SUBMIT_API_SUCCESS,
  PRODUCT_SUBMIT_TRIGGER,
} from '../constants';
import { ProductSubmitValues } from '../types';

// action types

interface ProductSubmitPayload {
  productId: number;
  values: ProductSubmitValues;
}

export interface ProductSubmitTriggerAction extends Action {
  type: typeof PRODUCT_SUBMIT_TRIGGER;
  payload: ProductSubmitPayload;
}

export interface ProductSubmitApiRequestAction extends APIRequestAction {
  type: typeof PRODUCT_SUBMIT_API_REQUEST;
  payload: ProductSubmitPayload;
}

export interface ProductSubmitNormalizedResponse extends NormalizedResponse {
  readonly entities: EntitiesState;
  readonly result: {
    allCollections: number[];
    collectionIds: number[];
    isDisabledVendor: boolean;
    productId: number;
    productVersion: number;
    shippingPolicies: number[];
    shippingTypes: number[];
    showUnpublish: boolean;
    status: string;
    vendorCode: string | null;
    enquiriesAllowed: boolean;
  };
}

export interface ProductSubmitApiSuccessAction extends APISuccessAction {
  type: typeof PRODUCT_SUBMIT_API_SUCCESS;
  payload: ProductSubmitPayload & {
    response: ProductSubmitNormalizedResponse;
  };
}

export interface ProductSubmitApiFailureAction extends APIFailureAction {
  type: typeof PRODUCT_SUBMIT_API_FAILURE;
  payload: ProductSubmitPayload & {
    error: NormalizedError;
  };
}

// action creators

export const productSubmitTrigger = (
  productId: number,
  values: ProductSubmitValues
): ProductSubmitTriggerAction => ({
  type: PRODUCT_SUBMIT_TRIGGER,
  payload: {
    productId,
    values,
  },
});

export const productSubmitApiRequest = (
  productId: number,
  values: ProductSubmitValues
): ProductSubmitApiRequestAction => ({
  type: PRODUCT_SUBMIT_API_REQUEST,
  payload: { productId, values },
});

export const productSubmitApiSuccess = (
  productId: number,
  values: ProductSubmitValues,
  response: ProductSubmitNormalizedResponse
): ProductSubmitApiSuccessAction => ({
  type: PRODUCT_SUBMIT_API_SUCCESS,
  payload: { productId, response, values },
});

export const productSubmitApiFailure = (
  productId: number,
  values: ProductSubmitValues,
  error: NormalizedError
): ProductSubmitApiFailureAction => ({
  type: PRODUCT_SUBMIT_API_FAILURE,
  payload: { error, productId, values },
});
