import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { all, fork, put, take, takeLatest } from 'redux-saga/effects';
import {
  DASHBOARD_URL,
  MODERATION_APPROVE_API_URL,
  MODERATION_LIST_API_URL,
  MODERATION_REJECT_API_URL,
  MODERATION_REVIEW_API_URL,
  MODERATION_URL,
} from '../../global/urls';
import toast from '../../global/utils/toast';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { ModerationRequestSchema, SessionSchema } from '../../schemas';
import { productSubmitTrigger } from '../Product/actions/submit.actions';
import { PRODUCT_SUBMIT_API_FAILURE, PRODUCT_SUBMIT_API_SUCCESS } from '../Product/constants';
import { defaultSortOrder } from './constants';
import {
  ApprovePublishPayload,
  approvePublishSlice,
  fetchModerationListSlice,
  RejectPublishPayload,
  rejectPublishSlice,
  ReviewPublishApiSuccessAction,
  ReviewPublishPayload,
  reviewPublishSlice,
} from './slices/moderationSlice';

const fetchSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: MODERATION_LIST_API_URL,
  urlParams: (payload) => payload,
  responseSchema: {
    moderationList: [ModerationRequestSchema],
    session: SessionSchema,
  },
  actions: fetchModerationListSlice.actions,
});

const reviewSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: MODERATION_REVIEW_API_URL,
  urlParams: ({ moderationId }: ReviewPublishPayload) => ({ moderationId }),
  responseSchema: {
    moderationRequest: ModerationRequestSchema,
    session: SessionSchema,
  },
  actions: reviewPublishSlice.actions,
});

function* visitProductModerationPageSaga(action: ReviewPublishApiSuccessAction) {
  const { moderationRequest: moderationId } = action.payload.response.result;
  const moderationUrl = generatePath(MODERATION_URL, { moderationId });
  yield put(push(moderationUrl));
}

const approveRequestSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: MODERATION_APPROVE_API_URL,
  urlParams: ({ moderationId }: ApprovePublishPayload) => ({ moderationId }),
  responseSchema: {
    moderationRequest: ModerationRequestSchema,
    session: SessionSchema,
  },
  actions: approvePublishSlice.actions,
});

function* approveSaga(action: PayloadAction<ApprovePublishPayload>): SagaIterator {
  const { productId, values, moderationId } = action.payload;
  const saveProductValues = {
    ...values,
    publish: false,
    moderationRequestId: moderationId,
  };
  yield put(productSubmitTrigger(productId, saveProductValues));
  const resultAction = yield take([PRODUCT_SUBMIT_API_SUCCESS, PRODUCT_SUBMIT_API_FAILURE]);
  if (resultAction.type === PRODUCT_SUBMIT_API_FAILURE) {
    if (resultAction.payload?.error?.json?.code === 'moderation_request_cancelled') {
      toast.error('This moderation request was cancelled by the user and cannot be approved.');
      // TODO: better way to reload moderation list - we're using some defaults in payload,
      //  but the current page query may be different.
      yield put(
        fetchModerationListSlice.actions.trigger({
          sort: defaultSortOrder,
          start: 0,
          search: '',
        })
      );
      yield put(push(DASHBOARD_URL));
    }
  }
  if (resultAction.type === PRODUCT_SUBMIT_API_SUCCESS) {
    yield fork(approveRequestSaga, action);
  }
}

const rejectSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: MODERATION_REJECT_API_URL,
  urlParams: ({ moderationId }: RejectPublishPayload) => ({ moderationId }),
  queryPayload: ({ message }: RejectPublishPayload) => ({ message }),
  responseSchema: {
    moderationRequest: ModerationRequestSchema,
    session: SessionSchema,
  },
  actions: rejectPublishSlice.actions,
});

function* visitDashboardPageSaga() {
  const moderationListUrl = generatePath(DASHBOARD_URL);
  yield put(push(moderationListUrl));
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(fetchModerationListSlice.actions.trigger.type, fetchSaga),
    takeLatest(reviewPublishSlice.actions.trigger.type, reviewSaga),
    takeLatest(reviewPublishSlice.actions.success.type, visitProductModerationPageSaga),
    takeLatest(approvePublishSlice.actions.trigger.type, approveSaga),
    takeLatest(approvePublishSlice.actions.success.type, visitDashboardPageSaga),
    takeLatest(rejectPublishSlice.actions.trigger.type, rejectSaga),
    takeLatest(rejectPublishSlice.actions.success.type, visitDashboardPageSaga),
  ]);
}
