/* eslint-disable  @typescript-eslint/no-explicit-any */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { NormalizedError } from '../../types';
import FormInputError from '../FormInputError/FormInputError';

type Props = {
  error: NormalizedError | null;
  name: string | ((json: any) => boolean);
  showWarningIcon?: boolean;
};

const FieldError: FC<Props> = ({ error, name, showWarningIcon }: Props) => {
  if (!error || !error.json) {
    return null;
  }

  const value = typeof name === 'string' ? error.json[name] : name(error.json);
  if (!value) {
    return null;
  }

  const fieldErrors = Array.isArray(value) ? value : [value];

  return (
    <>
      {fieldErrors.length && showWarningIcon && (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} size="xs" className="text-warning" />
          &nbsp;
        </>
      )}
      {fieldErrors.map((fieldError, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <FormInputError key={idx} isBlock>
          {fieldError.toString()}
        </FormInputError>
      ))}
    </>
  );
};

export default FieldError;
