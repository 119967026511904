import cx from 'classnames';
import React, { FC, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from '../../components/FalconCardHeader/FalconCardHeader';
import { isAuthenticated } from '../../helpers/utils';
import { UserRole } from '../../schemas';
import DashboardCharts from '../DashboardCharts/DashboardCharts';
import InventoryAlerts from '../InventoryAlerts/InventoryAlerts';
import Moderation from '../Moderation/Moderation';
import RejectedProducts from '../RejectedProducts/RejectedProducts';
import useDashboardData from './hooks/useDashboardData';

const mySiteName = (): string => {
  if (process.env.REACT_APP_SITE === 'shopnet') {
    return 'shopnet.superstoresystems.com';
  }
  if (process.env.REACT_APP_SITE === 'sss') {
    return 'superstoresystems.com';
  }
  return 'showshoppa.com';
};

const isCorrectSiteName = (siteName: string): boolean => {
  return siteName === mySiteName();
};

const Dashboard: FC = () => {
  const numMonths = 12;
  const {
    gotoLogin,
    gotoNoShops,
    gotoOtherShop,
    initTrigger,
    isInitialized,
    shop,
    user,
  } = useDashboardData();

  useEffect(() => {
    if (!isInitialized) initTrigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialized && !isAuthenticated(user)) {
      gotoLogin();
    }
  }, [gotoLogin, isInitialized, user]);

  useEffect(() => {
    if (isInitialized && isAuthenticated(user) && !shop) {
      gotoNoShops();
    }
  }, [gotoNoShops, isInitialized, shop, user]);

  useEffect(() => {
    if (isInitialized && isAuthenticated(user) && shop && !isCorrectSiteName(shop.siteName)) {
      gotoOtherShop(shop.siteName, shop.id);
    }
  });

  if (!shop) {
    return null;
  }

  return (
    <>
      <Card className={cx('h-lg-100 mb-3')}>
        <FalconCardHeader border={false} className="bg-white" title="Orders & Revenue" />
        <CardBody>
          <Row>
            <Col>
              <DashboardCharts numMonths={numMonths} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {user?.role === UserRole.Brand || user?.role === UserRole.Admin ? <Moderation /> : null}
      {user?.role === UserRole.Vendor && (
        <Row>
          <Col>
            <InventoryAlerts />
          </Col>
          <Col>
            <RejectedProducts />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Dashboard;
