import { push, replace } from 'connected-react-router';
import { stringifyUrl } from 'query-string';
import { SortOrder } from 'react-bootstrap-table-next';
import { Dispatch } from 'redux';
import { SortOrderDirection, StandardQueryParams } from '../../global/types';
import { PRODUCTS_URL } from '../../global/urls';
import { defaultSortOrder } from './constants';
import { ProductsSortOrder, SortableProductsColumn } from './types';

type QueryParams = StandardQueryParams & { type?: string };

export const getProductsUrl = (params: QueryParams): string => {
  const query: QueryParams = {};
  if (params.search) {
    query.search = params.search;
  }
  if (params.sort && params.sort !== defaultSortOrder) {
    query.sort = params.sort;
  }
  if (params.start) {
    query.start = params.start;
  }
  if (params.type) {
    query.type = params.type;
  }
  return stringifyUrl({ url: PRODUCTS_URL, query });
};

export const replaceProductsUrl = (dispatch: Dispatch, params: QueryParams): void => {
  const url = getProductsUrl(params);
  dispatch(replace(url, { keepState: true }));
};

export const pushProductsUrl = (
  dispatch: Dispatch,
  params: QueryParams,
  keepState = false
): void => {
  const url = getProductsUrl(params);
  dispatch(push(url, { keepState }));
};

export const mapTableSort = (
  sortOrder: ProductsSortOrder
): { dataField: ProductsSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as ProductsSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};

export const mapSortOrder = (
  columnName: SortableProductsColumn,
  sortDirection: SortOrderDirection
): ProductsSortOrder => {
  const sortOrder = ProductsSortOrder[columnName];
  return sortDirection === SortOrderDirection.asc
    ? sortOrder
    : (`-${sortOrder}` as ProductsSortOrder);
};
