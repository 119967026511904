// edit
export const ORDER_EDIT_ITEM_QUANTITY = 'ORDER_EDIT_ITEM_QUANTITY';

// fetch
export const ORDER_FETCH_API = 'ORDER_FETCH_API';
export const ORDER_FETCH_API_FAILURE = 'ORDER_FETCH_API_FAILURE';
export const ORDER_FETCH_API_REQUEST = 'ORDER_FETCH_API_REQUEST';
export const ORDER_FETCH_API_SUCCESS = 'ORDER_FETCH_API_SUCCESS';
export const ORDER_FETCH_TRIGGER = 'ORDER_FETCH_TRIGGER';

// fulfill
export const ORDER_FULFILL_API = 'ORDER_FULFILL_API';
export const ORDER_FULFILL_API_FAILURE = 'ORDER_FULFILL_API_FAILURE';
export const ORDER_FULFILL_API_REQUEST = 'ORDER_FULFILL_API_REQUEST';
export const ORDER_FULFILL_API_SUCCESS = 'ORDER_FULFILL_API_SUCCESS';
export const ORDER_FULFILL_TRIGGER = 'ORDER_FULFILL_TRIGGER';

// refund
export const ORDER_REFUND_API = 'ORDER_REFUND_API';
export const ORDER_REFUND_API_FAILURE = 'ORDER_REFUND_API_FAILURE';
export const ORDER_REFUND_API_REQUEST = 'ORDER_REFUND_API_REQUEST';
export const ORDER_REFUND_API_SUCCESS = 'ORDER_REFUND_API_SUCCESS';
export const ORDER_REFUND_TRIGGER = 'ORDER_REFUND_TRIGGER';

// select
export const ORDER_SELECT_ALL = 'ORDER_SELECT_ALL';
export const ORDER_TOGGLE_ITEM = 'ORDER_TOGGLE_ITEM';
