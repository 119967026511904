import { replace } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRouterState } from '../selectors';

const useSetupKeepState = (): string => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const locationState = location.state;

  // If location state doesn't contain 'keepState=true', remount the main content component using temporary key
  // and setup 'keepState=true' in the location state afterwards.
  // This is needed for 'searchInput' state to be resettable.
  useEffect(() => {
    if (!(locationState && locationState.keepState)) {
      const url = location.pathname + location.search + location.hash;
      dispatch(replace(url, { keepState: true }));
    }
  }, [dispatch, location.hash, location.pathname, location.search, locationState]);

  // return 'key' for MainContent component
  return !(locationState && locationState.keepState) ? 'replaceState' : '';
};

export default useSetupKeepState;
