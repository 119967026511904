import React, { FC } from 'react';
import CenteredWindow from '../../components/CenteredWindow/CenteredWindow';
import MainLogo from '../../components/MainLogo/MainLogo';
import EnterNewPassword from './components/EnterNewPassword/EnterNewPassword';
import InvalidToken from './components/InvalidToken/InvalidToken';
import PasswordChanged from './components/PasswordChanged/PasswordChanged';
import usePasswordResetData from './hooks/usePasswordResetData';
import { PasswordResetScreen } from './types';

const PasswordReset: FC = () => {
  const { changePasswordTrigger, error, screen } = usePasswordResetData();
  return (
    <CenteredWindow>
      <div>
        <div className="mb-4">
          <MainLogo />
        </div>
        {screen === PasswordResetScreen.ENTER_NEW_PASSWORD && (
          <EnterNewPassword changePasswordTrigger={changePasswordTrigger} error={error} />
        )}
        {screen === PasswordResetScreen.INVALID_TOKEN && <InvalidToken error={error} />}
        {screen === PasswordResetScreen.PASSWORD_CHANGED && <PasswordChanged error={error} />}
      </div>
    </CenteredWindow>
  );
};

export default PasswordReset;
