import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getSessionUser } from '../../../global/session/selectors';
import { AppState } from '../../../init/rootReducer';
import { ShopEntity, ShopSchema, UserEntity } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { getBrandProfileState } from '../selectors';
import { SaveBrandProfileRequestPayload, saveBrandProfileSlice } from '../slices';

interface BrandProfileData {
  error: NormalizedError | undefined;
  isLoading: boolean;
  loaded: boolean;
  logoUrl: string | null;
  saveBrandProfileTrigger: (payload: SaveBrandProfileRequestPayload) => void;
  shop: ShopEntity | null;
  shopId: number | null;
  user: UserEntity | null;
}

const useBrandProfileData = (): BrandProfileData => {
  const dispatch = useDispatch();
  const entities = useSelector(getEntitiesState);
  const user = useSelector(getSessionUser);

  const { error, isLoading, loaded } = useSelector(
    (state: AppState) => state.containers.brandProfileLoading
  );

  const { logoUrl, shopId } = useSelector(getBrandProfileState);

  const shop = useMemo(() => denormalize(shopId, ShopSchema, entities), [entities, shopId]);

  return {
    error,
    isLoading,
    loaded,
    logoUrl,
    saveBrandProfileTrigger: (payload) => dispatch(saveBrandProfileSlice.actions.trigger(payload)),
    shop,
    shopId,
    user,
  };
};

export default useBrandProfileData;
