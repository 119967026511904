import cx from 'classnames';
import { FieldAttributes, useField } from 'formik';
import React, { FC } from 'react';
import { CustomInput } from 'reactstrap';
import { CustomInputProps } from 'reactstrap/lib/CustomInput';

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
type Props = FieldAttributes<any> & CustomInputProps;

const CustomInputField: FC<Props> = (props: Props) => {
  const [field] = useField(props);
  return (
    <CustomInput
      {...field}
      {...props}
      className={cx('custom-sss-switch')}
      checked={field.value}
      type="switch"
    />
  );
};

export default CustomInputField;
