import cx from 'classnames';
import React, { ReactElement } from 'react';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { decodeImageSrc, formatPrice, isNotDefaultTitle } from '../../../../helpers/utils';
import { ProductImageEntity, RefundedItemEntity, ShopEntity } from '../../../../schemas';
import styles from './OrderRefunds.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const imageFormatter = (image: ProductImageEntity | null): ReactElement => (
  <CenterCropImg
    alt="product"
    className="rounded"
    height={32}
    src={decodeImageSrc(image)}
    width={32}
  />
);

export const itemFormatter = (name: string, row: RefundedItemEntity): ReactElement => {
  return (
    <div className={styles.OrderRefunds__item}>
      <div>{name}</div>
      <div className={styles.OrderRefunds__item__sku}>{row.sku}</div>
      <div>
        Reason: <span className={styles.OrderRefunds__item__reason}>{row.reason}</span>
      </div>
    </div>
  );
};

export const optionsFormatter = (options: string[]): ReactElement => {
  if (options === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      {options.filter(isNotDefaultTitle).map((option) => (
        <div key={option}>{option}</div>
      ))}
    </div>
  );
};

export const priceFormatter = (
  price: number,
  row: RefundedItemEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (price === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <div className={cx(tableStyles.textDark, 'text-nowrap')}>{formatPrice(price, currency)}</div>
  );
};

export const quantityFormatter = (quantity: number): ReactElement => {
  return <div className={cx(tableStyles.textDark, 'text-nowrap')}>{quantity}</div>;
};
