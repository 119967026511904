import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { LocationState } from '../../../global/types';
import { DASHBOARD_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import {
  ModerationRequestEntity,
  ModerationRequestSchema,
  ModerationRequestStatus,
} from '../../../schemas';
import { NormalizedError } from '../../../types';
import { getModerationListQueryState } from '../selectors';
import { fetchModerationListSlice } from '../slices/moderationSlice';
import { ModerationListSortOrder } from '../types';
import useModerationListQueryParams from './useModerationListQueryParams';

type ModerationListPageData = {
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | undefined;
  isLoading: boolean;
  loaded: boolean;
  locationState: LocationState;
  moderationList: ModerationRequestEntity[] | null;
  moderationListFetchTrigger(): void;
  search: string;
  sortOrder: ModerationListSortOrder;
  start: number;
};

const useModerationListPageData = (): ModerationListPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { locationState, search, sortOrder, start } = useModerationListQueryParams();
  const correctLocation = location.pathname === DASHBOARD_URL;
  const entities = useSelector(getEntitiesState);
  const { count, moderationRequestIds } = useSelector((state: AppState) =>
    getModerationListQueryState(state, sortOrder, start, search)
  );
  const { isLoading, loaded, error } = useSelector(
    (state: AppState) => state.containers.moderationListLoading
  );

  const moderationList = useMemo(
    () =>
      moderationRequestIds !== null
        ? moderationRequestIds
            .map((id) => denormalize(id, ModerationRequestSchema, entities))
            .filter((r: ModerationRequestEntity) => r.status === ModerationRequestStatus.Pending)
        : null,
    [entities, moderationRequestIds]
  );

  return {
    correctLocation,
    count,
    dispatch,
    error,
    isLoading,
    loaded,
    locationState,
    moderationList,
    moderationListFetchTrigger: () =>
      dispatch(fetchModerationListSlice.actions.trigger({ sort: sortOrder, start, search })),
    search,
    sortOrder,
    start,
  };
};

export default useModerationListPageData;
