import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { Col } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { UserEntity, UserRole } from '../../../../schemas';

type Props = {
  user: UserEntity | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShippingPoliciesRightControls: FC<Props> = ({ user }: Props) => {
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        {(user?.role === UserRole.Admin || user?.role === UserRole.Brand) && (
          <PrimaryButton className="mx-2" icon={faPlus} size="small">
            Add Policy
          </PrimaryButton>
        )}
        {/* <HeaderButton className="mx-2" icon={faExternalLinkAlt}> */}
        {/*   Import */}
        {/* </HeaderButton> */}
        {/* <HeaderButton className="mx-2" icon={faExternalLinkAlt}> */}
        {/*   Export */}
        {/* </HeaderButton> */}
      </Col>
    </>
  );
};

export default ShippingPoliciesRightControls;
