import arrayMove from 'array-move';
import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntitiesState } from '../../../../global/entities/selectors';
import { AppState } from '../../../../init/rootReducer';
import { ShippingType, ShippingTypeSchema } from '../../../../schemas';
import { NormalizedError } from '../../../../types';
import {
  fetchShippingTypesSlice,
  ReorderArgs,
  saveShippingTypesOrderSlice,
} from '../../slices/shippingTypesSlice';

export type ShippingTypesPageData = {
  shippingTypes: ShippingType[] | null;
  fetchShippingTypesTrigger: () => void;
  isLoading: boolean;
  loaded: boolean;
  error: NormalizedError | undefined;
  actionError: NormalizedError | undefined;
  reorderShippingTypes: (args: ReorderArgs) => void;
};

const useShippingTypesData = (): ShippingTypesPageData => {
  const shippingTypeIds = useSelector(
    (state: AppState) => state.containers.shippingTypes.shippingTypeIds
  );
  const entities = useSelector(getEntitiesState);

  const shippingTypes = useMemo(
    () =>
      shippingTypeIds !== null
        ? shippingTypeIds.map((id) => denormalize(id, ShippingTypeSchema, entities))
        : null,
    [entities, shippingTypeIds]
  );

  const shippingTypesLoading = useSelector(
    (state: AppState) => state.containers.shippingTypesLoading
  );

  const createShippingTypeLoading = useSelector(
    (state: AppState) => state.containers.createShippingTypeLoading
  );

  const isLoading = shippingTypesLoading.isLoading || createShippingTypeLoading.isLoading;
  const { loaded } = shippingTypesLoading;
  const { error } = shippingTypesLoading;
  const actionError = createShippingTypeLoading.error;

  const dispatch = useDispatch();

  const fetchShippingTypesTrigger = () => dispatch(fetchShippingTypesSlice.actions.trigger());

  const reorderShippingTypes = ({ oldIndex, newIndex }: ReorderArgs) => {
    if (!shippingTypeIds) return;
    const newIds = arrayMove(shippingTypeIds, oldIndex, newIndex);
    dispatch(saveShippingTypesOrderSlice.actions.trigger({ shippingTypeIds: newIds }));
  };

  return {
    shippingTypes,
    fetchShippingTypesTrigger,
    isLoading,
    loaded,
    error,
    actionError,
    reorderShippingTypes,
  };
};

export default useShippingTypesData;
