import produce from 'immer';
import { Reducer } from 'redux';
import {
  InventoryFetchApiFailureAction,
  InventoryFetchApiRequestAction,
  InventoryFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  INVENTORY_FETCH_API_FAILURE,
  INVENTORY_FETCH_API_REQUEST,
  INVENTORY_FETCH_API_SUCCESS,
} from '../constants';
import { InventoryQueryState } from '../types';

export const initialQueryState: InventoryQueryState = {
  count: 0,
  error: null,
  loaded: false,
  inventoryItemIds: null,
};

export type InventoryQueryActions =
  | InventoryFetchApiFailureAction
  | InventoryFetchApiRequestAction
  | InventoryFetchApiSuccessAction;

export const inventoryQueryReducer: Reducer<InventoryQueryState, InventoryQueryActions> = (
  state: InventoryQueryState = initialQueryState,
  action: InventoryQueryActions
): InventoryQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case INVENTORY_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case INVENTORY_FETCH_API_SUCCESS: {
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.inventoryItemIds = action.payload.response.result.inventoryItems;
        break;
      }

      case INVENTORY_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
