/* eslint-disable  import/prefer-default-export */
import { call } from 'redux-saga/effects';
import { checkIfForbiddenSaga } from '../global/auth/sagas';
import { isForbiddenError, isValidationError } from '../global/auth/utils';
import toast from '../global/utils/toast';

//
// generic subsaga for handling errors in API sagas
//
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* handleFailureSaga(error: Error) {
  if (!isForbiddenError(error)) {
    const errorMessage = isValidationError(error)
      ? 'Please check the page for details.'
      : 'An error occured.';
    yield call(toast.error, errorMessage);
  }
  yield* checkIfForbiddenSaga(error);
}
