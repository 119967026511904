import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { apiGet, normalizeError } from '../../helpers/api';
import { SessionSchema } from '../../schemas';
import { isSessionInitialized } from '../session/selectors';
import { INIT_API_URL } from '../urls';
import {
  initApiFailure,
  initApiRequest,
  initApiSuccess,
  InitNormalizedResponse,
  initTrigger,
} from './actions';
import { INIT_API_FAILURE, INIT_API_SUCCESS, INIT_TRIGGER } from './constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* initIfNeededSaga() {
  const isInitialized = yield select(isSessionInitialized);
  if (!isInitialized) {
    yield put(initTrigger());
    yield take([INIT_API_SUCCESS, INIT_API_FAILURE]);
  }
}

function* initSaga() {
  yield put(initApiRequest());

  try {
    const response = yield call(apiGet, INIT_API_URL);
    const normalizedResponse: InitNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });
    yield put(initApiSuccess(normalizedResponse));
  } catch (error) {
    yield put(initApiFailure(normalizeError(error)));
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(INIT_TRIGGER, initSaga)]);
}
