import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, ReactElement, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { v4 } from 'uuid';
import FinancialStatusBadge from '../../../../components/FinancialStatusBadge/FinancialStatusBadge';
import FulfillmentStatusBadge from '../../../../components/FulfillmentStatusBadge/FulfillmentStatusBadge';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { ORDER_URL } from '../../../../global/urls';
import { formatDateShort, formatPrice } from '../../../../helpers/utils';
import { FinancialStatus, FulfillmentStatus, OrderEntity, ShopEntity } from '../../../../schemas';
import styles from './formatters.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

type FraudRiskProps = {
  fraudRisk: number;
};

const FraudRisk: FC<FraudRiskProps> = ({ fraudRisk }: FraudRiskProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipId = useMemo(() => v4(), []);

  if (!fraudRisk) return null;
  return (
    <span>
      {' '}
      <span id={`custom-tooltip-${tooltipId}`} className={cx(styles.target)}>
        <FontAwesomeIcon
          className={fraudRisk === 1 ? styles.name__fraudRisk1 : styles.name__fraudRisk2}
          icon={faExclamationTriangle}
        />
      </span>
      <Tooltip
        isOpen={tooltipOpen}
        placement="right"
        target={`custom-tooltip-${tooltipId}`}
        toggle={toggle}
      >
        This order has been flagged as possibly fraudulent. To reduce your risk we suggest you
        contact the customer by phone to confirm their details before fulfilling this order.
      </Tooltip>
    </span>
  );
};

export const orderNameFormatter = (orderName: string, row: OrderEntity): ReactElement => (
  <Link
    className={cx(tableStyles.textDark, styles.name)}
    to={generatePath(ORDER_URL, { orderName })}
  >
    {orderName}
    {row.fraudRisk && <FraudRisk fraudRisk={row.fraudRisk} />}
  </Link>
);

export const dateFormatter = (
  value: string,
  row: OrderEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx('text-nowrap', tableStyles.textDark)}>
      {formatDateShort(value, currency)}
    </span>
  );
};

export const customerFormatter = (customerName: string): ReactElement => {
  return <span className={tableStyles.textDark}>{customerName}</span>;
};

export const vendorFormatter = (vendorName: string): ReactElement => {
  return <span className={tableStyles.textDark}>{vendorName}</span>;
};

export const fulfillmentFormatter = (status: FulfillmentStatus): ReactElement => {
  return <FulfillmentStatusBadge status={status} />;
};

export const financialsFormatter = (status: FinancialStatus): ReactElement => {
  return <FinancialStatusBadge status={status} />;
};

export const totalPriceFormatter = (
  totalPrice: number,
  row: OrderEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (totalPrice === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark, styles.totalPrice)}>
      {formatPrice(totalPrice, currency)}
    </span>
  );
};
