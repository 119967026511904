import cx from 'classnames';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Row } from 'reactstrap';
import LoadingSwitch from '../../components/LoadingSwitch/LoadingSwitch';
import SearchBox from '../../components/SearchBox/SearchBox';
import TableLoadingIndicator from '../../components/TableLoadingIndicator/TableLoadingIndicator';
import styles from './LoginAs.module.scss';
import useLoginAsData from './hooks/useLoginAsData';
import { replaceLoginAsUrl } from './utils';

const renderError = () => {
  return (
    <div
      style={{
        marginTop: '20px',
        border: '2px solid red',
        padding: '20px',
      }}
    >
      ERROR!
    </div>
  );
};

const LoginAs: FC = () => {
  const {
    correctLocation,
    dispatch,
    error,
    isLoading,
    loginAsFetchTrigger,
    loginAsTrigger,
    search,
    users,
  } = useLoginAsData();

  const [searchInput, setSearchInput] = useState(search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((newSearch: string, oldSearch: string) => {
      if (newSearch !== oldSearch) {
        replaceLoginAsUrl(dispatch, { search: newSearch });
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (correctLocation) {
      debounceSearch(searchInput, search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, search, searchInput]);

  return (
    <LoadingSwitch
      error={!!error}
      loading={isLoading}
      onLoad={loginAsFetchTrigger}
      renderError={renderError}
      renderLoading={() => <TableLoadingIndicator />}
      require={!!users}
    >
      <div>
        <h5 className={cx(styles.header)}>Login as...</h5>
        <Row className="mb-3 ml-1">
          <SearchBox
            focus
            type="search"
            placeholder="Search..."
            value={searchInput}
            onChange={setSearchInput}
          />
        </Row>
        <div>
          {users &&
            users.map((user) => {
              return (
                <Button
                  key={user.id}
                  block
                  color="falcon-primary"
                  className="mr-2"
                  onClick={() => loginAsTrigger(user.id)}
                >
                  {user.username}
                </Button>
              );
            })}
        </div>
      </div>
    </LoadingSwitch>
  );
};

export default LoginAs;
