import cx from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row } from 'reactstrap';
import styles from './App.module.scss';
import LeftColumn from './containers/LeftColumn/LeftColumn';
import MainHeader from './containers/MainHeader/MainHeader';
import { getSessionLanguage, getSessionUser } from './global/session/selectors';
import routes from './routes';
import { UserRole } from './schemas';
import i18next from './services/i18n';

const createRoutes = () =>
  routes.map((route) =>
    route.separate ? (
      <Route path={route.path} exact={route.exact} key={route.name} component={route.component} />
    ) : (
      <Route path={route.path} exact={route.exact} key={route.name}>
        <div className="d-flex flex-row w-100">
          <LeftColumn />
          <div className="flex-grow-1">
            <MainHeader />
            <div className="pl-4 w-100">{React.createElement(route.component)}</div>
          </div>
        </div>
      </Route>
    )
  );

const App: FC = () => {
  const user = useSelector(getSessionUser);
  const lang = useSelector(getSessionLanguage);

  i18next.changeLanguage(lang);
  const isBrand = user?.role === UserRole.Brand || user?.role === UserRole.Admin;

  return (
    <Container
      fluid
      className={cx('main-container min-vh-100', isBrand && styles['App__container--brand'])}
    >
      <Row noGutters>
        <Switch>{createRoutes()}</Switch>
        <ToastContainer />
      </Row>
    </Container>
  );
};

export default App;
