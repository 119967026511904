import { ColumnDescription } from 'react-bootstrap-table-next';
import { loaderFormatter } from '../../../../helpers/formatters';
import {
  actionsFormatter,
  additionalFeeFormatter,
  availabilityFormatter,
  baseFeeFormatter,
  freeFormatter,
  shippingFreeAboveFormatter,
  shippingMaxCapFormatter,
  shippingTypeFormatter,
} from './formatters';

export const columns = (): ColumnDescription[] => {
  return [
    {
      dataField: 'name',
      formatter: shippingTypeFormatter,
      text: 'Shipping Type',
    },
    {
      dataField: 'availability',
      formatter: availabilityFormatter,
      text: 'Availability',
    },
    {
      dataField: 'free',
      formatter: freeFormatter,
      text: 'Free',
    },
    {
      dataField: 'baseFee',
      formatter: baseFeeFormatter,
      text: 'Base Fee',
    },
    {
      dataField: 'additionalFee',
      formatter: additionalFeeFormatter,
      text: 'Additional Fee',
    },
    {
      dataField: 'shippingFreeAboveOrderValue',
      formatter: shippingFreeAboveFormatter,
      text: 'Shipping Free Above',
    },
    {
      dataField: 'shippingMaxCap',
      formatter: shippingMaxCapFormatter,
      text: 'Shipping Max Cap',
    },
    {
      dataField: '',
      text: '',
      formatter: actionsFormatter,
    },
  ];
};

export const loadingColumns = (): ColumnDescription[] =>
  columns().map((column) => ({
    ...column,
    formatter: loaderFormatter,
  }));
