import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import { Form, Input } from 'reactstrap';
import styles from './SearchBox.module.scss';

type Props = {
  onChange: (newValue: string) => void;
  placeholder?: string;
  type: 'search';
  value: string;
  focus?: boolean;
};

const SearchBox: FC<Props> = ({ focus, onChange, placeholder, type, value }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus, inputRef]);

  return (
    <Form className={cx('search-box')}>
      <Input
        type={type}
        placeholder={placeholder || ''}
        aria-label="Search"
        className={cx('rounded-pill search-input', styles.searchInput)}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        innerRef={inputRef}
      />
      <FontAwesomeIcon
        icon={faSearch}
        className={cx('position-absolute text-400 search-box-icon', styles.searchIcon)}
      />
    </Form>
  );
};

export default SearchBox;
