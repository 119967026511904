import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop } from '../../../global/session/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { GIFT_CARDS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import {
  GiftCardCodeEntity,
  GiftCardCodeSchema,
  GiftCardProductEntity,
  GiftCardProductSchema,
  ShopEntity,
} from '../../../schemas';
import { NormalizedError } from '../../../types';
import { giftCardCodesFetchTrigger } from '../actions';
import { GIFT_CARDS_FETCH_API_PREFIX } from '../constants';
import { getGiftCardsQueryState } from '../selectors';
import { GiftCardCodesSortOrder, GiftCardProductsSortOrder } from '../types';
import useGiftCardsQueryParams from './useGiftCardsQueryParams';

type GiftCardCodesPageData = {
  codesCount: number;
  codesSortOrder: GiftCardCodesSortOrder;
  correctLocation: boolean;
  dispatch: Dispatch;
  error: NormalizedError | null;
  giftCardCodes: GiftCardCodeEntity[] | null;
  giftCardCodesFetchTrigger(): void;
  giftCardProducts: GiftCardProductEntity[] | null;
  isLoading: boolean;
  loaded: boolean;
  productsSortOrder: GiftCardProductsSortOrder;
  search: string;
  shop: ShopEntity | null;
  start: number;
};

const useGiftCardsPageData = (): GiftCardCodesPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { codesSortOrder, productsSortOrder, search, start } = useGiftCardsQueryParams();
  const correctLocation = location.pathname === GIFT_CARDS_URL;
  const entities = useSelector(getEntitiesState);
  const shop = useSelector(getSessionActiveShop);
  const {
    codesCount,
    error,
    loaded,
    giftCardCodeIds,
    giftCardProductIds,
  } = useSelector((state: AppState) =>
    getGiftCardsQueryState(state, productsSortOrder, codesSortOrder, start, search)
  );

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, GIFT_CARDS_FETCH_API_PREFIX)
  );

  const giftCardCodes = useMemo(
    () =>
      giftCardCodeIds === null
        ? giftCardCodeIds
        : giftCardCodeIds.map((giftCardCodeId) =>
            denormalize(giftCardCodeId, GiftCardCodeSchema, entities)
          ),
    [entities, giftCardCodeIds]
  );

  const giftCardProducts = useMemo(
    () =>
      giftCardProductIds === null
        ? giftCardProductIds
        : giftCardProductIds.map((giftCardProductId) =>
            denormalize(giftCardProductId, GiftCardProductSchema, entities)
          ),
    [entities, giftCardProductIds]
  );

  return {
    codesCount,
    correctLocation,
    codesSortOrder,
    dispatch,
    error,
    giftCardCodes,
    giftCardCodesFetchTrigger: () =>
      dispatch(giftCardCodesFetchTrigger(productsSortOrder, codesSortOrder, start, search)),
    giftCardProducts,
    isLoading,
    loaded,
    productsSortOrder,
    search,
    shop,
    start,
  };
};

export default useGiftCardsPageData;
