import produce from 'immer';
import { Reducer } from 'redux';
import { NormalizedError } from '../../types';
import {
  PasswordResetApiFailureAction,
  PasswordResetApiRequestAction,
  PasswordResetApiSuccessAction,
} from './actions/passwordReset';
import {
  PasswordResetVerifyTokenApiFailureAction,
  PasswordResetVerifyTokenApiRequestAction,
  PasswordResetVerifyTokenApiSuccessAction,
} from './actions/passwordResetVerifyToken';
import {
  PASSWORD_RESET_API_FAILURE,
  PASSWORD_RESET_API_REQUEST,
  PASSWORD_RESET_API_SUCCESS,
  PASSWORD_RESET_VERIFY_TOKEN_API_FAILURE,
  PASSWORD_RESET_VERIFY_TOKEN_API_REQUEST,
  PASSWORD_RESET_VERIFY_TOKEN_API_SUCCESS,
} from './constants';

export type PasswordResetPageState = {
  error: NormalizedError | null;
  verified: boolean;
};

const initialState: PasswordResetPageState = {
  error: null,
  verified: false,
};

type PasswordResetPageActions =
  | PasswordResetApiFailureAction
  | PasswordResetApiRequestAction
  | PasswordResetApiSuccessAction
  | PasswordResetVerifyTokenApiFailureAction
  | PasswordResetVerifyTokenApiRequestAction
  | PasswordResetVerifyTokenApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const passwordResetPageReducer: Reducer<PasswordResetPageState, PasswordResetPageActions> = (
  state = initialState,
  action: PasswordResetPageActions
): PasswordResetPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case PASSWORD_RESET_VERIFY_TOKEN_API_REQUEST:
      case PASSWORD_RESET_API_REQUEST: {
        draft.error = null;
        break;
      }

      case PASSWORD_RESET_VERIFY_TOKEN_API_SUCCESS: {
        draft.verified = true;
        break;
      }

      case PASSWORD_RESET_API_SUCCESS: {
        break;
      }

      case PASSWORD_RESET_VERIFY_TOKEN_API_FAILURE:
      case PASSWORD_RESET_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
