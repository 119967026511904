/* eslint-disable  @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import { NormalizedError } from '../types';

type Payload = Record<string, any>;

export class ApiError extends Error {
  public statusCode: number;

  public json: any;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(statusCode: number, json: any) {
    const message = statusCode.toString();
    super(message);
    this.statusCode = statusCode;
    this.json = json;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function apiGet(url: string) {
  const fullUrl = process.env.REACT_APP_BACKEND_API_URL + url;
  const response = await fetch(fullUrl, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    let json = null;
    try {
      json = await response.json();
    } catch (error) {
      // ignore JSON errors
    }
    throw new ApiError(response.status, json);
  }

  return response.json();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function apiPost(url: string, payload: Payload, method = 'POST') {
  const csrftoken = Cookies.get('csrftoken');
  if (!csrftoken) {
    throw new Error('missing csrftoken');
  }

  const fullUrl = process.env.REACT_APP_BACKEND_API_URL + url;

  const requestHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
  };

  const response = await fetch(fullUrl, {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    let json = null;
    try {
      json = await response.json();
    } catch (error) {
      // ignore JSON errors
    }
    throw new ApiError(response.status, json);
  }

  return response.json();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function apiPut(url: string, payload: Payload) {
  return apiPost(url, payload, 'PUT');
}

// Wrapper that will accept parameters uniformly for all methods
export async function apiCall(url: string, method: 'GET'): Promise<any>;
export async function apiCall(url: string, method: string, payload: Payload): Promise<any>;
export async function apiCall(url: string, method: string, payload?: Payload): Promise<any> {
  switch (method) {
    case 'GET':
      return apiGet(url);
    default:
      return apiPost(url, payload as Payload, method);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function normalizeError(error: any): NormalizedError {
  const normalizedError: NormalizedError = {
    json: error.json,
    message: 'UNKNOWN',
    statusCode: error.statusCode,
  };
  if (error.statusCode === 400) {
    normalizedError.message = 'BAD REQUEST';
  } else if (error.statusCode === 404) {
    normalizedError.message = 'NOT FOUND';
  } else if (error.statusCode === 410) {
    normalizedError.message = 'GONE';
  } else if (error.statusCode === 500) {
    normalizedError.message = 'INTERNAL SERVER ERROR';
  } else if (error instanceof Error) {
    normalizedError.message = error.message ? error.message : error.toString();
  }
  return normalizedError;
}
