import { Location } from 'history';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { LocationState } from '../../../global/types';
import { defaultSortOrder } from '../constants';
import { VendorsSortOrder, VendorsType } from '../types';

type VendorsQueryParams = {
  location: Location<LocationState>;
  locationState: LocationState;
  search: string;
  sortOrder: VendorsSortOrder;
  start: number;
  type: VendorsType;
};

const parseVendorsSortOrder = (sort: string): VendorsSortOrder => {
  const sortOrders = Object.values(VendorsSortOrder);
  return sortOrders.includes(sort as VendorsSortOrder)
    ? (sort as VendorsSortOrder)
    : defaultSortOrder;
};

const VENDOR_TYPES: { [K: string]: VendorsType } = {
  active: VendorsType.Active,
  inactive: VendorsType.Inactive,
  invited: VendorsType.Invited,
  // shopnet: VendorsType.ShopNet,
};

const parseVendorsType = (type: string): VendorsType => {
  return VENDOR_TYPES[type] || VendorsType.Active;
};

const useVendorsQueryParams = (): VendorsQueryParams => {
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const start: number = parsed.start ? parseInt(parsed.start.toString(), 10) : 0;
  const search: string = parsed.search ? parsed.search.toString() : '';
  const sortOrder = parseVendorsSortOrder(parsed.sort ? parsed.sort.toString() : '');
  const type: VendorsType = parseVendorsType(parsed.type ? parsed.type.toString() : '');
  const { state } = location;

  return {
    location,
    locationState: state,
    search,
    sortOrder,
    start,
    type,
  };
};

export default useVendorsQueryParams;
