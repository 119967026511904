import { CollectionsEditFormValues } from 'containers/Collection/types';
import { Field, FormikProps } from 'formik';
import React, { FC } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import styles from '../../../Collections/components/CollectionsEditModal/CollectionsEditModal.module.scss';

type Props = {
  fprops: FormikProps<CollectionsEditFormValues>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CollectionTitle: FC<Props> = ({ fprops, onChange }: Props) => (
  <FormGroup>
    <Label className={styles.CollectionsEditModal__label} for="collections-edit-title">
      Title
    </Label>
    <Field
      as={Input}
      id="collections-edit-title"
      name="title"
      onChange={onChange}
      placeholder="Title"
      type="text"
    />
    {fprops.errors.title && fprops.touched.title && <div>{fprops.errors.title}</div>}
  </FormGroup>
);

export default CollectionTitle;
