import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { formatDateFancy } from '../../../../helpers/utils';
import { ShipmentEntity, ShopEntity } from '../../../../schemas';
import styles from './OrderFulfillmentHeader.module.scss';

interface Props {
  fulfillment: ShipmentEntity;
  shop: ShopEntity | null;
}

const OrderFulfillmentHeader: FC<Props> = ({ fulfillment, shop }: Props) => {
  return (
    <Row className={styles.OrderFulfillmentHeader} noGutters>
      <Col>
        <div className={styles.OrderFulfillmentHeader__name}>{fulfillment.name}</div>
        <div>{formatDateFancy(fulfillment.createdAt, shop?.currency)}</div>
      </Col>
      <Col>
        <div>Shipping</div>
        <div className={styles.OrderFulfillmentHeader__shipping}>{fulfillment.shippingTypes}</div>
      </Col>
      <Col>
        <div>Tracking</div>
        <div className={styles.OrderFulfillmentHeader__trackingNumber}>
          {fulfillment.trackingCode}
        </div>
      </Col>
    </Row>
  );
};

export default OrderFulfillmentHeader;
