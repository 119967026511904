import { faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { Col, Input } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import styles from './CollectionsRightControls.module.scss';

type Props = {
  onAddCollection: () => void;
  searchInput: string;
  setSearchInput: (newValue: string) => void;
};

const CollectionsRightControls: FC<Props> = ({
  onAddCollection,
  searchInput,
  setSearchInput,
}: Props) => {
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />
        <PrimaryButton className="mx-2" icon={faPlus} onClick={onAddCollection} size="small">
          Add Collection
        </PrimaryButton>
        {/* <HeaderButton className="mx-2" icon={faExternalLinkAlt}> */}
        {/*   Import */}
        {/* </HeaderButton> */}
        {/* <HeaderButton className="mx-2" icon={faExternalLinkAlt}> */}
        {/*   Export */}
        {/* </HeaderButton> */}
      </Col>
    </>
  );
};

export default CollectionsRightControls;
