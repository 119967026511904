import { NormalizedError } from '../../types';

export interface FormItem {
  allowOverselling: boolean;
  quantity: number;
  trackInventory: boolean;
}

export type FormItems = { [itemId: number]: FormItem };

export interface FormValues {
  items: FormItems;
}

export type InventoryQueryState = {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  inventoryItemIds: number[] | null;
};

export interface InventorySearchState {
  allIds: number[] | null;
  formItems: FormItems;
}

export interface InventoryPageState {
  queries: { [queryKey: string]: InventoryQueryState };
  searches: { [search: string]: InventorySearchState };
  selected: number[];
}

export enum InventorySortOrder {
  position = 'position',
  positionDesc = '-position',
}

export interface InventorySubmitValues {
  allowOverselling?: boolean;
  quantity?: number;
  trackInventory?: boolean;
}
