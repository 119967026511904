import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  INVENTORY_FETCH_API_FAILURE,
  INVENTORY_FETCH_API_REQUEST,
  INVENTORY_FETCH_API_SUCCESS,
  INVENTORY_FETCH_TRIGGER,
} from '../constants';
import { InventorySortOrder } from '../types';

// action types

type InventoryFetchPayload = {
  sort: InventorySortOrder;
  start: number;
  search: string;
};

export interface InventoryFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    count: number;
    ids: number[];
    inventoryItems: number[];
  };
}

export interface InventoryFetchApiRequestAction extends APIRequestAction {
  type: typeof INVENTORY_FETCH_API_REQUEST;
  payload: InventoryFetchPayload;
}

export type InventoryFetchTriggerAction = {
  type: typeof INVENTORY_FETCH_TRIGGER;
  payload: InventoryFetchPayload;
};

export interface InventoryFetchApiSuccessAction extends APISuccessAction {
  type: typeof INVENTORY_FETCH_API_SUCCESS;
  payload: InventoryFetchPayload & {
    response: InventoryFetchNormalizedResponse;
  };
}

export interface InventoryFetchApiFailureAction extends APIFailureAction {
  type: typeof INVENTORY_FETCH_API_FAILURE;
  payload: InventoryFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const inventoryFetchTrigger = (
  sort: InventorySortOrder,
  start: number,
  search: string
): InventoryFetchTriggerAction => ({
  type: INVENTORY_FETCH_TRIGGER,
  payload: {
    search,
    sort,
    start,
  },
});

export const inventoryFetchApiRequest = (
  sort: InventorySortOrder,
  start: number,
  search: string
): InventoryFetchApiRequestAction => ({
  type: INVENTORY_FETCH_API_REQUEST,
  payload: { search, sort, start },
});

export const inventoryFetchApiSuccess = (
  sort: InventorySortOrder,
  start: number,
  search: string,
  response: InventoryFetchNormalizedResponse
): InventoryFetchApiSuccessAction => ({
  type: INVENTORY_FETCH_API_SUCCESS,
  payload: { response, search, sort, start },
});

export const inventoryFetchApiFailure = (
  sort: InventorySortOrder,
  start: number,
  search: string,
  error: NormalizedError
): InventoryFetchApiFailureAction => ({
  type: INVENTORY_FETCH_API_FAILURE,
  payload: { error, search, sort, start },
});
