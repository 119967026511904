import { ApiSuccessResultActionPayload, makeApiRequestSlice } from '../../helpers/apiRequestSlice';

// invite vendor

interface InviteVendorRecord {
  commission: number;
  email: string;
  maxProducts: number | null;
  moderation: boolean;
  name: string;
}

export type InviteVendorRequestPayload = { invites: InviteVendorRecord[] };

export type InviteVendorResult = {
  vendorsInvited: number;
};

export type InviteVendorSuccessPayload = ApiSuccessResultActionPayload<
  InviteVendorRequestPayload,
  InviteVendorResult
>;

export const inviteVendorSlice = makeApiRequestSlice<
  InviteVendorRequestPayload,
  InviteVendorSuccessPayload
>({ name: 'vendors/invite' });

// delete vendor

export type DeleteVendorInviteRequestPayload = { inviteId: number };

export type DeleteVendorInviteResult = Record<string, never>;

export type DeleteVendorInviteSuccessPayload = ApiSuccessResultActionPayload<
  DeleteVendorInviteRequestPayload,
  DeleteVendorInviteResult
>;

export const deleteVendorInviteSlice = makeApiRequestSlice<
  DeleteVendorInviteRequestPayload,
  DeleteVendorInviteSuccessPayload
>({ name: 'vendor-invite/delete' });

// deactivate vendor

export type DeactivateVendorRequestPayload = { vendorId: number };

export type DeactivateVendorResult = Record<string, never>;

export type DeactivateVendorSuccessPayload = ApiSuccessResultActionPayload<
  DeactivateVendorRequestPayload,
  DeactivateVendorResult
>;

export const deactivateVendorSlice = makeApiRequestSlice<
  DeactivateVendorRequestPayload,
  DeactivateVendorSuccessPayload
>({ name: 'vendor/deactivate' });

// reactivate vendor

export type ReactivateVendorRequestPayload = { vendorId: number };

export type ReactivateVendorResult = Record<string, never>;

export type ReactivateVendorSuccessPayload = ApiSuccessResultActionPayload<
  ReactivateVendorRequestPayload,
  ReactivateVendorResult
>;

export const reactivateVendorSlice = makeApiRequestSlice<
  ReactivateVendorRequestPayload,
  ReactivateVendorSuccessPayload
>({ name: 'vendor/reactivate' });
