import { SagaIterator } from '@redux-saga/core';
import { push } from 'connected-react-router';
import { warehouseAddToStoreSlice } from 'containers/Warehouse/slices';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { generatePath } from 'react-router-dom';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { reloadPageSaga, resetSessionIfNeededSaga } from '../../global/session/sagas';
import { PRODUCT_URL, WAREHOUSE_ADD_TO_STORE_API_URL, WAREHOUSE_API_URL } from '../../global/urls';
import toast from '../../global/utils/toast';
import { apiGet, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import { SessionSchema, WarehouseProductSchema } from '../../schemas';
import { archiveProductsSlice } from '../Products/slices';
import {
  productsFetchApiFailure,
  productsFetchApiRequest,
  productsFetchApiSuccess,
  ProductsFetchNormalizedResponse,
  ProductsFetchTriggerAction,
} from './actions/fetch.actions';
import { WAREHOUSE_FETCH_TRIGGER } from './constants';

function* fetchSaga({ payload }: ProductsFetchTriggerAction) {
  const { inStock, search, sort, start, type } = payload;
  yield put(productsFetchApiRequest(sort, start, search, type, inStock));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: WAREHOUSE_API_URL,
      query: {
        inStock: payload.inStock,
        search: payload.search,
        sort: payload.sort,
        start: payload.start,
        type: payload.type,
      },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: ProductsFetchNormalizedResponse = normalize(response, {
      briefProductVersions: [WarehouseProductSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(productsFetchApiSuccess(sort, start, search, type, inStock, normalizedResponse));
  } catch (error) {
    yield put(productsFetchApiFailure(sort, start, search, type, inStock, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

export const addToStoreSaga = makeApiRequestSaga({
  baseUrl: WAREHOUSE_ADD_TO_STORE_API_URL,
  method: 'POST',
  // urlParams: (payload: WarehouseAddToStoreRequestPayload) => payload,
  // urlParams: (payload) => payload,
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: warehouseAddToStoreSlice.actions,
});

function* addToStoreConfirmationSaga(
  action: ReturnType<typeof warehouseAddToStoreSlice.actions.success>
) {
  yield call(toast.success, 'Product has been imported');
  const { productId } = action.payload.response.result;
  const productUrl = generatePath(PRODUCT_URL, { productId });
  yield put(push(productUrl));
}

function* successBulkArchiveInfoSaga(
  action: ReturnType<typeof archiveProductsSlice.actions.success>
) {
  const numProducts = action.payload.response.result.archivedProducts;
  yield call(toast.success, `${numProducts} products archived.`);
  // Need to refresh page as archived products are hidden from the list
  yield reloadPageSaga();
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(WAREHOUSE_FETCH_TRIGGER, fetchSaga),
    takeLatest(warehouseAddToStoreSlice.actions.trigger.type, addToStoreSaga),
    takeLatest(warehouseAddToStoreSlice.actions.success.type, addToStoreConfirmationSaga),
  ]);
}
