import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { Field, Form, Formik, FormikErrors } from 'formik';
import React, { FC } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import styles from './OrderRefundModal.module.scss';

type OrderRefundModalType = 'items' | 'shipping';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (message: string) => void;
  type: OrderRefundModalType;
}

interface RefundFormState {
  reason: string;
}

function validate(values: RefundFormState) {
  const errors: FormikErrors<RefundFormState> = {};
  if (!values.reason) {
    errors.reason = 'Reason for refund should be specified';
  }
  return errors;
}

const OrderRefundModal: FC<Props> = ({ isOpen, toggle, onSubmit, type }: Props) => {
  const initialValues: RefundFormState = {
    reason: '',
  };

  const submit = ({ reason }: RefundFormState) => {
    onSubmit(reason);
    toggle();
  };

  return (
    <Modal
      centered
      contentClassName={styles.OrderRefundModal__content}
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className={styles.OrderRefundModal__header}>
        <div className={cx(styles.OrderRefundModal__heading)}>
          {type === 'items' ? 'Refund Items' : 'Refund Shipping'}
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik initialValues={initialValues} validate={validate} onSubmit={submit}>
          {(fprops) => (
            <Form onSubmit={fprops.handleSubmit}>
              <Row>
                <Col className={styles.OrderRefundModal__text}>
                  {type === 'items'
                    ? 'Do you wish to refund selected items?'
                    : 'Do you wish to refund shipping?'}
                </Col>
              </Row>
              <Row className={styles.OrderRefundModal__fieldRow}>
                <Col>
                  <FormGroup>
                    <Label className={styles.OrderRefundModal__label} for="reason">
                      Reason for refund
                    </Label>
                    <Field as={Input} name="reason" type="text" />
                  </FormGroup>
                  {fprops.errors.reason && <div id="feedback">{fprops.errors.reason}</div>}
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <PrimaryButton
                    className={styles.OrderRefundModal__cancelBtn}
                    icon={faTimes}
                    gray
                    onClick={toggle}
                    size="large"
                  >
                    Cancel
                  </PrimaryButton>
                  <PrimaryButton
                    className={styles.OrderRefundModal__refundBtn}
                    icon={faCheck}
                    negative
                    size="large"
                    type="submit"
                  >
                    {type === 'items' ? 'Refund items' : 'Refund'}
                  </PrimaryButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default OrderRefundModal;
