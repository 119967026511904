import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CountrySelector from '../../../../../../components/CountrySelector/CountrySelector';
import CustomTooltip from '../../../../../../components/CustomTooltip/CustomTooltip';
import FormInputError from '../../../../../../components/FormInputError/FormInputError';
import { getEuVatCountries, NULL_COUNTRY } from '../../../../../../global/countries/countries';
import { INPUT_ERROR_MESSAGE } from '../../../../../../global/general/constants';
import {
  validateHasOnlyLettersAndNumbers,
  validateHasOnlyLettersAndNumbersOrEmpty,
  validateOrgName,
} from '../../../../../../helpers/validators';
import useVendorRegistrationBusinessData from '../../../../hooks/useVendorRegistrationBusinessData';
import styles from './GeneralInfo.module.scss';

type Props = {
  setBusinessInputErrors: Dispatch<SetStateAction<{ general: boolean; registered: boolean }>>;
  submitTouched: boolean;
};

const GeneralInfo: FC<Props> = ({ setBusinessInputErrors, submitTouched }: Props) => {
  const { t } = useTranslation();
  const euVatCountries = useMemo(() => [NULL_COUNTRY, ...getEuVatCountries()], []);
  const {
    general: { legalName, companyNumber, displayName, vatNumber, vatPrefix },
    generalActions: {
      setLegalName,
      setCompanyNumber,
      setDisplayedName,
      setVatNumber,
      setVatPrefix,
    },
  } = useVendorRegistrationBusinessData();

  const inputTouched = useMemo(
    () => ({
      legalName: submitTouched,
      displayName: submitTouched,
      companyNumber: submitTouched,
      vatNumber: submitTouched,
    }),
    [submitTouched]
  );

  const inputErrors = useMemo(
    () => ({
      legalName: !validateOrgName(legalName),
      displayName: !validateOrgName(displayName) && displayName.length > 0,
      companyNumber: !validateHasOnlyLettersAndNumbersOrEmpty(companyNumber),
      vatNumber:
        (!validateHasOnlyLettersAndNumbers(vatNumber) && vatNumber.length > 0) ||
        (vatNumber && !vatPrefix),
    }),
    [companyNumber, displayName, legalName, vatNumber, vatPrefix]
  );
  const onInputChange = useCallback(
    ({ target }) => {
      const { name: inputName, value } = target;
      switch (inputName) {
        case 'legalName': {
          setLegalName(value);
          inputErrors.legalName = !validateOrgName(value);
          inputTouched.legalName = true;
          break;
        }
        case 'displayName': {
          setDisplayedName(value);
          inputErrors.displayName = !validateOrgName(value) && value.length > 0;
          inputTouched.displayName = true;
          break;
        }
        case 'companyNumber': {
          setCompanyNumber(value.toUpperCase());
          inputErrors.companyNumber = !validateHasOnlyLettersAndNumbersOrEmpty(value);
          inputTouched.companyNumber = true;
          break;
        }
        case 'vatNumber': {
          setVatNumber(value.toUpperCase());
          inputErrors.vatNumber =
            !validateHasOnlyLettersAndNumbers(value.toUpperCase()) && value.length > 0;
          inputTouched.vatNumber = true;
          break;
        }

        default:
          break;
      }
    },
    [inputErrors, inputTouched, setCompanyNumber, setDisplayedName, setLegalName, setVatNumber]
  );

  useEffect(() => {
    if (Object.values(inputErrors).every((error) => !error)) {
      setBusinessInputErrors((prevState) => ({ ...prevState, general: false }));
    } else {
      setBusinessInputErrors((prevState) => ({ ...prevState, general: true }));
    }
  }, [
    inputErrors,
    inputErrors.vatNumber,
    inputErrors.companyNumber,
    inputErrors.displayName,
    inputErrors.legalName,
    setBusinessInputErrors,
  ]);

  return (
    <>
      <h4 className="sss-form-title">General Info</h4>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              {t('LEGAL_NAME_OF_YOUR_ORG')} <span className="sss-color-orange">*</span>
            </Label>
            <Input type="text" value={legalName} name="legalName" onChange={onInputChange} />
            {inputErrors.legalName && inputTouched.legalName && (
              <FormInputError>{INPUT_ERROR_MESSAGE.ORG_NAME}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Display Name / Trading As{' '}
              <CustomTooltip text="This is the name that will be displayed to customers" />
            </Label>
            <Input type="text" value={displayName} name="displayName" onChange={onInputChange} />
            {inputErrors.displayName && inputTouched.displayName && (
              <FormInputError>{INPUT_ERROR_MESSAGE.ORG_NAME}</FormInputError>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Company Number (if applicable){' '}
              <CustomTooltip innerClassName={styles.GeneralInfo__tooltip}>
                If you do not have a company number
                <br />
                i.e you are a sole trader, leave this blank
              </CustomTooltip>
            </Label>
            <Input
              type="text"
              value={companyNumber}
              name="companyNumber"
              onChange={onInputChange}
              className="text-capitalize"
            />
            {inputErrors.companyNumber && inputTouched.companyNumber && (
              <FormInputError>{INPUT_ERROR_MESSAGE.LETTERS_AND_NUMBERS}</FormInputError>
            )}
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">VAT Number (if applicable)</Label>
            <div className="d-flex flex-row">
              <div>
                <CountrySelector
                  countries={euVatCountries}
                  countryNamesFormat="short"
                  dropdownMenuWidth={80}
                  onCountrySelect={setVatPrefix}
                  selectedCountryCode={vatPrefix}
                />
              </div>
              <Input type="text" value={vatNumber} name="vatNumber" onChange={onInputChange} />
            </div>
            {inputErrors.vatNumber && inputTouched.vatNumber && (
              <div style={{ marginLeft: '100px' }}>
                <FormInputError>
                  {vatNumber && !vatPrefix
                    ? 'VAT country prefix needs to be selected'
                    : INPUT_ERROR_MESSAGE.LETTERS_AND_NUMBERS}
                </FormInputError>
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default GeneralInfo;
