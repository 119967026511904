import produce from 'immer';
import { Reducer } from 'redux';
import { CustomersQueryActions } from '../actionTypes';
import {
  CUSTOMERS_FETCH_API_FAILURE,
  CUSTOMERS_FETCH_API_REQUEST,
  CUSTOMERS_FETCH_API_SUCCESS,
} from '../constants';
import { CustomersQueryState } from '../types';

export const initialQueryState: CustomersQueryState = {
  count: 0,
  error: null,
  loaded: false,
  customerIds: null,
};

export const customersQueryReducer: Reducer<CustomersQueryState, CustomersQueryActions> = (
  state: CustomersQueryState = initialQueryState,
  action: CustomersQueryActions
): CustomersQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case CUSTOMERS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case CUSTOMERS_FETCH_API_SUCCESS: {
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.customerIds = action.payload.response.result.customers;
        break;
      }

      case CUSTOMERS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
