/* eslint-disable  @typescript-eslint/no-explicit-any */
import { SessionSectionNormalized } from '../../../global/init/actions';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  SELECT_SHOP_API_FAILURE,
  SELECT_SHOP_API_REQUEST,
  SELECT_SHOP_API_SUCCESS,
  SELECT_SHOP_TRIGGER,
} from '../constants';

// interfaces

interface SelectShopPayload {
  shopId: number;
}

export interface SelectShopTriggerAction {
  type: typeof SELECT_SHOP_TRIGGER;
  payload: SelectShopPayload & {
    nextUrl: string;
  };
}

export interface SelectShopApiRequestAction extends APIRequestAction {
  type: typeof SELECT_SHOP_API_REQUEST;
  payload: SelectShopPayload;
}

export interface SelectShopNormalizedResponse extends NormalizedResponse {
  readonly result: {
    session: SessionSectionNormalized;
  };
}

export interface SelectShopApiSuccessAction extends APISuccessAction {
  type: typeof SELECT_SHOP_API_SUCCESS;
  payload: SelectShopPayload & {
    response: SelectShopNormalizedResponse;
  };
}

export interface SelectShopApiFailureAction extends APIFailureAction {
  type: typeof SELECT_SHOP_API_FAILURE;
  payload: SelectShopPayload & {
    error: NormalizedError;
  };
}

// action creators

export const selectShopTrigger = (shopId: number, nextUrl: string): SelectShopTriggerAction => ({
  type: SELECT_SHOP_TRIGGER,
  payload: { nextUrl, shopId },
});

export const selectShopApiRequest = (shopId: number): SelectShopApiRequestAction => ({
  type: SELECT_SHOP_API_REQUEST,
  payload: { shopId },
});

export const selectShopApiSuccess = (
  shopId: number,
  response: SelectShopNormalizedResponse
): SelectShopApiSuccessAction => ({
  type: SELECT_SHOP_API_SUCCESS,
  payload: { response, shopId },
});

export const selectShopApiFailure = (
  shopId: number,
  error: NormalizedError
): SelectShopApiFailureAction => ({
  type: SELECT_SHOP_API_FAILURE,
  payload: { error, shopId },
});
