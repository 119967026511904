import produce from 'immer';
import { Reducer } from 'redux';
import {
  VendorsFetchApiFailureAction,
  VendorsFetchApiRequestAction,
  VendorsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  VENDORS_FETCH_API_FAILURE,
  VENDORS_FETCH_API_REQUEST,
  VENDORS_FETCH_API_SUCCESS,
} from '../constants';
import {
  DeleteVendorInviteSuccessAction,
  VENDOR_INVITE_DELETE_SUCCESS,
  VendorsQueryState,
} from '../types';

export const initialQueryState: VendorsQueryState = {
  count: 0,
  error: null,
  loaded: false,
  vendorIds: null,
};

export type VendorsQueryActions =
  | DeleteVendorInviteSuccessAction
  | VendorsFetchApiFailureAction
  | VendorsFetchApiRequestAction
  | VendorsFetchApiSuccessAction;

export const vendorsQueryReducer: Reducer<VendorsQueryState, VendorsQueryActions> = (
  state: VendorsQueryState = initialQueryState,
  action: VendorsQueryActions
): VendorsQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case VENDORS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case VENDORS_FETCH_API_SUCCESS: {
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.vendorIds = action.payload.response.result.vendors;
        break;
      }

      case VENDORS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      case VENDOR_INVITE_DELETE_SUCCESS: {
        const { inviteId } = action.payload.request;
        draft.vendorIds = state.vendorIds && state.vendorIds.filter((vid) => vid !== inviteId);
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
