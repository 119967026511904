import React, { FC } from 'react';
import LinkButton from '../../../../components/LinkButton/LinkButton';

type Props = {
  areAllSelected: boolean;
  count: number;
  numItems: number | undefined;
  numSelected: number;
  onSelectAll: () => void;
};

const OrdersLeftControls: FC<Props> = ({
  areAllSelected,
  count,
  numItems,
  numSelected,
  onSelectAll,
}: Props) => {
  if (!numItems) {
    return null;
  }
  return (
    <>
      {/* <OrdersActionsDropdown component="header" disabled={numSelected === 0} /> */}
      {numSelected ? (
        <div className="ml-3 small text-nowrap">
          <b>{numSelected}</b> selected (<b>{count}</b> total)
          {!areAllSelected && (
            <LinkButton className="ml-3" onClick={onSelectAll} size="small">
              Select all
            </LinkButton>
          )}
        </div>
      ) : null}
    </>
  );
};

export default OrdersLeftControls;
