import cx from 'classnames';
import { stringifyUrl } from 'query-string';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { ORDERS_URL } from '../../../../global/urls';
import { formatDateLong, formatPrice } from '../../../../helpers/utils';
import { CustomerEntity, ShopEntity } from '../../../../schemas';
import styles from './formatters.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const nameFormatter = (name: string, row: CustomerEntity): ReactElement => (
  <Link className={cx(styles.name)} to={stringifyUrl({ url: ORDERS_URL, query: { search: name } })}>
    <div className={tableStyles.textDark}>{name}</div>
    <div className={tableStyles.textSecondary}>{row.location}</div>
  </Link>
);

export const emailFormatter = (email: string, row: CustomerEntity): ReactElement => (
  <div className={styles.name}>
    <div className={tableStyles.textSecondary}>{email}</div>
    <div className={tableStyles.textSecondary}>{row.phone}</div>
  </div>
);

export const ordersCountFormatter = (orders: number): ReactElement => (
  <span className={tableStyles.textDark}>{orders}</span>
);

export const orderDateFormatter = (
  datetimeStr: string,
  row: CustomerEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx('text-nowrap', tableStyles.textSecondary)}>
      {formatDateLong(datetimeStr, currency)}
    </span>
  );
};

export const totalPriceFormatter = (
  totalPrice: number,
  row: CustomerEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (totalPrice === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark, styles.totalPrice)}>
      {formatPrice(totalPrice, currency)}
    </span>
  );
};
