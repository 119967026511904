import { ProductEnquiry, ProductStatus, ProductVersionType } from '../../schemas';
import { NormalizedError } from '../../types';

export interface Params {
  productId: string;
}

export interface ProductItemInformation {
  title: string;
  description: string;
  tags: string[];
}

export interface ProductItemSeo {
  title: string;
  description: string;
  url: string;
}

export interface ProductItemProperties {
  ageRestricted: boolean;
  customizable: boolean;
  enquiry: ProductEnquiry;
}

export type ProductState = {
  collections: ProductCollection[];
  error: NormalizedError | null;
  images: ImageItem[];
  information: ProductItemInformation;
  isDisabledVendor: boolean;
  isLoading: boolean;
  isMultipleVariants: boolean;
  isPhysicalProduct: boolean;
  isTouched: boolean;
  isTouchedUrlHandle: boolean;
  loaded: boolean;
  options: ProductOption[];
  productId: number | null;
  productVersionId: number | null;
  properties: ProductItemProperties;
  seo: ProductItemSeo;
  shipping: ProductShippingPolicy[];
  showUnpublish: boolean;
  status: ProductStatus | null;
  variants: ProductVariantItem[];
  vendorCode: string | null;
  versionType: ProductVersionType | null;
  enquiriesAllowed: boolean;
};

export type SetVariantParam = {
  draft: ProductState;
  paramName: string;
  paramValue: string | number | boolean | undefined;
  variantId: string;
};

export type SetShippingParam = {
  draft: ProductState;
  shippingType: string;
  paramName: 'available' | 'freeShipping' | 'baseShippingFee' | 'additionalItem';
  paramValue: number | boolean;
};

export enum ImageItemType {
  Image,
  TapToUpload,
}

export type ImageItem = {
  id: string;
  imagePreviewUrl: string;
  inputFile: File | null;
  itemType: ImageItemType;
};

export type ProductVariantItem = {
  id: string;
  price: string;
  compareToPrice: string | null;
  sku: string;
  barcode: string;
  quantity: number;
  trackInventory: boolean;
  purchaseNotInStock: boolean;
  imageId?: string;
  options: string[];
  imageSrc?: string;
  isDeleted: boolean;
  netPrice: string;
  marginPct: string;
};

export type ProductOption = {
  id: number | string;
  name: string;
  values: string[];
};

export type ProductShippingPolicy = {
  name: string;
  available: boolean;
  freeShipping: boolean;
  baseShippingFee: number | string;
  additionalItem: number | string;
};

export type MappedProductVariantItem = ProductVariantItem & {
  name: string;
  available: boolean;
};

export type ProductCollection = {
  id: number;
  name: string;
  checked: boolean;
};

export type SortTypeKey = '' | 'name' | 'sku' | 'price' | 'compareToPrice' | 'available';

export type SortType = {
  key: SortTypeKey;
  ascending: boolean;
};

interface ProductSubmitValuesShippingPolicy {
  additionalFee: number;
  baseFee: number;
  enabled: boolean;
  free: boolean;
  name: string;
}

interface ProductSubmitValuesImage {
  id: string;
  imageData: string | null;
}

interface ProductSubmitValuesOption {
  name: string;
  values: string[];
}

interface ProductSubmitValuesVariant {
  id: number | null;
  allowOverselling: boolean;
  barcode: string;
  compareAtPrice: number | null;
  imageId: string | null;
  options: string[];
  price: number;
  quantity: number;
  sku: string;
  trackInventoryEnabled: boolean;
}

export interface ProductSubmitValues {
  age18plusRequired: boolean;
  customizationEnabled: boolean;
  description: string;
  enquiry: string;
  handle: string;
  images: ProductSubmitValuesImage[];
  isGiftCardProduct: boolean;
  isMultipleVariants: boolean;
  options: ProductSubmitValuesOption[];
  requiresShipping: boolean;
  seoDescription: string;
  seoTitle: string;
  shipping: ProductSubmitValuesShippingPolicy[];
  tags: string[];
  title: string;
  variants: ProductSubmitValuesVariant[];
  publish: boolean;
  collectionIds: number[];
  moderationRequestId?: number;
}
