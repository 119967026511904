import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../init/rootReducer';
import {
  productAddNewVariant,
  productCloneVariant,
  productRemoveVariant,
  productSetPurchaseNotInStock,
  productSetTrackInventory,
  productSetVariantBarcode,
  productSetVariantCompareToPrice,
  productSetVariantImage,
  productSetVariantOption,
  productSetVariantPrice,
  productSetVariantProfit,
  productSetVariantProfitPct,
  productSetVariantQuantity,
  productSetVariantSku,
  productToggleMultipleVariants,
  productUndoRemoveVariant,
} from '../actions/variants.actions';
import { getProductState } from '../selectors';
import { ProductVariantItem } from '../types';
import useProductParams from './useProductParams';

export interface ProductVariantActions {
  addVariant: (id: string) => void;
  cloneVariant: (variantId: string) => void;
  removeVariant: (variantId: string) => void;
  setBarcode: (id: string, barcode: string) => void;
  setCompareToPrice: (id: string, compareToPrice: string) => void;
  setImage: (variantId: string, imageId: string) => void;
  setOption: (variantId: string, optionIndex: number, optionValue: string) => void;
  setPrice: (id: string, price: string) => void;
  setProfit: (id: string, profit: string) => void;
  setProfitPct: (id: string, profitPct: string) => void;
  setPurchaseNotInStock: (id: string) => void;
  setQuantity: (id: string, quantity: number) => void;
  setSku: (id: string, sku: string) => void;
  setTrackInventory: (id: string) => void;
  undoRemoveVariant: (variantId: string) => void;
}

type ProductVariantsData = {
  isMultipleVariants: boolean;
  toggleMultipleVariants: () => void;
  variants: ProductVariantItem[];
  variantActions: ProductVariantActions;
  vendorCode: string | null;
  status: string | null;
};

const useProductVariantsData = (): ProductVariantsData => {
  const dispatch = useDispatch();
  const { productId } = useProductParams();
  const { isMultipleVariants, variants, vendorCode, status } = useSelector((state: AppState) =>
    getProductState(state, productId)
  );
  const variantActions = {
    addVariant: (id: string) => dispatch(productAddNewVariant(productId, id)),
    cloneVariant: (variantId: string) => {
      dispatch(productCloneVariant(productId, variantId));
    },
    removeVariant: (variantId: string) => {
      dispatch(productRemoveVariant(productId, variantId));
    },
    setBarcode: (id: string, barcode: string) =>
      dispatch(productSetVariantBarcode(productId, id, barcode)),
    setCompareToPrice: (id: string, compareToPrice: string) =>
      dispatch(productSetVariantCompareToPrice(productId, id, compareToPrice)),
    setImage: (variantId: string, imageId: string) =>
      dispatch(productSetVariantImage(productId, variantId, imageId)),
    setOption: (variantId: string, optionIndex: number, optionValue: string) =>
      dispatch(productSetVariantOption(productId, variantId, optionIndex, optionValue)),
    setPrice: (id: string, price: string) => dispatch(productSetVariantPrice(productId, id, price)),
    setProfit: (id: string, profit: string) =>
      dispatch(productSetVariantProfit(productId, id, profit)),
    setProfitPct: (id: string, profitPct: string) =>
      dispatch(productSetVariantProfitPct(productId, id, profitPct)),
    setPurchaseNotInStock: (id: string) => dispatch(productSetPurchaseNotInStock(productId, id)),
    setQuantity: (id: string, quantity: number) =>
      dispatch(productSetVariantQuantity(productId, id, quantity)),
    setSku: (id: string, sku: string) => dispatch(productSetVariantSku(productId, id, sku)),
    setTrackInventory: (id: string) => dispatch(productSetTrackInventory(productId, id)),
    undoRemoveVariant: (variantId: string) => {
      dispatch(productUndoRemoveVariant(productId, variantId));
    },
  };

  return {
    isMultipleVariants,
    toggleMultipleVariants: () => dispatch(productToggleMultipleVariants(productId)),
    variants,
    variantActions,
    vendorCode,
    status,
  };
};

export default useProductVariantsData;
