import React, { ReactNode } from 'react';

export type Country = {
  abbr: string;
  flag: ReactNode;
  name: string;
  title: string;
  euVat: boolean;
};

export const NULL_COUNTRY: Country = {
  abbr: '',
  euVat: false,
  flag: null,
  name: '',
  title: '',
};

export const countriesData: { [K: string]: Country } = {
  austria: {
    abbr: 'AT',
    flag: <span>&#127462;&#127481;</span>,
    name: 'austria',
    title: 'Austria',
    euVat: true,
  },
  belgium: {
    abbr: 'BE',
    flag: <span>&#127463;&#127466;</span>,
    name: 'belgium',
    title: 'Belgium',
    euVat: true,
  },
  bulgaria: {
    abbr: 'BG',
    flag: <span>&#127463;&#127468;</span>,
    name: 'bulgaria',
    title: 'Bulgaria',
    euVat: true,
  },
  canada: {
    abbr: 'CA',
    flag: (
      <span role="img" aria-label="Canada">
        🇨🇦
      </span>
    ),
    name: 'canada',
    title: 'Canada',
    euVat: false,
  },
  croatia: {
    abbr: 'HR',
    flag: <span>&#127469;&#127479;</span>,
    name: 'croatia',
    title: 'Croatia',
    euVat: true,
  },
  cyprus: {
    abbr: 'CY',
    flag: <span>&#127464;&#127486;</span>,
    name: 'cyprus',
    title: 'Republic of Cyprus',
    euVat: true,
  },
  czech: {
    abbr: 'CZ',
    flag: <span>&#127464;&#127487;</span>,
    name: 'czech',
    title: 'Czech Republic',
    euVat: true,
  },
  denmark: {
    abbr: 'DK',
    flag: <span>&#127465;&#127472;</span>,
    name: 'denmark',
    title: 'Denmark',
    euVat: true,
  },
  estonia: {
    abbr: 'EE',
    flag: <span>&#127466;&#127466;</span>,
    name: 'estonia',
    title: 'Estonia',
    euVat: true,
  },
  finland: {
    abbr: 'FI',
    flag: <span>&#127467;&#127470;</span>,
    name: 'finland',
    title: 'Finland',
    euVat: true,
  },
  france: {
    abbr: 'FR',
    flag: <span>&#127467;&#127479;</span>,
    name: 'france',
    title: 'France',
    euVat: true,
  },
  germany: {
    abbr: 'DE',
    flag: <span>&#127465;&#127466;</span>,
    name: 'germany',
    title: 'Germany',
    euVat: true,
  },
  greece: {
    abbr: 'EL',
    flag: <span>&#127468;&#127479;</span>,
    name: 'greece',
    title: 'Greece',
    euVat: true,
  },
  hungary: {
    abbr: 'HU',
    flag: <span>&#127469;&#127482;</span>,
    name: 'hungary',
    title: 'Hungary',
    euVat: true,
  },
  ireland: {
    abbr: 'IE',
    flag: <span>&#127470;&#127466;</span>,
    name: 'ireland',
    title: 'Ireland',
    euVat: true,
  },
  italy: {
    abbr: 'IT',
    flag: <span>&#127470;&#127481;</span>,
    name: 'italy',
    title: 'Italy',
    euVat: true,
  },
  latvia: {
    abbr: 'LV',
    flag: <span>&#127473;&#127483;</span>,
    name: 'latvia',
    title: 'Latvia',
    euVat: true,
  },
  lithuania: {
    abbr: 'LT',
    flag: <span>&#127473;&#127481;</span>,
    name: 'lithuania',
    title: 'Lithuania',
    euVat: true,
  },
  luxembourg: {
    abbr: 'LU',
    flag: <span>&#127473;&#127482;</span>,
    name: 'luxembourg',
    title: 'Luxembourg',
    euVat: true,
  },
  malta: {
    abbr: 'MT',
    flag: <span>&#127474;&#127481;</span>,
    name: 'malta',
    title: 'Malta',
    euVat: true,
  },
  netherlands: {
    abbr: 'NL',
    flag: <span>&#127475;&#127473;</span>,
    name: 'netherlands',
    title: 'Netherlands',
    euVat: true,
  },
  poland: {
    abbr: 'PL',
    flag: <span>&#127477;&#127473;</span>,
    name: 'poland',
    title: 'Poland',
    euVat: true,
  },
  portugal: {
    abbr: 'PT',
    flag: <span>&#127477;&#127481;</span>,
    name: 'portugal',
    title: 'Portugal',
    euVat: true,
  },
  romania: {
    abbr: 'RO',
    flag: <span>&#127479;&#127476;</span>,
    name: 'romania',
    title: 'Romania',
    euVat: true,
  },
  slovakia: {
    abbr: 'SK',
    flag: <span>&#127480;&#127472;</span>,
    name: 'slovakia',
    title: 'Slovakia',
    euVat: true,
  },
  slovenia: {
    abbr: 'SI',
    flag: <span>&#127480;&#127470;</span>,
    name: 'slovenia',
    title: 'Slovenia',
    euVat: true,
  },
  spain: {
    abbr: 'ES',
    flag: <span>&#127466;&#127480;</span>,
    name: 'spain',
    title: 'Spain',
    euVat: true,
  },
  sweden: {
    abbr: 'SE',
    flag: <span>&#127480;&#127466;</span>,
    name: 'sweden',
    title: 'Sweden',
    euVat: true,
  },
  unitedKingdom: {
    abbr: 'GB',
    flag: <span>&#127468;&#127463;</span>,
    name: 'unitedKingdom',
    title: 'United Kingdom',
    euVat: true,
  },
  unitedStates: {
    abbr: 'US',
    flag: (
      <span role="img" aria-label="United States">
        🇺🇸
      </span>
    ),
    name: 'unitedStates',
    title: 'United States',
    euVat: false,
  },
};

export const getEuVatCountries = (): Country[] =>
  Object.keys(countriesData)
    .filter((key) => countriesData[key].euVat)
    .map((key) => countriesData[key])
    .sort((a, b) => a.title.localeCompare(b.title));

export const getAvailableCountries = (): Country[] =>
  Object.keys(countriesData)
    .map((key) => countriesData[key])
    .sort((a, b) => a.title.localeCompare(b.title));

// https://www.alt-codes.net/flags
