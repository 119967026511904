import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/warehouseQueryReducer';
import { initialSearchState } from './reducers/warehouseSearchReducer';
import {
  WarehousePageState,
  WarehouseQueryState,
  WarehouseSearchState,
  WarehouseSortOrder,
} from './types';

export const getWarehousePageState = (state: AppState): WarehousePageState =>
  state.containers.warehouse;

export const getWarehouseQueryState = (
  state: AppState,
  sort: WarehouseSortOrder,
  start: number,
  search: string
): WarehouseQueryState => {
  return (
    getWarehousePageState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState
  );
};

export const getWarehouseSearchState = (state: AppState, search: string): WarehouseSearchState =>
  getWarehousePageState(state).searches[search] || initialSearchState;
