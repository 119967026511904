/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../types';
import {
  ALPHA_PAGE_INIT,
  ALPHA_PING_API_FAILURE,
  ALPHA_PING_API_REQUEST,
  ALPHA_PING_API_SUCCESS,
  ALPHA_PING_TRIGGER,
} from './constants';

// interfaces

export interface AlphaPageInitAction {
  type: typeof ALPHA_PAGE_INIT;
}

export interface AlphaPingTriggerAction {
  type: typeof ALPHA_PING_TRIGGER;
}

export interface AlphaPingApiRequestAction extends APIRequestAction {
  type: typeof ALPHA_PING_API_REQUEST;
}

export interface AlphaPingNormalizedResponse extends NormalizedResponse {
  readonly result: {
    answer: string;
    localDatetime: string;
    timestamp: number;
  };
}

export interface AlphaPingApiSuccessAction extends APISuccessAction {
  type: typeof ALPHA_PING_API_SUCCESS;
  payload: {
    response: AlphaPingNormalizedResponse;
  };
}

export interface AlphaPingApiFailureAction extends APIFailureAction {
  type: typeof ALPHA_PING_API_FAILURE;
}

// action creators

export const alphaPageInit = (): AlphaPageInitAction => ({
  type: ALPHA_PAGE_INIT,
});

export const alphaPingTrigger = (): AlphaPingTriggerAction => ({
  type: ALPHA_PING_TRIGGER,
});

export const alphaPingApiRequest = (): AlphaPingApiRequestAction => ({
  type: ALPHA_PING_API_REQUEST,
});

export const alphaPingApiSuccess = (
  response: AlphaPingNormalizedResponse
): AlphaPingApiSuccessAction => ({
  type: ALPHA_PING_API_SUCCESS,
  payload: { response },
});

export const alphaPingApiFailure = (error: NormalizedError): AlphaPingApiFailureAction => ({
  type: ALPHA_PING_API_FAILURE,
  payload: { error },
});
