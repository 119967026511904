import cx from 'classnames';
import React, { CSSProperties, FC } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { ShipmentEntity, ShipmentItem, ShopEntity } from '../../../../schemas';
import OrderFulfillmentHeader from '../OrderFulfillmentHeader/OrderFulfillmentHeader';
import styles from './OrderFulfillment.module.scss';
import columns from './columns';

interface Props {
  fulfillment: ShipmentEntity;
  shop: ShopEntity | null;
}

const OrderFulfillment: FC<Props> = ({ fulfillment, shop }: Props) => {
  const rowStyleFormat = (row: ShipmentItem, rowIdx: number): CSSProperties => {
    return { backgroundColor: rowIdx % 2 === 0 ? '' : '#f9fafd' };
  };

  return (
    <div className={styles.OrderFulfillment}>
      <OrderFulfillmentHeader fulfillment={fulfillment} shop={shop} />
      <BootstrapTable
        keyField="id"
        data={fulfillment.items}
        columns={columns(shop)}
        bootstrap4
        bordered={false}
        classes={cx(tableStyles.table, 'align-middle mb-0')}
        headerClasses={cx(tableStyles.header, 'fs-1')}
        headerWrapperClasses={cx(tableStyles.headerWrapper)}
        rowClasses={cx(tableStyles.row, 'py-2 align-middle')}
        rowStyle={(row, idx) => rowStyleFormat(row, idx)}
      />
    </div>
  );
};

export default OrderFulfillment;
