import produce from 'immer';
import { Reducer } from 'redux';
import { VendorsFetchTriggerAction } from '../actions/fetch.actions';
import {
  VendorsAddSelectedVendors,
  VendorsRemoveSelectedVendors,
  VendorsSelectAllAction,
  VendorsToggleSelectedVendor,
} from '../actions/select.actions';
import {
  VENDORS_ADD_SELECTED_VENDORS,
  VENDORS_FETCH_API_FAILURE,
  VENDORS_FETCH_API_REQUEST,
  VENDORS_FETCH_API_SUCCESS,
  VENDORS_REMOVE_SELECTED_VENDORS,
  VENDORS_SELECT_ALL,
  VENDORS_SUBMIT_API_SUCCESS,
  VENDORS_TOGGLE_SELECTED_VENDOR,
} from '../constants';
import { makeQueryKey } from '../helpers';
import { VENDOR_INVITE_DELETE_SUCCESS, VendorsTypeState } from '../types';
import { VendorsQueryActions, vendorsQueryReducer } from './vendorsQueryReducer';
import { VendorsSearchAction, vendorsSearchReducer } from './vendorsSearchReducer';

export const initialTypeState: VendorsTypeState = {
  queries: {},
  searches: {},
  selected: [],
};

export type VendorsTypeAction =
  | VendorsAddSelectedVendors
  | VendorsFetchTriggerAction
  | VendorsQueryActions
  | VendorsRemoveSelectedVendors
  | VendorsSearchAction
  | VendorsSelectAllAction
  | VendorsToggleSelectedVendor;

// eslint-disable-next-line import/prefer-default-export
export const vendorsTypeReducer: Reducer<VendorsTypeState, VendorsTypeAction> = (
  state = initialTypeState,
  action: VendorsTypeAction
): VendorsTypeState =>
  produce(state, (draft) => {
    switch (action.type) {
      case VENDORS_FETCH_API_FAILURE:
      case VENDORS_FETCH_API_REQUEST:
      case VENDORS_FETCH_API_SUCCESS: {
        const { search, sort, start, type } = action.payload;
        const key = makeQueryKey(type, sort, start, search);
        draft.queries[key] = vendorsQueryReducer(state.queries[key], action);
        draft.searches[search] = vendorsSearchReducer(state.searches[search], action);
        break;
      }

      case VENDORS_SUBMIT_API_SUCCESS: {
        Object.keys(draft.searches).forEach((key) => {
          draft.searches[key] = vendorsSearchReducer(state.searches[key], action);
        });
        break;
      }

      case VENDOR_INVITE_DELETE_SUCCESS: {
        Object.keys(draft.queries).forEach((key) => {
          draft.queries[key] = vendorsQueryReducer(state.queries[key], action);
        });
        Object.keys(draft.searches).forEach((key) => {
          draft.searches[key] = vendorsSearchReducer(state.searches[key], action);
        });
        break;
      }

      case VENDORS_ADD_SELECTED_VENDORS: {
        const {
          payload: { selectedIds },
        } = action as VendorsAddSelectedVendors;
        selectedIds.forEach((id) => {
          if (!draft.selected.find((selectedId) => selectedId === id)) {
            draft.selected.push(id);
          }
        });
        break;
      }

      case VENDORS_REMOVE_SELECTED_VENDORS: {
        const {
          payload: { selectedIds },
        } = action as VendorsRemoveSelectedVendors;
        draft.selected = draft.selected.filter(
          (selectedId) => !selectedIds.some((id) => id === selectedId)
        );
        break;
      }

      case VENDORS_SELECT_ALL: {
        const { search } = action.payload;
        const { allIds } = state.searches[search];
        draft.selected = allIds || [];
        break;
      }

      case VENDORS_TOGGLE_SELECTED_VENDOR: {
        const {
          payload: { id },
        } = action as VendorsToggleSelectedVendor;
        const foundIdx = draft.selected.findIndex((itemId) => itemId === id);
        if (foundIdx === -1) {
          draft.selected.push(id);
        } else {
          draft.selected.splice(foundIdx, 1);
        }
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
