import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { RESET_SESSION } from '../../../global/session/constants';
import { CUSTOMERS_URL } from '../../../global/urls';
import { makeQueryKey } from '../../../helpers/utils';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import { CustomersActionTypes } from '../actionTypes';
import {
  CUSTOMERS_FETCH_API_FAILURE,
  CUSTOMERS_FETCH_API_REQUEST,
  CUSTOMERS_FETCH_API_SUCCESS,
} from '../constants';
import { CustomersPageState } from '../types';
import { customersQueryReducer } from './customersQueryReducer';

const initialState: CustomersPageState = {
  queries: {},
};

// eslint-disable-next-line import/prefer-default-export
export const customersPageReducer: Reducer<CustomersPageState, CustomersActionTypes> = (
  state = initialState,
  action: CustomersActionTypes
): CustomersPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case CUSTOMERS_FETCH_API_FAILURE:
      case CUSTOMERS_FETCH_API_REQUEST:
      case CUSTOMERS_FETCH_API_SUCCESS: {
        const key = makeQueryKey(action.payload.sort, action.payload.start, action.payload.search);
        draft.queries[key] = customersQueryReducer(state.queries[key], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: CUSTOMERS_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      default:
        return draft;
    }
    return draft;
  });
