import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getSessionActiveShop, getSessionUser } from '../../../global/session/selectors';
import { AppState } from '../../../init/rootReducer';
import { ShopEntity, UserEntity, VendorEntity, VendorSchema } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { getVendorProfileState } from '../selectors';
import { SaveVendorProfileRequestPayload, saveVendorProfileSlice } from '../slices';

interface VendorProfileData {
  error: NormalizedError | undefined;
  isLoading: boolean;
  loaded: boolean;
  logoUrl: string | null;
  saveVendorProfileTrigger: (payload: SaveVendorProfileRequestPayload) => void;
  shop: ShopEntity | null;
  user: UserEntity | null;
  vendor: VendorEntity;
  vendorShopId: number | null;
}

const useVendorProfileData = (): VendorProfileData => {
  const dispatch = useDispatch();
  const entities = useSelector(getEntitiesState);
  const shop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);

  const { error, isLoading, loaded } = useSelector(
    (state: AppState) => state.containers.vendorProfileLoading
  );

  const { logoUrl, vendorShopId } = useSelector(getVendorProfileState);

  const vendor = useMemo(() => denormalize(vendorShopId, VendorSchema, entities), [
    entities,
    vendorShopId,
  ]);

  return {
    error,
    isLoading,
    loaded,
    logoUrl,
    saveVendorProfileTrigger: (payload) =>
      dispatch(saveVendorProfileSlice.actions.trigger(payload)),
    shop,
    user,
    vendor,
    vendorShopId,
  };
};

export default useVendorProfileData;
