import React, { FC } from 'react';
import { Col } from 'reactstrap';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

// eslint-disable-next-line no-empty-pattern
const GiftCardCodesRightControls: FC<Props> = ({}: Props) => {
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center" />
    </>
  );
};

export default GiftCardCodesRightControls;
