import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import produce from 'immer';
import { matchPath } from 'react-router-dom';
import { Reducer } from 'redux';
import { LogoutApiSuccessAction } from '../../../global/logout/actions';
import { LOGOUT_API_SUCCESS } from '../../../global/logout/constants';
import { ResetSessionAction } from '../../../global/session/actions';
import { RESET_SESSION } from '../../../global/session/constants';
import { ORDERS_URL } from '../../../global/urls';
import { makeQueryKey } from '../../../helpers/utils';
import { LoginApiSuccessAction } from '../../Login/actions';
import { LOGIN_API_SUCCESS } from '../../Login/constants';
import { SelectShopApiSuccessAction } from '../../ShopSelector/actions/selectActions';
import { SELECT_SHOP_API_SUCCESS } from '../../ShopSelector/constants';
import { OrdersFetchTriggerAction } from '../actions/fetch.actions';
import {
  OrdersAddSelectedOrders,
  OrdersRemoveSelectedOrdersAction,
  OrdersSelectAllAction,
  OrdersToggleSelectedOrder,
} from '../actions/select.actions';
import {
  ORDERS_ADD_SELECTED_ORDERS,
  ORDERS_FETCH_API_FAILURE,
  ORDERS_FETCH_API_REQUEST,
  ORDERS_FETCH_API_SUCCESS,
  ORDERS_REMOVE_SELECTED_ORDERS,
  ORDERS_SELECT_ALL,
  ORDERS_TOGGLE_SELECTED_ORDER,
} from '../constants';
import { OrdersPageState } from '../types';
import { OrdersQueryAction, ordersQueryReducer } from './ordersQueryReducer';
import { ordersSearchReducer } from './ordersSearchReducer';

const initialState: OrdersPageState = {
  queries: {},
  searches: {},
  selected: [],
};

export type OrdersAction =
  | LocationChangeAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | OrdersAddSelectedOrders
  | OrdersFetchTriggerAction
  | OrdersQueryAction
  | OrdersRemoveSelectedOrdersAction
  | OrdersSelectAllAction
  | OrdersToggleSelectedOrder
  | ResetSessionAction
  | SelectShopApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const ordersPageReducer: Reducer<OrdersPageState, OrdersAction> = (
  state = initialState,
  action: OrdersAction
): OrdersPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_FETCH_API_FAILURE:
      case ORDERS_FETCH_API_REQUEST:
      case ORDERS_FETCH_API_SUCCESS: {
        const { search, sort, start } = action.payload;
        const key = makeQueryKey(sort, start, search);
        draft.queries[key] = ordersQueryReducer(state.queries[key], action);
        draft.searches[search] = ordersSearchReducer(state.searches[search], action);
        break;
      }

      case LOCATION_CHANGE: {
        const { location } = action.payload;
        const locationMatch = matchPath(location.pathname, {
          path: ORDERS_URL,
          exact: true,
        });
        if (locationMatch) {
          return initialState;
        }
        break;
      }

      case LOGIN_API_SUCCESS:
      case LOGOUT_API_SUCCESS:
      case RESET_SESSION:
      case SELECT_SHOP_API_SUCCESS: {
        return initialState;
      }

      case ORDERS_ADD_SELECTED_ORDERS: {
        const {
          payload: { selectedIds },
        } = action as OrdersAddSelectedOrders;
        selectedIds.forEach((id) => {
          if (!draft.selected.find((selectedId) => selectedId === id)) {
            draft.selected.push(id);
          }
        });
        break;
      }

      case ORDERS_REMOVE_SELECTED_ORDERS: {
        const {
          payload: { selectedIds },
        } = action as OrdersRemoveSelectedOrdersAction;
        draft.selected = draft.selected.filter(
          (selectedId) => !selectedIds.some((id) => id === selectedId)
        );
        break;
      }

      case ORDERS_SELECT_ALL: {
        const { search } = action.payload;
        const { allIds } = state.searches[search];
        draft.selected = allIds || [];
        break;
      }

      case ORDERS_TOGGLE_SELECTED_ORDER: {
        const {
          payload: { id },
        } = action as OrdersToggleSelectedOrder;
        const foundIdx = draft.selected.findIndex((itemId) => itemId === id);
        if (foundIdx === -1) {
          draft.selected.push(id);
        } else {
          draft.selected.splice(foundIdx, 1);
        }
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
