import { useDispatch, useSelector } from 'react-redux';
import {
  setBusinessGeneralCompanyNumber,
  setBusinessGeneralDisplayName,
  setBusinessGeneralLegalName,
  setBusinessGeneralVatNumber,
  setBusinessRegisteredAddressCity,
  setBusinessRegisteredAddressCountry,
  setBusinessRegisteredAddressLine1,
  setBusinessRegisteredAddressLine2,
  setBusinessRegisteredAddressPostcode,
  setBusinessTradingAddressCity,
  setBusinessTradingAddressCountry,
  setBusinessTradingAddressLine1,
  setBusinessTradingAddressLine2,
  setBusinessTradingAddressPostcode,
} from '../actions';
import {
  setBusinessGeneralVatPrefix,
  setBusinessRegisteredAddressState,
  setBusinessTradingAddressState,
} from '../actions/business';
import { getVendorRegistrationBusinessState } from '../selectors';
import {
  VendorRegistrationBusinessGeneral,
  VendorRegistrationBusinessRegisteredAddress,
  VendorRegistrationBusinessTradingAddress,
} from '../types';

type BusinessDetailsData = {
  general: VendorRegistrationBusinessGeneral;
  registeredAddress: VendorRegistrationBusinessRegisteredAddress;
  tradingAddress: VendorRegistrationBusinessTradingAddress;
  generalActions: {
    setLegalName: (name: string) => void;
    setDisplayedName: (name: string) => void;
    setCompanyNumber: (companyNumber: string) => void;
    setVatNumber: (vatNumber: string) => void;
    setVatPrefix: (vatPrefix: string) => void;
  };
  registeredAddressActions: {
    setAddressLine1: (address: string) => void;
    setAddressLine2: (address: string) => void;
    setAddressState: (state: string) => void;
    setCity: (city: string) => void;
    setCountry: (country: string) => void;
    setPostcode: (postcode: string) => void;
  };
  tradingAddressActions: {
    setAddressLine1: (address: string) => void;
    setAddressLine2: (address: string) => void;
    setAddressState: (state: string) => void;
    setCity: (city: string) => void;
    setCountry: (country: string) => void;
    setPostcode: (postcode: string) => void;
  };
};

const useVendorRegistrationBusinessData = (): BusinessDetailsData => {
  const dispatch = useDispatch();
  const { general, registeredAddress, tradingAddress } = useSelector(
    getVendorRegistrationBusinessState
  );

  const generalActions = {
    setLegalName: (name: string) => dispatch(setBusinessGeneralLegalName(name)),
    setDisplayedName: (name: string) => dispatch(setBusinessGeneralDisplayName(name)),
    setCompanyNumber: (companyNumber: string) =>
      dispatch(setBusinessGeneralCompanyNumber(companyNumber)),
    setVatNumber: (vatNumber: string) => dispatch(setBusinessGeneralVatNumber(vatNumber)),
    setVatPrefix: (vatPrefix: string) => dispatch(setBusinessGeneralVatPrefix(vatPrefix)),
  };

  const registeredAddressActions = {
    setAddressLine1: (address: string) => dispatch(setBusinessRegisteredAddressLine1(address)),
    setAddressLine2: (address: string) => dispatch(setBusinessRegisteredAddressLine2(address)),
    setAddressState: (state: string) => dispatch(setBusinessRegisteredAddressState(state)),
    setCity: (city: string) => dispatch(setBusinessRegisteredAddressCity(city)),
    setCountry: (country: string) => dispatch(setBusinessRegisteredAddressCountry(country)),
    setPostcode: (postcode: string) => dispatch(setBusinessRegisteredAddressPostcode(postcode)),
  };

  const tradingAddressActions = {
    setAddressLine1: (address: string) => dispatch(setBusinessTradingAddressLine1(address)),
    setAddressLine2: (address: string) => dispatch(setBusinessTradingAddressLine2(address)),
    setAddressState: (state: string) => dispatch(setBusinessTradingAddressState(state)),
    setCity: (city: string) => dispatch(setBusinessTradingAddressCity(city)),
    setCountry: (country: string) => dispatch(setBusinessTradingAddressCountry(country)),
    setPostcode: (postcode: string) => dispatch(setBusinessTradingAddressPostcode(postcode)),
  };

  return {
    general,
    generalActions,
    registeredAddress,
    registeredAddressActions,
    tradingAddress,
    tradingAddressActions,
  };
};

export default useVendorRegistrationBusinessData;
