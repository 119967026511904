import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NormalizedError } from '../../../types';
import { VendorRegistrationContractFields } from '../actionTypes/contractActions';
import { VendorRegistrationSubmitFields } from '../actionTypes/submitActions';
import { vendorRegistrationContractTrigger } from '../actions/contractActions';
import { vendorRegistrationSubmitTrigger } from '../actions/submitActions';
import { getVendorRegistrationState } from '../selectors';
import useVendorRegistrationBusinessData from './useVendorRegistrationBusinessData';
import useVendorRegistrationContractData from './useVendorRegistrationContractData';
import useVendorRegistrationLoginData from './useVendorRegistrationLoginData';

interface Params {
  invitationCode: string;
}

type VendorRegistrationData = {
  contractTrigger(): void;
  invitationCode: string;
  loggedInAs: string | null;
  onRegistrationSubmit(
    successCallback: () => void,
    failureCallback: (error: NormalizedError) => void
  ): void;
  scheduleOfFeesText: string;
  shopAgreementText: string;
  shopCurrency: string;
  shopLogoUrl: string;
};

const useVendorRegistrationData = (): VendorRegistrationData => {
  const dispatch = useDispatch();
  const {
    general: { legalName, companyNumber, displayName, vatNumber, vatPrefix },
    registeredAddress: {
      addressLine1: registeredAddressLine1,
      addressLine2: registeredAddressLine2,
      addressState: registeredState,
      city: registeredCity,
      country: registeredCountry,
      postcode: registeredPostcode,
    },
    tradingAddress: {
      addressLine1: tradingAddressLine1,
      addressLine2: tradingAddressLine2,
      addressState: tradingState,
      city: tradingCity,
      country: tradingCountry,
      postcode: tradingPostcode,
    },
  } = useVendorRegistrationBusinessData();
  const {
    contract: { authorized, email: contractEmail, name, phone, position, termsAccepted },
  } = useVendorRegistrationContractData();
  const {
    login: { email: loginEmail, password, passwordConfirm },
  } = useVendorRegistrationLoginData();
  const { invitationCode } = useParams<Params>();
  const {
    contractHash,
    loggedInAs,
    scheduleOfFeesText,
    shopAgreementText,
    shopCurrency,
    shopLogoUrl,
  } = useSelector(getVendorRegistrationState);

  const onRegistrationSubmit = (
    successCallback: () => void,
    failureCallback: (error: NormalizedError) => void
  ) => {
    const fields: VendorRegistrationSubmitFields = {
      authorized,
      companyNumber,
      contractEmail,
      contractHash,
      displayName,
      legalName,
      loginEmail,
      loggedInAs,
      name,
      password,
      passwordConfirm,
      phone,
      position,
      registeredAddressLine1,
      registeredAddressLine2,
      registeredCity,
      registeredCountry,
      registeredPostcode,
      registeredState,
      termsAccepted,
      tradingAddressLine1,
      tradingAddressLine2,
      tradingCity,
      tradingCountry,
      tradingPostcode,
      tradingState,
      vatNumber,
      vatPrefix,
    };

    dispatch(
      vendorRegistrationSubmitTrigger(invitationCode, fields, successCallback, failureCallback)
    );
  };

  const contractTrigger = useCallback(() => {
    const fields: VendorRegistrationContractFields = {
      companyNumber,
      legalName,
      loggedInAs,
      registeredAddressLine1,
      registeredAddressLine2,
      registeredCity,
      registeredCountry,
      registeredPostcode,
      registeredState,
    };
    dispatch(vendorRegistrationContractTrigger(invitationCode, fields));
  }, [
    companyNumber,
    dispatch,
    invitationCode,
    legalName,
    loggedInAs,
    registeredAddressLine1,
    registeredAddressLine2,
    registeredCity,
    registeredCountry,
    registeredPostcode,
    registeredState,
  ]);

  return {
    invitationCode,
    loggedInAs,
    scheduleOfFeesText,
    shopAgreementText,
    shopCurrency,
    shopLogoUrl,
    onRegistrationSubmit,
    contractTrigger,
  };
};

export default useVendorRegistrationData;
