import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import { CUSTOMERS_API_URL } from '../../global/urls';
import { apiGet, normalizeError } from '../../helpers/api';
import { handleFailureSaga } from '../../helpers/sagas';
import { CustomerSchema, SessionSchema } from '../../schemas';
import { CustomersFetchNormalizedResponse, CustomersFetchTriggerAction } from './actionTypes';
import {
  customersFetchApiFailure,
  customersFetchApiRequest,
  customersFetchApiSuccess,
} from './actions';
import { CUSTOMERS_FETCH_TRIGGER } from './constants';

function* fetchSaga({ payload }: CustomersFetchTriggerAction) {
  const { search, sort, start } = payload;
  yield put(customersFetchApiRequest(sort, start, search));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: CUSTOMERS_API_URL,
      query: { search: payload.search, sort: payload.sort, start: payload.start },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: CustomersFetchNormalizedResponse = normalize(response, {
      customers: [CustomerSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(customersFetchApiSuccess(sort, start, search, normalizedResponse));
  } catch (error) {
    yield put(customersFetchApiFailure(sort, start, search, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(CUSTOMERS_FETCH_TRIGGER, fetchSaga)]);
}
