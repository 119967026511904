import { Formik } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import FalconTable from '../../../../components/FalconTable/FalconTable';
import { SortOrderDirection } from '../../../../global/types';
import { PayoutEntity, ShopEntity } from '../../../../schemas';
import { mapSortOrder, mapTableSort } from '../../helpers';
import useOnSortChange from '../../hooks/useOnSortChange';
import { FormItems, FormValues, PayoutsSortOrder, SortablePayoutsColumn } from '../../types';
import { columns, loadingColumns } from './columns';

type Props = {
  formItems: FormItems;
  pageSize: number;
  payouts: PayoutEntity[] | null;
  shop: ShopEntity | null;
  sortOrder: PayoutsSortOrder;
};

const PayoutsTable: FC<Props> = ({ formItems, pageSize, payouts, shop, sortOrder }: Props) => {
  const onSortChange = useOnSortChange();

  const handleTableChange = (
    tctype: TableChangeType,
    newState: TableChangeState<unknown>
  ): void => {
    onSortChange(
      mapSortOrder(
        newState.sortField as SortablePayoutsColumn,
        newState.sortOrder as SortOrderDirection
      )
    );
  };

  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);

  const mappedVendorsName = useCallback(
    (payoutsList: PayoutEntity[]) =>
      payoutsList.map((payout) => ({
        ...payout,
        name: { name: payout.vendorDisplayName, code: payout.vendorCode },
      })),
    []
  );

  const data = useMemo(
    () =>
      payouts !== null
        ? mappedVendorsName(payouts)
        : Array.from({ length: pageSize }, (_, id) => ({ id })),
    [mappedVendorsName, pageSize, payouts]
  );

  const initialValues: FormValues = useMemo(() => ({ payouts: formItems }), [formItems]);

  return (
    <Formik<FormValues> enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
      <FalconTable
        columns={payouts !== null ? columns(shop) : loadingColumns(shop)}
        data={data}
        keyField="id"
        sort={bootstrapTableSort}
        onTableChange={handleTableChange}
        remote={{
          filter: true,
          pagination: true,
          sort: true,
        }}
      />
    </Formik>
  );
};

export default PayoutsTable;
