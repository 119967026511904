import { faBan, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { reloadPage } from '../../global/session/actions';
import { NormalizedError } from '../../types';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './TableError.module.scss';

type Props = {
  error: NormalizedError;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableError: FC<Props> = ({ error }: Props) => {
  const dispatch = useDispatch();
  const reload = () => dispatch(reloadPage());
  return (
    <div
      className={cx(
        'align-items-center d-flex flex-column justify-content-center',
        styles.TableError
      )}
    >
      <div className={styles.TableError__ban}>
        <FontAwesomeIcon icon={faBan} />
      </div>
      <div className={cx('my-2', styles.TableError__bigText)}>Error loading data</div>
      <div className={cx('my-2', styles.TableError__smallText)}>
        You can not seem to find the data you are looking for
      </div>
      <PrimaryButton
        className={cx('my-4', styles.TableError__retryBtn)}
        icon={faRedo}
        size="large"
        onClick={reload}
      >
        Retry
      </PrimaryButton>
    </div>
  );
};

export default TableError;
