import produce from 'immer';
import { Reducer } from 'redux';
import { NormalizedError } from '../../types';
import { AlphaPingApiFailureAction, AlphaPingApiSuccessAction } from './actions';
import { ALPHA_PING_API_FAILURE, ALPHA_PING_API_SUCCESS } from './constants';

export interface Pong {
  answer: string;
  localDatetime: string;
  timestamp: number;
}

export type AlphaPageState = {
  pong: Pong | null;
  error: NormalizedError | null;
};

const initialState: AlphaPageState = {
  pong: null,
  error: null,
};

type AlphaPageActions = AlphaPingApiSuccessAction | AlphaPingApiFailureAction;

// eslint-disable-next-line import/prefer-default-export
export const alphaPageReducer: Reducer<AlphaPageState, AlphaPageActions> = (
  state = initialState,
  action: AlphaPageActions
): AlphaPageState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ALPHA_PING_API_SUCCESS: {
        draft.pong = action.payload.response.result;
        break;
      }

      case ALPHA_PING_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
