import { Action } from 'redux';
import {
  PRODUCT_ADD_NEW_VARIANT,
  PRODUCT_CLONE_VARIANT,
  PRODUCT_REMOVE_VARIANT,
  PRODUCT_SET_PURCHASE_NOT_IN_STOCK,
  PRODUCT_SET_TRACK_INVENTORY,
  PRODUCT_SET_VARIANT_BARCODE,
  PRODUCT_SET_VARIANT_COMPARE_TO_PRICE,
  PRODUCT_SET_VARIANT_IMAGE,
  PRODUCT_SET_VARIANT_OPTION,
  PRODUCT_SET_VARIANT_PRICE,
  PRODUCT_SET_VARIANT_PROFIT,
  PRODUCT_SET_VARIANT_PROFIT_PCT,
  PRODUCT_SET_VARIANT_QUANTITY,
  PRODUCT_SET_VARIANT_SKU,
  PRODUCT_TOGGLE_MULTIPLE_VARIANTS,
  PRODUCT_UNDO_REMOVE_VARIANT,
} from '../constants';

// action types

export interface ProductToggleMultipleVariantsAction extends Action {
  type: typeof PRODUCT_TOGGLE_MULTIPLE_VARIANTS;
  payload: { productId: number };
}

export interface ProductSetVariantPriceAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_PRICE;
  payload: {
    productId: number;
    variantId: string;
    price: string;
  };
}

export interface ProductSetVariantProfitAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_PROFIT;
  payload: {
    productId: number;
    variantId: string;
    profit: string;
  };
}

export interface ProductSetVariantProfitPctAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_PROFIT_PCT;
  payload: {
    productId: number;
    variantId: string;
    profitPct: string;
  };
}

export interface ProductSetVariantCompareToPriceAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_COMPARE_TO_PRICE;
  payload: {
    productId: number;
    variantId: string;
    compareToPrice: string;
  };
}

export interface ProductSetVariantSkuAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_SKU;
  payload: {
    productId: number;
    variantId: string;
    sku: string;
  };
}

export interface ProductSetVariantBarcodeAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_BARCODE;
  payload: {
    productId: number;
    variantId: string;
    barcode: string;
  };
}

export interface ProductSetVariantQuantityAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_QUANTITY;
  payload: {
    productId: number;
    variantId: string;
    quantity: number;
  };
}

export interface ProductSetTrackInventoryAction extends Action {
  type: typeof PRODUCT_SET_TRACK_INVENTORY;
  payload: {
    productId: number;
    variantId: string;
  };
}

export interface ProductSetPurchaseNotInStockAction extends Action {
  type: typeof PRODUCT_SET_PURCHASE_NOT_IN_STOCK;
  payload: {
    productId: number;
    variantId: string;
  };
}

export interface ProductSetVariantImageAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_IMAGE;
  payload: {
    productId: number;
    variantId: string;
    imageId: string;
  };
}

export interface ProductAddNewVariantAction extends Action {
  type: typeof PRODUCT_ADD_NEW_VARIANT;
  payload: {
    productId: number;
    variantId: string;
  };
}

export interface ProductSetVariantOptionAction extends Action {
  type: typeof PRODUCT_SET_VARIANT_OPTION;
  payload: {
    productId: number;
    variantId: string;
    optionIndex: number;
    optionValue: string;
  };
}

export interface ProductCloneVariantAction extends Action {
  type: typeof PRODUCT_CLONE_VARIANT;
  payload: {
    productId: number;
    variantId: string;
  };
}

export interface ProductRemoveVariantAction extends Action {
  type: typeof PRODUCT_REMOVE_VARIANT;
  payload: {
    productId: number;
    variantId: string;
  };
}

export interface ProductUndoRemoveVariantAction extends Action {
  type: typeof PRODUCT_UNDO_REMOVE_VARIANT;
  payload: {
    productId: number;
    variantId: string;
  };
}

// action creators

export const productToggleMultipleVariants = (
  productId: number
): ProductToggleMultipleVariantsAction => ({
  type: PRODUCT_TOGGLE_MULTIPLE_VARIANTS,
  payload: { productId },
});

export const productSetVariantPrice = (
  productId: number,
  variantId: string,
  price: string
): ProductSetVariantPriceAction => ({
  type: PRODUCT_SET_VARIANT_PRICE,
  payload: { productId, variantId, price },
});

export const productSetVariantCompareToPrice = (
  productId: number,
  variantId: string,
  compareToPrice: string
): ProductSetVariantCompareToPriceAction => ({
  type: PRODUCT_SET_VARIANT_COMPARE_TO_PRICE,
  payload: { productId, variantId, compareToPrice },
});

export const productSetVariantProfit = (
  productId: number,
  variantId: string,
  profit: string
): ProductSetVariantProfitAction => ({
  type: PRODUCT_SET_VARIANT_PROFIT,
  payload: { productId, variantId, profit },
});

export const productSetVariantProfitPct = (
  productId: number,
  variantId: string,
  marginPct: string
): ProductSetVariantProfitPctAction => ({
  type: PRODUCT_SET_VARIANT_PROFIT_PCT,
  payload: { productId, variantId, profitPct: marginPct },
});

export const productSetVariantSku = (
  productId: number,
  variantId: string,
  sku: string
): ProductSetVariantSkuAction => ({
  type: PRODUCT_SET_VARIANT_SKU,
  payload: { productId, variantId, sku },
});

export const productSetVariantBarcode = (
  productId: number,
  variantId: string,
  barcode: string
): ProductSetVariantBarcodeAction => ({
  type: PRODUCT_SET_VARIANT_BARCODE,
  payload: { productId, variantId, barcode },
});

export const productSetVariantQuantity = (
  productId: number,
  variantId: string,
  quantity: number
): ProductSetVariantQuantityAction => ({
  type: PRODUCT_SET_VARIANT_QUANTITY,
  payload: { productId, variantId, quantity },
});

export const productSetTrackInventory = (
  productId: number,
  variantId: string
): ProductSetTrackInventoryAction => ({
  type: PRODUCT_SET_TRACK_INVENTORY,
  payload: { productId, variantId },
});

export const productSetPurchaseNotInStock = (
  productId: number,
  variantId: string
): ProductSetPurchaseNotInStockAction => ({
  type: PRODUCT_SET_PURCHASE_NOT_IN_STOCK,
  payload: { productId, variantId },
});

export const productSetVariantImage = (
  productId: number,
  variantId: string,
  imageId: string
): ProductSetVariantImageAction => ({
  type: PRODUCT_SET_VARIANT_IMAGE,
  payload: { productId, variantId, imageId },
});

export const productAddNewVariant = (
  productId: number,
  variantId: string
): ProductAddNewVariantAction => ({
  type: PRODUCT_ADD_NEW_VARIANT,
  payload: { productId, variantId },
});

export const productSetVariantOption = (
  productId: number,
  variantId: string,
  optionIndex: number,
  optionValue: string
): ProductSetVariantOptionAction => ({
  type: PRODUCT_SET_VARIANT_OPTION,
  payload: { productId, variantId, optionIndex, optionValue },
});

export const productCloneVariant = (
  productId: number,
  variantId: string
): ProductCloneVariantAction => ({
  type: PRODUCT_CLONE_VARIANT,
  payload: { productId, variantId },
});

export const productRemoveVariant = (
  productId: number,
  variantId: string
): ProductRemoveVariantAction => ({
  type: PRODUCT_REMOVE_VARIANT,
  payload: { productId, variantId },
});

export const productUndoRemoveVariant = (
  productId: number,
  variantId: string
): ProductUndoRemoveVariantAction => ({
  type: PRODUCT_UNDO_REMOVE_VARIANT,
  payload: { productId, variantId },
});
