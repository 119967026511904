import produce from 'immer';
import { Reducer } from 'redux';
import {
  PayoutsFetchApiFailureAction,
  PayoutsFetchApiRequestAction,
  PayoutsFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  PAYOUTS_FETCH_API_FAILURE,
  PAYOUTS_FETCH_API_REQUEST,
  PAYOUTS_FETCH_API_SUCCESS,
} from '../constants';
import { PayoutsQueryState } from '../types';

export const initialQueryState: PayoutsQueryState = {
  count: 0,
  error: null,
  loaded: false,
  payoutIds: null,
};

export type PayoutsQueryActions =
  | PayoutsFetchApiFailureAction
  | PayoutsFetchApiRequestAction
  | PayoutsFetchApiSuccessAction;

export const payoutsQueryReducer: Reducer<PayoutsQueryState, PayoutsQueryActions> = (
  state: PayoutsQueryState = initialQueryState,
  action: PayoutsQueryActions
): PayoutsQueryState =>
  produce(state, (draft) => {
    switch (action.type) {
      case PAYOUTS_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case PAYOUTS_FETCH_API_SUCCESS: {
        draft.count = action.payload.response.result.count;
        draft.loaded = true;
        draft.payoutIds = action.payload.response.result.payouts;
        break;
      }

      case PAYOUTS_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
