import React, { FC, useMemo } from 'react';
import { SelectRowProps, TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import FalconTable from '../../../../components/FalconTable/FalconTable';
import { SortOrderDirection } from '../../../../global/types';
import { CollectionEntity } from '../../../../schemas';
import { mapSortOrder, mapTableSort } from '../../helpers';
import useOnSortChange from '../../hooks/useOnSortChange';
import { CollectionsColumn, CollectionsSortOrder } from '../../types';
import columns from './columns';

type Props = {
  collections: CollectionEntity[] | null;
  onCollectionEdit: (collection: CollectionEntity) => void;
  pageSize: number;
  sortOrder: CollectionsSortOrder;
  selectRow: SelectRowProps<CollectionEntity>;
};

const CollectionsTable: FC<Props> = ({
  collections,
  onCollectionEdit,
  pageSize,
  sortOrder,
  selectRow,
}: Props) => {
  const onSortChange = useOnSortChange();

  const handleTableChange = (
    type: TableChangeType,
    newState: TableChangeState<CollectionEntity>
  ): void => {
    onSortChange(
      mapSortOrder(
        newState.sortField as CollectionsColumn,
        newState.sortOrder as SortOrderDirection
      )
    );
  };

  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);

  const data = useMemo(
    () =>
      collections !== null ? collections : Array.from({ length: pageSize }, (_, id) => ({ id })),
    [collections, pageSize]
  );

  return (
    <FalconTable
      columns={columns({ onEdit: onCollectionEdit })}
      data={data}
      keyField="id"
      selectRow={selectRow}
      sort={bootstrapTableSort}
      onTableChange={handleTableChange}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
    />
  );
};

export default CollectionsTable;
