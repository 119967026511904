import { replace } from 'connected-react-router';
import { stringifyUrl } from 'query-string';
import { Dispatch } from 'redux';
import { LOGIN_AS_URL } from '../../global/urls';

type QueryParams = {
  search?: string;
};

export const getLoginAsUrl = (params: QueryParams): string => {
  const query: QueryParams = {};
  if (params.search) {
    query.search = params.search;
  }
  return stringifyUrl({ url: LOGIN_AS_URL, query });
};

export const replaceLoginAsUrl = (dispatch: Dispatch, params: QueryParams): void => {
  const url = getLoginAsUrl(params);
  dispatch(replace(url));
};
