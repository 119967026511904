import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionActiveShop, getSessionShops } from '../../global/session/selectors';
import { selectShopTrigger } from './actions/selectActions';
import ShopSelectorDropdown from './components/ShopSelectorDropdown/ShopSelectorDropdown';

interface Props {
  className?: string;
}

const ShopSelector: FC<Props> = ({ className }: Props) => {
  const dispatch = useDispatch();
  const activeShop = useSelector(getSessionActiveShop);
  const shops = useSelector(getSessionShops);
  const handleSelectShop = (shopId: number) => dispatch(selectShopTrigger(shopId, ''));

  return (
    <ShopSelectorDropdown
      activeShop={activeShop}
      className={className}
      onSelectShop={handleSelectShop}
      shops={shops}
    />
  );
};

export default ShopSelector;
