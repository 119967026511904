import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import SimpleLoadingIndicator from '../../../../components/SimpleLoadingIndicator/SimpleLoadingIndicator';
import useOrderPageData from '../../hooks/useOrderPageData';
import OrderFulfillments from '../OrderFulfillments/OrderFulfillments';
import OrderHeader from '../OrderHeader/OrderHeader';
import OrderItems from '../OrderItems/OrderItems';
import OrderRefunds from '../OrderRefunds/OrderRefunds';

const renderError = () => {
  return (
    <div
      style={{
        marginTop: '20px',
        border: '2px solid red',
        padding: '20px',
      }}
    >
      ERROR!
    </div>
  );
};

const OrderMainContent: FC = () => {
  const {
    correctLocation,
    error,
    isFulfilling,
    isLoading,
    isRefunding,
    loaded,
    order,
    orderFetchTrigger,
    orderFulfillTrigger,
    orderItems,
    orderName,
    orderRefundTrigger,
    refundedItems,
    shipments,
    shop,
  } = useOrderPageData();

  const refundedItemsWithShipping = [...refundedItems];
  if (order?.isShippingRefunded) {
    refundedItemsWithShipping.push({
      id: 0,
      name: order.shippingTypes[0],
      options: [],
      price: order.shippingPrice,
      quantity: 1,
      reason: order.shippingRefundReason || '',
      sku: '',
      unitPrice: order.shippingPrice,
    });
  }

  return (
    <div>
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={orderFetchTrigger}
        renderError={renderError}
        renderLoading={() => <SimpleLoadingIndicator />}
        require={!!order || loaded || !correctLocation}
      >
        <OrderHeader
          order={order}
          orderName={orderName}
          orderRefundTrigger={orderRefundTrigger}
          shop={shop}
        />
        <OrderItems
          isFulfilling={isFulfilling}
          isRefunding={isRefunding}
          items={orderItems}
          order={order}
          orderFulfillTrigger={orderFulfillTrigger}
          orderName={orderName}
          orderRefundTrigger={orderRefundTrigger}
          shop={shop}
        />
        <OrderRefunds items={refundedItemsWithShipping} shop={shop} />
        <OrderFulfillments fulfillments={shipments} shop={shop} />
      </LoadingSwitch>
    </div>
  );
};

export default OrderMainContent;
