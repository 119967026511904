import React, { FC } from 'react';
import { SelectedRowsInfo } from '../../../../components/FalconTable/selectRow';
import ProductsActionsDropdown from '../ProductsActionsDropdown/ProductsActionsDropdown';

type Props = {
  total: number;
  numItems: number | undefined;
  selectedProducts: Set<number>;
  selectAll: () => void;
};

const ProductsLeftControls: FC<Props> = ({
  total,
  numItems,
  selectedProducts,
  selectAll,
}: Props) => {
  const numSelected = selectedProducts.size;
  if (!numItems) {
    return null;
  }
  return (
    <>
      <ProductsActionsDropdown
        component="header"
        disabled={selectedProducts.size === 0}
        selectedProducts={selectedProducts}
      />
      <SelectedRowsInfo numSelected={numSelected} total={total} selectAll={selectAll} />
    </>
  );
};

export default ProductsLeftControls;
