import cx from 'classnames';
import React, { FC } from 'react';
import styles from './CenterCropImg.module.scss';

type Props = {
  alt: string;
  className?: string;
  height: number;
  src: string;
  width: number;
};

const CenterCropImg: FC<Props> = ({ alt, className, height, src, width }: Props) => {
  return (
    <div className={cx(styles.CenterCropImg, className)} style={{ height, width }}>
      {src && <img alt={alt} className={styles.CenterCropImg__img} src={src} />}
    </div>
  );
};

export default CenterCropImg;
