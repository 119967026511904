import { Action } from 'redux';
import {
  PRODUCT_SET_SEO_DESCRIPTION,
  PRODUCT_SET_SEO_TITLE,
  PRODUCT_SET_SEO_URL,
} from '../constants';

// action types

export interface ProductSetSeoTitleAction extends Action {
  type: typeof PRODUCT_SET_SEO_TITLE;
  payload: {
    productId: number;
    title: string;
  };
}

export interface ProductSetSeoDescriptionAction extends Action {
  type: typeof PRODUCT_SET_SEO_DESCRIPTION;
  payload: {
    productId: number;
    description: string;
  };
}

export interface ProductSetSeoUrlAction extends Action {
  type: typeof PRODUCT_SET_SEO_URL;
  payload: {
    productId: number;
    url: string;
  };
}

// action creators

export const productSetSeoTitle = (productId: number, title: string): ProductSetSeoTitleAction => ({
  type: PRODUCT_SET_SEO_TITLE,
  payload: { productId, title },
});

export const productSetSeoDescription = (
  productId: number,
  description: string
): ProductSetSeoDescriptionAction => ({
  type: PRODUCT_SET_SEO_DESCRIPTION,
  payload: { productId, description },
});

export const productSetSeoUrl = (productId: number, url: string): ProductSetSeoUrlAction => ({
  type: PRODUCT_SET_SEO_URL,
  payload: { productId, url },
});
