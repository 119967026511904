import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop } from '../../../global/session/selectors';
import { COLLECTION_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import {
  CollectionEntity,
  CollectionProduct,
  CollectionSchema,
  MicroProductEntity,
  MicroProductSchema,
} from '../../../schemas';
import { NormalizedError } from '../../../types';
import { collectionShowMoreProducts } from '../actions/actions';
import { getCollectionState, isFetchingBrowse, isSubmittingCollection } from '../selectors';
import collectionSubmitSlice, { fetchCollectionSlice } from '../slices';
import { CollectionSubmitValues } from '../types';
import useCollectionParams from './useCollectionParams';

interface CollectionPageData {
  browsableProducts: MicroProductEntity[] | null;
  collection: CollectionEntity;
  collectionId: number;
  collectionFetchTrigger(): void;
  collectionSubmitTrigger(values: CollectionSubmitValues): void;
  correctLocation: boolean;
  dispatch: Dispatch;
  error: NormalizedError | undefined;
  isLoading: boolean;
  isLoadingBrowse: boolean;
  isSubmitting: boolean;
  loaded: boolean;
  numShown: number;
  numTotal: number;
  products: CollectionProduct[] | null;
  selectedProductIds: number[];
  shopUrl: string;
  showMore: () => void;
}

const useCollectionPageData = (): CollectionPageData => {
  const dispatch = useDispatch();
  const { collectionId } = useCollectionParams();
  const { location } = useSelector(getRouterState);
  const match = matchPath(location.pathname, { path: COLLECTION_URL, exact: true });
  const correctLocation = !!match;
  const entities = useSelector(getEntitiesState);
  const activeShop = useSelector(getSessionActiveShop);
  const { error, isLoading, loaded } = useSelector(
    (state: AppState) => state.containers.collectionLoading
  );

  const {
    browsableProductIds,
    numShown,
    numTotal,
    products,
    selectedProductIds,
  } = useSelector((state: AppState) => getCollectionState(state, collectionId));

  const isLoadingBrowse = useSelector(isFetchingBrowse);
  const isSubmitting = useSelector(isSubmittingCollection);

  const collection = useMemo(() => denormalize(collectionId, CollectionSchema, entities), [
    entities,
    collectionId,
  ]);

  const browsableProducts = useMemo(
    () =>
      browsableProductIds !== null
        ? browsableProductIds.map(
            (productId) =>
              denormalize(productId, MicroProductSchema, entities) as MicroProductEntity
          )
        : null,
    [browsableProductIds, entities]
  );

  // const loadMore = () => {
  //   dispatch(fetchCollectionProductsSlice.actions.trigger({ collectionId, start: 1 })); // TBD XXX
  // };

  return {
    browsableProducts,
    collection,
    collectionFetchTrigger: () => dispatch(fetchCollectionSlice.actions.trigger({ collectionId })),
    collectionId,
    collectionSubmitTrigger: (values) =>
      dispatch(collectionSubmitSlice.actions.trigger({ collectionId, values })),
    correctLocation,
    dispatch,
    error,
    isLoading,
    isLoadingBrowse,
    isSubmitting,
    loaded,
    numShown,
    numTotal,
    products,
    selectedProductIds,
    shopUrl: activeShop?.url || '',
    showMore: () => dispatch(collectionShowMoreProducts(collectionId)),
  };
};

export default useCollectionPageData;
