import { OrderItemEntity } from '../../schemas';
import { FormItems, ItemAndQuantity, OrderItemGroup } from './types';

export const orderOtherGroup = (group: OrderItemGroup): OrderItemGroup =>
  group === OrderItemGroup.Fulfilled ? OrderItemGroup.Unfulfilled : OrderItemGroup.Fulfilled;

export const groupToItemsName = (group: OrderItemGroup): string => {
  return group === OrderItemGroup.Unfulfilled ? 'unfulfilledItems' : 'fulfilledItems';
};

export const selectedItemsAndQuantities = (
  items: OrderItemEntity[],
  selectedIds: number[],
  formItems: FormItems
): ItemAndQuantity[] =>
  items
    .filter((item) => selectedIds.includes(item.id))
    .filter((item) => formItems[item.id].quantity > 0)
    .map((item) => ({
      itemId: item.id,
      quantity: formItems[item.id].quantity,
    }));
