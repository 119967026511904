import { push, replace } from 'connected-react-router';
import { stringifyUrl } from 'query-string';
import { Dispatch } from 'redux';
import { GIFT_CARDS_URL } from '../../global/urls';
import { defaultCodesSortOrder, defaultProductsSortOrder } from './constants';
import { GiftCardCodesSortOrder, GiftCardProductsSortOrder } from './types';

export const makeQueryKey = (
  sortProducts: GiftCardProductsSortOrder,
  sortCodes: GiftCardCodesSortOrder,
  start: number,
  search: string
): string => `${sortProducts}:${sortCodes}:${start}:${search}`;

type QueryParams = {
  search?: string;
  sortCodes?: string;
  sortProducts?: string;
  start?: number;
};

export const getGiftCardsUrl = (params: QueryParams): string => {
  const query: QueryParams = {};
  if (params.search) {
    query.search = params.search;
  }
  if (params.sortCodes && params.sortCodes !== defaultCodesSortOrder) {
    query.sortCodes = params.sortCodes;
  }
  if (params.sortProducts && params.sortProducts !== defaultProductsSortOrder) {
    query.sortProducts = params.sortProducts;
  }
  if (params.start) {
    query.start = params.start;
  }
  return stringifyUrl({ url: GIFT_CARDS_URL, query });
};

export const replaceGiftCardsUrl = (dispatch: Dispatch, params: QueryParams): void => {
  const url = getGiftCardsUrl(params);
  dispatch(replace(url, { keepState: true }));
};

export const pushGiftCardsUrl = (
  dispatch: Dispatch,
  params: QueryParams,
  keepState = false
): void => {
  const url = getGiftCardsUrl(params);
  dispatch(push(url, { keepState }));
};
