import cx from 'classnames';
import { Field, FormikProps } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CountrySelector from '../../../../components/CountrySelector/CountrySelector';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import tapStyles from '../../../../components/SortableGallery/components/TapToUpload/TapToUpload.module.scss';
import { getEuVatCountries, NULL_COUNTRY } from '../../../../global/countries/countries';
import { setLogoUrl } from '../../slices';
import { FormValues } from '../../types';
import styles from './VendorProfileMainInformation.module.scss';

interface ImageInputProps {
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageInput: FC<ImageInputProps> = ({ onChange }: ImageInputProps) => {
  return (
    <input
      type="file"
      onChange={onChange}
      accept="image/*"
      className="d-none"
      onClick={(e) => {
        const target = e.target as HTMLInputElement;
        // eslint-disable-next-line no-param-reassign
        target.value = '';
      }}
    />
  );
};

interface Props {
  fprops: FormikProps<FormValues>;
  logoUrl: string | null;
}

const VendorProfileMainInformation: FC<Props> = ({ fprops, logoUrl }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { errors, setFieldValue, touched, values } = fprops;
  const euVatCountries = useMemo(() => [NULL_COUNTRY, ...getEuVatCountries()], []);

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      let inputFiles: File[] = [];
      if ('files' in e.target && e.target.files !== null) {
        inputFiles = Array.from(e.target.files);
      }
      inputFiles.forEach((inputFile) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = typeof reader.result === 'string' ? reader.result : '';
          setFieldValue('logoFileName', inputFile.name);
          setFieldValue('logoFileData', data);
          dispatch(setLogoUrl(data));
        };
        reader.readAsDataURL(inputFile);
      });
    },
    [dispatch, setFieldValue]
  );

  const handleVatCountrySelect = (selectedCountry: string) => {
    setFieldValue('vatCountry', selectedCountry);
  };

  return (
    <Card className="h-lg-100 mb-3 p-3">
      <h4 className="sss-form-title">General Info</h4>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              {t('LEGAL_NAME_OF_YOUR_ORG')} <span className="sss-color-orange">*</span>
            </Label>
            <Field as={Input} name="legalName" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Display Name / Trading As{' '}
              <CustomTooltip text="This is the name that will be displayed to customers" />
            </Label>
            <Field as={Input} disabled name="displayName" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">
              Company Number (if applicable){' '}
              <CustomTooltip innerClassName={styles.VendorProfileMainInformation__tooltip}>
                If you do not have a company number
                <br />
                i.e you are a sole trader, leave this blank
              </CustomTooltip>
            </Label>
            <Field as={Input} className="text-capitalize" name="companyNumber" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">VAT Number (if applicable)</Label>
            <div className="d-flex flex-row">
              <CountrySelector
                countries={euVatCountries}
                countryNamesFormat="short"
                dropdownMenuWidth={80}
                onCountrySelect={handleVatCountrySelect}
                selectedCountryCode={values.vatCountry}
              />
              <Field as={Input} name="vatNumberOnly" type="text" />
            </div>
            {errors.vatNumberOnly && touched.vatNumberOnly && (
              <FormInputError className="mt-1" isBlock>
                {errors.vatNumberOnly}
              </FormInputError>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Description</Label>
            <Field
              as={Input}
              className="text-capitalize"
              name="description"
              style={{ height: '160px' }}
              type="textarea"
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Vendor Code</Label>
            <Field as={Input} disabled name="vendorCode" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Logo</Label>
            <div>
              {logoUrl ? (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className={cx(styles.VendorProfileMainInformation__logo)}>
                  <ImageInput onChange={handleImageChange} />
                  <img alt="Logo" style={{ maxHeight: '100px', maxWidth: '100px' }} src={logoUrl} />
                </label>
              ) : (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className={cx(tapStyles.tapToUpload, tapStyles.tapToUploadSmall)}>
                  <ImageInput onChange={handleImageChange} />
                  <div className={tapStyles.content}>Tap to upload logo</div>
                </label>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
};

export default VendorProfileMainInformation;
