import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { AppState } from '../../../init/rootReducer';
import { NormalizedError } from '../../../types';
import { passwordResetTrigger } from '../actions/passwordReset';
import { passwordResetVerifyTokenTrigger } from '../actions/passwordResetVerifyToken';
import { PASSWORD_RESET_API_PREFIX } from '../constants';
import getPasswordResetPageState from '../selectors';
import { PasswordResetScreen } from '../types';

interface PasswordResetData {
  error: NormalizedError | null;
  changePasswordTrigger(email: string): void;
  isProcessing: boolean;
  screen: PasswordResetScreen;
  token: string;
  uid: string;
}

const usePasswordResetData = (): PasswordResetData => {
  const [screen, setScreen] = useState(PasswordResetScreen.ENTER_NEW_PASSWORD);
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const uid: string = parsed.uid ? parsed.uid.toString() : '';
  const token: string = parsed.token ? parsed.token.toString() : '';

  const { error } = useSelector(getPasswordResetPageState);
  const isProcessing = useSelector((state: AppState) =>
    checkIfProcessing(state, PASSWORD_RESET_API_PREFIX)
  );
  const passwordChangedCallback = () => {
    setScreen(PasswordResetScreen.PASSWORD_CHANGED);
  };

  // verify token on first render
  useEffect(() => {
    dispatch(passwordResetVerifyTokenTrigger(uid, token));
  }, [dispatch, token, uid]);

  // invalid token?
  useEffect(() => {
    if (error) {
      if (error.json?.errors) {
        setScreen(PasswordResetScreen.INVALID_TOKEN);
      }
    }
  }, [error]);

  return {
    error,
    changePasswordTrigger: (email) => {
      dispatch(passwordResetTrigger(uid, token, email, passwordChangedCallback));
    },
    isProcessing,
    screen,
    token,
    uid,
  };
};

export default usePasswordResetData;
