import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { OrdersSortOrder } from '../types';
import { replaceOrdersUrl } from '../utils';
import useOrdersQueryParams from './useOrdersQueryParams';

const useOnSortChange = (): ((newSort: OrdersSortOrder) => void) => {
  const dispatch = useDispatch();
  const { search, sortOrder, start } = useOrdersQueryParams();

  return useCallback(
    (newSort: OrdersSortOrder) => {
      if (newSort !== sortOrder) {
        replaceOrdersUrl(dispatch, { search, sort: newSort, start });
      }
    },
    [dispatch, search, sortOrder, start]
  );
};

export default useOnSortChange;
