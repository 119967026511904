import { VendorRegistrationScreen, VendorRegistrationScreenStep } from './types';

// business details - general
export const SET_BUSINESS_GENERAL_LEGAL_NAME =
  'VENDOR_REGISTRATION_SET_BUSINESS_GENERAL_LEGAL_NAME';
export const SET_BUSINESS_GENERAL_DISPLAY_NAME =
  'VENDOR_REGISTRATION_SET_BUSINESS_GENERAL_DISPLAY_NAME';
export const SET_BUSINESS_GENERAL_COMPANY_NUMBER =
  'VENDOR_REGISTRATION_SET_BUSINESS_GENERAL_COMPANY_NUMBER';
export const SET_BUSINESS_GENERAL_VAT_NUMBER =
  'VENDOR_REGISTRATION_SET_BUSINESS_GENERAL_VAT_NUMBER';
export const SET_BUSINESS_GENERAL_VAT_PREFIX =
  'VENDOR_REGISTRATION_SET_BUSINESS_GENERAL_VAT_PREFIX';

// business details - registeredAddress
export const SET_BUSINESS_REGISTERED_ADDRESS_LINE_1 =
  'VENDOR_REGISTRATION_SET_BUSINESS_REGISTERED_ADDRESS_LINE_1';
export const SET_BUSINESS_REGISTERED_ADDRESS_LINE_2 =
  'VENDOR_REGISTRATION_SET_BUSINESS_REGISTERED_ADDRESS_LINE_2';
export const SET_BUSINESS_REGISTERED_ADDRESS_CITY =
  'VENDOR_REGISTRATION_SET_BUSINESS_REGISTERED_ADDRESS_CITY';
export const SET_BUSINESS_REGISTERED_ADDRESS_COUNTRY =
  'VENDOR_REGISTRATION_SET_BUSINESS_REGISTERED_ADDRESS_COUNTRY';
export const SET_BUSINESS_REGISTERED_ADDRESS_POSTCODE =
  'VENDOR_REGISTRATION_SET_BUSINESS_REGISTERED_ADDRESS_POSTCODE';
export const SET_BUSINESS_REGISTERED_ADDRESS_STATE =
  'VENDOR_REGISTRATION_SET_BUSINESS_REGISTERED_ADDRESS_STATE';

// business details - tradingAddress
export const SET_BUSINESS_TRADING_ADDRESS_LINE_1 =
  'VENDOR_REGISTRATION_SET_BUSINESS_TRADING_ADDRESS_LINE_1';
export const SET_BUSINESS_TRADING_ADDRESS_LINE_2 =
  'VENDOR_REGISTRATION_SET_BUSINESS_TRADING_ADDRESS_LINE_2';
export const SET_BUSINESS_TRADING_ADDRESS_CITY =
  'VENDOR_REGISTRATION_SET_BUSINESS_TRADING_ADDRESS_CITY';
export const SET_BUSINESS_TRADING_ADDRESS_COUNTRY =
  'VENDOR_REGISTRATION_SET_BUSINESS_TRADING_ADDRESS_COUNTRY';
export const SET_BUSINESS_TRADING_ADDRESS_POSTCODE =
  'VENDOR_REGISTRATION_SET_BUSINESS_TRADING_ADDRESS_POSTCODE';
export const SET_BUSINESS_TRADING_ADDRESS_STATE =
  'VENDOR_REGISTRATION_SET_BUSINESS_TRADING_ADDRESS_STATE';

// contract
export const SET_CONTRACT_NAME = 'VENDOR_REGISTRATION_SET_CONTRACT_NAME';
export const SET_CONTRACT_POSITION = 'VENDOR_REGISTRATION_SET_CONTRACT_POSITION';
export const SET_CONTRACT_EMAIL = 'VENDOR_REGISTRATION_SET_CONTRACT_EMAIL';
export const SET_CONTRACT_PHONE = 'VENDOR_REGISTRATION_SET_CONTRACT_PHONE';
export const TOGGLE_CONTRACT_TERMS_ACCEPTED = 'VENDOR_REGISTRATION_TOGGLE_CONTRACT_TERMS_ACCEPTED';
export const TOGGLE_CONTRACT_AUTHORIZED = 'VENDOR_REGISTRATION_TOGGLE_CONTRACT_AUTHORIZED';

// login
export const SET_LOGIN_EMAIL = 'VENDOR_REGISTRATION_SET_LOGIN_EMAIL';
export const SET_LOGIN_PASSWORD = 'VENDOR_REGISTRATION_SET_LOGIN_PASSWORD';
export const SET_LOGIN_PASSWORD_CONFIRM = 'VENDOR_REGISTRATION_SET_LOGIN_PASSWORD_CONFIRM';

// logged in as
export const VENDOR_REGISTRATION_SET_LOGGED_IN_AS = 'VENDOR_REGISTRATION_SET_LOGGED_IN_AS';

// fetch init
export const VENDOR_REGISTRATION_INIT_TRIGGER = 'VENDOR_REGISTRATION_INIT_TRIGGER';
export const VENDOR_REGISTRATION_INIT_API_REQUEST = 'VENDOR_REGISTRATION_INIT_API_REQUEST';
export const VENDOR_REGISTRATION_INIT_API_SUCCESS = 'VENDOR_REGISTRATION_INIT_API_SUCCESS';
export const VENDOR_REGISTRATION_INIT_API_FAILURE = 'VENDOR_REGISTRATION_INIT_API_FAILURE';

// fetch contract
export const VENDOR_REGISTRATION_CONTRACT_TRIGGER = 'VENDOR_REGISTRATION_CONTRACT_TRIGGER';
export const VENDOR_REGISTRATION_CONTRACT_API_REQUEST = 'VENDOR_REGISTRATION_CONTRACT_API_REQUEST';
export const VENDOR_REGISTRATION_CONTRACT_API_SUCCESS = 'VENDOR_REGISTRATION_CONTRACT_API_SUCCESS';
export const VENDOR_REGISTRATION_CONTRACT_API_FAILURE = 'VENDOR_REGISTRATION_CONTRACT_API_FAILURE';

// submit
export const VENDOR_REGISTRATION_SUBMIT_TRIGGER = 'VENDOR_REGISTRATION_SUBMIT_TRIGGER';
export const VENDOR_REGISTRATION_SUBMIT_API_REQUEST = 'VENDOR_REGISTRATION_SUBMIT_API_REQUEST';
export const VENDOR_REGISTRATION_SUBMIT_API_SUCCESS = 'VENDOR_REGISTRATION_SUBMIT_API_SUCCESS';
export const VENDOR_REGISTRATION_SUBMIT_API_FAILURE = 'VENDOR_REGISTRATION_SUBMIT_API_FAILURE';

// screen steps
export const SCREEN_STEPS: VendorRegistrationScreenStep[] = [
  { screen: VendorRegistrationScreen.BUSINESS_DETAILS, title: 'Business Details' },
  { screen: VendorRegistrationScreen.CONTRACT, title: 'Contract' },
  { screen: VendorRegistrationScreen.LOGIN_DETAILS, title: 'Login Details' },
];
