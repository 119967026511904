import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { initialQueryState } from './reducers/productsQueryReducer';
import { initialSearchState } from './reducers/productsSearchReducer';
import {
  ProductsPageState,
  ProductsQueryState,
  ProductsSearchState,
  ProductsSortOrder,
} from './types';

export const getProductsPageState = (state: AppState): ProductsPageState =>
  state.containers.products;

export const getProductsQueryState = (
  state: AppState,
  sort: ProductsSortOrder,
  start: number,
  search: string
): ProductsQueryState => {
  return (
    getProductsPageState(state).queries[makeQueryKey(sort, start, search)] || initialQueryState
  );
};

export const getProductsSearchState = (state: AppState, search: string): ProductsSearchState =>
  getProductsPageState(state).searches[search] || initialSearchState;
