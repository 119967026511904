import { NormalizedError } from '../../types';

export enum GiftCardCodesColumn {
  code = 'code',
  createdAt = 'createdAt',
  purchaserName = 'purchaserName',
  remaining = 'remaining',
  value = 'value',
}

export enum GiftCardCodesSortOrder {
  code = 'code',
  codeDesc = '-code',
  createdAt = 'createdAt',
  createdAtDesc = '-createdAt',
  purchaserName = 'purchaserName',
  purchaserNameDesc = '-purchaserName',
  remaining = 'remaining',
  remainingDesc = '-remaining',
  value = 'value',
  valueDesc = '-value',
}

export type GiftCardCodesQueryState = {
  codesCount: number;
  error: NormalizedError | null;
  giftCardCodeIds: number[] | null;
  giftCardProductIds: number[] | null;
  loaded: boolean;
};

export interface GiftCardsPageState {
  queries: { [queryKey: string]: GiftCardCodesQueryState };
}

export enum GiftCardProductsColumn {
  sku = 'sku',
  soldCount = 'soldCount',
  status = 'status',
  value = 'value',
}

export enum GiftCardProductsSortOrder {
  sku = 'sku',
  skuDesc = '-sku',
  soldCount = 'soldCount',
  soldCountDesc = '-soldCount',
  status = 'status',
  statusDesc = '-status',
  value = 'value',
  valueDesc = '-value',
}
