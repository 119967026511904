import cx from 'classnames';
import React, { ReactElement } from 'react';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { formatPrice, isNotDefaultTitle } from '../../../../helpers/utils';
import { ShipmentItem, ShopEntity } from '../../../../schemas';
import styles from './OrderFulfillment.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const itemFormatter = (name: string, row: ShipmentItem): ReactElement => {
  return (
    <div className={styles.OrderFulfillment__item}>
      <div>{name}</div>
      <div className={styles.OrderFulfillment__item__sku}>{row.sku}</div>
    </div>
  );
};

export const optionsFormatter = (options: string[]): ReactElement => {
  if (options === undefined) return <></>;
  return (
    <div className={cx(tableStyles.textDark)}>
      {options.filter(isNotDefaultTitle).map((option) => (
        <div key={option}>{option}</div>
      ))}
    </div>
  );
};

export const priceFormatter = (
  price: number,
  row: ShipmentItem,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (price === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <div className={cx(tableStyles.textDark, 'text-nowrap')}>{formatPrice(price, currency)}</div>
  );
};

export const quantityFormatter = (quantity: number, row: ShipmentItem): ReactElement => {
  return (
    <div className={cx(tableStyles.textDark, 'text-nowrap')}>
      {quantity} of {row.totalQuantity}
    </div>
  );
};
