import { SortOrder } from 'react-bootstrap-table-next';
import { SortOrderDirection } from '../../global/types';
import {
  GiftCardCodesColumn,
  GiftCardCodesSortOrder,
  GiftCardProductsColumn,
  GiftCardProductsSortOrder,
} from './types';

export const mapSortCodesOrder = (
  columnName: GiftCardCodesColumn,
  sortDirection: SortOrderDirection
): GiftCardCodesSortOrder => {
  if (columnName === GiftCardCodesColumn.purchaserName) {
    return sortDirection === SortOrderDirection.asc
      ? GiftCardCodesSortOrder.purchaserName
      : GiftCardCodesSortOrder.purchaserNameDesc;
  }
  const columnNameDesc = `-${columnName}` as GiftCardCodesSortOrder;
  return sortDirection === SortOrderDirection.asc
    ? GiftCardCodesSortOrder[columnName]
    : columnNameDesc;
};

export const mapTableSortCodes = (
  sortOrder: GiftCardCodesSortOrder
): { dataField: GiftCardCodesSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as GiftCardCodesSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};

export const mapSortProductsOrder = (
  columnName: GiftCardProductsColumn,
  sortDirection: SortOrderDirection
): GiftCardProductsSortOrder => {
  if (columnName === GiftCardProductsColumn.sku) {
    return sortDirection === SortOrderDirection.asc
      ? GiftCardProductsSortOrder.sku
      : GiftCardProductsSortOrder.skuDesc;
  }
  const columnNameDesc = `-${columnName}` as GiftCardProductsSortOrder;
  return sortDirection === SortOrderDirection.asc
    ? GiftCardProductsSortOrder[columnName]
    : columnNameDesc;
};

export const mapTableSortProducts = (
  sortOrder: GiftCardProductsSortOrder
): { dataField: GiftCardProductsSortOrder; order: SortOrder } => {
  const sortDirection = sortOrder[0] === '-' ? SortOrderDirection.desc : SortOrderDirection.asc;
  let sortParam = sortOrder;
  if (sortDirection === SortOrderDirection.desc) {
    sortParam = sortParam.slice(1) as GiftCardProductsSortOrder;
    return {
      dataField: sortParam,
      order: SortOrderDirection.desc,
    };
  }
  return {
    dataField: sortParam,
    order: SortOrderDirection.asc,
  };
};
