/* eslint-disable react/button-has-type */
import cx from 'classnames';
import React, { FC } from 'react';
import { ButtonProps } from 'reactstrap';
import styles from './LinkButton.module.scss';

type LinkButtonSize = 'large' | 'small';

interface Props extends ButtonProps {
  size: LinkButtonSize;
}

const LinkButton: FC<Props> = ({
  children,
  className,
  size = 'small',
  type = 'button',
  ...rest
}: Props) => {
  const btnSizeClass = size === 'large' ? styles.LinkButton__large : styles.LinkButton__small;
  return (
    <button {...rest} className={cx(styles.LinkButton, btnSizeClass, className)} type={type}>
      {children}
    </button>
  );
};

export default LinkButton;
