import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  ORDERS_FETCH_API_FAILURE,
  ORDERS_FETCH_API_REQUEST,
  ORDERS_FETCH_API_SUCCESS,
  ORDERS_FETCH_TRIGGER,
} from '../constants';
import { OrdersSortOrder } from '../types';

// action types

interface OrdersFetchPayload {
  sort: OrdersSortOrder;
  start: number;
  search: string;
}

export interface OrdersFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    count: number;
    ids: number[];
    orders: number[];
  };
}

export interface OrdersFetchTriggerAction {
  type: typeof ORDERS_FETCH_TRIGGER;
  payload: OrdersFetchPayload;
}

export interface OrdersFetchApiRequestAction extends APIRequestAction {
  type: typeof ORDERS_FETCH_API_REQUEST;
  payload: OrdersFetchPayload;
}

export interface OrdersFetchApiSuccessAction extends APISuccessAction {
  type: typeof ORDERS_FETCH_API_SUCCESS;
  payload: OrdersFetchPayload & {
    response: OrdersFetchNormalizedResponse;
  };
}

export interface OrdersFetchApiFailureAction extends APIFailureAction {
  type: typeof ORDERS_FETCH_API_FAILURE;
  payload: OrdersFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const ordersFetchTrigger = (
  sort: OrdersSortOrder,
  start: number,
  search: string
): OrdersFetchTriggerAction => ({
  type: ORDERS_FETCH_TRIGGER,
  payload: {
    search,
    sort,
    start,
  },
});

export const ordersFetchApiRequest = (
  sort: OrdersSortOrder,
  start: number,
  search: string
): OrdersFetchApiRequestAction => ({
  type: ORDERS_FETCH_API_REQUEST,
  payload: { search, sort, start },
});

export const ordersFetchApiSuccess = (
  sort: OrdersSortOrder,
  start: number,
  search: string,
  response: OrdersFetchNormalizedResponse
): OrdersFetchApiSuccessAction => ({
  type: ORDERS_FETCH_API_SUCCESS,
  payload: { response, search, sort, start },
});

export const ordersFetchApiFailure = (
  sort: OrdersSortOrder,
  start: number,
  search: string,
  error: NormalizedError
): OrdersFetchApiFailureAction => ({
  type: ORDERS_FETCH_API_FAILURE,
  payload: { error, search, sort, start },
});
