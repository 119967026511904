import React, { FC, useMemo } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';
import FalconTable from '../../../../components/FalconTable/FalconTable';
import { BriefProductEntity } from '../../../../schemas';
import useProductsTableColumns from '../../hooks/useProductsTableColumns';
import { ProductsSortOrder } from '../../types';
import { mapTableSort } from '../../utils';

type Props = {
  products: BriefProductEntity[] | null;
  pageSize: number;
  sortOrder: ProductsSortOrder;
  selectRow: SelectRowProps<BriefProductEntity>;
};

const ProductsTable: FC<Props> = ({ products, pageSize, sortOrder, selectRow }: Props) => {
  const { columns, loadingColumns } = useProductsTableColumns();

  const bootstrapTableSort = useMemo(() => mapTableSort(sortOrder), [sortOrder]);

  const data = useMemo(
    () => (products !== null ? products : Array.from({ length: pageSize }, (_, id) => ({ id }))),
    [products, pageSize]
  );

  return (
    <FalconTable
      columns={products !== null ? columns : loadingColumns}
      data={data}
      keyField="id"
      selectRow={selectRow}
      sort={bootstrapTableSort}
      onTableChange={() => {}}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
    />
  );
};

export default ProductsTable;
