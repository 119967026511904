import { checkIfProcessing } from '../../global/ui/selectors';
import { makeQueryKey } from '../../helpers/utils';
import { AppState } from '../../init/rootReducer';
import { SHIPPING_POLICIES_SUBMIT_API } from './constants';
import { initialQueryState } from './reducers/shippingPoliciesQueryReducer';
import {
  ShippingPoliciesPageState,
  ShippingPoliciesQueryState,
  ShippingPoliciesSortOrder,
} from './types';

export const getShippingPoliciesPageState = (state: AppState): ShippingPoliciesPageState =>
  state.containers.shippingPolicies;

export const getShippingPoliciesQueryState = (
  state: AppState,
  sort: ShippingPoliciesSortOrder,
  start: number,
  search: string
): ShippingPoliciesQueryState => {
  return (
    getShippingPoliciesPageState(state).queries[makeQueryKey(sort, start, search)] ||
    initialQueryState
  );
};

export const isSubmittingShippingPolicies = (state: AppState): boolean =>
  checkIfProcessing(state, SHIPPING_POLICIES_SUBMIT_API);
