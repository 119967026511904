import { SessionSectionNormalized } from '../../../global/init/actions';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  LOGIN_AS_API_FAILURE,
  LOGIN_AS_API_REQUEST,
  LOGIN_AS_API_SUCCESS,
  LOGIN_AS_TRIGGER,
} from '../constants';

// interfaces

interface LoginAsPayload {
  userId: number;
}

export interface LoginAsTriggerAction {
  type: typeof LOGIN_AS_TRIGGER;
  payload: LoginAsPayload;
}

export interface LoginAsApiRequestAction extends APIRequestAction {
  type: typeof LOGIN_AS_API_REQUEST;
  payload: LoginAsPayload;
}

export interface LoginAsNormalizedResponse extends NormalizedResponse {
  readonly result: {
    session: SessionSectionNormalized;
  };
}

export interface LoginAsApiSuccessAction extends APISuccessAction {
  type: typeof LOGIN_AS_API_SUCCESS;
  payload: LoginAsPayload & {
    response: LoginAsNormalizedResponse;
  };
}

export interface LoginAsApiFailureAction extends APIFailureAction {
  type: typeof LOGIN_AS_API_FAILURE;
  payload: LoginAsPayload & {
    error: NormalizedError;
  };
}

// action creators

export const loginAsTrigger = (userId: number): LoginAsTriggerAction => ({
  type: LOGIN_AS_TRIGGER,
  payload: { userId },
});

export const loginAsApiRequest = (userId: number): LoginAsApiRequestAction => ({
  type: LOGIN_AS_API_REQUEST,
  payload: { userId },
});

export const loginAsApiSuccess = (
  userId: number,
  response: LoginAsNormalizedResponse
): LoginAsApiSuccessAction => ({
  type: LOGIN_AS_API_SUCCESS,
  payload: { response, userId },
});

export const loginAsApiFailure = (
  userId: number,
  error: NormalizedError
): LoginAsApiFailureAction => ({
  type: LOGIN_AS_API_FAILURE,
  payload: { error, userId },
});
