import cx from 'classnames';
import React, { FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import styles from './MultipleInput.module.scss';

type SimpleTagProps = {
  tag: string;
  onClick?: (tag: string) => void;
};

export const SimpleTag: FC<SimpleTagProps> = ({ tag, onClick }: SimpleTagProps) => (
  <button
    className={cx('d-inline-block mx-1', styles.simpleTagButton)}
    onClick={onClick ? () => onClick(tag) : undefined}
    type="button"
  >
    &nbsp;{tag}
  </button>
);

interface TagProps {
  submittedValue: string;
  removeTag: (tag: string) => void;
  disabled?: boolean;
}

const Tag: FC<TagProps> = ({ removeTag, submittedValue, disabled }: TagProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={cx('d-inline-block mx-1', styles.tagButton)}
      onBlur={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
    >
      <button
        className={cx('font-weight-bold pl-2 pr-1', {
          [styles.tagButton__x__hover]: hovered,
        })}
        onClick={() => removeTag(submittedValue)}
        type="button"
        disabled={disabled}
      >
        &times;
      </button>
      {submittedValue}
    </div>
  );
};

type Props = {
  className?: string;
  name: string;
  title?: string;
  submittedValues: string[];
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const MultipleInput: FC<Props> = ({
  className,
  name,
  title,
  addTag,
  placeholder,
  removeTag,
  submittedValues = [],
  disabled,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('');

  const renderedValues =
    submittedValues && submittedValues.length > 0
      ? submittedValues.map((submittedValue) => (
          <Tag
            key={submittedValue}
            submittedValue={submittedValue}
            removeTag={removeTag}
            disabled={disabled}
          />
        ))
      : null;

  const idName = `multiple-input-${name}`;

  return (
    <div className={cx(className)}>
      {title && (
        <Row>
          <Col>
            <label className="small" htmlFor={idName}>
              {title}
            </label>
          </Col>
          <Col className={cx('small text-right', styles.rightTitle)}>
            Press Enter to add {title}
          </Col>
        </Row>
      )}
      <div
        className={cx(
          styles.multipleInput,
          'align-items-center bg-white d-flex flex-row flex-wrap pl-2 rounded'
        )}
      >
        {renderedValues}
        <input
          className={cx(styles.newInput)}
          id={idName}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && typeof inputValue === 'string' && inputValue.length > 0) {
              const trimmedValue = inputValue.trim();
              if (trimmedValue) {
                addTag(trimmedValue);
                setInputValue('');
                e.preventDefault();
              }
            }
            if (e.key === 'Backspace' && !inputValue && submittedValues.length > 0) {
              removeTag(submittedValues[submittedValues.length - 1]);
            }
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default MultipleInput;
