import cx from 'classnames';
import React, { ReactElement } from 'react';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { formatDateShort, formatPrice } from '../../../../helpers/utils';
import { GiftCardCodeEntity, ShopEntity } from '../../../../schemas';
import styles from './formatters.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const dateFormatter = (
  value: string,
  row: GiftCardCodeEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx('text-nowrap', tableStyles.textDark)}>
      {formatDateShort(value, currency)}
    </span>
  );
};

export const purchaserFormatter = (purchaserName: string): ReactElement => (
  <div className={tableStyles.textDark}>{purchaserName}</div>
);

export const statusFormatter = (status: string): ReactElement => {
  return <span className={cx('text-nowrap', tableStyles.textSecondary)}>{status}</span>;
};

export const valueFormatter = (
  value: number,
  row: GiftCardCodeEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (value === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <span className={cx(tableStyles.textDark, styles.totalPrice)}>
      {formatPrice(value, currency)}
    </span>
  );
};
