import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import envelope from '../../../../assets/img/envelope.png';
import { LOGIN_URL } from '../../../../global/urls';
import styles from './EmailSent.module.scss';

interface Props {
  email: string;
}

const EmailSent: FC<Props> = ({ email }: Props) => {
  return (
    <div className={cx('bg-white rounded shadow p-5 text-center', styles.emailSentWrapper)}>
      <img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={70} />
      <h4 className={cx(styles.emailSentTitle)}>Please check your email!</h4>
      <p className={cx(styles.emailSentSubTitle)}>
        An email has been sent to <strong>{email}</strong>. Please click on the included link to
        reset your password.
      </p>
      <Link to={LOGIN_URL}>
        <Button
          type="button"
          color="falcon-primary"
          size="sm"
          className={cx(styles.emailSentBackBtn, 'mt-3')}
        >
          <FontAwesomeIcon icon={faChevronLeft} transform="shrink-4 down-1" className="mr-1" />
          Return to login
        </Button>
      </Link>
    </div>
  );
};

export default EmailSent;
