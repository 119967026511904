import cx from 'classnames';
import React, { FC } from 'react';
import Errors from '../../../../components/Errors/Errors';
import { NormalizedError } from '../../../../types';
import PasswordResetForm from '../PasswordResetForm/PasswordResetForm';
import styles from './EnterNewPassword.module.scss';

interface Props {
  changePasswordTrigger(password: string): void;
  error: NormalizedError | null;
}

const EnterNewPassword: FC<Props> = ({ changePasswordTrigger, error }: Props) => {
  return (
    <div className={cx('bg-white rounded shadow p-5', styles.enterEmailWrapper)}>
      <div className="text-center">
        <h5 className="mb-0">Enter new password</h5>
        <PasswordResetForm onSubmit={changePasswordTrigger} />
        <Errors error={error} />
      </div>
    </div>
  );
};

export default EnterNewPassword;
