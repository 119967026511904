import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { apiPost, normalizeError } from '../../helpers/api';
import { LOGOUT_API_URL } from '../urls';
import {
  logoutApiFailure,
  logoutApiRequest,
  logoutApiSuccess,
  LogoutNormalizedResponse,
} from './actions';
import { LOGOUT_TRIGGER } from './constants';

function* logoutSaga() {
  yield put(logoutApiRequest());

  try {
    const response = yield call(apiPost, LOGOUT_API_URL, {});

    const normalizedResponse: LogoutNormalizedResponse = normalize(response, {});
    yield put(logoutApiSuccess(normalizedResponse));
  } catch (error) {
    yield put(logoutApiFailure(normalizeError(error)));
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(LOGOUT_TRIGGER, logoutSaga)]);
}
