import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import useToggle from '../../../../components/ModalForm/useToggle';
import { ShippingType } from '../../../../schemas';
import { deleteShippingTypeSlice } from '../../slices/shippingTypesSlice';
import { EditShippingTypeModal } from '../EditShippingTypeModal/EditShippingTypeModal';

export type Props = {
  shippingType: ShippingType;
};

const ShippingTypeActionsDropdown: FC<Props> = ({ shippingType }: Props) => {
  const [editModalOpen, editModalToggle] = useToggle();
  const [deleteModalOpen, deleteModalToggle] = useToggle();

  const items: DropDownItemDefinition[] = [
    { text: 'Edit', icon: faEdit, onClick: editModalToggle },
    { text: 'Delete', icon: faTrashAlt, onClick: deleteModalToggle },
  ];

  const { id } = shippingType;
  const dispatch = useDispatch();
  const deleteShippingTypeTrigger = () => dispatch(deleteShippingTypeSlice.actions.trigger({ id }));

  return (
    <>
      <DropdownMenu component="item" items={items} />

      <EditShippingTypeModal
        isOpen={editModalOpen}
        toggle={editModalToggle}
        shippingType={shippingType}
      />

      <ModalForm
        initialValues={{}}
        isOpen={deleteModalOpen}
        onSubmit={deleteShippingTypeTrigger}
        canSubmit={() => true}
        title="Delete Shipping Type"
        toggle={deleteModalToggle}
        okButtonNegative
      >
        Do you want to delete the following shipping type?
        <br />
        <b>{shippingType.name}</b>
      </ModalForm>
    </>
  );
};

export default ShippingTypeActionsDropdown;
