import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import styles from './CustomCheckbox.module.scss';

type Props = {
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange: (e: ChangeEvent) => void;
  name?: string;
};

const CustomCheckbox: FC<Props> = ({ checked, disabled, indeterminate, onChange, name }: Props) => {
  return (
    <div
      className={cx(
        'position-relative rounded d-flex justify-content-center align-items-center',
        styles.wrapper,
        {
          [styles.wrapperChecked]: checked || indeterminate,
          [styles.wrapperDisabled]: disabled,
        }
      )}
    >
      <input
        className="invisible"
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
        name={name || ''}
      />
      {(checked || indeterminate) && (
        <div className={cx(styles.checkIcon, 'text-white position-absolute')}>
          <FontAwesomeIcon icon={checked ? faCheck : faMinus} />
        </div>
      )}
    </div>
  );
};

export default CustomCheckbox;
