import { denormalize, schema } from 'normalizr';
import { useMemo } from 'react';
import { EntitiesState } from '../../types';

function useDenormalizeEntitiesMemo<R>(
  entities: EntitiesState,
  schema_: schema.Entity,
  ids: number[] | null
): R[] | null {
  return useMemo(
    () => (ids !== null ? ids.map((id) => denormalize(id, schema_, entities)) : null),
    [entities, schema_, ids]
  );
}

export default useDenormalizeEntitiesMemo;
