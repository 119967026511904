import { Action } from 'redux';
import {
  ORDERS_ADD_SELECTED_ORDERS,
  ORDERS_REMOVE_SELECTED_ORDERS,
  ORDERS_SELECT_ALL,
  ORDERS_TOGGLE_SELECTED_ORDER,
} from '../constants';

// action types

export interface OrdersToggleSelectedOrder extends Action {
  type: typeof ORDERS_TOGGLE_SELECTED_ORDER;
  payload: { id: number };
}

export interface OrdersAddSelectedOrders extends Action {
  type: typeof ORDERS_ADD_SELECTED_ORDERS;
  payload: { selectedIds: number[] };
}

export interface OrdersRemoveSelectedOrdersAction extends Action {
  type: typeof ORDERS_REMOVE_SELECTED_ORDERS;
  payload: { selectedIds: number[] };
}

export interface OrdersSelectAllAction extends Action {
  type: typeof ORDERS_SELECT_ALL;
  payload: { search: string };
}

// action creators

export const ordersToggleSelectedOrder = (id: number): OrdersToggleSelectedOrder => ({
  type: ORDERS_TOGGLE_SELECTED_ORDER,
  payload: { id },
});

export const ordersAddSelectedOrders = (selectedIds: number[]): OrdersAddSelectedOrders => ({
  type: ORDERS_ADD_SELECTED_ORDERS,
  payload: { selectedIds },
});

export const ordersRemoveSelectedOrders = (
  selectedIds: number[]
): OrdersRemoveSelectedOrdersAction => {
  return {
    type: ORDERS_REMOVE_SELECTED_ORDERS,
    payload: { selectedIds },
  };
};

export const ordersSelectAll = (search: string): OrdersSelectAllAction => ({
  type: ORDERS_SELECT_ALL,
  payload: { search },
});
