import cx from 'classnames';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardFooter } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';
import CenterCropImg from '../../../../components/CenterCropImg/CenterCropImg';
import useToggle from '../../../../components/ModalForm/useToggle';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { SHOPNET_SHOP_ID } from '../../../../global/constants';
import { getSessionActiveShop } from '../../../../global/session/selectors';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { decodeImageSrc, formatPrice } from '../../../../helpers/utils';
import { ShopEntity, WarehouseProductEntity, WarehouseProductStatus } from '../../../../schemas';
import { WarehouseAddToStoreRequestPayload, warehouseAddToStoreSlice } from '../../slices';
import ApproveProductsModal from '../ApproveProductsModal/ApproveProductsModal';
import styles from './WarehouseProduct.module.scss';

/* eslint-disable react/no-danger */

type Props = {
  product: WarehouseProductEntity;
};

const defaultHtmlOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'p', 'br'],
  // allowedAttributes: {
  //   a: ['href'],
  // },
};

const trimmed = (text: string, length: number) => {
  return text && text.length > length ? `${text.substring(0, length - 3)}...` : text;
};

const WarehouseProduct: FC<Props> = ({ product }: Props) => {
  const shop: ShopEntity | null = useSelector(getSessionActiveShop);
  const [approveProductsOpen, approveProductsToggle] = useToggle();
  const currency = shop?.currency;
  const { maxPrice, minPrice } = product;

  const dispatch = useDispatch();
  const approveProductsTrigger = (payload: WarehouseAddToStoreRequestPayload) => {
    dispatch(warehouseAddToStoreSlice.actions.trigger(payload));
  };

  // const setProductCollectionsTrigger = () =>
  //   dispatch(
  //     setCollectionsSlice.actions.trigger({
  //       productIds: [briefProduct.productId],
  //       collectionIds: collections.filter((c) => c.checked).map((c) => c.id),
  //     })
  //   );

  const sanitizeText = (dirty: string, options?: sanitizeHtml.IOptions) => ({
    __html: sanitizeHtml(dirty, { ...defaultHtmlOptions, ...options }),
  });

  return (
    <>
      <Card className={cx(styles.WarehouseProduct, 'mb-3 mr-3')}>
        <CardBody className="position-relative pt-0">
          <a
            href={product.productUrl}
            className={styles.WarehouseProduct__mainlink}
            rel="noreferrer"
            target="_blank"
          >
            <div className="d-flex">
              <div className="mr-3">
                <CenterCropImg
                  alt="product"
                  className="rounded"
                  height={240}
                  src={decodeImageSrc(product.image)}
                  width={240}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className={styles.WarehouseProduct__vendor}>{product.vendor}</div>
              <div className={styles.WarehouseProduct__title}>{product.name}</div>
              <div className={cx(tableStyles.textDark, styles.WarehouseProduct__price)}>
                {minPrice !== maxPrice
                  ? `${formatPrice(minPrice, currency)} - ${formatPrice(maxPrice, currency)}`
                  : formatPrice(minPrice, currency)}
              </div>
              <div className={styles.WarehouseProduct__inventory}>{product.inventory} in stock</div>
            </div>
            {/* <div className="mt-3"> */}
            {/*   <div className={styles.WarehouseProduct__categories}> */}
            {/*     {product.categories.map((category, idx) => { */}
            {/*       // eslint-disable-next-line react/no-array-index-key */}
            {/*       return <div key={idx}>{category}</div>; */}
            {/*     })} */}
            {/*   </div> */}
            {/* </div> */}
            <div
              className={styles.WarehouseProduct__description}
              dangerouslySetInnerHTML={sanitizeText(trimmed(product.description, 164))}
            />
          </a>
        </CardBody>
        <CardFooter className="d-flex align-items-center justify-content-between">
          <div>
            {product.status !== WarehouseProductStatus.Approved && (
              <PrimaryButton
                size="small"
                type="button"
                className={cx(styles.WarehouseProduct__addToStore, 'mx-auto')}
                onClick={approveProductsToggle}
                disabled={shop?.id === SHOPNET_SHOP_ID}
              >
                Add to Store
              </PrimaryButton>
            )}
          </div>
          <div>
            {product.status === WarehouseProductStatus.Approved && (
              <div className={styles.WarehouseProduct__inStore}>In Store</div>
            )}
          </div>
        </CardFooter>
      </Card>
      <ApproveProductsModal
        isOpen={approveProductsOpen}
        toggle={approveProductsToggle}
        // selectedProducts={new Set([product.productId])}
        whProductId={product.productId}
        submit={approveProductsTrigger}
      />
    </>
  );
};

export default WarehouseProduct;
