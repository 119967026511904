import { SagaIterator } from '@redux-saga/core';
import { all, takeLatest } from 'redux-saga/effects';
import {
  SHIPPING_TYPE_API_URL,
  SHIPPING_TYPES_API_URL,
  SHIPPING_TYPES_ORDER_API_URL,
} from '../../global/urls';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { SessionSchema, ShippingTypeSchema } from '../../schemas';
import {
  createShippingTypeSlice,
  deleteShippingTypeSlice,
  fetchShippingTypesSlice,
  saveShippingTypesOrderSlice,
  updateShippingTypeSlice,
} from './slices/shippingTypesSlice';

const fetchSaga = makeApiRequestSaga({
  method: 'GET',
  baseUrl: SHIPPING_TYPES_API_URL,
  responseSchema: {
    shippingTypes: [ShippingTypeSchema],
    session: SessionSchema,
  },
  actions: fetchShippingTypesSlice.actions,
});

const createSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: SHIPPING_TYPES_API_URL,
  queryPayload: ({ name }) => ({ name }),
  responseSchema: {
    shippingTypes: [ShippingTypeSchema],
    session: SessionSchema,
  },
  actions: createShippingTypeSlice.actions,
});

const saveOrderSaga = makeApiRequestSaga({
  method: 'POST',
  baseUrl: SHIPPING_TYPES_ORDER_API_URL,
  queryPayload: (payload) => payload,
  responseSchema: {
    shippingTypes: [ShippingTypeSchema],
    session: SessionSchema,
  },
  actions: saveShippingTypesOrderSlice.actions,
});

const updateSaga = makeApiRequestSaga({
  method: 'PUT',
  baseUrl: SHIPPING_TYPE_API_URL,
  urlParams: ({ id }) => ({ id }),
  queryPayload: (payload) => payload,
  responseSchema: {
    shippingType: ShippingTypeSchema,
    session: SessionSchema,
  },
  actions: updateShippingTypeSlice.actions,
});

const deleteSaga = makeApiRequestSaga({
  method: 'DELETE',
  baseUrl: SHIPPING_TYPE_API_URL,
  urlParams: ({ id }) => ({ id }),
  responseSchema: {
    shippingTypes: [ShippingTypeSchema],
    session: SessionSchema,
  },
  actions: deleteShippingTypeSlice.actions,
});

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(fetchShippingTypesSlice.actions.trigger.type, fetchSaga),
    takeLatest(createShippingTypeSlice.actions.trigger.type, createSaga),
    takeLatest(saveShippingTypesOrderSlice.actions.trigger.type, saveOrderSaga),
    takeLatest(updateShippingTypeSlice.actions.trigger.type, updateSaga),
    takeLatest(deleteShippingTypeSlice.actions.trigger.type, deleteSaga),
  ]);
}
