import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';

type LoginAsQueryParams = {
  search: string;
};

const useLoginAsQueryParams = (): LoginAsQueryParams => {
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const search: string = parsed.search ? parsed.search.toString() : '';

  return {
    search,
  };
};

export default useLoginAsQueryParams;
