import produce from 'immer';
import { Reducer } from 'redux';
import {
  OrdersFetchApiFailureAction,
  OrdersFetchApiRequestAction,
  OrdersFetchApiSuccessAction,
} from '../actions/fetch.actions';
import { ORDERS_FETCH_API_SUCCESS } from '../constants';
import { OrdersSearchState } from '../types';

export const initialSearchState: OrdersSearchState = {
  allIds: null,
};

export type OrdersSearchAction =
  | OrdersFetchApiFailureAction
  | OrdersFetchApiRequestAction
  | OrdersFetchApiSuccessAction;

export const ordersSearchReducer: Reducer<OrdersSearchState, OrdersSearchAction> = (
  state: OrdersSearchState = initialSearchState,
  action: OrdersSearchAction
): OrdersSearchState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ORDERS_FETCH_API_SUCCESS: {
        const { ids } = action.payload.response.result;
        draft.allIds = ids;
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
