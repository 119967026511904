import React, { CSSProperties, FC, useMemo, useState } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import LinkButton from '../LinkButton/LinkButton';

const selectRow = <RowType extends unknown>(
  onSelect: (row: RowType) => void,
  onSelectAll: (isSelect: boolean, rows: RowType[]) => void,
  selected?: number[]
): SelectRowProps<RowType> => ({
  classes: 'py-2 align-middle',
  clickToSelect: false,
  headerColumnStyle: () => ({ width: '44px' } as CSSProperties),
  mode: 'checkbox',
  onSelect,
  onSelectAll,
  selected,
  selectionHeaderRenderer: ({
    checked,
    indeterminate,
  }: {
    checked: boolean;
    indeterminate: boolean;
  }) => {
    return <CustomCheckbox checked={checked} indeterminate={indeterminate} onChange={() => {}} />;
  },
  selectionRenderer: ({ checked }: { checked: boolean }) => (
    <CustomCheckbox checked={checked} onChange={() => {}} />
  ),
});

export default selectRow;

export function useTableSelectRows<RowType extends { id: number }>(
  allIds: number[]
): {
  selected: Set<number>;
  setSelected: (selected: Set<number>) => void;
  selectRow: SelectRowProps<RowType>;
  selectAll: () => void;
  areAllSelected: boolean;
} {
  const initialSelected = new Set<number>();
  const [selected, setSelected] = useState(initialSelected);

  const onSelect = (row: RowType) => {
    const newSelected = new Set(selected);
    if (selected.has(row.id)) {
      newSelected.delete(row.id);
    } else {
      newSelected.add(row.id);
    }
    setSelected(newSelected);
  };

  const onSelectAll = (isSelected: boolean, rows: RowType[]) => {
    const newSelected = new Set(isSelected ? rows.map((c) => c.id) : []);
    setSelected(newSelected);
  };

  const selectedArr = useMemo(() => [...selected], [selected]);

  const selectRowProps = selectRow<RowType>(onSelect, onSelectAll, selectedArr);

  const selectAll = () => setSelected(new Set(allIds));
  const areAllSelected = useMemo(() => allIds.length === selectedArr.length, [allIds, selectedArr]);

  return {
    selected,
    setSelected,
    selectRow: selectRowProps,
    selectAll,
    areAllSelected,
  };
}

type SelectedRowsInfoProps = {
  numSelected: number;
  total: number;
  selectAll: () => void;
};

export const SelectedRowsInfo: FC<SelectedRowsInfoProps> = ({
  numSelected,
  total,
  selectAll,
}: SelectedRowsInfoProps) => {
  const areAllSelected = numSelected === total;
  return numSelected ? (
    <div className="ml-3 small text-nowrap">
      <b>{numSelected}</b> selected (<b>{total}</b> total)
      {!areAllSelected && (
        <LinkButton className="ml-3" onClick={selectAll} size="small">
          Select all
        </LinkButton>
      )}
    </div>
  ) : null;
};
