import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import sortCaretFunc from '../../../../global/utils/sortCaretFunc';
import { CollectionEntity } from '../../../../schemas';
import { CollectionsColumn } from '../../types';
import {
  actionsFormatter,
  CollectionsFormatterExtraData,
  managedByFormatter,
  nameFormatter,
  productsCountFormatter,
  productsOrderFormatter,
} from './formatters';

interface Props {
  onEdit: (collection: CollectionEntity) => void;
}

const columns = ({
  onEdit,
}: Props): ColumnDescription<CollectionEntity, CollectionsFormatterExtraData>[] => {
  return [
    {
      dataField: CollectionsColumn.title,
      formatter: nameFormatter,
      formatExtraData: { onEdit },
      headerStyle: () => ({ paddingLeft: '22px', whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Name',
    },
    {
      dataField: CollectionsColumn.managedBy,
      formatter: managedByFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Managed by',
    },
    {
      dataField: CollectionsColumn.productsOrder,
      formatter: productsOrderFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: false,
      text: 'Products Order',
    },
    {
      dataField: CollectionsColumn.productsCount,
      formatter: productsCountFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Products',
    },
    {
      dataField: '',
      text: '',
      formatter: actionsFormatter,
      formatExtraData: { onEdit },
    },
  ];
};

export default columns;
