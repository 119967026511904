import { Action } from 'redux';
import {
  VENDORS_ADD_SELECTED_VENDORS,
  VENDORS_REMOVE_SELECTED_VENDORS,
  VENDORS_SELECT_ALL,
  VENDORS_TOGGLE_SELECTED_VENDOR,
} from '../constants';
import { VendorsType } from '../types';

// action types

export type VendorsToggleSelectedVendor = {
  type: typeof VENDORS_TOGGLE_SELECTED_VENDOR;
  payload: { id: number; type: VendorsType };
};

export type VendorsAddSelectedVendors = {
  type: typeof VENDORS_ADD_SELECTED_VENDORS;
  payload: { selectedIds: number[]; type: VendorsType };
};

export type VendorsRemoveSelectedVendors = {
  type: typeof VENDORS_REMOVE_SELECTED_VENDORS;
  payload: { selectedIds: number[]; type: VendorsType };
};

export interface VendorsSelectAllAction extends Action {
  type: typeof VENDORS_SELECT_ALL;
  payload: { search: string; type: VendorsType };
}

// action creators

export const vendorsToggleSelectedVendor = (
  type: VendorsType,
  id: number
): VendorsToggleSelectedVendor => ({
  type: VENDORS_TOGGLE_SELECTED_VENDOR,
  payload: { id, type },
});

export const vendorsAddSelectedVendors = (
  type: VendorsType,
  selectedIds: number[]
): VendorsAddSelectedVendors => ({
  type: VENDORS_ADD_SELECTED_VENDORS,
  payload: { selectedIds, type },
});

export const vendorsRemoveSelectedVendors = (
  type: VendorsType,
  selectedIds: number[]
): VendorsRemoveSelectedVendors => ({
  type: VENDORS_REMOVE_SELECTED_VENDORS,
  payload: { selectedIds, type },
});

export const vendorsSelectAll = (type: VendorsType, search: string): VendorsSelectAllAction => ({
  type: VENDORS_SELECT_ALL,
  payload: { search, type },
});
