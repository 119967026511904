import React, { FC } from 'react';
import DropdownSelect, {
  DropdownSelectOption,
} from '../../../../components/DropdownSelect/DropdownSelect';
import { ProductsType } from '../../types';

const CHOICES: DropdownSelectOption<ProductsType>[] = [
  [ProductsType.Active, 'Active Products'],
  [ProductsType.Archived, 'Archived Products'],
];

type Props = {
  className?: string;
  disabled?: boolean;
  onChange: (choice: ProductsType) => void;
  productsType: ProductsType;
};

const ProductsTypeDropdown: FC<Props> = ({
  className,
  disabled = false,
  onChange,
  productsType,
}: Props) => (
  <DropdownSelect
    options={CHOICES}
    selected={productsType}
    onChange={onChange}
    className={className}
    disabled={disabled}
  />
);

export default ProductsTypeDropdown;
