import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import GiftCardsMainContent from './components/GiftCardsMainContent/GiftCardsMainContent';

const GiftCards: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <GiftCardsMainContent key={key} />;
};

export default GiftCards;
