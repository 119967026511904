/* eslint-disable  @typescript-eslint/no-explicit-any */
import { SessionSectionNormalized } from '../../global/init/actions';
import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../types';
import {
  GOTO_LOGIN,
  LOGIN_API_FAILURE,
  LOGIN_API_REQUEST,
  LOGIN_API_SUCCESS,
  LOGIN_TRIGGER,
} from './constants';

// action types

export interface GoToLoginAction {
  type: typeof GOTO_LOGIN;
  payload: {
    nextUrl: string;
  };
}

export interface LoginTriggerAction {
  type: typeof LOGIN_TRIGGER;
  payload: {
    username: string;
    password: string;
    nextUrl: string;
  };
}

export interface LoginApiRequestAction extends APIRequestAction {
  type: typeof LOGIN_API_REQUEST;
}

export interface LoginNormalizedResponse extends NormalizedResponse {
  readonly result: {
    session: SessionSectionNormalized;
  };
}

export interface LoginApiSuccessAction extends APISuccessAction<LoginNormalizedResponse> {
  type: typeof LOGIN_API_SUCCESS;
}

export interface LoginApiFailureAction extends APIFailureAction {
  type: typeof LOGIN_API_FAILURE;
}

// action creators

export const gotoLogin = (nextUrl: string): GoToLoginAction => ({
  type: GOTO_LOGIN,
  payload: { nextUrl },
});

export const loginTrigger = (
  username: string,
  password: string,
  nextUrl: string
): LoginTriggerAction => ({
  type: LOGIN_TRIGGER,
  payload: { username, password, nextUrl },
});

export const loginApiRequest = (): LoginApiRequestAction => ({
  type: LOGIN_API_REQUEST,
});

export const loginApiSuccess = (response: LoginNormalizedResponse): LoginApiSuccessAction => ({
  type: LOGIN_API_SUCCESS,
  payload: { response },
});

export const loginApiFailure = (error: NormalizedError): LoginApiFailureAction => ({
  type: LOGIN_API_FAILURE,
  payload: { error },
});
