import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  SHIPPING_POLICIES_FETCH_API_FAILURE,
  SHIPPING_POLICIES_FETCH_API_REQUEST,
  SHIPPING_POLICIES_FETCH_API_SUCCESS,
  SHIPPING_POLICIES_FETCH_TRIGGER,
} from '../constants';
import { ShippingPoliciesSortOrder } from '../types';

// action types

type ShippingPoliciesFetchPayload = {
  sort: ShippingPoliciesSortOrder;
  start: number;
  search: string;
};

export interface ShippingPoliciesFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    count: number;
    ids: number[];
    shippingPolicies: number[];
  };
}

export interface ShippingPoliciesFetchApiRequestAction extends APIRequestAction {
  type: typeof SHIPPING_POLICIES_FETCH_API_REQUEST;
  payload: ShippingPoliciesFetchPayload;
}

export type ShippingPoliciesFetchTriggerAction = {
  type: typeof SHIPPING_POLICIES_FETCH_TRIGGER;
  payload: ShippingPoliciesFetchPayload;
};

export interface ShippingPoliciesFetchApiSuccessAction extends APISuccessAction {
  type: typeof SHIPPING_POLICIES_FETCH_API_SUCCESS;
  payload: ShippingPoliciesFetchPayload & {
    response: ShippingPoliciesFetchNormalizedResponse;
  };
}

export interface ShippingPoliciesFetchApiFailureAction extends APIFailureAction {
  type: typeof SHIPPING_POLICIES_FETCH_API_FAILURE;
  payload: ShippingPoliciesFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const shippingPoliciesFetchTrigger = (
  sort: ShippingPoliciesSortOrder,
  start: number,
  search: string
): ShippingPoliciesFetchTriggerAction => ({
  type: SHIPPING_POLICIES_FETCH_TRIGGER,
  payload: {
    search,
    sort,
    start,
  },
});

export const shippingPoliciesFetchApiRequest = (
  sort: ShippingPoliciesSortOrder,
  start: number,
  search: string
): ShippingPoliciesFetchApiRequestAction => ({
  type: SHIPPING_POLICIES_FETCH_API_REQUEST,
  payload: { search, sort, start },
});

export const shippingPoliciesFetchApiSuccess = (
  sort: ShippingPoliciesSortOrder,
  start: number,
  search: string,
  response: ShippingPoliciesFetchNormalizedResponse
): ShippingPoliciesFetchApiSuccessAction => ({
  type: SHIPPING_POLICIES_FETCH_API_SUCCESS,
  payload: { response, search, sort, start },
});

export const shippingPoliciesFetchApiFailure = (
  sort: ShippingPoliciesSortOrder,
  start: number,
  search: string,
  error: NormalizedError
): ShippingPoliciesFetchApiFailureAction => ({
  type: SHIPPING_POLICIES_FETCH_API_FAILURE,
  payload: { error, search, sort, start },
});
