import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ProductsSortOrder } from '../types';
import { replaceProductsUrl } from '../utils';
import useProductsQueryParams from './useProductsQueryParams';

const useOnSortChange = (): ((newSort: ProductsSortOrder) => void) => {
  const dispatch = useDispatch();
  const queryParams = useProductsQueryParams();

  return useCallback(
    (newSort: ProductsSortOrder) => {
      if (newSort !== queryParams.sortOrder) {
        replaceProductsUrl(dispatch, { ...queryParams, sort: newSort });
      }
    },
    [dispatch, queryParams]
  );
};

export default useOnSortChange;
