import { Field } from 'formik';
import React, { FC } from 'react';
import { Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';

const VendorProfileBankInformation: FC = () => {
  return (
    <Card className="h-lg-100 mb-3 p-3">
      <h4 className="sss-form-title">
        Bank Information <CustomTooltip text="?" />
      </h4>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Bank Name</Label>
            <Field as={Input} name="bankName" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Bank Address</Label>
            <Field as={Input} name="bankAddress" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Bank Account Number</Label>
            <Field as={Input} name="bankAccountNumber" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Bank Routing Number or Sort Code</Label>
            <Field as={Input} name="bankSortCode" type="text" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Bank IBAN</Label>
            <Field as={Input} name="bankIban" type="text" />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label className="sss-form-subtitle">Bank SWIFT BIC</Label>
            <Field as={Input} name="bankSwiftBic" type="text" />
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
};

export default VendorProfileBankInformation;
