import { SHORT_MONTHS } from '../../helpers/utils';

export const formatXAxis = (tickItem: string): string => {
  if (!tickItem || tickItem === 'auto') {
    return '';
  }
  const [, monthStr] = tickItem.split('-');
  const month = parseInt(monthStr, 10);
  return SHORT_MONTHS[month - 1];
};

export const formatYAxis = (tickItem: number | string): string => {
  if (typeof tickItem !== 'number') {
    return '';
  }
  return `${tickItem.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatTooltipLabel = (value: string): string => {
  if (!value) return '';
  const [yearStr, monthStr] = value.split('-');
  const month = parseInt(monthStr, 10);
  return `${SHORT_MONTHS[month - 1]} ${yearStr}`;
};

export const formatTooltipValue = (value: number): string => {
  return new Intl.NumberFormat('en').format(value);
};
