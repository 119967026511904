import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import useDenormalizeEntitiesMemo from '../../../global/hooks/useDenormalizeEntitiesMemo';
import { getRouterState } from '../../../global/selectors';
import { getSessionUser } from '../../../global/session/selectors';
import { LocationState } from '../../../global/types';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { SHIPPING_POLICIES_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import {
  ShippingPolicyEntity,
  ShippingPolicySchema,
  ShippingType,
  ShippingTypeSchema,
  UserEntity,
} from '../../../schemas';
import { NormalizedError } from '../../../types';
import { shippingPoliciesFetchTrigger } from '../actions/fetch.actions';
import { SHIPPING_POLICIES_FETCH_API } from '../constants';
import { getShippingPoliciesQueryState } from '../selectors';
import { FormItems, ShippingPoliciesSortOrder } from '../types';
import useShippingPoliciesQueryParams from './useShippingPoliciesQueryParams';

type ShippingPoliciesPageData = {
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  formItems: FormItems;
  isLoading: boolean;
  loaded: boolean;
  locationState: LocationState;
  shippingPolicies: ShippingPolicyEntity[] | null;
  shippingPoliciesFetchTrigger(): void;
  sortOrder: ShippingPoliciesSortOrder;
  start: number;
  user: UserEntity | null;
};

const useShippingPoliciesData = (): ShippingPoliciesPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { locationState, search, sortOrder, start } = useShippingPoliciesQueryParams();
  const correctLocation = location.pathname === SHIPPING_POLICIES_URL;
  const entities = useSelector(getEntitiesState);
  const user = useSelector(getSessionUser);
  const { count, error, loaded, shippingPolicyIds } = useSelector((state: AppState) =>
    getShippingPoliciesQueryState(state, sortOrder, start, search)
  );

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, SHIPPING_POLICIES_FETCH_API)
  );

  const shippingTypeIds = useSelector(
    (state: AppState) => state.containers.shippingTypes.shippingTypeIds
  );

  const shippingTypes = useDenormalizeEntitiesMemo<ShippingType>(
    entities,
    ShippingTypeSchema,
    shippingTypeIds
  );

  const existingShippingPolicies = useDenormalizeEntitiesMemo<ShippingPolicyEntity>(
    entities,
    ShippingPolicySchema,
    shippingPolicyIds
  );

  const shippingTypeToPolicy = (shippingType: ShippingType): ShippingPolicyEntity => ({
    id: 0,
    additionalFee: 0,
    baseFee: 0,
    default: shippingType.default,
    disabled: true,
    free: false,
    name: shippingType.name,
    order: shippingType.order,
    productId: 0,
    shippingFreeAboveOrderValue: 0,
    shippingMaxCap: 0,
    shopId: 0,
    vendorId: 0,
  });

  const shippingPolicies = useMemo(
    () =>
      shippingTypes
        ? shippingTypes.map((shippingType) => {
            if (existingShippingPolicies) {
              const shippingPolicy = existingShippingPolicies.find(
                (policy) => policy.name === shippingType.name
              );
              if (shippingPolicy) return shippingPolicy;
            }
            return shippingTypeToPolicy(shippingType);
          })
        : null,
    [shippingTypes, existingShippingPolicies]
  );

  const formItems: FormItems = shippingPolicies
    ? shippingPolicies.reduce((acc, item) => {
        // eslint-disable-next-line no-param-reassign
        acc[item.order] = {
          additionalFee: item.additionalFee,
          applyToProducts: false,
          availability: !item.disabled,
          baseFee: item.baseFee,
          free: item.free,
          shippingFreeAbove: item.shippingFreeAboveOrderValue,
          shippingMaxCap: item.shippingMaxCap,
          order: item.order,
        };
        return acc;
      }, {} as FormItems)
    : {};

  return {
    correctLocation,
    count,
    dispatch,
    error,
    formItems,
    isLoading,
    loaded,
    locationState,
    shippingPolicies,
    shippingPoliciesFetchTrigger: () =>
      dispatch(shippingPoliciesFetchTrigger(sortOrder, start, search)),
    sortOrder,
    start,
    user,
  };
};

export default useShippingPoliciesData;
