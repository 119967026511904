import {
  SetLoginEmailAction,
  SetLoginPasswordAction,
  SetLoginPasswordConfirmAction,
} from '../actionTypes/login';
import { SET_LOGIN_EMAIL, SET_LOGIN_PASSWORD, SET_LOGIN_PASSWORD_CONFIRM } from '../constants';

export const setLoginEmail = (email: string): SetLoginEmailAction => ({
  type: SET_LOGIN_EMAIL,
  payload: { email },
});

export const setLoginPassword = (password: string): SetLoginPasswordAction => ({
  type: SET_LOGIN_PASSWORD,
  payload: { password },
});

export const setLoginPasswordConfirm = (
  passwordConfirm: string
): SetLoginPasswordConfirmAction => ({
  type: SET_LOGIN_PASSWORD_CONFIRM,
  payload: { passwordConfirm },
});
