import produce from 'immer';
import { Reducer } from 'redux';
import { LoginApiSuccessAction } from '../../containers/Login/actions';
import { LOGIN_API_SUCCESS } from '../../containers/Login/constants';
import { ShopEntity, UserEntity, VendorEntity } from '../../schemas';
import { EntitiesState } from '../../types';
import { InitApiSuccessAction, SessionSectionNormalized } from '../init/actions';
import { INIT_API_SUCCESS } from '../init/constants';
import { LogoutApiSuccessAction } from '../logout/actions';
import { LOGOUT_API_SUCCESS } from '../logout/constants';
import { ResetSessionAction } from './actions';
import { RESET_SESSION } from './constants';

export interface SessionState {
  activeShop: ShopEntity | null;
  isInitialized: boolean;
  shops: ShopEntity[];
  user: UserEntity | null;
  vendorshop: VendorEntity | null;
}

const initialState: SessionState = {
  activeShop: null,
  isInitialized: false,
  shops: [],
  user: null,
  vendorshop: null,
};

const initSession = (
  state: SessionState,
  normalizedSessionSection: SessionSectionNormalized,
  entities: EntitiesState
) =>
  produce(state, (draft) => {
    const activeShopId = normalizedSessionSection.activeShop;
    const shopIds = normalizedSessionSection.shops || [];
    const userId = normalizedSessionSection.user;
    const vendorshopId = normalizedSessionSection.vendorshop;
    draft.activeShop = activeShopId ? entities.shops[activeShopId] : null;
    draft.isInitialized = true;
    draft.shops = userId ? shopIds.map((shopId) => entities.shops[shopId]) : [];
    draft.user = userId ? entities.users[userId] : null;
    draft.vendorshop = vendorshopId ? entities.vendors[vendorshopId] : null;
    return draft;
  });

type SessionReducerAction =
  | InitApiSuccessAction
  | LoginApiSuccessAction
  | LogoutApiSuccessAction
  | ResetSessionAction;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sessionReducer: Reducer<SessionState, SessionReducerAction> = (
  state: SessionState = initialState,
  action: SessionReducerAction
) =>
  produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case INIT_API_SUCCESS:
      case LOGIN_API_SUCCESS:
        return initSession(
          state,
          action.payload.response.result.session,
          action.payload.response.entities
        );

      case LOGOUT_API_SUCCESS: {
        draft.activeShop = null;
        draft.shops = [];
        draft.user = null;
        draft.vendorshop = null;
        break;
      }

      case RESET_SESSION:
        if (!action.payload.dummy)
          return initSession(state, action.payload.normalizedSession, action.payload.entities);
        break;

      default:
        return draft;
    }

    return draft;
  });
