import { Location } from 'history';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { LocationState } from '../../../global/types';
import { defaultSortOrder } from '../constants';
import { OrdersSortOrder } from '../types';

type OrdersQueryParams = {
  location: Location<LocationState>;
  locationState: LocationState;
  search: string;
  sortOrder: OrdersSortOrder;
  start: number;
};

const parseOrdersSortOrder = (sort: string): OrdersSortOrder => {
  const sortOrders = Object.values(OrdersSortOrder);
  return sortOrders.includes(sort as OrdersSortOrder)
    ? (sort as OrdersSortOrder)
    : defaultSortOrder;
};

const useOrdersQueryParams = (): OrdersQueryParams => {
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const start: number = parsed.start ? parseInt(parsed.start.toString(), 10) : 0;
  const search: string = parsed.search ? parsed.search.toString() : '';
  const sortOrder = parseOrdersSortOrder(parsed.sort ? parsed.sort.toString() : '');
  const { state } = location;

  return {
    location,
    locationState: state,
    search,
    sortOrder,
    start,
  };
};

export default useOrdersQueryParams;
