import cx from 'classnames';
import {
  FormItems,
  FormValues,
  GroupItemsAndQuantities,
  ItemAndQuantity,
  OrderItemGroup,
} from 'containers/Order/types';
import { Formik, FormikProps } from 'formik';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'reactstrap';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import { AppState } from '../../../../init/rootReducer';
import { OrderEntity, OrderItemEntity, ShopEntity } from '../../../../schemas';
import { getOrderSelectedItems } from '../../selectors';
import { selectedItemsAndQuantities } from '../../utils';
import OrderItemsFooter from '../OrderItemsFooter/OrderItemsFooter';
import OrderItemsList from '../OrderItemsList/OrderItemsList';
import OrderItemsSum from '../OrderItemsSum/OrderItemsSum';
import styles from './OrderItems.module.scss';

interface Props {
  isFulfilling: boolean;
  isRefunding: boolean;
  items: OrderItemEntity[];
  order: OrderEntity | null;
  orderFulfillTrigger: (items: ItemAndQuantity[], trackingCode: string) => void;
  orderName: string;
  orderRefundTrigger: (
    groupItems: GroupItemsAndQuantities,
    reason: string,
    shippingOnly: boolean
  ) => void;
  shop: ShopEntity | null;
}

const OrderItems: FC<Props> = ({
  isFulfilling,
  isRefunding,
  items,
  order,
  orderFulfillTrigger,
  orderName,
  orderRefundTrigger,
  shop,
}: Props) => {
  const selectedFulfilledIds = useSelector((state: AppState) =>
    getOrderSelectedItems(state, orderName, OrderItemGroup.Fulfilled)
  );
  const selectedUnfulfilledIds = useSelector((state: AppState) =>
    getOrderSelectedItems(state, orderName, OrderItemGroup.Unfulfilled)
  );

  const unfulfilledItems: OrderItemEntity[] = items
    .filter((item) => item.fulfilledQuantity + item.refundedQuantity < item.totalQuantity)
    .map((item) => ({
      ...item,
      quantity: item.fulfillableQuantity,
      totalQuantity: item.fulfillableQuantity,
    }));

  const fulfilledItems: OrderItemEntity[] = items
    .filter((item) => item.fulfilledQuantity > 0)
    .map((item) => ({
      ...item,
      quantity: item.fulfilledQuantity,
      totalQuantity: item.fulfilledQuantity,
    }));

  if (unfulfilledItems.length === 0 && fulfilledItems.length === 0) {
    return null;
  }

  const itemsPrice = items.reduce((acc, item) => acc + item.price, 0);
  const discount = -items.reduce((acc, item) => acc + (item.discountAmount || 0), 0);
  const shippingPrice = order?.shippingPrice;
  const totalTax = order?.totalTax;
  const totalPrice =
    itemsPrice !== undefined && shippingPrice !== undefined && totalTax !== undefined
      ? itemsPrice + shippingPrice + totalTax + discount
      : undefined;

  const unfulfilledFormItems: FormItems = unfulfilledItems.reduce((acc, item) => {
    // eslint-disable-next-line no-param-reassign
    acc[item.id] = {
      quantity: item.quantity || 0,
    };
    return acc;
  }, {} as FormItems);

  const fulfilledFormItems: FormItems = fulfilledItems.reduce((acc, item) => {
    // eslint-disable-next-line no-param-reassign
    acc[item.id] = {
      quantity: item.quantity || 0,
    };
    return acc;
  }, {} as FormItems);

  const initialValues: FormValues = {
    fulfilledItems: fulfilledFormItems,
    trackingCode: '',
    unfulfilledItems: unfulfilledFormItems,
  };

  const handleFulfill = (fprops: FormikProps<FormValues>) => {
    const itemsToFulfill: ItemAndQuantity[] = unfulfilledItems
      .filter((item) => selectedUnfulfilledIds.includes(item.id))
      .filter((item) => fprops.values.unfulfilledItems[item.id].quantity > 0)
      .map((item) => ({
        itemId: item.id,
        quantity: fprops.values.unfulfilledItems[item.id].quantity,
      }));
    orderFulfillTrigger(itemsToFulfill, fprops.values.trackingCode);
  };

  const handleRefund = (fprops: FormikProps<FormValues>, reason: string) => {
    const itemsToRefund = {
      [OrderItemGroup.Unfulfilled]: selectedItemsAndQuantities(
        unfulfilledItems,
        selectedUnfulfilledIds,
        fprops.values.unfulfilledItems
      ),
      [OrderItemGroup.Fulfilled]: selectedItemsAndQuantities(
        fulfilledItems,
        selectedFulfilledIds,
        fprops.values.fulfilledItems
      ),
    };
    orderRefundTrigger(itemsToRefund, reason, false);
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values /* eslint-disable-line @typescript-eslint/no-unused-vars */) => {
        /* dummy */
      }}
    >
      {(fprops) => (
        <form>
          <Card className={cx('h-lg-100 mb-3', styles.OrderItems)}>
            {unfulfilledItems.length > 0 && (
              <>
                <FalconCardHeader border={false} className="bg-white" title="Unfulfilled Items" />
                <OrderItemsList
                  group={OrderItemGroup.Unfulfilled}
                  items={unfulfilledItems}
                  key="unfulfilled"
                  orderName={orderName}
                  selectedIds={selectedUnfulfilledIds}
                  shop={shop}
                />
              </>
            )}
            {fulfilledItems.length > 0 && (
              <>
                <FalconCardHeader border={false} className="bg-white" title="Fulfilled Items" />
                <OrderItemsList
                  group={OrderItemGroup.Fulfilled}
                  items={fulfilledItems}
                  orderName={orderName}
                  selectedIds={selectedFulfilledIds}
                  shop={shop}
                />
              </>
            )}
            <Row className="pr-4">
              <Col />
              <Col lg="auto">
                <OrderItemsSum heading="Items" shop={shop} value={itemsPrice} />
              </Col>
              <Col lg="auto">
                <OrderItemsSum heading="Discount" shop={shop} value={discount} />
              </Col>
              <Col lg="auto">
                <OrderItemsSum heading="Shipping" shop={shop} value={shippingPrice} />
              </Col>
              <Col lg="auto">
                <OrderItemsSum heading="Tax" shop={shop} value={totalTax} />
              </Col>
              <Col lg="auto">
                <OrderItemsSum heading="Total" shop={shop} value={totalPrice} />
              </Col>
            </Row>
            <OrderItemsFooter
              fprops={fprops}
              isFulfilling={isFulfilling}
              isRefunding={isRefunding}
              onFulfill={() => handleFulfill(fprops)}
              onRefund={(reason: string) => handleRefund(fprops, reason)}
              selectedFulfilled={selectedFulfilledIds}
              selectedUnfulfilled={selectedUnfulfilledIds}
            />
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default OrderItems;
