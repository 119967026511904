import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import {
  getSessionActiveShop,
  getSessionUser,
  getSessionVendor,
} from '../../../global/session/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { GIFT_CARD_URL, MODERATION_URL, PRODUCT_URL } from '../../../global/urls';
import { isShopNetShop } from '../../../global/utils/shopnet';
import { AppState } from '../../../init/rootReducer';
import {
  ProductVersionEntity,
  ProductVersionSchema,
  ShopEntity,
  UserEntity,
  VendorEntity,
} from '../../../schemas';
import { NormalizedError } from '../../../types';
import { approvePublishSlice, rejectPublishSlice } from '../../Moderation/slices/moderationSlice';
import { productFetchTrigger } from '../actions/fetch.actions';
import { productSubmitTrigger } from '../actions/submit.actions';
import { PRODUCT_FETCH_API, PRODUCT_SUBMIT_API } from '../constants';
import { getProductState } from '../selectors';
import { productArchiveSlice, productUnarchiveSlice, productUnpublishSlice } from '../slices';
import {
  ImageItem,
  ProductCollection,
  ProductItemInformation,
  ProductItemProperties,
  ProductItemSeo,
  ProductOption,
  ProductShippingPolicy,
  ProductSubmitValues,
  ProductVariantItem,
} from '../types';
import { isShopNetProduct1, skuWithVendorPrefix } from '../utils';
import useProductParams from './useProductParams';

interface ProductData {
  approvePublishTrigger(): void;
  correctLocation: boolean;
  dispatch: Dispatch;
  error: NormalizedError | null;
  isDisabledVendor: boolean;
  isGiftCardProduct: boolean;
  isLoading: boolean;
  isModeration: boolean;
  isModerationAllowed?: boolean;
  isShopNet: boolean;
  isShopNetProduct: boolean;
  isSubmitting: boolean;
  isTouched: boolean;
  loaded: boolean;
  product: ProductVersionEntity;
  productFetchTrigger(): void;
  productId: number;
  productPublishTrigger(): void;
  productSubmitTrigger(): void;
  productArchiveTrigger(): void;
  productUnarchiveTrigger(): void;
  productUnpublishTrigger(): void;
  rejectPublishTrigger(message: string): void;
  shop: ShopEntity | null;
  shopUrl: string;
  showUnpublish: boolean;
  user: UserEntity | null;
  vendor: VendorEntity | null;
  enquiriesAllowed: boolean;
}

interface MakeSubmitValuesProps {
  collections: ProductCollection[];
  images: ImageItem[];
  information: ProductItemInformation;
  isGiftCardProduct: boolean;
  isMultipleVariants: boolean;
  isPhysicalProduct: boolean;
  isShopNet: boolean;
  options: ProductOption[];
  properties: ProductItemProperties;
  publish: boolean;
  seo: ProductItemSeo;
  shipping: ProductShippingPolicy[];
  variants: ProductVariantItem[];
  vendorCode: string | null;
}

const toNumberOrNull = (value: string | null): number | null => {
  if (value === null) return null;
  if (value.length === 0) return null;
  return Number(value);
};

const makeSubmitValues = ({
  collections,
  images,
  information,
  isGiftCardProduct,
  isMultipleVariants,
  isPhysicalProduct,
  isShopNet,
  options,
  properties,
  publish,
  seo,
  shipping,
  variants,
  vendorCode,
}: MakeSubmitValuesProps): ProductSubmitValues => {
  return {
    age18plusRequired: properties.ageRestricted,
    customizationEnabled: properties.customizable,
    description: information.description,
    enquiry: properties.enquiry,
    handle: seo.url,
    images: images.map((item) => ({
      id: item.id,
      imageData: item.id.length === 36 ? item.imagePreviewUrl : null,
    })),
    isGiftCardProduct,
    isMultipleVariants,
    options: isMultipleVariants
      ? options.map((item) => ({
          name: item.name,
          values: item.values,
        }))
      : [],
    requiresShipping: isGiftCardProduct || isShopNet ? false : isPhysicalProduct,
    seoDescription: seo.description,
    seoTitle: seo.title,
    shipping: shipping.map((item) => ({
      additionalFee: Number(item.additionalItem),
      baseFee: Number(item.baseShippingFee),
      enabled: item.available,
      free: item.freeShipping,
      name: item.name,
    })),
    tags: information.tags,
    title: information.title,
    variants: variants
      .filter((item) => !item.isDeleted)
      .filter((item, idx) => isMultipleVariants || idx === 0)
      .map((item) => ({
        id: item.id.length === 36 ? null : parseInt(item.id, 10),
        allowOverselling: isGiftCardProduct ? true : item.purchaseNotInStock,
        barcode: item.barcode,
        compareAtPrice: toNumberOrNull(item.compareToPrice),
        imageId: item.imageId ? item.imageId : null,
        options: item.options,
        price: Number(item.price),
        quantity: item.quantity,
        sku: skuWithVendorPrefix(item.sku, vendorCode),
        trackInventoryEnabled: isGiftCardProduct ? false : item.trackInventory,
      })),
    collectionIds: collections.filter((pc) => pc.checked).map((pc) => pc.id),
    publish,
  };
};

const useProductData = (): ProductData => {
  const dispatch = useDispatch();
  const { productId, moderationId, moderationRequest } = useProductParams();
  const { location } = useSelector(getRouterState);
  const shop = useSelector(getSessionActiveShop);
  const user = useSelector(getSessionUser);
  const vendor = useSelector(getSessionVendor);
  const productMatch = matchPath(location.pathname, { path: PRODUCT_URL, exact: true });
  const moderationMatch = matchPath(location.pathname, { path: MODERATION_URL, exact: true });
  const giftCardProductMatch = matchPath(location.pathname, {
    path: GIFT_CARD_URL,
    exact: true,
  });
  const isModeration = !!moderationMatch;
  const isModerationAllowed = isModeration && moderationRequest?.reviewer?.id === user?.id;
  const isGiftCardProduct = !!giftCardProductMatch;
  const correctLocation = !!productMatch || !!moderationMatch || !!giftCardProductMatch;
  const entities = useSelector(getEntitiesState);
  const {
    collections,
    error,
    images,
    information,
    isDisabledVendor,
    isMultipleVariants,
    isPhysicalProduct,
    isTouched,
    loaded,
    options,
    properties,
    seo,
    shipping,
    showUnpublish,
    variants,
    vendorCode,
    enquiriesAllowed,
  } = useSelector((state: AppState) => getProductState(state, productId));

  const isLoading = useSelector((state: AppState) => checkIfProcessing(state, PRODUCT_FETCH_API));
  const isSubmittingProduct = useSelector((state: AppState) =>
    checkIfProcessing(state, PRODUCT_SUBMIT_API)
  );
  const isUnpublishing = useSelector(
    (state: AppState) => state.containers.productUnpublishLoading.isLoading
  );
  const isSubmitting = isSubmittingProduct || isUnpublishing;

  const product = useMemo(() => denormalize(productId, ProductVersionSchema, entities), [
    entities,
    productId,
  ]);

  const isShopNet = isShopNetShop(shop);
  const isShopNetProduct = isShopNetProduct1(productId);

  const productSubmitValues = makeSubmitValues({
    images,
    information,
    isGiftCardProduct,
    isMultipleVariants,
    isPhysicalProduct,
    isShopNet,
    options,
    properties,
    seo,
    shipping,
    variants,
    vendorCode,
    publish: false,
    collections,
  });

  const productUnpublishTrigger = productUnpublishSlice.actions.trigger;
  const productArchiveTrigger = productArchiveSlice.actions.trigger;
  const productUnarchiveTrigger = productUnarchiveSlice.actions.trigger;
  const rejectPublishTrigger = rejectPublishSlice.actions.trigger;
  const approvePublishTrigger = approvePublishSlice.actions.trigger;

  return {
    approvePublishTrigger: () =>
      dispatch(
        moderationId
          ? approvePublishTrigger({ moderationId, productId, values: productSubmitValues })
          : () => {}
      ),
    correctLocation,
    dispatch,
    error,
    isDisabledVendor,
    isGiftCardProduct,
    isLoading,
    isModeration,
    isModerationAllowed,
    isShopNet,
    isShopNetProduct,
    isSubmitting,
    isTouched,
    loaded,
    product,
    productFetchTrigger: () => dispatch(productFetchTrigger(productId, isGiftCardProduct)),
    productId,
    productPublishTrigger: () =>
      dispatch(productSubmitTrigger(productId, { ...productSubmitValues, publish: true })),
    productSubmitTrigger: () => dispatch(productSubmitTrigger(productId, productSubmitValues)),
    productUnpublishTrigger: () => dispatch(productUnpublishTrigger({ productId })),
    productArchiveTrigger: () => dispatch(productArchiveTrigger({ productId })),
    productUnarchiveTrigger: () => dispatch(productUnarchiveTrigger({ productId })),
    rejectPublishTrigger: (message: string) =>
      dispatch(rejectPublishTrigger({ moderationId, message })),
    shop,
    shopUrl: shop?.url || '',
    showUnpublish,
    user,
    vendor,
    enquiriesAllowed,
  };
};

export default useProductData;
