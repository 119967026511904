import { ErrorMessage, Field } from 'formik';
import React, { FC } from 'react';
import { FormGroup, Label } from 'reactstrap';
import FormikSelect from '../../../../components/FormikSelect/FormikSelect';
import { CollectionProductsOrder } from '../../../../schemas';

type Props = Record<string, never>;

const OPTIONS = [
  { value: CollectionProductsOrder.BestSelling, label: 'Best Selling' },
  { value: CollectionProductsOrder.AlphaAsc, label: 'Product title A-Z' },
  { value: CollectionProductsOrder.AlphaDesc, label: 'Product title Z-A' },
  { value: CollectionProductsOrder.PriceDesc, label: 'Highest Price' },
  { value: CollectionProductsOrder.PriceAsc, label: 'Lowest Price' },
  { value: CollectionProductsOrder.CreatedDesc, label: 'Newest' },
  { value: CollectionProductsOrder.Created, label: 'Oldest' },
  { value: CollectionProductsOrder.Manual, label: 'Manual' },
];

const CollectionOrder: FC<Props> = () => (
  <FormGroup>
    <Label className="small" htmlFor="collection-products-order">
      Order
    </Label>
    <Field
      component={FormikSelect}
      id="collection-products-order"
      name="productsOrder"
      options={OPTIONS}
    />
    <ErrorMessage name="vendorId" />
  </FormGroup>
);

export default CollectionOrder;
