import { CSSProperties } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import sortCaretFunc from '../../../../global/utils/sortCaretFunc';
import { GiftCardProductEntity, ShopEntity } from '../../../../schemas';
import { GiftCardProductsColumn } from '../../types';
import {
  actionsFormatter,
  FormatterExtraData,
  skuFormatter,
  soldCountFormatter,
  statusFormatter,
  valueFormatter,
} from './formatters';

const columns = (
  shop: ShopEntity | null
): ColumnDescription<GiftCardProductEntity, FormatterExtraData>[] => {
  return [
    {
      dataField: GiftCardProductsColumn.value,
      formatExtraData: { shop },
      formatter: valueFormatter,
      headerStyle: () => ({ paddingLeft: '22px', whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      style: () => ({ paddingRight: '40px' } as CSSProperties),
      text: 'Value',
    },
    {
      dataField: GiftCardProductsColumn.sku,
      formatter: skuFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'SKU',
    },
    {
      dataField: GiftCardProductsColumn.soldCount,
      formatter: soldCountFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      text: 'Sold',
    },
    {
      dataField: GiftCardProductsColumn.status,
      formatter: statusFormatter,
      headerStyle: () => ({ whiteSpace: 'nowrap' } as CSSProperties),
      sort: true,
      sortCaret: sortCaretFunc,
      text: 'Status',
    },
    {
      dataField: '',
      formatter: actionsFormatter,
      headerStyle: () => ({ width: '4%' }),
      text: '',
    },
  ];
};

export default columns;
