import {
  APIFailureAction,
  APIRequestAction,
  APISuccessAction,
  NormalizedError,
  NormalizedResponse,
} from '../../../types';
import {
  LOGIN_AS_FETCH_API_FAILURE,
  LOGIN_AS_FETCH_API_REQUEST,
  LOGIN_AS_FETCH_API_SUCCESS,
  LOGIN_AS_FETCH_TRIGGER,
} from '../constants';

// interfaces

interface LoginAsFetchPayload {
  search: string;
}

export interface LoginAsFetchTriggerAction {
  type: typeof LOGIN_AS_FETCH_TRIGGER;
  payload: LoginAsFetchPayload;
}

export interface LoginAsFetchApiRequestAction extends APIRequestAction {
  type: typeof LOGIN_AS_FETCH_API_REQUEST;
  payload: LoginAsFetchPayload;
}

export interface LoginAsFetchNormalizedResponse extends NormalizedResponse {
  readonly result: {
    users: number[];
  };
}

export interface LoginAsFetchApiSuccessAction extends APISuccessAction {
  type: typeof LOGIN_AS_FETCH_API_SUCCESS;
  payload: LoginAsFetchPayload & {
    response: LoginAsFetchNormalizedResponse;
  };
}

export interface LoginAsFetchApiFailureAction extends APIFailureAction {
  type: typeof LOGIN_AS_FETCH_API_FAILURE;
  payload: LoginAsFetchPayload & {
    error: NormalizedError;
  };
}

// action creators

export const loginAsFetchTrigger = (search: string): LoginAsFetchTriggerAction => ({
  type: LOGIN_AS_FETCH_TRIGGER,
  payload: { search },
});

export const loginAsFetchApiRequest = (search: string): LoginAsFetchApiRequestAction => ({
  type: LOGIN_AS_FETCH_API_REQUEST,
  payload: { search },
});

export const loginAsFetchApiSuccess = (
  search: string,
  response: LoginAsFetchNormalizedResponse
): LoginAsFetchApiSuccessAction => ({
  type: LOGIN_AS_FETCH_API_SUCCESS,
  payload: { response, search },
});

export const loginAsFetchApiFailure = (
  search: string,
  error: NormalizedError
): LoginAsFetchApiFailureAction => ({
  type: LOGIN_AS_FETCH_API_FAILURE,
  payload: { error, search },
});
