import cx from 'classnames';
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import { VendorRegistrationScreen } from '../../types';
import VendorSignUpFooter from '../VendorSignUpFooter/VendorSignUpFooter';
import VendorSignUpHeader from '../VendorSignUpHeader/VendorSignUpHeader';
import styles from './BusinessDetails.module.scss';
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import RegisteredAddress from './components/RegisteredAddress/RegisteredAddress';
import TradingAddress from './components/TradingAddress/TradingAddress';

type Props = {
  inputHasError: boolean;
  setInputHasError: (hasError: boolean) => void;
  setScreen: React.Dispatch<SetStateAction<VendorRegistrationScreen>>;
};

const BusinessDetails: FC<Props> = ({ inputHasError, setInputHasError, setScreen }: Props) => {
  const [businessInputErrors, setBusinessInputErrors] = useState({
    general: true,
    registered: true,
  });
  const [submitTouched, setSubmitTouched] = useState(false);

  useEffect(() => {
    if (Object.values(businessInputErrors).every((error) => !error)) {
      setInputHasError(false);
    } else {
      setInputHasError(true);
    }
  }, [
    businessInputErrors,
    businessInputErrors.general,
    businessInputErrors.registered,
    setInputHasError,
  ]);

  return (
    <>
      <VendorSignUpHeader
        screen={VendorRegistrationScreen.BUSINESS_DETAILS}
        setInputHasError={setInputHasError}
        setScreen={setScreen}
      />
      <div className="px-5 pt-5">
        <Form>
          <GeneralInfo
            setBusinessInputErrors={setBusinessInputErrors}
            submitTouched={submitTouched}
          />
          <RegisteredAddress
            setBusinessInputErrors={setBusinessInputErrors}
            submitTouched={submitTouched}
          />
          <TradingAddress />
          <div className={cx('pt-4 pl-1 pb-1', styles.requiredFieldsText)}>
            <span className="sss-color-orange">*</span> Required Fields
          </div>
        </Form>
      </div>
      <VendorSignUpFooter
        screen={VendorRegistrationScreen.BUSINESS_DETAILS}
        showError={inputHasError && submitTouched}
        goBack={null}
        goNext={() => {
          setSubmitTouched(true);
          if (!inputHasError) {
            setScreen(VendorRegistrationScreen.CONTRACT);
          }
        }}
      />
    </>
  );
};

export default BusinessDetails;
