import React, { FC } from 'react';
import LoadingSwitch from '../../../../components/LoadingSwitch/LoadingSwitch';
import TableError from '../../../../components/TableError/TableError';
import TableLoadingIndicator from '../../../../components/TableLoadingIndicator/TableLoadingIndicator';
import TableNoResults from '../../../../components/TableNoResults/TableNoResults';
import TablePageWrapper from '../../../../components/TablePageWrapper/TablePageWrapper';
import useDebouncedSearch from '../../../../global/hooks/useDebouncedSearch';
import usePaging from '../../../../global/hooks/usePaging';
import useScrollToTop from '../../../../global/hooks/useScrollToTop';
import { PAGE_SIZE, PAGINATION_DISPLAYED_RANGE } from '../../constants';
import useCustomersPageData from '../../hooks/useCustomersPageData';
import { pushCustomersUrl, replaceCustomersUrl } from '../../utils';
import CustomersPagination from '../CustomersPagination/CustomersPagination';
import CustomersRightControls from '../CustomersRightControls/CustomersRightControls';
import CustomersTable from '../CustomersTable/CustomersTable';

const CustomersMainContent: FC = () => {
  useScrollToTop();

  const {
    customersFetchTrigger,
    count,
    correctLocation,
    customers,
    error,
    isLoading,
    loaded,
    search,
    shop,
    sortOrder,
    start,
  } = useCustomersPageData();

  const { searchInput, setSearchInput } = useDebouncedSearch({
    correctLocation,
    replaceUrl: replaceCustomersUrl,
    search,
    sortOrder,
  });

  const { activePage, onPageChange } = usePaging({
    pageSize: PAGE_SIZE,
    pushUrl: pushCustomersUrl,
    searchInput,
    sortOrder,
    start,
  });

  if (error) {
    return <TableError error={error} />;
  }

  return (
    <TablePageWrapper
      title="Customers"
      rightControls={
        <CustomersRightControls searchInput={searchInput} setSearchInput={setSearchInput} />
      }
      itemsCount={count}
      pagination={
        <CustomersPagination
          activePage={activePage}
          displayedRange={PAGINATION_DISPLAYED_RANGE}
          itemsPerPage={PAGE_SIZE}
          onPageChange={onPageChange}
          totalPages={count}
        />
      }
      showCount={false}
    >
      <LoadingSwitch
        error={!!error}
        loading={isLoading}
        onLoad={customersFetchTrigger}
        renderError={null}
        renderLoading={() => <TableLoadingIndicator />}
        require={(customers && customers.length > 0) || loaded || !correctLocation}
      >
        {customers && customers.length > 0 ? (
          <CustomersTable
            customers={customers}
            pageSize={PAGE_SIZE}
            shop={shop}
            sortOrder={sortOrder}
          />
        ) : (
          <TableNoResults search={search} text="Customers who placed an order will appear here." />
        )}
      </LoadingSwitch>
    </TablePageWrapper>
  );
};

export default CustomersMainContent;
