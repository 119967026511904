import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DASHBOARD_URL } from '../../global/urls';
import { isAuthenticated } from '../../helpers/utils';
import { selectShopTrigger } from '../ShopSelector/actions/selectActions';
import useGoToShopData from './hooks/useGoToShopData';

export interface Params {
  shopId: string;
}

const GoToShop: FC = () => {
  const { gotoLogin, gotoNoShops, initTrigger, isInitialized, shop, user } = useGoToShopData();
  const dispatch = useDispatch();
  const { shopId } = useParams<Params>();

  useEffect(() => {
    if (!isInitialized) initTrigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialized && !isAuthenticated(user)) {
      gotoLogin();
    }
  }, [gotoLogin, isInitialized, user]);

  useEffect(() => {
    if (isInitialized && isAuthenticated(user) && !shop) {
      gotoNoShops();
    }
  }, [gotoNoShops, isInitialized, shop, user]);

  useEffect(() => {
    if (isInitialized && isAuthenticated(user) && shop) {
      dispatch(selectShopTrigger(parseInt(shopId, 10), DASHBOARD_URL));
    }
  }, [dispatch, isInitialized, shop, shopId, user]);

  return null;
};

export default GoToShop;
