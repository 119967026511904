import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { ReactElement } from 'react';
import styles from '../styles/falconTable.module.scss';
import { SortOrderDirection } from '../types';

const sortCaretFunc = (order: 'asc' | 'desc' | undefined): ReactElement | null => {
  if (!order)
    return (
      <span className={cx(styles.sortIcon)}>
        <FontAwesomeIcon icon={faSort} />
      </span>
    );
  if (order === SortOrderDirection.asc)
    return (
      <span className={cx(styles.sortIcon)}>
        <FontAwesomeIcon icon={faSortUp} />
      </span>
    );
  if (order === SortOrderDirection.desc)
    return (
      <span className={cx(styles.sortIcon)}>
        <FontAwesomeIcon icon={faSortDown} />
      </span>
    );
  return null;
};

export default sortCaretFunc;
