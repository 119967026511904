import produce from 'immer';
import { Reducer } from 'redux';
import { NormalizedError } from '../../../types';
import {
  OrderFetchApiFailureAction,
  OrderFetchApiRequestAction,
  OrderFetchApiSuccessAction,
} from '../actions/fetch.actions';
import {
  OrderFulfillApiFailureAction,
  OrderFulfillApiRequestAction,
  OrderFulfillApiSuccessAction,
} from '../actions/fulfill.actions';
import {
  OrderRefundApiFailureAction,
  OrderRefundApiRequestAction,
  OrderRefundApiSuccessAction,
} from '../actions/refund.actions';
import { OrderSelectAllAction, OrderToggleItemAction } from '../actions/select.actions';
import {
  ORDER_FETCH_API_FAILURE,
  ORDER_FETCH_API_REQUEST,
  ORDER_FETCH_API_SUCCESS,
  ORDER_FULFILL_API_SUCCESS,
  ORDER_REFUND_API_SUCCESS,
  ORDER_SELECT_ALL,
  ORDER_TOGGLE_ITEM,
} from '../constants';
import { OrderItemGroup } from '../types';
import { orderOtherGroup } from '../utils';

export type OrderState = {
  error: NormalizedError | null;
  loaded: boolean;
  orderId: number | null;
  orderItemIds: number[];
  refundedItemIds: number[];
  selectedItems: { [group in OrderItemGroup]: number[] };
  shipmentIds: number[];
};

export const initialOrderState: OrderState = {
  error: null,
  loaded: false,
  orderId: null,
  orderItemIds: [],
  refundedItemIds: [],
  selectedItems: {
    fulfilled: [],
    unfulfilled: [],
  },
  shipmentIds: [],
};

export type OrderReducerAction =
  | OrderFetchApiFailureAction
  | OrderFetchApiRequestAction
  | OrderFetchApiSuccessAction
  | OrderFulfillApiFailureAction
  | OrderFulfillApiRequestAction
  | OrderFulfillApiSuccessAction
  | OrderRefundApiFailureAction
  | OrderRefundApiRequestAction
  | OrderRefundApiSuccessAction
  | OrderSelectAllAction
  | OrderToggleItemAction;

export const orderReducer: Reducer<OrderState, OrderReducerAction> = (
  state: OrderState = initialOrderState,
  action: OrderReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ORDER_FETCH_API_FAILURE: {
        draft.error = action.payload.error;
        break;
      }

      case ORDER_FETCH_API_REQUEST: {
        draft.error = null;
        break;
      }

      case ORDER_FETCH_API_SUCCESS:
      case ORDER_FULFILL_API_SUCCESS:
      case ORDER_REFUND_API_SUCCESS: {
        const { result } = action.payload.response;
        draft.loaded = true;
        draft.orderId = result.order;
        draft.orderItemIds = result.orderItems;
        draft.shipmentIds = result.shipments;
        draft.refundedItemIds = result.refundedItems;
        draft.selectedItems = {
          fulfilled: [],
          unfulfilled: [],
        };
        break;
      }

      case ORDER_SELECT_ALL: {
        const { group, isSelect, itemIds } = action.payload;
        if (isSelect) {
          draft.selectedItems[group] = itemIds;
        } else {
          draft.selectedItems[group] = [];
        }
        draft.selectedItems[orderOtherGroup(group)] = [];
        break;
      }

      case ORDER_TOGGLE_ITEM: {
        const { group, itemId } = action.payload;
        const selected = state.selectedItems[group];
        if (selected.includes(itemId)) {
          draft.selectedItems[group] = selected.filter((id) => id !== itemId);
        } else {
          draft.selectedItems[group].push(itemId);
        }
        draft.selectedItems[orderOtherGroup(group)] = [];
        break;
      }

      default:
        return draft;
    }
    return draft;
  });
