import cx from 'classnames';
import React, { FC } from 'react';
import { formatPrice } from '../../../../helpers/utils';
import { ShopEntity } from '../../../../schemas';
import styles from './OrderItemsSum.module.scss';

interface Props {
  className?: string;
  heading: string;
  shop: ShopEntity | null;
  value: number | undefined;
}

const OrderItemsSum: FC<Props> = ({ className, heading, shop, value }: Props) => {
  return (
    <div className={cx(styles.OrderItemsSum, className)}>
      <div className={styles.OrderItemsSum__heading}>{heading}:</div>
      <div className={styles.OrderItemsSum__value}>
        {value !== undefined && formatPrice(value, shop?.currency)}
      </div>
    </div>
  );
};

export default OrderItemsSum;
