import { checkIfProcessing } from '../../global/ui/selectors';
import { AppState } from '../../init/rootReducer';
import { VENDORS_SUBMIT_API } from './constants';
import { makeQueryKey } from './helpers';
import { initialTypeState } from './reducers/vendorsPageReducer';
import { initialQueryState } from './reducers/vendorsQueryReducer';
import { initialSearchState } from './reducers/vendorsSearchReducer';
import {
  VendorsPageState,
  VendorsQueryState,
  VendorsSearchState,
  VendorsSortOrder,
  VendorsType,
} from './types';

export const getVendorsPageState = (state: AppState): VendorsPageState => state.containers.vendors;

const getVendorsTypeState = (state: AppState, type: VendorsType) =>
  getVendorsPageState(state).types[type] || initialTypeState;

export const getVendorsQueryState = (
  state: AppState,
  type: VendorsType,
  sort: VendorsSortOrder,
  start: number,
  search: string
): VendorsQueryState =>
  getVendorsTypeState(state, type).queries[makeQueryKey(type, sort, start, search)] ||
  initialQueryState;

export const getVendorsSearchState = (
  state: AppState,
  type: VendorsType,
  search: string
): VendorsSearchState => getVendorsTypeState(state, type).searches[search] || initialSearchState;

export const getVendorsStateSelected = (state: AppState, type: VendorsType): number[] =>
  getVendorsTypeState(state, type).selected;

export const isSubmittingVendors = (state: AppState): boolean =>
  checkIfProcessing(state, VENDORS_SUBMIT_API);
