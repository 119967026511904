import produce from 'immer';
import { denormalize } from 'normalizr';
import { Reducer } from 'redux';
import { InventoryItemSchema } from '../../../schemas';
import {
  InventoryFetchApiFailureAction,
  InventoryFetchApiRequestAction,
  InventoryFetchApiSuccessAction,
} from '../actions/fetch.actions';
import { INVENTORY_FETCH_API_SUCCESS } from '../constants';
import { FormItems, InventorySearchState } from '../types';

export const initialSearchState: InventorySearchState = {
  allIds: null,
  formItems: {},
};

export type InventorySearchAction =
  | InventoryFetchApiFailureAction
  | InventoryFetchApiRequestAction
  | InventoryFetchApiSuccessAction;

export const inventorySearchReducer: Reducer<InventorySearchState, InventorySearchAction> = (
  state: InventorySearchState = initialSearchState,
  action: InventorySearchAction
): InventorySearchState =>
  produce(state, (draft) => {
    switch (action.type) {
      case INVENTORY_FETCH_API_SUCCESS: {
        const { entities, result } = action.payload.response;
        const { ids } = result;
        draft.allIds = ids;

        const inventoryItems = result.inventoryItems.map((itemId) =>
          denormalize(itemId, InventoryItemSchema, entities)
        );

        draft.formItems = inventoryItems.reduce((acc, item) => {
          // eslint-disable-next-line no-param-reassign
          acc[item.id] = {
            allowOverselling: item.allowOverselling,
            quantity: item.inventory || 0,
            trackInventory: item.trackInventory,
          };
          return acc;
        }, {} as FormItems);

        break;
      }

      default:
        return draft;
    }
    return draft;
  });
