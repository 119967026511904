import { faExternalLinkAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { Col, Input } from 'reactstrap';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { PAYOUTS_EXPORT_API_URL } from '../../../../global/urls';
import styles from './PayoutsRightControls.module.scss';

type Props = {
  searchInput: string;
  setModalOpen: () => void;
  setSearchInput: (newValue: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PayoutsRightControls: FC<Props> = ({ searchInput, setSearchInput, setModalOpen }: Props) => {
  const exportUrl = process.env.REACT_APP_BACKEND_API_URL + PAYOUTS_EXPORT_API_URL;
  const exportItems: DropDownItemDefinition[] = [
    <a href={exportUrl} download className={styles.menuItemLink}>
      All transactions
    </a>,
  ];
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />
        <PrimaryButton className="mr-2" icon={faPlus} onClick={setModalOpen} size="small">
          Add Transaction
        </PrimaryButton>
        <DropdownMenu
          component="header"
          headerLabel="Export"
          headerIcon={faExternalLinkAlt}
          items={exportItems}
        />
      </Col>
    </>
  );
};

export default PayoutsRightControls;
