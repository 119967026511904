import cx from 'classnames';
import React, { FC } from 'react';
import Errors from '../../../../components/Errors/Errors';
import { NormalizedError } from '../../../../types';
import ForgottenPasswordForm from '../ForgottenPasswordForm/ForgottenPasswordForm';
import styles from './EnterEmail.module.scss';

interface Props {
  error: NormalizedError | null;
  forgottenPasswordTrigger(email: string): void;
}

const EnterEmail: FC<Props> = ({ error, forgottenPasswordTrigger }: Props) => {
  return (
    <div className={cx('bg-white rounded shadow p-5', styles.enterEmailWrapper)}>
      <div className="text-center">
        <h5 className="mb-0"> Forgot your password?</h5>
        <span className={cx(styles.subTitle)}>
          Enter your email and we&apos;ll send you a reset link.
        </span>
        <ForgottenPasswordForm onSubmit={forgottenPasswordTrigger} />
        <Errors error={error} />
      </div>
    </div>
  );
};

export default EnterEmail;
