import { NormalizedError } from '../../types';
import {
  GiftCardsFetchApiFailureAction,
  GiftCardsFetchApiRequestAction,
  GiftCardsFetchApiSuccessAction,
  GiftCardsFetchNormalizedResponse,
  GiftCardsFetchTriggerAction,
} from './actionTypes';
import {
  GIFT_CARDS_FETCH_API_FAILURE,
  GIFT_CARDS_FETCH_API_REQUEST,
  GIFT_CARDS_FETCH_API_SUCCESS,
  GIFT_CARDS_FETCH_TRIGGER,
} from './constants';
import { GiftCardCodesSortOrder, GiftCardProductsSortOrder } from './types';

export const giftCardCodesFetchTrigger = (
  sortProducts: GiftCardProductsSortOrder,
  sortCodes: GiftCardCodesSortOrder,
  start: number,
  search: string
): GiftCardsFetchTriggerAction => ({
  type: GIFT_CARDS_FETCH_TRIGGER,
  payload: {
    search,
    sortCodes,
    sortProducts,
    start,
  },
});

export const giftCardCodesFetchApiRequest = (
  sortProducts: GiftCardProductsSortOrder,
  sortCodes: GiftCardCodesSortOrder,
  start: number,
  search: string
): GiftCardsFetchApiRequestAction => ({
  type: GIFT_CARDS_FETCH_API_REQUEST,
  payload: { search, sortCodes, sortProducts, start },
});

export const giftCardCodesFetchApiSuccess = (
  sortProducts: GiftCardProductsSortOrder,
  sortCodes: GiftCardCodesSortOrder,
  start: number,
  search: string,
  response: GiftCardsFetchNormalizedResponse
): GiftCardsFetchApiSuccessAction => ({
  type: GIFT_CARDS_FETCH_API_SUCCESS,
  payload: { response, search, sortCodes, sortProducts, start },
});

export const giftCardCodesFetchApiFailure = (
  sortProducts: GiftCardProductsSortOrder,
  sortCodes: GiftCardCodesSortOrder,
  start: number,
  search: string,
  error: NormalizedError
): GiftCardsFetchApiFailureAction => ({
  type: GIFT_CARDS_FETCH_API_FAILURE,
  payload: { error, search, sortCodes, sortProducts, start },
});
