import cx from 'classnames';
import React, { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';
import ProductStatusBadge from '../../../../components/ProductStatusBadge/ProductStatusBadge';
import tableStyles from '../../../../global/styles/falconTable.module.scss';
import { GIFT_CARD_URL } from '../../../../global/urls';
import { formatPrice } from '../../../../helpers/utils';
import { GiftCardProductEntity, ProductStatus, ShopEntity } from '../../../../schemas';
import GiftCardProductActionsDropdown from '../GiftCardProductActionsDropdown/GiftCardProductActionsDropdown';
import styles from './formatters.module.scss';

export interface FormatterExtraData {
  shop: ShopEntity | null;
}

export const soldCountFormatter = (soldCount: number): ReactElement => (
  <span className={tableStyles.textDark}>{soldCount}</span>
);

export const skuFormatter = (sku: string, row: GiftCardProductEntity): ReactElement => (
  <Link className={cx(styles.name)} to={generatePath(GIFT_CARD_URL, { productId: row.id })}>
    <div className={tableStyles.textDark}>{sku}</div>
  </Link>
);

export const statusFormatter = (status: ProductStatus): ReactElement => {
  return <ProductStatusBadge status={status} />;
};

export const valueFormatter = (
  value: number,
  row: GiftCardProductEntity,
  _2: number,
  formatExtraData: FormatterExtraData
): ReactElement => {
  if (value === undefined) return <></>;
  const currency = formatExtraData.shop?.currency;
  return (
    <Link className={cx(styles.name)} to={generatePath(GIFT_CARD_URL, { productId: row.id })}>
      <div className={tableStyles.textDark}>{formatPrice(value, currency)}</div>
    </Link>
  );
};

export const actionsFormatter = (unused: unknown, product: GiftCardProductEntity): ReactElement => (
  <GiftCardProductActionsDropdown product={product} />
);
