import { createSlice, Draft, PayloadAction, Slice } from '@reduxjs/toolkit';
import { EntitiesState, NormalizedError, NormalizedResponse } from '../types';

export interface LoadingState {
  loaded: boolean;
  isLoading: boolean;
  error?: NormalizedError;
}

export interface ApiSuccessActionPayload<RequestActionPayload> {
  request: RequestActionPayload;
  response: NormalizedResponse;
}

export interface ApiSuccessResultActionPayload<RequestActionPayload, Result>
  extends ApiSuccessActionPayload<RequestActionPayload> {
  response: {
    entities: EntitiesState;
    result: Result;
  };
}

export interface ApiFailureActionPayload {
  error: NormalizedError;
}

type ApiRequestSliceConfig = { name: string; noReset?: boolean };
export function makeApiRequestSlice<
  RequestPayload,
  SuccessPayload extends ApiSuccessActionPayload<RequestPayload> = ApiSuccessActionPayload<RequestPayload>,
  FailurePayload extends ApiFailureActionPayload = ApiFailureActionPayload
>({
  name,
  noReset,
}: ApiRequestSliceConfig): Slice<
  LoadingState,
  {
    trigger: (draft: Draft<LoadingState>, action: PayloadAction<RequestPayload>) => void;
    request: (draft: Draft<LoadingState>, action: PayloadAction<RequestPayload>) => void;
    success: (draft: Draft<LoadingState>, action: PayloadAction<SuccessPayload>) => void;
    failure: (draft: Draft<LoadingState>, action: PayloadAction<FailurePayload>) => void;
  }
> {
  const initialState: LoadingState = {
    loaded: false,
    isLoading: false,
    error: undefined,
  };

  const resetState = () => initialState;

  return createSlice({
    name,
    initialState,
    reducers: {
      trigger: (draft) => {
        draft.loaded = false;
        draft.isLoading = true;
        draft.error = undefined;
      },
      request: () => {},
      success: (draft) => {
        draft.loaded = true;
        draft.isLoading = false;
      },
      failure: (draft, action) => {
        draft.isLoading = false;
        draft.error = action.payload.error;
      },
    },
    extraReducers: noReset
      ? {}
      : {
          LOGIN_API_SUCCESS: resetState,
          LOGOUT_API_SUCCESS: resetState,
          RESET_SESSION: resetState,
          SELECT_SHOP_API_SUCCESS: resetState,
        },
  });
}
