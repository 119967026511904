import cx from 'classnames';
import React, { FC } from 'react';
import { Alert } from 'reactstrap';
import { NormalizedError } from '../../types';
import styles from './Errors.module.scss';

interface Props {
  error: NormalizedError | null;
}

const IGNORED_FIELDS = ['code', 'isValidationError'];

const Errors: FC<Props> = ({ error }: Props) => {
  if (!error) {
    return null;
  }
  return error.json ? (
    <div>
      {Object.keys(error.json).map((fieldName) => {
        if (IGNORED_FIELDS.includes(fieldName)) {
          return null;
        }
        const value = error.json[fieldName];
        const fieldErrors = Array.isArray(value) ? value : [value];
        return (
          <div key={fieldName}>
            {fieldErrors.map((fieldError, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Alert key={idx} variant="danger" className={cx(styles.error)}>
                {fieldError.toString()}
              </Alert>
            ))}
          </div>
        );
      })}
    </div>
  ) : (
    <div>Error occured.</div>
  );
};

export default Errors;
