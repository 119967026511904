import cx from 'classnames';
import React, { FC } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styles from './AgreementModal.module.scss';

type Props = {
  onClose(): void;
  textHtml: string;
  title: string;
};

const AgreementModal: FC<Props> = ({ onClose, textHtml, title }: Props) => (
  <Modal
    isOpen
    toggle={onClose}
    className={cx(styles.wrapper)}
    contentClassName={cx(styles.modal)}
    size="lg"
  >
    <ModalHeader toggle={onClose}>{title}</ModalHeader>
    <ModalBody className={cx(styles.body)}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: textHtml }} />
    </ModalBody>
  </Modal>
);

export default AgreementModal;
