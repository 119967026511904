import cx from 'classnames';
import { replace } from 'connected-react-router';
import React, { FC, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import CenteredWindow from '../../../../components/CenteredWindow/CenteredWindow';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import { LOGIN_URL, VENDOR_REGISTRATION_URL } from '../../../../global/urls';
import useVendorRegistrationData from '../../hooks/useVendorRegistrationData';
import { VendorRegistrationScreen } from '../../types';
import styles from './FirstScreen.module.scss';

interface Props {
  setScreen: React.Dispatch<SetStateAction<VendorRegistrationScreen>>;
}

const FirstScreen: FC<Props> = ({ setScreen }: Props) => {
  const { invitationCode, shopLogoUrl } = useVendorRegistrationData();
  const dispatch = useDispatch();

  const gotoUrl = `${LOGIN_URL}?next=${generatePath(VENDOR_REGISTRATION_URL, { invitationCode })}`;
  const loginTrigger = () => dispatch(replace(gotoUrl)); // eslint-disable-line @typescript-eslint/no-unused-vars

  return (
    <CenteredWindow>
      <div className={cx(styles.FirstScreen__modal, 'bg-white rounded')}>
        <div className={cx(styles.FirstScreen__logoWrapper, 'd-flex justify-content-center')}>
          <img src={shopLogoUrl} alt="shop logo" className={cx(styles.FirstScreen__logo)} />
        </div>
        <h3 className={cx(styles.FirstScreen__title, 'text-center')}>Vendor Registration</h3>
        <div
          className={cx(
            'd-flex flex-column justify-content-between align-items-center',
            styles.FirstScreen__buttonsWrapper
          )}
        >
          <PrimaryButton
            className={styles.FirstScreen__btn}
            onClick={() => setScreen(VendorRegistrationScreen.BUSINESS_DETAILS)}
            size="large"
          >
            Register New Account
          </PrimaryButton>
          <SecondaryButton className={styles.FirstScreen__btn} onClick={loginTrigger} size="large">
            I’m already registered
          </SecondaryButton>
        </div>
      </div>
    </CenteredWindow>
  );
};

export default FirstScreen;
