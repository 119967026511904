import { Field } from 'formik';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import { AppState } from '../../../../init/rootReducer';
import { VendorEntity } from '../../../../schemas';
import { deactivateVendorSlice } from '../../slices';

export type Props = {
  isOpen: boolean;
  toggle: () => void;
  vendorId: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DeactivateVendorModal: FC<Props> = ({ isOpen, toggle, vendorId }: Props) => {
  const currentName = useSelector(
    (state: AppState) => (state.entities.vendors as Record<number, VendorEntity>)[vendorId].name
  );

  const dispatch = useDispatch();
  const onSubmit = () => dispatch(deactivateVendorSlice.actions.trigger({ vendorId }));

  return (
    <ModalForm
      canSubmit={() => true}
      initialValues={{ name: currentName }}
      isOpen={isOpen}
      okButtonLabel="Deactivate"
      okButtonNegative
      onSubmit={onSubmit}
      title="Deactivate Vendor"
      toggle={toggle}
    >
      <FormGroup>
        <Field as={Input} disabled id="name" name="name" type="text" />
      </FormGroup>
    </ModalForm>
  );
};

export default DeactivateVendorModal;
