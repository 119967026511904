import cx from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, Label } from 'reactstrap';
import FormInputError from '../../../../components/FormInputError/FormInputError';
import { INPUT_ERROR_MESSAGE } from '../../../../global/general/constants';
import { FORGOTTEN_PASSWORD_URL } from '../../../../global/urls';
import { validateEmail } from '../../../../helpers/validators';
import styles from './LoginPageForm.module.scss';

interface FormValues {
  email: string;
  password: string;
}

type Props = {
  isProcessing: boolean;
  loginTrigger: (email: string, pass: string, nextUrl: string) => void;
  nextUrl: string;
};

const LoginPageForm: FC<Props> = ({ isProcessing, loginTrigger, nextUrl }: Props) => {
  const initialValues: FormValues = { email: '', password: '' };
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.email) {
          errors.email = INPUT_ERROR_MESSAGE.REQUIRED;
        } else if (!validateEmail(values.email)) {
          errors.email = INPUT_ERROR_MESSAGE.EMAIL;
        }
        if (!values.password) {
          errors.password = INPUT_ERROR_MESSAGE.REQUIRED;
        }
        return errors;
      }}
      onSubmit={(values) => {
        loginTrigger(values.email, values.password, nextUrl);
      }}
    >
      {() => (
        <Form>
          <FormGroup>
            <Label className={cx('w-100 mb-0')}>
              <span className={cx(styles.labelText)}>Email address</span>
              <Field
                type="email"
                name="email"
                className={cx('form-control mt-2', styles.loginFormInput)}
                autoComplete="username"
              />
            </Label>
            <ErrorMessage name="email" component={FormInputError} />
          </FormGroup>
          <FormGroup>
            <div className="d-flex align-items-center justify-content-between">
              <Label className="mb-0">
                <span className={cx(styles.labelText)}>Password</span>
              </Label>
              <Link to={FORGOTTEN_PASSWORD_URL}>
                <button className={cx(styles.forgotPassword)} type="button">
                  Forgot Password?
                </button>
              </Link>
            </div>
            <Field
              type="password"
              name="password"
              className={cx('form-control mt-2', styles.loginFormInput)}
              autoComplete="current-password"
            />

            <ErrorMessage name="password" component={FormInputError} />
          </FormGroup>
          <FormGroup className="mt-3">
            <button
              type="submit"
              disabled={isProcessing}
              className={cx('sss-primary w-100 rounded', styles.logInBtn)}
            >
              Login
            </button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default LoginPageForm;
