import { AppState } from '../../init/rootReducer';
import { initialOrderState, OrderState } from './reducers/orderReducer';
import { OrderPageState } from './reducers/pageReducer';
import { OrderItemGroup } from './types';

export const getOrderPageState = (state: AppState): OrderPageState => state.containers.order;

export const getOrderState = (state: AppState, orderName: string): OrderState =>
  getOrderPageState(state).orders[orderName] || initialOrderState;

export const getOrderSelectedItems = (
  state: AppState,
  orderName: string,
  group: OrderItemGroup
): number[] => getOrderState(state, orderName).selectedItems[group];
