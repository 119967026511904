import cx from 'classnames';
import React, { FC } from 'react';
import { ProductStatus } from '../../schemas';
import styles from './ProductStatusBadge.module.scss';

interface BadgeConf {
  styleColor: string;
  text: string;
}

const PRODUCT_STATUSES: { [key in ProductStatus]?: BadgeConf } = {
  [ProductStatus.Approved]: {
    styleColor: 'Approved',
    text: 'Uploading',
  },
  [ProductStatus.Draft]: {
    styleColor: 'Draft',
    text: 'Draft',
  },
  [ProductStatus.InReview]: {
    styleColor: 'Moderated',
    text: 'Moderation',
  },
  [ProductStatus.Pending]: {
    styleColor: 'Moderated',
    text: 'Moderation',
  },
  [ProductStatus.Published]: {
    styleColor: 'Published',
    text: 'Published',
  },
  [ProductStatus.Rejected]: {
    styleColor: 'Rejected',
    text: 'Rejected',
  },
  [ProductStatus.Unpublished]: {
    styleColor: 'Unpublished',
    text: 'Unpublished',
  },
  [ProductStatus.Unpublishing]: {
    styleColor: 'Unpublishing',
    text: 'Unpublishing',
  },
  [ProductStatus.Archived]: {
    styleColor: 'Archived',
    text: 'Archived',
  },
  [ProductStatus.ShopNET]: {
    styleColor: 'ShopNET',
    text: 'ShopNET',
  },
};

const NO_INFO_STATUS: BadgeConf = {
  styleColor: 'NoInfo',
  text: 'No Info',
};

type Props = {
  className?: string;
  status: ProductStatus | null;
};

const ProductStatusBadge: FC<Props> = ({ className, status }: Props) => {
  if (!status) {
    return <span />;
  }

  const conf = PRODUCT_STATUSES[status] || NO_INFO_STATUS;
  const styleColor = `status${conf.styleColor}`;
  const { text } = conf;

  return (
    <span
      className={cx(
        'rounded-pill text-center text-capitalize py-1 px-3 fw-bold',
        styles.statusText,
        styles[styleColor],
        className
      )}
    >
      {text}
    </span>
  );
};

export default ProductStatusBadge;
