import { NormalizedError } from '../../types';

export enum CustomersColumn {
  name = 'name',
  email = 'email',
  ordersCount = 'ordersCount',
  lastOrderAt = 'lastOrderAt',
  totalSpent = 'totalSpent',
}

export enum CustomersSortOrder {
  name = 'name',
  nameDesc = '-name',
  email = 'email',
  emailDesc = '-email',
  ordersCount = 'ordersCount',
  ordersCountDesc = '-ordersCount',
  lastOrderAt = 'lastOrdersAt',
  lastOrderAtDesc = '-lastOrdersAt',
  totalSpent = 'totalSpent',
  totalSpentDesc = '-totalSpent',
}

export type CustomersQueryState = {
  count: number;
  error: NormalizedError | null;
  loaded: boolean;
  customerIds: number[] | null;
};

export interface CustomersPageState {
  queries: { [queryKey: string]: CustomersQueryState };
}
