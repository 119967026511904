import { faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { Link } from 'react-router-dom';
import { Col, Input } from 'reactstrap';
import ButtonIcon from '../../../../components/ButtonIcon/ButtonIcon';
import { PRODUCT_NEW_URL } from '../../../../global/urls';
import { UserEntity, UserRole } from '../../../../schemas';
import { ProductsType } from '../../types';
import ProductsTypeDropdown from '../ProductsTypeDropdown/ProductsTypeDropdown';
import styles from './ProductsRightControls.module.scss';

type Props = {
  searchInput: string;
  setSearchInput: (newValue: string) => void;
  user: UserEntity | null;
  productsType: ProductsType;
  onTypeChange: (type: ProductsType) => void;
};

const ProductsRightControls: FC<Props> = ({
  searchInput,
  setSearchInput,
  user,
  productsType,
  onTypeChange,
}: Props) => {
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />
        <ProductsTypeDropdown
          productsType={productsType}
          className="mr-2"
          onChange={onTypeChange}
        />
        {user?.role === UserRole.Vendor && (
          <Link to={PRODUCT_NEW_URL}>
            <ButtonIcon
              icon={faPlus}
              className={cx(styles.headerBtn, styles.btnShadow, 'sss-primary mx-2')}
            >
              Add Product
            </ButtonIcon>
          </Link>
        )}
        {/* <ButtonIcon */}
        {/*   icon={faExternalLinkAlt} */}
        {/*   transform="shrink-3 down-2 rotate-180" */}
        {/*   color="falcon-default" */}
        {/*   size="sm" */}
        {/*   className={cx('mx-2', styles.headerBtn, styles.headerBtnGray)} */}
        {/* > */}
        {/*   Import */}
        {/* </ButtonIcon> */}
        {/* <ButtonIcon */}
        {/*   icon={faExternalLinkAlt} */}
        {/*   transform="shrink-3 down-2" */}
        {/*   color="falcon-default" */}
        {/*   size="sm" */}
        {/*   className={cx('mx-2', styles.headerBtn, styles.headerBtnGray)} */}
        {/* > */}
        {/*   Export */}
        {/* </ButtonIcon> */}
      </Col>
    </>
  );
};

export default ProductsRightControls;
