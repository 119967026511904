import {
  ApiSuccessActionPayload,
  ApiSuccessResultActionPayload,
  makeApiRequestSlice,
} from '../../helpers/apiRequestSlice';
import { EntitiesState, NormalizedResponse } from '../../types';

export type ProductUnpublishPayload = {
  productId: number;
};

export interface ProductUnpublishNormalizedResponse extends NormalizedResponse {
  readonly entities: EntitiesState;
  readonly result: {
    allCollections: number[];
    collectionIds: number[];
    isDisabledVendor: boolean;
    productId: number;
    productVersion: number;
    shippingPolicies: number[];
    shippingTypes: number[];
    showUnpublish: boolean;
    status: string;
    vendorCode: string | null;
    enquiriesAllowed: boolean;
  };
}

export interface ProductUnpublishSuccessPayload
  extends ApiSuccessActionPayload<ProductUnpublishPayload> {
  response: ProductUnpublishNormalizedResponse;
}

export const productUnpublishSlice = makeApiRequestSlice<
  ProductUnpublishPayload,
  ProductUnpublishSuccessPayload
>({
  name: 'product/unpublish',
});

export type ProductArchiveRequestPayload = {
  productId: number;
};

export type ProductArchiveResult = {
  dummy: never;
};

export type ProductArchiveSuccessPayload = ApiSuccessResultActionPayload<
  ProductArchiveRequestPayload,
  ProductArchiveResult
>;

export const productArchiveSlice = makeApiRequestSlice<
  ProductArchiveRequestPayload,
  ProductArchiveSuccessPayload
>({
  name: 'product/archive',
});

export type ProductUnarchiveRequestPayload = {
  productId: number;
};

export type ProductUnarchiveResult = {
  dummy: never;
};

export type ProductUnarchiveSuccessPayload = ApiSuccessResultActionPayload<
  ProductUnarchiveRequestPayload,
  ProductUnarchiveResult
>;

export const productUnarchiveSlice = makeApiRequestSlice<
  ProductUnarchiveRequestPayload,
  ProductUnarchiveSuccessPayload
>({
  name: 'product/unarchive',
});
