import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { AppState } from '../../../init/rootReducer';
import { NormalizedError } from '../../../types';
import { loginTrigger } from '../actions';
import getLoginPageState from '../selectors';

interface LoginPageData {
  loginTrigger(username: string, password: string, nextUrl: string): void;
  error: NormalizedError | null;
  isProcessing: boolean;
  nextUrl: string;
}

const useLoginPageData = (): LoginPageData => {
  const dispatch = useDispatch();
  const { error } = useSelector(getLoginPageState);
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const nextUrl: string = parsed.next ? parsed.next.toString() : '';
  const isProcessing = useSelector((state: AppState) => checkIfProcessing(state, 'LOGIN_API'));
  return {
    loginTrigger: (username, password, url) => dispatch(loginTrigger(username, password, url)),
    error,
    isProcessing,
    nextUrl,
  };
};

export default useLoginPageData;
