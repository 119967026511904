import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../init/rootReducer';
import {
  productAddUploadedImage,
  productRemoveUploadedImage,
  productSetUploadedImages,
} from '../actions/images.actions';
import { getProductState } from '../selectors';
import { ImageItem } from '../types';
import useProductParams from './useProductParams';

type ProductImagesData = {
  images: ImageItem[];
  addImage(newImage: ImageItem): void;
  removeImage(id: string): void;
  setImages(newImages: ImageItem[]): void;
};

const useProductImagesData = (): ProductImagesData => {
  const dispatch = useDispatch();
  const { productId } = useProductParams();
  const { images } = useSelector((state: AppState) => getProductState(state, productId));

  return {
    images,
    addImage: (newImage: ImageItem) => dispatch(productAddUploadedImage(productId, newImage)),
    removeImage: (id: string) => dispatch(productRemoveUploadedImage(productId, id)),
    setImages: (newImages: ImageItem[]) => dispatch(productSetUploadedImages(productId, newImages)),
  };
};

export default useProductImagesData;
