import React, { FC } from 'react';
import useSetupKeepState from '../../global/hooks/useSetupKeepState';
import ShippingTypesMainContent from './components/ShippingTypesMainContent/ShippingTypesMainContent';

const ShippingTypes: FC = () => {
  const key = useSetupKeepState();
  if (key === 'replaceState') {
    return null;
  }
  return <ShippingTypesMainContent key={key} />;
};

export default ShippingTypes;
