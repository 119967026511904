import produce from 'immer';
import { Reducer } from 'redux';
import {
  SetBusinessGeneralCompanyNumberAction,
  SetBusinessGeneralDisplayNameAction,
  SetBusinessGeneralLegalNameAction,
  SetBusinessGeneralVatNumberAction,
  SetBusinessGeneralVatPrefixAction,
  SetBusinessRegisteredAddressCityAction,
  SetBusinessRegisteredAddressCountryAction,
  SetBusinessRegisteredAddressLine1Action,
  SetBusinessRegisteredAddressLine2Action,
  SetBusinessRegisteredAddressPostcodeAction,
  SetBusinessRegisteredAddressStateAction,
  SetBusinessTradingAddressCityAction,
  SetBusinessTradingAddressCountryAction,
  SetBusinessTradingAddressLine1Action,
  SetBusinessTradingAddressLine2Action,
  SetBusinessTradingAddressPostcodeAction,
  SetBusinessTradingAddressStateAction,
} from './actionTypes/business';
import {
  SetContractEmailAction,
  SetContractNameAction,
  SetContractPhoneAction,
  SetContractPositionAction,
  ToggleContractAuthorizedAction,
  ToggleContractTermsAcceptedAction,
} from './actionTypes/contract';
import { VendorRegistrationContractApiSuccessAction } from './actionTypes/contractActions';
import {
  VendorRegistrationInitApiFailureAction,
  VendorRegistrationInitApiSuccessAction,
} from './actionTypes/initActions';
import {
  SetLoginEmailAction,
  SetLoginPasswordAction,
  SetLoginPasswordConfirmAction,
} from './actionTypes/login';
import { SetLoggedInAsAction } from './actionTypes/miscActions';
import {
  SET_BUSINESS_GENERAL_COMPANY_NUMBER,
  SET_BUSINESS_GENERAL_DISPLAY_NAME,
  SET_BUSINESS_GENERAL_LEGAL_NAME,
  SET_BUSINESS_GENERAL_VAT_NUMBER,
  SET_BUSINESS_GENERAL_VAT_PREFIX,
  SET_BUSINESS_REGISTERED_ADDRESS_CITY,
  SET_BUSINESS_REGISTERED_ADDRESS_COUNTRY,
  SET_BUSINESS_REGISTERED_ADDRESS_LINE_1,
  SET_BUSINESS_REGISTERED_ADDRESS_LINE_2,
  SET_BUSINESS_REGISTERED_ADDRESS_POSTCODE,
  SET_BUSINESS_REGISTERED_ADDRESS_STATE,
  SET_BUSINESS_TRADING_ADDRESS_CITY,
  SET_BUSINESS_TRADING_ADDRESS_COUNTRY,
  SET_BUSINESS_TRADING_ADDRESS_LINE_1,
  SET_BUSINESS_TRADING_ADDRESS_LINE_2,
  SET_BUSINESS_TRADING_ADDRESS_POSTCODE,
  SET_BUSINESS_TRADING_ADDRESS_STATE,
  SET_CONTRACT_EMAIL,
  SET_CONTRACT_NAME,
  SET_CONTRACT_PHONE,
  SET_CONTRACT_POSITION,
  SET_LOGIN_EMAIL,
  SET_LOGIN_PASSWORD,
  SET_LOGIN_PASSWORD_CONFIRM,
  TOGGLE_CONTRACT_AUTHORIZED,
  TOGGLE_CONTRACT_TERMS_ACCEPTED,
  VENDOR_REGISTRATION_CONTRACT_API_SUCCESS,
  VENDOR_REGISTRATION_INIT_API_SUCCESS,
  VENDOR_REGISTRATION_SET_LOGGED_IN_AS,
} from './constants';
import { VendorRegistrationState } from './types';

const initialState: VendorRegistrationState = {
  business: {
    general: {
      legalName: '',
      displayName: '',
      companyNumber: '',
      vatNumber: '',
      vatPrefix: '',
    },
    registeredAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: 'GB',
      postcode: '',
      addressState: '',
    },
    tradingAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: 'GB',
      postcode: '',
      addressState: '',
    },
  },
  contract: {
    name: '',
    position: '',
    email: '',
    phone: '',
    termsAccepted: false,
    authorized: false,
  },
  login: {
    email: '',
    password: '',
    passwordConfirm: '',
  },
  contractHash: '',
  loggedInAs: null,
  scheduleOfFeesText: '',
  shopAgreementText: '',
  shopCurrency: '',
  shopLogoUrl: '',
};

type VendorRegistrationAction =
  // business general
  | SetBusinessGeneralLegalNameAction
  | SetBusinessGeneralDisplayNameAction
  | SetBusinessGeneralCompanyNumberAction
  | SetBusinessGeneralVatNumberAction
  | SetBusinessGeneralVatPrefixAction
  // business registeredAddress
  | SetBusinessRegisteredAddressLine1Action
  | SetBusinessRegisteredAddressLine2Action
  | SetBusinessRegisteredAddressCityAction
  | SetBusinessRegisteredAddressCountryAction
  | SetBusinessRegisteredAddressPostcodeAction
  | SetBusinessRegisteredAddressStateAction
  // business tradingAddress
  | SetBusinessTradingAddressLine1Action
  | SetBusinessTradingAddressLine2Action
  | SetBusinessTradingAddressCityAction
  | SetBusinessTradingAddressCountryAction
  | SetBusinessTradingAddressPostcodeAction
  | SetBusinessTradingAddressStateAction
  // contract
  | SetContractNameAction
  | SetContractPositionAction
  | SetContractEmailAction
  | SetContractPhoneAction
  | ToggleContractTermsAcceptedAction
  | ToggleContractAuthorizedAction
  // login
  | SetLoginEmailAction
  | SetLoginPasswordAction
  | SetLoginPasswordConfirmAction
  // misc actions
  | SetLoggedInAsAction
  // fetch init
  | VendorRegistrationInitApiSuccessAction
  | VendorRegistrationInitApiFailureAction
  // fetch contract
  | VendorRegistrationContractApiSuccessAction;

// eslint-disable-next-line import/prefer-default-export
export const vendorRegistrationReducer: Reducer<
  VendorRegistrationState,
  VendorRegistrationAction
> = (state = initialState, action: VendorRegistrationAction): VendorRegistrationState =>
  // eslint-disable-next-line consistent-return
  produce(state, (draft) => {
    switch (action.type) {
      // business details - general
      case SET_BUSINESS_GENERAL_LEGAL_NAME: {
        const {
          payload: { name },
        } = action as SetBusinessGeneralLegalNameAction;
        draft.business.general.legalName = name;
        break;
      }
      case SET_BUSINESS_GENERAL_DISPLAY_NAME: {
        const {
          payload: { name },
        } = action as SetBusinessGeneralDisplayNameAction;
        draft.business.general.displayName = name;
        break;
      }
      case SET_BUSINESS_GENERAL_COMPANY_NUMBER: {
        const {
          payload: { companyNumber },
        } = action as SetBusinessGeneralCompanyNumberAction;
        draft.business.general.companyNumber = companyNumber;
        break;
      }
      case SET_BUSINESS_GENERAL_VAT_NUMBER: {
        const {
          payload: { vatNumber },
        } = action as SetBusinessGeneralVatNumberAction;
        draft.business.general.vatNumber = vatNumber;
        break;
      }
      case SET_BUSINESS_GENERAL_VAT_PREFIX: {
        const {
          payload: { vatPrefix },
        } = action as SetBusinessGeneralVatPrefixAction;
        draft.business.general.vatPrefix = vatPrefix;
        break;
      }

      // business details - registeredAddress
      case SET_BUSINESS_REGISTERED_ADDRESS_LINE_1: {
        const {
          payload: { address },
        } = action as SetBusinessRegisteredAddressLine1Action;
        draft.business.registeredAddress.addressLine1 = address;
        break;
      }
      case SET_BUSINESS_REGISTERED_ADDRESS_LINE_2: {
        const {
          payload: { address },
        } = action as SetBusinessRegisteredAddressLine2Action;
        draft.business.registeredAddress.addressLine2 = address;
        break;
      }
      case SET_BUSINESS_REGISTERED_ADDRESS_CITY: {
        const {
          payload: { city },
        } = action as SetBusinessRegisteredAddressCityAction;
        draft.business.registeredAddress.city = city;
        break;
      }
      case SET_BUSINESS_REGISTERED_ADDRESS_COUNTRY: {
        const {
          payload: { country },
        } = action as SetBusinessRegisteredAddressCountryAction;
        draft.business.registeredAddress.country = country;
        break;
      }
      case SET_BUSINESS_REGISTERED_ADDRESS_POSTCODE: {
        const {
          payload: { postcode },
        } = action as SetBusinessRegisteredAddressPostcodeAction;
        draft.business.registeredAddress.postcode = postcode;
        break;
      }
      case SET_BUSINESS_REGISTERED_ADDRESS_STATE: {
        const { payload } = action as SetBusinessRegisteredAddressStateAction;
        draft.business.registeredAddress.addressState = payload.state;
        break;
      }

      // business details - tradingAddress

      case SET_BUSINESS_TRADING_ADDRESS_LINE_1: {
        const {
          payload: { address },
        } = action as SetBusinessTradingAddressLine1Action;
        draft.business.tradingAddress.addressLine1 = address;
        break;
      }
      case SET_BUSINESS_TRADING_ADDRESS_LINE_2: {
        const {
          payload: { address },
        } = action as SetBusinessTradingAddressLine2Action;
        draft.business.tradingAddress.addressLine2 = address;
        break;
      }
      case SET_BUSINESS_TRADING_ADDRESS_CITY: {
        const {
          payload: { city },
        } = action as SetBusinessTradingAddressCityAction;
        draft.business.tradingAddress.city = city;
        break;
      }
      case SET_BUSINESS_TRADING_ADDRESS_COUNTRY: {
        const {
          payload: { country },
        } = action as SetBusinessTradingAddressCountryAction;
        draft.business.tradingAddress.country = country;
        break;
      }
      case SET_BUSINESS_TRADING_ADDRESS_POSTCODE: {
        const {
          payload: { postcode },
        } = action as SetBusinessTradingAddressPostcodeAction;
        draft.business.tradingAddress.postcode = postcode;
        break;
      }
      case SET_BUSINESS_TRADING_ADDRESS_STATE: {
        const { payload } = action as SetBusinessTradingAddressStateAction;
        draft.business.tradingAddress.addressState = payload.state;
        break;
      }

      // contract
      case SET_CONTRACT_NAME: {
        const {
          payload: { name },
        } = action as SetContractNameAction;
        draft.contract.name = name;
        break;
      }
      case SET_CONTRACT_POSITION: {
        const {
          payload: { position },
        } = action as SetContractPositionAction;
        draft.contract.position = position;
        break;
      }
      case SET_CONTRACT_EMAIL: {
        const {
          payload: { email },
        } = action as SetContractEmailAction;
        draft.contract.email = email;
        break;
      }
      case SET_CONTRACT_PHONE: {
        const {
          payload: { phone },
        } = action as SetContractPhoneAction;
        draft.contract.phone = phone;
        break;
      }
      case TOGGLE_CONTRACT_TERMS_ACCEPTED: {
        draft.contract.termsAccepted = !draft.contract.termsAccepted;
        break;
      }
      case TOGGLE_CONTRACT_AUTHORIZED: {
        draft.contract.authorized = !draft.contract.authorized;
        break;
      }

      // login
      case SET_LOGIN_EMAIL: {
        const {
          payload: { email },
        } = action as SetLoginEmailAction;
        draft.login.email = email;
        break;
      }
      case SET_LOGIN_PASSWORD: {
        const {
          payload: { password },
        } = action as SetLoginPasswordAction;
        draft.login.password = password;
        break;
      }
      case SET_LOGIN_PASSWORD_CONFIRM: {
        const {
          payload: { passwordConfirm },
        } = action as SetLoginPasswordConfirmAction;
        draft.login.passwordConfirm = passwordConfirm;
        break;
      }

      case VENDOR_REGISTRATION_SET_LOGGED_IN_AS: {
        draft.loggedInAs = action.payload.username;
        break;
      }

      // fetch init
      case VENDOR_REGISTRATION_INIT_API_SUCCESS: {
        const { result } = action.payload.response;
        draft.shopCurrency = result.shopCurrency;
        draft.shopLogoUrl = process.env.REACT_APP_DJANGO_BASE_URL + result.shopLogoUrl;
        const defaultCountry = draft.shopCurrency === 'USD' ? 'US' : 'GB';
        draft.business.registeredAddress.country = defaultCountry;
        draft.business.tradingAddress.country = defaultCountry;
        break;
      }

      // fetch init
      case VENDOR_REGISTRATION_CONTRACT_API_SUCCESS: {
        const { result } = action.payload.response;
        draft.contractHash = result.contractHash;
        draft.scheduleOfFeesText = result.scheduleOfFeesText;
        draft.shopAgreementText = result.shopAgreementText;
        break;
      }

      default:
        return state;
    }
  });
