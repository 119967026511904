import cx from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, FormGroup, Label } from 'reactstrap';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import { ProductEnquiry } from '../../../../schemas';
import styles from './ProductProperties.module.scss';

type Props = {
  ageRestricted: boolean;
  customizable: boolean;
  enquiriesAllowed: boolean;
  enquiry: ProductEnquiry;
  setAgeRestricted: () => void;
  setCustomizable: () => void;
  setEnquiry: (value: ProductEnquiry) => void;
};

const ProductProperties: FC<Props> = ({
  ageRestricted,
  customizable,
  enquiriesAllowed,
  enquiry,
  setAgeRestricted,
  setCustomizable,
  setEnquiry,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Card className="h-lg-100 mb-3">
      <FalconCardHeader title="Product Properties" className="bg-white" />
      <CardBody className="py-0">
        <FormGroup>
          <Label
            check
            className={cx(styles.item, 'd-flex align-items-center gray-900 user-select-none')}
          >
            <div className="mr-2">
              <CustomCheckbox
                checked={ageRestricted}
                name="age18plus"
                onChange={setAgeRestricted}
              />
            </div>
            <span className="mr-2">Age restricted (18+)</span>
            <CustomTooltip text="Some text to explain Age restricted checkbox" />
          </Label>
        </FormGroup>
        <FormGroup>
          <Label
            check
            className={cx(styles.item, 'd-flex align-items-center gray-900 user-select-none')}
          >
            <div className="mr-2">
              <CustomCheckbox
                checked={customizable}
                name="customizable"
                onChange={setCustomizable}
              />
            </div>
            <span className="mr-2">{t('CUSTOMIZABLE')}</span>
            <CustomTooltip text={t('CUSTOMIZABLE_TOOLTIP')} />
          </Label>
        </FormGroup>
        {enquiriesAllowed && (
          <>
            <hr className={styles.dashedLine} />
            <FormGroup className={styles.item}>
              <Label
                check
                className={cx(styles.item, 'd-flex align-items-center gray-900 user-select-none')}
              >
                <div className="mr-2">
                  <CustomRadio
                    checked={enquiry === ProductEnquiry.No}
                    onChange={() => setEnquiry(ProductEnquiry.No)}
                    name="enquiry"
                  />
                </div>
                <span className="mr-2">Purchase Only</span>
                <CustomTooltip text="Some text to explain Purchase Only radio button" />
              </Label>
            </FormGroup>
            <FormGroup className={styles.item}>
              <Label
                check
                className={cx(styles.item, 'd-flex align-items-center gray-900 user-select-none')}
              >
                <div className="mr-2">
                  <CustomRadio
                    checked={enquiry === ProductEnquiry.Both}
                    onChange={() => setEnquiry(ProductEnquiry.Both)}
                    name="enquiry"
                  />
                </div>
                <span className="mr-2">Purchase & Enquiry</span>
                <CustomTooltip text="Some text to explain Purchase & Enquiry radio button" />
              </Label>
            </FormGroup>
            <FormGroup className={styles.item}>
              <Label
                check
                className={cx(styles.item, 'd-flex align-items-center gray-900 user-select-none')}
              >
                <div className="mr-2">
                  <CustomRadio
                    checked={enquiry === ProductEnquiry.Only}
                    onChange={() => setEnquiry(ProductEnquiry.Only)}
                    name="enquiry"
                  />
                </div>
                <span className="mr-2">Enquiry Only</span>
                <CustomTooltip text="Some text to explain Enquiry Only radio button" />
              </Label>
            </FormGroup>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ProductProperties;
