import { faSpinner, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import HeaderButton from 'components/HeaderButton/HeaderButton';
import React, { ChangeEvent, FC } from 'react';
import { Col, Input } from 'reactstrap';
import { UserEntity, UserRole } from '../../../../schemas';
import styles from './InventoryRightControls.module.scss';

type Props = {
  isImportStockLoading: boolean;
  searchInput: string;
  setModalOpen: () => void;
  setSearchInput: (newValue: string) => void;
  user: UserEntity | null;
};

const InventoryRightControls: FC<Props> = ({
  isImportStockLoading,
  searchInput,
  setModalOpen,
  setSearchInput,
  user,
}: Props) => {
  return (
    <>
      <Col className="mr-2 d-flex flex-row justify-content-end align-items-center">
        <Input
          bsSize="sm"
          className={cx('mx-2', styles.search)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
          placeholder="Search..."
          type="search"
          value={searchInput}
        />
        {user?.role === UserRole.Vendor && (
          <HeaderButton
            className="mx-2 text-nowrap"
            disabled={isImportStockLoading}
            icon={isImportStockLoading ? faSpinner : faWarehouse}
            spin={isImportStockLoading}
            onClick={setModalOpen}
          >
            Import Stock Levels
          </HeaderButton>
        )}
      </Col>
    </>
  );
};

export default InventoryRightControls;
