import cx from 'classnames';
import React, { FC } from 'react';
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import { SHOPNET_SHOP_ID } from '../../../../global/constants';
import { currencyToSymbol } from '../../../../helpers/utils';
import { ShopEntity } from '../../../../schemas';
import useProductStoresData from '../../hooks/useProductStoresData';
import styles from './ProductShipping.module.scss';

type ShippingInformationControlProps = {
  currencySymbol: string;
  disabled?: boolean;
  setAdditionalItem: (value: string) => void;
  setBaseFee: (value: string) => void;
  shippingData: {
    additionalItem: number | string;
    available: boolean;
    baseShippingFee: number | string;
    freeShipping: boolean;
  };
  shippingType: string;
  toggleAvailable: () => void;
  toggleFree: () => void;
};

const ShippingInformationControl: FC<ShippingInformationControlProps> = ({
  currencySymbol,
  disabled,
  setAdditionalItem,
  setBaseFee,
  shippingData,
  shippingType,
  toggleAvailable,
  toggleFree,
}: ShippingInformationControlProps) => {
  const enabled = shippingData.available && !disabled;
  return (
    <>
      <Row>
        <Col lg={6}>
          <CustomInput
            disabled={disabled}
            type="switch"
            label={
              <span>
                {shippingType} <CustomTooltip text={`Some text to explain ${shippingType}`} />
              </span>
            }
            id={`product-store-${shippingType}-shipping-disabled`}
            onChange={toggleAvailable}
            checked={shippingData.available}
            className="custom-sss-switch sss-gray-1100"
          />
        </Col>
        <Col lg={6}>
          <Label
            className={cx(
              styles.checkbox,
              'd-flex align-items-center gray-700 user-select-none',
              !enabled && styles.disabledLabel
            )}
            disabled={!enabled}
          >
            <CustomCheckbox
              disabled={!enabled}
              checked={shippingData.freeShipping}
              onChange={toggleFree}
            />
            <div className={cx('ml-2 sss-gray-1100', !enabled && styles.disabledLabel)}>Free</div>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Label disabled={!enabled} className={cx(!enabled && styles.disabledLabel)}>
            <small>Base Shipping Fee</small>
          </Label>
        </Col>
        <Col lg={6}>
          <Label disabled={!enabled} className={cx(!enabled && styles.disabledLabel)}>
            <small>Additional Item</small>
          </Label>
        </Col>
      </Row>
      <Row className={cx('d-flex align-items-center')}>
        <Col lg={6}>
          <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>{currencySymbol}</InputGroupText>
            </InputGroupAddon>
            <Input
              type="number"
              placeholder=""
              value={!shippingData.freeShipping ? shippingData.baseShippingFee : ''}
              min={0}
              disabled={!enabled || shippingData.freeShipping}
              onChange={(event) => setBaseFee(event.target.value)}
            />
          </InputGroup>
        </Col>
        <Col lg={6}>
          <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>{currencySymbol}</InputGroupText>
            </InputGroupAddon>
            <Input
              type="number"
              placeholder=""
              value={!shippingData.freeShipping ? shippingData.additionalItem : ''}
              min={0}
              disabled={!enabled || shippingData.freeShipping}
              onChange={(event) => setAdditionalItem(event.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

interface ProductShippingProps {
  disabled?: boolean;
  shop: ShopEntity | null;
}

const ProductShipping: FC<ProductShippingProps> = ({ disabled, shop }: ProductShippingProps) => {
  const {
    shipping,
    isPhysicalProduct,
    storeActions,
    togglePhysicalProduct,
  } = useProductStoresData();

  const {
    toggleShippingAvailable,
    toggleShippingFree,
    setShippingBaseFee,
    setShippingAdditionalItem,
  } = storeActions;

  const currencySymbol = currencyToSymbol(shop?.currency);
  const icDisabled = disabled || shop?.id === SHOPNET_SHOP_ID;

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Shipping">
        <CustomInput
          disabled={disabled}
          type="switch"
          label="Physical Product"
          id="product-stores-global-shipping"
          onClick={togglePhysicalProduct}
          onChange={() => {}}
          checked={isPhysicalProduct}
          className={cx('custom-sss-switch', styles.requiresShipping)}
        />
      </FalconCardHeader>
      <CardBody className="py-0 mb-3">
        <div className="small gray-600 mt-3">
          Configure shipping policies and fees for this product below.
        </div>
        <div className="small gray-600">Turn shipping off for digital or virtual products.</div>
      </CardBody>
      {isPhysicalProduct && (
        <>
          <FalconCardHeader title="Shipping Options" />
          <CardBody className="pt-0 pb-3">
            {shipping.map((policy, idx) => {
              return (
                <div key={policy.name}>
                  {idx > 0 && <hr className={styles.dashedLine} />}
                  <ShippingInformationControl
                    currencySymbol={currencySymbol}
                    disabled={icDisabled}
                    setAdditionalItem={(value) => setShippingAdditionalItem(policy.name, value)}
                    setBaseFee={(value) => setShippingBaseFee(policy.name, value)}
                    shippingData={policy}
                    shippingType={policy.name}
                    toggleAvailable={() => toggleShippingAvailable(policy.name)}
                    toggleFree={() => toggleShippingFree(policy.name)}
                  />
                </div>
              );
            })}
          </CardBody>
        </>
      )}
    </Card>
  );
};

export default ProductShipping;
