import { Action } from 'redux';
import { PRODUCT_ADD_TAG, PRODUCT_REMOVE_TAG } from '../constants';

// action types

export interface ProductAddProductTagAction extends Action {
  type: typeof PRODUCT_ADD_TAG;
  payload: {
    productId: number;
    tag: string;
  };
}

export interface ProductRemoveProductTagAction extends Action {
  type: typeof PRODUCT_REMOVE_TAG;
  payload: {
    productId: number;
    tag: string;
  };
}

// action creators

export const productAddProductTag = (
  productId: number,
  tag: string
): ProductAddProductTagAction => ({
  type: PRODUCT_ADD_TAG,
  payload: { productId, tag },
});

export const productRemoveProductTag = (
  productId: number,
  tag: string
): ProductRemoveProductTagAction => ({
  type: PRODUCT_REMOVE_TAG,
  payload: { productId, tag },
});
