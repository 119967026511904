import arrayMove from 'array-move';
import cx from 'classnames';
import { CollectionsEditFormValues, EnhancedCollectionProduct } from 'containers/Collection/types';
import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { SortEnd } from 'react-sortable-hoc';
import { Input } from 'reactstrap';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import { CollectionProductsOrder } from '../../../../schemas';
import CollectionSortableProducts from '../CollectionSortableProducts/CollectionSortableProducts';
import styles from './CollectionProducts.module.scss';

type Props = {
  fprops: FormikProps<CollectionsEditFormValues>;
  numShown: number;
  numTotal: number;
  onBrowse: () => void;
  onShowMore: () => void;
};

const handleSortEnd = (
  { newIndex, oldIndex }: SortEnd,
  fprops: FormikProps<CollectionsEditFormValues>
) => {
  const { products } = fprops.values;
  const newProductsTitles = arrayMove(products, oldIndex, newIndex);
  fprops.setFieldValue('products', newProductsTitles);
};

const handleMoveToTop = (productId: number, fprops: FormikProps<CollectionsEditFormValues>) => {
  const { products } = fprops.values;
  const index = products.findIndex((product) => product.productId === productId);
  const newProductsTitles = arrayMove(products, index, 0);
  fprops.setFieldValue('products', newProductsTitles);
};

const CollectionProducts: FC<Props> = ({
  fprops,
  numShown,
  numTotal,
  onBrowse,
  onShowMore,
}: Props) => {
  const [searchInput, setSearchInput] = useState('');
  const [realSearchInput, setRealSearchInput] = useState('');

  const { products } = fprops.values;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debounce((newSearch: string, oldSearch: string) => {
      setRealSearchInput(newSearch);
    }, 300),
    []
  );

  useEffect(() => {
    debounceSearch(searchInput, realSearchInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, realSearchInput, searchInput]);

  const trimmedSearch = realSearchInput.trim().toLowerCase();
  const enhancedProducts: EnhancedCollectionProduct[] = products.map((product, idx) => ({
    ...product,
    isFirst: idx === 0,
  }));
  const filteredProducts = realSearchInput
    ? enhancedProducts.filter((product) => product.name.toLowerCase().includes(trimmedSearch))
    : enhancedProducts;

  const sortable = fprops.values.productsOrder === CollectionProductsOrder.Manual;
  const toppable = sortable && !!realSearchInput;

  const showMoreButton = numShown < numTotal;

  return (
    <>
      <div className="small">
        Products <span className="sss-gray-1100">{!sortable && '(will be sorted live)'}</span>
      </div>
      <div className={styles.CollectionProducts__products}>
        {products && products.length > 0 ? (
          <>
            <div className="d-flex mb-1 mt-3 justify-content-between">
              <Input
                bsSize="sm"
                className={cx('d-inline-block mx-2', styles.CollectionProducts__search)}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSearchInput(event.target.value)
                }
                placeholder="Search..."
                type="search"
                value={searchInput}
              />

              <SecondaryButton size="small" disabled={false} onClick={onBrowse}>
                Edit Products in Collection
              </SecondaryButton>
            </div>

            <CollectionSortableProducts
              numShown={numShown}
              onMoveToTop={(productId: number) => handleMoveToTop(productId, fprops)}
              onSortEnd={(args) => handleSortEnd(args, fprops)}
              productsTitles={filteredProducts}
              sortable={sortable && !toppable}
              toppable={toppable}
            />
          </>
        ) : (
          <div>No products in the collection yet.</div>
        )}
        {showMoreButton && (
          <SecondaryButton className="d-block mt-1 mx-auto" onClick={onShowMore} size="small">
            Show more
          </SecondaryButton>
        )}
      </div>
    </>
  );
};

export default CollectionProducts;
