import { Action } from 'redux';
import { PRODUCT_SET_PRODUCT_DESCRIPTION, PRODUCT_SET_PRODUCT_TITLE } from '../constants';

// action types

export interface ProductSetProductTitleAction extends Action {
  type: typeof PRODUCT_SET_PRODUCT_TITLE;
  payload: {
    productId: number;
    title: string;
  };
}

export interface ProductSetProductDescriptionAction extends Action {
  type: typeof PRODUCT_SET_PRODUCT_DESCRIPTION;
  payload: {
    productId: number;
    description: string;
  };
}

// action creators

export const productSetProductTitle = (
  productId: number,
  title: string
): ProductSetProductTitleAction => ({
  type: PRODUCT_SET_PRODUCT_TITLE,
  payload: { productId, title },
});

export const productSetProductDescription = (
  productId: number,
  description: string
): ProductSetProductDescriptionAction => ({
  type: PRODUCT_SET_PRODUCT_DESCRIPTION,
  payload: { productId, description },
});
