import { SagaIterator } from '@redux-saga/core';
import { push } from 'connected-react-router';
import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { resetSessionIfNeededSaga } from '../../global/session/sagas';
import { DASHBOARD_URL, SELECT_SHOP_API_URL } from '../../global/urls';
import { apiPut, normalizeError } from '../../helpers/api';
import { handleFailureSaga } from '../../helpers/sagas';
import { SessionSchema } from '../../schemas';
import {
  selectShopApiFailure,
  selectShopApiRequest,
  selectShopApiSuccess,
  SelectShopNormalizedResponse,
  SelectShopTriggerAction,
} from './actions/selectActions';
import { SELECT_SHOP_TRIGGER } from './constants';

function* selectSaga({ payload }: SelectShopTriggerAction) {
  // redirect to dashboard to prevent viewing content of the other shop
  yield put(push(DASHBOARD_URL));

  const { shopId } = payload;
  yield put(selectShopApiRequest(shopId));
  try {
    yield* initIfNeededSaga();
    const response = yield call(apiPut, SELECT_SHOP_API_URL, {
      shopId,
    });
    const normalizedResponse: SelectShopNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(selectShopApiSuccess(shopId, normalizedResponse));
  } catch (error) {
    yield put(selectShopApiFailure(shopId, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(SELECT_SHOP_TRIGGER, selectSaga)]);
}
