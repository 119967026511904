import { Location } from 'history';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getRouterState } from '../../../global/selectors';
import { LocationState } from '../../../global/types';
import { defaultSortOrder } from '../constants';
import { ShippingPoliciesSortOrder } from '../types';

type ShippingPoliciesQueryParams = {
  location: Location<LocationState>;
  locationState: LocationState;
  search: string;
  sortOrder: ShippingPoliciesSortOrder;
  start: number;
};

const parseShippingPoliciesSortOrder = (sort: string): ShippingPoliciesSortOrder => {
  const sortOrders = Object.values(ShippingPoliciesSortOrder);
  return sortOrders.includes(sort as ShippingPoliciesSortOrder)
    ? (sort as ShippingPoliciesSortOrder)
    : defaultSortOrder;
};

const useShippingPoliciesQueryParams = (): ShippingPoliciesQueryParams => {
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const start: number = parsed.start ? parseInt(parsed.start.toString(), 10) : 0;
  const search: string = parsed.search ? parsed.search.toString() : '';
  const sortOrder = parseShippingPoliciesSortOrder(parsed.sort ? parsed.sort.toString() : '');
  const { state } = location;

  return {
    location,
    locationState: state,
    search,
    sortOrder,
    start,
  };
};

export default useShippingPoliciesQueryParams;
