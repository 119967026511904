import cx from 'classnames';
import React, { FC } from 'react';
import styles from './BrandBadge.module.scss';

type Props = {
  className?: string;
};

const BrandBadge: FC<Props> = ({ className }: Props) => {
  return (
    <span
      className={cx(
        'rounded-pill text-center text-capitalize py-1 px-3',
        styles.BrandBadge,
        className
      )}
    >
      Brand
    </span>
  );
};

export default BrandBadge;
