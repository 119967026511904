import * as Sentry from '@sentry/react';
import { StoreEnhancer } from 'redux';

const enhancers: StoreEnhancer[] = [];

const sentryDsn = process.env.REACT_APP_BACKEND_API_URL;
if (sentryDsn) {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();
  enhancers.push(sentryReduxEnhancer);
}

export default enhancers;
