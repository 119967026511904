import { SagaIterator } from '@redux-saga/core';
import { normalize } from 'normalizr';
import { stringifyUrl } from 'query-string';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { initIfNeededSaga } from '../../global/init/sagas';
import { reloadPageSaga, resetSessionIfNeededSaga } from '../../global/session/sagas';
import {
  PRODUCTS_ADD_COLLECTIONS_API_URL,
  PRODUCTS_ADD_TAGS_API_URL,
  PRODUCTS_API_URL,
  PRODUCTS_ARCHIVE_API_URL,
  PRODUCTS_CLONE_API_URL,
  PRODUCTS_COLLECTIONS_API_URL,
  PRODUCTS_REMOVE_COLLECTIONS_API_URL,
  PRODUCTS_REMOVE_TAGS_API_URL,
  PRODUCTS_SET_COLLECTIONS_API_URL,
  PRODUCTS_SET_TAGS_API_URL,
  PRODUCTS_TAGS_API_URL,
  PRODUCTS_UNARCHIVE_API_URL,
  PRODUCTS_UNPUBLISH_API_URL,
} from '../../global/urls';
import toast from '../../global/utils/toast';
import { apiGet, normalizeError } from '../../helpers/api';
import makeApiRequestSaga from '../../helpers/apiRequestSaga';
import { handleFailureSaga } from '../../helpers/sagas';
import { BriefProductSchema, CollectionSchema, SessionSchema } from '../../schemas';
import {
  productsFetchApiFailure,
  productsFetchApiRequest,
  productsFetchApiSuccess,
  ProductsFetchNormalizedResponse,
  ProductsFetchTriggerAction,
} from './actions/fetch.actions';
import { PRODUCTS_FETCH_TRIGGER } from './constants';
import {
  addCollectionsSlice,
  addTagsSlice,
  archiveProductsSlice,
  cloneProductsSlice,
  getProductsCollectionsSlice,
  getProductsTagsSlice,
  removeCollectionsSlice,
  removeTagsSlice,
  setCollectionsSlice,
  setTagsSlice,
  unarchiveProductsSlice,
  unpublishProductsSlice,
} from './slices';

function* fetchSaga({ payload }: ProductsFetchTriggerAction) {
  const { search, sort, start, type } = payload;
  yield put(productsFetchApiRequest(sort, start, search, type));
  try {
    yield* initIfNeededSaga();
    const url = stringifyUrl({
      url: PRODUCTS_API_URL,
      query: {
        search: payload.search,
        sort: payload.sort,
        start: payload.start,
        type: payload.type,
      },
    });
    const response = yield call(apiGet, url);
    const normalizedResponse: ProductsFetchNormalizedResponse = normalize(response, {
      briefProductVersions: [BriefProductSchema],
      session: SessionSchema,
    });
    yield* resetSessionIfNeededSaga(normalizedResponse);
    yield put(productsFetchApiSuccess(sort, start, search, type, normalizedResponse));
  } catch (error) {
    yield put(productsFetchApiFailure(sort, start, search, type, normalizeError(error)));
    yield* handleFailureSaga(error);
  }
}

const setTagsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_SET_TAGS_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: setTagsSlice.actions,
});

const addTagsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_ADD_TAGS_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: addTagsSlice.actions,
});

const removeTagsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_REMOVE_TAGS_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: removeTagsSlice.actions,
});

const setCollectionsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_SET_COLLECTIONS_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: setCollectionsSlice.actions,
});

const addCollectionsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_ADD_COLLECTIONS_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: addCollectionsSlice.actions,
});

const removeCollectionsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_REMOVE_COLLECTIONS_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: removeCollectionsSlice.actions,
});

const unpublishProductsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_UNPUBLISH_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    briefProductVersions: [BriefProductSchema],
    session: SessionSchema,
  },
  actions: unpublishProductsSlice.actions,
});

const archiveProductsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_ARCHIVE_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    briefProductVersions: [BriefProductSchema],
    session: SessionSchema,
  },
  actions: archiveProductsSlice.actions,
});

const unarchiveProductsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_UNARCHIVE_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    briefProductVersions: [BriefProductSchema],
    session: SessionSchema,
  },
  actions: unarchiveProductsSlice.actions,
});

const cloneSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_CLONE_API_URL,
  method: 'POST',
  queryPayload: (payload) => payload,
  responseSchema: {
    session: SessionSchema,
  },
  actions: cloneProductsSlice.actions,
});

type BulkActionSuccessAction = ReturnType<
  | typeof addTagsSlice.actions.success
  | typeof removeTagsSlice.actions.success
  | typeof addCollectionsSlice.actions.success
  | typeof removeCollectionsSlice.actions.success
>;

function* successBulkCloneInfoSaga(action: ReturnType<typeof cloneProductsSlice.actions.success>) {
  const numProducts = action.payload.request.productIds.length;
  yield call(toast.success, `${numProducts} products cloned.`);
}

function* successBulkActionInfoSaga(action: BulkActionSuccessAction) {
  const numProducts = action.payload.request.productIds.length;
  yield call(toast.success, `${numProducts} products updated.`);
}

function* successBulkUnpublishInfoSaga(
  action: ReturnType<typeof unpublishProductsSlice.actions.success>
) {
  const numProducts = action.payload.response.result.unpublishedProducts;
  yield call(toast.success, `${numProducts} products unpublished.`);
}

function* successBulkArchiveInfoSaga(
  action: ReturnType<typeof archiveProductsSlice.actions.success>
) {
  const numProducts = action.payload.response.result.archivedProducts;
  yield call(toast.success, `${numProducts} products archived.`);
  // Need to refresh page as archived products are hidden from the list
  yield reloadPageSaga();
}

function* successBulkUnarchiveInfoSaga(
  action: ReturnType<typeof unarchiveProductsSlice.actions.success>
) {
  const numProducts = action.payload.response.result.unarchivedProducts;
  yield call(toast.success, `${numProducts} products unarchived.`);
  // Need to refresh page as archived products are hidden from the list
  yield reloadPageSaga();
}

function* successSetTagsInfoSaga() {
  yield call(toast.success, 'Product tags have been updated');
}

function* successSetCollectionsInfoSaga() {
  yield call(toast.success, 'Product collections have been updated');
}

const getProductsTagsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_TAGS_API_URL,
  urlBuilder: (payload) =>
    stringifyUrl(
      { url: PRODUCTS_TAGS_API_URL, query: { product_ids: payload.productIds } },
      { arrayFormat: 'none' }
    ),
  responseSchema: { session: SessionSchema },
  method: 'GET',
  actions: getProductsTagsSlice.actions,
});

const getProductsCollectionsSaga = makeApiRequestSaga({
  baseUrl: PRODUCTS_COLLECTIONS_API_URL,
  urlBuilder: (payload) =>
    stringifyUrl(
      { url: PRODUCTS_COLLECTIONS_API_URL, query: { product_ids: payload.productIds } },
      { arrayFormat: 'none' }
    ),
  responseSchema: {
    collections: [CollectionSchema],
    session: SessionSchema,
  },
  method: 'GET',
  actions: getProductsCollectionsSlice.actions,
});

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(PRODUCTS_FETCH_TRIGGER, fetchSaga),
    takeLatest(setTagsSlice.actions.trigger.type, setTagsSaga),
    takeLatest(addTagsSlice.actions.trigger.type, addTagsSaga),
    takeLatest(removeTagsSlice.actions.trigger.type, removeTagsSaga),
    takeLatest(setCollectionsSlice.actions.trigger.type, setCollectionsSaga),
    takeLatest(addCollectionsSlice.actions.trigger.type, addCollectionsSaga),
    takeLatest(removeCollectionsSlice.actions.trigger.type, removeCollectionsSaga),
    takeLatest(unpublishProductsSlice.actions.trigger.type, unpublishProductsSaga),
    takeLatest(archiveProductsSlice.actions.trigger.type, archiveProductsSaga),
    takeLatest(unarchiveProductsSlice.actions.trigger.type, unarchiveProductsSaga),
    takeLatest(cloneProductsSlice.actions.trigger.type, cloneSaga),
    takeLatest(cloneProductsSlice.actions.success.type, successBulkCloneInfoSaga),
    takeLatest(
      [
        addTagsSlice.actions.success.type,
        removeTagsSlice.actions.success.type,
        addCollectionsSlice.actions.success.type,
        removeCollectionsSlice.actions.success.type,
      ],
      successBulkActionInfoSaga
    ),
    takeLatest(setTagsSlice.actions.success.type, successSetTagsInfoSaga),
    takeLatest(setCollectionsSlice.actions.success.type, successSetCollectionsInfoSaga),
    takeLatest(unpublishProductsSlice.actions.success.type, successBulkUnpublishInfoSaga),
    takeLatest(archiveProductsSlice.actions.success.type, successBulkArchiveInfoSaga),
    takeLatest(unarchiveProductsSlice.actions.success.type, successBulkUnarchiveInfoSaga),
    takeLatest(getProductsTagsSlice.actions.trigger.type, getProductsTagsSaga),
    takeLatest(getProductsCollectionsSlice.actions.trigger.type, getProductsCollectionsSaga),
  ]);
}
