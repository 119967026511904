import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FalconTable from '../../../../components/FalconTable/FalconTable';
import selectRow from '../../../../components/FalconTable/selectRow';
import { OrderItemEntity, ShopEntity } from '../../../../schemas';
import { orderSelectAll, orderToggleItem } from '../../actions/select.actions';
import { OrderItemGroup } from '../../types';
import columns from './columns';

interface Props {
  group: OrderItemGroup;
  items: OrderItemEntity[];
  orderName: string;
  selectedIds: number[];
  shop: ShopEntity | null;
}

const OrderItemsList: FC<Props> = ({ group, items, orderName, selectedIds, shop }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSelectAll = (isSelect: boolean, rows: OrderItemEntity[]) => {
    const itemIds = rows.map((row) => row.id);
    dispatch(orderSelectAll(orderName, group, isSelect, itemIds));
  };

  const onSelect = (row: OrderItemEntity) => {
    dispatch(orderToggleItem(orderName, group, row.id));
  };

  return (
    <FalconTable
      columns={columns({ group, shop, t })}
      data={items}
      keyField="id"
      selectRow={selectRow(onSelect, onSelectAll, selectedIds)}
    />
  );
};

export default OrderItemsList;
