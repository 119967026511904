import cx from 'classnames';
import React, { FC } from 'react';
import { BeatLoader } from 'react-spinners';
import styles from './SimpleLoadingIndicator.module.scss';

type Props = {
  className?: string;
};

const SimpleLoadingIndicator: FC<Props> = ({ className }: Props) => (
  <div
    className={cx(
      'align-items-center d-flex justify-content-center',
      styles.SimpleLoadingIndicator,
      className
    )}
  >
    <BeatLoader color="#ff8848" />
  </div>
);

export default SimpleLoadingIndicator;
