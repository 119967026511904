import { denormalize } from 'normalizr';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import { getEntitiesState } from '../../../../global/entities/selectors';
import { AppState } from '../../../../init/rootReducer';
import { CollectionEntity, CollectionSchema } from '../../../../schemas';
import CollectionsList from '../../../Product/components/CollectionsList/CollectionsList';
import { ProductCollection } from '../../../Product/types';
import { getProductsCollectionsSlice } from '../../slices';
import styles from './ApproveProducts.module.scss';

type Props = {
  isOpen: boolean;
  submit: ({
    whProductId,
    collectionIds,
  }: {
    whProductId: number;
    collectionIds: number[];
  }) => void;
  toggle: () => void;
  whProductId: number;
};

type CustomProps = Props & {
  title: string;
  message: JSX.Element;
  showAllCollections?: boolean;
};

function notEmpty<TValue>(value: TValue | undefined): value is TValue {
  return value !== undefined;
}

const useCollections = (initialCollections: ProductCollection[]) => {
  const [collections, setCollections] = useState([] as ProductCollection[]);

  useEffect(() => {
    setCollections(initialCollections);
  }, [setCollections, initialCollections]);

  const toggleCollectionItem = (id: number) =>
    setCollections(
      collections.map((collection) =>
        collection.id === id ? { ...collection, checked: !collection.checked } : collection
      )
    );
  return { collections, toggleCollectionItem };
};

const BulkApproveProductsModal: FC<CustomProps> = ({
  title: modalTitle,
  message,
  showAllCollections,
  isOpen,
  toggle,
  submit,
  whProductId,
}: CustomProps) => {
  // const productIds = useMemo(() => Array.from(selectedProducts), [selectedProducts]);
  const productIds = useMemo(() => [whProductId], [whProductId]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isOpen) return;
    const collectionsRequestProductIds = showAllCollections ? [] : productIds;
    dispatch(
      getProductsCollectionsSlice.actions.trigger({ productIds: collectionsRequestProductIds })
    );
  }, [dispatch, isOpen, showAllCollections, productIds]);

  const collectionIds = useSelector(
    (state: AppState) => state.containers.productsCollections.collectionIds
  );
  const entities = useSelector(getEntitiesState);

  const productsCollections = useMemo(
    () =>
      collectionIds.map(
        (collectionId) =>
          denormalize(collectionId, CollectionSchema, entities) as CollectionEntity | undefined
      ),
    [collectionIds, entities]
  );

  const initialCollections: ProductCollection[] = useMemo(
    () =>
      productsCollections.filter(notEmpty).map(({ id, title }) => ({
        id,
        name: title,
        checked: false,
      })),
    [productsCollections]
  );

  const { collections, toggleCollectionItem } = useCollections(initialCollections);

  const itemsSelected = collections.filter((c) => c.checked).map((c) => c.id);

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={() =>
        submit({
          collectionIds: itemsSelected,
          whProductId,
        })
      }
      // canSubmit={() => !!itemsSelected.length}
      canSubmit={() => true}
      size="lg"
      title={modalTitle}
      okButtonLabel="Import"
      // okButtonLabel="Import and Publish"
      // secondButtonLabel="Import only"
      initialValues={{}}
    >
      <div>{message}</div>
      <br />
      <div className="small">Collections</div>
      <div className={styles.ApproveProducts__collections}>
        <CollectionsList collections={collections} toggleCollectionItem={toggleCollectionItem} />
      </div>
    </ModalForm>
  );
};

const ApproveProductsModal: FC<Props> = (props: Props) => {
  return (
    <BulkApproveProductsModal
      title="Add to Store"
      message={
        <>
          Add <b>1</b> product to these Collections:
        </>
      }
      showAllCollections
      {...props}
    />
  );
};

export default ApproveProductsModal;
