import { useSelector } from 'react-redux';
import { matchPath, useParams } from 'react-router-dom';
import { getRouterState } from '../../../global/selectors';
import { MODERATION_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { ModerationRequestEntity } from '../../../schemas';
import { Params } from '../types';

interface ProductParams {
  productId: number;
  isModeration: boolean;
  moderationId?: number;
  moderationRequest?: ModerationRequestEntity;
}

interface ModerationParams {
  moderationId: string;
}

const useProductParams = (): ProductParams => {
  const params = useParams<Params>();
  const productIdOrNew = params.productId;
  const locationProductId = productIdOrNew === 'new' ? 0 : parseInt(productIdOrNew, 10);

  //
  // For now, we're assuming here that it can be either moderation page or product page.
  // This, with React's requirement for hooks to be unconditionally called in the same order, makes this place hackish.
  // Current intention is to reuse Product component for both product editing and moderation as much as possible,
  // without splitting it.
  // More correct (React-style and SOLID) way is to split it to common and case/specific parts and also
  // separate fetching data flows. But it's not yet clear how to do that properly at the moment,
  // and also seems like more work to do so than these hacks.
  //
  const { location } = useSelector(getRouterState);
  const moderationMatch = matchPath<ModerationParams>(location.pathname, {
    path: MODERATION_URL,
    exact: true,
  });
  const isModeration = !!moderationMatch;
  const moderationId = parseInt(moderationMatch?.params?.moderationId || '', 10);
  const moderationList = useSelector((state: AppState) => state.entities.moderationList);
  const moderationRequest = moderationList ? moderationList[moderationId] : undefined;
  const moderationProductId = moderationRequest ? moderationRequest.productId : 0;
  const productId = isModeration ? moderationProductId : locationProductId;

  return {
    productId,
    isModeration,
    moderationId,
    moderationRequest,
  };
};

export default useProductParams;
