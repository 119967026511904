export interface Params {
  orderName: string;
}

export enum OrderItemGroup {
  Fulfilled = 'fulfilled',
  Unfulfilled = 'unfulfilled',
}

interface FormItem {
  quantity: number;
}

export type FormItems = { [itemId: number]: FormItem };

export interface FormValues {
  fulfilledItems: FormItems;
  trackingCode: string;
  unfulfilledItems: FormItems;
}

export interface ItemAndQuantity {
  itemId: number;
  quantity: number;
}

export type GroupItemsAndQuantities = {
  [group in OrderItemGroup]: ItemAndQuantity[];
};
