import cx from 'classnames';
import React, { FC } from 'react';
import CenteredWindow from '../../components/CenteredWindow/CenteredWindow';
import Errors from '../../components/Errors/Errors';
import MainLogo from '../../components/MainLogo/MainLogo';
import { isSuperStoreSite } from '../../helpers/utils';
import styles from './Login.module.scss';
import LoginPageForm from './components/LoginPageForm/LoginPageForm';
import useLoginPageData from './hooks/useLoginPageData';

const Login: FC = () => {
  const { error, isProcessing, loginTrigger, nextUrl } = useLoginPageData();
  return (
    <CenteredWindow>
      <div>
        <div className="mb-4">
          <MainLogo />
        </div>
        <div className={cx('bg-white rounded shadow p-5', styles.loginFormWrapper)}>
          <h5 className={cx(styles.loginFormTitle, 'text-center')}>
            {isSuperStoreSite() ? 'SuperStore Systems Portal' : 'ShopCentre Portal'}
          </h5>
          <LoginPageForm
            isProcessing={isProcessing}
            loginTrigger={loginTrigger}
            nextUrl={nextUrl}
          />
          <Errors error={error} />
        </div>
      </div>
    </CenteredWindow>
  );
};

export default Login;
