import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionActiveShop } from '../../../global/session/selectors';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { COLLECTIONS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { CollectionEntity, CollectionSchema } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { collectionsFetchTrigger } from '../actions';
import { COLLECTIONS_FETCH_API } from '../constants';
import { getCollectionsQueryState } from '../selectors';
import submitCollectionsEditSlice from '../slices';
import { CollectionsSortOrder } from '../types';
import useCollectionsQueryParams from './useCollectionsQueryParams';

type CollectionsPageData = {
  collections: CollectionEntity[] | null;
  collectionsEditTrigger(
    collectionId: number,
    title: string,
    handle: string,
    managedBy: string
  ): void;
  collectionsFetchTrigger(): void;
  correctLocation: boolean;
  allIds: number[];
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  isLoading: boolean;
  loaded: boolean;
  search: string;
  shopUrl: string;
  sortOrder: CollectionsSortOrder;
  start: number;
};

const useCollectionsPageData = (): CollectionsPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { search, sortOrder, start } = useCollectionsQueryParams();
  const correctLocation = location.pathname === COLLECTIONS_URL;
  const entities = useSelector(getEntitiesState);
  const activeShop = useSelector(getSessionActiveShop);
  const { count, error, loaded, collectionIds, allIds } = useSelector((state: AppState) =>
    getCollectionsQueryState(state, sortOrder, start, search)
  );

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, COLLECTIONS_FETCH_API)
  );

  const collections = useMemo(
    () =>
      collectionIds === null
        ? collectionIds
        : collectionIds.map((collectionId) =>
            denormalize(collectionId, CollectionSchema, entities)
          ),
    [entities, collectionIds]
  );

  return {
    collections,
    collectionsEditTrigger: (
      collectionId: number,
      title: string,
      handle: string,
      managedBy: string
    ) =>
      dispatch(
        submitCollectionsEditSlice.actions.trigger({ collectionId, handle, managedBy, title })
      ),
    collectionsFetchTrigger: () => dispatch(collectionsFetchTrigger(sortOrder, start, search)),
    correctLocation,
    allIds,
    count,
    dispatch,
    error,
    isLoading,
    loaded,
    search,
    shopUrl: activeShop?.url || '',
    sortOrder,
    start,
  };
};

export default useCollectionsPageData;
