import CenteredWindow from 'components/CenteredWindow/CenteredWindow';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_URL } from '../../global/urls';

const NoShops: FC = () => {
  return (
    <CenteredWindow>
      <div className="text-center">
        <div>
          Your account is inactive. Please contact support.
          <br />
          <br />
          Back to <Link to={LOGIN_URL}>Login Page</Link>.
        </div>
      </div>
    </CenteredWindow>
  );
};

export default NoShops;
