import cx from 'classnames';
import { Formik } from 'formik';
import React, { CSSProperties, FC, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import styles from '../../../../global/styles/falconTable.module.scss';
import { InventoryItemEntity } from '../../../../schemas';
import { FormItems, FormValues, InventorySortOrder } from '../../types';
import { columns, loadingColumns } from './columns';

type Props = {
  formItems: FormItems;
  inventoryItems: InventoryItemEntity[] | null;
  pageSize: number;
  sortOrder: InventorySortOrder;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InventoryTable: FC<Props> = ({ formItems, inventoryItems, pageSize, sortOrder }: Props) => {
  const rowStyleFormat = (row: InventoryItemEntity, rowIdx: number): CSSProperties => {
    return { backgroundColor: rowIdx % 2 === 0 ? '' : '#f9fafd' };
  };

  const data = useMemo(
    () =>
      inventoryItems !== null
        ? inventoryItems
        : Array.from({ length: pageSize }, (_, id) => ({ id })),
    [inventoryItems, pageSize]
  );

  const initialValues: FormValues = useMemo(
    () => ({
      items: formItems,
    }),
    [formItems]
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values /* eslint-disable-line @typescript-eslint/no-unused-vars */) => {
        /* dummy */
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {(fprops) => (
        <BootstrapTable
          bootstrap4
          bordered={false}
          classes={cx(styles.table, 'align-middle')}
          columns={inventoryItems !== null ? columns() : loadingColumns()}
          data={data}
          headerClasses={cx(styles.header, 'fs-1')}
          headerWrapperClasses={cx(styles.headerWrapper)}
          keyField="id"
          rowClasses={cx(styles.row, 'py-2 align-middle')}
          rowStyle={(row, idx) => rowStyleFormat(row, idx)}
          onTableChange={() => {}}
          remote={{
            filter: true,
            pagination: true,
            sort: true,
          }}
        />
      )}
    </Formik>
  );
};

export default InventoryTable;
