import { useSelector } from 'react-redux';
import { getRouterState } from '../selectors';

interface RouterData {
  pathname: string;
}

const useRouterData = (): RouterData => {
  const router = useSelector(getRouterState);
  return { pathname: router.location.pathname };
};

export default useRouterData;
