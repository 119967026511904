import {
  faArchive,
  faBoxes,
  faBoxOpen,
  faClone,
  faMinusCircle,
  faTags,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownMenu, {
  DropDownItemDefinition,
} from '../../../../components/DropdownMenu/DropdownMenu';
import useToggle from '../../../../components/ModalForm/useToggle';
import { getSessionShops } from '../../../../global/session/selectors';
import useProductsQueryParams from '../../hooks/useProductsQueryParams';
import {
  AddCollectionsRequestPayload,
  addCollectionsSlice,
  AddTagsRequestPayload,
  addTagsSlice,
  ArchiveProductsRequestPayload,
  archiveProductsSlice,
  CloneProductsRequestPayload,
  cloneProductsSlice,
  RemoveCollectionsRequestPayload,
  removeCollectionsSlice,
  RemoveTagsRequestPayload,
  removeTagsSlice,
  UnarchiveProductsRequestPayload,
  unarchiveProductsSlice,
  UnpublishProductsRequestPayload,
  unpublishProductsSlice,
} from '../../slices';
import { ProductsType } from '../../types';
import ArchiveProductsModal from '../ArchiveProductsModal/ArchiveProductsModal';
import CloneProductsModal from '../CloneProductsModal/CloneProductsModal';
import {
  AddCollectionsModal,
  RemoveCollectionsModal,
} from '../EditCollectionsModal/EditCollectionsModal';
import { AddTagsModal, RemoveTagsModal } from '../EditTagsModal/EditTagsModal';
import UnarchiveProductsModal from '../UnarchiveProductsModal/UnarchiveProductsModal';
import UnpublishProductsModal from '../UnpublishProductsModal/UnpublishProductsModal';

type Props = {
  component: 'header' | 'item';
  disabled?: boolean;
  selectedProducts: Set<number>;
};

const ProductsActionsDropdown: FC<Props> = ({
  component,
  disabled = false,
  selectedProducts,
}: Props) => {
  const [addTagsOpen, addTagsToggle] = useToggle();
  const [removeTagsOpen, removeTagsToggle] = useToggle();
  const [addCollectionsOpen, addCollectionsToggle] = useToggle();
  const [removeCollectionsOpen, removeCollectionsToggle] = useToggle();
  const [unpublishOpen, unpublishToggle] = useToggle();
  const [archiveOpen, archiveToggle] = useToggle();
  const [unarchiveOpen, unarchiveToggle] = useToggle();
  const [cloneOpen, cloneToggle] = useToggle();
  const shops = useSelector(getSessionShops);
  const { type } = useProductsQueryParams();

  const canClone = shops && shops.length > 1;

  const activeDropDownItems: DropDownItemDefinition[] = [
    { text: 'Add Tags', icon: faTags, onClick: addTagsToggle },
    { text: 'Remove Tags', icon: faMinusCircle, onClick: removeTagsToggle },
    null,
    { text: 'Add Collections', icon: faBoxes, onClick: addCollectionsToggle },
    { text: 'Remove Collections', icon: faMinusCircle, onClick: removeCollectionsToggle },
    null,
    { text: 'Unpublish', icon: faUnlink, onClick: unpublishToggle },
    { text: 'Archive', icon: faArchive, onClick: archiveToggle },
  ];

  if (canClone) {
    activeDropDownItems.push(null);
    activeDropDownItems.push({ text: 'Clone to Other Store', icon: faClone, onClick: cloneToggle });
  }

  const archivedDropDownItems: DropDownItemDefinition[] = [
    { text: 'Unarchive', icon: faBoxOpen, onClick: unarchiveToggle },
  ];

  const dropDownItems = type === ProductsType.Active ? activeDropDownItems : archivedDropDownItems;

  const dispatch = useDispatch();
  const addTagsTrigger = (payload: AddTagsRequestPayload) =>
    dispatch(addTagsSlice.actions.trigger(payload));
  const removeTagsTrigger = (payload: RemoveTagsRequestPayload) =>
    dispatch(removeTagsSlice.actions.trigger(payload));
  const addCollectionsTrigger = (payload: AddCollectionsRequestPayload) =>
    dispatch(addCollectionsSlice.actions.trigger(payload));
  const removeCollectionsTrigger = (payload: RemoveCollectionsRequestPayload) =>
    dispatch(removeCollectionsSlice.actions.trigger(payload));
  const unpublishProductsTrigger = (payload: UnpublishProductsRequestPayload) =>
    dispatch(unpublishProductsSlice.actions.trigger(payload));
  const archiveProductsTrigger = (payload: ArchiveProductsRequestPayload) =>
    dispatch(archiveProductsSlice.actions.trigger(payload));
  const unarchiveProductsTrigger = (payload: UnarchiveProductsRequestPayload) =>
    dispatch(unarchiveProductsSlice.actions.trigger(payload));
  const cloneProductsTrigger = (payload: CloneProductsRequestPayload) =>
    dispatch(cloneProductsSlice.actions.trigger(payload));

  return (
    <>
      <DropdownMenu
        component={component}
        items={dropDownItems}
        headerLabel="Bulk Actions"
        disabled={disabled}
      />
      <AddTagsModal
        isOpen={addTagsOpen}
        toggle={addTagsToggle}
        selectedProducts={selectedProducts}
        submit={addTagsTrigger}
      />
      <RemoveTagsModal
        isOpen={removeTagsOpen}
        toggle={removeTagsToggle}
        selectedProducts={selectedProducts}
        submit={removeTagsTrigger}
      />
      <AddCollectionsModal
        isOpen={addCollectionsOpen}
        toggle={addCollectionsToggle}
        selectedProducts={selectedProducts}
        submit={addCollectionsTrigger}
      />
      <RemoveCollectionsModal
        isOpen={removeCollectionsOpen}
        toggle={removeCollectionsToggle}
        selectedProducts={selectedProducts}
        submit={removeCollectionsTrigger}
      />
      <UnpublishProductsModal
        isOpen={unpublishOpen}
        toggle={unpublishToggle}
        selectedProducts={selectedProducts}
        submit={unpublishProductsTrigger}
      />
      <ArchiveProductsModal
        isOpen={archiveOpen}
        toggle={archiveToggle}
        selectedProducts={selectedProducts}
        submit={archiveProductsTrigger}
      />
      <UnarchiveProductsModal
        isOpen={unarchiveOpen}
        toggle={unarchiveToggle}
        selectedProducts={selectedProducts}
        submit={unarchiveProductsTrigger}
      />
      <CloneProductsModal
        isOpen={cloneOpen}
        toggle={cloneToggle}
        selectedProducts={selectedProducts}
        submit={cloneProductsTrigger}
      />
    </>
  );
};

export default ProductsActionsDropdown;
