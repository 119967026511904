import { Formik, FormikErrors, FormikProps } from 'formik';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import slugify from 'slugify';
import FalconCardHeader from '../../../../components/FalconCardHeader/FalconCardHeader';
import {
  CollectionEntity,
  CollectionProduct,
  CollectionProductsOrder,
  MicroProductEntity,
} from '../../../../schemas';
import ProductSubmitButton from '../../../Product/components/ProductSubmitButton/ProductSubmitButton';
import { collectionBumpProducts } from '../../actions/actions';
import { CollectionsEditFormValues, CollectionSubmitValues } from '../../types';
import CollectionBrowseModal, {
  CollectionBrowseFormValues,
} from '../CollectionBrowseModal/CollectionBrowseModal';
import CollectionHandle from '../CollectionHandle/CollectionHandle';
import CollectionOrder from '../CollectionOrder/CollectionOrder';
import CollectionProducts from '../CollectionProducts/CollectionProducts';
import CollectionTitle from '../CollectionTitle/CollectionTitle';
import CollectionType from '../CollectionType/CollectionType';

type Props = {
  browsableProducts: MicroProductEntity[] | null;
  browseModalCounter: number;
  collection: CollectionEntity;
  isBrowseModalOpen: boolean;
  isLoadingBrowse: boolean;
  isSubmitting: boolean;
  numShown: number;
  numTotal: number;
  onBrowse: () => void;
  onShowMore: () => void;
  onSubmit: (values: CollectionSubmitValues) => void;
  products: CollectionProduct[];
  selectedProductIds: number[];
  shopUrl: string;
  toggleBrowseModal: () => void;
};

const defaultInitialValues: CollectionsEditFormValues = {
  handle: '',
  managedBy: 'vendor',
  productsOrder: CollectionProductsOrder.BestSelling,
  products: [],
  title: '',
};

function validate(values: CollectionsEditFormValues) {
  const errors: FormikErrors<CollectionsEditFormValues> = {};
  if (!values.title) {
    errors.title = 'Required';
  }
  if (!values.handle) {
    errors.handle = 'Required';
  }
  return errors;
}

const CollectionForm: FC<Props> = ({
  browsableProducts,
  browseModalCounter,
  collection,
  isBrowseModalOpen,
  isLoadingBrowse,
  isSubmitting,
  numShown,
  numTotal,
  onBrowse,
  onShowMore,
  onSubmit,
  products,
  selectedProductIds,
  shopUrl,
  toggleBrowseModal,
}: Props) => {
  const [bumper, setBumper] = useState(0);
  const dispatch = useDispatch();

  const initialValues: CollectionsEditFormValues = collection
    ? {
        handle: collection.handle,
        managedBy: collection.isVendorManaged ? 'vendor' : 'brand',
        productsOrder: collection.productsOrder,
        products,
        title: collection.title,
      }
    : defaultInitialValues;

  const submit = (values: CollectionsEditFormValues) => {
    const submitValues: CollectionSubmitValues = {
      handle: values.handle,
      managedBy: values.managedBy,
      productIds: values.products.map((product) => product.productId),
      productsOrder: values.productsOrder,
      title: values.title,
    };
    onSubmit(submitValues);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fprops: FormikProps<CollectionsEditFormValues>
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  ) => {
    const { value } = event.target;
    fprops.setFieldValue('title', value);
    if (!fprops.touched.handle || !fprops.values.handle) {
      fprops.setFieldValue('handle', slugify(value, { lower: true, strict: true }));
      fprops.setFieldTouched('handle', false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleBrowseDone = (
    collectionId: number,
    values: CollectionBrowseFormValues,
    fprops: FormikProps<CollectionsEditFormValues>
  ) => {
    const { added, removedIds } = values;

    const newProducts = [...fprops.values.products].filter(
      (product) => !removedIds.includes(product.productId)
    );

    added.forEach((product) => {
      newProducts.push({
        image: product.image,
        productId: product.id,
        name: product.name,
        status: product.status,
      });
    });

    fprops.setFieldValue('products', newProducts);

    const addedIds = added.map((microProduct) => microProduct.id);

    setBumper(bumper + 1);
    dispatch(collectionBumpProducts(collectionId, addedIds, removedIds));
  };

  // const selectedProductIds = useMemo(
  //   () => (products ? products.map((product) => product.productId) : []),
  //   [products]
  // );

  return (
    <Formik<CollectionsEditFormValues>
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
    >
      {(fprops) => (
        <>
          <Form onSubmit={fprops.handleSubmit}>
            <Card className="h-lg-100 mb-3">
              <FalconCardHeader
                border={false}
                title={`${collection.title} (${collection.handle})`}
                className="bg-white"
              >
                <ProductSubmitButton
                  canSubmit
                  isSubmitting={isSubmitting}
                  label="Save change"
                  type="submit"
                />
              </FalconCardHeader>
              <CardBody className="py-0">
                <Row>
                  <Col lg={8}>
                    <CollectionTitle
                      fprops={fprops}
                      onChange={(event) => handleTitleChange(event, fprops)}
                    />
                  </Col>
                  <Col lg={4} />
                </Row>
                <Row>
                  <Col lg={8}>
                    <CollectionHandle fprops={fprops} shopUrl={shopUrl} />
                    <CollectionProducts
                      fprops={fprops}
                      numShown={numShown}
                      numTotal={numTotal}
                      onBrowse={onBrowse}
                      onShowMore={onShowMore}
                    />
                  </Col>
                  <Col lg={4}>
                    <CollectionType fprops={fprops} />
                    <CollectionOrder />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>

          <CollectionBrowseModal
            browsableProducts={browsableProducts}
            collection={collection}
            isLoading={isLoadingBrowse}
            isOpen={isBrowseModalOpen}
            key={browseModalCounter}
            onDone={(collectionId, values) => handleBrowseDone(collectionId, values, fprops)}
            initialSelectedProductIds={selectedProductIds}
            toggle={toggleBrowseModal}
          />
        </>
      )}
    </Formik>
  );
};

export default CollectionForm;
