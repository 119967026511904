import { denormalize } from 'normalizr';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { getEntitiesState } from '../../../global/entities/selectors';
import { getRouterState } from '../../../global/selectors';
import { getSessionUser } from '../../../global/session/selectors';
import { LocationState } from '../../../global/types';
import { checkIfProcessing } from '../../../global/ui/selectors';
import { PRODUCTS_URL } from '../../../global/urls';
import { AppState } from '../../../init/rootReducer';
import { BriefProductEntity, BriefProductSchema, UserEntity } from '../../../schemas';
import { NormalizedError } from '../../../types';
import { productsFetchTrigger } from '../actions/fetch.actions';
import { PRODUCTS_FETCH_API_PREFIX } from '../constants';
import { getProductsQueryState, getProductsSearchState } from '../selectors';
import { ProductsSortOrder, ProductsType } from '../types';
import useProductsQueryParams from './useProductsQueryParams';

type ProductsPageData = {
  correctLocation: boolean;
  count: number;
  dispatch: Dispatch;
  error: NormalizedError | null;
  isLoading: boolean;
  loaded: boolean;
  locationState: LocationState;
  products: BriefProductEntity[] | null;
  productsFetchTrigger(): void;
  search: string;
  sortOrder: ProductsSortOrder;
  start: number;
  user: UserEntity | null;
  allProductIds: number[];
  productsType: ProductsType;
};

const useProductsPageData = (): ProductsPageData => {
  const dispatch = useDispatch();
  const { location } = useSelector(getRouterState);
  const { locationState, search, sortOrder, start, type } = useProductsQueryParams();
  const correctLocation = location.pathname === PRODUCTS_URL;
  const entities = useSelector(getEntitiesState);
  const user = useSelector(getSessionUser);
  const { count, error, loaded, productVersionIds } = useSelector((state: AppState) =>
    getProductsQueryState(state, sortOrder, start, search)
  );
  const { allProductIds } = useSelector((state: AppState) => getProductsSearchState(state, search));

  const isLoading = useSelector((state: AppState) =>
    checkIfProcessing(state, PRODUCTS_FETCH_API_PREFIX)
  );

  const products = useMemo(
    () =>
      productVersionIds !== null
        ? productVersionIds
            .map(
              (productId) =>
                denormalize(productId, BriefProductSchema, entities) as BriefProductEntity
            )
            // Remapping product version IDs to product ids
            .map((e) => ({
              ...e,
              id: e.productId,
            }))
        : null,
    [entities, productVersionIds]
  );

  return {
    correctLocation,
    count,
    dispatch,
    error,
    isLoading,
    loaded,
    locationState,
    products,
    productsFetchTrigger: () => dispatch(productsFetchTrigger(sortOrder, start, search, type)),
    search,
    allProductIds,
    sortOrder,
    start,
    user,
    productsType: type,
  };
};

export default useProductsPageData;
